import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@purespectrum1/ui/modal';

import { ToasterService } from '@purespectrum1/ui/toaster-service';

import { AuthService } from '../../auth/auth.service';
import { TabService } from '../tab-service/tab.service';

import { Constants } from '../dashboard-constants';
import { notifyMessage } from '../../constants/notify-message';
import { SurveyStatus } from '../../utils/survey-status';
import { SURVEY_STATUS } from '../../constants/survey-status';
import { STATUS_BUTTONS } from '../../constants/status-buttons';
import {
  Survey,
  Quotas,
  Locale,
  SurveyStatusInterface,
  DashboardChannel,
  TcResponse,
  SurveyUpdate,
  McSVPEachSurvey,
} from '../../shared/services/buyer-api/survey.interface';
import { BuyerApiService } from '../../shared/services/buyer-api/survey.service';
import { SupplierApiService } from '../../shared/services/supplier-api/supplier-api.service';

import { Tab } from '../types';
import { ChangeSurveyStatusModalComponent } from '../change-survey-status-modal/change-survey-status-modal.component';
import { INVOICE_TYPE } from '../../constants/invoice-type';
import { CommonService } from '../../shared/services/common-service/common-service';
import { SurveyPayloadService } from '../../../app/create-surveys/survey-payload.service';
import { SocketService } from '../../shared/services/socket/socket.service';
import { Subscription, interval } from 'rxjs';
import { takeWhile, mergeMap, map } from 'rxjs/operators';
import { EventEmitterService } from '../../shared/services/event-emitter.service';
import { SurveyMetaDataService } from '../../shared/services/survey-meta-data/survey-meta-data.service';
import { AuthState } from '@purespectrum1/ui/marketplace/shared/interfaces/auth.interface';
import { BulkSurveyStatusChangeModalComponent } from '../../shared/ui/bulk-survey-status-change-modal/bulk-survey-status-change-modal.component';
import { BULK_STATUS_CHANGE_MODAL } from '../../constants/modal-constants';
import { BulkInvoiceHelperService } from '../../shared/services/bulk-invoice/bulk-invoice-helper.service';
import { BillingService } from '../../shared/services/billing-service/billing.service';
import { CountryInterface } from '../../shared/interfaces/create-surveys.interface';
import { McSvpCountryNameMapper } from '../services/mc-svp-country-name-mapper';
import { BuyerSurveyResponse } from '@purespectrum1/ui/marketplace/shared/interfaces/survey.interface';

@Component({
  selector: 'ps-survey-detail',
  templateUrl: './survey-detail.component.html',
  styleUrls: ['./survey-detail.component.css'],
})
export class SurveyDetailComponent implements OnInit, OnDestroy {
  private _tabs: Tab[] = Constants.SURVEY_TABS;
  public userType = this._auth.userType;
  public surveyStatus = SurveyStatus;
  public statusButtons = STATUS_BUTTONS;
  public countries: CountryInterface[] = [];
  public selectedSvpCountry: McSVPEachSurvey = {} as McSVPEachSurvey;
  public svpChildIdPassedViaRoute?: number | null;
  public activeSurveyDashboardTabKey: string | null;
  surveyId?: number;
  survey: Survey = {} as Survey;
  buyerSurvey: BuyerSurveyResponse = {} as BuyerSurveyResponse;
  surveyQuotas: Array<Quotas> = [];
  locale: Locale = {} as Locale;
  isQbpChildSurvey: boolean = false;
  isRecontact: boolean = false;
  isChildTCsClosed: boolean = false;
  auth: AuthState = this._auth.getMarketplaceAuth;
  private _subscriptions: Subscription = new Subscription();

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _toastr: ToasterService,
    private _tabService: TabService,
    private _modal: ModalService,
    private _buyerApiService: BuyerApiService,
    private _supplierApiService: SupplierApiService,
    private _commanService: CommonService,
    private _surveyPayloadService: SurveyPayloadService,
    private _socketService: SocketService,
    private _eventEmitterService: EventEmitterService,
    private _surveyMetaData: SurveyMetaDataService,
    private _bulkInvoiceHelperService: BulkInvoiceHelperService,
    private _billingService: BillingService,
    private _cdr: ChangeDetectorRef
  ) {
    const extras = this._router.getCurrentNavigation()?.extras.state;
    this.activeSurveyDashboardTabKey = extras?.activeTab;
    this.svpChildIdPassedViaRoute = extras?.svp_child_id;
  }

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      this.surveyId = params.id;
      if (this._auth.userType === 'supplier') {
        this._getSupplierSurvey();
      } else {
        this._getSurveyDetails();
      }
      this._setSurveyStatus();
    });
    this._getCountries();
    this._socketSubscriptions();
  }

  private _socketSubscriptions() {
    this._subscriptions.add(
      this._socketService.surveyCompletes$.subscribe((data) => {
        if (Number(this.surveyId) === Number(data.surveyId)) {
          this.survey.goal = data.completes;
        }
      })
    );
    this._subscriptions.add(
      this._socketService.dashboardChannel$.subscribe(
        (data: DashboardChannel) => {
          this._updateDashboardWithDataReceived(data);
        }
      )
    );
  }

  private _updateDashboardWithDataReceived(data: DashboardChannel) {
    if (this.surveyId == data.surveyManagement.survey_id) {
      this.survey.fielded = data.surveyManagement.fielded;
    }
  }

  private _getSupplierSurvey() {
    this._supplierApiService.getSupplierSurvey(this.surveyId).subscribe(
      (response) => {
        this.survey = this._surveyPayloadService.mapSupplierSurvey(response);
        this._checkQbpChildSurvey();
        this._checkRecontact();
        this._checkChildTCsStatusClosed();
        this._surveyMetaData.sendPdsEnabledData(response.survey.pds);
        this._surveyMetaData.sendModularSurveyData(
          !!response.survey.is_modular_survey
        );
      },
      (error) => {
        this._toastr.error(notifyMessage.errorMessage.NO_DATA_ERROR);
      }
    );
  }

  private _getSurveyDetails() {
    this._getSurvey(this.surveyId).subscribe(
      (response) => {
        this._surveyPayloadService.mapSurveyDetails(response);
        this.buyerSurvey = response;
        this._addCountryLanguageMcSvpSurvey();
        this._addDefaultMcSvpChildSelection();
        this.survey = this._surveyPayloadService.mapBuyerSurvey(response);
        this.isQbpChildSurvey = this.survey.is_qbp_child_survey;
        const manualScpiOverrideEnabled =
          response.manual_scpi_override_enabled || false;
        this._surveyMetaData.setSupplierOverrideValue(
          manualScpiOverrideEnabled
        );
        this._checkRecontact();
        this._checkChildTCsStatusClosed();
      },
      (error) => {
        this._toastr.error(notifyMessage.errorMessage.NO_DATA_ERROR);
      }
    );
  }

  private _addDefaultMcSvpChildSelection() {
    if (!this.buyerSurvey.mc.SVP) {
      return;
    }

    const foundIndex = (this.buyerSurvey.mc.surveys ?? []).findIndex(
      (childSurvey) =>
        childSurvey.surveyId === Number(this.svpChildIdPassedViaRoute)
    );
    const selectedSurveyIndex = foundIndex !== -1 ? foundIndex : 0;
    this.selectedSvpCountry =
      this.buyerSurvey.mc.surveys &&
      this.buyerSurvey.mc.surveys[selectedSurveyIndex]
        ? this.buyerSurvey.mc.surveys[selectedSurveyIndex]
        : {
            surveyId: this.buyerSurvey.ps_survey_id,
            countryCode: this.buyerSurvey.locale.country_code,
            languageCode: this.buyerSurvey.locale.language_translate,
            countryName: this.buyerSurvey.locale.country_name,
          };
  }

  isEmptySurvey() {
    return (
      this.survey && Object.keys(this.survey).length === 0 && this.surveyQuotas
    );
  }

  navigateToDashboard() {
    const tabIndex =
      this._tabs.findIndex(
        (tab) => tab.status === this.survey.status.toString()
      ) || 1;
    this._tabService.setActiveTab(tabIndex);
    this._router.navigate(['/dashboard']);
  }

  checkDisableSurveyStatus(survey: Survey) {
    return (
      this.userType === 'supplier' ||
      [SURVEY_STATUS.DRAFT, SURVEY_STATUS.INVOICE].indexOf(survey.status) >
        -1 ||
      (survey.status === SURVEY_STATUS.CLOSED &&
        survey?.invoiceType === INVOICE_TYPE.MONTHLY)
    );
  }

  async openStatusModal(surveyId: number, status: string) {
    const [isProceed, isBulkInvoicingCase, surveysToClose, surveysToInvoice] =
      await this._checkIfItisBulkInvoicingCase(status);
    if (!isProceed) {
      return;
    }
    if (!isBulkInvoicingCase) {
      return this.openSingleStatusUpdateModal(surveyId, status);
    }
    return this._updateSurveyStatuses(surveysToClose, surveysToInvoice);
  }

  private async _checkIfItisBulkInvoicingCase(
    status: string
  ): Promise<[boolean, boolean, SurveyUpdate[], SurveyUpdate[]]> {
    const numberedStatus = Number(this._commanService.getSurveyStatus(status));
    if (numberedStatus !== SurveyStatus.Invoice) {
      return [true, false, [], []];
    }
    return this._checkAndShowModalIfSurveysWithSamePo(numberedStatus);
  }

  openSingleStatusUpdateModal(surveyId: number, status: string) {
    const surveyUpdateData = {
      surveyId: surveyId,
      status: status,
      survey_pause_threshold: this.survey.survey_pause_threshold,
      is_survey_pause_threshold_triggered:
        this.survey.is_survey_pause_threshold_triggered,
      currentCpi: this.survey.cpi.current,
      currencySymbol: this.survey.currencySymbol,
    };
    const modalRef = this._modal.open(ChangeSurveyStatusModalComponent, {
      data: surveyUpdateData,
      width: '450px',
      height: '18.75rem',
    });
    modalRef.onClose$.subscribe((msg) => {
      if (msg === Constants.STATUS_CHANGE_MESSAGE) {
        this.survey = {
          ...this.survey,
          ...{ status: this._commanService.getSurveyStatus(status) },
        };
        this._eventEmitterService.changeSurveyStatus({
          surveyId: surveyId,
          status: this.survey.status,
          channelType: this.survey.channelType,
        });
        if (this.survey.status === SURVEY_STATUS.CLOSED) {
          this._watchTCsStatus();
        }
      }
    });
  }

  private _checkQbpChildSurvey() {
    if (!this.survey.is_qbp_enable && this.survey.qbp?.quota_ids) {
      this.isQbpChildSurvey = true;
    }
  }

  private _checkRecontact() {
    if (
      this.survey.survey_category_code &&
      this.survey.survey_category_code === 234
    ) {
      this.isRecontact = true;
    }
  }

  private _setSurveyStatus() {
    this._commanService.invokeChangeSurveyStatus.subscribe(
      (surveyStatus: SurveyStatusInterface) => {
        if (this.surveyId == surveyStatus.surveyId) {
          this.survey.status = surveyStatus.status;
        }
      }
    );
  }

  private _getChildTCsClosedStatus(trafficChannels: TcResponse[]) {
    return trafficChannels
      .filter(
        (tc) =>
          !tc.survey_id || (tc.survey_id && tc.status !== SURVEY_STATUS.DRAFT)
      ) // Either TC is orphaned(no survey id) or survey is not in draft status. we are excluding draft TC(abodoned) to check the invoice the survey.
      .every(
        (tc) =>
          tc.status === SURVEY_STATUS.CLOSED ||
          tc.create_status === Constants.CHILD_CREATION_STATUS.ERROR ||
          tc.create_status === Constants.CHILD_CREATION_STATUS.PENDING
      );
  }

  private _checkChildTCsStatusClosed() {
    if (this.survey.status !== SURVEY_STATUS.CLOSED) {
      return;
    }
    if (this._auth.userType != 'supplier') {
      this._buyerApiService
        .getTrafficChannelData(this.surveyId!)
        .subscribe((trafficChannels) => {
          this.isChildTCsClosed =
            this._getChildTCsClosedStatus(trafficChannels);
        });
    }
  }

  private _watchTCsStatus() {
    interval(800)
      .pipe(
        mergeMap(() =>
          this._buyerApiService.getTrafficChannelData(this.surveyId!)
        ),
        map(this._getChildTCsClosedStatus),
        takeWhile((isTCsClosed: boolean) => !isTCsClosed)
      )
      .subscribe(
        undefined,
        (error) => {
          this.isChildTCsClosed = false;
          this._toastr.error(error.error?.ps_api_response_message || error.msg);
        },
        () => {
          this.isChildTCsClosed = true;
        }
      );
  }

  showInvoiceTab() {
    if (
      this.survey.invoiceType !== INVOICE_TYPE.MONTHLY &&
      this._auth.userType !== 'supplier' &&
      (!!this._auth.buyerConfig?.enableInvoiceTab ||
        this._auth.userType === 'operator') &&
      this.survey.status === SurveyStatus.Closed
    ) {
      return true;
    }
    return false;
  }

  async invoiceSurvey() {
    if (!this.isChildTCsClosed) {
      return;
    }
    if (
      !this.survey.billing_id &&
      !this._auth.buyerConfig?.enableMandatoryBillingNumber
    ) {
      const shouldProceed = await this._billingService.openBillingNumberModal();
      if (!shouldProceed) {
        return;
      }
    }
    const [isProceed, , surveysToClose, surveysToInvoice] =
      await this._checkAndShowModalIfSurveysWithSamePo(SurveyStatus.Invoice);
    if (!isProceed) {
      return;
    }
    await this._updateSurveyStatuses(surveysToClose, surveysToInvoice);
  }

  private async _updateSurveyStatuses(
    surveysToClose: SurveyUpdate[],
    surveysToInvoice: SurveyUpdate[]
  ) {
    await this._buyerApiService
      .updateSurveyStatusesInBulk(surveysToClose)
      .toPromise();
    const subscription = this._buyerApiService
      .updateSurveyStatusesInBulk(surveysToInvoice)
      .subscribe(
        (data) => {
          this.survey.status = data[this.survey.id].ps_survey_status;
          this._eventEmitterService.changeSurveyStatus({
            surveyId: this.survey.id,
            status: this.survey.status,
            channelType: this.survey.channelType,
          });
          const isBulkInvoiced = Object.keys(data).length > 1;
          this._toastr.success(
            isBulkInvoiced
              ? notifyMessage.successMessage.SURVEY_DETAIL.BULK_SURVEYS_INVOICED
              : notifyMessage.successMessage.SURVEY_DETAIL.SINGLE_SURVEY_INVOICED.replace(
                  '<SURVEY_ID>',
                  String(this.survey.id)
                )
          );
          subscription.unsubscribe();
        },
        (error) => {
          this._toastr.error(error.error?.ps_api_response_message || error.msg);
        }
      );
  }

  private async _checkAndShowModalIfSurveysWithSamePo(status: SurveyStatus) {
    let notInvoicedSurveysWithSamePo =
      await this._fetchLaunchedAndNotInvoicedSurveysWithSamePo();
    notInvoicedSurveysWithSamePo = notInvoicedSurveysWithSamePo.filter(
      (s) => Number(s.ps_survey_id) !== Number(this.surveyId)
    );
    if (!notInvoicedSurveysWithSamePo.length) {
      return this._bulkInvoiceHelperService.decideSurveysToChangeStatusOf(
        status,
        BULK_STATUS_CHANGE_MODAL.NO_BULK_INVOICING_ACTION,
        [],
        [this.survey.id]
      );
    }
    const action = await this._showModalIfInvoicedSurveysWithSamePo(
      notInvoicedSurveysWithSamePo
    );
    return this._bulkInvoiceHelperService.decideSurveysToChangeStatusOf(
      status,
      action,
      notInvoicedSurveysWithSamePo,
      [this.survey.id]
    );
  }

  private async _fetchLaunchedAndNotInvoicedSurveysWithSamePo() {
    if (!this.survey.billing_id) {
      return [];
    }
    return this._buyerApiService
      .getLaunchedAndNotInvoicedSurveysWithSamePo(this.survey.billing_id)
      .toPromise();
  }

  private async _showModalIfInvoicedSurveysWithSamePo(
    notInvoicedSurveysWithSamePo: BuyerSurveyResponse[]
  ) {
    const notInvoicedSurveyIdsWithSamePo =
      this._bulkInvoiceHelperService.fetchNotInvoicedSortedSurveyIdsWithSamePo(
        notInvoicedSurveysWithSamePo
      );
    const modalRef = this._modal.open(BulkSurveyStatusChangeModalComponent, {
      data: {
        surveyIds: notInvoicedSurveyIdsWithSamePo.join(', '),
        thisAndAllBtnText:
          BULK_STATUS_CHANGE_MODAL.BUTTON.THIS_AND_ALL_LISTED.TEXT
            .SURVEY_DETAIL,
        onlyThisBtnText:
          BULK_STATUS_CHANGE_MODAL.BUTTON.ONLY_THIS.TEXT.SURVEY_DETAIL,
      },
      width: '60%',
    });
    return modalRef.onClose$.toPromise();
  }

  private _getCountries() {
    const countries = this._commanService.getCountries().subscribe(
      (countriesData) => {
        this.countries = countriesData;
      },
      (err) => {
        this._toastr.error(err.error.msg);
      }
    );
    this._subscriptions.add(countries);
  }

  private _addCountryLanguageMcSvpSurvey() {
    if (!this.buyerSurvey.mc.SVP) {
      return;
    }
    this.buyerSurvey.mc.surveys = new McSvpCountryNameMapper(
      this.buyerSurvey.mc.surveys || [],
      this.countries
    ).map();
  }

  public selectSvpCountry(svpCountry: McSVPEachSurvey) {
    this.selectedSvpCountry = svpCountry;
    this._cdr.detectChanges();
  }

  private _getSurvey(
    surveyId?: number,
    targeting: number = 0,
    accessible = false
  ) {
    return this._buyerApiService.getSurvey(surveyId, 0, accessible);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
