<ul class="header-div-ul">
  <ng-container *ngIf="isRecontactSurvey">
    <li class="header-div-li" *ngIf="totalClicks">
      <div>
        <h6 class="header-div-h6">CLICKS</h6>
        <span class="header-div-span">{{ totalClicks }}</span>
      </div>
    </li>

    <li class="header-div-li">
      <div>
        <h6 class="header-div-h6">Total Cost</h6>
        <span class="header-div-span">{{
          surveyTotalCost | currency : currencySymbol
        }}</span>
      </div>
    </li>
  </ng-container>
  <li class="header-div-li">
    <div>
      <h6 class="header-div-h6 close" (click)="close()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </h6>
    </div>
  </li>
</ul>
