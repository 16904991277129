import { Component, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { GetSurveyPerformanceReportResult } from '../survey-performance-reporta.interface';

@Component({
  selector: 'ps-survey-performance-report-table',
  templateUrl: './survey-performance-report-table.component.html',
  styleUrls: ['./survey-performance-report-table.component.css'],
})
export class SurveyPerformanceReportTableComponent {
  private readonly _bucketUrl = environment.BUCKET_URL;

  constructor() {}
  @Input()
  public dataList: Array<GetSurveyPerformanceReportResult> = [];
}
