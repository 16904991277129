import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  ModalData,
  ModalRef,
  SupplierBlend,
  GroupSupplier,
  ToasterService,
  ManageSuppliersService,
  SupplierGroupTemplateResponseInterface,
  GroupTemplateSupplier,
} from '@purespectrum1/ui';

import { validateSupplierGroupName } from '../../../utils/regex';
import { Constants } from '../../manage-suppliers/manage-suppliers-constants';

@Component({
  selector: 'ps-supplier-group-modal',
  templateUrl: './supplier-group-modal.component.html',
  styleUrls: ['./supplier-group-modal.component.css'],
})
export class SupplierGroupModalComponent implements OnInit {
  constructor(
    private _fb: FormBuilder,
    private _toastr: ToasterService,
    private _manageSupplierService: ManageSuppliersService,
    @Inject(ModalData) private _modalData: SupplierGroupModalData,
    private _modalRef: ModalRef<
      SupplierGroupModalComponent,
      {
        msg: string;
        supplierGroup: GroupSupplier;
      }
    >
  ) {}

  public form!: FormGroup;
  private _suppliers = this._modalData.selectedSupplierGroup;
  private _blend = this._modalData.selectedSupplierBlend;
  public edit = this._modalData.editModal;

  ngOnInit(): void {
    this._generateForm();
    this._patchModalData();
    this._distributeAllocation();
  }

  get supplier_id(): FormArray {
    return this.form.get('supplier_id') as FormArray;
  }

  private _generateForm(): void {
    this.form = this._fb.group({
      group_name: ['', Validators.required],
      allocation_percentage: ['', Validators.required],
      is_custom_allocation: [false, Validators.required],
      supplier_id: this._fb.array([
        this._fb.group({
          id: [null, [Validators.required]],
          name: [null, [Validators.required]],
          allocation: [null],
        }),
      ]),
    });
  }

  private _patchModalData(): void {
    const {
      group_name = '',
      allocation_percentage = '',
      is_custom_allocation = false,
    } = this._suppliers[0];

    const groupSuppliers = this._suppliers.flatMap(({ supplier_id }) => {
      return supplier_id.map(({ id, name, allocation }) => {
        return { id, name, allocation };
      });
    });

    this.form.patchValue({
      group_name,
      is_custom_allocation,
      allocation_percentage,
    });

    this._patchSupplierId(groupSuppliers);
  }

  private _patchSupplierId(groupSuppliers: GroupTemplateSupplier[]): void {
    this.supplier_id.clear();
    groupSuppliers.forEach((supplier) =>
      this.supplier_id.push(this._fb.group(supplier))
    );
  }

  private _applyAllocationToAllSuppliers(allocationValue: number) {
    this.supplier_id.controls.forEach((supplier) =>
      supplier.patchValue({
        allocation: allocationValue,
      })
    );
  }

  private _distributeAllocation(forceEvenlyDistribution: boolean = false) {
    const isAllocationsUndefined = Array.from(this.supplier_id.value).some(
      (s: any) =>
        !s.allocation ||
        s.allocation === Constants.DEFAULT_SUB_SUPPLIER_ALLOCATION
    );
    if (!this.form.value.is_custom_allocation) {
      // If custom allocation is not actived we should use 100 percent of the
      // allocaiton of the group as it's work before
      this._applyAllocationToAllSuppliers(
        Constants.DEFAULT_SUB_SUPPLIER_ALLOCATION
      );
    } else if (forceEvenlyDistribution || isAllocationsUndefined) {
      const evenlyAllocation = Math.floor(100 / this.supplier_id.length);
      this._applyAllocationToAllSuppliers(evenlyAllocation);
    }
  }

  public changeCustomAllocation(): void {
    const flag = !this.form.value.is_custom_allocation;
    this.form.patchValue({
      is_custom_allocation: flag,
    });

    this._distributeAllocation(true);
  }

  public removeSupplierFromGroup(index: number): void {
    if (this.supplier_id.length > 2) {
      this.supplier_id.removeAt(index);
      return;
    }

    this._toastr.error('Supplier group should have more than one Supplier');
  }

  public saveSupplierGroup(saveAsTemplate: boolean = false): void {
    if (!this.form.value.group_name) {
      this._toastr.warning('Group Name is required');
    } else if (!this.edit && this._checkValidGroupName()) {
      this._toastr.warning('Group Name already exits');
    } else if (!validateSupplierGroupName(this.form.value.group_name)) {
      this._toastr.warning(Constants.INVALID_SUPPLIER_GROUP_NAME);
    } else if (!this._validateCustomAllocation()) {
      this._toastr.error(
        'The sum of Allocation % for suppliers should greater than 100% for the Group'
      );
    } else {
      if (saveAsTemplate) {
        this._saveGroupAsTemplate();
      } else {
        this._modalRef.close({
          msg: 'ok',
          supplierGroup: this._mapGroupSupplierPayload(),
        });
      }
    }
  }

  private _checkValidGroupName(): boolean {
    const isGroupExits = this._blend.suppliers?.filter(
      ({ group_name, setActive }) =>
        group_name === this.form.value.group_name && !setActive
    );

    return isGroupExits?.length ? true : false;
  }

  private _validateCustomAllocation(): boolean {
    if (!this.form.value.is_custom_allocation) {
      return true;
    }

    const totalAllocation = this.supplier_id.controls.reduce(
      (sum, { value: { allocation } = 0 }) => sum + Number(allocation),
      0
    );
    if (totalAllocation >= 100) {
      return true;
    }

    return false;
  }

  private _mapGroupSupplierPayload(): GroupSupplier {
    const {
      group_name,
      allocation_percentage,
      is_custom_allocation,
      supplier_id,
    } = this.form.value;

    return {
      group_name,
      allocation_percentage,
      is_custom_allocation,
      supplier_id: is_custom_allocation
        ? supplier_id
        : supplier_id.map(({ allocation = 0, ...rest }) => rest),
      setActive: false,
    };
  }

  private _saveGroupAsTemplate(): void {
    const supplierGroupTemplateObj: SupplierGroupTemplateResponseInterface = {
      groupName: this.form.value.group_name,
      allocation: Number(this.form.value.allocation_percentage),
      is_custom_allocation: this.form.value.is_custom_allocation,
      suppliers: this.form.value.supplier_id.map((supplier: any) => ({
        ...supplier,
        allocation: Number(supplier.allocation),
      })),
    };

    this._manageSupplierService
      .saveGroupAsTemplate(supplierGroupTemplateObj)
      .subscribe(
        (_response) => {
          this._toastr.success(Constants.SUPPLIER_GROUP_TEMPLATE_SAVED);
        },
        (err) => {
          const errMsg =
            err?.error?.msg ||
            err?.error?.ps_api_response_message ||
            Constants.SUPPLIER_GROUP_TEMPLATE_NOT_SAVED;
          this._toastr.error(errMsg);
        }
      );
  }

  public close(): void {
    this._modalRef.close();
  }
}

interface SupplierGroupModalData {
  selectedSupplierGroup: GroupSupplier[];
  selectedSupplierBlend: SupplierBlend;
  editModal: boolean;
}
