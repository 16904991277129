<!-- Either it's TC tab, or MC tab with survey (the one on display) being MC project -->
<ng-container *ngIf="isTCTab || !isSingleCountrySurvey">
  <div class="table-responsive" [ngClass]="{ 'not-scrollable': !scrollable }">
    <table class="table table-borderless surevey-table-data">
      <thead [class.multi-country]="!isTCTab">
        <tr>
          <th>Status</th>
          <th
            *ngFor="let header of tableHeaders"
            [psSortTable]="surveys"
            data-order="desc"
            [attr.data-name]="header.orderKey"
          >
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let survey of surveys">
          <tr *ngIf="survey.create_status === CHILD_CREATION_STATUS.PENDING">
            <td>
              <div>
                <div class="tcPendingMessage"><b> PENDING </b></div>
              </div>
            </td>
            <td class="text-center" *ngFor="let in of counter(2)">-</td>
            <td class="text-center">{{ survey.survey_type }}</td>
            <td class="text-center" *ngFor="let in of counter(9)">-</td>
          </tr>
          <tr
            *ngIf="
              survey.survey_type === 'Parent' ||
              survey.create_status === CHILD_CREATION_STATUS.READY
            "
          >
            <td>
              <div class="survey-status">
                <button
                  class="surveyStatusBtn menu-btn"
                  [psUiDropdownToggleFor]="surveysStatus"
                  [disabled]="checkDisableSurveyStatus(survey)"
                  [class.disable-tc-status]="
                    isTCTab && survey.ps_survey_status === surveyStatus.Closed
                  "
                >
                  {{ surveyStatus[survey.ps_survey_status] }}
                </button>
                <ps-ui-dropdown
                  class="status-dropdown"
                  #surveysStatus="psUiDropdown"
                >
                  <div *ngFor="let statusButton of statusButtons">
                    <button
                      psUiDropdownItem
                      *ngIf="
                        statusButton.condition.indexOf(
                          survey.ps_survey_status
                        ) > -1
                      "
                      (click)="
                        openStatusModal(survey.ps_survey_id, statusButton.value)
                      "
                    >
                      {{ statusButton.value }}
                    </button>
                  </div>
                </ps-ui-dropdown>
              </div>
            </td>
            <td class="text-left">
              <a
                [class.survey-click]="
                  (userType === 'operator' || userType === 'buyer') && !isTCTab
                "
                disabled="true"
                (click)="
                  (userType === 'operator' || userType === 'buyer') &&
                    !isTCTab &&
                    redirectToSurvey(survey.ps_survey_id)
                "
                >{{ survey.ps_survey_id }}</a
              >
            </td>
            <td class="text-left">
              <a
                [class.survey-click]="
                  (userType === 'operator' || userType === 'buyer') && !isTCTab
                "
                (click)="
                  (userType === 'operator' || userType === 'buyer') &&
                    !isTCTab &&
                    redirectToSurvey(survey.ps_survey_id)
                "
                >{{ survey.survey_title }}</a
              >
            </td>
            <td class="text-center">{{ survey.survey_type }}</td>
            <td class="text-center">
              {{ survey.creation_date | date : 'MM/dd/yyyy h:mma' }}
            </td>
            <td class="text-center">
              {{ survey.fielded || 0 }} | {{ survey.goal || 0 }}
            </td>
            <td class="text-center">
              {{ survey.avg_cpi | currency : survey.currencySymbol }}
            </td>
            <td
              class="text-center"
              *ngIf="
                survey.survey_type === 'Parent' ||
                survey.survey_type === 'Multicountry'
              "
            >
              {{ survey.cpi_current | currency : survey.currencySymbol }}
            </td>
            <td
              class="text-center"
              *ngIf="!['Multicountry', 'Parent'].includes(survey.survey_type)"
            >
              <div
                class=""
                *ngIf="currentCpiEditable.includes(survey.ps_survey_status)"
              >
                <div class="hover-edit-trigger" title="click to edit">
                  <div
                    class="hover-text-field"
                    *ngIf="!survey.isEdit"
                    (click)="editMode(survey)"
                  >
                    {{ survey.cpi_current | currency : survey.currencySymbol }}
                    <div class="pencil-outer pencil-outer-qbp">
                      <div class="fa fa-pencil"></div>
                    </div>
                  </div>
                  <input
                    [ngClass]="{
                      'inactive-red': survey.cpi_current > 10
                    }"
                    class="inputText hover-font-color input-qbp-cpi"
                    type="inputText"
                    [(ngModel)]="survey.cpi_current"
                    (keyup.enter)="updateCurrentCpi(survey)"
                    (keypress)="validateQbpCpiQty($event)"
                    (change)="verifyCpiToShowWarning($event)"
                    *ngIf="survey.isEdit"
                    psInputDecimalRestriction
                  />
                </div>
                <div class="cpi-modal-warning" *ngIf="showWarningModal">
                  <span id="margin-warning-div">
                    <button
                      class="close-button-ps"
                      (click)="hideWarningModal(survey)"
                    >
                      <i class="fa fa-close icon-close"></i>
                    </button>
                    <div>
                      <p class="cpi-text-warning">
                        Please confirm the Override CPI value you have entered
                        is accurate.
                      </p>
                      <button
                        type="button"
                        class="btn btn-primary confirm-button cpiConfirmButton"
                        (click)="userAcceptedCpi()"
                      >
                        Confirm
                      </button>
                    </div>
                  </span>
                </div>
                <div class="edit-button-group" *ngIf="survey.isEdit">
                  <div
                    class="fa fa-check glyphicon glyphicon-ok"
                    (click)="updateCurrentCpi(survey)"
                  ></div>
                  <div
                    class="fa fa-remove glyphicon glyphicon-remove"
                    (click)="hideWarningModal(survey)"
                  ></div>
                </div>
              </div>
              <div
                *ngIf="!currentCpiEditable.includes(survey.ps_survey_status)"
              >
                {{ survey.cpi_current | currency : survey.currencySymbol }}
              </div>
            </td>
            <td class="text-center">
              {{ survey.epc === undefined ? 'NA' : survey.epc }}
            </td>
            <td class="text-center">
              {{ survey.current_ir }}
            </td>
            <td class="text-center">
              {{ survey.total_starts ? survey.total_starts : 0 }}
            </td>
            <td class="text-center">
              {{ survey.last_24_starts ? survey.last_24_starts : 0 }}
            </td>
            <td class="text-center">
              {{ survey.in_progress === undefined ? '-' : survey.in_progress }}
            </td>
          </tr>
          <tr *ngIf="survey.create_status === CHILD_CREATION_STATUS.ERROR">
            <td>
              <div>
                <div class="tcErrorMessage"><b> ERROR </b></div>
              </div>
            </td>
            <td colspan="12">
              <p class="text-center">
                We have encountered an error trying to launch this Traffic
                Channel.<br />
                Please review the Audience of the Parent Project and re-save if
                there are any discrepancies.If the problem persists please reach
                out to your Client Success team.
              </p>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</ng-container>

<!-- MC tab but survey is not a MC project -->
<ng-container *ngIf="!isTCTab && isSingleCountrySurvey">
  <div>
    <p class="text-center m-0 text-uppercase text-decor">
      <strong>This is a single country survey</strong>
    </p>
  </div>
</ng-container>
