import { Component, OnInit } from '@angular/core';
import { AdjustmentsReconciliationServiceService } from './adjustments-reconciliation-service/adjustments-reconciliation-service.service';
import {
  Tab,
  GetAdjustmentFilesDataResponse,
  Reconcilation,
  ManualFix,
} from './adjustments-reconciliation-service/adjustments-reconciliation-service.interface';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'ps-adjustments-reconciliation',
  templateUrl: './adjustments-reconciliation.component.html',
  styleUrls: ['./adjustments-reconciliation.component.css'],
})
export class AdjustmentsReconciliationComponent implements OnInit {
  public showReconData: boolean = true;
  public showManualFixData: boolean = false;
  list = [
    {
      title: 'RECONCILIATION',
      active: true,
    },
    {
      title: 'MANUAL FIX',
      active: false,
    },
  ];

  public userId!: number;
  public userName!: string;
  public operationType: string = 'RECON';
  public adjustmentsReconciliations!: Array<Reconcilation>;
  public adjustmentsManualFix!: Array<ManualFix>;
  constructor(
    private _adjustmentReconciliationService: AdjustmentsReconciliationServiceService,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    if (this._auth.user?.id) {
      this.userId = this._auth.user.id;
      this.userName = this._auth.user.usrName;
    }
    this.getAdjustmentsFilesData(this.userId);
    if (this._auth.userType !== 'operator') {
      this.tabs.splice(1, 1);
    }
  }
  public tabs: Tab[] = this.list;
  public activeTab: Tab = this.tabs[0];

  checkActiveTab(activeIndex: number) {
    this.activeTab = this.tabs[activeIndex];
    this.getTabContent(activeIndex);
  }

  getTabContent(tabId: number) {
    if (tabId === 1) {
      this.operationType = 'MF';
      this.showReconData = false;
      this.showManualFixData = true;
    } else {
      this.operationType = 'RECON';
      this.showReconData = true;
      this.showManualFixData = false;
    }
  }

  getAdjustmentsFilesData(id: number) {
    this._adjustmentReconciliationService
      .getAdjustmentsFilesData(id)
      .subscribe((response: GetAdjustmentFilesDataResponse) => {
        this.adjustmentsReconciliations = response.reconciliations;
        this.adjustmentsManualFix = response.manualFixes;
      });
  }
}
