import { Component, OnDestroy } from '@angular/core';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import * as fileSaver from 'file-saver';
import { ExternalBuyerMappingsService } from './external-buyer-mappings.service';
import { Constants } from '../operator-settings-constants';
import {
  ExternalBuyer,
  UploadMappingsResponse,
} from './external-buyer-mappings.interface';
import { notifyMessage } from '../../constants/notify-message';

@Component({
  selector: 'ps-external-buyer-mappings',
  templateUrl: './external-buyer-mappings.component.html',
  styleUrls: ['./external-buyer-mappings.component.css'],
})
export class ExternalBuyerMappingsComponent implements OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  externalBuyers: ExternalBuyer[] = Constants.EXTERNAL_BUYERS;
  selectedExternalBuyer: ExternalBuyer | undefined;

  constructor(
    private _toastr: ToasterService,
    private _externalBuyerMappingsService: ExternalBuyerMappingsService
  ) {}

  selectBuyer(buyer: ExternalBuyer) {
    this.selectedExternalBuyer = buyer;
  }

  uploadMappings(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isValidFile = this._validateFileExtention(file);
    if (this.selectedExternalBuyer && isValidFile) {
      const fdata = new FormData();
      if (this.selectedExternalBuyer.key) {
        fdata.append('key', this.selectedExternalBuyer.key);
      }
      fdata.append('mappings', file);
      const mappings$ = this._externalBuyerMappingsService
        .uploadMappings(fdata, this.selectedExternalBuyer.id)
        .subscribe(
          (response: UploadMappingsResponse) => {
            const { count = 0 } = response;
            this._toastr.success(
              `Uploaded mappings for ${count} qualifications`
            );
          },
          ({ error }) => {
            const message =
              error.message ?? notifyMessage.errorMessage.SERVER_ERROR;
            this._toastr.error(message);
          }
        );
      this._subscriptions.add(mappings$);
    }
  }

  private _validateFileExtention(file: File) {
    const validExt = new Array('.xls', '.xlsx', '.csv');
    if (!file) {
      this._toastr.error(notifyMessage.errorMessage.FILE_UPLOAD_MISSING);
      return false;
    }
    const fileExt = file.name.substring(file.name.lastIndexOf('.'));
    if (validExt.indexOf(fileExt) < 0) {
      this._toastr.error(
        notifyMessage.errorMessage.FILE_UPLOAD_ASK_EXTERNAL_BUYER
      );
      return false;
    }
    return true;
  }

  downloadMappings() {
    if (this.selectedExternalBuyer) {
      const mappings$ = this._externalBuyerMappingsService
        .downloadMappings(this.selectedExternalBuyer.id)
        .subscribe(
          (result: Blob) => {
            if (this.selectedExternalBuyer) {
              fileSaver.saveAs(
                result,
                this.selectedExternalBuyer.downloadFileName
              );
            }
          },
          ({
            data: {
              message = notifyMessage.errorMessage.FILE_DOWNLOAD_FAILED,
            } = {},
          } = {}) => {
            this._toastr.error(message);
          }
        );
      this._subscriptions.add(mappings$);
    }
  }

  downloadSampleMappings() {
    if (this.selectedExternalBuyer && this.selectedExternalBuyer.templatePath) {
      window.open(this.selectedExternalBuyer.templatePath, '_blank');
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
