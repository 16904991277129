<main
  class="container mb-5"
  *ngIf="
    multiCountryMappingFormArray.length ||
    isUsedInDefaultMapping ||
    noMappingEnableNext
  "
>
  <div
    *ngFor="
      let multiCountryMappingFormArray of getMultiCountryMappingControls();
      let id = index
    "
  >
    <div class="mb-3 mt-3">
      <h2
        class="text-center"
        *ngIf="
          getMultiCountryMappingIndexControl(id, 0) && !isUsedInDefaultMapping
        "
      >
        CONDITION MAPPING -
        {{ getMultiCountryMappingIndexControl(id, 0).value?.locale }}
      </h2>
      <h2
        class="text-center"
        *ngIf="
          getMultiCountryMappingIndexControl(id, 0) && isUsedInDefaultMapping
        "
      >
        DECIPHER MAPPINGS -
        {{ getMultiCountryMappingIndexControl(id, 0).value?.locale }}
      </h2>
    </div>
    <div
      *ngFor="
        let mappingForm of getMultiCountryMappingControl(id);
        let i = index
      "
    >
      <form [formGroup]="mappingForm">
        <div class="row survey_form">
          <div class="col-md-3 form-group">
            <label>DECIPHER QUESTION ID</label>
            <input
              type="text"
              class="form-control disabled-input"
              name="decipherQuestionId"
              readonly="true"
              formControlName="decipherQuestionId"
              *ngIf="!isUsedInDefaultMapping"
            />
            <input
              type="text"
              class="form-control"
              name="decipherQuestionId"
              formControlName="decipherQuestionId"
              *ngIf="isUsedInDefaultMapping"
            />
          </div>
          <div class="col-md-7 form-group" formArrayName="qualificationCodes">
            <label>PURESPECTRUM QUALIFICATION</label>
            <div
              class="row"
              *ngFor="
                let qualification of getQualificationControls(
                  getMultiCountryMappingIndexControl(id, i)
                );
                let qualIndex = index
              "
            >
              <ps-ui-select-dropdown
                class="col-md-11 mb-1"
                [dropdownItems]="localeQualifications[mappingForm.value.locale]"
                [label]="
                  findQualification(
                    qualification.value,
                    mappingForm.value.locale
                  )?.desc
                "
                [field]="'desc'"
                (selectedItem)="
                  setQualification(mappingForm, qualIndex, $event)
                "
                [addNewItem]="newQual"
                [addNewItemDropDown]="addItemObject"
              ></ps-ui-select-dropdown>
              <a class="col-md-1 circle-icon">
                <i
                  *ngIf="qualIndex === 0"
                  class="fa fa-plus-circle"
                  aria-hidden="true"
                  (click)="addQualification(mappingForm)"
                ></i>
                <i
                  *ngIf="qualIndex !== 0"
                  class="fa fa-times-circle"
                  aria-hidden="true"
                  (click)="removeQualification(mappingForm, qualIndex)"
                ></i>
              </a>
            </div>
          </div>
          <div class="col-md-2 form-group">
            <label>CONDITION MAPPING</label>
            <div class="condition-mapping-child">
              <a
                class="modal-oppener-icon"
                (click)="openMappingModal(mappingForm)"
              >
                <i class="fa fa-angle-right" aria-hidden="true"></i
              ></a>
              &nbsp;
              <input
                *ngIf="!isUsedInDefaultMapping"
                type="checkbox"
                class="mappingCheckbox"
                name="active"
                formControlName="active"
                (change)="validateActive(mappingForm, true)"
              />
              <a
                *ngIf="
                  isUsedInDefaultMapping &&
                  i !== getMultiCountryMappingControl(id).length - 1
                "
                (click)="removeMapping(id, i)"
              >
                <i class="fa fa-times" aria-hidden="true"></i
              ></a>
              <a
                *ngIf="
                  isUsedInDefaultMapping &&
                  i === getMultiCountryMappingControl(id).length - 1
                "
                (click)="addEmptyMapping(id, mappingForm.value.locale)"
              >
                <i class="fa fa-plus" aria-hidden="true"></i
              ></a>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="formButton">
      <button
        psUiButton
        (click)="
          saveDecipherMappings(
            id,
            getMultiCountryMappingIndexControl(id, 0).value?.locale
          )
        "
        *ngIf="
          isUsedInDefaultMapping && getMultiCountryMappingIndexControl(id, 0)
        "
      >
        Save Decipher Mappings
      </button>
    </div>
  </div>
  <div class="formButton">
    <button
      psUiButton
      type="button"
      (click)="submit()"
      *ngIf="!isUsedInDefaultMapping"
    >
      Next
    </button>
  </div>
</main>
