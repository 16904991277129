import { Component, OnDestroy } from '@angular/core';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import * as fileSaver from 'file-saver';
import { IPSOSMappingsService } from './ipsos-mappings.service';
import { Constants } from '../operator-settings-constants';
import { IPSOS, UploadMappingsResponse } from './ipsos-mappings.interface';
import { notifyMessage } from '../../constants/notify-message';

@Component({
  selector: 'ps-ipsos-mappings',
  templateUrl: './ipsos-mappings.component.html',
  styleUrls: ['./ipsos-mappings.component.css'],
})
export class IPSOSMappingsComponent implements OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  ipsosInfo: IPSOS = Constants.IPSOS;

  constructor(
    private _toastr: ToasterService,
    private _ipsosMappingsService: IPSOSMappingsService
  ) {}

  uploadMappings(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isValidFile = this._validateFileExtention(file);
    if (isValidFile) {
      const fdata = new FormData();
      fdata.append('key', this.ipsosInfo.key);
      fdata.append('mappings', file);
      const mappings$ = this._ipsosMappingsService
        .uploadMappings(fdata)
        .subscribe(
          (response: UploadMappingsResponse) => {
            const { count = 0 } = response;
            this._toastr.success(
              `Uploaded mappings for ${count} qualifications`
            );
          },
          ({ error }) => {
            const message =
              error.message ?? notifyMessage.errorMessage.SERVER_ERROR;
            this._toastr.error(message);
          }
        );
      this._subscriptions.add(mappings$);
    }
  }

  private _validateFileExtention(file: File) {
    const validExt = new Array('.csv');
    if (!file) {
      this._toastr.error(notifyMessage.errorMessage.FILE_UPLOAD_MISSING);
      return false;
    }
    const fileExt = file.name.substring(file.name.lastIndexOf('.'));
    if (validExt.indexOf(fileExt) < 0) {
      this._toastr.error(notifyMessage.errorMessage.FILE_UPLOAD_ASK_IPSOS);
      return false;
    }
    return true;
  }

  downloadMappings() {
    const mappings$ = this._ipsosMappingsService.downloadMappings().subscribe(
      (result: Blob) => {
        fileSaver.saveAs(result, this.ipsosInfo.downloadFileName);
      },
      ({
        data: {
          message = notifyMessage.errorMessage.FILE_DOWNLOAD_FAILED,
        } = {},
      } = {}) => {
        this._toastr.error(message);
      }
    );
    this._subscriptions.add(mappings$);
  }

  downloadSampleMappings() {
    window.open(this.ipsosInfo.templatePath, '_blank');
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
