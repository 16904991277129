import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { PutResetPasswordResponse } from './reset-password.interface';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  updatePassword(cmpId: number, data: any) {
    return this._http.put<PutResetPasswordResponse>(
      `${this._baseUrl}/updateuserpassword/${cmpId}`,
      data
    );
  }
}
