import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@purespectrum1/ui/modal';
import { ServiceAccountModalComponent } from '../../layout/header/user-menu/service-account-modal/service-account-modal.component';
import { AuthService } from '../../auth/auth.service';
import { AuthState } from '@purespectrum1/ui/marketplace/shared/interfaces/auth.interface';

@Component({
  selector: 'ps-recontact-survey',
  templateUrl: './recontact-survey.component.html',
  styleUrls: ['./recontact-survey.component.css'],
})
export class RecontactSurveyComponent {
  public auth: AuthState = this._auth.getMarketplaceAuth;
  constructor(
    private _router: Router,
    private _modal: ModalService,
    private _auth: AuthService
  ) {}

  goToDashboard() {
    this._router.navigateByUrl('/dashboard');
  }

  selectBuyer(open: boolean) {
    if (open) {
      this._modal.open(ServiceAccountModalComponent, {});
    }
  }
}
