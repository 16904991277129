import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

import { ButtonModule } from '@purespectrum1/ui/button';
import { LoginComponent } from './login/login.component';
import { GuestGuard } from './guest.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { InvalidPasswordLinkComponent } from './invalid-password-link/invalid-password-link.component';
import { LayoutModule } from '../layout/layout.module';
import { AccessDeniedComponent } from '../access-denied/access-denied.component';
import { AuthGuard } from './auth.guard';
@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    InvalidPasswordLinkComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    LayoutModule,
    RouterModule.forChild([
      { path: 'login', component: LoginComponent, canActivate: [GuestGuard] },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'resetforgotPassword/:token',
        component: ResetPasswordComponent,
      },
      {
        path: 'invalid-password/:reason',
        component: InvalidPasswordLinkComponent,
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
        canActivate: [AuthGuard],
      },
    ]),
    ButtonModule,
  ],
})
export class AuthModule {}
