import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { StcReportReponse, StcReport } from './stc-report.interface';

@Injectable({
  providedIn: 'root',
})
export class StcReportService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  getStcReport(stcReportData: StcReport): Observable<StcReportReponse> {
    let queryStr = '';

    for (let [key, value] of Object.entries(stcReportData)) {
      if (value) {
        queryStr += `&${key}=${value}`;
      }
    }

    return this._http.get<StcReportReponse>(
      `${this._baseUrl}/reports/surveystcReport?${queryStr}`
    );
  }
}
