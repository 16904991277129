<div class="select-operator-div">
  <div *ngIf="!currentProjectManagerAssigned">
    <a><span>Assign Operator Project Manager</span></a>
    <div class="project-manager-button">
      <button class="menu-btn" [psUiDropdownToggleFor]="projectManagerMenu">
        <img
          alt=" dropdown-arrow"
          class="dropdown-arrow"
          src="../../../assets/img/down-arrow.png"
        />
      </button>
      <ps-ui-dropdown
        class="dropdown-operator-select"
        #projectManagerMenu="psUiDropdown"
      >
        <div class="operator-select-dropdown">
          <div class="col-sm-12 operator-search">
            <div class="input-group input-group-sm operator-search-bar">
              <span class="input-group-addon col-sm-2"
                ><i class="fa fa-search" aria-hidden="true"></i>
                <span class="glyphicon glyphicon-search"></span
              ></span>
              <input
                class="col-sm-10 input-group-type"
                [(ngModel)]="searchText"
                (click)="setupSearchInput($event)"
                type="text"
              />
              <hr />
            </div>
            <table class="col-sm-12">
              <th>
                <p class="current-user" (click)="setThisOperator()">
                  Assign Current User
                </p>
                <hr />
              </th>
              <tbody>
                <tr
                  *ngFor="
                    let operator of allOperatorUser
                      | searchFilter : searchText : 'name'
                  "
                >
                  <td class="operator-name-li" (click)="setOperator(operator)">
                    {{ operator.name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ps-ui-dropdown>
    </div>
  </div>
  <div *ngIf="currentProjectManagerAssigned">
    <span class="manager-identifier">Operator</span>
    <a>
      <span
        >{{ currentProjectManager }}
        <i
          (click)="removeOperator()"
          class="fa fa-times-circle del-operator"
        ></i></span
    ></a>
  </div>
</div>
