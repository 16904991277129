<ng-container *ngIf="state.some()">
  <div class="bulk-edit-container" [class.show]="!show" #bulkEditContainer>
    <div class="wrapper">
      <div class="mb-3">
        <div class="bulk-edit-title">
          <p>Bulk Summary</p>
          <i
            class="accordion-chevron fa fa-caret-up"
            (click)="onSelectedSettings()"
          ></i>
        </div>
        <small class="bulk-edit-sub-title"
          >Surveys Selected {{ state.length() | number }} over
          {{ total | number }}</small
        >
      </div>
      <div class="bulk-edit-content" *ngIf="show">
        <ps-ui-card-row>
          <ps-ui-fielded-card [content]="statsFormatted"></ps-ui-fielded-card>
          <ps-ui-total-cost-card
            [content]="statsFormatted"
            [userRole]="userType"
          ></ps-ui-total-cost-card>
          <ps-ui-cpi-card
            [content]="statsFormatted"
            [userRole]="userType"
          ></ps-ui-cpi-card>
          <ps-ui-loi-card [content]="statsFormatted"></ps-ui-loi-card>
          <ps-bulk-options
            [options]="(this.dropdownOptions$ | async) || []"
            [userRole]="userType"
            (selectedOption)="onSelectedOption($event)"
          ></ps-bulk-options>
        </ps-ui-card-row>
      </div>
      <ps-bulk-edit-container
        [surveys]="state.selected()"
        [option]="selectedOption || defaultOption"
        (confirmation)="onConfirmation($event)"
        (report)="onReport($event)"
      ></ps-bulk-edit-container>
    </div>
  </div>
</ng-container>
