import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { ModalService } from '@purespectrum1/ui/modal';
import { SurveyStatus } from '../../../utils/survey-status';
import { SurveyTableInterface } from '../../interfaces/survey-table.interface';
import { STATUS_BUTTONS } from '../../../constants/status-buttons';
import { INVOICE_TYPE } from '../../../constants/invoice-type';
import { Constants } from '../../../dashboard/dashboard-constants';
import { ChangeSurveyStatusModalComponent } from '../../../dashboard/change-survey-status-modal/change-survey-status-modal.component';
import { CommonService } from '../../services/common-service/common-service';
import { BuyerApiService } from '../../../shared/services/buyer-api/survey.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { notifyMessage } from '../../../constants/notify-message';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
import { LayoutConstants } from '../../../layout/layout-constants';

@Component({
  selector: 'ps-surveys-table',
  templateUrl: './surveys-table.component.html',
  styleUrls: ['./surveys-table.component.css'],
})
export class SurveysTableComponent implements OnInit, OnDestroy {
  @Input() tableHeaders: { label: string; orderKey: string }[] = [];
  @Input() surveys: Array<SurveyTableInterface> = [];
  @Input() isTCTab: boolean = false;
  @Input() isSingleCountrySurvey: boolean = false;
  @Input() scrollable = true;
  public statusButtons = STATUS_BUTTONS;
  public openSurveyId?: number;
  public userType!: string;
  public editStateModal: boolean = false;
  public currentCpiEditable = [SurveyStatus.Live, SurveyStatus.Paused];
  private _subscriptions: Subscription = new Subscription();
  public CHILD_CREATION_STATUS = Constants.CHILD_CREATION_STATUS;
  public showWarningModal: boolean = false;
  public cpiAccepted: boolean = false;
  public cpi_current_old!: number;

  surveyStatus = SurveyStatus;

  constructor(
    private _route: Router,
    private _modal: ModalService,
    private _commanService: CommonService,
    private _activateRoute: ActivatedRoute,
    private _auth: AuthService,
    private _buyerApiService: BuyerApiService,
    private _toastr: ToasterService,
    private _eventEmitterService: EventEmitterService
  ) {}

  ngOnInit(): void {
    this.userType = this._auth.userType;
    this._activateRoute.params.subscribe((params) => {
      this.openSurveyId = params.id;
    });
    this._subscriptions.add(
      this._eventEmitterService.changeSurveyStatusEmitter.subscribe((data) => {
        this._manageSurveys(data);
      })
    );
  }

  public verifyCpiToShowWarning(event: any) {
    if (event.target.value > LayoutConstants.MAX_CPI_THRESHOLD) {
      this.showWarningModal = true;
      this.cpiAccepted = false;
    }
  }
  public userAcceptedCpi() {
    this.cpiAccepted = true;
    this.showWarningModal = false;
  }
  public hideWarningModal(survey: SurveyTableInterface) {
    this.showWarningModal = false;
    survey.isEdit = false;
    survey.cpi_current = this.cpi_current_old;
  }

  public editMode(survey: any) {
    survey.isEdit = true;
    this.cpi_current_old = survey.cpi_current;
  }

  private _manageSurveys(data: {
    surveyId: number;
    status: number;
    channelType: string;
  }) {
    if (data.status === SurveyStatus.Live) {
      this.surveys.forEach((survey) => {
        if (
          data.status === SurveyStatus.Live &&
          Number(survey.ps_survey_id) == data.surveyId
        ) {
          survey.ps_survey_status = data.status;
        }
      });
    } else {
      this.surveys.forEach((survey) => {
        if (
          !data.channelType &&
          [
            SurveyStatus.Paused,
            SurveyStatus.Live,
            SurveyStatus.Closed,
          ].includes(survey.ps_survey_status)
        ) {
          survey.ps_survey_status = data.status;
        }
      });
    }
  }

  public redirectToSurvey(surveyId: number) {
    this._route.navigateByUrl(`/dashboard/${surveyId}`);
  }

  openStatusModal(surveyId: number, status: string) {
    const surveyUpdateData = { surveyId: surveyId, status: status };
    const modalRef = this._modal.open(ChangeSurveyStatusModalComponent, {
      data: surveyUpdateData,
      width: '450px',
      height: '18.75rem',
    });
    const surveyPsStatus = Number(this._commanService.getSurveyStatus(status));
    modalRef.onClose$.subscribe((msg) => {
      if (msg === Constants.STATUS_CHANGE_MESSAGE) {
        let surveyType = '';
        this.surveys.forEach((survey: SurveyTableInterface) => {
          if (survey.ps_survey_id === surveyId) {
            surveyType = survey.survey_type;
            survey.ps_survey_status = surveyPsStatus;
          }
        });

        if (surveyType == 'Parent' && surveyPsStatus != SurveyStatus.Live) {
          this.surveys.forEach((survey: SurveyTableInterface) => {
            if (
              [SurveyStatus.Paused, SurveyStatus.Live].includes(
                survey.ps_survey_status
              )
            ) {
              survey.ps_survey_status = surveyPsStatus;
            }
          });
        }
        if (this.openSurveyId == surveyId) {
          this._commanService.onChangeSurveyStatus({
            surveyId: surveyId,
            status: Number(this._commanService.getSurveyStatus(status)),
          });
        }
      }
    });
  }

  public checkDisableSurveyStatus(survey: SurveyTableInterface) {
    return (
      [SurveyStatus.Draft, SurveyStatus.Invoice].indexOf(
        survey.ps_survey_status
      ) > -1 ||
      (survey.ps_survey_status === SurveyStatus.Closed &&
        (survey?.invoiceType === INVOICE_TYPE.MONTHLY || this.isTCTab))
    );
  }

  public updateCurrentCpi(survey: SurveyTableInterface) {
    if (
      survey.cpi_current > LayoutConstants.MAX_CPI_THRESHOLD &&
      this.cpiAccepted === false
    ) {
      return;
    } else {
      this._buyerApiService
        .updateTMCpi(survey.ps_survey_id, survey.cpi_current)
        .subscribe((resp) => {
          survey.isEdit = false;
          this._toastr.success(notifyMessage.successMessage.CPI_UPDATED);
        });
      this.cpiAccepted = false;
    }
  }

  public validateQbpCpiQty(event: any) {
    this.cpiAccepted = false;
    let charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  counter(i: number) {
    return Array.from(Array(i).keys());
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
