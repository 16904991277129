import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TabsModule } from '@purespectrum1/ui/tabs';
import { ModalModule } from '@purespectrum1/ui/modal';
import { SliderModule } from '@purespectrum1/ui/slider';
import { ButtonModule } from '@purespectrum1/ui/button';
import { SelectDropdownModule } from '@purespectrum1/ui/select-dropdown';
import { RangeModule } from '@purespectrum1/ui/range';

import { AuthGuard } from '../auth/auth.guard';

import { InputRestrictionModule } from '../shared/ui/input-restriction/input-restriction.module';

import { CreateSurveysComponent } from './create-surveys.component';
import { RecontactSurveyComponent } from './recontact-survey/recontact-survey.component';
import { ChooseSuppliersComponent } from './choose-suppliers/choose-suppliers.component';
import { AccordionModule } from '@purespectrum1/ui/accordion';
import {
  CreateSurveyModule as MarketplaceCreateSurveyModule,
  LayoutModule as MarketplaceLayoutModule,
} from '@purespectrum1/ui/marketplace';
import { EnvironmentConfig } from '@purespectrum1/ui/marketplace/shared/interfaces/environment.interface';
import { environment } from '../../environments/environment';
import { GoogleTagManagerModule } from '@purespectrum1/ui/tracking';

@NgModule({
  declarations: [
    CreateSurveysComponent,
    RecontactSurveyComponent,
    ChooseSuppliersComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SelectDropdownModule,
    RangeModule,
    ModalModule,
    AccordionModule,
    RouterModule.forChild([
      {
        path: 'create-surveys',
        canActivate: [AuthGuard],
        component: CreateSurveysComponent,
      },
      {
        path: 'surveys',
        canActivate: [AuthGuard],
        children: [
          {
            path: ':id/edit',
            component: CreateSurveysComponent,
          },
          {
            path: ':id/edit/suppliers',
            component: ChooseSuppliersComponent,
          },
        ],
      },
      {
        path: 'recontact-survey',
        canActivate: [AuthGuard],
        component: RecontactSurveyComponent,
      },
      {
        path: 'recontact-survey/:id',
        canActivate: [AuthGuard],
        component: RecontactSurveyComponent,
      },
    ]),
    TabsModule,
    SliderModule,
    MarketplaceLayoutModule,
    InputRestrictionModule,
    ButtonModule,
    MarketplaceCreateSurveyModule.forChild({
      environment,
    } as EnvironmentConfig),
    GoogleTagManagerModule,
  ],
  exports: [],
})
export class CreateSurveyModule {}
