import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  GetBuyerConfigResponse,
  UpdateBuyerConfigResponse,
  GetBuyerConfigByBuyerIdResponse,
} from '../operator-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class BuyerConfigService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  getBuyerConfig() {
    return this._http.get<GetBuyerConfigResponse>(
      `${this._baseUrl}/buyerConfigSettings`
    );
  }

  getBuyerConfigByBuyerId(buyerId: number) {
    return this._http.get<GetBuyerConfigByBuyerIdResponse>(
      `${this._baseUrl}/buyers/v2/configs/${buyerId}`
    );
  }

  updateBuyerConfig(id: string, data: UpdateBuyerConfigData) {
    return this._http.put<UpdateBuyerConfigResponse>(
      `${this._baseUrl}/buyerConfigSettings/${id}`,
      data
    );
  }
}

interface UpdateBuyerConfigData {
  id: number;
  config: string;
  enable: boolean;
}
