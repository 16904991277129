import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { PsidReportRequest, PsidReportResponse } from './psid-report.interface';

@Injectable({
  providedIn: 'root',
})
export class PsidReportService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  getPsidTransIdreport(reportType: string, transValues: PsidReportRequest) {
    let queryStr = '';
    for (const [key, value] of Object.entries(transValues)) {
      if (value) {
        queryStr += `&${key}=${value}`;
      }
    }
    return this._http
      .get<PsidReportResponse>(
        `${this._baseUrl}/reports/psidReport?reportTypes=${reportType}${queryStr}`
      )
      .pipe(map((response) => response.result));
  }
}
