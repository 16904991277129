import { Injectable } from '@angular/core';
import { notifyMessage } from '../constants/notify-message';
import { ToasterService } from '@purespectrum1/ui/toaster-service';

@Injectable({
  providedIn: 'root',
})
export class OperatorSettingsService {
  constructor(private _toastr: ToasterService) {}
  validateFileExtention(file: File, validExtentions: Array<string>) {
    if (!file) {
      this._toastr.error(notifyMessage.errorMessage.FILE_UPLOAD_MISSING);
      return false;
    }
    const fileExt = file.name.substring(file.name.lastIndexOf('.'));
    if (validExtentions.indexOf(fileExt) < 0) {
      this._toastr.error(
        notifyMessage.errorMessage.FILE_UPLOAD_ERROR_GENERIC.replace(
          '<FILE_EXTENSION>',
          validExtentions.join('/')
        )
      );
      return false;
    }
    return true;
  }
}
