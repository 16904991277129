import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiCountryInfoListingComponent } from './multi-country-info-listing.component';
import { MultiCountryColumnContentDirective } from './directives/multi-county-column-content.directive';
import { MultiCountryRowHoverContentDirective } from './directives/multi-country-row-hover-content.directive';

@NgModule({
  declarations: [
    MultiCountryInfoListingComponent,
    MultiCountryColumnContentDirective,
    MultiCountryRowHoverContentDirective,
  ],
  imports: [CommonModule],
  exports: [
    MultiCountryInfoListingComponent,
    MultiCountryColumnContentDirective,
    MultiCountryRowHoverContentDirective,
  ],
})
export class MultiCountryInfoListingModule {}
