import { CurrencyExchange } from '../shared/types/currency-service-interface';

export class LocaleToUSDConversion {
  constructor(
    private readonly _localCurrencyValue: number,
    private readonly _exchangeRateObj = <CurrencyExchange>{}
  ) {}

  public convert(): number {
    if (
      !this._exchangeRateObj ||
      !this._exchangeRateObj.rate ||
      !this._exchangeRateObj.CRRate
    ) {
      return this._localCurrencyValue;
    }
    const { rate: exchangeRate, CRRate } = this._exchangeRateObj;
    const AEX = this._localCurrencyValue / exchangeRate;
    return Number(AEX - AEX * CRRate);
  }
}
