import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { AuthService } from '../../auth/auth.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { DecipherService } from '../../shared/services/decipher/decipher.service';
import { CommonService } from '../../shared/services/common-service/common-service';
import {
  CategoriesInterface,
  CountryInterface,
  LanguageInterface,
} from '../../shared/interfaces/create-surveys.interface';
import {
  DecipherSettingsResponse,
  DecipherSurveyData,
  DecipherMetaData,
  DecipherURI,
  Directory,
  SelectedSurvey,
  Locale,
  SurveyDirectory,
} from '../../shared/services/decipher/decipher.interface';
import { notifyMessage } from '../../constants/notify-message';

@Component({
  selector: 'ps-import-decipher-surveys',
  templateUrl: './import-decipher-surveys.component.html',
  styleUrls: ['./import-decipher-surveys.component.css'],
})
export class ImportDecipherSurveysComponent implements OnInit, OnDestroy {
  @ViewChild('uriDropdown') uriDropdownSearch: any;
  @ViewChild('directoryDropdown') directoryDropdownSearch: any;
  @ViewChild('surveyDropdown') surveyDropdownSearch: any;
  @ViewChild('categoryDropdown') categoryDropdownSearch: any;
  @ViewChild('countryDropdown') countryDropdownSearch: any;
  @ViewChild('languageDropdown') languageDropdownSearch: any;
  form!: FormGroup;
  private _subscriptions: Subscription = new Subscription();
  public categories: CategoriesInterface[] = [];
  public countries: CountryInterface[] = [];
  public languages: LanguageInterface[] = [];
  public selectedCountriesLength: number = 0;
  public decipherSettings!: DecipherMetaData;
  public decipherSurveys: DecipherSurveyData[] = [];
  public decipherURI: DecipherURI[] = [];
  public directory: Directory[] = [];
  public surveyDirectory: string = '';
  public selectedDecipher: string = '';
  public selectedSurvey!: SelectedSurvey;
  public multiSelect: boolean = false;
  public maxNoOfCompletes: number = 100000;
  constructor(
    private _fb: FormBuilder,
    private _toastr: ToasterService,
    private _authService: AuthService,
    private _commonService: CommonService,
    private _decipherService: DecipherService
  ) {}

  ngOnInit(): void {
    this._initForm();
    this._getDecipherURI();
    this._getCountries();
    this._getCategories();
  }

  private _getDecipherURI() {
    const { cmp } = this._authService.user!;
    this._decipherService.getDecipherURI(cmp).subscribe(
      (response) => {
        this.decipherURI = response.data;
      },
      (err) => {
        this._toastr.error(err.error.msg);
      }
    );
  }

  private _getDirectory() {
    const { cmp } = this._authService.user!;
    const uri =
      this.form.value?.decipherURI?.value || this.decipherSettings?.selectedUri;
    this._decipherService.getDirectory(cmp, uri).subscribe(
      (response) => {
        this.directory = response.data;
      },
      (err) => {
        this._toastr.error(err.error.msg);
      }
    );
  }

  private _getDecipherSurveys() {
    const { cmp } = this._authService.user!;
    const decipherSurveysSub = this._decipherService
      .getDecipherSurveys(
        cmp,
        this.form.value.decipherURI.value,
        this.form.value.surveyDirectory.name
      )
      .subscribe(
        (decipherSurveys) => {
          this.decipherSurveys = decipherSurveys.data;
        },
        (err) => {
          this._toastr.error(err.error.msg);
        }
      );
    this._subscriptions.add(decipherSurveysSub);
  }

  intValidate = (control: AbstractControl): ValidationErrors | null => {
    if (/^\d*$/.test(control.value)) {
      return null;
    }
    return { integerError: true };
  };

  private _initForm() {
    this.form = this._fb.group({
      decipherURI: [null, [Validators.required]],
      surveyDirectory: [null, [Validators.required]],
      selectedSurvey: [null, [Validators.required]],
      selectedCategory: [null, [Validators.required]],
      selectedCountries: [null, [Validators.required]],
      selectedLanguage: [null, [Validators.required]],
      numberOfCompletes: [
        0,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(this.maxNoOfCompletes),
          this.intValidate,
        ],
      ],
      lengthOfSurvey: [
        0,
        [Validators.required, Validators.min(1), this.intValidate],
      ],
      incidence: [
        0,
        [Validators.required, Validators.min(1), this.intValidate],
      ],
      fieldTime: [
        0,
        [Validators.required, Validators.min(1), this.intValidate],
      ],
    });
  }

  private _getCategories() {
    const categories = this._commonService.getCategories().subscribe(
      (categorysData) => {
        this.categories = categorysData.sample.values;
      },
      (err) => {
        this._toastr.error(err.error.msg);
      }
    );
    this._subscriptions.add(categories);
  }

  private _getCountries() {
    const countries = this._commonService.getCountries().subscribe(
      (countriesData) => {
        this.countries = countriesData;
        this._getDecipherSettings();
      },
      (err) => {
        this._toastr.error(err.error.msg);
      }
    );
    this._subscriptions.add(countries);
  }

  private _patchCountryLanguage = (
    countryCode: string,
    languageCode: string,
    selectedCountries: CountryInterface[] = []
  ) => {
    const foundCountry = this.countries.find(
      (country) => country.short_Code === countryCode
    );
    const isDuplicate = selectedCountries.some(
      (obj) => obj.short_Code === foundCountry?.short_Code
    );
    if (foundCountry && !isDuplicate) {
      selectedCountries.push(foundCountry);
    }
    this.languages = selectedCountries[0].lang;
    const selectedLang = this.multiSelect
      ? {}
      : this.languages.find((lang) => {
          return lang.transalte_code === languageCode;
        });
    const countries = this.multiSelect
      ? selectedCountries
      : selectedCountries[0];
    this.form.patchValue({
      selectedCountries: countries,
      selectedLanguage: selectedLang,
    });
  };

  private _getDecipherSettings() {
    const cmp = this._authService.user!.cmp;
    const decipherSettings$ = this._decipherService
      .getDecipherSettings(cmp)
      .subscribe(
        (response: DecipherSettingsResponse) => {
          this.decipherSettings = response.metaData;
          this._patchCountryLanguage(
            this.decipherSettings?.defaultLocale.split('_')[1],
            this.decipherSettings?.defaultLocale.split('_')[0]
          );
          this.directory = [{ name: this.decipherSettings?.directoryName }];
          if (this.decipherSettings.multiPath) {
            this._getDirectory();
          }
          this.form.patchValue({
            decipherURI: { value: this.decipherSettings?.selectedUri },
            surveyDirectory: { name: this.decipherSettings?.directoryName },
          });
          this.getSurveys(this.form.value.surveyDirectory);
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add(decipherSettings$);
  }

  multiSelectCountries = (eventData: SelectedSurvey) => {
    const selectCountries: CountryInterface[] = [];
    let countryWithNoLocale = '';
    this.multiSelect = eventData.locale.length > 1 ? true : false;
    eventData.locale.forEach((country: Locale) => {
      if (country.locale) {
        this._patchCountryLanguage(
          country.locale.split('_')[1],
          country.locale.split('_')[0],
          selectCountries
        );
      } else {
        countryWithNoLocale = countryWithNoLocale + ' ' + country.language;
      }
    });
    if (countryWithNoLocale) {
      this._toastr.warning(
        'No locale found for above country please add manually.',
        countryWithNoLocale
      );
    }
  };

  public patchFormBySurvey = (eventData: SelectedSurvey) => {
    this.selectedSurvey = eventData;
    this.multiSelectCountries(eventData);
    this.form.patchValue({
      selectedCategory: this.categories.find((category) => {
        return category.name === eventData.category;
      }),
      lengthOfSurvey: Math.ceil(eventData.questions / 3),
      incidence: 70,
      fieldTime: 3,
    });
    this._setDecipherCompletes();
    this.surveyDropdownSearch?.searchTextControl.reset();
  };

  private _setDecipherCompletes = async () => {
    const cmp = this._authService.user!.cmp;
    const {
      decipherURI: { value: uri },
    } = this.form.value;
    const { path } = this.selectedSurvey;
    const numberOfCompletes = await this._getDecipherRequiredCompletes(
      cmp,
      uri,
      path
    );
    if (numberOfCompletes) {
      this.form.patchValue({
        numberOfCompletes,
      });
    } else {
      this.form.patchValue({
        numberOfCompletes: null,
      });
    }
  };

  private _getDecipherRequiredCompletes = async (
    cmp: number,
    uri: string,
    surveyPath: string
  ) => {
    try {
      const response = await this._decipherService
        .getDecipherSurveyRequiredCompletes(cmp, uri, surveyPath)
        .toPromise();
      if (
        response.apiStatus == 'Success' &&
        response.data.requiredCompletes > 0
      ) {
        return response.data.requiredCompletes;
      }
      this._toastr.warning(
        notifyMessage.warningMessage.SURVEY_REQUIRED_COMPLETE_NOT_FOUND
      );
    } catch (e: any) {
      this._toastr.warning(e.error.msg);
    }
    return 0;
  };

  public getDirectory = () => {
    if (this.form.value.decipherURI) {
      this._getDirectory();
      this.form.patchValue({
        surveyDirectory: '',
        selectedSurvey: '',
        selectedCategory: '',
        numberOfCompletes: 0,
        lengthOfSurvey: 0,
        incidence: 0,
        fieldTime: 0,
      });
      this._patchCountryLanguage(
        this.decipherSettings?.defaultLocale.split('_')[1],
        this.decipherSettings?.defaultLocale.split('_')[0]
      );
    }
    this.uriDropdownSearch?.searchTextControl.reset();
  };

  public getSurveys = (surveyDirectory: SurveyDirectory) => {
    const dirName =
      surveyDirectory?.name || this.form.value.surveyDirectory.name;
    if (this.form.value.decipherURI && this.form.value.surveyDirectory) {
      this.form.patchValue({
        selectedSurvey: '',
        selectedCategory: '',
        surveyDirectory: { name: dirName },
        numberOfCompletes: 0,
        lengthOfSurvey: 0,
        incidence: 0,
        fieldTime: 0,
      });
      this._getDecipherSurveys();
      this._patchCountryLanguage(
        this.decipherSettings?.defaultLocale.split('_')[1],
        this.decipherSettings?.defaultLocale.split('_')[0]
      );
    }
    this.directoryDropdownSearch?.searchTextControl.reset();
  };

  public getLanguages = (selectedCountry: CountryInterface) => {
    this.languages = this.multiSelect ? [] : selectedCountry?.lang;
    this.form.patchValue({
      selectedLanguage: '',
      selectedCountries: selectedCountry,
    });
  };

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
