<ps-ui-modal class="modal-main-container">
  <ps-ui-modal-title>
    <h5 psUiModalTitle>PROJECT STATUS CHANGE</h5>
  </ps-ui-modal-title>

  <ps-ui-modal-body>
    <p class="mt-2 text-center">
      The project you are SENDING TO INVOICING shares a billing number with
      another survey(s).
    </p>
    <p class="my-2">Survey(s):</p>
    <div class="scrollable-content-container">
      <div>{{ data.surveyIds }}</div>
    </div>
    <p class="mt-4 labelText text-center">
      What surveys do you want to CLOSE & INVOICE?
    </p>
  </ps-ui-modal-body>

  <ps-ui-modal-footer>
    <button
      type="button"
      psUiButton
      color="secondary"
      class="btn btn-default leftBtn"
      (click)="close(modalConstants.BUTTON.THIS_AND_ALL_LISTED.ACTION)"
    >
      {{ data.thisAndAllBtnText }}
    </button>
    <button
      type="button"
      class="btn btn-default rightBtn"
      psUiButton
      (click)="close(modalConstants.BUTTON.ONLY_THIS.ACTION)"
    >
      {{ data.onlyThisBtnText }}
    </button>
  </ps-ui-modal-footer>
</ps-ui-modal>
