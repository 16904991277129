import { SurveyData } from '@purespectrum1/ui/survey-top-view/shared/survey.interface';
import { SurveyStats } from '../types';

interface FormatterState {
  format(surveyData: SurveyStats): SurveyData;
}

export class DefaultFormatterState {
  format(surveyData: SurveyData): SurveyData {
    return surveyData;
  }
}

export class CustomFormatterState implements FormatterState {
  format(surveyData: SurveyStats): SurveyData {
    const formattedData: SurveyData = {
      currencySymbol: surveyData.currency_symbol || '$',
      totalCostLabel: surveyData.totalCostLabel || 'Current',
      totalCost: surveyData.total_cost || { projected: 0, current: 0 },
      totalCostValue: surveyData.total_cost.current,
      cpi1Label: 'At Launch',
      cpi1Value: surveyData.cpi.launch,
      cpi2Label: 'Current',
      cpi2Value: surveyData.cpi.current,
      fielded: this._formatNumber(surveyData.fielded),
      loi: surveyData.loi || { launch: 0, overall: 0, recent: 0 },
      loiLabel: 'Recent',
      loiValue: this._formatNumber(surveyData.loi?.recent),
      manualFixCompltCount: this._formatNumber(
        surveyData.manual_fix_complt_count
      ),
      reconciliationCompleteCount: this._formatNumber(
        surveyData.rec_complt_count
      ),
      field_date_info: {
        creation_date: 0,
        days_in_field: 0,
        days_remaining: 0,
        end_date: 0,
      },
      goal: surveyData.goal,
      cpi: surveyData.cpi || {
        current: '',
        launch: '',
        projected: undefined,
        averageCpi: 0,
        averageACpi: 0,
      },
      incidenceLabel: '',
      incidenceValue: 0,
      incidence: {
        launch: 0,
        overall: 0,
        recent: 0,
      },
      companyId: 0,
      languageName: '',
      is_qbp_child_survey: false,
      click_balancing: 0,
      qbp: {
        enabled: false,
        surveyId: 0,
        selectedQuotaIds: [],
        quota_ids: [],
        category: '',
        cpi: 0,
      },
    };
    return formattedData;
  }

  private _formatNumber(value: number | undefined): number {
    return value || 0;
  }
}

export class CardsFormatter {
  constructor(private readonly _state: FormatterState) {}

  public format(surveyData: SurveyStats): SurveyData {
    return this._state.format(surveyData);
  }
}
