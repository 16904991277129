import { EachCountry } from '../../shared/interfaces/common-service.interface';
import { McSVPEachSurvey } from '../../shared/services/buyer-api/survey.interface';

export class McSvpCountryNameMapper {
  constructor(
    private readonly _mcSvpChildSurveys: McSVPEachSurvey[],
    private readonly _countries: EachCountry[]
  ) {}

  public map(): McSVPEachSurvey[] {
    return this._mcSvpChildSurveys.map((svpChildSurvey) =>
      this._mapper(svpChildSurvey)
    );
  }

  private _mapper(svpChildSurvey: McSVPEachSurvey): McSVPEachSurvey {
    const matchedCountry = this._countries.find(
      (country) =>
        country.short_Code === this._getCountryCode(svpChildSurvey.countryCode)
    );
    return {
      ...svpChildSurvey,
      ...{ countryName: matchedCountry?.name },
    };
  }

  private _getCountryCode(countryCode: string) {
    if (countryCode === 'GB') {
      countryCode = 'UK';
    }
    return countryCode;
  }
}
