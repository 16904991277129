import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import {
  AddDecipherSettingsRequest,
  BuyerURIRecords,
  DecipherURIResponse,
  DecipherUriResponse,
  DecipherSettingsResponse,
  DecipherUriUpdateResponse,
  GetDecipherSurveys,
  GetDecipherURI,
  GetDirectory,
  GetQuotasPayload,
  GetDecipherQuotas,
  GetMetadata,
  CreateSurveyResponse,
  DecipherMappingsPayload,
  DecipherMappingsResponse,
  CreateSurveyPayloadData,
  GetDecipherRequiredCompletes,
} from './decipher.interface';
import { BuyerQualificationsResponse } from '../buyer/buyer-response.interface';

@Injectable({
  providedIn: 'root',
})
export class DecipherService {
  private readonly _baseUrl = environment.pureSpectrum.decipherUrl;
  constructor(private _http: HttpClient) {}

  getBuyerQualifications(buyerId: number, locale: string) {
    return this._http.get<BuyerQualificationsResponse>(
      `${this._baseUrl}/v2/companies/${buyerId}/qualifications?locale=${locale}`
    );
  }

  addDecipherSettings(buyerId: number, payload: AddDecipherSettingsRequest) {
    return this._http.post<DecipherSettingsResponse>(
      `${this._baseUrl}/v1/companies/${buyerId}/settings`,
      payload
    );
  }
  addDecipherURI(buyerId: number, payload: BuyerURIRecords) {
    return this._http.post<DecipherUriResponse>(
      `${this._baseUrl}/v1/companies/${buyerId}/uriRecord`,
      payload
    );
  }

  updateDecipherURI(id: string, payload: BuyerURIRecords) {
    return this._http.put<DecipherUriUpdateResponse>(
      `${this._baseUrl}/v1/companies/${id}/uriRecord`,
      payload
    );
  }

  getDecipherSettings(buyerId: number) {
    return this._http.get<DecipherSettingsResponse>(
      `${this._baseUrl}/v1/companies/${buyerId}/settings`
    );
  }
  getMultiPathAccess(decipherCloud: string, token: string) {
    const headers = {
      'x-authentication': token,
    };

    const requestOptions = {
      headers: headers,
    };

    return this._http.get<any>(
      `${this._baseUrl}/v2/companies/${decipherCloud}`,
      requestOptions
    );
  }

  getDecipherMappings(buyerId: number, locale: string) {
    return this._http.get<any>(
      `${this._baseUrl}/v2/companies/${buyerId}/mappings/locale/${locale}`
    );
  }

  putDecipherMappings(
    buyerId: number,
    locale: string,
    payload: DecipherMappingsPayload
  ) {
    return this._http.put<DecipherMappingsResponse>(
      `${this._baseUrl}/v2/companies/${buyerId}/mappings/locale/${locale}`,
      payload
    );
  }

  getDecipherSurveys(buyerId: number, uri: string, directory: string) {
    return this._http.get<GetDecipherSurveys>(
      `${this._baseUrl}/v2/companies/${buyerId}/surveys?uri=${uri}&directory=${directory}`
    );
  }

  getDecipherSurveyRequiredCompletes(
    buyerId: number,
    uri: string,
    path: string
  ) {
    return this._http.get<GetDecipherRequiredCompletes>(
      `${this._baseUrl}/v1/companies/${buyerId}/surveys/completes-required?uri=${uri}&path=${path}`
    );
  }

  getDecipherURIList() {
    return this._http.get<DecipherURIResponse>(
      `${this._baseUrl}/v1/companies/uri/list`
    );
  }

  saveSurveyMetadata(cmp: number, payload: any) {
    return this._http.post<any>(
      `${this._baseUrl}/v2/companies/${cmp}/surveys/metadata`,
      payload
    );
  }
  updateSurveyMapping(cmp: number, payload: any) {
    return this._http.post<any>(
      `${this._baseUrl}/v2/companies/${cmp}/surveys/mappings`,
      payload
    );
  }
  updateSurveyMarkers(buyerId: number, payload: any) {
    return this._http.put<any>(
      `${this._baseUrl}/v2/companies/${buyerId}/surveys/markers`,
      payload
    );
  }

  getDecipherURI(buyerId: number) {
    return this._http.get<GetDecipherURI>(
      `${this._baseUrl}/v2/companies/${buyerId}/uri/list`
    );
  }

  getDirectory(buyerId: number, uri: string) {
    return this._http.get<GetDirectory>(
      `${this._baseUrl}/v2/companies/${buyerId}/uri/${uri}/directories`
    );
  }

  getMetadata(buyerId: number, path: string) {
    return this._http.get<GetMetadata>(
      `${this._baseUrl}/v2/companies/${buyerId}/surveys/metadata?path=${path}`
    );
  }

  getDecipherQuotas(buyerId: number, payload: GetQuotasPayload) {
    return this._http.post<GetDecipherQuotas>(
      `${this._baseUrl}/v2/companies/${buyerId}/quotas`,
      payload
    );
  }
  createSurvey(buyerId: number, payload: CreateSurveyPayloadData) {
    return this._http.post<CreateSurveyResponse>(
      `${this._baseUrl}/buyer/${buyerId}/v2/surveys`,
      payload
    );
  }

  createNewQuestion(buyerId: number, payload: any) {
    return this._http.post<any>(
      `${this._baseUrl}/v2/companies/${buyerId}/qualifications`,
      payload
    );
  }
}
