import {
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { EachCountry } from '../../interfaces/common-service.interface';
import { MultiCountryColumnContentDirective } from './directives/multi-county-column-content.directive';
import { McSVPEachSurvey } from '../../services/buyer-api/survey.interface';
import { McSvpCountryNameMapper } from '../../../dashboard/services/mc-svp-country-name-mapper';
import { TableCellTemplateDirective } from '@purespectrum1/ui/table';
import { MultiCountryRowHoverContentDirective } from './directives/multi-country-row-hover-content.directive';

@Component({
  selector: 'ps-multi-country-info-listing',
  templateUrl: './multi-country-info-listing.component.html',
  styleUrls: ['./multi-country-info-listing.component.css'],
})
export class MultiCountryInfoListingComponent implements OnInit {
  @Input()
  public countries: EachCountry[] = [];
  @Input()
  public mcSvpChildSurveys: McSVPEachSurvey[] = [];

  @ContentChildren(MultiCountryColumnContentDirective)
  public columns!: QueryList<MultiCountryColumnContentDirective>;
  @ContentChild(TableCellTemplateDirective)
  public cell!: TableCellTemplateDirective;
  @ContentChild(MultiCountryRowHoverContentDirective)
  public hoveredRow!: MultiCountryRowHoverContentDirective;
  public hoveredId: number | null = null;

  @Output() tap = new EventEmitter<unknown>();
  @Output() hover = new EventEmitter<number | null>();

  public mappedMcSvpChildSurveys: McSVPEachSurvey[] = [];
  constructor() {}

  ngOnInit(): void {
    this.mappedMcSvpChildSurveys = new McSvpCountryNameMapper(
      this.mcSvpChildSurveys,
      this.countries
    ).map();
  }

  selectHoveredRow(hoveredId: number | null): void {
    this.hoveredId = hoveredId;
    this.hover.emit(this.hoveredId);
  }
}
