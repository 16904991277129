import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { InvoiceOperationService } from '../invoice-operation-services/invoice-operation.service';
import { Subscription } from 'rxjs';
import { CompanyResponseObject } from '../../../shared/interfaces/company.interface';
import {
  Constants,
  MONTH_NAME_TO_NUMBER,
  MONTH_NUMBER_TO_NAME,
} from '../invoice-operation.constants';
import { GetInvoice } from '../invoice-operation-services/invoice-operation.interface';
import * as fileSaver from 'file-saver';
import { notifyMessage } from '../../../constants/notify-message';
import { CompanyInfo } from '../invoice-operation-services/invoice-operation.interface';
import { AuthService } from '../../../auth/auth.service';
import { INVOICE_TYPE } from '../../../constants/invoice-type';

@Component({
  selector: 'ps-manage-invoice',
  templateUrl: './manage-invoice.component.html',
  styleUrls: ['./manage-invoice.component.css'],
})
export class ManageInvoiceComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public buyers: CompanyInfo[] = [];
  public selectedCompany?: CompanyResponseObject;
  public selectMonth =
    MONTH_NUMBER_TO_NAME[
      this.convertNumberToDoubleDigit(new Date().getMonth() + 1)
    ];
  public months = Constants.MONTHS_TO_DISPLAY;
  public selectYear = new Date().getFullYear();
  public selectDate = new Date().getDate();
  public years = Constants.YEARS;
  public dates = Constants.DATES;
  public isPoOrProject = false;
  public isABuyer = false;
  public isLoading = true;
  constructor(
    private _toastr: ToasterService,
    private _invoiceOperationService: InvoiceOperationService,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    this._getCompanies();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  private _getCompanies() {
    this._invoiceOperationService.getInvoiceCompaniesData().subscribe({
      next: (resp: CompanyInfo[]) => {
        this.buyers = resp;
      },
      error: () => {
        this._toastr.error(notifyMessage.errorMessage.COMPANIES_NOT_FOUND);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  setSelectedCompany(company: CompanyResponseObject) {
    this.selectedCompany = company;
    this.checkIfPoOrProject();

    if (this.selectedCompany?.isABuyer) {
      this.isABuyer = true;
    } else {
      this.isABuyer = false;
    }
  }

  checkIfPoOrProject() {
    const invoiceType = this.getBuyerInvoiceTypeFromHistory();
    this.isPoOrProject = invoiceType !== INVOICE_TYPE.MONTHLY;
  }

  downloadInvoice() {
    const company_id = this.selectedCompany?.id || 0;
    const company_name = this.selectedCompany?.name || 'All';
    const period: string =
      this.selectYear + MONTH_NAME_TO_NUMBER[this.selectMonth];
    this._invoiceOperationService
      .downloadSurveyFinanceReport(company_id, company_name, period)
      .subscribe(
        (resp: GetInvoice) => {
          window.open(
            this._invoiceOperationService.downloadInvoice(
              resp.fileName,
              this._authService.token
            )
          );
        },
        (error: { error: { msg: string } }) => {
          this._toastr.error(error.error.msg);
        }
      );
  }

  downloadBuyerPdf() {
    const company_id = this.selectedCompany?.id || 0;
    const company_name = this.selectedCompany?.name || 'All';
    const period: string =
      this.selectYear + MONTH_NAME_TO_NUMBER[this.selectMonth];
    const pdf_type = 'buyerPdf';
    const invoiceType = this.getBuyerInvoiceTypeFromHistory();

    if (invoiceType !== INVOICE_TYPE.MONTHLY) {
      this._downloadPoProjectFile(
        company_id,
        this.convertNumberToDoubleDigit(this.selectDate),
        invoiceType,
        false,
        MONTH_NAME_TO_NUMBER[this.selectMonth],
        this.convertNumberToDoubleDigit(this.selectYear),
        period,
        `${this.selectedCompany?.id}-${this.selectedCompany?.name}.zip`.replace(
          / /g,
          ''
        )
      );
    } else {
      // we have started to store the pdf file of MONTHLY on s3 bucket from start of august 2021
      if (
        Number(this.selectYear) > 2021 ||
        (Number(this.selectYear) === 2021 &&
          Number(MONTH_NAME_TO_NUMBER[this.selectMonth]) >= 7)
      ) {
        this._downloadPoProjectFile(
          company_id,
          this.convertNumberToDoubleDigit(this.selectDate),
          invoiceType,
          false,
          MONTH_NAME_TO_NUMBER[this.selectMonth],
          this.convertNumberToDoubleDigit(this.selectYear),
          period,
          (this.selectedCompany?.name || '').replace(/ /g, '') +
            '-' +
            period +
            '.pdf'
        );
      } else {
        this._downloadSurveyFinance(
          company_id,
          company_name,
          pdf_type,
          period,
          (this.selectedCompany?.name || '').replace(/ /g, '') +
            '-' +
            period +
            '.pdf'
        );
      }
    }
  }

  private _downloadPoProjectFile(
    cmp: number,
    date: string,
    invoiceType: string,
    isStatement: boolean,
    month: string,
    year: string,
    period: string,
    filename: string
  ) {
    this._invoiceOperationService
      .downloadPoProjectInvoice({
        cmp,
        date,
        month,
        year,
        invoiceType,
        isStatement,
      })
      .subscribe(
        (result: ArrayBuffer) => {
          const file = new Blob([result], { type: 'application/pdf' });
          if (filename) {
            fileSaver.saveAs(file, filename);
          } else {
            fileSaver.saveAs(file, `${this.selectedCompany?.name}-${period}`);
          }
        },
        (error) => {
          const outBuff = error.error
            ? this._arrayBufferToString(error.error)
            : notifyMessage.errorMessage.FILE_DOWNLOAD_FAILED;
          this._toastr.error(
            typeof JSON.parse(outBuff) == 'object'
              ? JSON.parse(outBuff).msg
              : outBuff
          );
        }
      );
  }

  private _arrayBufferToString(buffer: ArrayBuffer) {
    let jsonKey: string = '';
    new Uint8Array(buffer).forEach(function (byte: number) {
      jsonKey += String.fromCharCode(byte);
    });
    return jsonKey;
  }

  downloadSupplierPdf() {
    const company_id = this.selectedCompany?.id || 0;
    const company_name = this.selectedCompany?.name || 'All';
    const period: string =
      this.selectYear + MONTH_NAME_TO_NUMBER[this.selectMonth];
    const pdf_type = 'supplierPdf';

    // we have started to store the pdf file of MONTHLY on s3 bucket from start of august 2021
    if (
      Number(this.selectYear) > 2021 ||
      (Number(this.selectYear) === 2021 &&
        Number(MONTH_NAME_TO_NUMBER[this.selectMonth]) >= 7)
    ) {
      this._downloadPoProjectFile(
        company_id,
        this.convertNumberToDoubleDigit(this.selectDate),
        this.getBuyerInvoiceTypeFromHistory(),
        true,
        MONTH_NAME_TO_NUMBER[this.selectMonth],
        this.convertNumberToDoubleDigit(this.selectYear),
        period,
        (this.selectedCompany?.name || '').replace(/ /g, '') +
          '-' +
          period +
          '.pdf'
      );
    } else {
      this._downloadSurveyFinance(
        company_id,
        company_name,
        pdf_type,
        period,
        (this.selectedCompany?.name || '').replace(/ /g, '') +
          '-' +
          period +
          '.pdf'
      );
    }
  }

  downloadBuyerXlsx() {
    const company_id = this.selectedCompany?.id || 0;
    const company_name = this.selectedCompany?.name || 'All';
    let period: string =
      this.selectYear + MONTH_NAME_TO_NUMBER[this.selectMonth];
    const invoiceType = this.getBuyerInvoiceTypeFromHistory();
    if (invoiceType !== INVOICE_TYPE.MONTHLY) {
      period += this.convertNumberToDoubleDigit(this.selectDate); // YYYYMMDD
    }
    this._downloadSurveyFinanceXlsx(
      company_id,
      company_name,
      'buyerXlsx',
      period,
      invoiceType
    );
  }

  downloadSupplierXlsx() {
    const company_id = this.selectedCompany?.id || 0;
    const company_name = this.selectedCompany?.name || 'All';
    const period: string =
      this.selectYear + MONTH_NAME_TO_NUMBER[this.selectMonth];
    this._downloadSurveyFinanceXlsx(
      company_id,
      company_name,
      'supplierXlsx',
      period
    );
  }

  regenerateInvoice() {
    const company_id = this.selectedCompany?.id || 0;
    this._invoiceOperationService
      .regenerateInvoice({
        cmp: company_id,
        date: this.convertNumberToDoubleDigit(this.selectDate),
        month: MONTH_NAME_TO_NUMBER[this.selectMonth],
        year: this.convertNumberToDoubleDigit(this.selectYear),
        invoiceType: this.getBuyerInvoiceTypeFromHistory(),
      })
      .subscribe(
        (resp) => {
          if (resp) {
            this._toastr.success(
              `${resp.status.length} surveys Regenerated: ${resp.status}`
            );
          }
        },
        (error) => {
          this._toastr.error(
            error.error.msg
              ? error.error.msg
              : notifyMessage.errorMessage.INVOICE_REGENERATE_FAILED
          );
        }
      );
  }

  convertNumberToDoubleDigit(numberToConvert: number) {
    let number = `${numberToConvert}`;
    if (number.length == 1) {
      return `0${numberToConvert}`;
    }
    return number;
  }

  private _downloadSurveyFinance(
    company_id: number,
    company_name: string,
    pdf_type: string,
    period: string,
    filename: string
  ) {
    this._invoiceOperationService
      .downloadSurveyFinance({ company_id, company_name, pdf_type, period })
      .subscribe(
        (result: ArrayBuffer) => {
          const file = new Blob([result], { type: 'application/pdf' });
          if (filename) {
            fileSaver.saveAs(file, filename);
          } else {
            fileSaver.saveAs(file, `${this.selectedCompany?.name}-${period}`);
          }
        },
        (error) => {
          const outBuff = error.error
            ? this._arrayBufferToString(error.error)
            : notifyMessage.errorMessage.FILE_DOWNLOAD_FAILED;
          this._toastr.error(
            typeof outBuff == 'string' ? outBuff : JSON.parse(outBuff).msg
          );
        }
      );
  }

  private _downloadSurveyFinanceXlsx(
    company_id: number,
    company_name: string,
    pdf_type: string,
    period: string,
    invoiceType: string = INVOICE_TYPE.MONTHLY
  ) {
    this._invoiceOperationService
      .downloadSurveyFinanceXlsx({
        company_id,
        company_name,
        pdf_type,
        period,
        invoiceType,
      })
      .subscribe(
        (result: Blob) => {
          fileSaver.saveAs(result, `${company_name}-${period}.xlsx`);
        },
        ({
          data: {
            message = notifyMessage.errorMessage.FILE_DOWNLOAD_FAILED,
          } = {},
        } = {}) => {
          this._toastr.error(message);
        }
      );
  }

  getBuyerInvoiceTypeFromHistory = () => {
    let invoiceType = this.selectedCompany?.generateInvoice
      ? this.selectedCompany.generateInvoice
      : INVOICE_TYPE.MONTHLY;
    if (this.selectedCompany?.invoiceTypeHistory?.length) {
      // find the invoiceType of the closest lower period with respect to the current selectedDate
      const selectedDate = `${String(this.selectYear)}${String(
        MONTH_NAME_TO_NUMBER[this.selectMonth]
      )}`;
      invoiceType = this.selectedCompany.invoiceTypeHistory
        .sort((a, b) => Number(a.period) - Number(b.period))
        .reduce(
          (acc, value) =>
            Number(selectedDate) >= Number(value.period)
              ? value.invoiceType
              : acc,
          INVOICE_TYPE.MONTHLY
        );
    }
    return invoiceType;
  };
}
