import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import {
  GetBuyerResponse,
  AddNCConfigRequest,
  AddNCConfigResponse,
  GetNCConfigResponse,
  SendRNDataRequest,
  SendRNDataResponse,
  GendRNDataResponse,
  PutPDSettingsRequest,
  PutPDSettingsResponse,
  GetPDSettingsResponse,
  GetBuyerRateCardResponse,
} from '../../interfaces/buyer.interface';
import {
  BuyerServiceAccountTokenRes,
  NcBuyer,
  NcBuyerConfig,
  NcBuyerDetails,
  PostNcConfigRes,
  TrafficReport,
} from './buyer-response.interface';
import { catchError, map, tap } from 'rxjs/operators';
import { InvoiceOperationService } from '../../../buyer/invoice-operation/invoice-operation-services/invoice-operation.service';
import { AuthService } from '../../../auth/auth.service';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BuyerService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  private readonly _nightcrawlerUrl = environment.pureSpectrum.nightcrawlerUrl;
  constructor(
    private _http: HttpClient,
    private _auth: AuthService,
    private _invoiceOperationService: InvoiceOperationService
  ) {}

  getBuyers() {
    return this._http.get<GetBuyerResponse>(`${this._baseUrl}/getBuyers`);
  }

  addNightcrawlerConfigSettings(buyerId: number, payload: AddNCConfigRequest) {
    return this._http.post<AddNCConfigResponse>(
      `${this._baseUrl}/buyers/${buyerId}/nc-config`,
      payload
    );
  }

  getNightcrawlerConfigSettings(buyerId: number) {
    return this._http.get<GetNCConfigResponse>(
      `${this._baseUrl}/buyers/${buyerId}/nc-config`
    );
  }

  sendBuyerRNData(payload: SendRNDataRequest) {
    return this._http.post<SendRNDataResponse>(
      `${this._baseUrl}/sendRNdata`,
      payload
    );
  }

  getBuyerRNdata(buyerId: number) {
    return this._http.get<GendRNDataResponse>(
      `${this._baseUrl}/getRNdata/${buyerId}`
    );
  }

  getNightcrawlerBuyers(token: string) {
    return this._http.get<NcBuyer[]>(`${this._nightcrawlerUrl}/buyers`, {
      headers: {
        skipInterceptor: 'true',
        'access-token': token,
      },
    });
  }
  getNightcrawlerBuyer(buyerId: number, token: string) {
    return this._http.get<NcBuyerDetails>(
      `${this._nightcrawlerUrl}/buyers/${buyerId}`,
      {
        headers: {
          skipInterceptor: 'true',
          'access-token': token,
        },
      }
    );
  }
  syncSurveyToNightcrawler(ncBuyerConfig: NcBuyerConfig) {
    return this._http
      .post<PostNcConfigRes>(
        `${this._baseUrl}/nightcrawler/v1/surveys`,
        ncBuyerConfig
      )
      .pipe(
        map((response) => {
          return response?.psSupplier?.surveyEntryLink;
        })
      );
  }

  getBuyerServiceAccountToken(buyerId?: number) {
    return this._http.get<BuyerServiceAccountTokenRes>(
      `${this._baseUrl}/users/buyer-service-account/token/${buyerId}`
    );
  }

  downloadTransactionReport(
    surveyId: number | undefined,
    includeTestTransactions: boolean
  ) {
    const _userCompanyId = this._auth.user?.cmp;
    const userType = this._auth.userType;

    return this._invoiceOperationService
      .downloadSTRV3(
        Number(_userCompanyId),
        surveyId?.toString(),
        userType,
        includeTestTransactions
      )
      .pipe(
        tap((resp) => {
          const fileName = resp.fileName;
          window.location.assign(
            this._invoiceOperationService.downloadFile(
              fileName,
              this._auth.token!
            )
          );
        })
      );
  }

  updatePDSettings(cmpId: number, payload: PutPDSettingsRequest) {
    return this._http.put<PutPDSettingsResponse>(
      `${this._baseUrl}/companies/${cmpId}/programmatic-direct-settings`,
      payload
    );
  }

  getPDSettings(cmpId: number) {
    return this._http.get<GetPDSettingsResponse>(
      `${this._baseUrl}/companies/${cmpId}/programmatic-direct-settings`
    );
  }

  getBuyerRateCard(cmpId: number, country?: number, language?: number) {
    return this._http.get<GetBuyerRateCardResponse>(
      `${this._baseUrl}/buyer-rate-cards/${cmpId}?country=${country}&language=${language}`
    );
  }

  uploadBuyerRateCardFile(formData: FormData): Observable<UploadFileResponse> {
    return this._http.post<UploadFileResponse>(
      `${this._baseUrl}/buyer-rate-cards/upload`,
      formData
    );
  }

  updateBuyerRateCardFile(
    formData: FormData,
    rateCardId: string
  ): Observable<UploadFileResponse> {
    return this._http.put<UploadFileResponse>(
      `${this._baseUrl}/buyer-rate-cards/upload/${rateCardId}`,
      formData
    );
  }

  deleteBuyerRateCardFile(rateCardId: string): Observable<UploadFileResponse> {
    return this._http.delete<UploadFileResponse>(
      `${this._baseUrl}/buyer-rate-cards/${rateCardId}`
    );
  }

  downloadBuyerRateCardFile(
    buyerId: number,
    supplierId: number,
    countryId?: number,
    languageId?: number,
    token?: string
  ) {
    return `${this._baseUrl}/buyer-rate-cards/download/${buyerId}/${supplierId}/${countryId}/${languageId}?access_token=${token}`;
  }

  downloadCombinedReport(
    surveyIds: number[],
    includeTestTransactions: boolean = false
  ) {
    const _userCompanyId = this._auth.user?.cmp;
    const userType = this._auth.userType;
    const userToken = this._auth.token!;

    const paramsObject = {
      user_id: Number(_userCompanyId),
      survey_id: surveyIds.join(','),
      type: userType,
      includeTestTransactions,
    };
    const params = new HttpParams({ fromObject: paramsObject });

    return this.fetchTrafficReport(params).pipe(
      tap((response) => {
        window.location.assign(
          this._invoiceOperationService.downloadFile(
            response.fileName,
            userToken
          )
        );
      }),
      catchError((e) => {
        return throwError(e.error.msg);
      })
    );
  }

  fetchTrafficReport(params: HttpParams): Observable<TrafficReport> {
    return this._http.get<TrafficReport>(
      `${this._baseUrl}/reports/surveys/traffic-report`,
      { params }
    );
  }
}

export interface UploadFileResponse {
  apiStatus: string;
  statusCode: number;
}
