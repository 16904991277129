import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { UploadMappingsResponse } from './external-buyer-mappings.interface';

@Injectable({
  providedIn: 'root',
})
export class ExternalBuyerMappingsService {
  private readonly _buyerExternalIntegrationUrl =
    environment.pureSpectrum.buyerExternalIntegrationUrl;

  constructor(private _http: HttpClient) {}

  uploadMappings(
    formData: FormData,
    id: string
  ): Observable<UploadMappingsResponse> {
    return this._http.post<UploadMappingsResponse>(
      `${this._buyerExternalIntegrationUrl}/api/v1/integrations/${id}/qualification-mappings`,
      formData,
      {
        headers: {
          skipInterceptor: 'true',
        },
      }
    );
  }

  downloadMappings(id: string): Observable<Blob> {
    return this._http.get<Blob>(
      `${this._buyerExternalIntegrationUrl}/api/v1/integrations/${id}/qualification-mappings/download`,
      { responseType: 'blob' as 'json' }
    );
  }
}
