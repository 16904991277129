<div class="table-absolute">
  <table class="table table-striped border mt-1">
    <thead>
      <tr>
        <th
          scope="col"
          data-order=""
          data-name="transactionId"
          [psSortTable]="dataList"
        >
          Transaction
        </th>
        <th
          scope="col"
          data-order=""
          data-name="supplierName"
          [psSortTable]="dataList"
        >
          Supplier Name
        </th>
        <th
          scope="col"
          data-order=""
          data-name="supplierSessionId"
          [psSortTable]="dataList"
        >
          Supplier Session ID
        </th>
        <th
          scope="col"
          data-order=""
          data-name="supplierSessionId"
          [psSortTable]="dataList"
        >
          Supplier Respondent ID
        </th>
        <th
          scope="col"
          data-order=""
          data-name="transactionStatus"
          [psSortTable]="dataList"
        >
          Transaction Status
        </th>
        <th
          scope="col"
          data-order=""
          data-name="country"
          [psSortTable]="dataList"
        >
          Survey Start Time
        </th>
        <th scope="col" data-order="" data-name="LOI" [psSortTable]="dataList">
          LOI
        </th>
        <th scope="col" data-order="" data-name="PSID" [psSortTable]="dataList">
          PSID
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of dataList">
        <td>{{ item.transactionId }}</td>
        <td>{{ item.supplierName }}</td>
        <td>{{ item.supplierSessionId || 'NA' }}</td>
        <td>{{ item.supplierRespondentId }}</td>
        <td>{{ item.transactionStatus }}</td>
        <td>{{ item.surveyStartTime }}</td>
        <td>{{ item.LOI || 'NA' }}</td>
        <td>{{ item.PSID }}</td>
      </tr>
    </tbody>
  </table>
</div>
