import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import { notifyMessage } from '../../constants/notify-message';
import { AuthService } from '../../auth/auth.service';
import { DecipherService } from '../../shared/services/decipher/decipher.service';
import { BuyerSettingsService } from '../buyer-settings-service/buyer-settings-service.service';
import {
  Tab,
  UpdateObj,
  BuyerResponse,
  UpdateDataObj,
  VariableMappingKeys,
} from '../buyer-settings-service/buyer-settings-service.interface';
import { Constants } from './buyer-settings-constant';
import { decipherHashingConstants } from './hashing/hashing-constant';
import {
  DecipherMetaData,
  DecipherSettingsResponse,
} from 'src/app/shared/services/decipher/decipher.interface';
import { AuthState as AuthStateInterface } from '@purespectrum1/ui/marketplace/shared/interfaces/auth.interface';
import { BillingNumberRulesComponent } from './billing-number-rules/billing-number-rules.component';

interface TabFilterCondition {
  title: string;
  condition: (auth: AuthStateInterface) => boolean;
}

@Component({
  selector: 'ps-buyer-settings',
  templateUrl: './buyer-settings.component.html',
  styleUrls: ['./buyer-settings.component.css'],
})
export class BuyerSettingsComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  userCmp!: number;
  userCmpName!: string;
  decipherSettings!: DecipherMetaData;
  decipherMappings!: any;
  @ViewChild('billingRules', { static: false })
  billingRules!: BillingNumberRulesComponent;
  private _subscription: Subscription = new Subscription();

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _toastr: ToasterService,
    private _decipherService: DecipherService,
    private _buyerSettingsService: BuyerSettingsService
  ) {}

  public tabs: Tab[] = Constants.MANAGE_BUYER_TABS;
  public activeTab: Tab = this.tabs[0];
  ngOnInit(): void {
    if (this._auth.user?.cmp) {
      this.userCmp = this._auth.user.cmp;
    }
    this._generateForm();
    this._getDecipherSettings();
    this._getManageBuyerSetting();
    this.handleBillingNumberRulesTab();
    this._getDecipherMappings();
  }

  private _getDecipherSettings = () => {
    this._decipherService.getDecipherSettings(this.userCmp).subscribe(
      (response: DecipherSettingsResponse) => {
        this.decipherSettings = response.metaData;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
  };

  private _getDecipherMappings = () => {
    this._decipherService.getDecipherMappings(this.userCmp, 'all').subscribe(
      (response: any) => {
        this.decipherMappings = response;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
  };

  private _generateForm() {
    this.form = this._fb.group({
      cmp: [this.userCmp, [Validators.required]],
      cmpName: [''],
      decipherObj: this._fb.group({
        cmp: [null, [Validators.required]],
        directory_name: [''],
        locale: [''],
        selectedQualification: [],
        selectedSupplierType: [''],
        selectedURI: [''],
        vendor: [''],
      }),
      private_key: [''],
      hashType: [null],
      isSHA1: [false, [Validators.required]],
      isSHA2: [false, [Validators.required]],
      isOutboundHash: [false],
      transactionIdCb: ['1', []],
      transactionIdIn: ['transaction_id', []],
      transactionIdOut: ['transaction_id', []],
      psTransactionId2Cb: [0, []],
      psTransactionId2In: ['transaction_id', []],
      psTransactionId2Out: ['transaction_id2', []],
      psTransactionId3Cb: [0, []],
      psTransactionId3In: ['transaction_id', []],
      psTransactionId3Out: ['transaction_id3', []],
      psTransactionId4Cb: [0, []],
      psTransactionId4In: ['transaction_id', []],
      psTransactionId4Out: ['transaction_id4', []],
      psIdCb: [1, []],
      psIdOut: ['psid', []],
      psIdIn: ['psid', []],
      psSurveyIdCb: [0, []],
      psSurveyIdOut: ['survey_id', []],
      psSupplierIdCb: [0, []],
      psSupplierIdOut: ['supplier_id', []],
      psSupplierGroupNameCb: [0, []],
      psSupplierGroupNameOut: [
        'supplier_grouping_name',
        [Validators.maxLength(30)],
      ],
      statusCb: [1, []],
      statusIn: ['st', []],
      psHashCb: [1, []],
      psHashIn: ['ps_hash', []],
      decipherHashIn: [decipherHashingConstants.HASH_KEY_MAP.incoming, []],
      decipherHashOut: [decipherHashingConstants.HASH_KEY_MAP.outgoing, []],
      variable_mapping: this._fb.array([]),
      isHashing: [false],
      isDecipher: [false, [Validators.required]],
      st: [1],
      is_decipher_hash: [false, [Validators.required]],
      consolidatedView: [true, [Validators.required]],
      decipherHashKeyID: ['', []],
      psHashedIdCb: [0, []],
      psHashedIdOut: ['hashed_id', []],
      psHashedIdIn: ['hashed_id', []],
    });
  }

  handleBillingNumberRulesTab(): void {
    const conditions = [
      this._auth.user?.buyerAcssLvls === 'superAdmin',
      this._auth.buyerConfig?.enableBillingNumberRules,
    ];
    const isAllowBillingNumberRules = conditions.every((item) => item);

    this.tabs = Constants.MANAGE_BUYER_TABS.filter(
      (tab) => isAllowBillingNumberRules || tab.title !== 'BILLING NUMBER RULES'
    );
  }

  checkActiveTab(activeIndex: number) {
    this.activeTab = this.tabs[activeIndex];
  }

  private _mapHashingType(buyer: BuyerResponse) {
    if (buyer.hashType === 'sha1') {
      this.form.controls.isSHA1.setValue(true);
    } else {
      this.form.controls.isSHA2.setValue(true);
    }
  }

  private _checkValues(object: UpdateObj) {
    if (object.hashType == null) {
      delete object.hashType;
    }
  }

  private _getManageBuyerSetting() {
    const buyerSetting$ = this._buyerSettingsService
      .getBuyerSettings(this.userCmp)
      .subscribe(
        (res) => {
          const buyer = res.buyer;
          this.userCmpName = buyer.companyName;
          if (buyer.isHashing) {
            this._mapHashingType(buyer);
          }
          let isPSIDExist = false;
          let isPSHashExist = false;

          this._setVariableMapping(buyer, isPSIDExist, isPSHashExist);
          this.form.patchValue(buyer);
        },
        (err) => {
          const errorMessage = err.error.msg;
          this._toastr.error(errorMessage);
        }
      );
    this._subscription.add(buyerSetting$);
  }

  private _setVariableMapping(
    buyer: BuyerResponse,
    isPSIDExist: boolean,
    isPSHashExist: boolean
  ) {
    for (let i = 0; i < buyer.variable_mapping.length; i++) {
      for (let j in buyer.variable_mapping[i]) {
        this._setMappingControls(j, buyer.variable_mapping[i][j]);
        if (j == 'ps_psid') {
          isPSIDExist = true;
        }
        if (j == 'ps_hash') {
          isPSHashExist = true;
        }
        if (j == 'ps_hashed_id') {
          this.form.controls.psHashedIdCb.setValue(1);
        }
      }
    }

    this.setPsIdPSHash(isPSIDExist, isPSHashExist);
  }

  setPsIdPSHash(isPSIDExist: boolean, isPSHashExist: boolean) {
    if (!isPSIDExist) {
      this.form.controls.psIdCb.setValue(0);
    }
    if (!isPSHashExist) {
      this.form.controls.psHashCb.setValue(0);
    }
  }

  saveSettingData() {
    if (this.activeTab.title === this.tabs[3]?.title) {
      this.billingRules.saveRules();
      return;
    }

    let updateObj = this.form.value;
    updateObj.isHashing = !!(updateObj.isSHA1 || updateObj.isSHA2);
    if (this._isKeyOrPrivateKeyMissing(updateObj)) {
      return this._toastr.warning(
        notifyMessage.warningMessage.BUYER_SETTINGS.KEY_OR_PRIVATE_KEY_MISSING
      );
    }
    if (
      updateObj.psSupplierGroupNameCb &&
      updateObj.psSupplierGroupNameOut.length > 30
    ) {
      return this._toastr.warning(
        notifyMessage.warningMessage.BUYER_SETTINGS
          .SUPPLIER_GROUP_NAME_CHAR_LENGTH
      );
    }
    if (updateObj.is_decipher_hash && !updateObj.decipherHashKeyID) {
      return this._toastr.warning(
        notifyMessage.warningMessage.DECIPHER_KEYRING_REQUIRED
      );
    }
    updateObj.cmpName = this.userCmpName;
    this._checkValues(updateObj);
    updateObj = this._manageUpdateObject(updateObj);
    this._buyerSettingsService
      .updateBuyerSettings(updateObj, this.userCmp)
      .subscribe(
        (res) => {
          this._setFormValuesPostUpdate(updateObj);
          this._toastr.success(
            notifyMessage.successMessage.BUYER_SETTINGS.VARIABLE_MAPPING
              .SAVE_SETTING
          );
        },
        (err) => {
          const errorMessage = err.error.msg;
          this._toastr.error(errorMessage);
        }
      );
  }

  // eslint-disable-next-line complexity
  private _manageUpdateObject(updateObj: UpdateDataObj) {
    let variable_mapping = [];
    //If Hash key name is present in 'hashing' tab, then the hash checkbox should automatically be checked.
    updateObj.psHashCb = updateObj.isHashing ? !!updateObj.psHashIn : false;
    if (updateObj.transactionIdCb) {
      const transaction = this._getTransactionMapping(updateObj);
      variable_mapping.push({ ps_transaction: transaction });
    }
    if (updateObj.psIdCb) {
      const psid = this._getPsIdMapping(updateObj);
      variable_mapping.push({ ps_psid: psid });
    }

    if (updateObj.psHashedIdCb) {
      const hashedId = this._getPsHashedIdMapping(updateObj);
      variable_mapping.push({ ps_hashed_id: hashedId });
    }

    if (updateObj.statusCb) {
      const status = {
        outgoing: '',
        incoming: updateObj.statusIn,
      };

      variable_mapping.push({ ps_status: status });
    }
    if (updateObj.psHashCb) {
      const ps_hash = this._getPsHashMapping(updateObj);
      variable_mapping.push({ ps_hash: ps_hash });
    }
    if (updateObj.is_decipher_hash) {
      const decipher_hash = {
        outgoing: updateObj.decipherHashOut,
        incoming: updateObj.decipherHashIn,
      };
      variable_mapping.push({ decipher_hash });
      updateObj.decipherHashKeyID = Number(updateObj.decipherHashKeyID);
    } else {
      delete updateObj.decipherHashKeyID;
    }
    if (updateObj.psTransactionId2Cb) {
      const transaction_id2 = this._getTransactionId2Mapping(updateObj);
      variable_mapping.push({ ps_transaction_id2: transaction_id2 });
    }
    if (updateObj.psTransactionId3Cb) {
      const transaction_id3 = this._getTransactionId3Mapping(updateObj);
      variable_mapping.push({ ps_transaction_id3: transaction_id3 });
    }
    if (updateObj.psTransactionId4Cb) {
      const transaction_id4 = this._getTransactionId4Mapping(updateObj);
      variable_mapping.push({ ps_transaction_id4: transaction_id4 });
    }
    if (updateObj.psSurveyIdCb) {
      const survey_id = this._getSurveyIdMapping(updateObj);
      variable_mapping.push({ ps_survey_id: survey_id });
    }
    if (updateObj.psSupplierIdCb) {
      const supplier_id = this._getSupplierIdMapping(updateObj);
      variable_mapping.push({ ps_supplier_id: supplier_id });
    }
    if (updateObj.psSupplierGroupNameCb) {
      const survey_grouping_name =
        this._getSurveyGroupingNameMapping(updateObj);
      variable_mapping.push({
        ps_supplier_grouping_name: survey_grouping_name,
      });
    }
    updateObj.variable_mapping = variable_mapping;
    return updateObj;
  }

  private _setMappingControls(
    updateKey: string,
    mappingObj: VariableMappingKeys
  ) {
    if (updateKey == 'ps_supplier_id') {
      this.form.controls.psSupplierIdCb.setValue(1);
      this.form.controls.psSupplierIdOut.setValue(mappingObj.outgoing);
    }

    this._setSurveyId(updateKey, mappingObj);
    this._setSurveyGrouping(updateKey, mappingObj);

    if (updateKey == 'ps_transaction') {
      this.form.controls.transactionIdIn.setValue(mappingObj.incoming);
      this.form.controls.transactionIdOut.setValue(mappingObj.outgoing);
    }

    if (updateKey == 'ps_psid') {
      this.form.controls.psIdOut.setValue(mappingObj.outgoing);
      this.form.controls.psIdIn.setValue(mappingObj.incoming);
    }

    if (updateKey == 'ps_status') {
      this.form.controls.statusIn.setValue(mappingObj.incoming);
    }

    if (updateKey == 'ps_hash') {
      this.form.controls.psHashIn.setValue(mappingObj.incoming);
    }

    if (updateKey == 'decipher_hash') {
      this.form.controls.decipherHashIn.setValue(mappingObj.incoming);
      this.form.controls.decipherHashIn.setValue(mappingObj.outgoing);
    }

    if (updateKey == 'ps_transaction_id2') {
      this.form.controls.psTransactionId2Cb.setValue(1);
      this.form.controls.psTransactionId2In.setValue(mappingObj.incoming);
      this.form.controls.psTransactionId2Out.setValue(mappingObj.outgoing);
    }

    if (updateKey == 'ps_transaction_id3') {
      this.form.controls.psTransactionId3Cb.setValue(1);
      this.form.controls.psTransactionId3In.setValue(mappingObj.incoming);
      this.form.controls.psTransactionId3Out.setValue(mappingObj.outgoing);
    }

    if (updateKey == 'ps_transaction_id4') {
      this.form.controls.psTransactionId4Cb.setValue(1);
      this.form.controls.psTransactionId4In.setValue(mappingObj.incoming);
      this.form.controls.psTransactionId4Out.setValue(mappingObj.outgoing);
    }

    if (updateKey == 'ps_hashed_id') {
      this.form.controls.psHashedIdOut.setValue(mappingObj.outgoing);
      this.form.controls.psHashedIdIn.setValue(mappingObj.incoming);
    }
  }

  private _isKeyOrPrivateKeyMissing(updateObj: UpdateDataObj) {
    return (
      updateObj.isHashing && (!updateObj.private_key || !updateObj.psHashIn)
    );
  }

  private _setFormValuesPostUpdate(updateObj: UpdateDataObj) {
    this.form.controls.psHashCb.setValue(updateObj.psHashCb ? 1 : 0);
    this.form.controls.psHashIn.setValue(updateObj.psHashIn);
  }

  private _setSurveyId(updateKey: string, mappingObj: VariableMappingKeys) {
    if (updateKey == 'ps_survey_id') {
      this.form.controls.psSurveyIdCb.setValue(1);
      this.form.controls.psSurveyIdOut.setValue(mappingObj.outgoing);
    }
  }
  private _setSurveyGrouping(
    updateKey: string,
    mappingObj: VariableMappingKeys
  ) {
    if (updateKey == 'ps_supplier_grouping_name') {
      this.form.controls.psSupplierGroupNameCb.setValue(1);
      this.form.controls.psSupplierGroupNameOut.setValue(mappingObj.outgoing);
    }
  }

  private _getTransactionMapping(updateObj: UpdateDataObj) {
    return {
      outgoing:
        updateObj.transactionIdOut != ''
          ? updateObj.transactionIdOut
          : 'transaction_id', //default transaction_id if empty
      incoming: updateObj.transactionIdIn,
    };
  }

  private _getPsIdMapping(updateObj: UpdateDataObj) {
    return {
      outgoing: updateObj.psIdOut != '' ? updateObj.psIdOut : 'psid', //default psid if empty
      incoming: updateObj.psIdIn,
    };
  }

  private _getPsHashMapping(updateObj: UpdateDataObj) {
    return {
      outgoing: '',
      incoming: updateObj.psHashIn ? updateObj.psHashIn : '',
    };
  }

  private _getTransactionId2Mapping(updateObj: UpdateDataObj) {
    return {
      outgoing:
        updateObj.psTransactionId2Out != ''
          ? updateObj.psTransactionId2Out
          : 'transaction_id2', //default transaction_id if empty
      incoming: updateObj.psTransactionId2In,
    };
  }

  private _getTransactionId3Mapping(updateObj: UpdateDataObj) {
    return {
      outgoing:
        updateObj.psTransactionId3Out != ''
          ? updateObj.psTransactionId3Out
          : 'transaction_id3', //default transaction_id if empty
      incoming: updateObj.psTransactionId3In,
    };
  }

  private _getTransactionId4Mapping(updateObj: UpdateDataObj) {
    return {
      outgoing:
        updateObj.psTransactionId4Out != ''
          ? updateObj.psTransactionId4Out
          : 'transaction_id4', //default transaction_id if empty
      incoming: updateObj.psTransactionId4In,
    };
  }

  private _getSurveyGroupingNameMapping(updateObj: UpdateDataObj) {
    return {
      outgoing:
        updateObj.psSupplierGroupNameOut != ''
          ? updateObj.psSupplierGroupNameOut
          : 'supplier_grouping_name',
      incoming: '',
    };
  }

  private _getSupplierIdMapping(updateObj: UpdateDataObj) {
    return {
      outgoing:
        updateObj.psSupplierIdOut != ''
          ? updateObj.psSupplierIdOut
          : 'supplier_id',
      incoming: '',
    };
  }

  private _getSurveyIdMapping(updateObj: UpdateDataObj) {
    return {
      outgoing:
        updateObj.psSurveyIdOut != '' ? updateObj.psSurveyIdOut : 'survey_id',
      incoming: '',
    };
  }

  private _getPsHashedIdMapping(updateObj: UpdateDataObj) {
    return {
      outgoing: updateObj.psHashedIdOut != '' ? updateObj.psHashedIdOut : '',
      incoming: updateObj.psHashedIdIn,
    };
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
