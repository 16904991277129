import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import {
  BuyersListing,
  SuppliersListing,
  PdsSupplier,
} from './programmatic-direct-settings.interface';
import { CompanyService } from '../../shared/services/company/company.service';
import { BuyerService } from '../../shared/services/buyer/buyer.service';
import { UNITS } from '../../constants/currency-units';
import { AuthService } from '../../auth/auth.service';
import { GetPDSettingsResponse } from '../../shared/interfaces/buyer.interface';

@Component({
  selector: 'ps-programmatic-direct-settings',
  templateUrl: './programmatic-direct-settings.component.html',
  styleUrls: ['./programmatic-direct-settings.component.css'],
})
export class ProgrammaticDirectSettingsComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public buyers: BuyersListing[] = [];
  public suppliers: SuppliersListing[] = [];
  public buyerPDSettings!: GetPDSettingsResponse;
  public selectedBuyer!: BuyersListing;
  public newPDSSupplier: PdsSupplier = { editable: false } as PdsSupplier;
  public currencySymbol: string = '';
  public selectedBuyerFx: number = 321;
  private _oldFee: number | null = null;

  constructor(
    private _buyerService: BuyerService,
    private _companyService: CompanyService,
    private _auth: AuthService,
    private _toastr: ToasterService
  ) {}

  ngOnInit(): void {
    this.getBuyers();
  }

  public getBuyers() {
    const $getBuyers = this._buyerService.getBuyers().subscribe(
      (buyerResponse) => {
        this.buyers = buyerResponse.company;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add($getBuyers);
  }

  public selectBuyer(buyer: BuyersListing) {
    this.selectedBuyer = buyer;
    this._getPDSettings(buyer.id);
    this._setCurrency(buyer.id);
    this.resetAddSupplier();
  }

  private _getPDSettings(id: number) {
    const $getSettings = this._buyerService.getPDSettings(id).subscribe(
      (response: GetPDSettingsResponse) => {
        this.buyerPDSettings = response;
        const supplierIds: number[] = [];
        this.buyerPDSettings?.suppliers?.forEach((supplier) => {
          supplier.editable = false;
          supplierIds.push(supplier.id);
        });
        this._getSuppliers(supplierIds);
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add($getSettings);
  }

  private _getSuppliers(supplierIds: number[]) {
    const $getCompanies = this._companyService.getCompanies().subscribe(
      (response) => {
        this.suppliers = response.companies.filter((eachCompany) => {
          return eachCompany.isASupplier;
        });
        this.suppliers = this.suppliers.filter(
          (supplier) => !supplierIds.includes(supplier.id)
        );
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add($getCompanies);
  }

  private _setCurrency(id: number) {
    const $getCompany = this._companyService
      .getCompany(id)
      .subscribe((response) => {
        this.currencySymbol =
          response.company && response.company.length && response.company[0].fx
            ? `${UNITS[response.company[0].fx]} `
            : '';
        this.selectedBuyerFx = response.company ? response.company[0]?.fx : 321;
      });
    this._subscriptions.add($getCompany);
  }

  public selectSupplier(supplier: SuppliersListing) {
    this.newPDSSupplier.id = supplier.id;
    this.newPDSSupplier.name = supplier.name;
    if (this.buyerPDSettings) {
      const supplierFeeSettings = this.buyerPDSettings?.suppliers?.find(
        (supp) => supp.id === this.newPDSSupplier?.id
      );
      this.newPDSSupplier.fee = supplierFeeSettings?.fee || null;
    }
  }

  public updatePDSettings(supplier: PdsSupplier) {
    const validRes = this._validatePDSPayload(supplier);
    if (!validRes.valid) {
      return this._toastr.warning(validRes.message);
    }
    const payload = this._mapPDSettingsPayload(supplier);
    const $updateSettings = this._buyerService
      .updatePDSettings(payload.buyerId, payload)
      .subscribe(
        (response) => {
          this._getPDSettings(payload.buyerId);
          this.resetAddSupplier();
          this._toastr.success(response.message);
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add($updateSettings);
  }

  private _validatePDSPayload(supplier: PdsSupplier) {
    if (supplier.fee && supplier.fee < 0.01) {
      return {
        valid: false,
        message: 'Please enter valid fee to proceed',
      };
    } else if (!supplier.id) {
      return {
        valid: false,
        message: 'Please select suppliers to proceed',
      };
    } else {
      return {
        valid: true,
        message: '',
      };
    }
  }

  private _mapPDSettingsPayload(supplier: PdsSupplier) {
    const payload = {
      name: this.selectedBuyer.name,
      buyerId: this.selectedBuyer.id,
      fx: this.selectedBuyerFx,
      supplier: {} as PdsSupplier,
    };

    payload.supplier = {
      id: supplier.id,
      name: supplier.name,
      fee: supplier.fee,
      updatedAt: new Date().getTime(),
      updatedBy: {
        userId: this._auth?.user?.id || 0,
        name: this._auth?.user?.usrName || '',
      },
    };
    return payload;
  }

  public validateInputFee(event: Event) {
    if (parseInt((<HTMLInputElement>event.target).value) < 0) {
      this.newPDSSupplier.fee = null;
    }
  }

  public resetAddSupplier() {
    this.newPDSSupplier = { editable: false } as PdsSupplier;
  }

  public editSupplier(supplier: PdsSupplier) {
    const index = this.buyerPDSettings?.suppliers?.findIndex(
      (sup) => sup.editable === true
    );
    if (index !== -1) {
      this.buyerPDSettings.suppliers[index].editable = false;
      this.buyerPDSettings.suppliers[index].fee = this._oldFee;
    }
    supplier.editable = true;
    this._oldFee = supplier.fee;
  }

  public resetEditSupplier(supplier: PdsSupplier) {
    supplier.editable = false;
    supplier.fee = this._oldFee;
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
