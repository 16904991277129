import {
  Directive,
  Input,
  ElementRef,
  HostListener,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: '[psSortTable]',
})
export class SortDirective {
  private _sortOrder = 1;
  private _collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  @Input()
  psSortTable: Array<any> = [];
  @HostBinding('class')
  elementClass = 'sorting-arrow';

  constructor(private _targetElem: ElementRef) {}

  @HostListener('click')
  sortData() {
    // Get Reference Of Current Clicked Element
    const elem = this._targetElem.nativeElement;
    // Get In WHich Order list should be sorted by default it should be set to desc on element attribute
    const order = elem.getAttribute('data-order');
    // Get The Property Type specially set [data-type=date] if it is date field
    const type = elem.getAttribute('data-type');
    // Get The Property Name from Element Attribute
    const property = elem.getAttribute('data-name');
    if (order === 'desc') {
      this.psSortTable.sort(this._startSort(property, order, type));
      elem.setAttribute('data-order', 'asc');
    } else {
      this.psSortTable.sort(this._startSort(property, order, type));
      elem.setAttribute('data-order', 'desc');
    }
    this._resetOtherElements(property);
  }

  private _startSort(property: any, order: string, type = '') {
    this._sortOrder = order === 'desc' ? -1 : 1;
    return (a: any, b: any) => {
      if (type === 'date') {
        return this._sortrTable(new Date(a[property]), new Date(b[property]));
      } else {
        return (
          this._collator.compare(a[property], b[property]) * this._sortOrder
        );
      }
    };
  }

  private _sortrTable(a: any, b: any) {
    if (a < b) {
      return -1 * this._sortOrder;
    } else if (a > b) {
      return 1 * this._sortOrder;
    } else {
      return 0 * this._sortOrder;
    }
  }

  private _resetOtherElements(clickedProperty: any) {
    // get reference of the parent element
    const parentElement = this._targetElem.nativeElement.parentElement;
    if (parentElement) {
      // get reference of all the child elements
      const children = parentElement.children;
      for (const childElement of children) {
        // get the property value from each child element attribute
        const property = childElement.getAttribute('data-name');
        if (property && property !== clickedProperty) {
          // reset the property value for the elements which is not clicked
          childElement.setAttribute('data-order', '');
        }
      }
    }
  }
}
