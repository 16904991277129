import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulkSurveyStatusChangeModalComponent } from './bulk-survey-status-change-modal.component';
import { ModalModule } from '@purespectrum1/ui/modal';
import { ButtonModule } from '@purespectrum1/ui/button';

@NgModule({
  declarations: [BulkSurveyStatusChangeModalComponent],
  imports: [CommonModule, ModalModule, ButtonModule],
  exports: [BulkSurveyStatusChangeModalComponent],
})
export class BulkSurveyStatusChangeModalModule {}
