import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToasterService } from '@purespectrum1/ui/toaster-service';

import { CompanyService } from '../../shared/services/company/company.service';
import { SupplierSettingService } from '../supplier-setting-service/supplier-setting.service';
import { CompaniesResponse } from '../../shared/interfaces/company.interface';
import { SupplierSettingObject } from '../../shared/interfaces/supplier-setting.interface';

@Component({
  selector: 'ps-supplier-settings',
  templateUrl: './supplier-settings.component.html',
  styleUrls: ['./supplier-settings.component.css'],
})
export class SupplierSettingsComponent implements OnInit, OnDestroy {
  suppliers: SupplierSettingObject[] = [];
  private _subscription: Subscription = new Subscription();
  constructor(
    private _companyService: CompanyService,
    private _supplierSettingService: SupplierSettingService,
    private _toastrService: ToasterService
  ) {}

  ngOnInit(): void {
    this._getSuppliers();
  }

  _getSuppliers() {
    const suppliers$ = this._companyService.getCompanies().subscribe(
      (response: CompaniesResponse) => {
        this.suppliers = response.companies
          .filter(
            (company) =>
              company.isASupplier ||
              (company.isFusion && !!company.isFusionSupplierActive)
          )
          .map((supplier) => {
            return {
              name: supplier.name,
              id: supplier.id,
              supplierType: supplier.supplier_type === 'public' ? false : true,
              dynamicProfiler: supplier.dynamicProfiler,
              isPrivacyPolicyActive: supplier.isPrivacyPolicyActive,
              isDataQualityQuestionsActive:
                !!supplier.isDataQualityQuestionsActive,
              isAsrRedirectionEnable: supplier.isAsrRedirectionEnable,
              skipPreScreener: supplier.skipPreScreener,
              isFusion: supplier.isFusion,
            };
          });
      },
      (error) => {
        this._toastrService.error(error.error.msg);
      }
    );
    this._subscription.add(suppliers$);
  }

  _buildUpdatePayload(supplierId: number, value: boolean, settingType: string) {
    const mapper: any = {
      supplierType: (_value: boolean) => {
        return {
          supplier_type: _value ? 'private' : 'public',
        };
      },
      dynamicProfiler: (_value: boolean) => {
        return {
          dynamicProfiler: !!_value,
        };
      },
      isPrivacyPolicyActive: (_value: boolean) => {
        return {
          isPrivacyPolicyActive: !!_value,
        };
      },
      isDataQualityQuestionsActive: (_value: boolean) => {
        return {
          isDataQualityQuestionsActive: !!_value,
        };
      },
      isAsrRedirectionEnable: (_value: boolean) => {
        return {
          isAsrRedirectionEnable: !!_value,
        };
      },
      skipPreScreener: (_value: boolean) => {
        return {
          skipPreScreener: !!_value,
        };
      },
    };
    if (!mapper[settingType]) {
      this._toastrService.error('Something went wrong in mapping!');
    }
    return Object.assign({}, mapper[settingType](value), { cmp: supplierId });
  }
  /**
   * on change of any setting, this function invoked from html and then setting saved in db with service invocation.
   * @param supplierId supplier id - unique id for each supplier
   * @param value each setting has boolean value
   * @param settingType setting flag which used to send with PUT supplier setting api
   */
  changeSupplierSetting(
    supplierId: number,
    value: boolean,
    settingType: string
  ) {
    const payload = this._buildUpdatePayload(supplierId, value, settingType);
    const updateSetting$ = this._supplierSettingService
      .updateSupplierSetting(supplierId, payload)
      .subscribe(
        (response) => {
          this._toastrService.success(response.msg);
        },
        (error) => {
          this._toastrService.error(error.error.msg);
        }
      );
    this._subscription.add(updateSetting$);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
