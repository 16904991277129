import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SurveyTransReportService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  surveyTransactionReportData(
    dateRange: { startDate: string | null; endDate: string | null },
    countryCode: string
  ) {
    return this._http.get<SurveyTransactionReportResponse>(
      `${this._baseUrl}/getSurveyTransReport?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&country_code=${countryCode}`
    );
  }
}

interface SurveyTransactionReportResponse {
  apiStatus: string;
  result: Array<EachPayload>;
}

interface EachPayload {
  _id: number;
  Payload: Array<{
    statusCode: number;
    buyerRevenue: number;
    supplierRevenue: number;
    total: number;
    surveyName: string;
  }>;
}
