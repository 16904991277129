import { Component, Inject } from '@angular/core';
import { OEUploadResponse } from '../../../questions-library/questions-library-services/questions-library-services.interface';
import { ModalRef, ModalData } from '@purespectrum1/ui/modal';

@Component({
  selector: 'ps-oe-question-upload-confirmation-modal',
  templateUrl: './oe-question-upload-confirmation-modal.component.html',
  styleUrls: ['./oe-question-upload-confirmation-modal.component.css'],
})
export class OeQuestionUploadConfirmationModalComponent {
  constructor(
    private _modalRef: ModalRef<
      OeQuestionUploadConfirmationModalComponent,
      string
    >,
    @Inject(ModalData) public data: OEUploadResponse
  ) {}

  close() {
    this._modalRef.close();
  }
}
