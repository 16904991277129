import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';
import { Pagination } from '../../shared/types/pagination';
import { environment } from '../../../environments/environment';
import { Constants } from '../operator.constant';
import { USER_DASHBOARD_VIEW_OBJECT } from '../../dashboard/dashboard-constants';
import {
  UserPayload,
  UsersResponse,
  SaveUserResponse,
  UpdateUserResponse,
  UpdateUsersStatusPayload,
  UpdateUsersStatusResponse,
  OperatorUsersResponse,
  ProjectManagers,
  DashboardViewPayload,
} from './user.interface';
import { UserFilter } from '../../dashboard/survey-search/survey-search.component';
import { UserProjectManagers } from '../../dashboard/types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getUsers(pagination: Pagination = Pagination.first(), search: string = '') {
    const params =
      search.length !== 0
        ? { ...pagination.raw(), search }
        : { ...pagination.raw() };

    const query = new URLSearchParams({ ...params, company: 'true' });

    return this._http
      .get<UsersResponse>(`${this._baseUrl}/users?${query.toString()}`)
      .pipe(switchMap((response) => this._verifyUserStatus(response)));
  }

  _verifyUserStatus(response: UsersResponse): Observable<UsersResponse> {
    return from(response.companies).pipe(
      map((user) => {
        const status =
          user.status === Constants.USER_STATUS.PAUSED
            ? Constants.USER_STATUS.DEACTIVE
            : user.status;

        return { ...user, status };
      }),
      toArray(),
      map((users) => ({ ...response, companies: users }))
    );
  }

  getToken(userId: number, userStatus: boolean) {
    return this._http.get<{ token: string }>(
      `${this._baseUrl}/getUserToken/${userId}/${userStatus}`
    );
  }

  updateUserStatus(userId: number, payload: UpdateUsersStatusPayload) {
    return this._http.put<UpdateUsersStatusResponse>(
      `${this._baseUrl}/updateuserstatus/${userId}`,
      payload
    );
  }

  saveUser(payload: UserPayload) {
    return this._http.post<SaveUserResponse>(`${this._baseUrl}/user`, payload);
  }

  updateUser(payload: UserPayload, userId: number) {
    return this._http.put<UpdateUserResponse>(
      `${this._baseUrl}/user/${userId}`,
      payload
    );
  }

  getAllOperatorUser(apiUsers = true) {
    return this._http.get<OperatorUsersResponse>(
      `${this._baseUrl}/operator-users?apiUsers=${apiUsers}`
    );
  }

  getAllBuyerUser(companyId: number, apiUsers = true) {
    return this._http.get<ProjectManagers[]>(
      `${this._baseUrl}/companies/${companyId}/users?apiUsers=${apiUsers}`
    );
  }

  updateProjectManager(
    surveyId: number,
    userId: number | undefined,
    condition: string
  ) {
    let payload = [];
    if (condition === 'add') {
      payload = [
        {
          op: 'replace',
          path: '/pm',
          value: userId,
        },
      ];
    } else {
      payload = [
        {
          op: 'remove',
          path: '/pm',
        },
      ];
    }
    return this._http.patch<UpdateUserResponse>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}`,
      payload
    );
  }

  updateBuyerProjectManager(
    surveyId: number,
    userId: number | undefined,
    condition: string
  ) {
    let payload = [];
    if (condition === 'add') {
      payload = [
        {
          op: 'replace',
          path: '/bpm',
          value: userId,
        },
      ];
    } else {
      payload = [
        {
          op: 'remove',
          path: '/bpm',
        },
      ];
    }
    return this._http.patch<UpdateUserResponse>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}`,
      payload
    );
  }

  setUserViewSelection(userId?: number, payload?: DashboardViewPayload) {
    return this._http.post<DashboardViewPayload>(
      `${this._baseUrl}/users/${userId}/dashboard-view`,
      payload
    );
  }
  getUserViewSelection(userId?: number) {
    return this._http.get<DashboardViewPayload>(
      `${this._baseUrl}/users/${userId}/dashboard-view`
    );
  }

  setSurveyFilterOptions(
    selectedDashboardView: DashboardViewPayload,
    userId: number
  ) {
    let serviceOperatorFilter: UserFilter = {
      current: false,
      search: false,
    };
    let buyerFilter: UserFilter = {
      current: false,
      search: false,
      unassigned: false,
    };
    let projectManagerObject: UserProjectManagers = {
      buyer: undefined,
      serviceOperator: undefined,
    };
    switch (selectedDashboardView.option) {
      case USER_DASHBOARD_VIEW_OBJECT.VIEW_MY_PROJECTS_BUYER:
        buyerFilter = {
          current: true,
          search: false,
          unassigned: false,
          projectManagerId: userId,
        };
        projectManagerObject = {
          buyer: userId,
        };
        break;
      case USER_DASHBOARD_VIEW_OBJECT.VIEW_MY_PROJECTS_OPERATOR:
        serviceOperatorFilter = {
          current: true,
          search: false,
          projectManagerId: userId,
        };
        projectManagerObject = {
          serviceOperator: userId,
        };
        break;
      case USER_DASHBOARD_VIEW_OBJECT.VIEW_BY_PM:
        buyerFilter = {
          current: false,
          search: true,
          unassigned: false,
          projectManagerId: selectedDashboardView.pmId,
        };
        projectManagerObject = {
          buyer: selectedDashboardView.pmId,
        };
        break;
      case USER_DASHBOARD_VIEW_OBJECT.VIEW_UNASSIGNED:
        buyerFilter = {
          current: false,
          search: false,
          unassigned: true,
          projectManagerId: -1,
        };
        projectManagerObject = { buyer: -1 };
        break;
      case USER_DASHBOARD_VIEW_OBJECT.VIEW_BY_OPERATOR:
        serviceOperatorFilter = {
          current: false,
          search: true,
          projectManagerId: selectedDashboardView.pmId,
        };
        projectManagerObject = {
          serviceOperator: selectedDashboardView.pmId,
        };
        break;
      default:
        serviceOperatorFilter = {
          current: false,
          search: false,
        };
        buyerFilter = {
          current: false,
          search: false,
          unassigned: false,
        };
        projectManagerObject = { buyer: undefined, serviceOperator: undefined };
        break;
    }

    return {
      buyerFilterObject: buyerFilter,
      serviceOperatorFilterObject: serviceOperatorFilter,
      projectManagerObject,
    };
  }
}
