export const SURVEY_STATUS = {
  DRAFT: 11,
  LIVE: 22,
  PAUSED: 33,
  CLOSED: 44,
  INVOICE: 55,
};

export const DEFAULT_SURVYE_STATUS_FOR_INVOICE_OPERATIONS = [22, 33, 44];

export const STATUS_OPTIONS_FOR_INVOICE_OPERATIONS = [
  {
    name: 'Live',
    id: 22,
  },
  {
    name: 'Paused',
    id: 33,
  },
  {
    name: 'Closed',
    id: 44,
  },
  {
    name: 'Invoice',
    id: 55,
  },
];

export const ELIGIBLE_SURVEY_STATUS_INVOICE = [22, 33, 44, 55];
