import { Component, HostListener, Input } from '@angular/core';
import { ModalService } from '@purespectrum1/ui/modal';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Router } from '@angular/router';
import {
  ManualFix,
  Reconcilation,
} from 'src/app/buyer/adjustments-reconciliation/adjustments-reconciliation-service/adjustments-reconciliation-service.interface';
import { AdjustmentsReconciliationServiceService } from '../../../../buyer/adjustments-reconciliation/adjustments-reconciliation-service/adjustments-reconciliation-service.service';
import { notifyMessage } from '../../../../constants/notify-message';
import { AdjustmentModalComponent } from '../adjustment-modal/adjustment-modal.component';

@Component({
  selector: 'ps-adjustment-file-upload',
  templateUrl: './adjustment-file-upload.component.html',
  styleUrls: ['./adjustment-file-upload.component.css'],
})
export class AdjustmentFileUploadComponent {
  @Input() adjustmentReconciliatonRecord!: Array<Reconcilation>;
  @Input() adjustmentManualFixRecord!: Array<ManualFix>;
  @Input() userId!: number;
  @Input() userName: string = '';
  @Input() showBackToDashboardButton: boolean = false;
  @Input() showDownloadTemplateText: boolean = false;
  @Input() operationType: string = '';
  isDisableUpload: boolean = true;
  fileName: string = '';
  file!: File;
  private _fdata: FormData = new FormData();
  adjustmentReportStatus: boolean = true;
  adjustmentId?: string;

  constructor(
    private _modal: ModalService,
    private _toastr: ToasterService,
    private _adjustmentReconciliationService: AdjustmentsReconciliationServiceService,
    private _router: Router
  ) {}

  @HostListener('window:beforeunload')
  onUnloadPage() {
    if (this.adjustmentId) {
      this._adjustmentReconciliationService.declinedAdjustmentBeacon(
        this.adjustmentId
      );
    }
  }

  uploadAdjustmentsFile(operationType: string) {
    this._fdata = new FormData(); // clear _fdata
    this._fdata.append('file', this.file);
    this._fdata.append('operationType', operationType);
    this._fdata.append('userId', this.userId.toString());
    this._fdata.append('userName', this.userName);

    const uploadAdvQues$ = this._adjustmentReconciliationService
      .uploadAdjustementFile(this._fdata)
      .subscribe(
        (_res) => {
          if (this.adjustmentReportStatus) {
            this._toastr.success(notifyMessage.successMessage.FILE_ADDED);

            this.adjustmentId = _res._id;
            this.openStatusModal(
              _res.summary,
              _res.surveyId,
              _res.operationType,
              true,
              _res._id
            );
          }
        },
        (err) => {
          const errorMessage = err.error.message;
          if (errorMessage) {
            this._toastr.warning(errorMessage);
          } else {
            this._toastr.error('Sorry, we had an unexpected error');
          }
        },
        () => {
          this.fileName = '';
          this.isDisableUpload = true;
        }
      );
  }

  onFileChange(event: Event) {
    const element = <HTMLInputElement>event.currentTarget;
    this.file = (element.files as FileList)[0];
    if (element.files !== null) {
      this.fileName = this.file?.name;
      const fileExtension = this.fileName?.split('.').pop();
      if (element.files.length == 0) {
        this._toastr.warning(notifyMessage.warningMessage.NO_FILE);
        this.isDisableUpload = true;
        return;
      }
      if (this._validateFileExtention(this.file)) {
        this.isDisableUpload = false;
        this.adjustmentReportStatus = true;
      } else {
        this.isDisableUpload = true;
        this.fileName = '';
      }
    }
  }

  openStatusModal(
    summary: object,
    id: Array<number>,
    type: string,
    status: boolean,
    adjustmentId: string
  ) {
    this._modal.open(AdjustmentModalComponent, {
      data: { summary, id, type, status, adjustmentId },
      disableClose: true,
    });
  }

  private _validateFileExtention(file: File) {
    const validExt = new Array('.xls', '.xlsx', '.csv');
    if (!file) {
      this._toastr.error(notifyMessage.errorMessage.FILE_UPLOAD_MISSING);
      return false;
    }
    const fileExt = file.name.substring(file.name.lastIndexOf('.'));
    if (validExt.indexOf(fileExt) < 0) {
      this._toastr.error(
        notifyMessage.errorMessage.FILE_UPLOAD_ASK_EXTERNAL_BUYER
      );
      return false;
    }
    return true;
  }

  navigateToDashboard() {
    this._router.navigate(['/dashboard']);
  }
}
