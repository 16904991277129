import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'caping' })
export class CapingPipe implements PipeTransform {
  transform(input: any): number | string {
    if (!input) {
      return input;
    }
    let modifiedInput = input.toString().replace(/,/g, '');
    modifiedInput = parseInt(modifiedInput);
    if (modifiedInput < 0) {
      return 0;
    } else if (modifiedInput > 9999) {
      return '-';
    } else {
      return input;
    }
  }
}
