import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToasterService } from '@purespectrum1/ui/toaster-service';

import { forkJoin } from 'rxjs';
import { InvoiceOperationService } from '../invoice-operation-services/invoice-operation.service';
import { AuthService } from '../../../auth/auth.service';
import { SurveyStatus } from '../../../utils/survey-status';
import { IReportFilterField } from '../invoice-operation.constants';
import { STATUS_BUTTONS } from '../../../constants/status-buttons';
import { ModalService } from '@purespectrum1/ui/modal';
import {
  SURVEY_STATUS,
  DEFAULT_SURVYE_STATUS_FOR_INVOICE_OPERATIONS,
  STATUS_OPTIONS_FOR_INVOICE_OPERATIONS,
} from '../../../constants/survey-status';
import { ChangeSurveyStatusModalComponent } from '../../../dashboard/change-survey-status-modal/change-survey-status-modal.component';
import {
  EachCountry,
  EachLanguage,
} from 'src/app/shared/interfaces/create-surveys.interface';
import { EachCompany } from '../../../shared/interfaces/company.interface';
import { BuyerApiService } from '../../../shared/services/buyer-api/survey.service';
import { BulkInvoiceHelperService } from '../../../shared/services/bulk-invoice/bulk-invoice-helper.service';
import { BULK_STATUS_CHANGE_MODAL } from '../../../constants/modal-constants';
import { SurveyUpdate } from '../../../shared/services/buyer-api/survey.interface';
import { BulkSurveyStatusChangeModalComponent } from '../../../shared/ui/bulk-survey-status-change-modal/bulk-survey-status-change-modal.component';
import { MultiSelectedStatus } from '../../../../app/operator/reports-dashboard/transaction-by-status-report/transaction-by-status-report.interface';
import { BillingService } from '../../../shared/services/billing-service/billing.service';
import { ReportService } from '../../download-reports/report-service/report.service';
import { CommonService } from '../../../shared/services/common-service/common-service';
import { BuyerSurveyResponse } from '@purespectrum1/ui/marketplace/shared/interfaces/survey.interface';

@Component({
  selector: 'ps-search-survey-invoice',
  templateUrl: './search-survey-invoice.component.html',
  styleUrls: ['./search-survey-invoice.component.css'],
})
export class SearchSurveyInvoiceComponent implements OnInit {
  constructor(
    // private _manageInvoiceService: ManageInvoiceService,
    private _invoiceOperationService: InvoiceOperationService,
    private _toastr: ToasterService,
    private _auth: AuthService,
    private _router: Router,
    private _modal: ModalService,
    private _buyerApiService: BuyerApiService,
    private _bulkInvoiceHelperService: BulkInvoiceHelperService,
    private _billingService: BillingService,
    private _reportService: ReportService,
    private _commonService: CommonService
  ) {}

  public selectedCountryLanguages: EachLanguage[] = [];
  public countries: Array<EachCountry> = [];
  public desktopMonthlyActUsr: string = '';
  public mobileMonthlyActUsr: string = '';
  public patnrDelvryMulplr: string = '';
  public isCountrySelected: string = '';
  public isLanguageSelected: string = '';
  public isInvoiceSelected: string = '';
  private _startDate: string = '';
  private _endDate: string = '';
  private _selectedStatuses: number[] =
    DEFAULT_SURVYE_STATUS_FOR_INVOICE_OPERATIONS;
  public surveyStatusOptions = STATUS_OPTIONS_FOR_INVOICE_OPERATIONS;
  filterFields = <IReportFilterField>{};
  surveys: SurveyWithSelection[] = [];
  surveyStatus: { [key: number]: string } = SurveyStatus;
  checkedAll: boolean = false;
  private _page: number = 1;
  buttonDisabled: boolean = true;
  selectedSurveys: SurveyWithSelection[] = [];
  buyers: any = [];
  surveyCount: number = 0;
  private _surveyLimit: number = 20;
  private _userType = this._auth.userType;
  private _userCompany!: number;
  private _loadMoreSurveys = true;
  public options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };
  public daterange: any = {};
  public selectedCompany?: EachCompany;
  public statusButtons = STATUS_BUTTONS;
  public SURVEY_STATUS = SURVEY_STATUS;

  ngOnInit() {
    if (this._auth.user?.cmp) {
      this._userCompany = this._auth.user.cmp;
    }
    this._getCountries();
    this._getCompanies();
  }

  private _getCountries() {
    this._commonService.getCountries().subscribe(
      (countriesData) => {
        this.countries = countriesData;
      },
      (err) => {
        this._toastr.error(err.error.msg);
      }
    );
  }

  allChecked() {
    this.surveys.forEach((survey: SurveyWithSelection) => {
      if (this.checkedAll) {
        this.selectedSurveys.push(survey);
        survey.selected = true;
        this.buttonDisabled = false;
      } else {
        this.selectedSurveys.splice(
          this._findIndex(survey.ps_survey_id, this.selectedSurveys),
          1
        );
        survey.selected = false;
        this.buttonDisabled = true;
      }
    });
  }

  singleSelected(index: number, surveyId: number) {
    if (this.surveys.find((survey) => survey.selected == false)) {
      this.checkedAll = false;
    } else {
      this.checkedAll = true;
    }

    if (this.surveys[index].selected) {
      this.selectedSurveys.push(this.surveys[index]);
    } else {
      this.selectedSurveys.splice(
        this._findIndex(surveyId, this.selectedSurveys),
        1
      );
    }
    if (this.selectedSurveys.length) {
      this.buttonDisabled = false;
    } else {
      this.buttonDisabled = true;
    }
  }

  private _findIndex(surveyId: number, selectedSurveys: SurveyWithSelection[]) {
    return selectedSurveys.findIndex(
      (survey) => survey.ps_survey_id === surveyId
    );
  }

  public selectedDate(value: any, datepicker?: any) {
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // use passed valuable to update state
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;
    this._startDate = this._formatDate(this.daterange.start._d);
    this._endDate = this._formatDate(this.daterange.end._d);
    this.searchProjects();
  }

  calendarCanceled() {
    this.daterange.start = '';
    this.daterange.end = '';
    this.daterange.label = '';
    this._startDate = '';
    this._endDate = '';
  }

  // eslint-disable-next-line complexity
  searchProjects() {
    const validSearch = this.validateInvoiceSearch();
    if (!validSearch) {
      return;
    }

    let surveyIds = '';
    let surveyName = '';
    let poNumber = '';
    let buyerId = '';
    let checkBuyers = false;
    this.checkedAll = false;
    this.buttonDisabled = true;
    this.selectedSurveys = [];
    this._page = 1;
    if (this._startDate && this._endDate) {
      const currentDate = this._formatDate(new Date());
      if (this._startDate > currentDate || this._endDate > currentDate) {
        this._toastr.error(
          'Error:  The start / end date cannot be greater than the current date. Please update to continue'
        );
      } else {
        checkBuyers = true;
      }
    }
    this.surveys = [];
    if (this.filterFields.surveyId && this.filterFields.surveyId.length >= 3) {
      surveyIds = this.filterFields.surveyId.replace(/\n/g, ',');
      checkBuyers = true;
    }
    if (
      this.filterFields.surveyName &&
      this.filterFields.surveyName.length >= 3
    ) {
      surveyName = this.filterFields.surveyName;
      checkBuyers = true;
    }
    if (this.filterFields.poNumber && this.filterFields.poNumber.length >= 3) {
      poNumber = this.filterFields.poNumber;
      checkBuyers = true;
    }
    if (this.selectedCompany?.id) {
      buyerId = `${this.selectedCompany.id}`;
      checkBuyers = true;
    }
    if (checkBuyers) {
      this._getBuyerSurvey(
        this._page,
        surveyName,
        poNumber,
        this._startDate,
        this._endDate,
        buyerId,
        surveyIds
      );
    }
  }

  validateInvoiceSearch() {
    if (!this.selectedCompany && this._userType === 'operator') {
      return false;
    }
    return true;
  }

  onScroll() {
    // User should be able to scroll and fetch surveys only where there is initial bunch of surveys already in the table
    if (!this.surveys.length) {
      return;
    }
    this._page = this._page + 1;
    const buyerId = this.validateInvoiceSearch()
      ? this.selectedCompany?.id.toString()
      : '';

    if (this._loadMoreSurveys) {
      let surveyIds = '';
      if (this.filterFields.surveyId) {
        surveyIds = this.filterFields.surveyId.replace(/\n/g, ',');
      }
      this._getBuyerSurvey(
        this._page,
        this.filterFields.surveyName,
        this.filterFields.poNumber,
        this._startDate,
        this._endDate,
        buyerId ?? '',
        surveyIds
      );
    }
  }

  private _getBuyerSurvey(
    pageNo: number,
    searchTitle: string,
    billingPo: string,
    startDate: string,
    endDate: string,
    buyerId: string,
    surveyIds: string
  ) {
    this._invoiceOperationService
      .getBuyerSurvey(
        pageNo,
        searchTitle,
        billingPo,
        startDate,
        endDate,
        buyerId,
        surveyIds,
        this._selectedStatuses
      )
      .subscribe((surveys) => {
        // for page 1 we are reassigning the surveys fetched to this.surveys so no duplicates can be pushed
        // and for others we are doing the pagination thing on scroll with page numbers.
        this._loadMoreSurveys = !!surveys.length;
        if (pageNo === 1) {
          this.surveys = surveys.map((s) => ({ ...s, selected: false }));
        } else {
          this.surveys.push(...surveys.map((s) => ({ ...s, selected: false })));
        }
      });
  }

  async sendToInvoice() {
    const isSingleBuyer = this._buyerValidateToInvoice();
    if (!isSingleBuyer) {
      return false;
    }
    const isSurveysEligibleToInvoice = this._checkSurveysEligibleToInovice();
    if (!isSurveysEligibleToInvoice) {
      return false;
    }
    const isProceed = await this._confirmBillingNumber();
    if (!isProceed) {
      return false;
    }
    const billingPONumbers = [
      ...new Set(
        this.selectedSurveys
          .filter((survey) => survey.billing_id)
          .map((survey: SurveyWithSelection) => survey.billing_id)
      ),
    ];
    const isPoValidationChecked =
      this._pONumberValidateToInvoice(billingPONumbers);
    if (!isPoValidationChecked) {
      return false;
    }
    await this._updateStatusToInvoice(billingPONumbers);
    return true;
  }

  private _confirmBillingNumber() {
    const surveys = this.selectedSurveys.filter((survey) => !survey.billing_id);
    if (
      surveys.length &&
      !this._auth.buyerConfig?.enableMandatoryBillingNumber
    ) {
      return this._billingService.openBillingNumberModal();
    }
    return true;
  }

  private _buyerValidateToInvoice() {
    const maxBuyerSurveyInvoice = 1;
    const buyers = [
      ...new Set(
        this.selectedSurveys.map(
          (survey: SurveyWithSelection) => survey.company_name
        )
      ),
    ];
    if (buyers.length > maxBuyerSurveyInvoice) {
      this._toastr.error(
        'Selection has surveys created from different Buyers. Select only single buyer to continue'
      );
    }
    return true;
  }

  private _checkSurveysEligibleToInovice() {
    const notEligibleToInvoice = this.selectedSurveys.filter(
      (survey: SurveyWithSelection) =>
        survey.ps_survey_status !== SurveyStatus.Closed
    );
    if (notEligibleToInvoice.length) {
      const surveys = notEligibleToInvoice
        .map(function (survey: SurveyWithSelection) {
          return survey.ps_survey_id;
        })
        .join(',');
      this._toastr.error(
        `Error:  The survey ${surveys} must be closed to invoice`
      );
      return false;
    }
    return true;
  }

  private _pONumberValidateToInvoice(billingPONumbers: (number | string)[]) {
    const maxBillingPONumber = 1;
    if (billingPONumbers.length > maxBillingPONumber) {
      this._toastr.warning(
        `Selected Surveys contain multiple Billing/PO Numbers`
      );
      return false;
    }
    return true;
  }

  private async _updateStatusToInvoice(billingPONumbers: (number | string)[]) {
    const [isProceed, , surveysToClose, surveysToInvoice] =
      await this._checkAndShowModalIfSurveysWithSamePo(
        billingPONumbers,
        SurveyStatus.Invoice
      );
    if (!isProceed) {
      return;
    }
    await forkJoin(this._fetchSurveyInvoices(surveysToClose)).toPromise();
    forkJoin(this._fetchSurveyInvoices(surveysToInvoice)).subscribe(
      (results) => {
        const rejectedSurveys: any = [];
        const invoicedSurveys = [];

        if (!results) {
          return;
        }

        results.forEach((result: any, index: number) => {
          if (result.status == 'rejected') {
            rejectedSurveys.push(this.selectedSurveys[index].ps_survey_id);
          } else {
            invoicedSurveys.push(this.selectedSurveys[index].ps_survey_id);
          }
        });
        if (rejectedSurveys.length) {
          this._toastr.error(
            `Error:  The survey ${rejectedSurveys.join(
              ','
            )} is already in Invoice status`
          );
          return;
        }
        if (invoicedSurveys.length) {
          const isMultipleInvoice = invoicedSurveys.length > 1 ? true : false;
          const invoiceType = this._getBuyerInvoiceType(
            this.selectedSurveys[0].company_name
          );
          if (invoiceType === 'MONTHLY') {
            if (isMultipleInvoice) {
              this._toastr.success(
                'Success!  The selected surveys will invoice next month'
              );
            } else if (!isMultipleInvoice) {
              this._toastr.success(
                `Success! The selected survey ${this.selectedSurveys[0].ps_survey_id} will invoice next month`
              );
            }
          } else {
            this._toastr.success(
              'Success!  The selected survey will invoice next month'
            );
          }
        }
      },
      (err) => {
        this._toastr.error(err.error.ps_api_response_message);
      }
    );
  }

  private async _checkAndShowModalIfSurveysWithSamePo(
    billingPONumbers: (number | string)[],
    status: SurveyStatus
  ) {
    let notInvoicedSurveysWithSamePo =
      await this._fetchLaunchedAndNotInvoicedSurveysWithSamePo(
        billingPONumbers
      );
    const selectedSurveyIds = this._fetchSelectedSurveyIds();

    notInvoicedSurveysWithSamePo = notInvoicedSurveysWithSamePo.filter(
      (s) => !selectedSurveyIds.includes(Number(s.ps_survey_id))
    );
    if (!notInvoicedSurveysWithSamePo.length) {
      return this._bulkInvoiceHelperService.decideSurveysToChangeStatusOf(
        status,
        BULK_STATUS_CHANGE_MODAL.NO_BULK_INVOICING_ACTION,
        [],
        selectedSurveyIds
      );
    }
    const action = await this._showModalIfInvoicedSurveysWithSamePo(
      notInvoicedSurveysWithSamePo
    );
    return this._bulkInvoiceHelperService.decideSurveysToChangeStatusOf(
      status,
      action,
      notInvoicedSurveysWithSamePo,
      selectedSurveyIds
    );
  }

  private async _fetchLaunchedAndNotInvoicedSurveysWithSamePo(
    billingPONumbers: (number | string)[]
  ) {
    if (!billingPONumbers.length) {
      return [];
    }
    const billingPONumber = String(billingPONumbers[0]);
    return this._buyerApiService
      .getLaunchedAndNotInvoicedSurveysWithSamePo(billingPONumber)
      .toPromise();
  }

  private async _showModalIfInvoicedSurveysWithSamePo(
    notInvoicedSurveysWithSamePo: BuyerSurveyResponse[]
  ) {
    const notInvoicedSurveyIdsWithSamePo =
      this._bulkInvoiceHelperService.fetchNotInvoicedSortedSurveyIdsWithSamePo(
        notInvoicedSurveysWithSamePo
      );
    const modalRef = this._modal.open(BulkSurveyStatusChangeModalComponent, {
      data: {
        surveyIds: notInvoicedSurveyIdsWithSamePo.join(', '),
        thisAndAllBtnText:
          BULK_STATUS_CHANGE_MODAL.BUTTON.THIS_AND_ALL_LISTED.TEXT
            .INVOICE_OPERATIONS,
        onlyThisBtnText:
          BULK_STATUS_CHANGE_MODAL.BUTTON.ONLY_THIS.TEXT.INVOICE_OPERATIONS,
      },
      width: '65%',
    });
    return modalRef.onClose$.toPromise();
  }

  private _fetchSurveyInvoices(surveysToUpdate: SurveyUpdate[]) {
    return surveysToUpdate.map(({ surveyId, status }) => {
      return this._invoiceOperationService.updateSurveyStatus(
        Number(surveyId),
        { ps_survey_status: Number(status) }
      );
    });
  }

  private _getBuyerInvoiceType(buyerName: string) {
    const buyer = this.buyers.find(
      (_buyer: { name: string }) => _buyer.name == buyerName
    );
    return buyer && buyer.generateInvoice ? buyer.generateInvoice : 'MONTHLY';
  }

  private _getCompanies() {
    this._invoiceOperationService
      .getInvoiceCompaniesData({ buyers: true })
      .subscribe(
        (resp) => {
          if (resp) {
            this.buyers = resp;
          } else {
            this._toastr.warning(
              'Warning:  No companies found. Please update to continue'
            );
          }
        },
        (error) => {
          this._toastr.error(
            'Error: Unable to retrieve Companies. Please refresh and try again'
          );
        }
      );
  }

  setSelectedCompany(company: EachCompany) {
    this.selectedCompany = company;
    this.searchProjects();
  }

  downloadReports() {
    const surveyIds = this._fetchSelectedSurveyIds();
    this.buttonDisabled = true;
    this._invoiceOperationService
      .downloadSTRV3(this._userCompany, surveyIds.toString(), this._userType)
      .subscribe(
        (resp) => {
          this.buttonDisabled = false;
          const fileName = resp.fileName;
          window.location.assign(
            this._invoiceOperationService.downloadFile(
              fileName,
              this._auth.token!
            )
          );
        },
        (error) => {
          this.buttonDisabled = false;
          this._toastr.error('Something went wrong! Please try again.');
        }
      );
  }

  private _fetchSelectedSurveyIds() {
    return [
      ...new Set(
        this.selectedSurveys.map(
          (survey: SurveyWithSelection) => survey.ps_survey_id
        )
      ),
    ];
  }

  private _formatDate(date: Date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('');
  }

  public openStatusModal(survey: SurveyWithSelection, status: string) {
    const surveyUpdateData = {
      surveyId: survey.ps_survey_id,
      status: status,
      is_survey_pause_threshold_triggered:
        survey.is_survey_pause_threshold_triggered,
      survey_pause_threshold: survey.survey_pause_threshold,
      currentCpi: survey.acpi,
      currencySymbol: survey.currency_symbol,
    };
    const modalRef = this._modal.open(ChangeSurveyStatusModalComponent, {
      data: surveyUpdateData,
    });
    modalRef.onClose$.subscribe((mssg) => {
      if (mssg === 'statusSuccessfullyUpdated') {
        this.updateSurveyList(surveyUpdateData);
      }
    });
  }

  public updateSurveyList(surveyUpdateData: SurveyUpdate) {
    const index: number = this.surveys.findIndex(
      (indexElement) => indexElement.ps_survey_id === surveyUpdateData.surveyId
    );
    this.surveys.splice(index, 1);
  }

  public openSurveyDetails(id: number) {
    this._router.navigate([`/dashboard/${id}`]);
  }

  setSelectedStatuses(event: Array<MultiSelectedStatus>) {
    this._selectedStatuses = event.map((status: MultiSelectedStatus) => {
      return status.id;
    });
    this.searchProjects();
  }

  requestSupplierSummaryReport() {
    const surveysIds = this.selectedSurveys.map(
      (selectedSurvey) => selectedSurvey.ps_survey_id
    );
    const email = this._auth.email();
    this._reportService
      .requestMultiSurveysStatementReport(email, surveysIds)
      .subscribe((result) => {
        if (result.status !== 'Success') {
          this._toastr.error(result.message);
        }
        this._toastr.success(result.message);
      });
  }
}

type SurveyWithSelection = BuyerSurveyResponse & {
  selected: boolean;
};
