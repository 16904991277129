import { CompanyResponseObject } from '@purespectrum1/ui/marketplace/shared/interfaces/company.interface';

export interface CompanyFormatterType {
  is(company: CompanyResponseObject): boolean;
  type(): string;
}

export class BuyerCompanyFormatter implements CompanyFormatterType {
  is(company: CompanyResponseObject): boolean {
    return company.isABuyer;
  }

  type(): string {
    return '(Buyer Account)';
  }
}

export class SupplierCompanyFormatter implements CompanyFormatterType {
  is(company: CompanyResponseObject): boolean {
    return company.isASupplier;
  }

  type(): string {
    return '(Supplier Account)';
  }
}

export class OperatorCompanyFormatter implements CompanyFormatterType {
  is(company: CompanyResponseObject): boolean {
    return company.isAnOperator;
  }

  type(): string {
    return '(Operator Account)';
  }
}

export class UnknownCompanyFormatter implements CompanyFormatterType {
  is(company: CompanyResponseObject): boolean {
    return true;
  }

  type(): string {
    return '(Unknown)';
  }
}

export const DEFAULT_COMPANY_FORMATTERS = [
  new BuyerCompanyFormatter(),
  new SupplierCompanyFormatter(),
  new OperatorCompanyFormatter(),
  new UnknownCompanyFormatter(),
];

export class CompanyFormatter {
  constructor(
    private readonly _company: CompanyResponseObject,
    private readonly _formatters: CompanyFormatterType[] = DEFAULT_COMPANY_FORMATTERS
  ) {}

  public format(): CompanyFormatterType {
    const formatter = this._formatters.find((formatterObj) =>
      formatterObj.is(this._company)
    );
    return formatter || new UnknownCompanyFormatter();
  }

  public toString() {
    return `${this._company.name}`;
  }
}
