import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import * as fileSaver from 'file-saver';
import { notifyMessage } from '../../../app/constants/notify-message';
import { BuyerService } from '../../../app/shared/services/buyer/buyer.service';
import { CoreMappingService } from './core-mapping.service';
import {
  GetBuyerResponse,
  Buyer,
} from '../../shared/interfaces/buyer.interface';
import { OperatorSettingsService } from '../operator-settings.service';
import { Constants } from '../operator-settings-constants';

@Component({
  selector: 'ps-core-mappings',
  templateUrl: './core-mappings.component.html',
  styleUrls: ['./core-mappings.component.css'],
})
export class CoreMappingsComponent implements OnDestroy, OnInit {
  private _subscriptions: Subscription = new Subscription();
  selectedBuyer?: {
    name: string;
    id: number;
    blocked: boolean;
    key?: string;
    downloadFileName?: string;
  };
  buyerList: Array<Buyer> = [];

  constructor(
    private _toastr: ToasterService,
    private _buyerService: BuyerService,
    private _coreMappingService: CoreMappingService,
    private _operatorSettingsService: OperatorSettingsService
  ) {}

  ngOnInit(): void {
    const getBuyers = this._buyerService.getBuyers().subscribe(
      (buyerResponse: GetBuyerResponse) => {
        this.buyerList = buyerResponse.company;
        this.buyerList.splice(0, 0, {
          name: 'All',
          id: 0,
          blocked: false,
          tempGenerateInvoice: '',
          invoiceChangeOverTS: 0,
        });
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add(getBuyers);
  }

  selectBuyer(buyer: Buyer) {
    this.selectedBuyer = buyer;
  }

  uploadMappings(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isValidFile = this._operatorSettingsService.validateFileExtention(
      file,
      Constants.FILES_FILTER_LIST
    );
    if (this.selectedBuyer && isValidFile) {
      const fdata = new FormData();
      if (this.selectedBuyer.key) {
        fdata.append('key', this.selectedBuyer.key);
      }
      fdata.append('mappings', file);
      const mappings$ = this._coreMappingService
        .uploadFileCoreMapping(fdata, this.selectedBuyer.id)
        .subscribe(
          (response: any) => {
            this._toastr.success(`Mapping File Uploaded`);
          },
          ({
            data: { message = notifyMessage.errorMessage.SERVER_ERROR } = {},
          } = {}) => {
            this._toastr.error(message);
          }
        );
      this._subscriptions.add(mappings$);
    }
  }

  downloadMappings() {
    if (this.selectedBuyer) {
      const mappings$ = this._coreMappingService
        .downloadCoreMappings(this.selectedBuyer.id)
        .subscribe(
          (result: Blob) => {
            if (this.selectedBuyer) {
              const fileName =
                this.selectedBuyer.name.split(' ').join('') + '.csv';
              fileSaver.saveAs(result, fileName);
            }
          },
          ({
            data: {
              message = notifyMessage.errorMessage.FILE_DOWNLOAD_FAILED,
            } = {},
          } = {}) => {
            this._toastr.error(message);
          }
        );
      this._subscriptions.add(mappings$);
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
