import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { AuthService } from '../../../auth/auth.service';
import { UserService } from '../../../operator/user-service/user.service';
import { SurveyMetaDataService } from '../../../shared/services/survey-meta-data/survey-meta-data.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';

import { AssignOpeartorConstants } from './assign-project-manager-constants';

import {
  ProjectManagers,
  PmPayload,
} from '../../../operator/user-service/user.interface';
import { User } from '../../../shared/types/user.interface';
import { notifyMessage } from '../../../constants/notify-message';

@Component({
  selector: 'ps-assign-project-manager-tab',
  templateUrl: './assign-project-manager-tab.component.html',
  styleUrls: ['./assign-project-manager-tab.component.css'],
})
export class AssignProjectManagerTabComponent implements OnInit, OnDestroy {
  private _subscription: Subscription = new Subscription();

  searchText: string = '';
  public currentProjectManager!: string | undefined;
  public currentProjectManagerAssigned = false;
  public userDetails?: User = {} as User;
  public surveyId: number = 0;

  public allOperatorUser: ProjectManagers[] = [];

  constructor(
    private _userService: UserService,
    private _toastr: ToasterService,
    private _surveyMetaDataService: SurveyMetaDataService,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userDetails = this._authService.user;
    const isServiceOperator =
      this.userDetails?.operatorAcssLvls !== 'none' ? true : false;
    if (isServiceOperator) {
      this._getAllOperators();
    }

    this._getSurveyHeaderData();
  }

  setupSearchInput = function (event: Event) {
    event.preventDefault();
    event.stopPropagation();
  };

  private _getSurveyHeaderData() {
    this._surveyMetaDataService
      .getSurveyHeaderData()
      .subscribe((surveyResponse) => {
        this.surveyId = surveyResponse.surveyId;

        if (surveyResponse && surveyResponse.pmObj && surveyResponse.pmObj.nm) {
          this.currentProjectManagerAssigned = true;
          this.currentProjectManager = surveyResponse.pmObj.nm;
        } else {
          this.currentProjectManagerAssigned = false;
          this.currentProjectManager = '';
        }
      });
  }

  private _getAllOperators() {
    const operatorUsers = this._userService.getAllOperatorUser(false).subscribe(
      (response) => {
        this.allOperatorUser = response.users;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscription.add(operatorUsers);
  }

  setOperator(operatorData: ProjectManagers) {
    const operatorPayload = {
      id: operatorData.id,
      nm: operatorData.name,
      eml: operatorData.email,
    };
    const payload = { pm: operatorPayload };
    this._addOrRemoveProjectManager(payload, AssignOpeartorConstants.ADD);
  }

  setThisOperator() {
    const operatorPayload = {
      id: Number(this.userDetails?.id),
      nm: this.userDetails?.usrName,
      eml: this.userDetails?.eml,
    };
    const payload = { pm: operatorPayload };
    this._addOrRemoveProjectManager(payload, AssignOpeartorConstants.ADD);
  }

  private _addOrRemoveProjectManager(payload: PmPayload, condition: string) {
    const userId = payload.pm.id;
    this._userService
      .updateProjectManager(this.surveyId, userId, condition)
      .subscribe((result) => {
        this.currentProjectManager = payload?.pm.nm;
        this.currentProjectManagerAssigned = true;
        this._getNotificationsAsPerConditions(condition);
      });
  }

  private _getNotificationsAsPerConditions(condition: string) {
    if (condition === AssignOpeartorConstants.REMOVE) {
      this._toastr.success(
        notifyMessage.successMessage.PROJECT_OPERATOR_REMOVED
      );
      this.currentProjectManager = '';
      this.currentProjectManagerAssigned = false;
    } else {
      this._toastr.success(
        notifyMessage.successMessage.PROJECT_OPERATOR_ASSIGNED
      );
    }
  }

  removeOperator() {
    const payload = { pm: {} };
    this._addOrRemoveProjectManager(payload, AssignOpeartorConstants.REMOVE);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
