import { Injectable } from '@angular/core';
import { DashboardQuota } from '@purespectrum1/ui/marketplace/shared/interfaces/manage-quotas.interface';
import { Socket } from 'ngx-socket-io';

import {
  SupplierSurveyStartChannel,
  SupplierChannel,
  DashboardChannel,
} from './../buyer-api/survey.interface';
import {
  BuyerProgressChannelV3,
  SurveyStatusChange,
  QuotaCPIChange,
  SurveyCompletesChange,
  QbsCTUpdate,
} from './socket.interface';
@Injectable({
  providedIn: 'root',
})
export class SocketService {
  buyerDashboardQuotaTab$ = this._socket.fromEvent<DashboardQuota>(
    'buyerDashboardQuotaTab'
  );

  supplierSurveyStartChannelV3$ =
    this._socket.fromEvent<SupplierSurveyStartChannel>(
      'supplierSurveyStartChannelV3'
    );

  supplierChannel$ = this._socket.fromEvent<SupplierChannel>('supplierChannel');

  dashboardChannel$ = this._socket.fromEvent<DashboardChannel>('dashboard');
  buyerProgressChannelV3$ = this._socket.fromEvent<BuyerProgressChannelV3>(
    'buyerProgressChannelV3'
  );

  surveyStatusChange$ =
    this._socket.fromEvent<SurveyStatusChange>('surveyStatusChange');

  trafficChannelsTab$ = this._socket.fromEvent<number>('trafficChannelsTab');

  quotaCpiUpdate$ = this._socket.fromEvent<QuotaCPIChange>('updateQuotaCpi');

  surveyCompletes$ =
    this._socket.fromEvent<SurveyCompletesChange>('surveyCompletes');

  updateQuotaSupTarget$ = this._socket.fromEvent<QbsCTUpdate>(
    'updateQuotaSupTarget'
  );

  constructor(private _socket: Socket) {}
}
