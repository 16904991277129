import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import {
  SupplierSurveyCountResponse,
  SupplierSurveyResponse,
  SupplierSurveysResponse,
  FlexibilityPricesParams,
} from './supplier-api.interface';

import { Quotas } from '../buyer-api/survey.interface';

import { SurveyStatus } from '../../../utils/survey-status';
import { V2SurveysQualification } from '../../interfaces/qualification.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SupplierApiService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getSupplierSurveyCounts(status = SurveyStatus.Live, page = 1, search = '') {
    return this._http.get<SupplierSurveyCountResponse>(
      encodeURI(
        `${this._baseUrl}/suppliers/v2/surveys/count?UI=1&page=${page}&search=${search}&status=${status}&limit=20`
      )
    );
  }

  getSupplierSurveys(
    status = SurveyStatus.Live,
    page: number = 1,
    search = ''
  ) {
    return this._http.get<SupplierSurveysResponse>(
      encodeURI(
        `${this._baseUrl}/suppliers/v2/surveys/new?UI=1&page=${page}&search=${search}&status=${status}&limit=20`
      )
    );
  }

  getSupplierSurvey(surveyId?: number) {
    return this._http.get<SupplierSurveyResponse>(
      `${this._baseUrl}/suppliers/v2/surveys/${surveyId}?UI=1&targeting=0`
    );
  }
  getQualifications(surveyId: number | undefined) {
    return this._http.get<V2SurveysQualification[]>(
      `${this._baseUrl}/suppliers/v2/surveys/${surveyId}/qualifications?UI=1`
    );
  }

  getQuotaDetails(
    surveyId?: number,
    queryParams?: { page: number; limit: number; search: string }
  ) {
    let url = `${this._baseUrl}/suppliers/v2/surveys/${surveyId}/quotas`;
    const paramsOptions = {
      UI: 1,
      QBS: 1,
      ...queryParams,
    };
    const params = new HttpParams({ fromObject: paramsOptions });
    return this._http.get<Quotas[]>(url, { observe: 'response', params }).pipe(
      map((response) => {
        return {
          header: response.headers.get('total'),
          quota: response.body,
        };
      })
    );
  }

  getFlexibilityPricesParams(surveyId: number) {
    return this._http.get<FlexibilityPricesParams>(
      `${this._baseUrl}/supplier/getFlexibilityPricesParams/${surveyId}`
    );
  }
}
