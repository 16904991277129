import { Component, Inject, OnInit } from '@angular/core';
import { ModalData, ModalRef } from '@purespectrum1/ui/modal';

@Component({
  selector: 'ps-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css'],
})
export class ConfirmationModalComponent implements OnInit {
  public data: ConfirmModalData = this._modalData;
  public text!: string[];
  public innerHtml!: string;
  constructor(
    @Inject(ModalData)
    private _modalData: ConfirmModalData,
    private _modalRef: ModalRef<ConfirmationModalComponent, string>
  ) {}

  ngOnInit(): void {
    if (!Array.isArray(this.data.text)) {
      this.text = [this.data.text];
    } else {
      this.text = this.data.text;
    }
    this.innerHtml = this.data.innerHtml;
  }

  close(msg?: string) {
    this._modalRef.close(msg);
  }
}

interface ConfirmModalData {
  text: string | string[];
  innerHtml: string;
  onlyOneTCLive?: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
}
