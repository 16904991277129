<!-- On Import decipher page, we are using iframe, so removing the header and the header will come from iframe. 
Make sure to remove this part when import-decipher page is rewritten in angular. -->
<ng-container>
  <div *ngIf="!hideHeader" class="main_container">
    <ps-header-service-account> </ps-header-service-account>
    <!-- Informing Suppliers if they have PDS relationship with the owner of a particular survey or not -->
    <ng-container *psHasRole="'supplier'">
      <ng-container *ngIf="this.surveyDetailPage && pdsEnabledObj.enabled">
        <h6 class="pds-topbar">
          DIRECT RELATIONSHIP: This buyer, {{ pdsEnabledObj.buyer_name }}, has
          indicated that they will coordinate payment to you outside of the
          PureSpectrum Marketplace.
        </h6>
      </ng-container>
    </ng-container>
    <ng-container *psHasRole="'supplier'">
      <ng-container *ngIf="this.surveyDetailPage && isModularSurvey">
        <h6 class="pds-topbar">
          MODULAR Survey: The LOI of this project can change depending on the
          respondent answers.
        </h6>
      </ng-container>
    </ng-container>

    <header class="dashboard-header">
      <div class="col-auto dash-logo-section">
        <img
          class="img-responsive"
          src="../../../assets/img/logo-without-name.png"
          alt="Logo"
          title=""
          routerLink="/dashboard"
        />
        <p><a href="tel:+877865-PURE">(877) 865-PURE</a></p>
      </div>
      <div
        *ngIf="onCreateSurveyPage"
        class="col-xs-12 col-sm-8 col-md-10 header-div"
      >
        <ps-create-survey-data
          [isRecontactSurvey]="isRecontactSurveyRoute"
        ></ps-create-survey-data>
      </div>
      <div
        *ngIf="!onCreateSurveyPage"
        class="dashbrd-options pull-right text-right"
      >
        <ng-container *ngIf="surveyDetailPage">
          <ng-container *ngIf="enableViewByPM">
            <ps-assign-buyer-project-manager
              *psHasRole="['operator', 'buyer']"
            ></ps-assign-buyer-project-manager>
          </ng-container>
          <ps-assign-project-manager-tab
            *psHasRole="'operator'"
          ></ps-assign-project-manager-tab>
        </ng-container>
        <div class="new-survey-div" *ngIf="showCreate">
          <a
            class="new-survey-button-link"
            (click)="goToCreateSurveyPage()"
            psUiGtagEvent
            trackOn="click"
            action="create_survey"
            category="Survey_Create"
            [params]="{ event_label: 'Create Survey Button Clicked' }"
            ><span>+ New Survey</span></a
          >
          <div class="new-survey-btn">
            <button class="menu-btn" [psUiDropdownToggleFor]="decipherMenu">
              <img
                alt="new survey dropdown-arrow"
                class="dropdown-arrow"
                src="../../../assets/img/down-arrow.png"
              />
            </button>
            <ps-ui-dropdown #decipherMenu="psUiDropdown">
              <a
                psUiDropdownItem
                routerLink="/import-decipher-survey"
                *psConfigEnabled="'decipher'"
                >Import From Decipher</a
              >
              <a psUiDropdownItem routerLink="/recontact-survey"
                >Recontact Survey</a
              >
            </ps-ui-dropdown>
          </div>
        </div>
        <ps-user-menu [menuItems]="entitlements"></ps-user-menu>
      </div>
      <div class="clearfix"></div>
    </header>
  </div>
</ng-container>
