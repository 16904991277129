<!-- Reconciliation Modal -->
<ps-ui-modal
  class="reconcileSummaryModal"
  *ngIf="reconSummaryData"
  [showButtonClose]="false"
>
  <ps-ui-modal-title class="center-align">Reconciliation</ps-ui-modal-title>
  <ps-ui-modal-body>
    <div *ngIf="!reconSummaryData.status">
      <p class="mb-3">Transaction processed successfully:</p>
      <p>
        - {{ reconSummaryData.summary.completes }} transaction_id's already had
        status as completes.
      </p>
      <p>
        - {{ reconSummaryData.summary.eligible }} total transaction_id's which
        now have status as completes.
      </p>
      <p>
        - {{ reconSummaryData.summary.rejected }} transactions have been marked
        as Buyer Rejections
      </p>
      <p>
        - {{ reconSummaryData.summary.eligible }} total transaction_id’s have
        been marked as Reconciled
      </p>
      <p>
        - {{ reconSummaryData.summary.test }} transactions are not valid to
        manually fix
      </p>
      <br />
      <p *ngIf="recoModalSurveyList">
        The transaction_id's changed their status from the following survey/s:
      </p>
      <p class="surveyList-id" *ngIf="recoModalSurveyList">
        {{ recoModalSurveyList }}
      </p>
    </div>
    <!-- Reconciliation modal for upload -->
    <div *ngIf="reconSummaryData.status">
      <p class="mb-3">Transaction processing summary:</p>
      <p>
        - {{ reconSummaryData.summary.completes }} total valid transaction_id's
        uploaded.
      </p>
      <p>
        - {{ reconSummaryData.summary.completes }} transaction_id's already have
        status as completes
      </p>
      <p>
        - {{ reconSummaryData.summary.eligible }} transactions eligible to
        reconcile.
      </p>
      <p>
        - {{ reconSummaryData.summary.rejected }} existing completes will change
        to Buyer Reject status.
      </p>
      <p>
        - {{ reconSummaryData.summary.test }} transactions are not valid to
        manually fix
      </p>
      <br />
      <p *ngIf="recoModalSurveyList">
        Transaction_id's from this file will reconcile completes from following
        survey/s:
      </p>
      <p class="surveyList-id" *ngIf="recoModalSurveyList">
        {{ recoModalSurveyList }}
      </p>
      <br />
      <p class="mb-0">
        Once these transaction_id's are reconciled, the surveys will not accept
        any further reconciliations.
      </p>
      <br />
      <p>
        Please ensure the file contains final list of transaction_id's for the
        survey you wish to reconcile.
      </p>
    </div>
    <br />
    <div class="text-center">
      <a
        *ngIf="!reconSummaryData.status"
        class="status-change-no save_status closeButton"
        data-dismiss="modal"
        (click)="close()"
        >Close</a
      >
      <a
        *ngIf="reconSummaryData.status"
        class="status-change-yes save_status closeButton"
        data-dismiss="modal"
        (click)="confirmAdjustment(reconSummaryData.adjustmentId)"
        >Confirm</a
      >
      <br />
      <a
        *ngIf="reconSummaryData.status"
        class="status-change-no text-decoration-none cancel_status noTksClose"
        data-dismiss="modal"
        (click)="declineAdjustment(reconSummaryData.adjustmentId)"
        >NO, Thanks</a
      >
      <br />
    </div>
  </ps-ui-modal-body>
</ps-ui-modal>
<!-- manual fix modal -->
<ps-ui-modal
  class="reconcileSummaryModal"
  *ngIf="mfSummaryData"
  [showButtonClose]="false"
>
  <ps-ui-modal-title class="center-align">MANUAL FIX</ps-ui-modal-title>
  <ps-ui-modal-body>
    <!-- Manual Fix modal for summary -->
    <div *ngIf="!mfSummaryData.status">
      <p>Transaction processed successfully:</p>
      <p>
        - {{ mfSummaryData.summary.transactions }} total transaction_id's
        changed status as per file uploaded.
      </p>
      <p>
        - {{ mfSummaryData.summary.buyerCPIValue }} transactions changed value
        of BUYER CPI.
      </p>
      <p>
        - {{ mfSummaryData.summary.supplierCPIValue }} transactions changed
        value of SUPPLIER CPI
      </p>
      <p>
        - {{ mfSummaryData.summary.test }} transactions are not valid to
        manually fix
      </p>
      <br />
      <p *ngIf="mfModalSurveyList">
        The transaction_id's changed their status from the following survey/s:
      </p>
      <p class="surveyList-id" *ngIf="mfModalSurveyList">
        {{ mfModalSurveyList }}
      </p>
    </div>
    <!-- Manual Fix modal for upload -->
    <div *ngIf="mfSummaryData.status">
      <p>Transaction processing summary:</p>
      <br />
      <p>
        - {{ mfSummaryData.summary.transactions }} total valid transaction_id's
        uploaded.
      </p>
      <p>
        - {{ mfSummaryData.summary.transactionsStatus }} transaction_id's will
        change status.
      </p>
      <p>
        - {{ mfSummaryData.summary.buyerCPIValue }} transactions will have a
        change in BUYER CPI value.
      </p>
      <p>
        - {{ mfSummaryData.summary.supplierCPIValue }} transactions will have a
        change in SUPPLIER CPI value.
      </p>
      <p>
        - {{ mfSummaryData.summary.test }} transactions are not valid to
        manually fix
      </p>
      <br />
      <p *ngIf="mfModalSurveyList">
        Transaction_id's from this file will manually fix the transaction from
        following survey/s:
      </p>
      <p *ngIf="mfModalSurveyList" class="surveyList-id">
        {{ mfModalSurveyList }}
      </p>
    </div>
    <br />
    <div class="text-center">
      <a
        *ngIf="!mfSummaryData.status"
        class="status-change-no save_status closeButton"
        data-dismiss="modal"
        (click)="close()"
        >Close</a
      >
      <a
        *ngIf="mfSummaryData.status"
        class="status-change-yes save_status closeButton"
        data-dismiss="modal"
        (click)="confirmAdjustment(mfSummaryData.adjustmentId)"
        >Confirm</a
      >
      <br />
      <a
        *ngIf="mfSummaryData.status"
        class="status-change-no text-decoration-none cancel_status noTksClose"
        data-dismiss="modal"
        (click)="declineAdjustment(mfSummaryData.adjustmentId)"
        >NO, Thanks</a
      >
      <br />
    </div>
  </ps-ui-modal-body>
</ps-ui-modal>
