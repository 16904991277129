<main class="container">
  <div class="hashing-tabs">
    <div>
      <h4>Buyer Setting</h4>
    </div>
    <ps-ui-tab-group (activeIndexChange)="checkActiveTab($event)">
      <ps-ui-tab *ngFor="let tab of tabs" [label]="tab.title"></ps-ui-tab>
    </ps-ui-tab-group>

    <form
      class="form-div col-xs-12 col-sm-12 col-md-12 col-md-offset-1"
      *ngIf="form"
      [formGroup]="form"
    >
      <ps-hashing
        [hidden]="activeTab.title !== 'HASHING'"
        [form]="form"
      ></ps-hashing>
      <ps-variable-mapping
        [hidden]="activeTab.title !== 'VARIABLE MAPPING'"
        [form]="form"
      ></ps-variable-mapping>
      <ps-billing-number-rules
        #billingRules
        *ngIf="activeTab.title === 'BILLING NUMBER RULES'"
      ></ps-billing-number-rules>
      <ps-conditions-mapping
        *ngIf="activeTab.title === 'DECIPHER'"
        [isUsedInDefaultMapping]="true"
        [decipherMappings]="decipherMappings"
      ></ps-conditions-mapping>
    </form>

    <div class="hasing-buttons mt-6 pb-4">
      <button
        class="dashboard_button"
        psUiButton
        [routerLink]="['/dashboard/']"
      >
        Back to Dashboard
      </button>
      <button
        class="save_button"
        psUiButton
        (click)="saveSettingData()"
        *ngIf="activeTab.title !== 'DECIPHER'"
      >
        Save Buyer settings
      </button>
    </div>
  </div>
</main>
