import { SURVEY_STATUS } from '../../../constants/survey-status';
import { INVOICE_TYPE } from '../../../constants/invoice-type';

export class BulkEditStatusMapper {
  private _surveyStatus = SURVEY_STATUS;
  private _statusToHide = [SURVEY_STATUS.DRAFT.toString()];
  constructor(generateInvoice: string) {
    if (![INVOICE_TYPE.PROJECT, INVOICE_TYPE.PO].includes(generateInvoice)) {
      this._statusToHide.push(SURVEY_STATUS.INVOICE.toString());
    }
  }

  public list(): Partial<typeof SURVEY_STATUS> {
    const filteredEnteries = Object.entries<number>(this._surveyStatus).filter(
      ([, stValue]) => !this._statusToHide.includes(String(stValue))
    );
    return Object.fromEntries(filteredEnteries);
  }
}
