import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  SupplierPriceCardPayload,
  SupplierPriceCardResponse,
  SupplierResponse,
} from './supplier-pricing-card.interface';

@Injectable({ providedIn: 'root' })
export class SupplierPricingCardService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  saveSupplierPriceCard(payload: SupplierPriceCardPayload) {
    return this._http.post<SupplierPriceCardResponse>(
      `${this._baseUrl}/supplierPriceCard`,
      payload
    );
  }

  getSupplier(lng: number, cntry: number, byr: number) {
    return this._http.get<SupplierResponse>(
      `${this._baseUrl}/supplierPriceCard?language=${lng}&country=${cntry}&buyerCounterParty=${byr}`
    );
  }
}
