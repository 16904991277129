import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { AuthService } from '../../../auth/auth.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ps-legacy-iframe',
  templateUrl: './legacy-iframe.component.html',
  styleUrls: ['./legacy-iframe.component.css'],
})
export class LegacyIframeComponent implements OnInit {
  @Input() path!: string;

  iframeUrl?: SafeUrl;

  constructor(private _auth: AuthService, private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const authInfo = this._encyrptAuthInfo();
    this.iframeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      `${environment.pureSpectrum.legacyPlatformUrl}/#/${this.path}?data=${authInfo}`
    );
  }

  private _encyrptAuthInfo(): string {
    const user = this._auth.user;
    const token = this._auth.token;
    const data = { user, token };
    return btoa(JSON.stringify(data));
  }
}
