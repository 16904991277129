import { Component } from '@angular/core';
import { ToasterService } from '@purespectrum1/ui';

import { QuestionsLibraryService } from '../questions-library-services/questions-library-services.service';
import { messages } from '../questions-library.constants';

const FILE_CONSTANTS = {
  templatePath: `/assets/templates/DQTargetingImportFile.xlsx`,
  allowedTypes: ['xlsx', 'xls', 'csv'],
  name: 'data_quality_question',
};

@Component({
  selector: 'ps-import-data-quality-questions',
  templateUrl: './import-data-quality-questions.component.html',
  styleUrls: ['./import-data-quality-questions.component.css'],
})
export class ImportDataQualityQuestionsComponent {
  fileName: string = '';
  isDisableUpload: boolean = true;

  private _formdata: FormData = new FormData();

  constructor(
    private _questionsLibraryService: QuestionsLibraryService,
    private _toastr: ToasterService
  ) {}

  public onFileChange(event: Event) {
    const element = <HTMLInputElement>event.currentTarget;
    if (element?.files?.length == 0) {
      this._toastr.warning(messages.WARNING_MESSAGE.NO_FILE);
      this.isDisableUpload = true;
      return;
    }

    const file: File = (element.files as FileList)[0];
    this._formdata.delete(FILE_CONSTANTS.name);

    if (file) {
      this.fileName = file?.name;
      const fileExtension = this.fileName?.split('.').pop() as string;

      if (!FILE_CONSTANTS.allowedTypes.includes(fileExtension)) {
        this.isDisableUpload = true;
        this._formdata.delete(FILE_CONSTANTS.name);
        this._toastr.warning(messages.WARNING_MESSAGE.INCORRECT_FILE_FORMAT);
        return;
      }

      this.isDisableUpload = false;
      this._formdata.append(FILE_CONSTANTS.name, file);
      this.uploadDataQualityQuestions(true);
    }
  }

  public downloadFile() {
    window.open(FILE_CONSTANTS.templatePath, '_blank');
  }

  public uploadDataQualityQuestions(validate: boolean) {
    this._questionsLibraryService
      .uploadDataQualityQuestions(this._formdata, validate)
      .subscribe(
        (res) => {
          if (validate) {
            return this._toastr.success(messages.SUCCESS_MESSAGE.FILE_ADDED);
          }
          this._toastr.success(messages.SUCCESS_MESSAGE.DQ_QUESTIONS_UPLOADED);
        },
        (err) => {
          const errorMessage =
            err?.error?.ps_api_response_message ||
            messages.ERROR_MESSAGE.DQ_UPLOAD_UNKNOWN_ERROR;
          this._toastr.warning(errorMessage);
          this._formdata.delete(FILE_CONSTANTS.name);
          this.isDisableUpload = true;
        }
      );
  }
}
