import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ps-hashing',
  templateUrl: './hashing.component.html',
  styleUrls: ['../base-form.css'],
})
export class HashingComponent {
  @Input()
  form!: FormGroup;

  constructor() {}
}
