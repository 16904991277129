import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private _auth: AuthService) {}

  canActivate(): boolean | UrlTree {
    if (!this._auth.isLoggedIn()) {
      return this._router.parseUrl('/login');
    }
    return true;
  }
}
