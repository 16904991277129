import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class CoreMappingService {
  pureSpectrumUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  uploadFileCoreMapping(formData: FormData, buyerId: number): Observable<any> {
    return this._http.post<any>(
      `${this.pureSpectrumUrl}/suppliers/v2/mappings/upload/${buyerId}`,
      formData
    );
  }

  downloadCoreMappings(buyerId: number) {
    return this._http.get<Blob>(
      `${this.pureSpectrumUrl}/suppliers/v2/mappings/${buyerId}`,
      { responseType: 'blob' as 'json' }
    );
  }
}
