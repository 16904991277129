import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

import { Constants } from '../../../constants/trans-status';
import { CommonService } from '../../../shared/services/common-service/common-service';
import { CompanyService } from '../../../shared/services/company/company.service';
import { EachCountry } from '../../../shared/interfaces/common-service.interface';
import { CompanyResponseObject } from '../../../shared/interfaces/company.interface';
import {
  Result,
  Payload,
  SupplierReportService,
} from './supplier-report.service';
import {
  TotalSupplierTableData,
  SupplierTableData,
  ValueDate,
} from './supplier-report.interface';
@Component({
  selector: 'ps-supplier-report',
  templateUrl: './supplier-report.component.html',
  styleUrls: ['./supplier-report.component.css'],
})
export class SupplierReportComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public countries: EachCountry[] = [];
  public buyers!: CompanyResponseObject[];
  public selectedBuyer!: CompanyResponseObject;
  public selectedCountry!: EachCountry;
  public supplierData: Result[] = [];
  public tableTotal!: TotalSupplierTableData;

  constructor(
    private _toastr: ToasterService,
    private _commonService: CommonService,
    private _companyService: CompanyService,
    private _supplierReportService: SupplierReportService,
    private _datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this._getCountries();
    this._getAllBuyers();
  }

  private _getCountries() {
    const addAllOption = true;
    const countriesResponse$ = this._commonService
      .getCountries(addAllOption)
      .subscribe(
        (resp) => {
          this.countries = resp;
          this.selectedCountry = this.countries[0];
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add(countriesResponse$);
  }

  private _getAllBuyers() {
    const payload = {
      isSpplr: false,
      isBuyr: true,
    };
    const buyersResponse$ = this._companyService
      .getAllBuyers(payload, true)
      .subscribe(
        (resp) => {
          this.buyers = resp.companies;
          this.selectedBuyer = this.buyers[0];
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add(buyersResponse$);
  }

  selectCountry(country: EachCountry) {
    this.selectedCountry = country;
  }

  selectBuyer(buyer: CompanyResponseObject) {
    this.selectedBuyer = buyer;
  }

  public datapickerOptions = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };

  public dateRange = {
    start: { _d: Date.now() },
    end: { _d: Date.now() },
    label: '',
  };

  public selectedDate(value: ValueDate) {
    this.dateRange.start = value.start;
    this.dateRange.end = value.end;
  }

  public getReport() {
    const payloadObj = {
      countryCode: this.selectedCountry.short_Code,
      buyerId: this.selectedBuyer.id || 0,
      startDate: this._datepipe.transform(
        this.dateRange.start._d,
        'yyyy-MM-dd'
      ),
      endDate: this._datepipe.transform(this.dateRange.end._d, 'yyyy-MM-dd'),
    };
    const supplierReports$ = this._supplierReportService
      .getSuppliersReport(payloadObj)
      .subscribe(
        (reps) => {
          this.supplierData = reps.result;
          this.tableTotal = {
            supplierRevenue: 0,
            completes: 0,
            totalClicks: 0,
            validClicks: 0,
            epc: 0,
            totalEpc: 0,
          };
          this.supplierData.forEach((supplier: SupplierTableData) => {
            let completes: number = 0;
            let totalClicks: number = 0;
            let validClicks: number = 0;
            let epc: number = 0;
            let totalEpc: number = 0;
            let supplierRevenue: number = 0;
            let supplierName: string = '';

            supplier.Payload.forEach((supTrans: Payload) => {
              if (
                supTrans.statusCode == Constants.TRANSACTION_STATUS.COMPLETE
              ) {
                supplierRevenue = supTrans.supplierRevenue;
                completes = supTrans.total;
              }
              if (
                [
                  Constants.TRANSACTION_STATUS.COMPLETE,
                  Constants.TRANSACTION_STATUS.BUYER_SIDE_IN_PROGRESS,
                  Constants.TRANSACTION_STATUS.BUYER_QUOTAFULL,
                  Constants.TRANSACTION_STATUS.BUYER_DROP,
                  Constants.TRANSACTION_STATUS.BUYER_TERMINATION,
                ].includes(supTrans.statusCode)
              ) {
                validClicks += supTrans.total;
              }
              supplierName = supTrans.Supplier_name;
              totalClicks += supTrans.total;
              epc = Math.round((supplierRevenue / validClicks) * 100) / 100;
            });
            supplier.supplierName = supplierName;
            supplier.supplierRevenue = Math.round(supplierRevenue * 100) / 100;
            supplier.completes = completes;
            supplier.validClicks = validClicks;
            supplier.totalClicks = totalClicks;
            supplier.epc = epc;
            supplier.totalEpc =
              Math.round((supplierRevenue / totalClicks) * 100) / 100;
            this.tableTotal.supplierRevenue +=
              Math.round(supplierRevenue * 100) / 100;
            this.tableTotal.completes += completes;
            this.tableTotal.validClicks += validClicks;
            this.tableTotal.totalClicks += totalClicks;
          });

          this.tableTotal.epc +=
            Math.round(
              (this.tableTotal.supplierRevenue / this.tableTotal.validClicks) *
                100
            ) / 100;
          this.tableTotal.totalEpc +=
            Math.round(
              (this.tableTotal.supplierRevenue / this.tableTotal.totalClicks) *
                100
            ) / 100;
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
