import { Injectable } from '@angular/core';

/**
 * Persist data to session storage and optionally local storage.
 * Handles converting objects to and from JSON automatically
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  /**
   * Save an item in storage using the provided key. Encodes objects as JSON
   */
  setItem(key: string, value: any, options = { useLocalStorage: false }): void {
    if (options.useLocalStorage) {
      // Reset the previous values
      this.removeItem(key);
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  /**
   * Retrieve an item from storage using the provided key. Converts objects back from JSON
   * Checks local storage before session storage
   */
  getItem<T>(key: string): T | null {
    let value = localStorage.getItem(key);

    if (!value) {
      value = sessionStorage.getItem(key);
    }

    if (value) {
      return JSON.parse(value) as T;
    }
    return null;
  }

  /**
   * Remove an item from storage
   */
  removeItem(key: string): void {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  }

  /**
   * Clear all items in storage
   */
  clear(): void {
    sessionStorage.clear();
    localStorage.clear();
  }
}
