import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputDecimalRestrictionDirective } from './input-decimal-restriction.directive';

@NgModule({
  declarations: [InputDecimalRestrictionDirective],
  imports: [CommonModule],
  exports: [InputDecimalRestrictionDirective],
})
export class InputDecimalRestrictionModule {}
