import { Component } from '@angular/core';
import { notifyMessage } from '../../constants/notify-message';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Constants } from '../operator-settings-constants';
import { CrmIdService } from './crm-id.service';

@Component({
  selector: 'ps-crm-id',
  templateUrl: './crm-id.component.html',
  styleUrls: ['./crm-id.component.css'],
})
export class CrmIdComponent {
  constructor(
    private _crmIdService: CrmIdService,
    private _toastr: ToasterService
  ) {}

  public validateAndUploadCrmIdFile(event: Event) {
    const target = event.target as HTMLInputElement;
    const file = (target.files as FileList)[0];
    const isValidFile = this._validateFileAndFileExtension(file);
    if (!isValidFile) {
      return;
    }
    this._uploadCrmIdFile(file);
  }

  private _validateFileAndFileExtension(file: File) {
    if (!file) {
      this._toastr.warning(notifyMessage.warningMessage.FILE_UPLOAD_MISSING);
      return false;
    }
    const fileExt = file.name.substring(file.name.lastIndexOf('.'));
    if (!Constants.CRM_ID.VALID_FILE_EXTENSIONS.includes(fileExt)) {
      this._toastr.warning(
        notifyMessage.warningMessage.INVALID_FILE_EXTENSION.replace(
          '<FILE_EXTENSION>',
          '.xls/.xlsx/.csv'
        )
      );
      return false;
    }
    return true;
  }

  private _uploadCrmIdFile(file: File) {
    const fileData = new FormData();
    fileData.append('file', file);
    this._crmIdService.uploadCrmIdFile(fileData).subscribe(
      (response) => {
        this._toastr.success(
          notifyMessage.successMessage.OPERATOR_SETTINGS.CRM_ID.FILE_UPLOADED
        );
      },
      ({ error }) => {
        const message = error.msg ?? notifyMessage.errorMessage.SERVER_ERROR;
        this._toastr.error(message);
      }
    );
  }

  downloadTemplateFile() {
    window.open(Constants.CRM_ID.templatePath, '_blank');
  }
}
