import { Component, Inject } from '@angular/core';
import { ModalRef, ModalData } from '@purespectrum1/ui/modal';
import { PSIDUploadResponse } from '../../operator-settings.interface';

@Component({
  selector: 'ps-psid-upload-confirmation-modal',
  templateUrl: './psid-upload-confirmation-modal.component.html',
  styleUrls: ['./psid-upload-confirmation-modal.component.css'],
})
export class PsidUploadConfirmationModalComponent {
  constructor(
    private _modalRef: ModalRef<PsidUploadConfirmationModalComponent, string>,
    @Inject(ModalData) public data: PSIDUploadResponse
  ) {}

  close() {
    this._modalRef.close();
  }
}
