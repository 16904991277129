<ps-ui-header
  (closeSurvey)="goToDashboard($event)"
  (openServiceAccountModal)="selectBuyer($event)"
  [isNewSurvey]="true"
></ps-ui-header>
<ps-ui-create-surveys
  [isInsight]="false"
  [surveyId]="surveyId"
  [auth]="auth"
></ps-ui-create-surveys>
