<main class="container">
  <div class="form-section">
    <form
      class="reset-form form-reset-div"
      (ngSubmit)="updatePassword()"
      [formGroup]="form"
    >
      <h2 class="text-center log-in-text">Set Password</h2>
      <div class="mb-3">
        <input
          type="password"
          class="form-control input-tag"
          id="newPassword"
          placeholder="New Password"
          formControlName="cpass"
        />
      </div>
      <div class="mb-3">
        <input
          type="password"
          class="form-control input-tag"
          id="confirmPassword"
          placeholder="Confirm Password"
          formControlName="newpass"
        />
      </div>
      <div class="d-grid">
        <button
          class="btn btn-info btn-lg reset"
          type="submit"
          psUiButton
          [outline]="true"
          [disabled]="form.invalid"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</main>
