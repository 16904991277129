import {
  ChangeSurveyModalData,
  ChangeSurveyModalTemplate,
  ChangeSurveyModalTemplateData,
} from './change-survey-modal.template';

export class DeleteSurveyModalTemplate implements ChangeSurveyModalTemplate {
  constructor(private _data: ChangeSurveyModalData) {}

  getData(): ChangeSurveyModalTemplateData {
    return {
      title: 'DELETE DRAFT',
      body: `Delete Survey # ${this._data.surveyId}?`,
      close: 'No. DO NOT DELETE this project',
      delete: 'Yes, DELETE this project',
    };
  }
}
