<div class="row">
  <div class="col-12 text-center px-0 adjustment-page">
    <div class="table-responsive sort_table mt-4">
      <table class="table table-striped table-hover table-border">
        <thead>
          <tr>
            <th
              scope="col"
              data-order=""
              data-name="fileName"
              [psSortTable]="adjustmentReconciliatonRecord"
            >
              File Name
            </th>
            <th
              scope="col"
              data-order=""
              data-name="crtdOn"
              [psSortTable]="adjustmentReconciliatonRecord"
            >
              Request Date/Time
            </th>
            <th
              scope="col"
              data-order=""
              data-name="summaryTransactions"
              [psSortTable]="adjustmentReconciliatonRecord"
            >
              Number of Transactions
            </th>
            <th
              scope="col"
              data-order=""
              data-name="status_label"
              [psSortTable]="adjustmentReconciliatonRecord"
            >
              Status
            </th>
            <th
              class="text-center"
              scope="col"
              data-order=""
              data-name="showInfoButtons"
            >
              Info
            </th>
          </tr>
        </thead>
        <tbody
          *ngIf="
            operationType === 'RECON' && adjustmentReconciliatonRecord?.length;
            else manualFix
          "
        >
          <tr *ngFor="let list of adjustmentReconciliatonRecord">
            <td>
              <a
                [href]="list.fileUrl"
                class="text-left text-decoration-none"
                target="_blank"
                >{{ list.fileName }}
              </a>
            </td>
            <td class="text-center">
              {{ list.crtdOn | date : 'MM/dd/yyyy HH:m:s' }}
            </td>
            <td class="text-center">{{ list.summary.transactions }}</td>
            <td class="text-center">{{ list.status_label }}</td>
            <td class="text-center">
              <button
                type="button"
                class="btn btn-primary submit-btn mr-20"
                data-target="#reconcileSummaryModal"
                data-toggle="modal"
                *ngIf="list.status_label !== 'Declined'"
                [disabled]="list.status_label !== 'Success' ? 'disabled' : ''"
                (click)="
                  openStatusModal(
                    list.summary,
                    list.surveyId,
                    list.operationType,
                    false,
                    list._id
                  )
                "
              >
                Show Summary
              </button>
              <button
                type="button"
                class="btn btn-primary submit-btn declined_status"
                *ngIf="
                  list.status_label !== 'Declined' &&
                  showDownloadReportButtoonInRecon
                "
                [disabled]="list.status_label !== 'Success' ? 'disabled' : ''"
                (click)="downloadAdjustmentReport(list._id)"
              >
                Download Report
              </button>
            </td>
          </tr>
        </tbody>
        <ng-template #manualFix>
          <tbody
            *ngIf="
              operationType === 'MF' && adjustmentManualFixRecord?.length;
              else noData
            "
          >
            <tr *ngFor="let list of adjustmentManualFixRecord">
              <td>
                <a
                  href="javascript:void(0)"
                  class="text-left text-decoration-none"
                  target="_blank"
                  >{{ list.fileName }}
                </a>
              </td>
              <td class="text-center">
                {{ list.crtdOn | date : 'MM/dd/yyyy HH:m:s' }}
              </td>
              <td class="text-center">{{ list.summary.transactions }}</td>
              <td class="text-center">{{ list.status_label }}</td>
              <td class="text-center">
                <button
                  type="button"
                  class="btn btn-primary submit-btn mr-20"
                  data-target="#reconcileSummaryModal"
                  data-toggle="modal"
                  *ngIf="list.status_label !== 'Declined'"
                  [disabled]="list.status_label !== 'Success' ? 'disabled' : ''"
                  (click)="
                    openStatusModal(
                      list.summary,
                      list.surveyId,
                      list.operationType,
                      false,
                      list._id
                    )
                  "
                >
                  Show Summary
                </button>
                <button
                  type="button"
                  class="btn btn-primary submit-btn declined_status"
                  *ngIf="list.status_label !== 'Declined'"
                  [disabled]="list.status_label !== 'Success' ? 'disabled' : ''"
                  (click)="downloadAdjustmentReport(list._id)"
                >
                  Download Report
                </button>
              </td>
            </tr>
          </tbody>
        </ng-template>
        <ng-template #noData>
          <tbody class="no-data">
            <tr>
              <td [attr.colspan]="5">
                <p>No Data Available In Table</p>
              </td>
            </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
  </div>
</div>
