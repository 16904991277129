import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { AuthService } from '../../auth/auth.service';

import { ResetPasswordService } from '../reset-password-service/reset-password.service';

@Component({
  selector: 'ps-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  form!: FormGroup;
  private _userDetails: any;

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _toastr: ToasterService,
    private _resetPasswordService: ResetPasswordService
  ) {}

  ngOnInit(): void {
    this._userDetails = this._auth.user;
    this.form = this._fb.group({
      cpass: [null, Validators.required],
      newpass: [null, Validators.required],
    });
  }
  updatePassword() {
    if (!this.form.valid) {
      this._toastr.error(
        'Password cannot be blank. Please update to continue.'
      );
      return;
    } else if (this.form.value.newpass != this.form.value.cpass) {
      this._toastr.error('Passwords do not match. Please update to continue.');
      return;
    }
    this._resetPasswordService
      .updatePassword(this._userDetails.id, this.form.value)
      .subscribe(
        (res: any) => {
          this._toastr.success(res.msg);
        },
        (err: { error: { msg: any } }) => {
          const errorMessage = err.error.msg;
          this._toastr.error(errorMessage);
        }
      );
  }
}
