import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import {
  OperatorSettingsResponse,
  PSIDUploadResponse,
} from '../operator-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  getOperatorSettings() {
    return this._http.get<OperatorSettingsResponse>(
      `${this._baseUrl}/operatorSettings/`
    );
  }

  updateOperatorSettings(id: number, data: {}) {
    return this._http.put<OperatorSettingsResponse>(
      `${this._baseUrl}/operatorSettings/${id}`,
      data
    );
  }

  uploadPSIDs(data: FormData) {
    return this._http.post<PSIDUploadResponse>(
      `${this._baseUrl}/operator-settings/purescore/update`,
      data
    );
  }
}
