/**
 * Since we're not using hash routes we can't use angular redirects for matching old routes
 * so instead we'll subscribe to the router and manually redirect for any matching routes
 */
export const legacyRedirects: LegacyRedirect[] = [
  {
    path: '/#/dashboard',
    redirectTo: 'dashboard',
  },
  {
    path: '/#/CreateSurveys',
    redirectTo: 'create-surveys',
  },
  {
    path: '/#/invoiceOperations',
    redirectTo: 'invoice-operations',
  },
  {
    path: '/#/forgotPassword',
    redirectTo: 'forgot-password',
  },
  {
    path: '/#/resetforgotPassword',
    redirectTo: 'resetforgotPassword',
  },
];

interface LegacyRedirect {
  path: string;
  redirectTo: string;
}
