import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalData, ModalRef } from '@purespectrum1/ui';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { AcitivityService } from '../../../shared/services/activity/activity.service';
import { ConfigSettingsModalData } from '../config-settings.component';

@Component({
  selector: 'ps-enable-real-time-notification-modal',
  templateUrl: './enable-real-time-notification-modal.component.html',
  styleUrls: ['./enable-real-time-notification-modal.component.css'],
})
export class EnableRealTimeNotificationModalComponent implements OnInit {
  public form!: FormGroup;

  /**
   * This regex pattern aims to match URLs that start with optional protocols (http://, https://),
   * followed by a domain name, domain extension, and various characters that form the path, query, or fragment part of
   * the URL, as well as allowing `%` as a valid character (for encoded characters).
   *
   * https://regexr.com/7q3qi
   */
  private _urlPattern: RegExp =
    /^(?:(?:http|https):\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=%]+$/;

  public get webhook() {
    return this.form.get('webhook')!;
  }

  constructor(
    @Inject(ModalData) private _modalData: ConfigSettingsModalData,
    private _modalRef: ModalRef<
      EnableRealTimeNotificationModalComponent,
      string
    >,
    private _toastr: ToasterService,
    private _activityService: AcitivityService,
    private _fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.form = this._fb.group({
      webhook: [
        '',
        [Validators.required, Validators.pattern(this._urlPattern)],
      ],
    });
  }

  public save(): void {
    this._activityService
      .register(this.webhook.value, this._modalData.buyerId)
      .subscribe(
        () => {
          this._toastr.success('Webhook registered with success');
        },
        () => {
          this._toastr.error('Unable to save webhook');
          this.close();
        },
        () => this.close()
      );
  }

  public close() {
    this._modalRef.close();
  }
}
