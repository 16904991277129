import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalRef } from '@purespectrum1/ui';

@Component({
  selector: 'ps-dashboard-clear-selection-confirmation',
  template: `<ps-ui-modal [showButtonClose]="false">
    <ps-ui-modal-body>
      <p class="label-text">
        Selecting another status view, the system will reset the survey
        selection.
      </p>
      <p>Do you want to leave your current operation?</p>
    </ps-ui-modal-body>
    <ps-ui-modal-footer>
      <button
        type="button"
        psUiButton
        color="secondary"
        class="btn btn-default footer-btn yes-btn"
        (click)="onConfirm()"
      >
        Yes
      </button>

      <button
        type="button"
        class="btn btn-default footer-btn cancel-btn"
        psUiButton
        (click)="onClose()"
      >
        Cancel
      </button>
    </ps-ui-modal-footer>
  </ps-ui-modal>`,
  styles: [
    `
      :host ::ng-deep .modal-content {
        justify-content: center;
        text-align: center;
      }

      :host ::ng-deep .modal-header {
        display: none;
      }

      .exclamation-style {
        font-size: 60px;
        margin-left: 43%;
      }

      .lable-text {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 18px;
      }

      .footer-btn {
        color: #000;
        width: 50%;
        border: none;
        height: 61px;
        border-radius: 0;
        font-size: 14px;
      }

      .yes-btn {
        background-color: #4a91c5;
      }

      .cancel-btn {
        background-color: #d7d7d7;
      }

      :host ::ng-deep .modal-footer > * {
        margin: 0 !important;
      }

      :host ::ng-deep .modal-footer {
        padding: 0 !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DashboardClearSelectionConfirmation {
  constructor(private _modalRef: ModalRef<unknown, boolean>) {}

  public onConfirm() {
    this._modalRef.close(true);
  }

  public onClose() {
    this._modalRef.close(false);
  }
}
