<form [formGroup]="form">
  <div class="row">
    <div class="col mb-3">
      <label for="parent-question">Parent Question</label>
      <ps-ui-select-dropdown
        id="parent-question"
        labelNone="Select parent question"
        field="question_description"
        [dropdownItems]="questions"
        [loading]="loading"
        (selectedItem)="parentQuestionSelected($event)"
        formControlName="parentQuestion"
        [searchByKeys]="['qualification_id']"
        [disabled]="
          isEditLocalizationMode && class !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
        "
        [ngClass]="{
          'not-allowed-cursor':
            isEditLocalizationMode &&
            class !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
        }"
      ></ps-ui-select-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label for="condition" class="d-block">Condition</label>
      <div
        class="form-check form-check-inline"
        *ngFor="let condition of conditions"
      >
        <input
          class="form-check-input"
          type="radio"
          [id]="'condition-' + condition.key"
          formControlName="condition"
          [value]="condition.key"
          [attr.disabled]="
            isEditLocalizationMode &&
            class !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
              ? true
              : null
          "
          [ngClass]="{
            'not-allowed-cursor':
              isEditLocalizationMode &&
              class !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
          }"
        />
        <label class="form-check-label" [for]="'condition-' + condition.key">{{
          condition.value
        }}</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label for="qualification-options">Qualification Options</label>
      <ps-ui-select-dropdown
        id="qualification-options"
        labelNone="Pick options"
        class="qual-opts-dropdown"
        [clearSearch]="false"
        [field]="'name'"
        [multi]="true"
        [loading]="loading"
        [disabled]="
          isEditLocalizationMode && class !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
        "
        [ngClass]="{
          'not-allowed-cursor':
            isEditLocalizationMode &&
            class !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
        }"
        [dropdownItems]="qualificationOptions"
        formControlName="qualificationOptions"
      ></ps-ui-select-dropdown>
    </div>
  </div>
</form>
