import { BulkEditOption, BulkEditPayloadValue } from '../types';
import {
  BulkEditBillingNumberView,
  BulkEditCpiView,
  BulkEditCpiWithTargetMarginView,
  BulkEditDeleteDraftView,
  BulkEditPMView,
  BulkEditPOView,
  BulkEditStatusView,
  BulkMarginView,
} from './bulk-view';
import { BulkView } from './types';
import { ProjectManagers as ProjectManager } from '../../../operator/user-service/user.interface';
import { BuyerConfig } from '../../../auth/buyer-config/buyer-config.service.interface';
import { CurrencyExchange } from '../../../shared/types/currency-service-interface';

export class BulkEditViewFactory {
  constructor(
    private readonly _option: BulkEditOption,
    private readonly _newValue: BulkEditPayloadValue,
    private readonly _buyerConfig: BuyerConfig,
    private readonly _isServiceBuyer: boolean,
    private readonly _currencyExchange = <CurrencyExchange>{}
  ) {}

  public create(): BulkView {
    switch (this._option.type) {
      case 'cpi':
        return this._isServiceBuyer && this._buyerConfig.enableTargetMargin
          ? new BulkEditCpiWithTargetMarginView(
              this._newValue as string,
              this._currencyExchange
            )
          : new BulkEditCpiView(this._newValue as string);
      case 'status':
        return new BulkEditStatusView(this._newValue as string);
      case 'projectManager':
        return new BulkEditPMView(
          this._newValue as ProjectManager,
          this._option.role!
        );
      case 'accountManager':
        return new BulkEditPOView(
          this._newValue as ProjectManager,
          this._option.role!
        );
      case 'billingNumber':
        return new BulkEditBillingNumberView(this._newValue as string);
      case 'margin':
        return new BulkMarginView(this._newValue as string);
      case 'deleteDraft':
        return new BulkEditDeleteDraftView();
      default:
        throw new Error(`Invalid option`);
    }
  }
}
