<div class="container daily-statics-form-settiings dash-report">
  <p class="report-title-spacing">Daily Statistics Report</p>

  <div class="row">
    <div class="col-sm-6 col-md-4 form-group">
      <label for="Country">Country</label>
      <ps-ui-select-dropdown
        [dropdownItems]="countries"
        [field]="'name'"
        labelNone="Select Country"
        [label]="selectedCountry?.name"
        (selectedItem)="selectCountry($event)"
      >
      </ps-ui-select-dropdown>
    </div>
    <div class="form-group col-xs-12 col-md-6 col-lg-4">
      <label for="dateRange">Date Range</label>
      <input
        class="form-control date-range"
        type="text"
        name="daterangeInput"
        daterangepicker
        [options]="options"
        (selected)="selectedDate($event)"
      />
    </div>
  </div>
  <div class="row mt-12 justify-content-center">
    <div class="hasing-buttons mt-6 col-sm-4">
      <button
        type="submit"
        psUiButton
        color="primary"
        size="large"
        (click)="getReport()"
      >
        Find
      </button>
    </div>
  </div>
  <!-- Stats Table -->
  <div
    class="row table-responsive table-absolute mt-5"
    *ngIf="statsData.length > 0"
  >
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th
            scope="col"
            [psSortTable]="statsData"
            data-order=""
            data-name="buyerRevenue"
          >
            Total Revenue
          </th>
          <th
            scope="col"
            [psSortTable]="statsData"
            data-order=""
            data-name="overallCpi"
          >
            Overall CPI
          </th>
          <th
            scope="col"
            [psSortTable]="statsData"
            data-order=""
            data-name="supplierRevenue"
          >
            Total Cost
          </th>

          <th
            scope="col"
            [psSortTable]="statsData"
            data-order=""
            data-name="margin"
          >
            Margin
          </th>
          <th
            scope="col"
            [psSortTable]="statsData"
            data-order=""
            data-name="completes"
          >
            Completes
          </th>
          <th
            scope="col"
            [psSortTable]="statsData"
            data-order=""
            data-name="marginPerComplete"
          >
            Margin per Complete
          </th>
          <th
            scope="col"
            [psSortTable]="statsData"
            data-order=""
            data-name="buyerClicks"
          >
            Buyer Clicks
          </th>
          <th
            scope="col"
            [psSortTable]="statsData"
            data-order=""
            data-name="validClicks"
          >
            Valid Clicks
          </th>
          <th
            scope="col"
            [psSortTable]="statsData"
            data-order=""
            data-name="totalClicks"
          >
            Total Clicks
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of statsData">
          <td>{{ data.buyerRevenue || 0 }}</td>
          <td>{{ data.overallCpi || 0 }}</td>
          <td>{{ data.supplierRevenue || 0 }}</td>
          <td>{{ data.margin || 0 }}</td>
          <td>{{ data.completes }}</td>
          <td>{{ data.marginPerComplete || 0 }}</td>
          <td>{{ data.buyerClicks || 0 }}</td>
          <td>{{ data.validClicks || 0 }}</td>
          <td>{{ data.totalClicks || 0 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
