import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private _surveyCreationSource = new BehaviorSubject({});
  private _mapPsidModalClose = new BehaviorSubject<PsidModelClose>({
    close: false,
  });
  private _isPageLoaderDisplay$ = new BehaviorSubject<boolean>(true);

  currentSurveySource = this._surveyCreationSource.asObservable();
  constructor() {}

  setIsSurveyCreatedTrue(isSurveyCreated: boolean, surveyId: number) {
    this._surveyCreationSource.next({
      isSurveyCreated: isSurveyCreated,
      surveyId: surveyId,
    });
  }

  setPsidModelClose(close: boolean) {
    this._mapPsidModalClose.next({
      close: close,
    });
  }

  getPsidModelClose(): Observable<PsidModelClose> {
    return this._mapPsidModalClose.asObservable();
  }

  setIsPageLoaderDisplay(display: boolean) {
    this._isPageLoaderDisplay$.next(display);
  }

  getIsPageLoaderDisplay(): Observable<boolean> {
    return this._isPageLoaderDisplay$.asObservable();
  }
}

interface MapQuotas {
  mapQuotas: boolean;
}
interface PsidModelClose {
  close: boolean;
}
