<div class="form-section">
  <a href="#">
    <img
      class="img-responsive center-block login-logo"
      src="../../../assets/img/logo-login-ps.png"
      alt="Logo"
      title=""
    />
  </a>

  <form
    class="login-form form-login-div"
    (ngSubmit)="login()"
    [formGroup]="form"
  >
    <h2 class="text-center log-in-text">Marketplace Login</h2>

    <div class="mb-3">
      <input
        type="email"
        class="form-control input-tag"
        id="LoginEmail"
        placeholder="Email"
        formControlName="email"
      />
    </div>
    <div class="mb-3">
      <input
        type="password"
        class="form-control input-tag"
        id="LoginPassword"
        placeholder="Password"
        formControlName="password"
      />
    </div>
    <div class="mb-3">
      <re-captcha
        *ngIf="showCaptcha"
        [siteKey]="captchaSiteKey"
        (resolved)="resolved($event)"
        formControlName="captcha"
      >
      </re-captcha>
    </div>
    <ul class="list-inline list-unstyled">
      <li>
        <div class="checkbox">
          <label>
            <input type="checkbox" formControlName="rememberme" />
            Remember me
          </label>
        </div>
      </li>
      <li class="text-right">
        <a class="login-text forgot-pw" routerLink="/forgot-password"
          >Forgot Password?</a
        >
      </li>
    </ul>
    <div class="d-grid">
      <button
        class="btn btn-info btn-lg login login-button"
        psUiButton
        [outline]="true"
        [disabled]="form.invalid"
      >
        LOG IN
      </button>
    </div>
    <div class="sign-up">
      <p class="text-center login-form-p">
        Don't have an account?
        <a href="https://www.purespectrum.com/signup.html" class="login-text"
          >Sign Up Today</a
        >
      </p>
    </div>
  </form>
</div>
<footer class="ps-footer">
  <a href="tel:(877) 865-PURE">(877) 865-PURE</a>
  <p>Copyright © 2022 - PureSpectrum - All rights reserved</p>
</footer>
