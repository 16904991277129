import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { UserType } from '../../../shared/types';

/**
 * Directive for automatically showing and hiding content based on the user's role
 * Similar to *ngIf="userType === role" but without needing to pull in the auth service
 */
@Directive({
  selector: '[psHasRole]',
})
export class HasRoleDirective {
  private _hasView = false;

  @Input() set psHasRole(role: UserType | UserType[]) {
    const roles = Array.isArray(role) ? role : [role];

    if (roles.includes(this._auth.userType) && !this._hasView) {
      this._viewContainer.createEmbeddedView(this._templateRef);
      this._hasView = true;
    } else if (!roles.includes(this._auth.userType) && this._hasView) {
      this._viewContainer.clear();
      this._hasView = false;
    }
  }

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _auth: AuthService
  ) {}
}
