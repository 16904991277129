import { GroupSupplier, SupplierBlend } from '@purespectrum1/ui';
import { deepCopy } from '../../../../app/utils/deep-copy';

export class FlexibilityClass {
  constructor() {}

  updateFlexValue(supplierBlendData: SupplierBlend, flexValue: number) {
    const supplierBlend = deepCopy(supplierBlendData);
    supplierBlend.suppliers.forEach((supplier: GroupSupplier) => {
      supplier.flexibility_percentage = flexValue;
    });
    return supplierBlend;
  }
}
