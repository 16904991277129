<ps-ui-modal>
  <ps-ui-modal-body>
    <div>
      <i
        class="fa fa-exclamation-circle exclamation-style"
        aria-hidden="true"
      ></i>
      <p class="label-text" *ngIf="!fileUploading">
        You are updating the Genpop information for
        {{ genpopSelectedQualificationName }} in {{ genpopSelectedCountry }} and
        {{ genpopSelectedLanguage }}. Do you want to continue?
      </p>
      <p class="label-text" *ngIf="fileUploading">File is processing ...</p>
    </div>
  </ps-ui-modal-body>
  <ps-ui-modal-footer>
    <button
      type="button"
      psUiButton
      color="secondary"
      (click)="close()"
      [disabled]="fileUploading"
      class="cancel-btn btn btn-default pull-right"
    >
      Cancel
    </button>
    <button
      type="button"
      psUiButton
      color="primary"
      (click)="uploadFile()"
      [disabled]="fileUploading"
      class="clear-btn btn btn-primary pull-left"
    >
      Continue
    </button>
  </ps-ui-modal-footer>
</ps-ui-modal>
