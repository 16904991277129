import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdjustmentModalComponent } from './adjustment-modal/adjustment-modal.component';
import { AdjustmentTableComponent } from './adjustment-table/adjustment-table.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from '@purespectrum1/ui/dropdown';
import { TabsModule } from '@purespectrum1/ui/tabs';
import { ButtonModule } from '@purespectrum1/ui/button';
import { ModalModule } from '@purespectrum1/ui/modal';
import { SortTableModule } from '../../../sort-table-directive/sort-table.module';
import { AdjustmentFileUploadComponent } from './adjustment-file-upload/adjustment-file-upload.component';

@NgModule({
  declarations: [
    AdjustmentTableComponent,
    AdjustmentModalComponent,
    AdjustmentFileUploadComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    TabsModule,
    ButtonModule,
    ModalModule,
    SortTableModule,
  ],
  exports: [
    AdjustmentTableComponent,
    AdjustmentModalComponent,
    AdjustmentFileUploadComponent,
  ],
})
export class AdjustmentModule {}
