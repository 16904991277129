<div class="manual-rate-card-div">
  <form (ngSubmit)="save()" [formGroup]="form">
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label for="EPC" class="col-form-label pull-right">EPC</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <input
          type="number"
          id="EPC"
          name="epc"
          class="form-control"
          formControlName="EPCM"
          placeholder="EPC Value"
          psInputDecimalRestriction
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label for="EPC" class="col-form-label pull-right">Base Reward</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <input
          type="number"
          id="EPC"
          name="epc"
          class="form-control"
          formControlName="EPCFloor"
          placeholder="EPC Floor"
          psInputDecimalRestriction
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label for="EPC" class="col-form-label pull-right">Minimum Price</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <input
          type="number"
          id="EPC"
          name="epc"
          class="form-control"
          formControlName="minimumPrice"
          placeholder="Minimum Price"
          psInputDecimalRestriction
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label for="EPC" class="col-form-label pull-right"
          >Minimum Price Per Minute</label
        >
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <input
          type="number"
          id="EPC"
          name="epc"
          class="form-control"
          formControlName="minimumPricePerMinute"
          placeholder="Minimum Price Per Minute"
          psInputDecimalRestriction
        />
      </div>
    </div>
    <div class="manual-rate-card-buttons">
      <div class="pull-left">
        <a class="btn btn-primary submit-btn" href="/">
          <span>Back to Dashboard</span>
        </a>
      </div>
      <div class="pull-left">
        <button
          type="button"
          class="btn btn-primary submit-btn"
          [disabled]="deactivateFormulaPriceCard === false"
          (click)="deactivatePriceCard()"
        >
          Deactivate
        </button>
      </div>

      <div class="pull-right">
        <button
          type="submit"
          class="btn btn-primary submit-btn"
          [disabled]="!form.valid"
        >
          Save
        </button>
      </div>
      <div class="pull-right">
        <button
          type="button"
          class="btn btn-primary submit-btn"
          [disabled]="!form.valid"
          (click)="cancel()"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
