import { SurveyListing } from '../../../shared/services/buyer-api/survey.interface';
import { BulkEditPayloadValue } from '../types';
import { PatchSurveyInterface } from '@purespectrum1/ui/marketplace/shared/interfaces/survey.interface';
import { ProjectManagers } from 'src/app/operator/user-service/user.interface';

export interface BulkPayload<T> {
  survey(): number;
  value(): T;
  payload(): PatchSurveyInterface[];
  update(value: BulkEditPayloadValue): BulkPayload<T>;
}

export class BulkPayloadValueCasting {
  constructor(private readonly _value: BulkEditPayloadValue) {}

  public number(): number {
    if (typeof this._value === 'string') {
      return parseInt(this._value, 10);
    }

    if (typeof this._value === 'number') {
      return this._value;
    }

    throw new Error('Unable to parse value');
  }

  public float(): number {
    if (typeof this._value === 'string') {
      return parseFloat(this._value);
    }

    if (typeof this._value === 'number') {
      return this._value;
    }

    throw new Error('Unable to parse value');
  }

  public string(tryConvert = false): string {
    if (typeof this._value === 'string') {
      return this._value;
    }

    if (tryConvert) {
      return this._value.toString();
    }

    throw new Error('Unable to convert value to string');
  }

  public manager(): ProjectManagers {
    if (typeof this._value === 'object' && 'id' in this._value) {
      return this._value as ProjectManagers;
    }

    throw new Error('Unable to cast to project manager');
  }
}

abstract class BulkEditPayloadBase<T> implements BulkPayload<T> {
  constructor(private readonly _survey: number, private readonly _value: T) {}

  public survey(): number {
    return this._survey;
  }

  public value(): T {
    return this._value;
  }

  abstract payload(): PatchSurveyInterface[];
  abstract update(value: BulkEditPayloadValue): BulkPayload<T>;
}

export class BulkEditCpiPayload extends BulkEditPayloadBase<number> {
  constructor(survey: number, value: BulkEditPayloadValue) {
    super(survey, new BulkPayloadValueCasting(value).float());
  }

  public payload(): PatchSurveyInterface[] {
    return [
      {
        op: 'replace',
        path: '/offer_price',
        value: this.value(),
      },
    ];
  }

  public update(value: BulkEditPayloadValue): BulkPayload<number> {
    return new BulkEditCpiPayload(this.survey(), value);
  }
}

export class BulkEditMarginPayload extends BulkEditPayloadBase<number> {
  constructor(survey: number, value: BulkEditPayloadValue) {
    super(survey, new BulkPayloadValueCasting(value).number());
  }

  public payload(): PatchSurveyInterface[] {
    return [
      {
        op: 'replace',
        path: '/margin',
        value: this.value(),
      },
    ];
  }

  public update(value: BulkEditPayloadValue): BulkPayload<number> {
    return new BulkEditMarginPayload(this.survey(), value);
  }
}

export class BulkEditProjectManagerPayload extends BulkEditPayloadBase<ProjectManagers> {
  constructor(survey: number, value: BulkEditPayloadValue) {
    super(survey, new BulkPayloadValueCasting(value).manager());
  }

  public payload(): PatchSurveyInterface[] {
    return [
      {
        op: 'replace',
        path: '/bpm',
        value: this.value().id,
      },
    ];
  }

  public update(value: BulkEditPayloadValue): BulkPayload<ProjectManagers> {
    return new BulkEditProjectManagerPayload(this.survey(), value);
  }
}

export class BulkEditAccountManagerPayload extends BulkEditPayloadBase<ProjectManagers> {
  constructor(survey: number, value: BulkEditPayloadValue) {
    super(survey, new BulkPayloadValueCasting(value).manager());
  }

  public payload(): PatchSurveyInterface[] {
    return [
      {
        op: 'replace',
        path: '/pm',
        value: this.value().id,
      },
    ];
  }

  public update(value: BulkEditPayloadValue): BulkPayload<ProjectManagers> {
    return new BulkEditAccountManagerPayload(this.survey(), value);
  }
}

export class BulkEditBillingNumberPayload extends BulkEditPayloadBase<string> {
  constructor(survey: number, value: BulkEditPayloadValue) {
    super(survey, new BulkPayloadValueCasting(value).string());
  }

  public payload(): PatchSurveyInterface[] {
    return [
      {
        op: 'replace',
        path: '/billing_id',
        value: this.value(),
      },
    ];
  }

  public update(value: BulkEditPayloadValue): BulkPayload<string> {
    return new BulkEditBillingNumberPayload(this.survey(), value);
  }
}

export class BulkEditStatusPayload extends BulkEditPayloadBase<number> {
  constructor(survey: number, value: BulkEditPayloadValue) {
    super(survey, new BulkPayloadValueCasting(value).number());
  }

  public payload(): PatchSurveyInterface[] {
    return [
      {
        op: 'replace',
        path: '/ps_survey_status',
        value: this.value(),
      },
    ];
  }

  public update(value: BulkEditPayloadValue): BulkPayload<number> {
    return new BulkEditStatusPayload(this.survey(), value);
  }
}

export class BulkEditDeleteDraftPayload extends BulkEditPayloadBase<number> {
  constructor(survey: number, value: BulkEditPayloadValue) {
    super(survey, new BulkPayloadValueCasting(value).number());
  }

  public payload(): PatchSurveyInterface[] {
    return [
      {
        op: 'delete',
        path: '/ps_survey_status',
        value: this.survey(),
      },
    ];
  }

  public update(value: BulkEditPayloadValue): BulkPayload<number> {
    return new BulkEditStatusPayload(this.survey(), value);
  }
}

export class BulkEditPayloadFormat {
  constructor(
    private readonly _value: BulkEditPayloadValue,
    private readonly _type: string,
    private readonly _surveys: SurveyListing[]
  ) {}

  public payloads(): BulkPayload<BulkEditPayloadValue>[] {
    return this._surveys.map((survey) => this._format(survey));
  }

  private _format(survey: SurveyListing): BulkPayload<BulkEditPayloadValue> {
    switch (this._type) {
      case 'cpi':
        return new BulkEditCpiPayload(survey.surveyId, this._value);
      case 'projectManager':
        return new BulkEditProjectManagerPayload(survey.surveyId, this._value);
      case 'accountManager':
        return new BulkEditAccountManagerPayload(survey.surveyId, this._value);
      case 'billingNumber':
        return new BulkEditBillingNumberPayload(survey.surveyId, this._value);
      case 'status':
        return new BulkEditStatusPayload(survey.surveyId, this._value);
      case 'margin':
        return new BulkEditMarginPayload(survey.surveyId, this._value);
      case 'deleteDraft':
        return new BulkEditDeleteDraftPayload(survey.surveyId, this._value);
      default:
        throw new Error(`Formatter for type '${this._type}' not found.`);
    }
  }
}
