<div class="container daily-statics-form-settiings dash-report">
  <p class="report-title-spacing">Survey Transaction Report</p>
  <div class="row">
    <div class="col-sm-6 col-md-4 form-group">
      <label for="Country">Country</label>
      <ps-ui-select-dropdown
        [dropdownItems]="countries"
        [field]="'name'"
        [enableSearch]="true"
        labelNone="Select Country"
        [label]="selectedCountry?.name"
        (selectedItem)="selectCountry($event)"
      >
      </ps-ui-select-dropdown>
    </div>
    <div class="form-group col-xs-12 col-md-6 col-lg-4">
      <label for="dateRange">Date Range</label>
      <input
        class="form-control date-range"
        type="text"
        name="daterangeInput"
        daterangepicker
        [options]="options"
        (selected)="selectedDate($event)"
      />
    </div>
  </div>
</div>

<div class="row mt-12 justify-content-center">
  <div class="hasing-buttons mt-6 col-sm-4">
    <button
      class="btn submit-btn"
      type="submit"
      psUiButton
      color="primary"
      size="large"
      (click)="findReportData()"
    >
      Find
    </button>
  </div>
</div>

<div
  class="row table-responsive table-absolute mt-5"
  *ngIf="transactionData.length > 0"
>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th
          scope="col"
          data-order=""
          data-name="_id"
          [psSortTable]="transactionData"
        >
          Survey Id
        </th>
        <th
          scope="col"
          data-order=""
          data-name="surveyName"
          [psSortTable]="transactionData"
        >
          Survey Name
        </th>
        <th
          scope="col"
          data-order=""
          data-name="buyerRevenue"
          [psSortTable]="transactionData"
        >
          Total Revenue
        </th>
        <th
          scope="col"
          data-order=""
          data-name="supplierRevenue"
          [psSortTable]="transactionData"
        >
          Total Cost
        </th>
        <th
          scope="col"
          data-order=""
          data-name="margin"
          [psSortTable]="transactionData"
        >
          Margin
        </th>
        <th
          scope="col"
          data-order=""
          data-name="completes"
          [psSortTable]="transactionData"
        >
          Completes
        </th>
        <th
          scope="col"
          data-order=""
          data-name="buyerClicks"
          [psSortTable]="transactionData"
        >
          Buyer Clicks
        </th>
        <th
          scope="col"
          data-order=""
          data-name="validClicks"
          [psSortTable]="transactionData"
        >
          Valid Clicks
        </th>
        <th
          scope="col"
          data-order=""
          data-name="totalClicks"
          [psSortTable]="transactionData"
        >
          Total Clicks
        </th>
        <th
          scope="col"
          data-order=""
          data-name="buyerEpc"
          [psSortTable]="transactionData"
        >
          Buyer Epc
        </th>
        <th
          scope="col"
          data-order=""
          data-name="validEpc"
          [psSortTable]="transactionData"
        >
          Valid Epc
        </th>
        <th
          scope="col"
          data-order=""
          data-name="totalEpc"
          [psSortTable]="transactionData"
        >
          Total Epc
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of transactionData">
        <td>{{ data._id || 0 }}</td>
        <td>{{ data.surveyName || '' }}</td>
        <td>{{ data.buyerRevenue || 0 | number : '1.2-2' }}</td>
        <td>{{ data.supplierRevenue || 0 | number : '1.2-2' }}</td>
        <td>{{ data.margin || 0 | number : '1.2-2' }}</td>
        <td>{{ data.completes || 0 }}</td>
        <td>{{ data.buyerClicks || 0 }}</td>
        <td>{{ data.validClicks || 0 }}</td>
        <td>{{ data.totalClicks || 0 }}</td>
        <td>{{ data.buyerEpc || 0 | number : '1.2-2' }}</td>
        <td>{{ data.validEpc || 0 | number : '1.2-2' }}</td>
        <td>{{ data.totalEpc || 0 | number : '1.2-2' }}</td>
      </tr>
    </tbody>
  </table>
</div>
