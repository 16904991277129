import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@purespectrum1/ui/button';
import { SliderModule } from '@purespectrum1/ui/slider';
import { TabsModule } from '@purespectrum1/ui/tabs';
import { RouterModule } from '@angular/router';
import { SelectDropdownModule } from '@purespectrum1/ui/select-dropdown';
import { AuthGuard } from '../auth/auth.guard';
import { ManageBuyersComponent } from './manage-buyers/manage-buyers.component';
import { HashingComponent } from './supplier-settings/hashing/hashing.component';
import { SupplierSettingsComponent } from './supplier-settings/supplier-settings.component';
import { ExclusionSettingsComponent } from './supplier-settings/exclusion-settings/exclusion-settings.component';
import { RedirectUrlComponent } from './supplier-settings/redirect-url/redirect-url.component';
import { VariableMappingComponent } from './supplier-settings/variable-mapping/variable-mapping.component';
import { NotificationStatusComponent } from './supplier-settings/notification-status/notification-status.component';
import { SupplierPricingCardComponent } from './supplier-pricing-card/supplier-pricing-card.component';
import { ManualRateCardComponent } from './supplier-pricing-card/manual-rate-card/manual-rate-card.component';
import { FormulaComponent } from './supplier-pricing-card/formula/formula.component';
import { InputRestrictionModule } from '../shared/ui/input-restriction/input-restriction.module';
import { InputDecimalRestrictionModule } from '../shared/ui/input-decimal-restriction/input-decimal-restriction.module';
import { S2scallbacksComponent } from './supplier-settings/s2scallbacks/s2scallbacks.component';
import { USER_TYPES } from '../operator/manage-companies/manage-companies-form/constant';
import { UserGuard } from '../auth/user.guard';

@NgModule({
  declarations: [
    ManageBuyersComponent,
    SupplierSettingsComponent,
    HashingComponent,
    ExclusionSettingsComponent,
    RedirectUrlComponent,
    VariableMappingComponent,
    NotificationStatusComponent,
    SupplierPricingCardComponent,
    ManualRateCardComponent,
    FormulaComponent,
    S2scallbacksComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SelectDropdownModule,
    ReactiveFormsModule,
    TabsModule,
    ButtonModule,
    SliderModule,
    InputRestrictionModule,
    InputDecimalRestrictionModule,
    RouterModule.forChild([
      {
        path: 'manage-buyers',
        canActivate: [AuthGuard, UserGuard],
        data: { roles: [USER_TYPES.SUPPLIER] },
        component: ManageBuyersComponent,
      },
      {
        path: 'supplier-settings',
        canActivate: [AuthGuard, UserGuard],
        data: { roles: [USER_TYPES.SUPPLIER] },
        component: SupplierSettingsComponent,
      },
      {
        path: 'supplier-pricing-card',
        canActivate: [AuthGuard, UserGuard],
        data: { roles: [USER_TYPES.SUPPLIER] },
        component: SupplierPricingCardComponent,
      },
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SupplierModule {}
