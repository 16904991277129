<div class="container manage-suppliers">
  <div class="row justify-content-md-center">
    <div class="col-xs-12 col-sm-11 col-md-8">
      <a
        routerLink="/dashboard"
        psUiButton
        class="col-md-3 back-button basic-ps-button"
        >Back To Dashboard</a
      >
      <br />
      <h4 class="text-center">Manage Suppliers</h4>
      <ps-ui-tab-group
        (activeIndexChange)="checkActiveTab($event)"
        [activeIndex]="initialTab"
      >
        <ps-ui-tab *ngFor="let tab of tabs" [label]="tab.title"></ps-ui-tab>
      </ps-ui-tab-group>
      <div *ngIf="activeTab.title === 'SUPPLIER ACCESS'">
        <table class="table">
          <thead>
            <th>Name</th>
            <th class="thead-text">
              <span class="left-space">Block Supplier</span>
              <div class="supplier-head">
                <input
                  type="checkbox"
                  [(ngModel)]="blockAllFlag"
                  (change)="selectAllBlock($event)"
                  id="blockAllFlag"
                />
                <label for="blockAllFlag" class="btn btn-select"
                  >Select All</label
                >
              </div>
            </th>
            <th *psConfigEnabled="'enablePureScoreAndRD'" class="thead-text">
              <span class="left-space">Disable PureScore</span>
              <div class="supplier-head">
                <input
                  type="checkbox"
                  [(ngModel)]="pureScoreStFlag"
                  (change)="selectAllPurescore($event)"
                  id="pureScoreStFlag"
                />
                <label for="pureScoreStFlag" class="btn btn-select"
                  >Select All</label
                >
              </div>
            </th>
            <th *psConfigEnabled="'enablePureScoreAndRD'" class="thead-text">
              <span class="left-space">Disable Research Defender Checks</span>
              <div class="supplier-head">
                <input
                  type="checkbox"
                  [(ngModel)]="researchDefStFlag"
                  (change)="selectAllResearchDefender($event)"
                  id="researchDefStFlag"
                />
                <label for="researchDefStFlag" class="btn btn-select"
                  >Select All</label
                >
              </div>
            </th>
            <th *psConfigEnabled="'enablePureScoreAndRD'" class="thead-text">
              <span class="left-space"
                >Disable Research Defender Dupe Check</span
              >
              <div class="supplier-head">
                <input
                  type="checkbox"
                  [(ngModel)]="researchDefDupeStFlag"
                  (change)="selectAllResearchDefenderDupe($event)"
                />
                <label for="researchDefStFlag" class="btn btn-select"
                  >Select All</label
                >
              </div>
            </th>
          </thead>
          <tbody>
            <div
              class="supplier-box"
              *ngFor="let supplier of suppliers; let i = index"
            >
              <tr
                class="supplier-row"
                [ngClass]="{
                  selectedSupplier: supplier.selected
                }"
                (click)="selectSupplierValue(supplier)"
              >
                <td>
                  {{ supplier.name
                  }}<i
                    *ngIf="supplier.id === this.fusionCmpId"
                    [ngClass]="{ selectedSupplierFusion: supplier.selected }"
                    aria-hidden="true"
                    class="dropdown-icon fa fa-chevron-down"
                  ></i>
                </td>
                <td>
                  <input
                    *ngIf="supplier.id !== this.fusionCmpId"
                    type="checkbox"
                    class="form-check-input"
                    [checked]="supplier.supplrSt === 'B'"
                    (change)="blockSupplier($event, i)"
                  />
                </td>
                <td *psConfigEnabled="'enablePureScoreAndRD'">
                  <input
                    type="checkbox"
                    *ngIf="supplier.id !== this.fusionCmpId"
                    class="form-check-input"
                    [checked]="supplier.pureScoreSt === false"
                    (change)="setPureScore($event, i)"
                  />
                </td>
                <td *psConfigEnabled="'enablePureScoreAndRD'">
                  <input
                    type="checkbox"
                    *ngIf="supplier.id !== this.fusionCmpId"
                    class="form-check-input"
                    [checked]="supplier.researchDefSt === false"
                    (change)="setResearchDefender($event, i)"
                  />
                </td>
                <td *psConfigEnabled="'enablePureScoreAndRD'">
                  <input
                    type="checkbox"
                    *ngIf="supplier.id !== this.fusionCmpId"
                    class="form-check-input"
                    [checked]="supplier.researchDefDupeSt === false"
                    (change)="setResearchDefenderDupe($event, i)"
                  />
                </td>
              </tr>
              <div
                class="fusion-supplier-box"
                *ngIf="
                  supplier.id === this.fusionCmpId && this.showFusionSuppliers
                "
              >
                <span
                  class="fusionSupMessage"
                  *ngIf="fusionSuppliers.length === 0"
                  >Your account is set to block all Fusion suppliers. If this is
                  incorrect, please connect with Platform support.</span
                >
                <tr
                  class="supplier-row"
                  *ngFor="
                    let fusionSupplier of fusionSuppliers;
                    let findex = index
                  "
                >
                  <td>
                    <span class="fusion-supplier-name">{{
                      fusionSupplier.supplierName
                    }}</span>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [checked]="fusionSupplier.blockedInPlatform === true"
                      (change)="blockFusionSupplier(findex)"
                    />
                  </td>
                  <td *psConfigEnabled="'enablePureScoreAndRD'">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [disabled]="true"
                    />
                  </td>
                  <td *psConfigEnabled="'enablePureScoreAndRD'">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [disabled]="true"
                    />
                  </td>
                  <td *psConfigEnabled="'enablePureScoreAndRD'">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [disabled]="true"
                    />
                  </td>
                </tr>
              </div>
            </div>
          </tbody>
        </table>
        <button
          psUiButton
          class="pull-right basic-ps-button"
          (click)="saveSuppliers()"
        >
          Save
        </button>
      </div>
      <div class="row" *ngIf="activeTab.title === 'SUPPLIER BLENDS'">
        <button
          *ngIf="!showGroupingButton"
          class="col-md-3 offset-md-9 basic-ps-button"
          psUiButton
          (click)="newSupplierBlend()"
        >
          New Supplier Blend
        </button>
        <div *ngIf="!showGroupingButton" class="lock-blend-alignment">
          <label class="lock-blend-label">Lock All Blends</label>
          <label class="lock-blend">
            <ps-ui-slider
              (selectedChange)="lockAllBlends()"
              [beforeToggleChange]="canChangeLockedToggle"
              [selected]="selectLockAllBlends"
            ></ps-ui-slider>
          </label>
        </div>
        <div class="supplier-group-dropdown" *ngIf="showGroupingButton">
          <ps-ui-select-dropdown
            class="col-md-5"
            [dropdownItems]="supplierGroupTemplates"
            [field]="'groupName'"
            labelNone="-- Choose Supplier Group --"
            (selectedItem)="selectGroupTemplate($event)"
          >
          </ps-ui-select-dropdown>
          <div class="col-md-9 edit-supplier-action-button">
            <button
              class="btn btn-primary btn-sm button-supplier"
              type="button"
              (click)="clearAllSuppliers()"
            >
              Clear All
            </button>
            <button
              class="btn btn-primary btn-sm button-supplier"
              type="button"
              psUiHotjarEvent
              trackOn="click"
              eventName="Choose Your Supplier - Allocations"
              (click)="selectAll()"
            >
              Select All
            </button>
            <span [ngClass]="{ 'disable-not-allowed': !enableGroupingButton }">
              <div class="btn-group">
                <button
                  class="btn btn-primary btn-sm button-supplier button-supplier-group"
                  type="button"
                  (click)="openGroupModal()"
                  [disabled]="!enableGroupingButton"
                  psUiHotjarEvent
                  trackOn="click"
                  eventName="Choose Your Supplier - Supplier Grouping"
                >
                  Group Suppliers
                </button>
              </div>
            </span>
          </div>
        </div>

        <table *ngIf="!selectedSupplierBlend.is_active" style="width: 100%">
          <thead>
            <th style="padding-bottom: 10px; text-align: center" colspan="1">
              Set As Default
            </th>
            <th style="padding-bottom: 10px" colspan="12">Blend Name</th>
          </thead>
          <tbody>
            <tr
              class="supplierSettingrow"
              *ngFor="let blend of supplierBlends; let i = index"
            >
              <td style="text-align: center">
                <input
                  type="checkbox"
                  style="vertical-align: baseline"
                  class="form-check-input"
                  [(ngModel)]="blend.is_default"
                  (click)="changeIsDefault($event, blend)"
                />
              </td>
              <td>{{ blend.config_name }}</td>
              <td class="blend-name">
                <p *ngFor="let supplier of blend.suppliers">
                  {{ i !== 0 && supplier.group_name !== '' ? ',' : '' }}&nbsp;{{
                    supplier.group_name
                  }}
                </p>
              </td>
              <td class="pull-right">
                <button
                  type="button"
                  class="btn btn-link"
                  (click)="openSupplierBlend(blend, true)"
                >
                  Edit
                </button>
                |
                <button
                  type="submit"
                  class="btn btn-link"
                  (click)="deleteSupplierBlend(blend)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          *ngIf="selectedSupplierBlend && selectedSupplierBlend.is_active"
          style="width: 100%"
        >
          <thead>
            <th style="padding-bottom: 10px" colspan="2">
              <span
                style="cursor: pointer"
                class="btn-link"
                (click)="navigateToBlends()"
              >
                Supplier Group Blends
              </span>
              &nbsp;>&nbsp;
              <input
                style="border: 0px"
                placeholder="Enter Name"
                type="text"
                [(ngModel)]="selectedSupplierBlend.config_name"
                name="name"
              />
            </th>
            <th colspan="1" class="text-center min-width">Allocation %</th>
            <th
              colspan="1"
              class="text-center min-width"
              *ngIf="flexibilitySelectedType.key === 1"
            >
              Flexibility %
            </th>
          </thead>
          <tbody>
            <tr *ngIf="!isProd">
              <td>
                <label>Country</label>
                <ps-ui-select-dropdown
                  [dropdownItems]="countries"
                  [field]="'name'"
                  [enableSearch]="true"
                  labelNone="Select Country"
                  [(ngModel)]="selectedCountry"
                  [label]="selectedCountry?.name"
                  (selectedItem)="selectCountry($event)"
                >
                </ps-ui-select-dropdown>
              </td>
              <td>
                <label>Language</label>
                <ps-ui-select-dropdown
                  [dropdownItems]="languages"
                  [field]="'name'"
                  [enableSearch]="true"
                  [(ngModel)]="selectedLanguage"
                  labelNone="Select Language"
                  [label]="selectedLanguage?.name"
                  [loading]="false"
                  (selectedItem)="selectLanguage($event)"
                >
                </ps-ui-select-dropdown>
              </td>
            </tr>
            <tr>
              <td class="blendFlexibility">
                <div class="supplier-group-dropdown">
                  <ps-ui-select-dropdown
                    class="dropdown-button"
                    [dropdownItems]="flexibilityStatus"
                    [field]="'value'"
                    [enableSearch]="false"
                    (selectedItem)="setFlexibilityType($event)"
                    [(ngModel)]="flexibilitySelectedType"
                  >
                  </ps-ui-select-dropdown>
                  <span class="flexibilityLable">
                    <label class="status pull-middle quota-flex-btn">
                      <span class="mx-1">by</span>
                    </label>
                    <span class="flexibilityInput">
                      <input
                        type="number"
                        class="text-line disabled-state"
                        [ngClass]="{
                          'invalid-val':
                            selectedSupplierBlend.blend_flexibility > 100,
                          blank: flexibilitySelectedType.key === 1
                        }"
                        maxlength="3"
                        [(ngModel)]="selectedSupplierBlend.blend_flexibility"
                        psUiHotjarEvent
                        trackOn="change"
                        eventName="Choose Your Supplier - Flexibility"
                        (keyup)="
                          updateFlexValue(
                            selectedSupplierBlend.blend_flexibility
                          )
                        "
                        [disabled]="flexibilitySelectedType.key !== 0"
                      /><span>%</span>
                    </span>
                  </span>
                </div>
              </td>
              <td colspan="3">
                <p class="flexibility-note">
                  <b>Note:</b> If you don’t want any Completes allocated to a
                  given Supplier you must enter a value of “0”. <br />
                  Allocation values (below) that are left
                  <b><i>blank</i></b> will have any remaining Completes evenly
                  distributed among them.
                </p>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="lock-blend-alignment">
                <label class="lock-blend-label">Lock Blend</label>
                <label class="lock-blend">
                  <ps-ui-slider
                    (selectedChange)="lockSingleBlend($event)"
                    [beforeToggleChange]="canChangeLockedToggle"
                    [selected]="selectedSupplierBlend.is_locked"
                  ></ps-ui-slider>
                </label>
              </td>
            </tr>
            <tr
              *ngFor="let supplier of selectedSupplierBlend.suppliers"
              [ngClass]="{ selected: supplier.setActive }"
              class="supplierBlendsrow"
            >
              <td
                (click)="
                  supplier.setActive = !supplier.setActive;
                  selectSupplierGroup(supplier)
                "
              >
                {{
                  supplier.supplier_id.length > 1
                    ? supplier.group_name
                    : supplier.supplier_id[0].name
                }}
              </td>
              <td
                (click)="
                  supplier.setActive = !supplier.setActive;
                  selectSupplierGroup(supplier)
                "
                class="blend-name"
              >
                <p *ngIf="supplier.supplier_id.length > 1">
                  <span
                    *ngFor="
                      let individualSupplier of supplier.supplier_id;
                      let i = index
                    "
                    >{{ individualSupplier.name
                    }}{{
                      i < supplier.supplier_id.length - 1 ? ',' : ''
                    }}&nbsp;</span
                  >
                </p>
              </td>
              <td style="text-align: -webkit-center">
                <input
                  style="text-align: center"
                  class="form-control allocation-input"
                  type="text"
                  placeholder="Evenly Distribute"
                  [(ngModel)]="supplier.allocation_percentage"
                  (keyup)="updateSupplierAllocation(supplier)"
                  psInputRestriction="[0-9]"
                />
              </td>
              <td
                style="text-align: -webkit-center"
                *ngIf="flexibilitySelectedType.key === 1"
              >
                <input
                  style="text-align: center"
                  class="form-control"
                  type="text"
                  [(ngModel)]="supplier.flexibility_percentage"
                  (keyup)="updateSupplierFlexibility(supplier)"
                />
              </td>
              <td class="pull-right" style="display: flex">
                <button
                  *ngIf="supplier.group_name"
                  type="button"
                  class="btn btn-link"
                  (click)="editGroupModal(supplier)"
                >
                  Edit
                </button>
                <p style="margin-bottom: 0">
                  {{ supplier.group_name ? '|' : '&nbsp;' }}
                </p>
                <button
                  *ngIf="supplier.group_name"
                  type="submit"
                  class="btn btn-link"
                  (click)="ungroupSupplierGroup(supplier)"
                >
                  Ungroup
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <button
          *ngIf="selectedSupplierBlend && selectedSupplierBlend.is_active"
          psUiButton
          class="pull-right basic-ps-button"
          (click)="
            saveSupplierBlends(
              UPDATE_SUPPLIER_TRIGGER_SOURCE.SAVE_SUPPLIER_BLEND
            )
          "
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<ps-footer-copyright></ps-footer-copyright>
