import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { hashConstants, idConstants } from './hashing-constant';
import { CommonService } from '../../../shared/services/common-service/common-service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { notifyMessage } from '../../../constants/notify-message';
import { DecipherHashKeyID } from '../../../shared/interfaces/common-service.interface';

@Component({
  selector: 'ps-hashing',
  templateUrl: './hashing.component.html',
  styleUrls: ['../base-form.css'],
})
export class HashingComponent implements OnInit {
  Hashtype: Array<String>;
  Id: Array<String>;
  @Input()
  form!: FormGroup;
  decipherHashKeyIDs: DecipherHashKeyID[] = [];

  constructor(
    private _commonService: CommonService,
    private _toastr: ToasterService
  ) {
    this.Hashtype = hashConstants.HASHING_TYPE;
    this.Id = idConstants.ID_TYPE;
  }

  ngOnInit(): void {
    this.getDecipherHashKeyIDs();
  }

  isDecipherHashSelected() {
    return this.form.controls.is_decipher_hash.value;
  }

  checkHashing(event: Event) {
    const radioName = (<HTMLInputElement>event.target).name;
    const radioId = (<HTMLInputElement>event.target).id;
    if (radioId !== this.Id[0]) {
      this.form.controls.hashType.setValue(null);
      this.form.controls.isHashing.setValue(false);
      this.form.controls.isOutboundHash.setValue(false);
      return;
    }

    switch (radioName) {
      case this.Hashtype[0]:
        this.form.controls.hashType.setValue(null);
        this.form.controls.isSHA1.setValue(false);
        this.form.controls.isSHA2.setValue(false);
        this.form.controls.isHashing.setValue(false);
        break;
      case this.Hashtype[1]:
        this.form.controls.hashType.setValue('sha1');
        this.form.controls.is_decipher_hash.setValue(false);
        this.form.controls.isHashing.setValue(true);
        this.form.controls.isSHA2.setValue(false);
        break;
      case this.Hashtype[2]:
        this.form.controls.hashType.setValue('sha2');
        this.form.controls.is_decipher_hash.setValue(false);
        this.form.controls.isHashing.setValue(true);
        this.form.controls.isSHA1.setValue(false);
        break;
      default:
        this.form.controls.hashType.setValue(null);
        this.form.controls.isHashing.setValue(false);
        break;
    }
  }

  getDecipherHashKeyIDs() {
    this._commonService.getDecipherHashKeyIDs().subscribe(
      (response) => {
        this.decipherHashKeyIDs = response.data;
        const selectedKeyID = this.form.value.decipherHashKeyID;
        const isSelectedKeyIDValid = response.data.find(
          (keyID) => keyID.id == selectedKeyID
        );
        if (!isSelectedKeyIDValid) {
          this.form.controls.decipherHashKeyID.setValue('');
        }
      },
      (error) => {
        const errorMessage = error.msg || notifyMessage.errorMessage.API_ERROR;
        this._toastr.error(errorMessage);
      }
    );
  }
}
