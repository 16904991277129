'use strict';

export const hashConstants = Object.freeze({
  HASHING_TYPE: ['is_decipher_hash', 'isSHA1', 'isSHA2'],
});

export const idConstants = Object.freeze({
  ID_TYPE: ['Enable', 'NotEnable'],
});

export const decipherHashingConstants = Object.freeze({
  HASH_KEY_MAP: {
    incoming: '_s',
    outgoing: '_s',
  },
});
