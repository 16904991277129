import { Component } from '@angular/core';
import { QuestionsLibraryService } from '../questions-library-services/questions-library-services.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import {
  messages,
  fileConstants,
  fileDQConstants,
} from '../questions-library.constants';
import { SkippedQues } from '../questions-library-services/questions-library-services.interface';

@Component({
  selector: 'ps-import-questions',
  templateUrl: './import-questions.component.html',
  styleUrls: ['./import-questions.component.css'],
})
export class ImportQuestionsComponent {
  isDisableUpload: boolean = true;
  fileName: string = '';
  skipedQues: SkippedQues[] = [];
  private _fdata: FormData = new FormData();

  constructor(
    private _questionsLibrarySettingService: QuestionsLibraryService,
    private _toastr: ToasterService
  ) {}

  public onFileChange(event: Event) {
    const element = <HTMLInputElement>event.currentTarget;
    const file: File = (element.files as FileList)[0];
    this._fdata.delete(fileConstants.FORM_FILE_NAME);
    this.skipedQues = [];
    if (element.files != null) {
      this.fileName = file?.name;
      const fileExtension = this.fileName?.split('.').pop();
      if (element.files.length == 0) {
        this._toastr.warning(messages.WARNING_MESSAGE.NO_FILE);
        this.isDisableUpload = true;
        return;
      }
      if (fileExtension == fileConstants.FILE_TYPE) {
        this._fdata.append(fileConstants.FORM_FILE_NAME, file);
        this.uploadAdvanceQuestion(true);
        this.isDisableUpload = false;
      } else if (fileExtension != fileConstants.FILE_TYPE) {
        this._fdata.delete(fileConstants.FORM_FILE_NAME);
        this.isDisableUpload = true;
        this._toastr.warning(messages.WARNING_MESSAGE.INCORRECT_FILE_FORMAT);
      }
    }
  }

  public downloadFile() {
    window.open(fileConstants.FILE, '_blank');
  }

  public uploadAdvanceQuestion(validateFile: boolean) {
    const uploadAdvQues$ = this._questionsLibrarySettingService
      .uploadAdvanceQuestions(this._fdata, validateFile)
      .subscribe(
        (res) => {
          if (validateFile) {
            this._toastr.success(messages.SUCCESS_MESSAGE.FILE_ADDED);
          }
          if (res.skippedQues && res.skippedQues.length) {
            this.skipedQues = res.skippedQues;
            this._toastr.success(res.msg);
          }
        },
        (err) => {
          const errorMessage = err.error.msg;
          this.isDisableUpload = true;
          this._fdata.delete(fileConstants.FORM_FILE_NAME);
          this._toastr.warning(errorMessage);
        }
      );
  }

  public downloadDQFile() {
    window.open(fileDQConstants.FILE, '_blank');
  }
}
