import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, iif, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import {
  SurveyListing,
  Survey,
} from '../../../shared/services/buyer-api/survey.interface';
import { BuyerApiService } from '../../../shared/services/buyer-api/survey.service';
import { SupplierApiService } from '../../../shared/services/supplier-api/supplier-api.service';
import { SurveyPayloadService } from '../../../../app/create-surveys/survey-payload.service';
import { AuthService } from '../../../auth/auth.service';
import { WIDGETS } from '../../dashboard-constants';
import { Widget } from '../widget';
import { DashboardTab } from '../../domain/dashboard-tab';

@Component({
  selector: 'ps-widget-container',
  templateUrl: './widget-container.html',
  styleUrls: ['./widget-container.css'],
  animations: [
    trigger('expand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class WidgetContainerComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  expanded = false;

  @Input()
  widget?: Widget;

  @Input()
  survey!: SurveyListing;
  @Input()
  public isDasaboard: boolean = false;
  @Input()
  public isMultiCountryWidget: boolean = false;
  @Input()
  public tab?: DashboardTab;
  public dashbordTabStatus: string = '';

  survey$!: Observable<Survey>;

  private _controller = new BehaviorSubject<boolean>(false);
  private _loaded = false;

  get widgets() {
    return WIDGETS;
  }

  constructor(
    private readonly _auth: AuthService,
    private readonly _buyerApiService: BuyerApiService,
    private readonly _supplierApiService: SupplierApiService,
    private readonly _surveyPayloadService: SurveyPayloadService
  ) {}

  ngOnInit() {
    const isSupplier = this._auth.userType === 'supplier';

    const default$ = this._buyerApiService
      .getSurvey(this.survey.surveyId)
      .pipe(
        map((response) => this._surveyPayloadService.mapBuyerSurvey(response))
      );

    const supplier$ = this._supplierApiService
      .getSupplierSurvey(this.survey.surveyId)
      .pipe(
        map((response) =>
          this._surveyPayloadService.mapSupplierSurvey(response)
        )
      );

    this.survey$ = this._controller.asObservable().pipe(
      filter((expanded) => expanded),
      switchMap(() => iif(() => isSupplier, supplier$, default$)),
      tap(() => (this._loaded = true)),
      shareReplay(1)
    );
    this.dashbordTabStatus = String(this.tab?.status);
  }

  ngOnChanges(): void {
    if (this.expanded && !this._loaded) {
      this._controller.next(this.expanded);
    }
  }

  ngOnDestroy(): void {
    this._controller.complete();
  }
}
