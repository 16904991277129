<ps-ui-modal id="mapping_modal">
  <h5 psUiModalTitle>Conditions Mapping</h5>
  <ps-ui-modal-body>
    <div class="form-group">
      <form [formGroup]="modalForm">
        <table class="table text-center">
          <thead>
            <tr>
              <th *ngIf="!isUsedInDefaultMapping">
                Decipher Qualifcation Code
              </th>
              <th *ngIf="isUsedInDefaultMapping">Decipher Condition Code</th>
              <th>Description</th>
              <th>PS Condition Name</th>
            </tr>
          </thead>
          <tbody formArrayName="target">
            <tr *ngFor="let target of getTargetFormControls(); let i = index">
              <td [formGroupName]="i" *ngIf="isUsedInDefaultMapping">
                <input
                  type="text"
                  class="form-control text-input in-line small_text"
                  placeholder="Q1.r1"
                  formControlName="decipherCondition"
                />
              </td>
              <td *ngIf="!isUsedInDefaultMapping">
                {{ target.get('decipherCode')?.value }}
              </td>
              <td *ngIf="!isUsedInDefaultMapping">
                {{ target.get('desc')?.value }}
              </td>
              <td [formGroupName]="i" *ngIf="isUsedInDefaultMapping">
                <input
                  type="text"
                  class="form-control text-input in-line small_text"
                  placeholder="Desc here"
                  formControlName="desc"
                />
              </td>
              <td>
                <div
                  *ngFor="
                    let mapping of getMappingFormControls(target);
                    let mappingIndex = index
                  "
                  class="conditions"
                >
                  <ng-container *ngIf="mapping.get('conditionCodes')">
                    <div
                      *ngFor="
                        let condition of getConditionControls(mapping);
                        let conditionIndex = index
                      "
                      class="condition"
                    >
                      <ps-ui-select-dropdown
                        class="in-line"
                        [dropdownItems]="
                          getQualificationAnswers(
                            mapping.controls.qualificationCode.value
                          )
                        "
                        [field]="'local_text'"
                        [label]="
                          optionLabel(
                            mapping.controls.qualificationCode.value,
                            condition.value
                          )
                        "
                        (selectedItem)="
                          setCondition(mapping, conditionIndex, $event)
                        "
                      ></ps-ui-select-dropdown>
                      <a class="text-center in-line">
                        <i
                          *ngIf="
                            conditionIndex ===
                            getConditionControls(mapping).length - 1
                          "
                          class="fa fa-plus-square-o"
                          (click)="addCondition(mapping)"
                        ></i>
                        <i
                          *ngIf="
                            mapping.controls.conditionCodes.value.length > 1 ||
                            condition.value
                          "
                          class="fa fa-times m-1"
                          (click)="removeCondition(mapping, conditionIndex)"
                        ></i>
                      </a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="mapping.get('rangeSets')">
                    <input
                      *ngIf="
                        isRangeType(mapping.controls.qualificationCode.value)
                      "
                      type="text"
                      class="form-control text-input in-line"
                      [placeholder]="
                        returnPlaceholder(
                          mapping.controls.qualificationCode.value
                        )
                      "
                      [attr.value]="
                        returnRangeString(mapping.controls.rangeSets.value)
                      "
                      (input)="updateRange(mapping, $event)"
                    />
                  </ng-container>
                </div>
              </td>
              <td *ngIf="isUsedInDefaultMapping">
                <a
                  *ngIf="i !== getTargetFormControls().length - 1"
                  (click)="removeConditionMapping(i)"
                >
                  <i class="fa fa-times" aria-hidden="true"></i
                ></a>
                <a
                  *ngIf="i === getTargetFormControls().length - 1"
                  (click)="
                    addConditionMapping(
                      target.value.mappings[0].qualificationCode
                    )
                  "
                >
                  <i class="fa fa-plus" aria-hidden="true"></i
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </ps-ui-modal-body>
  <ps-ui-modal-footer>
    <button psUiButton (click)="close()">Apply</button>
  </ps-ui-modal-footer>
</ps-ui-modal>
