import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  Output,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@purespectrum1/ui/modal';

import { of, Subscription } from 'rxjs';
import { ToasterService } from '@purespectrum1/ui/toaster-service';

import { Constants } from '../../operator.constant';
import { UserService } from '../../user-service/user.service';
import { CompanyService } from '../../../shared/services/company/company.service';
import { AuthService } from '../../../auth/auth.service';

import { ChangeUserStatusModalComponent } from '../change-user-status-modal/change-user-status-modal.component';
import {
  CompaniesResponse,
  CompanyResponseObject,
} from '../../../shared/interfaces/company.interface';
import {
  UserPayload,
  UpdateUserResponse,
  UserResponseObject,
  UpdateUsersStatusResponse,
  ProjectManagers,
  PpmPayload,
} from '../../user-service/user.interface';
import { ManageUserEditCompanyModalComponent } from '../manage-user-edit-company-modal/manage-user-edit-company-modal.component';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'ps-manage-user-form',
  templateUrl: './manage-user-form.component.html',
  styleUrls: ['./manage-user-form.component.css'],
})
export class ManageUserFormComponent implements OnInit, OnChanges, OnDestroy {
  private _subscription: Subscription = new Subscription();
  @Input() user!: UserResponseObject;
  @Output() updated = new EventEmitter();
  public companies: CompanyResponseObject[] = [];
  public edit: boolean = false;
  public editCompany: boolean = true;
  public disableBuyerAccessLevel: boolean = false;
  public disableOperatorAccessLevel: boolean = false;
  public disableSupplierAccessLevel: boolean = false;
  public buyerAccessLevels: AccessLevel[] = [...Constants.ACCESS_LEVELS];
  public sellerAccessLevels: AccessLevel[] = Constants.ACCESS_LEVELS;
  public operatorAccessLevels: AccessLevel[] = Constants.ACCESS_LEVELS;
  public disableIsApiUserToggle: boolean = true;
  public disableDeactivateButton: boolean = false;
  public isAllCompaniesFilled: boolean = false;
  public superOperator: boolean = false;
  form!: FormGroup;
  public projectManagers: ProjectManagers[] = [];
  public enableViewByPMForCompany: boolean = false;
  public questionLibraryAccessToggle: QuestionLibraryAccessToggle = {
    show: false,
    disabled: true,
  };

  public get selectedCompany() {
    return this.form.controls.company.value;
  }

  private _hasQuestionLibraryAccessLevel = false;

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _modal: ModalService,
    private _toastr: ToasterService,
    private _userService: UserService,
    private _companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this._manageAccessLevels();
    this._generateForm();
    this._setSelectedUser(this.user);
    this.superOperator = this._auth.user?.operatorAcssLvls === 'admin';
    this.buyerAccessLevels.push(Constants.BUYER_SUPER_ADMIN_ACCESS_LEVEL);
    this.buyerAccessLevels.push(Constants.BUYER_LIMITED_DRAFT_ACCESS_LEVEL);
    this._hasQuestionLibraryAccessLevel =
      !!this._auth.user!.questionLibraryAccessLevel;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.user?.isFirstChange()) {
      this._setSelectedUser(changes.user.currentValue);
    }
  }

  private _setSelectedUser(user: UserResponseObject) {
    this.questionLibraryAccessToggle = {
      show: false,
      disabled: true,
    };
    this.user = user;
    this.edit = !this.isNewUser();
    this.editCompany = this.edit;
    this._resetDisableAccessLevelFlags();
    this._mapResponseValues(this.user);
    this.fetchCompanyDetailsOrAllCompanies();
  }

  private _generateForm() {
    this.form = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      company: ['', [Validators.required]],
      apiUserStatus: [false],
      token: [''],
      welcomeEmailStatus: [true],
      password: [''],
      buyerSideAccessLevels: [null],
      supplierSideAccessLevels: [null],
      operatorAccessLevels: [null],
      slackMemberId: [''],
      proxyProjectManager: [null],
      questionLibraryAccessLevel: [false],
    });
  }

  private _mapResponseValues(
    user: UserResponseObject,
    companyDetail?: CompanyResponseObject
  ) {
    const values: { [k: string]: unknown } = {
      email: user.email,
      name: user.name,
      slackMemberId: user.slackMemberId,
    };

    if (companyDetail) {
      values.company = companyDetail;
      this._setDisableAccessLevelFlags(companyDetail);
    }

    if (user.isApiUser) {
      values.apiUserStatus = true;
      values.token = ''; // Keeping the token empty even when a user switches from one API user to another; he can request for token using 'Get token' button
      this.disableDeactivateButton = true;
    } else {
      values.apiUserStatus = false;
    }

    if (user.id) {
      values.welcomeEmailStatus = false;
    }

    const proxyProjectManager = this.projectManagers?.find(
      (element) => element.id === user.proxyProjectManager?.id
    );
    if (proxyProjectManager) {
      values.proxyProjectManager = proxyProjectManager;
    } else {
      this.form.controls.proxyProjectManager.setValue(null);
    }

    const operatorAccessLevels = this.operatorAccessLevels.find(
      (element) => element.value === user.operatorAccessLevels
    );
    if (operatorAccessLevels) {
      values.operatorAccessLevels = operatorAccessLevels;
    }

    const buyerSideAccessLevels = this.buyerAccessLevels.find(
      (element) => element.value === user.buyerSideAccessLevels
    );
    if (buyerSideAccessLevels) {
      values.buyerSideAccessLevels = buyerSideAccessLevels;
    }

    const supplierSideAccessLevels = this.sellerAccessLevels.find(
      (element) => element.value === user.supplierSideAccessLevels
    );
    if (supplierSideAccessLevels) {
      values.supplierSideAccessLevels = supplierSideAccessLevels;
    }

    this.form.patchValue(values);

    this.setQuestionLibraryAccess(
      user.questionLibraryAccessLevel ? true : false
    );
  }

  private _setDisableAccessLevelFlags(company: CompanyResponseObject) {
    if (!company.isABuyer) {
      this.disableBuyerAccessLevel = true;
    }

    if (!company.isAnOperator) {
      this.disableOperatorAccessLevel = true;
    }

    if (!company.isASupplier) {
      this.disableSupplierAccessLevel = true;
    }
  }

  private _resetDisableAccessLevelFlags() {
    this.form.controls.buyerSideAccessLevels.setValue('');
    this.form.controls.supplierSideAccessLevels.setValue('');
    this.form.controls.operatorAccessLevels.setValue('');

    this.disableBuyerAccessLevel = false;
    this.disableOperatorAccessLevel = false;
    this.disableSupplierAccessLevel = false;
  }

  resetUserForm() {
    this.edit = false;
    this.editCompany = false;
    this.resetUser();
    this._generateForm();
    this._resetDisableAccessLevelFlags();

    if (!this.isAllCompaniesFilled) {
      this._getCompanies();
    }
  }

  resetUser() {
    this.user = {
      id: 0,
      name: '',
      email: '',
      company: 0,
      status: '',
      buyerSideAccessLevels: '',
      supplierSideAccessLevels: '',
      operatorAccessLevels: '',
      isApiUser: false,
      slackMemberId: '',
      proxyProjectManager: {} as PpmPayload,
    };
  }

  isNewUser(): boolean {
    return this.user.id === 0;
  }

  fetchCompanyDetailsOrAllCompanies() {
    if (!this.isNewUser()) {
      return this.getCompanyDetails();
    }

    this.form.controls.company.setValue('');
    this._resetDisableAccessLevelFlags();
    this._getCompanies();
  }

  setSelectedCompany(company: CompanyResponseObject) {
    const companyChange = this.edit && company.id !== this.user.company;

    if (this.isNewUser()) {
      this._resetDisableAccessLevelFlags();
      const updateSelectedCompany = this.updateSelectedCompany(
        company
      ).subscribe(() => {
        this.form.controls.company.setValue(company);
        this.updateSelectedCompany(company);
      });

      return this._subscription.add(updateSelectedCompany);
    }

    const update$ = companyChange
      ? this.confirmChangeCompany(this.form.controls.company.value, company)
      : of(company).pipe(tap((selected) => this.companyChangedType(selected)));

    const updateSelectedCompany = update$
      .pipe(switchMap((selected) => this.updateSelectedCompany(selected)))
      .subscribe((_company) => {
        return;
      });

    return this._subscription.add(updateSelectedCompany);
  }

  confirmChangeCompany(
    old: CompanyResponseObject,
    selected: CompanyResponseObject
  ) {
    const isSupplier = this.user.supplierSideAccessLevels !== 'none';
    const isBuyer = this.user.buyerSideAccessLevels !== 'none';

    if ((isSupplier || isBuyer) && selected.isAnOperator) {
      this._toastr.error(
        'The User selected cannot change to an Operator Account. Please, in order to change, contact the Product team.'
      );
      this.form.controls.company.setValue(old);
      return of(old);
    }

    return this.openCompanyUpdateModal(old, selected);
  }

  updateSelectedCompany(company: CompanyResponseObject) {
    this.enableViewByPMForCompany =
      this._auth.allBuyerConfigs.enableViewByPM.includes(company.id) || false;
    this._getProjectManagers(company.id);

    this._setQuestionLibraryAccessToggle();

    return this._companyService.getCompanyDetail(company.id).pipe(
      tap((result) => {
        this._setDisableAccessLevelFlags(company);

        // PD-9355 Enable api user toggle if no api users exist for this company
        if (!result.apiUsers || !result.apiUsers.length) {
          this.disableIsApiUserToggle = false;
        } else {
          this.form.controls.apiUserStatus.setValue(false);
          this.disableIsApiUserToggle = true;
        }

        // Service Operator only show for PS companies
        const serviceOperatorAccessLevel =
          result.id === Constants.PS_CMP_ID
            ? [Constants.SERVICE_OPERATOR_ACCESS_LEVEL]
            : [];

        this.operatorAccessLevels = [
          ...Constants.ACCESS_LEVELS,
          ...serviceOperatorAccessLevel,
        ];

        this._manageAccessLevels();
      })
    );
  }

  setSelectedWelcomeEmailStatus(flag: boolean) {
    this.form.controls.welcomeEmailStatus.setValue(flag);
    this.form.controls.password.setValue('');
  }

  setSelectedApiUserStatus(flag: boolean) {
    this.form.controls.apiUserStatus.setValue(flag);
    this.disableDeactivateButton = !!flag;
  }

  getCurrentCompanyByUser() {
    return this.companies.find((_company) => _company.id === this.user.company);
  }

  getCompanyDetails() {
    if (this.isNewUser()) {
      return;
    }

    const company = this.getCurrentCompanyByUser();
    if (this.isAllCompaniesFilled && company) {
      this._mapResponseValues(this.user, company);
      this.updateSelectedCompany(company);
      return;
    }

    this._companyService
      .getCompanyDetail(this.user.company)
      .subscribe((companyDetails) => {
        this.companies = [...this.companies, companyDetails];
        this._mapResponseValues(this.user, companyDetails);
        this.updateSelectedCompany(companyDetails);
      });
  }

  enableEditCompany() {
    this.editCompany = !this.editCompany;
    if (!this.isAllCompaniesFilled && !this.editCompany) {
      this._getCompanies();
    }
  }

  copyToken() {
    navigator.clipboard
      .writeText(this.form.value.token)
      .then((res) => {
        this._toastr.success('Copied to Clipboard');
      })
      .catch((error) => {
        this._toastr.error('Error in copying');
      });
  }

  getToken(id: number) {
    const getToken$ = this._userService.getToken(id, true).subscribe(
      (response: { token: string }) => {
        this.form.controls.token.setValue(response.token);
      },
      (error) => {
        this._toastr.error('Error in fetching Token.');
      }
    );
    this._subscription.add(getToken$);
  }

  private _getCompanies() {
    this.companies = [];
    const companies = this._companyService.getCompanies().subscribe(
      (response: CompaniesResponse) => {
        this.isAllCompaniesFilled = true;
        this.companies = response.companies;

        const company = this.getCurrentCompanyByUser();

        if (company) {
          this._mapResponseValues(this.user, company);
        }
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscription.add(companies);
  }

  openStatusModal() {
    const modalRef = this._modal.open(ChangeUserStatusModalComponent, {
      data: this.user,
    });

    modalRef.onClose$.subscribe((msg) => {
      if (msg === 'save') {
        this._updateUserStatus();
      }
    });
  }

  private _updateUserStatus() {
    const payload = {
      encId: '',
      id: this.user.id,
      name: this.user.name,
      status:
        this.user.status === Constants.USER_STATUS.DEACTIVE
          ? Constants.USER_STATUS.ACTIVE
          : Constants.USER_STATUS.INACTIVE,
    };
    const updateUser$ = this._userService
      .updateUserStatus(this.user.id, payload)
      .subscribe(
        (response: UpdateUsersStatusResponse) => {
          this.user.status =
            this.user.status === Constants.USER_STATUS.DEACTIVE
              ? Constants.USER_STATUS.ACTIVE
              : Constants.USER_STATUS.DEACTIVE;
          this._toastr.success(response.msg);
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscription.add(updateUser$);
  }

  openCompanyUpdateModal(
    userCompany: CompanyResponseObject,
    newCompany: CompanyResponseObject
  ) {
    const modalRef = this._modal.open(ManageUserEditCompanyModalComponent, {
      data: {
        from: userCompany,
        to: newCompany,
        user: this.user,
      },
    });

    return modalRef.onClose$.pipe(
      map((msg) => {
        if (msg === 'save') {
          this.companyChangedType(newCompany, userCompany);
          return newCompany;
        }

        if (msg === 'cancel' || msg === undefined) {
          this.form.controls.company.setValue(userCompany);
        }

        return userCompany;
      })
    );
  }

  companyChangedType(
    newCompany: CompanyResponseObject,
    oldCompany?: CompanyResponseObject
  ) {
    const buyerAccesLevel = this.form.controls.buyerSideAccessLevels.value;
    const supplierAccesLevel =
      this.form.controls.supplierSideAccessLevels.value;

    if (newCompany.id === this.user.company) {
      return this.swapAccessLevel(
        newCompany.isABuyer ? buyerAccesLevel : '',
        newCompany.isASupplier ? supplierAccesLevel : ''
      );
    }

    const changedToSupplier =
      newCompany.isASupplier && oldCompany && oldCompany.isABuyer;
    const changedToBuyer =
      newCompany.isABuyer && oldCompany && oldCompany.isASupplier;

    if (changedToBuyer || changedToSupplier) {
      return this.swapAccessLevel(
        newCompany.isABuyer ? supplierAccesLevel : '',
        newCompany.isASupplier ? buyerAccesLevel : ''
      );
    }
  }

  swapAccessLevel(buyer?: AccessLevel | '', supplier?: AccessLevel | '') {
    this._resetDisableAccessLevelFlags();
    this.form.patchValue(
      {
        buyerSideAccessLevels: buyer,
        supplierSideAccessLevels: supplier,
      },
      { emitEvent: false }
    );
  }

  saveUser() {
    if (this._validateForm()) {
      return;
    }

    const payload: UserPayload = this._mapPayload();

    if (this.user?.id) {
      this._updateUser(payload);
    } else {
      this._saveUser(payload);
    }
  }

  private _validateForm() {
    if (this.form.controls.email.invalid) {
      this._toastr.error('Invalid User Id.');
      return true;
    }
    if (this.form.controls.name.invalid) {
      this._toastr.error('Invalid Name.');
      return true;
    }
    if (this.form.controls.company.invalid) {
      this._toastr.error('Invalid Company.');
      return true;
    }
    if (
      !this.user?.id &&
      !this.form.value.welcomeEmailStatus &&
      this.form.value.password === ''
    ) {
      this._toastr.error('Invalid Password.');
      return true;
    }
    const errorMsg = this._validateAccessLevels();
    if (errorMsg) {
      this._toastr.error(`${errorMsg}`);
      return true;
    }
    return false;
  }

  private _validateAccessLevels() {
    let msg = '';
    if (
      this.form.value.company.isABuyer &&
      !this.form.value.buyerSideAccessLevels
    ) {
      msg = 'Invalid Buyer access level.';
    } else if (
      this.form.value.company.isASupplier &&
      !this.form.value.supplierSideAccessLevels
    ) {
      msg = 'Invalid Supplier access level.';
    } else if (
      this.form.value.company.isAnOperator &&
      !this.form.value.operatorAccessLevels
    ) {
      msg = 'Invalid Operator access level.';
    }
    return msg;
  }

  private _mapPayload() {
    const payload: UserPayload = {
      email: this.form.value.email,
      name: this.form.value.name,
      company: this.form.value.company.id,
      companyName: this.form.value.company.name,
      apiUserStatus: this.form.value.apiUserStatus,
      slackMemberId: this.form.value.slackMemberId,
      welcomeEmailStatus: this.form.value.welcomeEmailStatus,
      buyerSideAccessLevels:
        this.form.value.buyerSideAccessLevels?.value || 'none',
      operatorAccessLevels:
        this.form.value.operatorAccessLevels?.value || 'none',
      supplierSideAccessLevels:
        this.form.value.supplierSideAccessLevels?.value || 'none',
    };
    if (this.form.value.password) {
      payload.password = this.form.value.password;
    }
    if (this.form.value.apiUserStatus && this.form.value.token) {
      payload.token = this.form.value.token;
    }
    if (this.user?.id) {
      payload.status =
        this.user.status === Constants.USER_STATUS.ACTIVE ? 1 : 2;
    }
    if (this.form.value.proxyProjectManager) {
      payload.proxyProjectManager = this._mapPmPayload();
    }
    payload.questionLibraryAccessLevel = Number(
      this.form.value.questionLibraryAccessLevel
    );
    return payload;
  }

  private _saveUser(payload: UserPayload) {
    const saveUser$ = this._userService.saveUser(payload).subscribe(
      (response: UpdateUserResponse) => {
        this._toastr.success(response.msg);
        this._updateUserDetails(response.User.id);
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscription.add(saveUser$);
  }

  private _updateUser(payload: UserPayload) {
    const updateUser$ = this._userService
      .updateUser(payload, this.user.id)
      .subscribe(
        (response: UpdateUserResponse) => {
          this._toastr.success(response.msg);
          this._updateUserDetails(this.user.id);
        },
        (error) => {
          this._toastr.success(error.msg);
        }
      );
    this._subscription.add(updateUser$);
  }

  private _updateUserDetails(userId: number) {
    this.updated.emit(userId);
  }

  private _manageAccessLevels() {
    if (this._auth.user?.operatorAcssLvls != 'admin') {
      this.operatorAccessLevels = this.operatorAccessLevels.filter(
        (accessLevel) => accessLevel.value != 'admin'
      );
    }
  }

  private _mapPmPayload() {
    const { id, name: nm = '', email } = this.form.value.proxyProjectManager;
    return { id, nm, email };
  }

  private _getProjectManagers(cmpId: number) {
    if (!this.enableViewByPMForCompany) {
      return;
    }

    const projectManagersSub = this._userService
      .getAllBuyerUser(cmpId)
      .subscribe(
        (response) => {
          this.projectManagers = response;
          this.projectManagers.splice(0, 0, {
            email: '',
            name: '-- Select Project Manager --',
            company: 0,
            id: 0,
          });

          if (this.user?.proxyProjectManager) {
            const selectedPm = this.projectManagers.filter(
              (pm) => pm.id === this.user?.proxyProjectManager?.id
            )[0];
            this.form.patchValue({ proxyProjectManager: selectedPm });
          }
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscription.add(projectManagersSub);
  }

  private _enableQuestionLibraryToggle(show: boolean, disabled: boolean) {
    this.questionLibraryAccessToggle = { show, disabled };
  }

  private _setQuestionLibraryAccessToggle(): void {
    //Operator selected or a buyer with question library acess
    if (
      this._isOperatorSelected() ||
      this._isSelectedBuyerQuestionLibraryEnabled()
    ) {
      const disabled = !!!this._hasQuestionLibraryAccessLevel;
      this._enableQuestionLibraryToggle(true, disabled);
      return;
    }

    //Buyer without question library enabled
    if (this.selectedCompany.isABuyer) {
      this._enableQuestionLibraryToggle(false, true);
    }
  }

  private _isOperatorSelected(): boolean {
    return (
      this.selectedCompany.id === Constants.PS_CMP_ID &&
      this.selectedCompany.isAnOperator
    );
  }

  private _isSelectedBuyerQuestionLibraryEnabled(): boolean {
    return (
      this.selectedCompany.isABuyer &&
      !!this._auth.allBuyerConfigs.enableQuestionLibrary.includes(
        this.selectedCompany.id
      )
    );
  }

  public setQuestionLibraryAccess(flag: boolean) {
    this.form.controls.questionLibraryAccessLevel.setValue(flag);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}

interface AccessLevel {
  value: string;
  label: string;
}

interface QuestionLibraryAccessToggle {
  show: boolean;
  disabled: boolean;
}
