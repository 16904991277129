import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';

import { ModalService } from '@purespectrum1/ui/modal';

import { AuthService } from '../../../auth/auth.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { MappingModalComponent } from '../mapping-modal/mapping-modal.component';
import { DecipherService } from '../../../shared/services/decipher/decipher.service';
import {
  Target,
  QuestionMapping,
  ProcessedTarget,
  BuyerQualifications,
  ProcessedTargetMapping,
  RangeSet,
  LocaleQualifications,
} from '../../../shared/interfaces/qualification-mappings.interface';
import { Router } from '@angular/router';
import { notifyMessage } from '../../../constants/notify-message';
import {
  CreateSurveyResponse,
  DecipherMetaData,
  SurveyImportData,
  Locales,
  Locale,
  SelectedSurvey,
  NewLocaleMappingObj,
  MappingPayload,
  Mappings,
  DecipherMappingsPayload,
  DecipherMappingsResponse,
  DecipherMappings,
  CreateMappingPayload,
  CreateSurveyPayloadData,
} from '../../../shared/services/decipher/decipher.interface';
import {
  QUALIFICATION_CODES,
  QUALIFICATION_TYPES,
} from '../../../constants/qualification-code';
import { objectFlip } from '../../../utils/object-flip';
import { Constants } from '../../../create-surveys/create-survey.constants';
import { v4 as uuidv4 } from 'uuid';
import { AddNewQualifictionModalComponent } from '../add-new-qualifiction-modal/add-new-qualifiction-modal.component';
import { DecipherImportHelperService } from '../../../shared/services/decipher/decipher-import-helper/decipher-import-helper.service';
import { MissingMarkersModalComponent } from '@purespectrum1/ui/marketplace';

@Component({
  selector: 'ps-conditions-mapping',
  templateUrl: './conditions-mapping.component.html',
  styleUrls: ['./conditions-mapping.component.css'],
})
export class ConditionsMappingComponent implements OnInit, OnChanges {
  @Input() decipherMappings!: DecipherMappings;
  @Input() isUsedInDefaultMapping!: boolean;
  @Input() surveyMetadata!: SurveyImportData;
  @Input() decipherSettings!: DecipherMetaData;
  @Input() selectedSurvey!: SelectedSurvey;
  public addItemObject: any;
  public quotaMappings!: any[];
  public noMappingEnableNext!: boolean;
  public localeQualifications: LocaleQualifications = {};
  public isQBPEnabled = false;
  public surveyData!: CreateSurveyResponse;
  public showNext: boolean = true;
  public newQual: boolean = true;
  private _generateMappingForm = (mappingData?: Partial<QuestionMapping>) => {
    const {
      decipherQuestionId,
      active = false,
      qualificationCodes = [],
      locale = 'en_US',
    } = mappingData || {};
    return this._fb.group({
      decipherQuestionId: [decipherQuestionId],
      qualificationCodes: this._fb.array([...qualificationCodes]),
      active: [active],
      target: this._fb.array([]),
      locale: locale,
    });
  };

  private _generateQualificationControl = (qualificationCode?: number) => {
    return new FormControl(qualificationCode);
  };

  private _generateTargetForm = (targetData?: Partial<ProcessedTarget>) => {
    const { decipherCode, desc, decipherCondition } = targetData || {};
    return this._fb.group({
      decipherCode: [decipherCode],
      decipherCondition: [decipherCondition],
      mappings: this._fb.array([]),
      desc: [desc],
    });
  };

  private _generateConditionMapping = (
    mappingData?: ProcessedTargetMapping
  ) => {
    const { qualificationCode, conditionCodes = [] } = mappingData || {};
    return this._fb.group({
      qualificationCode: [qualificationCode],
      conditionCodes: this._fb.array(
        this._decipherImportHelper.shrinkConditionCodes(conditionCodes)
      ),
    });
  };

  private _generateRangeMapping = (mappingData?: ProcessedTargetMapping) => {
    const { qualificationCode, rangeSets: { from = null, to = null } = {} } =
      mappingData || {};
    return this._fb.group({
      qualificationCode: [qualificationCode],
      rangeSets: this._fb.group({
        from: [from],
        to: [to],
      }),
    });
  };

  mappingFormArray = this._fb.array([]);
  multiCountryMappingFormArray = this._fb.array([]);

  public processData = (data: Array<Mappings>) => {
    let processedDataArray: Array<QuestionMapping> = [];
    data.forEach((item: Mappings) => {
      if (item) {
        const decipherQuestionId = item.mappingId
          .split(/[.=]/)[0]
          .replace('list', 'Supplier/Vendor');
        let existingQuestionMapping = processedDataArray.find(
          (qMap) => qMap.decipherQuestionId == decipherQuestionId
        );

        if (!existingQuestionMapping) {
          existingQuestionMapping = {
            decipherQuestionId: decipherQuestionId,
            qualificationCodes: [],
            active: item.active,
            target: [],
          };
          processedDataArray.push(existingQuestionMapping);
        }

        const existingQuestionTarget = existingQuestionMapping.target.find(
          (target) => target.decipherCondition == item.mappingId
        );
        if (existingQuestionTarget) {
          return;
        }

        item.target = item.target || [];
        const newQuestionTarget = {
          desc: item.desc,
          decipherCondition: item.mappingId,
          decipherCode: item.mappingId,
          mappings: item.target.map((targetItem: Target) => {
            existingQuestionMapping?.qualificationCodes.push(
              targetItem.qualification_code
            );
            return this._getCorrectMappingObj(targetItem);
          }),
        };

        existingQuestionMapping.target.push(newQuestionTarget);
      }
    });
    processedDataArray.forEach((d: QuestionMapping) => {
      d.qualificationCodes = [...new Set(d.qualificationCodes)];
    });
    return processedDataArray;
  };

  public createMappingPayload = (index: number) => {
    let originalData: any = [];

    this.multiCountryMappingFormArray.value[index].forEach(
      (entry: CreateMappingPayload) => {
        const qualificationCode = entry.qualificationCodes[0];

        entry.target.forEach((targetEntry: any) => {
          let originalEntry;
          const desc = targetEntry.desc;
          const mappingId = targetEntry.decipherCondition;

          if (targetEntry.mappings[0]?.conditionCodes) {
            originalEntry = {
              target: [
                {
                  qualification_code: qualificationCode,
                  condition_codes: targetEntry.mappings[0].conditionCodes,
                },
              ],
              desc,
              mappingId: mappingId,
            };
          } else if (targetEntry.mappings[0]?.rangeSets) {
            originalEntry = {
              target: [
                {
                  qualification_code: qualificationCode,
                  range_sets: this._returnPayloadRangeSet(
                    targetEntry.mappings[0].rangeSets,
                    qualificationCode
                  ),
                },
              ],
              desc,
              mappingId: mappingId,
            };
          }

          originalData.push(originalEntry);
        });
      }
    );
    return originalData;
  };

  public patchDataToForm = (data: Array<QuestionMapping>, locale: string) => {
    this.mappingFormArray.clear();
    data.forEach((item: QuestionMapping) => {
      const { decipherQuestionId, active, qualificationCodes } = item;
      const mappingForm = this._generateMappingForm({
        decipherQuestionId,
        active,
        qualificationCodes,
        locale,
      });
      this._addFillerQualificationCode(mappingForm);
      item.target.forEach((targetItem: ProcessedTarget) => {
        const targetForm = this._generateTargetForm(targetItem);
        const targetMappingControls = this._initTargetMappingsControls(
          targetItem.mappings
        );
        this._setFormArray(
          targetForm.get('mappings') as FormArray,
          targetMappingControls
        );
        this._addFillerTargetMappings(targetForm, item.qualificationCodes);
        (<FormArray>mappingForm.get('target')).push(targetForm);
      });
      this.mappingFormArray.push(mappingForm);
    });
    return this.mappingFormArray;
  };

  private _initTargetMappingsControls = (
    targetMappings: ProcessedTargetMapping[]
  ) => {
    if (targetMappings && targetMappings.length) {
      return targetMappings.map((mappingItem: ProcessedTargetMapping) => {
        return this._isRangeType(mappingItem.qualificationCode)
          ? this._generateRangeMapping(mappingItem)
          : this._generateConditionMapping(mappingItem);
      });
    }
    return [];
  };

  private _addFillerQualificationCode(mappingForm: FormGroup) {
    const qualificationCodesFormArray = mappingForm.get(
      'qualificationCodes'
    ) as FormArray;
    if (!qualificationCodesFormArray.length) {
      qualificationCodesFormArray.push(this._generateQualificationControl());
    }
  }

  private _addFillerTargetMappings = (
    target: FormGroup,
    qualificationCodes: number[]
  ) => {
    if (target.get('mappings') && qualificationCodes) {
      qualificationCodes.forEach((code) => {
        const isExist = this.getTargetMappings(target).find(
          (targetMapping) => targetMapping.value.qualificationCode == code
        );
        if (!isExist) {
          (target.get('mappings') as FormArray).push(
            this._isRangeType(code)
              ? this._generateRangeMapping({ qualificationCode: code })
              : this._generateConditionMapping({ qualificationCode: code })
          );
        }
      });
    }
  };

  private _isRangeType = (qualification: number) => {
    const qualificationDetail = this.findQualification(qualification);
    const { RANGE, INPUT } = QUALIFICATION_TYPES;
    return qualificationDetail
      ? ([RANGE, INPUT] as number[]).includes(qualificationDetail.type)
      : false;
  };

  public findQualification = (qualCode: number, locale?: string) => {
    if (locale && this.localeQualifications[locale]) {
      return this.localeQualifications[locale].find(
        (qualification) => qualification.qualification_code == qualCode
      );
    }
    for (let localeObj of Object.keys(this.localeQualifications)) {
      const qual = this.localeQualifications[localeObj].find(
        (qualification) => qualification.qualification_code == qualCode
      );
      if (qual) {
        return qual;
      }
    }
    return null;
  };

  public async submit() {
    try {
      const quotaMappingPayload = this.returnQuotaMappingPayload();
      await this._updateMetadata();
      await this._updateMappings(quotaMappingPayload);
      await this._updateMarkers();
      this._openMissingMarkerModal();
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  private async _createSurveyAndRedirect() {
    this.surveyData = await this.createSurveys();
    this.goToSurveyEdit(this.surveyData.data[0].psSurveyId);
  }

  public saveDecipherMappings = (index: number, locale: string) => {
    let allMappingCompleted: boolean = false;
    this.multiCountryMappingFormArray.value[index].forEach((mapping: any) => {
      const mappingCheck =
        mapping.decipherQuestionId &&
        mapping.qualificationCodes &&
        mapping.qualificationCodes.length &&
        mapping.qualificationCodes[0] &&
        mapping.target &&
        mapping.target.length;
      if (mappingCheck) {
        allMappingCompleted = true;
      } else {
        this._toastr.error(
          notifyMessage.errorMessage.CONDITION_MAPPING_ERROR.ALL_DEFAULT_MAPPING
        );
        allMappingCompleted = false;
        return;
      }
    });
    if (allMappingCompleted) {
      const mappings = this.createMappingPayload(index);
      const payload = {
        locale,
        mappings,
        cmp: this._authService.user!.cmp,
      };
      this._putDecipherMappings(locale, payload);
    }
  };

  private _putDecipherMappings = (
    locale: string,
    payload: DecipherMappingsPayload
  ) => {
    this._decipherService
      .putDecipherMappings(this._authService.user!.cmp, locale, payload)
      .subscribe(
        (response: DecipherMappingsResponse) => {
          this._toastr.success('Default Decipher Mappings Saved', locale);
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
  };

  goToSurveyEdit(psSurveyId: number) {
    this._router.navigateByUrl('/surveys/' + psSurveyId + '/edit');
  }

  createSurveys() {
    const surveyPath = this.surveyMetadata.selectedSurvey?.path;
    let payload: CreateSurveyPayloadData = {
      path: surveyPath,
    };
    const locales = this.getSelectedLocale();
    if (locales.length > 1) {
      payload['mc_unique_id'] = uuidv4();
    }
    const cmp = this._authService.user!.cmp;
    return this._decipherService.createSurvey(cmp, payload).toPromise();
  }

  public returnQuotaMappingPayload() {
    const localeQuestionMappingArray: Array<QuestionMapping[]> =
      this.multiCountryMappingFormArray.value;
    const payload: Mappings[] = [];
    this.quotaMappings.forEach((localeMapping, idx) => {
      localeMapping.mappings.forEach((mapping: any) => {
        const splitCase = new RegExp(/ and | or /g);
        const decipherDataPoints: string[] = mapping.mappingId.split(splitCase);
        let isAllDataPointsMapped = true;
        let mappedPsQuals: Target[] = [];
        decipherDataPoints.forEach((dataPoint) => {
          const questionId = dataPoint
            .split(/[.=]/)[0]
            .replace('list', 'Supplier/Vendor');
          const optionMappingId = mapping.mappingId;
          const questionMappings = localeQuestionMappingArray[idx];
          const questionMapping = questionMappings.find((questionMap) => {
            return questionMap.decipherQuestionId == questionId;
          });
          if (
            !(
              questionMapping &&
              questionMapping.active &&
              questionMapping.target &&
              questionMapping.target.length
            )
          ) {
            isAllDataPointsMapped = false;
            return;
          }
          const optionObj = questionMapping.target.find(
            (target: ProcessedTarget) => {
              return target.decipherCode === optionMappingId;
            }
          );
          if (!(optionObj && optionObj.mappings && optionObj.mappings.length)) {
            isAllDataPointsMapped = false;
            return;
          }
          if (this._decipherImportHelper.isOptionUnmapped(optionObj.mappings)) {
            isAllDataPointsMapped = false;
            return;
          }
          const payloadTarget = optionObj.mappings.map(
            (targetMapping: ProcessedTargetMapping) => {
              return this._createPayloadTargetObj(targetMapping);
            }
          );
          mappedPsQuals.push(...payloadTarget);
        });
        const newMappingObj = {
          ...mapping,
          active: isAllDataPointsMapped,
          target: isAllDataPointsMapped ? mappedPsQuals : [],
          locale: localeMapping.locale,
        };
        payload.push(newMappingObj);
      });
    });
    let removeIndex: Array<number> = [];
    payload.forEach((mapping: any, i: number) => {
      if (mapping.target.length) {
        mapping.target.forEach((target: any) => {
          if (
            !target.condition_codes?.length &&
            target.qualification_code === 'MP_suppliers'
          ) {
            removeIndex.push(i);
          }
        });
      }
    });
    if (removeIndex.length) {
      removeIndex.forEach((index: number, i: number) => {
        payload.splice(index - i, 1);
      });
    }
    return payload;
  }

  private _validateTargetMapping = (
    targetMapping: ProcessedTargetMapping,
    qualificationDetail: BuyerQualifications,
    locale: string = 'en_US'
  ) => {
    const { conditionCodes, rangeSets } = targetMapping;
    if (Array.isArray(conditionCodes)) {
      return this._validateSelectedConditions(
        conditionCodes,
        qualificationDetail,
        locale
      );
    } else {
      return this._validateSelectedRangeSet(
        rangeSets!,
        qualificationDetail,
        locale
      );
    }
  };

  private _validateSelectedConditions = (
    conditionCodes: string[],
    qualificationDetail: BuyerQualifications,
    locale: string = 'en_US'
  ) => {
    const localization = this._getLocalizationObj(qualificationDetail, locale);
    if (!localization || !localization.answers.length) {
      return false;
    }
    if (!conditionCodes.filter(Boolean).length) {
      return false;
    }
    const answers = localization.answers;
    const { returnConditionCodesNoNull } = this._decipherImportHelper;
    return returnConditionCodesNoNull(conditionCodes).every((conditionCode) => {
      return answers.find(
        (answer) =>
          answer.condition_code.toString() === conditionCode.toString()
      );
    });
  };

  private _getLocalizationObj = (
    qualificationDetail: BuyerQualifications,
    locale: string
  ) => {
    locale = locale.replace('UK', 'GB');
    return qualificationDetail.localizations.find(
      (_localization) => _localization.locale == locale
    );
  };

  private _validateSelectedRangeSet = (
    rangeSet: RangeSet,
    qualificationDetail: BuyerQualifications,
    locale: string = 'en_US'
  ) => {
    const localization = this._getLocalizationObj(qualificationDetail, locale);
    if (!localization) {
      return false;
    }
    return this._decipherImportHelper.isValidRange(
      rangeSet,
      localization.format
    );
  };

  private _createPayloadTargetObj = (targetMapping: ProcessedTargetMapping) => {
    const obj: Target = {
      qualification_code: targetMapping.qualificationCode,
    };
    if (targetMapping.conditionCodes) {
      obj.condition_codes = this._returnPayloadConditionCodes(
        targetMapping.conditionCodes
      );
    } else if (targetMapping.rangeSets) {
      obj.range_sets = this._returnPayloadRangeSet(
        targetMapping.rangeSets,
        targetMapping.qualificationCode
      );
    }
    return obj;
  };

  private _returnPayloadConditionCodes(conditionCodes: string[]): string[] {
    const conditionCodesNoNull = conditionCodes.filter(
      (code) => !this._decipherImportHelper.isEmptyValue(code)
    );
    return conditionCodesNoNull.map((code) => code.toString());
  }

  private _returnPayloadRangeSet(rangeSet: RangeSet, qualCode: number) {
    const payloadRangeSet: RangeSet = {
      from: rangeSet.from,
      to: rangeSet.to,
    };
    const units = this._returnUnits(qualCode);
    if (units) {
      payloadRangeSet.units = units;
    }
    return [payloadRangeSet];
  }

  private _returnUnits(qualificationCode: number) {
    const codeQualNameMapping = objectFlip(QUALIFICATION_CODES);
    if (codeQualNameMapping[qualificationCode] == 'INCOME') {
      return Constants.UNITS.INCOME;
    } else if (
      ['CHILD_AGE', 'AGE'].includes(codeQualNameMapping[qualificationCode])
    ) {
      return Constants.UNITS.AGE.YEAR;
    }
    return null;
  }

  private async _updateMetadata() {
    const locales = this.getSelectedLocale();
    const payload = {
      category: this.surveyMetadata.selectedCategory.id,
      cmp: this._authService.user!.cmp,
      completes: this.surveyMetadata.numberOfCompletes,
      fieldTime: this.surveyMetadata.fieldTime,
      ir: this.surveyMetadata.incidence,
      loi: this.surveyMetadata.lengthOfSurvey,
      locales,
      path: this.surveyMetadata.selectedSurvey.path,
      shortCode: this.surveyMetadata.selectedCountries.short_Code,
      title: this.surveyMetadata.selectedSurvey.title,
      uri: this.surveyMetadata.decipherURI.value,
      vendor: this.decipherSettings.vendor,
    };
    return this._decipherService
      .saveSurveyMetadata(this._authService.user!.cmp, payload)
      .toPromise();
  }

  private async _updateMappings(mappings: Mappings[]) {
    const payload: MappingPayload = {
      cmp: this._authService.user!.cmp,
      uri: this.surveyMetadata.decipherURI.value,
      path: this.surveyMetadata.selectedSurvey.path,
      data: [],
    };
    this.getSelectedLocale().forEach((localeData) => {
      const newLocaleMappingObj: NewLocaleMappingObj = {
        locale: localeData.localizationCode,
        mappings: mappings.filter((mapping) => {
          return mapping.locale == localeData.localizationCode;
        }),
      };
      if (localeData.primaryLocale) {
        newLocaleMappingObj.isPrimaryLocale = true;
      }
      payload.data.push(newLocaleMappingObj);
    });
    return this._decipherService
      .updateSurveyMapping(this._authService.user!.cmp, payload)
      .toPromise();
  }

  private async _updateMarkers() {
    const payload = {
      path: this.surveyMetadata.selectedSurvey.path,
      uri: this.surveyMetadata.decipherURI.value,
    };
    const cmp = this._authService.user!.cmp;
    return this._decipherService.updateSurveyMarkers(cmp, payload).toPromise();
  }

  private _getDecipherLanguage(locale: string) {
    const { selectedSurvey } = this.surveyMetadata;
    const decipherSurveyData = selectedSurvey.locale.find(
      (lang: Locale) => lang.locale === locale
    );
    return decipherSurveyData?.decipherSurveyLanguage;
  }

  public getSelectedLocale() {
    const locales: Locales[] = [];
    const { selectedCountries, selectedLanguage, selectedSurvey } =
      this.surveyMetadata;
    const selectedLocale = selectedSurvey.locale.find(
      (lang: Locale) => lang.locale
    );

    const primaryLocale = selectedLocale?.locale;
    if (selectedCountries.length >= 1) {
      selectedCountries.forEach((country: any) => {
        country.lang.forEach((lang: any) => {
          const localizationCode = `${lang.transalte_code}_${country.short_Code}`;
          const decipherSurveyLanguage =
            this._getDecipherLanguage(localizationCode);
          if (!decipherSurveyLanguage) {
            return;
          }
          const obj = {
            decipherSurveyLanguage,
            localizationCode,
            countryCode: country.short_Code,
            primaryLocale: primaryLocale === localizationCode ? true : false,
          };
          locales.push(obj);
        });
      });
    } else {
      const localizationCode = `${selectedLanguage.transalte_code}_${selectedCountries.short_Code}`;
      const decipherSurveyLanguage =
        this._getDecipherLanguage(localizationCode);
      if (!decipherSurveyLanguage) {
        return locales;
      }
      const obj = {
        localizationCode,
        decipherSurveyLanguage,
        countryCode: selectedCountries.short_Code,
        primaryLocale: primaryLocale === localizationCode ? true : false,
      };
      locales.push(obj);
    }
    return locales;
  }

  public getAllLocales = () => {
    let allLocales: any = [];
    this.decipherMappings?.mappings.forEach((mapping: any) => {
      allLocales.push({
        localizationCode: mapping.locale,
      });
    });
    return allLocales;
  };

  constructor(
    private _fb: FormBuilder,
    private _modal: ModalService,
    private _toastr: ToasterService,
    private _authService: AuthService,
    private _decipherService: DecipherService,
    private _router: Router,
    private _decipherImportHelper: DecipherImportHelperService,
    private _cd: ChangeDetectorRef
  ) {
    this.isQBPEnabled = this._authService.buyerConfig?.qbp || false;
  }

  public addQualification = (mappingForm: FormGroup) => {
    (mappingForm.get('qualificationCodes') as FormArray).push(
      this._generateQualificationControl()
    );
  };

  public removeQualification = (
    mappingForm: FormGroup,
    qualificationIndex: number
  ) => {
    (mappingForm.get('qualificationCodes') as FormArray).removeAt(
      qualificationIndex
    );
    this._updateTargetMappings(mappingForm);
  };

  public openMappingModal = (mappingFormGroup: FormGroup) => {
    const qualCodes = mappingFormGroup
      .get('qualificationCodes')!
      .value.filter(Boolean);
    const { isUsedInDefaultMapping } = this;
    let qualificationDetails: BuyerQualifications[] = [];
    if (Array.isArray(qualCodes)) {
      qualificationDetails = qualCodes
        .map((code: number) => {
          return this.findQualification(code, mappingFormGroup.value.locale)!;
        })
        .filter(Boolean);
    }
    if (
      !isUsedInDefaultMapping ||
      (isUsedInDefaultMapping && qualificationDetails[0]?.qualification_code)
    ) {
      this._modal
        .open(MappingModalComponent, {
          data: {
            mappingData: mappingFormGroup.value,
            isUsedInDefaultMapping,
            qualificationDetails,
          },
        })
        .onClose$.subscribe((targetFormVal) => {
          if (!targetFormVal) {
            return;
          }
          this.updateMappingsWithModal(mappingFormGroup, targetFormVal);
        });
    } else {
      this._toastr.error(
        notifyMessage.errorMessage.CONDITION_MAPPING_ERROR.SELECT_QUALIFICATION
      );
    }
  };

  private _openMissingMarkerModal = () => {
    this._modal
      .open(MissingMarkersModalComponent, {
        data: {
          auth: this._authService.getMarketplaceAuth,
          decipherSurveyPath: this.surveyMetadata.selectedSurvey.path,
        },
      })
      .onClose$.subscribe((action) => {
        if (action.continue) {
          this._createSurveyAndRedirect();
        }
      });
  };

  public removeMapping = (id: number, index: number) => {
    (this.multiCountryMappingFormArray.controls[id] as FormArray).removeAt(
      index
    );
  };

  public addEmptyMapping = (index: number, locale: string) => {
    (this.multiCountryMappingFormArray.controls[index] as FormArray).push(
      this._generateMappingForm({
        locale,
        decipherQuestionId: '',
        qualificationCodes: [0],
      })
    );
  };

  public updateMappingsWithModal = (
    mappingFG: FormGroup,
    modalTargetData: ProcessedTarget[]
  ) => {
    const finalTargetData = modalTargetData.map((targetData) => {
      const newTargetForm = this._generateTargetForm(targetData);
      const targetMappingControls = this._initTargetMappingsControls(
        targetData.mappings
      );
      this._setFormArray(
        newTargetForm.get('mappings') as FormArray,
        targetMappingControls
      );
      return newTargetForm;
    });
    while ((mappingFG.get('target') as FormArray).length) {
      (mappingFG.get('target') as FormArray).removeAt(0);
    }
    finalTargetData.forEach((target) => {
      (mappingFG.get('target') as FormArray).push(target);
    });
  };

  public getMappingControl = (index: number) => {
    return this.mappingFormArray.controls[index] as FormGroup;
  };

  public getMappingControls = () => {
    return this.mappingFormArray.controls as FormGroup[];
  };

  public getMultiCountryMappingControls = () => {
    return this.multiCountryMappingFormArray.controls as FormArray[];
  };

  public getMultiCountryMappingControl = (index: number) => {
    return (this.multiCountryMappingFormArray.controls[index] as FormArray)
      .controls as FormGroup[];
  };

  public getQuestionMappingControlsForLocale = (locale: string) => {
    const questionMappingFormArray = this.getMultiCountryMappingControls().find(
      (questionMappings) => {
        return (
          questionMappings.value.length &&
          questionMappings.value[0].locale === locale
        );
      }
    );
    return questionMappingFormArray
      ? (questionMappingFormArray.controls as FormGroup[])
      : [];
  };

  public getMultiCountryMappingIndexControl = (
    outerIndex: number,
    innerIndex: number
  ) => {
    return (this.multiCountryMappingFormArray.controls[outerIndex] as FormArray)
      .controls[innerIndex] as FormGroup;
  };

  public getQualificationControls = (mappingForm: FormGroup) => {
    return (mappingForm.get('qualificationCodes') as FormArray)
      .controls as FormControl[];
  };

  public getTargetControls = (mappingForm: FormGroup) => {
    return (mappingForm.get('target') as FormArray).controls as FormGroup[];
  };

  public getTargetMappings = (targetControl: FormGroup) => {
    return (targetControl.get('mappings')! as FormArray)
      .controls as FormGroup[];
  };

  public setQualification = (
    mappingForm: FormGroup,
    qualIndex: number,
    selectedQual: BuyerQualifications
  ) => {
    if (selectedQual.qualification_code == 399) {
      this._modal
        .open(AddNewQualifictionModalComponent, {
          data: {
            langData: this.getSelectedLocale(),
          },
        })
        .onClose$.subscribe((localeQual) => {
          if (!localeQual) {
            selectedQual.desc = 'Select';
            return;
          }
          for (const element of localeQual.localizations) {
            const qualTOBePushed = {
              ...localeQual,
              localizations: [element],
            };
            const targetLocale =
              element.locale === 'en_GB' ? 'en_UK' : element.locale;
            this.localeQualifications[targetLocale] = [
              ...this.localeQualifications[targetLocale],
              qualTOBePushed,
            ];
            this._cd.detectChanges();
            if (mappingForm.value.locale === targetLocale) {
              this.setQualification(mappingForm, qualIndex, qualTOBePushed);
            }
          }
        });
    } else {
      this.getQualificationControls(mappingForm)[qualIndex].setValue(
        selectedQual.qualification_code
      );
      this._updateTargetMappings(mappingForm);
      mappingForm.get('active')?.setValue(true);
      this.validateActive(mappingForm);
    }
  };

  private _validatePrefilledActiveMapping = (
    questionMappingsControls: FormGroup[]
  ) => {
    questionMappingsControls.forEach((mappingForm) => {
      if (mappingForm.controls.active.value) {
        this.validateActive(mappingForm, true);
      }
    });
  };

  public validateActive = (mappingFG: FormGroup, isClicked?: boolean) => {
    const formValue: QuestionMapping = mappingFG.value;
    if (!formValue.active) {
      return;
    }
    if (this._duplicateQualificationCombination(formValue)) {
      return this._deactivateMapping(mappingFG, isClicked);
    }
    const isQualificationExists = this._selectedQualificationsExist(
      formValue.qualificationCodes,
      formValue.locale
    );
    if (!isQualificationExists) {
      return this._deactivateMapping(mappingFG, isClicked);
    }
    if (!this._hasValidTargetMappings(formValue)) {
      return this._deactivateMapping(mappingFG, isClicked);
    }
    if (this._hasDuplicateTargetMapping(formValue.target)) {
      return this._deactivateMapping(mappingFG, isClicked);
    }
  };

  private _hasDuplicateTargetMapping = (targets: ProcessedTarget[]) => {
    return this._decipherImportHelper.hasDuplicate(targets);
  };

  private _duplicateQualificationCombination = (
    questionMapping: QuestionMapping
  ) => {
    const uniqueQuals = new Set([...questionMapping.qualificationCodes]);
    if (uniqueQuals.size != questionMapping.qualificationCodes.length) {
      return true;
    }
    return !!this.getQuestionMappingControlsForLocale(
      questionMapping.locale!
    ).find((innerQuestionMapping) => {
      const questionMappingValue: QuestionMapping = innerQuestionMapping.value;
      if (
        questionMappingValue.decipherQuestionId ==
        questionMapping.decipherQuestionId
      ) {
        return false;
      }
      if (
        questionMappingValue.qualificationCodes.length !=
        questionMapping.qualificationCodes.length
      ) {
        return false;
      }
      const sameCombination = questionMappingValue.qualificationCodes.every(
        (qual) => questionMapping.qualificationCodes.includes(qual)
      );
      if (sameCombination && questionMappingValue.active) {
        return true;
      }
      return false;
    });
  };

  private _selectedQualificationsExist = (
    selectedQualifications: number[],
    locale: string = 'en_US'
  ) => {
    return selectedQualifications.every((qual) =>
      this.findQualification(qual, locale)
    );
  };

  private _hasValidTargetMappings = (questionMapping: QuestionMapping) => {
    const qualToDetailMapObj = this._returnDetailForQualCodes(
      questionMapping.qualificationCodes,
      questionMapping.locale
    );
    const hasAtleastOneValidMapping = this._hasAtleastOneValidMappedTarget(
      questionMapping,
      qualToDetailMapObj
    );
    const hasNoPartialMapping = this._hasNoPartiallyMappedTarget(
      questionMapping,
      qualToDetailMapObj
    );
    return hasAtleastOneValidMapping && hasNoPartialMapping;
  };

  private _hasAtleastOneValidMappedTarget = (
    questionMapping: QuestionMapping,
    qualToDetailMap: QualToDetailMapping
  ) => {
    return questionMapping.target.some((target) => {
      const { mappings = [] } = target;
      if (!mappings.length) {
        return false;
      }
      return mappings.every((mapping) =>
        this._validateTargetMapping(
          mapping,
          qualToDetailMap[mapping.qualificationCode],
          questionMapping.locale
        )
      );
    });
  };

  private _hasNoPartiallyMappedTarget = (
    questionMapping: QuestionMapping,
    qualToDetailMap: QualToDetailMapping
  ) => {
    return questionMapping.target.every((target) => {
      const { mappings = [] } = target;
      if (!mappings.length) {
        return false;
      }
      const isValidMapping = mappings.every((mapping) =>
        this._validateTargetMapping(
          mapping,
          qualToDetailMap[mapping.qualificationCode],
          questionMapping.locale
        )
      );
      if (isValidMapping) {
        return true;
      }
      return this._decipherImportHelper.isOptionUnmapped(mappings);
    });
  };

  private _returnDetailForQualCodes = (
    qualificationCodes: number[],
    locale: string = 'en_US'
  ): QualToDetailMapping => {
    return qualificationCodes.reduce((qualToDetailMap, qual) => {
      return {
        ...qualToDetailMap,
        [qual]: this.findQualification(qual, locale),
      };
    }, {});
  };

  private _deactivateMapping = (
    mappingForm: FormGroup,
    isClicked?: boolean
  ) => {
    mappingForm.get('active')?.setValue(false);
    if (isClicked) {
      this._toastr.error(
        notifyMessage.errorMessage.CONDITION_MAPPING_ERROR.INVALID_MAPPING
      );
    }
  };

  private _updateTargetMappings = (mappingForm: FormGroup) => {
    this.getTargetControls(mappingForm).forEach((target: FormGroup) => {
      const oldTargetMappingControls = this.getTargetMappings(target);
      const qualificationCodes = mappingForm.get('qualificationCodes')!.value;
      const newTargetMappingControls: FormGroup[] = qualificationCodes.map(
        (qCode: number) => {
          const oldTargetMappingControl = oldTargetMappingControls.find(
            (oldControls) => oldControls.value.qualificationCode == qCode
          );
          if (oldTargetMappingControl) {
            return oldTargetMappingControl;
          }
          if (this._isRangeType(qCode)) {
            const newRangeControl = this._generateRangeMapping({
              qualificationCode: qCode,
            });
            this._fillRangeValues(target, newRangeControl);
            return newRangeControl;
          }
          return this._generateConditionMapping({ qualificationCode: qCode });
        }
      );
      this._setFormArray(
        target.get('mappings') as FormArray,
        newTargetMappingControls
      );
    });
  };

  private _setFormArray = (
    formArray: FormArray,
    controls: Array<FormGroup | FormControl>
  ) => {
    formArray.clear();
    controls.forEach((control) => {
      formArray.push(control);
    });
  };

  private _fillRangeValues = (
    targetFG: FormGroup,
    targetMappingForm: FormGroup
  ) => {
    const condition: string = targetFG.get('decipherCondition')?.value;
    const splitRange = this._splitRangeCondition(condition);
    if (splitRange.length == 2) {
      const [from, to] = splitRange;
      (targetMappingForm.get('rangeSets') as FormGroup)
        .get('from')
        ?.setValue(Number(from));
      (targetMappingForm.get('rangeSets') as FormGroup)
        .get('to')
        ?.setValue(Number(to) - 1);
    }
  };

  private _splitRangeCondition = (conditionString: string) => {
    let splitRange;
    const rangePattern = /range['(]*\d+[,-]\d+/;
    const checkPattern = /check['(]*\d+[,-]\d+/;
    if (conditionString.match(rangePattern)) {
      splitRange = conditionString
        .match(rangePattern)?.[0]
        .replace(/range['"()]*/, '')
        .split(/[,-]/);
    } else if (conditionString.match(checkPattern)) {
      splitRange = conditionString
        .match(checkPattern)?.[0]
        .replace(/check['"()]*/, '')
        .split(/[,-]/);
    }
    return Array.isArray(splitRange) ? splitRange : [];
  };

  public getDecipherQuotas = () => {
    const { cmp } = this._authService.user!;
    const getQuotasPayload = {
      uri: this.surveyMetadata.decipherURI.value,
      path: this.surveyMetadata.selectedSurvey.path,
      locale: this.getSelectedLocale(),
      vendor: this.decipherSettings.vendor,
    };
    let undefinedLocale = '';
    this._decipherService.getDecipherQuotas(cmp, getQuotasPayload).subscribe(
      (response) => {
        this.multiCountryMappingFormArray.clear();
        this.quotaMappings = response.data || [];
        if (!(response.data && response.data.length)) {
          this.noMappingEnableNext = true;
          this._toastr.warning(
            notifyMessage.errorMessage.CONDITION_MAPPING_ERROR.NO_MAPPINGS_FOUND
          );
        }
        response.data.forEach((localeMapping: any, index) => {
          this.patchDataToForm(
            this.processData(localeMapping.mappings),
            localeMapping.locale
          );
          this.multiCountryMappingFormArray.push(
            this.deepCopyFormArray(this.mappingFormArray)
          );
          if (!this.multiCountryMappingFormArray.controls[index].value.length) {
            undefinedLocale += ` ${localeMapping?.locale},`;
          }
          this._validatePrefilledActiveMapping(
            this.getMultiCountryMappingControl(index)
          );
        });
        this.validationErrors(undefinedLocale);
      },
      (err) => {
        this._toastr.error(err.error.msg);
      }
    );
  };

  validationErrors(undefinedLocale: string) {
    if (undefinedLocale) {
      this._toastr.warning(
        notifyMessage.errorMessage.CONDITION_MAPPING_ERROR.NO_LOCALE_FOUND,
        undefinedLocale
      );
    }
  }

  deepCopyFormArray(formArrayToCopy: FormArray): FormArray {
    const copiedArray = this._fb.array([]);

    formArrayToCopy.controls.forEach((control) => {
      if (control instanceof FormGroup) {
        copiedArray.push(this.deepCopyForm(control));
      } else if (control instanceof FormArray) {
        copiedArray.push(this.deepCopyFormArray(control));
      } else {
        copiedArray.push(this._fb.control(control.value));
      }
    });

    return copiedArray;
  }

  deepCopyForm(formToCopy: FormGroup | FormArray): FormGroup | FormArray {
    if (formToCopy instanceof FormGroup) {
      const copiedForm = this._fb.group({});

      Object.keys(formToCopy.controls).forEach((controlName) => {
        const control = formToCopy.get(controlName);

        if (control instanceof FormGroup) {
          copiedForm.addControl(controlName, this.deepCopyForm(control));
        } else if (control instanceof FormArray) {
          copiedForm.addControl(controlName, this.deepCopyFormArray(control));
        } else {
          copiedForm.addControl(controlName, this._fb.control(control?.value));
        }
      });

      return copiedForm;
    } else if (formToCopy instanceof FormArray) {
      return this.deepCopyFormArray(formToCopy);
    }

    return formToCopy; // Return as is if not FormGroup or FormArray
  }

  public getBuyerQualifications = async () => {
    const { cmp } = this._authService.user!;
    const locales: Locales[] = !this.isUsedInDefaultMapping
      ? this.getSelectedLocale()
      : this.getAllLocales();
    try {
      const promises = locales.map((locale: Locales) =>
        this._decipherService
          .getBuyerQualifications(cmp, locale.localizationCode)
          .toPromise()
      );
      const responses = await Promise.all(promises);
      this.multiCountryMappingFormArray.clear();
      this.addItemObject = [
        {
          qualification_code: 399,
          recency: 30,
          desc: 'Add new item',
          category: '',
          tags: '',
          type: 1,
          class: 1,
          create_survey_question_order: 1000,
          randomize_answer_options: false,
          localizations: [],
        },
      ];
      locales.forEach((locale: Locales, idx: number) => {
        this.localeQualifications[locale.localizationCode] =
          responses[idx].data;
      });
      if (!this.isUsedInDefaultMapping) {
        this.getDecipherQuotas();
      } else {
        this.decipherMappings.mappings.forEach((localeMapping: any) => {
          this.patchDataToForm(
            this.processData(localeMapping.mappings),
            localeMapping.defaultLocale || localeMapping.locale
          );
          this.multiCountryMappingFormArray.push(
            this.deepCopyFormArray(this.mappingFormArray)
          );
        });
      }
    } catch (error: any) {
      this._toastr.error(error.error?.msg || error.message);
    }
  };

  private _getCorrectMappingObj = (target: Target): ProcessedTargetMapping => {
    const obj: ProcessedTargetMapping = {
      qualificationCode: target.qualification_code,
    };
    if (target.hasOwnProperty('range_sets')) {
      if (target.range_sets && target.range_sets[0]) {
        const { from, to } = target.range_sets[0];
        obj.rangeSets = {
          from: this._resolveFromTo(from),
          to: this._resolveFromTo(to),
        };
      } else {
        obj.rangeSets = {} as RangeSet;
      }
    } else {
      obj.conditionCodes = target.condition_codes;
    }
    return obj;
  };

  private _resolveFromTo(value: number | null) {
    const { isEmptyValue } = this._decipherImportHelper;
    return isEmptyValue(value) ? null : Number(value);
  }

  async ngOnInit() {
    await this.getBuyerQualifications();
  }

  async ngOnChanges(change: SimpleChanges) {
    if (!this.isUsedInDefaultMapping && this._selectedSurveyChanged(change)) {
      this.getDecipherQuotas();
      await this.getBuyerQualifications();
    }
    if (!this.isUsedInDefaultMapping && this._selectedLocaleChanged()) {
      await this.getBuyerQualifications();
    }
  }

  private _selectedSurveyChanged(change: SimpleChanges) {
    const prevdata = change.surveyMetadata.previousValue;
    const currdata = change.surveyMetadata.currentValue;
    return (
      change.hasOwnProperty('surveyMetadata') &&
      prevdata &&
      prevdata.selectedSurvey.title != currdata.selectedSurvey.title
    );
  }

  private _selectedLocaleChanged() {
    const availableQualification = Object.keys(this.localeQualifications);
    const unavailableLocales = this.getSelectedLocale().filter((localeObj) => {
      return availableQualification.find(
        (locale) => locale != localeObj.localizationCode
      );
    });
    return unavailableLocales.length;
  }
}

interface QualToDetailMapping {
  [key: number]: BuyerQualifications;
}
