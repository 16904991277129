import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { BuyerService } from '../../shared/services/buyer/buyer.service';
import {
  Buyer,
  BuyerList,
} from '../../shared/services/buyer/buyer-response.interface';
import { SupplierService } from '../supplier-service/supplier.service';
@Component({
  selector: 'ps-manage-buyers',
  templateUrl: './manage-buyers.component.html',
  styleUrls: ['./manage-buyers.component.css'],
})
export class ManageBuyersComponent implements OnInit, OnDestroy {
  buyers: Buyer[] = [];
  blockedBuyers: BuyerList[] = [];
  userCmp!: number;
  subscriptions: Subscription[] = [];
  selectAllTogle: boolean = false;
  constructor(
    private _buyerService: BuyerService,
    private _supplierService: SupplierService,
    private _toastr: ToasterService,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userCmp = this._authService.user!.cmp;
    this.getBuyers();
  }

  getBuyers() {
    this.subscriptions.push(
      this._buyerService.getBuyers().subscribe(
        (resp) => {
          this.buyers = resp.company;
          this.getBlockedBuyers();
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      )
    );
  }

  getBlockedBuyers() {
    const id = this.userCmp;
    this.subscriptions.push(
      this._supplierService.getBlockedBuyers(id).subscribe(
        (resp) => {
          if (resp.buyer && resp.buyer.buyerList) {
            this.blockedBuyers = resp.buyer.buyerList;
            this.transformBlockedBuyers();
          }
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      )
    );
  }

  transformBlockedBuyers() {
    this.buyers.some((buyer) => {
      const exists = this.blockedBuyers.find(
        (blockedBuyer: BuyerList) => blockedBuyer.buyerId === buyer.id
      );
      if (exists) {
        buyer.blocked = true;
      }
    });
    this._setToggleButton();
  }

  selectAll() {
    this.selectAllTogle = !this.selectAllTogle;
    if (this.selectAllTogle) {
      this.buyers.forEach((buyer: { blocked: boolean }) => {
        buyer.blocked = true;
      });
    } else {
      this.buyers.forEach((buyer: { blocked: boolean }) => {
        buyer.blocked = false;
      });
    }
  }

  blockBuyer(event: Event, index: number) {
    const e = event.target as HTMLInputElement;
    if (e.checked) {
      this.buyers[index].blocked = true;
    } else {
      this.buyers[index].blocked = false;
    }
    this._setToggleButton();
  }

  saveBuyers() {
    const buyerList: BuyerList[] = [];
    this.buyers.forEach((buyer) => {
      if (buyer.blocked) {
        const buyerObj = {
          buyerId: buyer.id,
          buyerStatus: 'B',
        };
        buyerList.push(buyerObj);
      }
    });
    const updateObj = {
      supplierId: this.userCmp,
      buyerList: buyerList,
    };
    this.subscriptions.push(
      this._supplierService.updateBlockedBuyers(updateObj).subscribe(
        (resp) => {
          this._toastr.success('Success! Buyer status has been saved');
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      )
    );
  }

  private _setToggleButton() {
    if (this.buyers.length === this.blockedBuyers.length) {
      this.selectAllTogle = true;
    } else {
      this.selectAllTogle = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
