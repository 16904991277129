import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '../../../shared/services/common-service/common-service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { CountryInterface } from '../../../shared/interfaces/create-surveys.interface';
import { SurveyTransReportService } from './survey-trans-report.service';
import { Constants } from '../../../constants/trans-status';
@Component({
  selector: 'ps-survey-transaction-report',
  templateUrl: './survey-transaction-report.component.html',
  styleUrls: ['./survey-transaction-report.component.css'],
})
export class SurveyTransactionReportComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public countries: CountryInterface[] = [];
  public selectedCountry!: CountryInterface;
  public transactionData: EachPayload[] = [];
  public daterange = {
    start: { _d: Date.now() },
    end: { _d: Date.now() },
    label: '',
  };
  public options: OptionsInterface = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };

  constructor(
    private _commonService: CommonService,
    private _toaster: ToasterService,
    private _datepipe: DatePipe,
    private _surveytransReportService: SurveyTransReportService
  ) {}
  ngOnInit() {
    this._getCountries();
  }

  private _getCountries() {
    const addAllOption = true;
    const countries = this._commonService.getCountries(addAllOption).subscribe(
      (countriesData) => {
        this.countries = countriesData;
        this.selectedCountry = this.countries[0];
      },
      (err) => {
        this._toaster.error(err.error.msg);
      }
    );
    this._subscriptions.add(countries);
  }

  selectCountry(country: CountryInterface) {
    this.selectedCountry = country;
  }

  public selectedDate(value: any) {
    this.daterange.start = value.start;
    this.daterange.end = value.end;
  }

  findReportData() {
    const payload = {
      startDate: this._datepipe.transform(
        this.daterange.start._d,
        'yyyy-MM-dd'
      ),
      endDate: this._datepipe.transform(this.daterange.end._d, 'yyyy-MM-dd'),
    };
    if (payload.startDate && payload.endDate) {
      const reportData = this._surveytransReportService
        .surveyTransactionReportData(payload, this.selectedCountry.short_Code)
        .subscribe(
          (surveyReportData) => {
            this.transactionData = surveyReportData.result;
            this.transactionData = this.calculateStats(this.transactionData);
          },
          (error) => {
            this._toaster.error(error.error.msg);
          }
        );
      this._subscriptions.add(reportData);
    }
  }

  calculateStats(transData: Array<EachPayload>) {
    let surveyName: string;
    let buyerRevenue: number = 0,
      supplierRevenue: number = 0,
      completes: number = 0,
      validClicks: number = 0,
      buyerClicks: number = 0,
      totalClicks: number = 0;

    transData.forEach((eachTransaction) => {
      eachTransaction.Payload.forEach((eachPayload) => {
        if (
          [Constants.TRANSACTION_STATUS.COMPLETE].indexOf(
            eachPayload.statusCode
          ) > -1
        ) {
          buyerRevenue = eachPayload.buyerRevenue;
          supplierRevenue = eachPayload.supplierRevenue;
          completes = eachPayload.total;
        }
        if (
          [
            Constants.TRANSACTION_STATUS.BUYER_QUOTAFULL,
            Constants.TRANSACTION_STATUS.BUYER_TERMINATION,
            Constants.TRANSACTION_STATUS.BUYER_DROP,
            Constants.TRANSACTION_STATUS.COMPLETE,
          ].indexOf(eachPayload.statusCode) > -1
        ) {
          validClicks += eachPayload.total;
        }
        if (
          [
            Constants.TRANSACTION_STATUS.BUYER_QUOTAFULL,
            Constants.TRANSACTION_STATUS.BUYER_TERMINATION,
            Constants.TRANSACTION_STATUS.BUYER_DROP,
            Constants.TRANSACTION_STATUS.BUYER_QUALITY_TERMINATION,
            Constants.TRANSACTION_STATUS.COMPLETE,
            Constants.TRANSACTION_STATUS.BUYER_SECURITY,
          ].indexOf(eachPayload.statusCode) > -1
        ) {
          buyerClicks += eachPayload.total;
        }
        totalClicks += eachPayload.total;
        surveyName = eachPayload.surveyName;
      });
      eachTransaction.surveyName = surveyName;
      eachTransaction.buyerRevenue = Math.round(buyerRevenue * 100) / 100;
      eachTransaction.supplierRevenue = Math.round(supplierRevenue * 100) / 100;
      eachTransaction.margin =
        Math.round(
          (eachTransaction.buyerRevenue - eachTransaction.supplierRevenue) * 100
        ) / 100;
      eachTransaction.completes = completes;
      eachTransaction.totalClicks = totalClicks;
      eachTransaction.buyerClicks = buyerClicks;
      eachTransaction.validClicks = validClicks;
      eachTransaction.buyerEpc =
        Math.round((buyerRevenue / buyerClicks) * 100) / 100;
      eachTransaction.validEpc =
        Math.round((buyerRevenue / validClicks) * 100) / 100;
      eachTransaction.totalEpc =
        Math.round((buyerRevenue / totalClicks) * 100) / 100;
      (buyerRevenue = 0),
        (supplierRevenue = 0),
        (completes = 0),
        (validClicks = 0),
        (buyerClicks = 0),
        (totalClicks = 0);
    });
    return transData;
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
interface OptionsInterface {
  locale: {
    format: string;
  };
  alwaysShowCalendars: boolean;
}
interface EachPayload {
  buyerClicks?: number;
  totalClicks?: number;
  completes?: number;
  validEpc?: number;
  buyerEpc?: number;
  totalEpc?: number;
  validClicks?: number;
  margin?: number;
  supplierRevenue?: number;
  buyerRevenue?: number;
  surveyName?: string;
  _id: number;
  Payload: Array<{
    statusCode: number;
    buyerRevenue: number;
    supplierRevenue: number;
    total: number;
    surveyName: string;
  }>;
}
