<div class="row">
  <div class="flex-column">
    <div class="d-flex justify-content-center">
      <input
        #fileUpload
        hidden
        type="file"
        class="file-upload"
        accept=".xlsx,.xls,.csv"
        (change)="uploadMappings($event)"
      />
      <button
        type="button"
        class="btn btn-link upload-button"
        (click)="fileUpload.value = ''; fileUpload.click()"
      >
        Upload a file
      </button>
    </div>
    <div class="d-flex justify-content-center">.csv</div>
    <div class="d-flex justify-content-center p-3">
      <a
        type="button"
        class="btn btn-link download-button"
        (click)="downloadSampleSamplifyMappings()"
      >
        Download Template
      </a>
    </div>
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-link download-button"
        (click)="downloadSamplifyCSVFile()"
      >
        Download Mappings CSV
      </button>
    </div>
    <div class="d-flex justify-content-center or-text">or</div>
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-link download-button-clear"
        (click)="clearSamplifyMappings()"
      >
        Clear Upload
      </button>
    </div>
  </div>
</div>
