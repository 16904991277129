import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AcitivityService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  public register(webhook: string, cmpId: number) {
    return this._http.post(`${this._baseUrl}/activities/v2/notifications`, {
      webhook,
      cmpId,
    });
  }

  public unsubscribe(cmpId: number) {
    return this._http.put(
      `${this._baseUrl}/activities/v2/notifications/unsubscribe`,
      {
        cmpId,
      }
    );
  }
}
