import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Question } from '../questions-library-services/questions-library-services.interface';
import { QuestionsLibraryService } from '../questions-library-services/questions-library-services.service';

@Component({
  selector: 'ps-questions-list',
  templateUrl: './questions-list.component.html',
  styleUrls: ['./questions-list.component.css'],
})
export class QuestionsListComponent implements OnInit, OnChanges {
  public questions: Question[] = [];
  public page: number = 1;
  public questionId!: number;
  public total: number = 0;
  private _searchKeySub: Subject<string> = new Subject();
  private _subscription: Subscription = new Subscription();
  @Input() search: string = '';
  @Input() isReloadQuestions: boolean = false;
  @Output() qualificationId: EventEmitter<number> = new EventEmitter();
  @Input() filterCategories: string = '';

  constructor(
    private _toastrService: ToasterService,
    private _questionsLibraryService: QuestionsLibraryService
  ) {}

  ngOnInit(): void {
    this.getQuestions(1);
    const searchKeyRes = this._searchKeySub
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((searchTextValue) => {
        this.search = searchTextValue;
        this.getQuestions(1);
      });
    this._subscription.add(searchKeyRes);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.search?.currentValue) {
      this._searchKeySub.next(changes.search.currentValue);
    } else {
      this._searchKeySub.next(this.search);
    }
    // If updated questions list is asked for, we need to update the list (In case of add/delete question)
    if (changes.isReloadQuestions?.currentValue) {
      this.search = '';
      this.getQuestions(1);
      this.isReloadQuestions = false;
    }

    if (
      changes.filterCategories?.previousValue !==
      changes.filterCategories?.currentValue
    ) {
      this.getQuestions(1);
    }
  }

  getQuestions(page: number) {
    this._questionsLibraryService
      .getQuestions(page, this.search, this.filterCategories)
      .subscribe(
        (response) => {
          this.total = Number(response.total);
          this.questions = response.questions || [];
          this.page = page;
        },
        (error) => {
          this._toastrService.error(error.error.ps_api_response_message);
          this.questions = [];
        }
      );
  }

  setQualificationId(id: number) {
    this.questionId = id;
    this.qualificationId.emit(id);
  }
}
