import { Component, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Transaction } from '../transaction-by-status-report.interface';

@Component({
  selector: 'ps-transaction-by-status-report-table',
  templateUrl: './transaction-by-status-report-table.component.html',
  styleUrls: ['./transaction-by-status-report-table.component.css'],
})
export class TransactionByStatusReportTableComponent {
  private readonly _bucketUrl = environment.BUCKET_URL;

  constructor() {}
  @Input()
  public dataList: Array<Transaction> = [];
}
