<div class="d-flex justify-content-center">
  <form (ngSubmit)="saveUser()" [formGroup]="form">
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label for="UserId" class="col-form-label pull-right">User Id</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <input
          type="email"
          id="UserId"
          name="userId"
          class="form-control"
          formControlName="email"
          [attr.disabled]="edit ? true : null"
          placeholder="User Id"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label for="Name" class="col-form-label pull-right">Name</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <input
          type="text"
          id="Name"
          name="name"
          class="form-control"
          formControlName="name"
          placeholder="Name"
        />
      </div>
    </div>
    <!-- Prevent Chrome to autofill -->
    <input
      hidden="true"
      type="password"
      name="password"
      autocomplete="new-password"
    />
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label for="SlackMemberId" class="col-form-label pull-right"
          >Slack ID</label
        >
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <input
          type="text"
          id="SlackMemberId"
          name="SlackMemberId"
          class="form-control"
          formControlName="slackMemberId"
          placeholder="Slack ID"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label class="col-form-label pull-right">Company</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <ps-ui-select-dropdown
          class="basic-size-with-default-color fix-size-block-padding manage-select-dropdown"
          [field]="'name'"
          [dropdownItems]="companies"
          labelNone="--Select Company--"
          labelAlignment="start"
          formControlName="company"
          [label]="form.controls.company.value?.name"
          [disabled]="editCompany"
          (selectedItem)="setSelectedCompany($event)"
        ></ps-ui-select-dropdown>
      </div>
      <span
        *ngIf="
          superOperator &&
          edit &&
          !user.isApiUser &&
          user.operatorAccessLevels === 'none'
        "
        class="fa fa-solid fa-{{
          editCompany ? 'pencil' : 'close'
        }} fa-lg align-self-center edit-company col-sm-2 col-md-2 col-lg-2 col-xl-2"
        (click)="enableEditCompany()"
      ></span>
    </div>
    <div class="form-group row" *ngIf="enableViewByPMForCompany">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label class="col-form-label pull-right">Default PM</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <ps-ui-select-dropdown
          class="basic-size-with-black-color fix-size-block-padding manage-select-dropdown"
          [field]="'name'"
          [dropdownItems]="projectManagers"
          labelAlignment="start"
          formControlName="proxyProjectManager"
          [label]="form.controls.proxyProjectManager.value?.name"
          (selectedItem)="form.controls.proxyProjectManager.setValue($event)"
        ></ps-ui-select-dropdown>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label for="apiUserStatus" class="col-form-label pull-right"
          >Is API User</label
        >
      </div>
      <div class="col-sm-1 col-md-1 col-lg-1 col-xl-1">
        <ps-ui-slider
          id="apiUserStatus"
          (selectedChange)="setSelectedApiUserStatus($event)"
          [selected]="form.value.apiUserStatus"
          [disabled]="disableIsApiUserToggle"
        ></ps-ui-slider>
      </div>
      <div
        *ngIf="selectedCompany && disableIsApiUserToggle"
        class="col-sm-4 col-md-4 col-lg-4 col-xl-4 api-alert"
      >
        <span>
          This account already has an API user, there can be only 1 API user per
          company.
        </span>
      </div>
      <div
        *ngIf="!disableIsApiUserToggle"
        class="col-sm-4 col-md-4 col-lg-4 col-xl-4 api-alert"
      >
        <span>
          Once an API user is created it can not be disabled. If there is an
          urgent need please contact the Product Team in slack on the
          #platform_support channel.
        </span>
      </div>
    </div>
    <div class="form-group row" *ngIf="edit && form.value.apiUserStatus">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label class="col-form-label pull-right">Token</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <textarea
          rows="8"
          name="token"
          class="form-control"
          formControlName="token"
          *ngIf="form.value.token"
          [attr.disabled]="form.value.token ? true : null"
        ></textarea
        ><br />
        <button
          type="button"
          *ngIf="form.value.token"
          class="btn btn-success pull-right"
          (click)="copyToken()"
        >
          Copy Token
        </button>
        <button
          type="button"
          *ngIf="!form.value.token"
          class="btn btn-success"
          (click)="getToken(user.id)"
        >
          Get Token
        </button>
      </div>
    </div>
    <div class="form-group row" *ngIf="!edit">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label for="welcomeEmailStatus" class="col-form-label pull-right"
          >Send Password Setup Email</label
        >
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <ps-ui-slider
          id="welcomeEmailStatus"
          (selectedChange)="setSelectedWelcomeEmailStatus($event)"
          [selected]="form.value.welcomeEmailStatus"
        ></ps-ui-slider>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label for="Password" class="col-form-label pull-right">Password</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <input
          type="password"
          id="Password"
          name="password"
          class="form-control"
          formControlName="password"
          [attr.disabled]="form.value.welcomeEmailStatus ? true : null"
          placeholder="Password"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label class="col-form-label pull-right"
          >Buyer Side Access Levels</label
        >
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <ps-ui-select-dropdown
          class="basic-size-with-black-color fix-size-block-padding manage-select-dropdown"
          [field]="'label'"
          [enableSearch]="false"
          [dropdownItems]="buyerAccessLevels"
          labelNone="--Select Buyer Access Levels--"
          [label]="form.controls.buyerSideAccessLevels.value?.label"
          labelAlignment="start"
          formControlName="buyerSideAccessLevels"
          [disabled]="disableBuyerAccessLevel"
          (selectedItem)="form.controls.buyerSideAccessLevels.setValue($event)"
        ></ps-ui-select-dropdown>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label class="col-form-label pull-right"
          >Seller Side Access Levels</label
        >
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <ps-ui-select-dropdown
          class="basic-size-with-black-color fix-size-block-padding manage-select-dropdown"
          [field]="'label'"
          [enableSearch]="false"
          [dropdownItems]="sellerAccessLevels"
          labelNone="--Select Supplier Access Levels--"
          formControlName="supplierSideAccessLevels"
          labelAlignment="start"
          [label]="form.controls.supplierSideAccessLevels.value?.label"
          [disabled]="disableSupplierAccessLevel"
          (selectedItem)="
            form.controls.supplierSideAccessLevels.setValue($event)
          "
        ></ps-ui-select-dropdown>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label class="col-form-label pull-right"
          >Operator Side Access Levels</label
        >
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <ps-ui-select-dropdown
          class="basic-size-with-black-color fix-size-block-padding manage-select-dropdown"
          [field]="'label'"
          [enableSearch]="false"
          [dropdownItems]="operatorAccessLevels"
          [label]="form.controls.operatorAccessLevels.value?.label"
          labelNone="--Select Operator Access Levels--"
          labelAlignment="start"
          formControlName="operatorAccessLevels"
          [disabled]="disableOperatorAccessLevel"
          (selectedItem)="form.controls.operatorAccessLevels.setValue($event)"
        ></ps-ui-select-dropdown>
      </div>
    </div>
    <div class="form-group row" *ngIf="questionLibraryAccessToggle.show">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label for="QuestionLibraryAccess" class="col-form-label pull-right"
          >Question Library Access</label
        >
      </div>
      <div class="col-sm-1 col-md-1 col-lg-1 col-xl-1">
        <ps-ui-slider
          id="QuestionLibraryAccess"
          (selectedChange)="setQuestionLibraryAccess($event)"
          [selected]="form.value.questionLibraryAccessLevel"
          [disabled]="questionLibraryAccessToggle.disabled"
        ></ps-ui-slider>
      </div>
    </div>
    <div class="actions mb-3">
      <button
        type="button"
        class="btn btn-primary cancel-button"
        (click)="resetUserForm()"
      >
        Cancel
      </button>
      <button type="submit" class="btn btn-success">Save</button>
      <button
        *ngIf="user?.status"
        class="btn"
        type="button"
        [ngClass]="user.status === 'Active' ? 'btn-secondary' : 'btn-success'"
        [disabled]="user.status === 'Active' && disableDeactivateButton"
        (click)="openStatusModal()"
      >
        {{ user.status === 'Active' ? 'Deactivate' : 'Activate' }}
      </button>
    </div>
  </form>
</div>
