export const QUALIFICATION_CODES = Object.freeze({
  GENDER: 211,
  AGE: 212,
  INCOME: 213,
  RACE: 214,
  EMPLOYMENT: 215,
  EDUCATION: 216,
  RELATIONSHIP: 217,
  CHILDREN: 218,
  CHILD_GENDER: 220,
  CHILD_AGE: 230,
  DEVICE: 219,
  REGION: 223,
  DIVISION: 224,
  STATE: 225,
  ZIPCODE: 229,
  FSA: 303,
  RACE_ETHNICITY: 244,
  HISPANIC_ORIGIN: 245,
});

export const QUALIFICATION_TYPES = Object.freeze({
  SINGLE_PUNCH: 1,
  SINGLE_PUNCH_ALT: 2,
  MULTI_PUNCH: 3,
  RANGE: 4,
  INPUT: 5,
});

export const CHILDREN_QUALIFICATION_CODES = [
  QUALIFICATION_CODES.CHILDREN,
  QUALIFICATION_CODES.CHILD_AGE,
  QUALIFICATION_CODES.CHILD_GENDER,
];

export const NOT_TO_BE_GROUPED_QUALIFICATIONS = [211, 212, 213, 218, 229, 303];

export const FILE_UPLOAD_LOCATION_QUOTAS = [229, 303];

export const LOCATION_QUALIFICATION_CODES = [
  QUALIFICATION_CODES.ZIPCODE,
  QUALIFICATION_CODES.FSA,
];
