<ps-ui-card>
  <p psCardHeader>BULK OPERATIONS</p>
  <button
    psCardContent
    [psUiDropdownToggleFor]="bulkOptions"
    class="button-dropdown"
  >
    <span class="button-description">
      <span>{{ displaySelectedOption }}</span>
      <span class="chevron-down"></span>
    </span>
  </button>

  <p psCardFooter class="card-foot">
    <ps-ui-dropdown #bulkOptions class="dropdown-options">
      <div *ngFor="let option of options">
        <ng-container>
          <button
            psUiDropdownItem
            class="status-option"
            (click)="onSelectOption(option)"
          >
            {{ option.label }}
          </button>
        </ng-container>
      </div>
    </ps-ui-dropdown>
  </p>
</ps-ui-card>
