<main class="container">
  <div class="row">
    <div class="backbutton hasing-buttons col-md-6 mt-6">
      <button
        id="dashboard"
        type="button"
        psUiButton
        size="small"
        color="primary"
        [routerLink]="['/dashboard/']"
      >
        Back to Dashboard
      </button>
    </div>
  </div>
  <p class="reports-dashboard-text">Reports Dashboard</p>

  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <ps-ui-tab-group
        [activeIndex]="activeTabIndex"
        (activeIndexChange)="setActiveTab($event)"
      >
        <ps-ui-tab
          *ngFor="let tab of reportsdashboardSettingsTabs"
          [label]="tab.title"
        >
          <ng-template psUiTabContent>
            <div *ngIf="tab.key === 'SURVEY_PERFORMANCE_REPORT'">
              <ps-survey-performance-report></ps-survey-performance-report>
            </div>

            <div *ngIf="tab.key === 'DAILY_STATISTICS_REPORT'">
              <ps-daily-statistics-report></ps-daily-statistics-report>
            </div>

            <div *ngIf="tab.key === 'SURVEY_TRANSACTION_REPORT'">
              <ps-survey-transaction-report></ps-survey-transaction-report>
            </div>

            <div *ngIf="tab.key === 'BUYER_SALES_REPORT'">
              <ps-buyer-sales-report></ps-buyer-sales-report>
            </div>

            <div *ngIf="tab.key === 'SUPPLIER_REPORT'">
              <ps-supplier-report></ps-supplier-report>
            </div>

            <div *ngIf="tab.key === 'PSID_REPORT'">
              <ps-psid-report></ps-psid-report>
            </div>

            <div *ngIf="tab.key === 'STC_REPORT'">
              <ps-stc-report></ps-stc-report>
            </div>

            <div *ngIf="tab.key === 'TRANSACTION_BY_STATUS'">
              <ps-transaction-by-status-report></ps-transaction-by-status-report>
            </div>

            <div *ngIf="tab.key === 'EPC_REPORT'">
              <ps-epc-report></ps-epc-report>
            </div>
          </ng-template>
        </ps-ui-tab>
      </ps-ui-tab-group>
    </div>
  </div>
</main>
