import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { Component, Inject } from '@angular/core';
import {
  Answer,
  CustomScreener,
  Locales,
  Options,
} from '../../../shared/services/decipher/decipher.interface';
import { ModalData, ModalRef } from '@purespectrum1/ui';
import { DecipherService } from '../../../shared/services/decipher/decipher.service';
import { AuthService } from '../../../auth/auth.service';
import {
  BuyerQualifications,
  LocaleQualificationsAPI,
} from 'src/app/shared/interfaces/qualification-mappings.interface';
import { MappingModalComponent } from '../mapping-modal/mapping-modal.component';
import { catchError } from 'rxjs/operators';
import { notifyMessage } from '../../../constants/notify-message';
import { throwError } from 'rxjs';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
@Component({
  selector: 'ps-add-new-qualifiction-modal',
  templateUrl: './add-new-qualifiction-modal.component.html',
  styleUrls: ['./add-new-qualifiction-modal.component.css'],
})
export class AddNewQualifictionModalComponent {
  customScreenerForm!: FormGroup;
  selectedLocale!: Locales;
  langData: Locales[] = this._modalData.langData;
  options: Options[] = [
    {
      id: 1,
      value: 1,
      label: 'Single Select',
    },
    {
      id: 2,
      value: 3,
      label: 'Multi Select',
    },
  ];
  newQuestion: boolean = false;
  validations: any = {};
  customScreenerFormValue!: CustomScreener;
  localisationNotAvailable: boolean = false;
  localisationError: string = '';
  localisationSaveError = false;
  allowEditQuestion: boolean = false;
  defaultRows: any = [];
  locale!: Locales;
  newQualification!: LocaleQualificationsAPI;
  constructor(
    @Inject(ModalData) private _modalData: any,
    private _decipherService: DecipherService,
    private _authService: AuthService,
    private _formBuilder: FormBuilder,
    private _modalRef: ModalRef<MappingModalComponent, BuyerQualifications[]>,
    private _toastr: ToasterService
  ) {
    this.customScreenerForm = new FormGroup({
      qName: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9 ]+$'),
      ]),
      text: new FormControl('', [
        Validators.required,
        Validators.pattern('^[^"\\\\]+$'),
      ]),
      type: new FormControl('', Validators.required),
      noneOfAbove: new FormControl(false, Validators.required),
      other: new FormControl(false, Validators.required),
    });
    this.customScreenerFormValue = this.customScreenerForm.value;
  }

  addNewQuestion() {
    // Add as new question
    this.customScreenerFormValue.class = 3;
    const payload = {
      ...this.customScreenerForm.value,
      buyerName: this._authService.user!.cmp,
      text: {
        textItem: this.customScreenerForm.value.text,
      },
    };
    this._decipherService
      .createNewQuestion(this._authService.user!.cmp, payload)
      .pipe(
        catchError((error) => {
          const {
            msg: { ps_api_response_code = 0, ps_api_response_message = '' },
          } = error;
          if (ps_api_response_code == 1014) {
            this._toastr.error(
              notifyMessage.errorMessage.CONDITION_MAPPING_ERROR
                .CONTACT_PURESPECTRUM
            );
            return throwError(
              notifyMessage.errorMessage.CONDITION_MAPPING_ERROR
                .CONTACT_PURESPECTRUM
            );
          }
          this._toastr.error(
            notifyMessage.errorMessage.CONDITION_MAPPING_ERROR.GENERIC_ERROR,
            ps_api_response_message
          );
          return throwError(ps_api_response_message);
        })
      )
      .subscribe((data) => {
        this.newQualification = data;
        this.close();
      });
  }
  initializeRows() {
    if (!this.customScreenerForm.controls['answers']) {
      this.customScreenerForm.addControl(
        'answers',
        this._formBuilder.array([], Validators.required)
      );
      this.newQuestion = true;
      this.langData.forEach((lang: Locales) => {
        this.addAnswers(lang.localizationCode);
      });
    }
  }
  // /**
  //  * It is used to get create controls of independent fields in form
  //  *  */
  addRow(optionValue: number) {
    this.customScreenerForm.controls['type'].setValue(optionValue);
    this.initializeRows();
  }

  addAnswers = (answer: string = '') => {
    const items = this.customScreenerForm.get('answers') as FormArray;
    items.push(this.createAnswersFields(answer));
    this.addMoreItems({}, items.length - 1);
    this.getDefaultRows(items, items.length - 1);
  };

  createAnswersFields = (answer: string) => {
    const fieldParams = this._formBuilder.group({});
    fieldParams.addControl(
      'locale',
      this._formBuilder.control(answer, Validators.required)
    );
    fieldParams.addControl('rows', this._formBuilder.array([]));
    return fieldParams;
  };

  public getcustomScreenerFormControl = () => {
    return (this.customScreenerForm.controls['answers'] as FormArray)
      .controls as FormGroup[];
  };
  findIndexOfSelectedLocale() {
    return this.customScreenerForm.controls['answers'].value.findIndex(
      (ans: Answer) => ans.locale === (this.locale?.localizationCode || 'en_US')
    );
  }
  public getcustomScreenerFormIndexControl = (
    outerIndex: number,
    innerIndex: number
  ) => {
    return (this.customScreenerForm.controls['answers'] as FormArray).controls[
      innerIndex
    ] as FormGroup;
  };

  public getcustomScreenerFormRowsIndexControl = (innerIndex: number) => {
    return (
      (this.customScreenerForm.controls['answers'] as FormArray).controls[
        innerIndex
      ] as FormGroup
    ).controls['rows'] as FormArray;
  };

  public getcustomScreenerFormRowIndexControl = (innerIndex: number) => {
    return (
      (
        (this.customScreenerForm.controls['answers'] as FormArray).controls[
          innerIndex
        ] as FormGroup
      ).controls['rows'] as FormArray
    ).controls;
  };

  public getcustomScreenerAnswerOptionsControl = (
    innerIndex: number,
    outerIndex: number
  ) => {
    return (
      (
        (
          (
            (this.customScreenerForm.controls['answers'] as FormArray).controls[
              innerIndex
            ] as FormGroup
          ).controls['rows'] as FormArray
        ).controls[outerIndex] as FormGroup
      ).controls['text'] as FormGroup
    ).controls['textItem'];
  };

  addMoreItems = (autoFilledValue: any, index: number = 0) => {
    const items = this.getcustomScreenerFormRowsIndexControl(index);
    if (!autoFilledValue.condition_code) {
      autoFilledValue.condition_code = this.findMaxCondCode(index) + 1;
    }
    items.push(this.createFields(autoFilledValue));
    this.getDefaultRows(items, index);
    items.controls.sort(this.sortByField('condition_code'));
  };
  sortByField(field: string) {
    return (a: any, b: any) => {
      const aValue = a.get(field).value;
      const bValue = b.get(field).value;
      if (aValue > bValue) {
        return 1;
      }
      return -1; // Adjust the sorting logic as needed
    };
  }

  createFieldsForAllLocales = (autoFilledValue: any = {}) => {
    for (let id = 0; id < this.langData.length; id++) {
      const items = this.getcustomScreenerFormRowsIndexControl(id);
      if (!autoFilledValue.condition_code) {
        autoFilledValue.condition_code = this.findMaxCondCode(id) + 1;
      }
      items.push(this.createFields(autoFilledValue));
      this.getDefaultRows(items, id);
      items.controls.sort(this.sortByField('condition_code'));
    }
  };

  getDefaultRows = (items: any, index: number = 0) => {
    const allRows = items.value;
    this.defaultRows[index] = allRows.filter(
      (row: any) => row.condition_code !== 998 && row.condition_code !== 999
    );
  };

  createFields = (autoFilledValue: any = {}) => {
    let { text = { textItem: '' }, condition_code } = autoFilledValue;
    const fieldParams: any = {};
    fieldParams['text'] = this.createFormGroup(text.textItem);
    fieldParams['condition_code'] = new FormControl(condition_code, []);
    return this._formBuilder.group(fieldParams);
  };

  createFormGroup(autoFilledValue: string) {
    const fieldParams: any = {};
    const validators = [Validators.required, Validators.pattern('^[^"\\\\]+$')];
    fieldParams['textItem'] = new FormControl(autoFilledValue, validators);
    return this._formBuilder.group(fieldParams);
  }
  getcustomScreenerValue() {
    const previousLocale =
      this.customScreenerForm.getRawValue().answers[0].locale;
    const previousValue = { ...this.customScreenerForm.getRawValue() };
    this.saveCustomScreenerOnChangeOfLocale(previousLocale, previousValue);
  }
  checkAnswerRows() {
    let answers = this.customScreenerFormValue.answers;
    let valid = true;
    if (this.langData.length != answers.length) {
      valid = false;
    }
    answers.forEach((answer: Answer) => {
      if (answer && (!answer.locale || !answer.rows)) {
        valid = false;
      }
    });
    if (!this.customScreenerForm.controls['answers'].valid) {
      this.localisationNotAvailable = true;
      this.localisationSaveError = true;
      this.localisationError =
        'One or more Translations are missing. Create before saving.';
    } else {
      this.localisationNotAvailable = false;
      this.localisationSaveError = false;
    }

    return valid;
  }
  checkCustomScreenerFormValueForLocale() {
    // check all field exist
    const fields = ['qName', 'type', 'answers', 'text'];
    let fieldParams = [];
    fieldParams = fields.filter((field) => {
      if (!this.customScreenerFormValue.hasOwnProperty(field)) {
        return true;
      } else {
        if (field == 'text' && !this.customScreenerFormValue[field].textItem) {
          return true;
        }
        return false;
      }
    });

    if (fieldParams.length || !this.checkAnswerRows()) {
      return false;
    } else {
      return true;
    }
  }

  checkformValidation(valid: boolean, formValid: boolean) {
    return this.customScreenerForm.valid && valid && formValid;
  }

  saveQuestion = async () => {
    let valid = false;
    let formValid = false;
    if (this.customScreenerForm.getRawValue().answers) {
      this.getcustomScreenerValue();
      valid = this.checkValidations();
      const defaultLanguageindex = 0;
      const buyer_text =
        this.customScreenerFormValue.answers[defaultLanguageindex].text;
      this.customScreenerFormValue.text = buyer_text;
      formValid = this.checkCustomScreenerFormValueForLocale();
    }
    if (this.checkformValidation(valid, formValid)) {
      this.addNewQuestion();
    } else {
      this.validateAllFormFields(this.customScreenerForm);
    }
  };
  findMaxCondCode(index: number) {
    let maxCode = 110;
    const items = this.getcustomScreenerFormRowsIndexControl(index);
    items.value.forEach((row: any) => {
      if (row.condition_code >= maxCode && row.condition_code! < 900) {
        maxCode = row.condition_code;
      }
    });
    return maxCode;
  }

  // /**
  //  * It is used to mark all fields as touched to show Error messages
  //  *  */
  validateAllFormFields = (formGroup: FormGroup) => {
    Object.keys(formGroup.controls).forEach((field) => {
      const control: any = formGroup.get(field);
      if (control.controls) {
        control.controls.forEach((innerControl: any, index: number) => {
          control.controls[index]
            .get(Object.keys(control.controls[0].controls)[0])
            .markAsTouched({ onlySelf: true });
        });
      }
      control.markAsTouched({ onlySelf: true });
    });
  };

  checkValidations() {
    return this.checkFormValidation() && this.duplicateOptionsValidation();
  }

  checkFormValidation() {
    if (!this.customScreenerForm.valid && !this.customScreenerForm.disabled) {
      this.validations.invalidForm = true;
      return false;
    } else {
      this.validations.invalidForm = false;
      return true;
    }
  }

  manageOtherAndNoneOfAboveEvents = (conditionCode: number) => {
    this.initializeRows();
    const conditionCodeMapping: any = {
      998: 'other',
      999: 'noneOfAbove',
    };

    this.customScreenerForm.value[conditionCodeMapping[conditionCode]]
      ? this.createFieldsForAllLocales({
          text: { textItem: conditionCodeMapping[conditionCode] },
          condition_code: conditionCode,
        })
      : this.removeSubControlByConditionCode(conditionCode);
  };
  removeSubControlByConditionCode = (condition_code: number) => {
    for (const lang of this.langData) {
      const { rows = [] } =
        this.customScreenerForm.value.answers[
          this.findIndexOfSelectedLocale()
        ] || {};
      const index = rows.findIndex(
        (ans: any) => ans.condition_code === condition_code
      );
      if (index > -1) {
        this.removeSubControl(index);
      }
    }
  };
  removeSubControl = (index: number) => {
    for (let id = 0; id < this.langData.length; id++) {
      const control: any = (
        (this.customScreenerForm.controls['answers'] as FormArray).controls[
          id
        ] as FormGroup
      ).controls['rows'] as FormArray;
      // Check if conditionCode is already passed to the function
      control.removeAt(index);
      ((
        (this.customScreenerForm.controls['answers'] as FormArray).controls[
          id
        ] as FormGroup
      ).controls['rows'] as FormArray) = control;
      this.getDefaultRows(control, id);
    }
  };

  checkLocalesCondition(locale: Locales) {
    if (
      locale &&
      (locale.primaryLocale ||
        this.langData.length == 1 ||
        this.langData[0].localizationCode == locale.localizationCode)
    ) {
      return true;
    }
    return false;
  }

  saveCustomScreenerOnChangeOfLocale(locale: string, previousValue: any) {
    this.customScreenerFormValue.qName = previousValue?.qName || '';
    this.customScreenerFormValue.type = previousValue?.type || 1;
    this.customScreenerFormValue.answers = previousValue?.answers || [];
    const previousLocaleIndex = this.customScreenerFormValue.answers.findIndex(
      (answer: any) => {
        return answer.locale == locale;
      }
    );
    if (previousLocaleIndex >= 0) {
      let text = { textItem: previousValue.text };
      previousValue.answers[0].text = text;
      this.customScreenerFormValue.answers[previousLocaleIndex] =
        previousValue.answers[0];
    } else {
      let text = { textItem: previousValue.text };
      previousValue.answers[0].text = text;
      this.customScreenerFormValue.answers.push(previousValue.answers[0]);
    }
  }
  selectLangOption = ($event: any) => {
    if (this.customScreenerForm.getRawValue().answers) {
      const previousLocale =
        this.customScreenerForm.getRawValue().answers[0].locale;
      const previousValue = { ...this.customScreenerForm.getRawValue() };
      this.saveCustomScreenerOnChangeOfLocale(previousLocale, previousValue);
    }
    this.locale = $event;
    const prevLocale = this.selectedLocale;
    setTimeout(() => (this.selectedLocale = prevLocale));
    return false;
  };
  duplicateOptionsValidation() {
    const occurances: any = {};
    const { rows = [] } = this.customScreenerForm.value.answers[0] || {};
    const duplicateValueRow = rows.find((row: any) => {
      const { text: { textItem: rowValue = '' } = {} } = row;
      if (rowValue) {
        if (occurances.hasOwnProperty(rowValue)) {
          return row;
        }
        occurances[rowValue] = 1;
      }
    });
    if (duplicateValueRow) {
      this.validations.duplicateAnswerOption = `"${duplicateValueRow.text.textItem}" is used multiple times`;
      return false;
    } else {
      delete this.validations.duplicateAnswerOption;
      return true;
    }
  }
  close() {
    this._modalRef.close(this.newQualification?.data);
  }
}
