import { SurveyListing } from 'src/app/shared/services/buyer-api/survey.interface';
import { DEFAULT_STATS_SURVEY } from '../dashboard-constants';
import { SurveyStats } from '../bulk-edit/types';

type DashboardSelectedSurveyParams = {
  stats: SurveyStats;
  selected: SurveyListing[];
};

export class DashboardSelectedSurveys {
  constructor(
    private readonly _stats: SurveyStats,
    private readonly _selected: SurveyListing[]
  ) {}

  public static empty(): DashboardSelectedSurveys {
    return new DashboardSelectedSurveys(DEFAULT_STATS_SURVEY, []);
  }

  public select(selected: SurveyListing[], stats: SurveyStats) {
    return this.copyWith({
      stats: stats,
      selected: selected,
    });
  }

  public stats(): SurveyStats {
    return this._stats;
  }

  public selected(): SurveyListing[] {
    return this._selected;
  }

  public some(): boolean {
    return this._selected.length > 0;
  }

  public length(): number {
    return this._selected.length;
  }

  public copyWith(
    params: DashboardSelectedSurveyParams
  ): DashboardSelectedSurveys {
    return new DashboardSelectedSurveys(
      params.stats ?? this._stats,
      params.selected ?? this._selected
    );
  }
}
