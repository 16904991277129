import { Component, Input } from '@angular/core';
import { BillingNumberTooltip } from '@purespectrum1/ui/marketplace';
import { BillingNumberRules } from '@purespectrum1/ui/marketplace/shared/interfaces/billing-rules.interfaces';

@Component({
  selector: 'ps-billing-number-tooltip',
  templateUrl: './billing-number-tooltip.component.html',
  styleUrls: ['./billing-number-tooltip.component.css'],
})
export class BillingNumberTooltipComponent {
  public tooltip?: BillingNumberTooltip;
  public specialChars = '“{ } [ ] ( ) /  ~ - , ; : . < > _ * + & # @“';

  @Input()
  set rules(billingRules: BillingNumberRules | null) {
    if (billingRules) {
      this.tooltip = new BillingNumberTooltip(billingRules);
    }
  }
}
