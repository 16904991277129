import { Component, Inject } from '@angular/core';
import { ModalData, ModalRef } from '@purespectrum1/ui/modal';

import { UserResponseObject } from '../../user-service/user.interface';

@Component({
  selector: 'ps-change-user-status-modal',
  templateUrl: './change-user-status-modal.component.html',
  styleUrls: ['./change-user-status-modal.component.css'],
})
export class ChangeUserStatusModalComponent {
  user: UserResponseObject = this._modalData;

  constructor(
    @Inject(ModalData) private _modalData: UserResponseObject,
    private _modalRef: ModalRef<ChangeUserStatusModalComponent, string>
  ) {}

  close(msg?: string) {
    this._modalRef.close(msg);
  }
}
