<div class="container-fluid text-center">
  <div>
    <div class="form-group">
      <h3>Import Data Quality Questions</h3>
    </div>
    <div class="form-group">
      <input
        type="file"
        [hidden]="true"
        class="file-input"
        accept=".csv, .xls, .xlsx"
        (change)="onFileChange($event)"
        #fileUpload
      />
      <div class="file-upload">
        <button class="btn btn-grey" (click)="fileUpload.click()">
          {{ fileName || '.csv, .xls, .xlsx' }}
        </button>
      </div>
    </div>
    <div class="form-group">
      <div class="download-csv">
        <a (click)="downloadFile()"> Download Template </a>
        <span class="numberCircle">?</span>
      </div>
    </div>
    <div class="form-group">
      <button
        psUiButton
        (click)="uploadDataQualityQuestions(false)"
        [disabled]="isDisableUpload"
      >
        Upload File
      </button>
      <button psUiButton [routerLink]="['/questions-library/']">
        Back To All Questions
      </button>
    </div>
  </div>
</div>
