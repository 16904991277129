<div class="supplier-tabs" [formGroup]="form">
  <div class="form-group">
    <label class="control-label col-md-4">Supplier Url For Completes:</label>
    <div class="col-md-6">
      <input
        name="supp_url_completes"
        type="text"
        placeholder="Supplier Url For Completes"
        class="form-control input-md"
        formControlName="supp_url_completes"
      />
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-4">Supplier Url For Terms:</label>
    <div class="col-md-6">
      <input
        name="supp_url_terms"
        type="text"
        placeholder="Supplier Url For Terms"
        class="form-control input-md"
        formControlName="supp_url_terms"
      />
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-4">Supplier Url For OverQuotas:</label>
    <div class="col-md-6">
      <input
        name="supp_url_overquota"
        type="text"
        placeholder="Supplier Url For OverQuotas"
        class="form-control input-md"
        formControlName="supp_url_overquota"
      />
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-4"
      >Supplier Url For Security Termination:</label
    >
    <div class="col-md-6">
      <input
        name="supp_url_security_terms"
        type="text"
        placeholder="Supplier Url For Security Termination"
        class="form-control input-md"
        formControlName="supp_url_security_terms"
      />
    </div>
  </div>
</div>
