<div class="form-section">
  <form
    class="form-forgot-div"
    (ngSubmit)="forgotPassword()"
    [formGroup]="form"
  >
    <a href="#" class="box-logo">
      <img
        class="img-responsive center-block forgot-password-logo"
        src="../../../assets/img/logo-login-ps.png"
        alt="Logo"
        title=""
      />
    </a>
    <h2 class="text-center forgot-password-text mt-5">
      Marketplace - Forgot Password?
    </h2>
    <div class="mb-3">
      <input
        type="email"
        class="form-control input-tag"
        id="ForgotEmail"
        placeholder="Email"
        formControlName="email"
      />
    </div>
    <div class="mb-3 captcha">
      <re-captcha
        [siteKey]="captchaSiteKey"
        (resolved)="resolved($event)"
        formControlName="captcha"
      ></re-captcha>
    </div>
    <div class="d-grid">
      <button
        class="btn btn-info btn-lg forgot-password"
        psUiButton
        [outline]="true"
        [disabled]="form.invalid"
      >
        Send Reset Email
      </button>
    </div>
    <div class="d-grid">
      <p class="text-center return-to-p">
        Return to
        <a href="/login" class="login-text">Login</a>
      </p>
    </div>
    <div class="ps-footer">
      <a href="tel:(877) 865-PURE">(877) 865-PURE</a>
      <p>Copyright © 2022 - PureSpectrum - All rights reserved</p>
    </div>
  </form>
</div>
