import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  GetStrReportResponse,
  PostStrReportResponse,
  DownLoginReportInterface,
} from '../report-response.interface';
@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  private readonly _psReportsUrl = environment.pureSpectrum.reportServiceUrl;

  constructor(private _http: HttpClient) {}

  getStrReports(id: number) {
    return this._http.get<GetStrReportResponse>(
      `${this._baseUrl}/reports?userId=${id}`
    );
  }
  getPsReports(id: number) {
    return this._http.get<GetStrReportResponse>(
      `${this._psReportsUrl}/v1/ps-reports-service/reports?userId=${id}`
    );
  }
  saveReportData(payload: any) {
    return this._http.post<PostStrReportResponse>(
      `${this._baseUrl}/reports`,
      payload
    );
  }
  downloadLoginReport() {
    return this._http.get<DownLoginReportInterface>(
      `${this._baseUrl}/reports/userLoginReport`
    );
  }
  downloadFile(fileName: string, token?: string) {
    return `${this._baseUrl}/reports/download-report/${fileName}?access_token=${token}`;
  }

  downloadStatementReport(
    cmp: number,
    month: string,
    year: number,
    type: string
  ) {
    return `${this._baseUrl}/reports/suppliers-buyerside/${cmp}?month=${month}&year=${year}&type=${type}`;
  }

  requestMultiSurveysStatementReport(email: string, surveys: Array<number>) {
    return this._http.post<{ status: string; message: string }>(
      `${this._psReportsUrl}/multi-surveys`,
      { email, surveys }
    );
  }
}
