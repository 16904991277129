import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';

import { EpcReportService } from './epc-report.service';
import { CompanyService } from '../../../shared/services/company/company.service';

import { EachReport, SelectDate } from './epc-report.interface';
import { notifyMessage } from '../../../constants/notify-message';
import { CompanyResponseObject } from '../../../shared/interfaces/company.interface';

@Component({
  selector: 'ps-epc-report',
  templateUrl: './epc-report.component.html',
  styleUrls: ['./epc-report.component.css'],
})
export class EpcReportComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public companies: CompanyResponseObject[] = [];
  public selectedCompany!: CompanyResponseObject;
  public epcReportByBuyerArr: EachReport[] = [];

  constructor(
    private _toastr: ToasterService,
    private _datepipe: DatePipe,
    private _epcReportService: EpcReportService,
    private _companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this._getCompanies();
  }

  private _getCompanies() {
    const payload = {
      isSpplr: false,
      isBuyr: true,
    };
    const companiesResponse$ = this._companyService
      .getAllBuyers(payload)
      .subscribe(
        (resp) => {
          this.companies = resp.companies;
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add(companiesResponse$);
  }

  public dateRange = {
    start: { _d: Date.now() },
    end: { _d: Date.now() },
    label: '',
  };

  public options = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };

  public selectedDate(value: SelectDate) {
    this.dateRange.start = value.start;
    this.dateRange.end = value.end;
  }

  public selectCompany(company: CompanyResponseObject) {
    this.selectedCompany = company;
  }

  public getEpcReport() {
    const isValid = this._validateFields();
    if (isValid) {
      const payloadObj = {
        b_id: this.selectedCompany.id,
        startDate: this._datepipe.transform(
          this.dateRange.start._d,
          'yyyy-MM-dd'
        ),
        endDate: this._datepipe.transform(this.dateRange.end._d, 'yyyy-MM-dd'),
      };
      this._epcReportService.getEpcReport(payloadObj).subscribe(
        (response) => {
          if (response.result.length > 0) {
            this.epcReportByBuyerArr = response.result;
          } else {
            this._toastr.error(
              notifyMessage.errorMessage.EPC_REPORT.NO_DATA_ERROR
            );
          }
        },
        (error) => {
          this._toastr.error(JSON.stringify(error.error.msg));
        }
      );
    } else {
      this._toastr.error(
        notifyMessage.warningMessage.EPC_REPORT.DATERANGE_COMPANY_REQUIRED
      );
    }
  }

  private _validateFields() {
    return (
      this.dateRange &&
      this.dateRange.start &&
      this.dateRange.end &&
      this.selectedCompany &&
      this.selectedCompany.id
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
