<ps-ui-paginated-list
  [length]="total"
  [limit]="10"
  (pageChange)="getQuestions($event)"
>
  <ps-ui-paginated-list-item empty>
    <p description>No Questions Available</p>
  </ps-ui-paginated-list-item>

  <div *ngFor="let question of questions">
    <ps-ui-paginated-list-item
      [active]="questionId === question.qualification_id[0]"
      (tap)="setQualificationId(question.qualification_id[0])"
    >
      <p title>
        {{ question.qualification_id[0] }} |
        {{ question.question_description }}
      </p>
      <br />
      <p description>
        <span *ngIf="question.locale?.length">
          Localizations:
          <span *ngFor="let locale of question.locale"
            >{{ locale | localeShortCode : '-' : 'UK' : 'GB' }},</span
          ><br />
        </span>
        <span *ngIf="question.question_category?.length">
          Question Category:
          <span *ngFor="let category of question.question_category"
            >{{ category.label }},</span
          ><br />
        </span>
        <span *ngIf="question.question_tags?.length">
          Question Tags:
          <span *ngFor="let tags of question.question_tags">{{ tags }},</span>
        </span>
      </p>
    </ps-ui-paginated-list-item>
  </div>
</ps-ui-paginated-list>
