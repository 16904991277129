import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { IndividualConfig } from 'ngx-toastr';

import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { notifyMessage } from '../../constants/notify-message';
import { environment } from '../../../environments/environment';
import { retryServerError } from '../../utils/retry-server-error';

import { AuthService } from '../auth.service';

@Component({
  selector: 'ps-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  showCaptcha: boolean = false;
  public readonly captchaSiteKey = environment.CATPTCHA_SITE_KEY;

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _router: Router,
    private _toastr: ToasterService
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, Validators.required],
      rememberme: true,
      captcha: [null],
    });
  }

  login() {
    this._auth
      .login(this.form.value)
      .pipe(retryServerError(3))
      .subscribe(
        (res) => {
          this._router.navigateByUrl('/dashboard');
        },
        (error) => {
          this._errorHandler(error);
        }
      );
  }

  resolved(captchaResponse: string) {
    this.form.patchValue({ captcha: captchaResponse });
  }

  private _errorHandler(error: any) {
    if (error.statusText === 'Unknown Error') {
      this._toastr.error(
        notifyMessage.errorMessage.UNKNOWN_ERROR_5XX,
        'Error:',
        {
          timeOut: 0,
        } as IndividualConfig
      );
      return;
    }
    const errorMessage = error.error?.msg;
    if (errorMessage === 'Invalid credentials') {
      this._captchaHandler(error.error);
      this._toastr.error(
        notifyMessage.errorMessage.INVALID_CREDENTIALS,
        'Error:',
        {
          timeOut: 0,
        } as IndividualConfig
      );
    } else {
      this._toastr.error(errorMessage);
    }
  }

  private _captchaHandler(error: { isCaptchaRequired: boolean }) {
    if (error.isCaptchaRequired) {
      this.showCaptcha = true;
      this._setCaptchaAsRequired();
    }
  }

  private _setCaptchaAsRequired() {
    this.form.get('captcha')!.addValidators(Validators.required);
  }
}
