import { Component, Input } from '@angular/core';
import {
  ManualFix,
  Reconcilation,
} from '../../../../buyer/adjustments-reconciliation/adjustments-reconciliation-service/adjustments-reconciliation-service.interface';
import { ModalService } from '@purespectrum1/ui/modal';
import { AdjustmentsReconciliationServiceService } from '../../../../buyer/adjustments-reconciliation/adjustments-reconciliation-service/adjustments-reconciliation-service.service';
import { AdjustmentModalComponent } from '../adjustment-modal/adjustment-modal.component';

@Component({
  selector: 'ps-adjustment-table',
  templateUrl: './adjustment-table.component.html',
  styleUrls: ['./adjustment-table.component.css'],
})
export class AdjustmentTableComponent {
  @Input() adjustmentReconciliatonRecord!: Array<Reconcilation>;
  @Input() adjustmentManualFixRecord!: Array<ManualFix>;
  @Input() userId!: number;
  @Input() userName: string = '';
  @Input() operationType: string = '';
  @Input() showDownloadReportButtoonInRecon: boolean = false;
  @Input() showDownloadTemplateText: boolean = false;
  @Input() showBackToDashboardButton: boolean = false;
  isDisableUpload: boolean = true;
  fileName: string = '';
  file!: File;
  adjustmentReportStatus: boolean = true;
  constructor(
    private _modal: ModalService,
    private _adjustmentReconciliationService: AdjustmentsReconciliationServiceService
  ) {}
  downloadAdjustmentReport(adjustmentId: string) {
    this.adjustmentReportStatus = false;
    const url_link =
      this._adjustmentReconciliationService.getAdjustmentsReport(adjustmentId);
    window.open(url_link, '_blank');
    this.adjustmentReportStatus = true;
  }

  openStatusModal(
    summary: object,
    id: Array<number>,
    type: string,
    status: boolean,
    adjustmentId: string
  ) {
    const modalRef = this._modal.open(AdjustmentModalComponent, {
      data: { summary, id, type, status, adjustmentId },
    });
  }
}
