import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { UploadMappingsResponse } from './ipsos-mappings.interface';

@Injectable({
  providedIn: 'root',
})
export class IPSOSMappingsService {
  private readonly _ipsosUrl = environment.pureSpectrum.ipsosUrl;

  constructor(private _http: HttpClient) {}

  uploadMappings(formData: FormData): Observable<UploadMappingsResponse> {
    return this._http.post<UploadMappingsResponse>(
      `${this._ipsosUrl}/api/v1/mappings`,
      formData,
      {
        headers: {
          skipInterceptor: 'true',
        },
      }
    );
  }

  downloadMappings(): Observable<Blob> {
    return this._http.get<Blob>(`${this._ipsosUrl}/api/v1/mappings`, {
      responseType: 'blob' as 'json',
    });
  }
}
