import { Component, Inject, OnInit } from '@angular/core';
import { ModalData, ModalRef } from '@purespectrum1/ui/modal';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { notifyMessage } from '../../../../constants/notify-message';
import {
  ReconcilationModal,
  ManualFixModal,
} from '../../../../buyer/adjustments-reconciliation/adjustments-reconciliation-service/adjustments-reconciliation-service.interface';
import { AdjustmentsReconciliationServiceService } from '../../../../buyer/adjustments-reconciliation/adjustments-reconciliation-service/adjustments-reconciliation-service.service';
@Component({
  selector: 'ps-adjustment-modal',
  templateUrl: './adjustment-modal.component.html',
  styleUrls: ['./adjustment-modal.component.css'],
})
export class AdjustmentModalComponent implements OnInit {
  reconSummaryData?: ReconcilationModal;
  mfSummaryData?: ManualFixModal;
  public recoModalSurveyList!: string;
  public mfModalSurveyList!: string;
  constructor(
    @Inject(ModalData) private _modalData: any,
    private _modalRef: ModalRef<AdjustmentModalComponent, string>,
    private _adjustmentReconciliationService: AdjustmentsReconciliationServiceService,
    private _toastr: ToasterService
  ) {}

  close(msg?: string) {
    this._modalRef.close(msg);
  }
  ngOnInit() {
    this._setUpModal();
  }
  confirmAdjustment(adjustmentId: string) {
    this._adjustmentReconciliationService
      .confirmAdjustment(adjustmentId)
      .subscribe((response) => {
        this._modalRef.close();
        this._toastr.success('File uploaded successfully. Preparing a summary');
      });
  }
  declineAdjustment(adjustmentId: string) {
    this._adjustmentReconciliationService
      .declinedAdjustment(adjustmentId)
      .subscribe((response) => {
        this._modalRef.close();
        this._toastr.success('Adjustment declined successfully.');
      });
  }

  private _setUpModal(): void {
    if (this._modalData.type === 'MF') {
      this.mfModalSurveyList = this._modalData.id
        ?.toString()
        .replace(/,/g, ', ');
      this.mfSummaryData = this._modalData;
    } else if (this._modalData.type === 'RECON') {
      this.recoModalSurveyList = this._modalData.id
        ?.toString()
        .replace(/,/g, ', ');
      this.reconSummaryData = this._modalData;
    } else {
      this._toastr.error(notifyMessage.errorMessage.OPERATION_UNAVAILABLE);
      this.close();
    }
  }
}
