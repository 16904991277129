<div class="row">
  <div class="col-sm-6 col-md-4 form-group">
    <label class="p-1">Country</label>
    <ps-ui-select-dropdown
      [dropdownItems]="countries"
      [field]="'name'"
      labelNone="Select Country"
      [label]="selectedCountry?.name"
      (selectedItem)="selectCountry($event)"
    >
    </ps-ui-select-dropdown>
  </div>
  <div class="flex-column" *ngIf="selectedCountry">
    <div class="d-flex justify-content-center">
      <input
        #fileUpload
        hidden
        type="file"
        class="file-upload"
        accept=".xlsx,.xls,.csv"
        (change)="uploadTrafficDensityFile($event)"
      />
      <button
        type="button"
        class="btn btn-link upload-button"
        (click)="fileUpload.value = ''; fileUpload.click()"
      >
        Upload Traffic Density File for {{ selectedCountry.name }}
      </button>
    </div>
    <div class="d-flex justify-content-center">.csv, .xlsx, .xlx</div>
    <div class="d-flex justify-content-center p-3">
      <a
        type="button"
        class="btn btn-link download-button"
        (click)="downloadTrafficDensityFileTemplate()"
      >
        Download Template
      </a>
    </div>
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-link download-button"
        (click)="downloadCurrentTrafficDensityFile()"
      >
        Download Current File
      </button>
    </div>
  </div>
</div>
