import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService, SelectDropdownComponent } from '@purespectrum1/ui';
import { SearchQualificationsModelComponent } from './search-qualifications-model/search-qualifications-model.component';
import {
  AnswerOptionUI,
  DataQuality,
  QualityRule,
  Range,
} from '../../../questions-library-services/questions-library-services.interface';
import { Constants } from '../../../questions-library.constants';
import { AuthService } from '../../../../auth/auth.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';

@Component({
  selector: 'ps-data-quality',
  templateUrl: './data-quality.component.html',
  styleUrls: ['./data-quality.component.css'],
})
export class DataQualityComponent implements OnInit, OnDestroy {
  @ViewChild(SelectDropdownComponent)
  private _answerDropdown?: SelectDropdownComponent<AnswerOptionUI>;
  @Input() locale!: string;
  @Input() questionType: number = 1;
  @Input() class?: number;
  @Input() qualificationId!: string;
  @Input() dataQualityAdded!: any;
  @Input() isEditLocalizationMode: boolean = false;
  @Input() set answers(value: AnswerOptionUI[]) {
    const filteredAnswers = value.filter(
      (answer: AnswerOptionUI) => answer.id && answer.text
    );
    const removedAnswer = filteredAnswers.length < this._answers.length;
    this._answers = filteredAnswers;
    if (removedAnswer) {
      this._answerDropdown?.selectNone();
      this.qualityRules = this.qualityRules.filter(
        this.getIsQRDataQualityInAnswers.bind(this)
      );
    }
    this.isAnswerAvailable = this._answers.length > 0;
    this.multiLabelText = this.isAnswerAvailable
      ? this.CONSTANTS.DATA_QUALITY.AVAILABLE_ANSWER_SELECT.text
      : this.CONSTANTS.DATA_QUALITY.EMPTY_ANSWER_SELECT.text;
  }

  @Output() dataQuality = new EventEmitter();
  private _subscriptions = new Subscription();
  private _answers: AnswerOptionUI[] = [];
  public qualityRules: DataQuality[] = [];
  public qualityRule: DataQuality = {};
  public condition: string = 'or';
  public range: Range = { min: '', max: '' };
  public isOperator: boolean = false;
  public isAnswerAvailable: boolean = false;
  public multiLabelText: string =
    Constants.DATA_QUALITY.EMPTY_ANSWER_SELECT.text;
  readonly CONSTANTS = Constants;
  get answers(): AnswerOptionUI[] {
    return this._answers;
  }

  constructor(
    private _modal: ModalService,
    private _auth: AuthService,
    private _toastr: ToasterService
  ) {}

  ngOnInit(): void {
    this.isOperator = this._auth.user?.operatorAcssLvls !== 'none';
    if (this.dataQualityAdded?.length) {
      this.qualityRules = this.dataQualityAdded;
    }
  }

  setSelectedItems(options: AnswerOptionUI[]) {
    const qualityRule = this.setQualityRule(
      this.qualificationId,
      options,
      this.questionType,
      this.condition,
      this.range
    );
    this.qualityRule = { ...this.qualityRule, ...qualityRule };
  }

  setQualityRule(
    qualId: string | number,
    options: AnswerOptionUI[],
    type: number,
    condition: string,
    range: Range
  ) {
    const qualityRule: DataQuality = {};
    switch (type) {
      case Constants.QUESTION_TYPE.SINGLEPUNCH:
        qualityRule[qualId] = this._setQualityRuleForSinglepunch(options);
        break;
      case Constants.QUESTION_TYPE.MULTIPUNCH:
        qualityRule[qualId] = this._setQualityRuleForMultipunch(
          options,
          condition
        );
        break;
      default:
        qualityRule[qualId] = [range];
        break;
    }
    return qualityRule;
  }

  private _setQualityRuleForSinglepunch(options: AnswerOptionUI[]) {
    return options.map((option) => {
      return {
        code: [option.id],
      };
    });
  }

  private _setQualityRuleForMultipunch(
    options: AnswerOptionUI[],
    condition: string
  ) {
    if (condition === 'or') {
      // works same as singlepunch
      return this._setQualityRuleForSinglepunch(options);
    }
    return [
      {
        code: options.map((option) => {
          return option.id;
        }),
      },
    ];
  }

  /**
   * Function to convert quality rules in text format
   */
  generateLowQualityResponseText(qualityRules: DataQuality | any) {
    const qualAndArr = [];
    for (let qualId in qualityRules) {
      const condOrArr = [];
      for (let rule of qualityRules[qualId]) {
        // Range question
        if (Number.isInteger(rule?.min) || Number.isInteger(rule?.max)) {
          condOrArr.push(`${rule.min} - ${rule.max}`);
        }
        // singlepunch and multipunch
        else if (rule.code?.length) {
          // condition code inside code array will join with `and` condition
          const ruleText =
            rule.code.length === 1
              ? `${rule.code.join(' and ')}`
              : `(${rule.code.join(' and ')})`;
          condOrArr.push(ruleText);
        }
      }
      if (condOrArr.length) {
        // All qualification inside quality_rule object will seperated with `or` condition
        qualAndArr.push(`[%%${qualId}%% (${condOrArr.join(' or ')})]`);
      }
    }
    // All rules inside quality_rules array are seperated with `and` codition
    return `${qualAndArr.join(' and ')}`;
  }

  isQualityRuleInput() {
    if (this.isAnswerAvailable && Object.keys(this.qualityRule).length > 0) {
      for (const rule in this.qualityRule) {
        if ((this.qualityRule[rule]?.length as number) > 0) {
          return true;
        }
      }
    }
    return false;
  }

  addQualityRules() {
    if (this.isQualityRuleInput()) {
      this.qualityRules.push({ ...this.qualityRule });
      this.qualityRule = { ...this.qualityRule };
      this.range = { min: '', max: '' };
      // Emit data quality rule in localization component
      this.dataQuality.emit(this.qualityRules);
    } else {
      this._toastr.warning(
        this.CONSTANTS.DATA_QUALITY.EMPTY_ANSWER_WARNING.text
      );
    }
  }

  removeQualityRules(index: number) {
    this.qualityRules.splice(index, 1);
  }

  private _checkRangeInAnswers(range: Range) {
    const mindefined = range.min !== null || range.min !== undefined;
    const maxdefined = range.max !== null || range.max !== undefined;
    return this._answers.every((ans: AnswerOptionUI) => {
      //Assure answer in range interval(min <= answer <= max) for all;
      return (
        (mindefined || maxdefined) &&
        ((mindefined && ans.id >= (range.min as number)) || !mindefined) &&
        ((maxdefined && ans.id <= (range.max as number)) || !maxdefined)
      );
    });
  }

  private _checkQualityRuleInAnswers(qrule: QualityRule) {
    return qrule.code.every(
      ((cod: number) =>
        this._answers.some((ans: AnswerOptionUI) => ans.id == cod)).bind(this)
    );
  }

  getIsQualityNRangeInAnswers(dqr: QualityRule | Range) {
    /**
     * Determine whether (QualityRule or Range) is present in answer list.
     * @returns boolean whether reference is present;
     */
    const dqrange = dqr as Range;
    const dqrule = dqr as QualityRule;
    const isRange =
      Number.isInteger(dqrange?.min) || Number.isInteger(dqrange?.max);
    if (isRange) {
      return this._checkRangeInAnswers(dqrange);
    } else if (dqrule.code?.length) {
      return this._checkQualityRuleInAnswers(dqrule);
    } else {
      console.warn("DataQuality property provided can't be read");
    }
    return true;
  }

  getIsQRDataQualityInAnswers(qualrule: DataQuality) {
    for (const rule in qualrule) {
      const inAnswers = (qualrule[rule] as Array<QualityRule | Range>)?.every(
        this.getIsQualityNRangeInAnswers.bind(this)
      );
      if (inAnswers) {
        return true;
      }
    }
    return false;
  }

  openSearchQualificationModel() {
    // Open search qualification model
    const modalRef = this._modal.open(SearchQualificationsModelComponent, {
      data: {
        locale: this.locale,
        setQualityRule: this.setQualityRule.bind(this),
      },
    });
    modalRef.onClose$.subscribe(
      (data: { msg: string; qualityRule: DataQuality }) => {
        if (data?.msg === 'save') {
          this.qualityRule = { ...this.qualityRule, ...data.qualityRule };
        }
      }
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
