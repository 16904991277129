import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { QuestionsLibraryService } from '../../../questions-library-services/questions-library-services.service';
import { Constants } from '../../../questions-library.constants';
import {
  Options,
  Answer,
  ParentQuestion,
  Dependency,
  Localization,
} from '../../../questions-library-services/questions-library-services.interface';

@Component({
  selector: 'ps-question-with-dependency',
  templateUrl: './dependency.component.html',
  styleUrls: ['./dependency.component.css'],
})
export class DependencyComponent implements OnInit {
  @Input() isEditLocalizationMode: boolean = false;
  @Input() class?: number;
  @Input() clonedLocalization?: Localization;

  form!: FormGroup;
  questions: ParentQuestion[] = [];
  qualificationOptions: Answer[] = [];
  conditions: Options[] = [];
  loading: boolean = false;
  readonly CONSTANTS = Constants;

  constructor(
    fb: FormBuilder,
    rootFormGroup: FormGroupDirective,
    private _questionsLibraryService: QuestionsLibraryService
  ) {
    this.form = this._generateForm(fb);
    rootFormGroup.control.addControl('dependency', this.form);
    this.conditions = Constants.DEPENDENCY_CONDITION;
  }

  ngOnInit(): void {
    if (this.clonedLocalization && this.clonedLocalization.dependency) {
      this.form.patchValue({
        condition: this.clonedLocalization.dependency.match_criteria,
      });
    }
  }

  private _generateForm(fb: FormBuilder): FormGroup {
    return fb.group({
      parentQuestion: ['', Validators.required],
      condition: ['', Validators.required],
      qualificationOptions: ['', Validators.required],
    });
  }

  public onLocaleSelected(locale: string, dependency: Dependency = {}) {
    if (!locale) {
      return;
    }
    this.loading = true;
    this._questionsLibraryService
      .getParentQuestions(locale, 0, 0)
      .subscribe((questions: any) => {
        this.loading = false;
        this.questions = questions;
        if (Object.keys(dependency).length) {
          this._setForm(dependency);
        }
      });
  }

  parentQuestionSelected(parentQuestion: ParentQuestion) {
    this.qualificationOptions = parentQuestion.answers;
  }

  private _setForm(dependency: Dependency) {
    const parentQuestion = this.questions.find(
      (question: ParentQuestion) =>
        question.qualification_id === dependency.parent_qualification_id
    );
    this.qualificationOptions = parentQuestion?.answers || [];
    this.form.patchValue({
      parentQuestion: parentQuestion,
      condition: dependency.match_criteria,
      qualificationOptions: dependency?.condition?.map((id: number | string) =>
        this.qualificationOptions.find((opt: Answer) => opt.id === Number(id))
      ),
    });
  }
}
