'use strict';

export const Constants = Object.freeze({
  FEASIBILITY_ESTIMATE: [
    { name: 'None', genpopMode: 0 },
    { name: 'Genpop Estimate', genpopMode: 1 },
  ],
  FEASIBILITY_UPLOAD: {
    name: 'FEASIBILITY_UPLOAD',
    templatePath: '/assets/templates/feasibility_upload-template.csv',
    key: 'feasibility_upload',
    downloadFileName: 'feasibility.csv',
  },
});
