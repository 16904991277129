import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import { NetsuiteService } from '../../../shared/services/netsuite/netsuite.service';
import { CompanyResponseObject } from '../../../shared/interfaces/company.interface';
import { CompanyCurrencyResponseObject } from '../../../shared/types/currency-service-interface';
import { DEFAULT_NETSUITE_OBJECT } from '../manage-companies-form/constant';
import { notifyMessage } from '../../../constants/notify-message';
import { Netsuite } from '../../../shared/interfaces/netsuite.interface';

@Component({
  selector: 'ps-netsuite-form',
  templateUrl: './netsuite-form.component.html',
  styleUrls: ['./netsuite-form.component.css'],
})
export class NetsuiteFormComponent implements OnInit, OnChanges, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  @Input() selectedCompany!: CompanyResponseObject;
  @Input() currencies!: CompanyCurrencyResponseObject[];
  @Input() isFusion: boolean = false;
  public form!: FormGroup;
  public nsInternalId: string = '';
  public isASupplier: boolean = false;
  public defaultCurrency: CompanyCurrencyResponseObject = {
    currencyShortCode: 'USD',
    currencyFullName: 'United States Dollar',
    fx: 321,
  };
  constructor(
    private _controlContainer: ControlContainer,
    private _toastr: ToasterService,
    private _netSuiteService: NetsuiteService
  ) {}

  ngOnInit(): void {
    this._initializeForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedCompany?.currentValue) {
      this.selectedCompany = changes.selectedCompany.currentValue;
      this._initializeForm();
    }
  }

  private _initializeForm() {
    this.form = <FormGroup>this._controlContainer.control;
    this._subscriptions.add(
      this.form
        .get('netsuite.internalId')
        ?.valueChanges.subscribe((internalId) => {
          this.nsInternalId = internalId;
        })
    );
    if (!(this.form.value.isABuyer && this.form.value.isASupplier)) {
      this.form.patchValue({
        netsuite: { enabled: false },
      });
      this.toggleNSIntegration();
    }
  }

  toggleNSIntegration() {
    this.form.patchValue({
      netsuite: DEFAULT_NETSUITE_OBJECT,
    });
  }

  fetchNetSuiteCompany() {
    if (!this.nsInternalId || isNaN(Number(this.nsInternalId))) {
      this._toastr.error(notifyMessage.errorMessage.NETSUITE.NOT_FOUND);
      return;
    }

    if (!this.isFusion && !this.form.value.fx && !this.isASupplier) {
      this._toastr.error(notifyMessage.errorMessage.NETSUITE.INVALID_CURRENCY);
      return;
    }

    this.isASupplier = !!this.form?.value.isASupplier;
    const userType = this.isASupplier || this.isFusion ? 'supplier' : 'buyer';

    this._subscriptions.add(
      this._netSuiteService
        .fetchNetSuiteCompany(Number(this.nsInternalId), userType)
        .subscribe(
          (response) => {
            if (!response.isVendor && this.isFusion) {
              return this._toastr.error(
                notifyMessage.errorMessage.NETSUITE_NOT_ALLOW_VENDOR_AND_FUSION
              );
            }

            this.hasSelectedCurrency(response);
          },
          (err) => {
            const { error: { msg = '' } = {} } = err;
            if (
              this.isFusion &&
              msg ===
                'The Internal ID entered is not found. Make sure NetSuite Company has all following information: Full Address, Currency'
            ) {
              return this._toastr.error(
                notifyMessage.errorMessage.NETSUITE_NOT_ALLOW_VENDOR_AND_FUSION
              );
            }
            this._toastr.error(msg);
          }
        )
    );
  }

  hasCurrency(response: Netsuite) {
    response.companyName = response.name;
    this.form.patchValue({ netsuite: response });
  }

  hasSelectedCurrency(response: Netsuite) {
    const findCurrency = this.currencies.find(
      (currency) => currency.fx === this.form.value.fx
    );
    const selectedCurrency = findCurrency ? findCurrency : this.defaultCurrency;

    if (selectedCurrency.currencyShortCode !== 'USD' && this.isFusion) {
      this._toastr.error(
        notifyMessage.errorMessage.NETSUITE_FUSION_ALLOW_JUST_USD
      );
      return;
    }

    const { currencies: netSuiteCurrencies = [] } = response;
    const hasCurrency = netSuiteCurrencies.some(
      (currency) =>
        currency.currencyShortCode === selectedCurrency.currencyShortCode
    );

    if (!hasCurrency) {
      this._toastr.error(
        notifyMessage.errorMessage.NETSUITE.INVALID_CURRENCY_MATCH
      );
      return;
    }

    this.hasCurrency(response);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
