import { Pipe, PipeTransform } from '@angular/core';
import { StringTruncator } from '../../utils/string-truncate';

@Pipe({ name: 'truncateWord' })
export class TruncateWordPipe implements PipeTransform {
  transform(value: string, max: number = 20) {
    const truncator = new StringTruncator(value, max);
    return truncator.truncate();
  }
}
