import { Component, OnChanges, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { AuthService } from '../../../auth/auth.service';
import { notifyMessage } from '../../../constants/notify-message';
import { Entitlement } from '../../../shared/interfaces/entitlement.interface';
import { Constants } from '../header.constants';
import { ModalService } from '@purespectrum1/ui/modal';
import { ServiceAccountModalComponent } from './service-account-modal/service-account-modal.component';
import { UserType } from '../../../constants/global';
import { DashboardStateService } from '../../../dashboard/services/dashboard-state.service';

@Component({
  selector: 'ps-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css'],
})
export class UserMenuComponent implements OnChanges {
  public userEmail?: string = this._auth.user?.eml;
  public isServiceOperator: boolean = false;
  @Input() menuItems: Entitlement[] = [];
  readonly Constants = Constants;
  public featureLinks: {
    [key: string]: string;
  } = Constants.USER_ACCESS.LINKS;

  constructor(
    private _auth: AuthService,
    private _toastr: ToasterService,
    private _router: Router,
    private _modal: ModalService,
    private _dashboardStateService: DashboardStateService
  ) {}

  ngOnChanges(): void {
    this._filterMenuItems();
    this._enableServiceOperator();
  }

  private _enableServiceOperator(): void {
    this.isServiceOperator =
      this._auth.user?.operatorAcssLvls === UserType.SERVICE_OPERATOR;
  }

  private _filterMenuItems() {
    this.menuItems = this.menuItems.filter((item: Entitlement) => {
      if (
        this._auth.userType === 'buyer' &&
        item.featureKey === 'questionLibrary'
      ) {
        // In case of question library, need to check if it is enabled for that buyer
        return (
          this._auth.buyerConfig?.enableQuestionLibrary &&
          !Constants.USER_ACCESS.IGNORE_FEATURE_KEYS.includes(item.featureKey)
        );
      }
      return !Constants.USER_ACCESS.IGNORE_FEATURE_KEYS.includes(
        item.featureKey
      );
    });
  }

  logout() {
    this._auth.logout().subscribe(
      () => {
        this._toastr.success(notifyMessage.successMessage.LOGOUT_SUCCESS);
        this._dashboardStateService.clear();
        this._router.navigateByUrl('/login');
      },
      (err) => {
        if (!localStorage.length) {
          this._toastr.success(notifyMessage.successMessage.LOGOUT_SUCCESS);
        } else {
          const errorMessage = err.error.msg;
          this._toastr.error(errorMessage);
        }
      }
    );
  }

  selectServiceAccount() {
    this._modal.open(ServiceAccountModalComponent, {});
  }

  getFeatureLink(featureKey: string) {
    return this.featureLinks[featureKey] || '#';
  }
}
