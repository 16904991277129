<div class="table-absolute">
  <table class="table table-striped table-borderless mt-1">
    <thead>
      <tr>
        <th
          scope="col"
          [psSortTable]="dataList"
          data-order="desc"
          data-name="reportId"
        >
          Report ID
        </th>
        <th
          scope="col"
          [psSortTable]="dataList"
          data-order="desc"
          data-name="filename"
        >
          Report Name
        </th>
        <th
          scope="col"
          [psSortTable]="dataList"
          data-order="desc"
          data-name="type"
        >
          Type
        </th>
        <th
          scope="col"
          [psSortTable]="dataList"
          data-order="desc"
          data-name="requestedTimestamp"
          data-type="date"
        >
          Request Date/Time
        </th>
        <th
          scope="col"
          [psSortTable]="dataList"
          data-order="desc"
          data-name="reportStartDate"
          data-type="date"
        >
          Report Start Date
        </th>
        <th
          scope="col"
          [psSortTable]="dataList"
          data-order="desc"
          data-name="reportEndDate"
          data-type="date"
        >
          Report End Date
        </th>
        <th
          scope="col"
          [psSortTable]="dataList"
          data-order="desc"
          data-name="reportStatus"
        >
          Status
        </th>
        <th scope="col">Download</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of dataList">
        <td>{{ data.reportId }}</td>
        <td>{{ data.filename }}</td>
        <td>{{ data.type }}</td>
        <td>{{ data.requestedTimestamp | date : 'dd-MM-YYYY HH:mm:ss' }}</td>
        <td>{{ data.reportStartDate || '-' }}</td>
        <td>{{ data.reportEndDate || '-' }}</td>
        <td>{{ reportStatus[data.reportStatus] }}</td>
        <td>
          <a
            class="button"
            psUiButton
            type="button"
            color="primary"
            [disabled]="data.reportStatus !== 1"
            [href]="data.download"
            >Download
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
