<label class="d-block"
  >Upload <span *ngIf="operationType === 'RECON'">Reconciliation</span>
  <span *ngIf="operationType === 'MF'">Manual Fix</span> File</label
>
<div class="position-relative upload-file">
  <input
    type="file"
    [hidden]="true"
    class="file-input"
    accept=".csv, .xls, .xlsx"
    (change)="onFileChange($event)"
    #fileUpload
  />
  <input
    id="filePath"
    type="text"
    name="fileInput"
    [value]="fileName"
    placeholder=".csv, .xlsx"
    class="input-md"
    (click)="fileUpload.value = ''; fileUpload.click()"
    readonly
  />
</div>
<br />
<button
  psUiButton
  [disabled]="isDisableUpload"
  class="reconcile-innerbutton text-white button-recon"
  (click)="uploadAdjustmentsFile(operationType)"
>
  Upload
</button>
<br /><br />

<!-- .xlsx file format-->
<a
  target="_blank"
  href="../../../../assets/templates/Price Update Sample File v2.xlsx"
  class="text-decoration-none"
  *ngIf="operationType === 'MF'"
>
  Download Sample
</a>
<a
  style="color: #337ab7"
  target="_blank"
  href="../../../../assets/templates/reconciliation.xlsx"
  class="text-decoration-none"
  *ngIf="operationType === 'RECON'"
>
  {{ showDownloadTemplateText ? 'Download template' : 'Download Sample' }}
</a>
<br /><br />

<a
  (click)="navigateToDashboard()"
  psUiButton
  class="btn btn-primary submit-btn"
  *ngIf="showBackToDashboardButton"
  >Back to Dashboard</a
>
