import { Component, OnDestroy } from '@angular/core';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import { notifyMessage } from '../../constants/notify-message';
import { Constants } from '../operator-settings-constants';
import {
  UploadMappingsResponse,
  DownloadSamplifyMappingResponse,
} from './samplify-mappings.interface';
import { SamplifyMappingsService } from './samplify-mappings.service';

@Component({
  selector: 'ps-samplify-mappings',
  templateUrl: './samplify-mappings.component.html',
  styleUrls: ['./samplify-mappings.component.css'],
})
export class SamplifyMappingsComponent implements OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  private _samplifyMappingsFilePath: string = '';

  constructor(
    private _toastr: ToasterService,
    private _samplifyMappingsService: SamplifyMappingsService
  ) {}

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  uploadMappings(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isValidFile = this._validateFileExtention(file);
    if (isValidFile) {
      const fdata = new FormData();
      fdata.append('mappings', file);
      const mappings$ = this._samplifyMappingsService
        .uploadMappings(fdata)
        .subscribe(
          (response: UploadMappingsResponse) => {
            this._toastr.success(response.msg);
            this._samplifyMappingsFilePath = response.samplifyMappingsFilePath;
          },
          (error) => {
            this._toastr.error(error.error.msg);
          }
        );
      this._subscriptions.add(mappings$);
    }
  }

  private _validateFileExtention(file: File) {
    const validExt = new Array('.xlsx', '.xls', '.csv');
    if (!file) {
      this._toastr.error(notifyMessage.errorMessage.FILE_UPLOAD_MISSING);
      return false;
    }
    const fileExt = file.name.substring(file.name.lastIndexOf('.'));
    if (validExt.indexOf(fileExt) < 0) {
      this._toastr.error(
        notifyMessage.errorMessage.FILE_UPLOAD_ASK_EXTERNAL_BUYER
      );
      return false;
    }
    return true;
  }

  public downloadSamplifyCSVFile() {
    const downloadSampleFile$ = this._samplifyMappingsService
      .downloadSamplifyMappings()
      .subscribe(
        (response: DownloadSamplifyMappingResponse) => {
          if (response && response.apiStatus) {
            let rows = response.csvRows;
            let csvRows = [];
            let head = [
              [
                'SurveyLocalization',
                'SamplifyQuestionId',
                'SamplifyQuestionName',
                'SamplifyOptionIdText',
                'psConditionCode',
                'SamplifyOptionId',
                'SamplifyOptionIdLocalizedText',
                'psQualificationCode',
                'SamplifyQuestionText',
                'SamplifyQuestionLocalizedText',
                'questionType',
                'CountryLanguage',
              ],
            ];

            for (let cell = 0; cell < head.length; cell++) {
              csvRows.push(head[cell].join(','));
            }
            for (let i = 0; i < rows.length; i++) {
              csvRows.push(rows[i]);
            }

            let csvString = csvRows.join('\n');
            let csvFile = new Blob([csvString], { type: 'text/csv' });
            let downloadLink = document.createElement('a');
            downloadLink.download = Constants.SAMPLIFY.downloadFileName;
            downloadLink.href = window.URL.createObjectURL(csvFile);
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            downloadLink.click();
          } else {
            this._toastr.error(notifyMessage.errorMessage.FILE_DOWNLOAD_FAILED);
          }
        },
        (error) => {
          this._toastr.error(error.msg);
        }
      );
    this._subscriptions.add(downloadSampleFile$);
  }

  public downloadSampleSamplifyMappings() {
    window.open(Constants.SAMPLIFY.templatePath, '_blank');
  }

  public clearSamplifyMappings() {
    this._samplifyMappingsFilePath = '';
    this._toastr.success('Success! Mappings have been cleared');
  }
}
