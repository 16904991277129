export const FLEXIBILITY_OPTIONS = {
  GLOBAL: {
    key: 0,
    value: 'Global',
  },
  NONE: {
    key: 2,
    value: 'None',
  },
  SPECIFIC: {
    key: 1,
    value: 'Selective',
  },
};
