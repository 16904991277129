import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../dashboard-constants';
import { SURVEY_STATUS } from '../../constants/survey-status';
import { SurveyListing } from '../../shared/services/buyer-api/survey.interface';

@Pipe({ name: 'surveyRouterLink' })
export class SurveyRouterLinkPipe implements PipeTransform {
  transform(survey: SurveyListing) {
    return survey.status === SURVEY_STATUS.DRAFT
      ? survey.samplesType === Constants.RECONTACT_SURVEY_SAMPLE_TYPE
        ? ['/recontact-survey', survey.surveyId]
        : ['/launch-survey', survey.surveyId]
      : ['/dashboard', survey.surveyId];
  }
}
