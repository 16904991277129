import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { CompanyInfo } from '../../shared/interfaces/company.interface';
import {
  map,
  debounceTime,
  catchError,
  distinctUntilChanged,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { Pagination } from '../../shared/types/pagination';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { CompanyService } from '../../shared/services/company/company.service';

@Component({
  selector: 'ps-manage-companies',
  templateUrl: './manage-companies.component.html',
  styleUrls: ['./manage-companies.component.css'],
})
export class ManageCompaniesComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  public total = 0;
  public pagination = Pagination.first();
  public companies$!: Observable<CompanyInfo[]>;
  public selected?: CompanyInfo;

  private _fetch = new Subject<Pagination>();

  constructor(
    private _companyService: CompanyService,
    private _toastr: ToasterService,
    private _fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group({
      search: '',
    });

    const fetch$ = this._fetch.pipe(startWith(this.pagination));
    const search$ = this.form.valueChanges.pipe(
      debounceTime(1000),
      map((values) => {
        this.pagination = Pagination.first();
        return values.search as string;
      }),
      distinctUntilChanged(),
      startWith('')
    );

    this.companies$ = combineLatest([fetch$, search$]).pipe(
      switchMap(([_, search]) =>
        this._companyService.getCompaniesPage(this.pagination, search).pipe(
          catchError((error) => {
            this._toastr.error(error.error.msg);
            return of({ count: 0, companies: [] });
          })
        )
      ),
      map((response) => {
        this.total = response.count || 0;
        return response.companies;
      })
    );

    this.getCompanies();
  }

  public getCompanies() {
    this._fetch.next(this.pagination);
  }

  onAddCompany() {
    this.selected = { id: 0, name: '' };
  }

  onCompanyDeleted() {
    this.onAddCompany();
    this.getCompanies();
  }

  onPageChange(page: number) {
    this.pagination = Pagination.to(page);
    this.getCompanies();
  }

  ngOnDestroy() {
    this._fetch.complete();
  }
}
