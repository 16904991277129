import { ModalRef } from '@purespectrum1/ui/modal';
import { Component } from '@angular/core';

import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { AuthService } from '../../auth/auth.service';
import { ChurnZeroConsts, ChurnZeroService } from '@purespectrum1/ui';

@Component({
  selector: 'ps-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.css'],
})
export class TosComponent {
  public optIn: boolean = true;
  public scrolled: boolean = false;

  constructor(
    private _auth: AuthService,
    private _toastr: ToasterService,
    private _modalRef: ModalRef<TosComponent, string>,
    private _churnZeroService: ChurnZeroService
  ) {}

  onStatusAccept() {
    const userId: number = this._auth?.user?.id || 0;
    this._auth.updateAcceptedTOS(userId, this.optIn).subscribe(
      (res) => {
        this._modalRef.close();
        this._churnZeroService.sendEvent(
          ChurnZeroConsts.events.acceptTos,
          ChurnZeroConsts.eventDescriptions.acceptTos
        );
        this._churnZeroService.updateAttribute(
          ChurnZeroConsts.contact,
          ChurnZeroConsts.fieldName.hasAcceptedOptInCommunication,
          this.optIn + ''
        );
      },
      (err) => {
        const errorMessage = err.error.msg;
        this._toastr.error(errorMessage);
      }
    );
  }
}
