<div class="container">
  <div class="row">
    <div class="col-xs-12 col-sm-11 col-md-11 survey">
      <table class="supplier-settings-table" *ngIf="suppliers.length > 0">
        <thead>
          <tr>
            <th>Name</th>
            <th class="text-center">Supplier Type</th>
            <th class="text-center">Dynamic Profiler</th>
            <th class="text-center">Privacy Policy</th>
            <th class="text-center">Data Quality Questions</th>
            <th class="text-center">Routing</th>
            <th class="text-center">Skip Screener</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let supplier of suppliers">
            <td>
              <div>{{ supplier.name }}</div>
            </td>
            <td>
              <ps-ui-capsule-radio-button
                class="capsule-button-row"
                [ngClass]="{ 'disable-radio-btn': supplier.isFusion }"
                [value]="supplier.supplierType"
                [id]="'supplierType' + supplier.id"
                [onLabel]="'Private'"
                [offLabel]="'Public'"
                (valueChange)="
                  changeSupplierSetting(supplier.id, $event, 'supplierType')
                "
              >
              </ps-ui-capsule-radio-button>
            </td>
            <td>
              <ps-ui-capsule-radio-button
                class="capsule-button-row"
                [ngClass]="{ 'disable-radio-btn': supplier.isFusion }"
                [value]="supplier.dynamicProfiler"
                [id]="'dynamicProfiler' + supplier.id"
                (valueChange)="
                  changeSupplierSetting(supplier.id, $event, 'dynamicProfiler')
                "
              >
              </ps-ui-capsule-radio-button>
            </td>
            <td>
              <ps-ui-capsule-radio-button
                class="capsule-button-row"
                [ngClass]="{ 'disable-radio-btn': supplier.isFusion }"
                [value]="supplier.isPrivacyPolicyActive"
                [id]="'isPrivacyPolicyActive' + supplier.id"
                (valueChange)="
                  changeSupplierSetting(
                    supplier.id,
                    $event,
                    'isPrivacyPolicyActive'
                  )
                "
              >
              </ps-ui-capsule-radio-button>
            </td>
            <td>
              <ps-ui-capsule-radio-button
                class="capsule-button-row"
                [value]="supplier.isDataQualityQuestionsActive"
                [id]="'isDataQualityQuestionsActive' + supplier.id"
                (valueChange)="
                  changeSupplierSetting(
                    supplier.id,
                    $event,
                    'isDataQualityQuestionsActive'
                  )
                "
              >
              </ps-ui-capsule-radio-button>
            </td>
            <td>
              <ps-ui-capsule-radio-button
                class="capsule-button-row"
                [ngClass]="{ 'disable-radio-btn': supplier.isFusion }"
                [value]="supplier.isAsrRedirectionEnable"
                [id]="'isAsrRedirectionEnable' + supplier.id"
                (valueChange)="
                  changeSupplierSetting(
                    supplier.id,
                    $event,
                    'isAsrRedirectionEnable'
                  )
                "
              >
              </ps-ui-capsule-radio-button>
            </td>
            <td>
              <ps-ui-capsule-radio-button
                class="capsule-button-row"
                [ngClass]="{ 'disable-radio-btn': supplier.isFusion }"
                [value]="supplier.skipPreScreener"
                [id]="'skipPreScreener' + supplier.id"
                (valueChange)="
                  changeSupplierSetting(supplier.id, $event, 'skipPreScreener')
                "
              >
              </ps-ui-capsule-radio-button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="backbutton col-md-6 mt-6">
          <button
            class=""
            id="dashboard"
            type="button"
            psUiButton
            size="large"
            color="primary"
            [routerLink]="['/dashboard/']"
          >
            Back to Dashboard
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
