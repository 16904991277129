import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

/**
 * Guard to prevent a logged in user from accessing a route that only unauthenticated users can access
 */
@Injectable({
  providedIn: 'root',
})
export class GuestGuard implements CanActivate {
  constructor(private _router: Router, private _auth: AuthService) {}

  canActivate(): boolean | UrlTree {
    if (this._auth.isLoggedIn()) {
      return this._router.parseUrl('/dashboard');
    }
    return true;
  }
}
