<div class="row currency-form-settiings" [formGroup]="form">
  <div class="col-sm-6 col-md-4 form-group">
    <label>CURRENCIES</label>
    <ps-ui-select-dropdown
      #currencyList="psUiSelectDropdown"
      [dropdownItems]="currencies"
      [field]="'name'"
      labelNone="Select Currency"
      [label]="form?.value?.name"
      (selectedItem)="selectCurrency($event)"
    >
    </ps-ui-select-dropdown>
  </div>
  <div class="col-sm-6 col-md-3 form-group">
    <label>Currency Code (fx)</label>
    <input type="number" class="form-control" formControlName="fx" />
  </div>
  <div class="col-sm-6 col-md-3 form-group">
    <label>CRRate</label>
    <input type="number" class="form-control" formControlName="CRRate" />
  </div>
  <div class="col-sm-6 col-md-2 form-group">
    <label>Currency Symbol</label>
    <input type="text" class="form-control" formControlName="symbol" />
  </div>
</div>
<div class="row">
  <div class="col-sm-12 col-md-12 hasing-buttons text-center mt-6">
    <button type="submit" psUiButton color="primary" (click)="saveCurrency()">
      Submit
    </button>
  </div>
</div>
<div class="row">
  <div class="hasing-buttons col-md-6 mt-6">
    <button
      id="dashboard"
      type="button"
      psUiButton
      color="primary"
      [routerLink]="['/dashboard/']"
    >
      Back to Dashboard
    </button>
  </div>
</div>
