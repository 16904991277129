<ps-ui-modal>
  <ps-ui-modal-body>
    <i
      class="fa fa-exclamation-circle exclamation-style"
      aria-hidden="true"
    ></i>
    <p class="lable-text">
      Edited Current Target amounts may be affected by your changes. Would you
      like to continue?
    </p>
    <br />
  </ps-ui-modal-body>
  <ps-ui-modal-footer>
    <button
      type="button"
      psUiButton
      color="secondary"
      (click)="close()"
      class="cancle-btn btn btn-default pull-right"
    >
      NO
    </button>
    <button
      type="button"
      class="clear-btn btn btn-primary pull-left"
      psUiButton
      (click)="close('continue')"
    >
      YES
    </button>
  </ps-ui-modal-footer>
</ps-ui-modal>
