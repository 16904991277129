import { Injectable } from '@angular/core';
import { SupplierSurveyObject } from '../dashboard/survey-service/supplier-survey.interface';
import {
  Cpi,
  ProjectManager,
} from '../shared/services/buyer-api/survey.interface';
import { Criteria } from '../shared/services/quota/quota.interface';
import { SupplierSurveyResponse } from '../shared/services/supplier-api/supplier-api.interface';
import { SurveyPayload } from '../shared/services/survey-meta-data/survey-meta-data.interface';
import { SurveyMetaDataService } from '../shared/services/survey-meta-data/survey-meta-data.service';
import { BuyerSurveyResponse } from '@purespectrum1/ui/marketplace/shared/interfaces/survey.interface';
@Injectable({
  providedIn: 'root',
})
export class SurveyPayloadService {
  constructor(private _surveyMetaDataService: SurveyMetaDataService) {}

  public mapSurveyDetails(surveyDetais: BuyerSurveyResponse) {
    this._generateTopBarObject(surveyDetais);
    this._mapPmObject(surveyDetais.pm);
    this._mapBpmObject(surveyDetais.pm);

    let payload = {} as SurveyPayload;
    payload = Object.assign(payload, surveyDetais);
    payload.update_supplier = false;
    delete payload.ps_survey_status;

    if (surveyDetais.qualifications?.length) {
      const mappedQualifications = surveyDetais.qualifications
        .filter(
          (qualification) =>
            (qualification.isDefault === 0 || qualification.isQuotaSet) &&
            qualification.qualification_code
        )
        .map((qualification) => {
          return {
            qualification_name: qualification.qualification_name,
            qualification_code: qualification.qualification_code,
            range_sets: qualification.range_sets,
            condition_codes: qualification.condition_codes,
          };
        });

      if (mappedQualifications.length) {
        payload.qualifications = mappedQualifications;
      } else {
        delete payload.qualifications;
      }
    } else {
      delete payload.qualifications;
    }

    if (surveyDetais.quotas?.length) {
      payload.quotas = surveyDetais.quotas.map((quota) => {
        return {
          buyer_quota_id: quota.buyer_quota_id,
          quota_title: quota.quota_title,
          required_count: quota.required_count,
          criteria: this._mapCriteria(quota.criteria),
          percentage: quota.percentage,
          maximum: quota.maximum,
          minimum: quota.minimum,
          flexible: quota.flexible,
          suppliers: quota.suppliers,
          quota_category: quota.quota_category,
          current_target: quota.current_target,
        };
      });
    } else {
      delete payload.quotas;
    }

    return this.removeEmpty(payload);
  }

  private _mapPmObject(pm?: ProjectManager) {
    if (pm && pm.id) {
      pm.name = pm.nm;
      pm.email = pm.eml;
    }
  }

  private _mapBpmObject(bpm?: ProjectManager) {
    if (bpm && bpm.id) {
      bpm.name = bpm.nm;
      bpm.email = bpm.eml;
    }
  }

  private _mapCriteria(criterias: Criteria[]) {
    return criterias.map((criteria) => {
      return {
        qualification_name: criteria.qualification_name,
        qualification_code: criteria.qualification_code,
        condition_codes: criteria.condition_codes,
        condition_names: criteria.condition_names,
        range_sets: criteria.range_sets,
        group_name: criteria.group_name,
      };
    });
  }

  public removeEmpty(obj: any) {
    Object.keys(obj).forEach((k) => {
      if (obj[k] && typeof obj[k] === 'object') {
        this.removeEmpty(obj[k]);
      } else if (obj[k] === '' || obj[k] === null) {
        delete obj[k];
      }
    });
    return obj;
  }

  private _generateTopBarObject(
    {
      field_time = 0,
      creation_date = 0,
      mod_on = 0,
      ps_survey_status,
      ps_survey_id,
      completes_required = 0,
      cpi: { current = 0, feasibility_cpi = 0 } = {} as Cpi,
      pm,
      bpm,
      locale,
      expected_loi,
      expected_ir,
      incl_excl,
      currency_symbol = '',
      buyer_company,
    }: BuyerSurveyResponse,
    totalClicks?: number
  ) {
    const obj = {
      surveyFieldTime: field_time,
      surveyLastSaved: creation_date,
      surveyId: ps_survey_id,
      surveyStatus: ps_survey_status,
      totalClicks: 0,
      completes: completes_required,
      cpi: feasibility_cpi, // always show feasibility_cpi in header
      total_cost: completes_required * feasibility_cpi,
      pmObj: pm,
      bpmObj: bpm,
      buyerCompany: buyer_company,
      location: {
        countryCode: locale?.country_code,
        languageShort: locale?.language_code,
      },
      expected_loi,
      expected_ir,
      incl_excl,
      currencySymbol: currency_symbol,
    };

    if (mod_on) {
      obj.surveyLastSaved = mod_on;
    }
    if (totalClicks) {
      obj.totalClicks = totalClicks;
    }
    this._surveyMetaDataService.sendSurveyHeaderData(obj);
  }

  public getProcessingPermissionToSave(
    manualProcessing: boolean,
    isAutoSave: boolean,
    isFormValid: boolean,
    isSurveyCreated: boolean
  ) {
    if (manualProcessing && isSurveyCreated && isAutoSave) {
      // block for update but not for creation
      return false;
    }
    if (!isFormValid) {
      // metadata required fields are not filled
      return false;
    }
    return true;
  }

  public addTotalClickInHeaderBar(
    surveyDetais: BuyerSurveyResponse,
    totalClicks: number
  ) {
    this._generateTopBarObject(surveyDetais, totalClicks);
  }

  public mapBuyerSurvey(buyerSurveyResponse: BuyerSurveyResponse) {
    const cpi = this._getBuyerCpi(buyerSurveyResponse);
    return {
      id: buyerSurveyResponse.ps_survey_id,
      survey_localization: buyerSurveyResponse.survey_localization,
      status: buyerSurveyResponse.ps_survey_status,
      title: buyerSurveyResponse.survey_title,
      countryName: buyerSurveyResponse.locale.country_name,
      languageName: buyerSurveyResponse.locale.language_name,
      countryCode: this._mapCountryCode(
        buyerSurveyResponse.locale.country_code
      ),
      currencySymbol: buyerSurveyResponse.currency_symbol,
      fielded: buyerSurveyResponse.fielded,
      goal: buyerSurveyResponse.goal,
      reconciliationCompleteCount: buyerSurveyResponse.rec_complt_count,
      manualFixCompltCount: buyerSurveyResponse.manual_fix_complt_count,
      totalCostLabel: 'Current',
      totalCostValue: buyerSurveyResponse.total_cost.current,
      totalCost: buyerSurveyResponse.total_cost,
      cpi1Label: 'Current',
      cpi1Value: cpi.current,
      cpi2Label: 'Average',
      cpi2Value: cpi.averageACpi,
      cpi: cpi,
      loiLabel: 'Recent',
      loiValue: buyerSurveyResponse.loi.recent,
      loi: buyerSurveyResponse.loi,
      incidenceLabel: 'Recent',
      incidenceValue: buyerSurveyResponse.incidence.recent,
      incidence: buyerSurveyResponse.incidence,
      invoiceType: buyerSurveyResponse.company.generate_invoice,
      channelType: buyerSurveyResponse.channel_type,
      projectId: buyerSurveyResponse.ps_project_id || null,
      companyId: buyerSurveyResponse.buyer_company,
      field_date_info: buyerSurveyResponse.field_date_info,
      survey_open_close_date_info:
        buyerSurveyResponse.survey_open_close_date_info,
      buyer_name: buyerSurveyResponse.buyer_name,
      buyer_email: buyerSurveyResponse.buyer_email,
      buyer_message: buyerSurveyResponse.buyer_message,
      samples_name: buyerSurveyResponse.samples_name,
      incl_excl: buyerSurveyResponse.incl_excl,
      survey_grouping: buyerSurveyResponse.survey_grouping,
      billing_id: buyerSurveyResponse.billing_id,
      survey_external_id: buyerSurveyResponse.survey_external_id,
      survey_client: buyerSurveyResponse.survey_client,
      sb_project_id: buyerSurveyResponse.sb_project_id,
      sb_project_resync: buyerSurveyResponse.sb_project_resync,
      offer_id: buyerSurveyResponse.offer_id,
      term_id: buyerSurveyResponse.term_id,
      qlc: buyerSurveyResponse.qlc,
      is_qbp_enable: buyerSurveyResponse.is_qbp_enable,
      crmId: buyerSurveyResponse.crm_id,
      qbp: buyerSurveyResponse.qbp,
      survey_category_code: buyerSurveyResponse.survey_category_code,
      is_qbp_child_survey: buyerSurveyResponse.is_qbp_child_survey,
      asanaId: buyerSurveyResponse.asana_id,
      click_balancing: buyerSurveyResponse.click_balancing,
      mc_unique_id: buyerSurveyResponse.mc_unique_id,
      fx: buyerSurveyResponse.fx,
      epc: buyerSurveyResponse.epc,
      in_progress: buyerSurveyResponse.in_progress,
      total_starts: buyerSurveyResponse.total_starts,
      last_24_starts: buyerSurveyResponse.last_24_starts,
      price_reco: buyerSurveyResponse.price_reco,
      field_time: buyerSurveyResponse.field_time,
      is_feot_enabled: buyerSurveyResponse.is_feot_enabled,
      launch_date: buyerSurveyResponse.launch_date,
      is_enable_margin_max: buyerSurveyResponse.is_enable_margin_max,
      mc: buyerSurveyResponse.mc,
      survey_pause_threshold: buyerSurveyResponse.survey_pause_threshold,
      is_survey_pause_threshold_triggered:
        buyerSurveyResponse.is_survey_pause_threshold_triggered,
      mcProjectId: buyerSurveyResponse.ps_mc_project_id,
      pds_ignore_override: buyerSurveyResponse.pds_ignore_override,
    };
  }

  public mapSupplierSurvey(supplierSurveyResponse: SupplierSurveyResponse) {
    const totalCost = this._getSupplierTotalCost(supplierSurveyResponse.survey);
    const cpi = this._getSupplierCpi(supplierSurveyResponse.survey);
    const loi = this._getSupplierLoi(supplierSurveyResponse.survey);
    const incidence = this._getSupplierIncidence(supplierSurveyResponse.survey);
    return {
      id: supplierSurveyResponse.survey.survey_id,
      survey_localization: supplierSurveyResponse.survey.surveyLocalization,
      status: supplierSurveyResponse.survey.survey_status,
      title: supplierSurveyResponse.survey.survey_name,
      countryName: supplierSurveyResponse.survey.locale.cnt_nm,
      languageName: supplierSurveyResponse.survey.locale.lang_nm,
      countryCode: this._mapCountryCode(
        supplierSurveyResponse.survey.locale.cnt_cd
      ),
      currencySymbol: '$',
      fielded: supplierSurveyResponse.survey.supplier_completes.achieved,
      goal: supplierSurveyResponse.survey.supplier_completes.needed,
      reconciliationCompleteCount:
        supplierSurveyResponse.survey.rec_complt_count,
      manualFixCompltCount:
        supplierSurveyResponse.survey.manual_fix_complt_count,
      totalCostLabel: 'Current',
      totalCostValue: totalCost.current,
      totalCost: totalCost,
      cpi1Label: 'Current',
      cpi1Value: cpi.current,
      cpi2Label: 'Average',
      cpi2Value: cpi.averageACpi,
      cpi: cpi,
      loiLabel: 'Recent',
      loiValue: loi.recent,
      loi: loi,
      incidenceLabel: 'Recent',
      incidenceValue: incidence.recent,
      incidence: incidence,
      field_date_info: {
        creation_date: supplierSurveyResponse.survey.creation_date,
        end_date: supplierSurveyResponse.survey.end_date,
        days_in_field: supplierSurveyResponse.survey.days_in_field,
        days_remaining: supplierSurveyResponse.survey.days_remaining,
      },
      open_dates: supplierSurveyResponse.survey.open_dates,
      sr_launch_ts: supplierSurveyResponse.survey.sr_launch_ts,
      sr_close_ts: supplierSurveyResponse.survey.sr_close_ts,
      samples_name: supplierSurveyResponse.survey.category,
      supplier_survey_grouping: supplierSurveyResponse.survey.survey_grouping,
      live_url: supplierSurveyResponse.survey.live_url,
      test_url: supplierSurveyResponse.survey.test_url,
      redirect_urls: supplierSurveyResponse.survey.redirect_urls,
      buyer_message: supplierSurveyResponse.survey.buyer_message,
      incl_excl: supplierSurveyResponse.survey.incl_excl,
      qbp: supplierSurveyResponse.survey.qbp,
      is_qbp_enable: supplierSurveyResponse.survey.is_qbp_enable,
      channelType: supplierSurveyResponse.survey.channel_type,
      survey_category_code: supplierSurveyResponse.survey_category_code,
      is_qbp_child_survey: supplierSurveyResponse.survey.is_qbp_child_survey,
      click_balancing: supplierSurveyResponse.survey.click_balancing,
      companyId: 0,
      isPDSEnabled: !!supplierSurveyResponse.survey?.pds?.enabled,
      isModularSurvey: !!supplierSurveyResponse.survey?.is_modular_survey,
    };
  }

  private _getBuyerCpi(buyerSurveyResponse: BuyerSurveyResponse) {
    return {
      launch: buyerSurveyResponse.cpi?.launch
        ? buyerSurveyResponse.cpi.launch
        : 0,
      projected: buyerSurveyResponse.price_override
        ? buyerSurveyResponse.price_override
        : buyerSurveyResponse.cpi.projected,
      current: buyerSurveyResponse.cpi.current,
      averageCpi:
        buyerSurveyResponse.total_cost.current !== 0
          ? buyerSurveyResponse.total_cost.current / buyerSurveyResponse.fielded
          : 0,
      averageACpi:
        buyerSurveyResponse.fielded > 0 &&
        buyerSurveyResponse.total_cost.current !== 0
          ? buyerSurveyResponse.total_cost.current / buyerSurveyResponse.fielded
          : buyerSurveyResponse.cpi.current
          ? buyerSurveyResponse.cpi.current
          : 0,
    };
  }

  private _mapCountryCode(countryCode: string): string {
    return countryCode === 'UK' ? 'GB' : countryCode;
  }

  private _getSupplierTotalCost(survey: SupplierSurveyObject) {
    return {
      current: survey.total_cost_current,
      projected: survey.total_cost_projected,
    };
  }

  private _getSupplierCpi(survey: SupplierSurveyObject) {
    return {
      launch: survey.cpi_launch,
      current: survey.is_qbp_enable ? 'QBP' : survey.cpi || 0,
      averageCpi: survey.cpi_average || 0,
      averageACpi: survey.cpi_average || 0,
    };
  }

  private _getSupplierLoi(survey: SupplierSurveyObject) {
    return {
      launch: survey.loi_launch,
      overall: survey.survey_performance.overall.loi,
      recent: survey.survey_performance.last_block.loi
        ? survey.survey_performance.last_block.loi
        : survey.survey_performance.overall.loi,
    };
  }

  private _getSupplierIncidence(survey: SupplierSurveyObject) {
    return {
      launch: survey.ir_launch,
      overall: survey.survey_performance.overall.ir,
      recent: survey.survey_performance.last_block.ir
        ? survey.survey_performance.last_block.ir
        : survey.survey_performance.overall.ir,
    };
  }
}
