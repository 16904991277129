<ps-ui-modal>
  <h5 psUiModalTitle>Enable Real-Time Notification (Activity Log)</h5>
  <ps-ui-modal-body>
    <div class="form-group">
      <div class="col-md-4">
        <label for="webhook">Webhook URL</label>
      </div>
    </div>
    <form [formGroup]="form">
      <input
        type="text"
        webhook="webhook"
        class="form-control"
        formControlName="webhook"
        placeholder="Input real time webhook url"
      />
      <div *ngIf="webhook.invalid && (webhook.dirty || webhook.touched)">
        <div class="error-alert" *ngIf="webhook.errors?.['required']">
          Webhook is required.
        </div>
        <div class="error-alert" *ngIf="webhook.errors?.['pattern']">
          Webhook should be a valida url.
        </div>
      </div>
    </form>
  </ps-ui-modal-body>
  <ps-ui-modal-footer>
    <button type="button" psUiButton color="secondary" (click)="close()">
      Close
    </button>
    <button type="button" psUiButton [disabled]="!form.valid" (click)="save()">
      Save changes
    </button>
  </ps-ui-modal-footer>
</ps-ui-modal>
