import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'zeroDash' })
export class ZeroDashPipe implements PipeTransform {
  constructor(private _decimalPipe: DecimalPipe) {}

  transform(value: number, ...rest: any[]): string | null {
    return !value ? '-' : this._decimalPipe.transform(value, ...rest);
  }
}
