import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { ModalService } from '@purespectrum1/ui/modal';
import { CompanyService } from '../../../shared/services/company/company.service';
import { CurrencyService } from '../../../shared/services/currency-service';
import { NetsuiteService } from '../../../shared/services/netsuite/netsuite.service';
import { UserService } from '../../user-service/user.service';
import { CompanyCurrencyResponseObject } from 'src/app/shared/types/currency-service-interface';
import { ProjectManagers } from '../../user-service/user.interface';
import {
  CompanyResponseObject,
  CompanyInfo,
  ApiResponseObject,
} from '../../../shared/interfaces/company.interface';
import { EmptyOrBlankStringRegex, DefaultSupplierCurrency } from './constant';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { INVOICE_TYPE } from '../../../constants/invoice-type';
import { tap } from 'rxjs/operators';
import { notifyMessage } from '../../../constants/notify-message';

type NoUndefinedField<T> = {
  [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>;
};

@Component({
  selector: 'ps-manage-companies-form',
  templateUrl: './manage-companies-form.component.html',
  styleUrls: ['./manage-companies-form.component.css'],
})
export class ManageCompaniesFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() company!: CompanyInfo;
  @Output() deleted = new EventEmitter<CompanyResponseObject>();
  @Output() added = new EventEmitter<CompanyResponseObject>();

  form!: FormGroup;
  private _subscription: Subscription = new Subscription();
  public currencies!: CompanyCurrencyResponseObject[];
  public selectedCompany!: CompanyResponseObject;
  public selectedCurrency!: CompanyCurrencyResponseObject;
  public currencyPlaceholder: string = '';
  public disableCurrency = false;
  public isABuyer = false;
  public isASupplier = false;
  public isAnOperator = false;
  public pmEmailsToDisplay: ProjectManagers[] = [];
  public isNetSuite = false;
  public company$!: Observable<CompanyResponseObject>;
  public isFusion = false;

  constructor(
    private _fb: FormBuilder,
    private _companyService: CompanyService,
    private _netSuiteService: NetsuiteService,
    private _currencyService: CurrencyService,
    private _toastr: ToasterService,
    private _userService: UserService,
    private _modal: ModalService,
    public route: ActivatedRoute
  ) {
    this.isNetSuite = true;
  }

  ngOnInit(): void {
    this.prepareNewFormData();
  }

  ngOnChanges(changes: SimpleChanges) {
    const id = changes.company.currentValue.id;
    const add = of({} as CompanyResponseObject);
    const edit = this._companyService.getCompanyDetail(id);
    const operation = id === 0 ? add : edit;

    this.company$ = operation.pipe(
      tap((company) => {
        this.setSelectedCompany(company);
        this.updateAccessLevelsOnCompanyChange(company);
      })
    );
  }

  public prepareNewFormData() {
    this._generateNewForm();
    this._getCurrencyForCompanies();
    this._getAllOperators();

    this.selectedCompany = {} as CompanyResponseObject;
    this.selectedCurrency = {} as CompanyCurrencyResponseObject;
  }

  private _generateNewForm() {
    this.form = this._fb.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      primaryLiaison: ['', [Validators.required]],
      secondaryLiaison: ['', [Validators.required]],
      isABuyer: [null, [Validators.required]],
      isASupplier: [null, [Validators.required]],
      isAnOperator: [null, [Validators.required]],
      fx: [null, [Validators.required]],
      isAServiceAccount: [false],
      tempGenerateInvoice: [''],
      isFusion: [false],
      generateInvoice: [''],
      supp_url_completes: [''],
      supp_url_overquota: [''],
      supp_url_security_terms: [''],
      supp_url_terms: [''],
      isExpose: [false],
      isAdditionalMetrics: [false],
      isPureScoreEnable: [false],
      isRdFlagsEnable: [false],
      appendProfileOnRedirects: [false],
      isNotify: [false],
      accountManagers: [[]],
      projectManager: [{}],
      orgId: [''],
      isTestAccount: [false],
      netsuite: this._fb.group({
        internalId: [''],
        enabled: [false],
        companyName: [''],
        primaryEmail: [''],
        billingInfo: this._fb.group({
          address: [''],
          city: [''],
          state: [''],
          zip: [''],
          country: [''],
        }),
        currencies: [[]],
      }),
    });

    this.form.get('isASupplier')?.valueChanges.subscribe((enabled) => {
      if (!enabled) {
        this.form.removeControl('sendSupplierStatement');
      }
    });
  }

  private _manageControlsendSupplierStatement(controlStatus: boolean) {
    this.form.removeControl('sendSupplierStatement');
  }

  private _getCurrencyForCompanies() {
    const currency = this._currencyService.getCurrencies().subscribe(
      (response) => {
        this.currencies = response.currencies;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscription.add(currency);
  }

  private _getAllOperators() {
    const operatorUsers = this._userService.getAllOperatorUser().subscribe(
      (response) => {
        this.pmEmailsToDisplay = response.users;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscription.add(operatorUsers);
  }

  private _getCurrencyPlaceHolder(fx: number) {
    if (fx) {
      const foundFx = this.currencies.find(
        (currency: { fx: number }) => currency.fx === fx
      );

      if (foundFx) {
        this.disableCurrency = true;
        this.currencyPlaceholder = foundFx.currencyShortCode;
      }
    }
  }

  setSelectedCompanyCurrency(currency: CompanyCurrencyResponseObject) {
    if (this.isASupplier && currency.fx !== DefaultSupplierCurrency.fx) {
      this._toastr.warning(
        notifyMessage.warningMessage.INVALID_SUPPLIER_CURRENCY
      );
      return;
    }
    this.selectedCurrency = currency;
    this.currencyPlaceholder = this.selectedCurrency.currencyShortCode;
    this.form.patchValue({
      fx: this.selectedCurrency.fx,
    });
  }

  updateAccessLevelsOnCompanyChange(company: CompanyResponseObject) {
    if (!company.id) {
      this.isABuyer = false;
      this.isASupplier = false;
      this.isAnOperator = false;
    }
    if (company.isABuyer) {
      this.isABuyer = company.isABuyer;
      this.updateBuyer();
    }
    if (company.isASupplier) {
      this.isASupplier = company.isASupplier;
      this.updateSupplier();
    }

    if (company.isAnOperator) {
      this.isAnOperator = company.isAnOperator;
      this.updateOperator();
    }
  }

  updateBuyer() {
    const keys = ['isASupplier', 'isAnOperator'];
    this.isASupplier = false;
    this.isAnOperator = false;
    this.resetOrDisableAcessLevelInputs(keys, this.isABuyer);
  }

  updateSupplier() {
    const keys = ['isABuyer', 'isAnOperator'];
    this.isABuyer = false;
    this.isAnOperator = false;
    this.resetOrDisableAcessLevelInputs(keys, this.isASupplier);
  }

  updateOperator() {
    const keys = ['isABuyer', 'isASupplier'];
    this.isABuyer = false;
    this.isASupplier = false;
    this.resetOrDisableAcessLevelInputs(keys, this.isAnOperator);
  }

  async setSelectedCompany(company: CompanyResponseObject) {
    this.isFusion = company.isFusion;
    this.prepareNewFormData();
    this.selectedCompany = company;
    if (company.nsInternalId && this.isNetSuite) {
      this._syncNetsuiteDetail(company.nsInternalId);
      company.netsuite = this.selectedCompany.netsuite;
    }
    if (!company.tempGenerateInvoice && !!company.generateInvoice) {
      company.tempGenerateInvoice = company.generateInvoice;
    }
    this.form.patchValue(company);
    this.updateAccessLevelsOnCompanyChange(company);
    if (this.form.get('isFusion')?.value) {
      const sendSupplierStatement =
        !!this.selectedCompany.sendSupplierStatement;
      this._manageControlsendSupplierStatement(sendSupplierStatement);
    } else if (!this.form.get('isASupplier')?.value) {
      this.form.removeControl('sendSupplierStatement');
    }
    if (this.selectedCompany.fx) {
      this._getCurrencyPlaceHolder(this.selectedCompany.fx);
      this.disableCurrency = true;
    }
  }

  /**
   * Removes undefined, null, '' and '  ' properties. Doesn't clean up nested objects.
   */
  _clearFormValue<T extends {}>(obj: T): NoUndefinedField<T> {
    return Object.entries(obj)
      .filter(([_, value]) => !this._isNullUndefinedOrEmptyString(value))
      .reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {} as NoUndefinedField<T>
      );
  }

  _isNullUndefinedOrEmptyString(value: unknown) {
    return (
      value === null ||
      value === undefined ||
      EmptyOrBlankStringRegex.test(value as string)
    );
  }

  _setInvoiceType(payload: NoUndefinedField<CompanyResponseObject>) {
    if (this.isABuyer) {
      payload.generateInvoice =
        payload.tempGenerateInvoice || INVOICE_TYPE.MONTHLY;
    }

    return payload;
  }

  private _setNsInternalId(payload: NoUndefinedField<CompanyResponseObject>) {
    if (this.isNetSuite) {
      const { netsuite: { internalId = 0 } = {} } = payload;
      payload.nsInternalId = internalId;
    }
    return payload;
  }

  checkCompanyName() {
    return this._companyService.getCompanyByName(this.form.value.name);
  }

  validateCompanyType() {
    return [
      this.form.value.isABuyer,
      this.form.value.isAnOperator,
      this.form.value.isASupplier,
      this.isFusion,
    ].some((value) => value);
  }

  async saveCompany() {
    if (!this.validateCompanyType()) {
      this._toastr.error('You must select a company type');
      return;
    }
    let payload = this.form.value;
    if (this._validateNetsuiteInternalId()) {
      this._toastr.error(notifyMessage.errorMessage.NETSUITE.INVALID_ID);
      return;
    }
    this._setNsInternalId(payload);
    //this._managePayload(payload);
    payload = this._clearFormValue<CompanyResponseObject>(payload);
    this._setInvoiceType(payload);
    const company = this._companyService.saveCompanyDetails(payload).subscribe(
      (response) => {
        this.checkCompanyDetailsResponse(response);
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscription.add(company);
  }

  checkCompanyDetailsResponse(response: ApiResponseObject) {
    if (response.quickBookErrorMessage) {
      this._toastr.error(response.quickBookErrorMessage);
    }

    if (response.company?.id) {
      this._companyService
        .getCompanyDetail(response.company?.id)
        .subscribe((_company) => (this.selectedCompany = _company));
    }
    this.added.emit(response.company);
    this._toastr.success(response.msg);
  }

  async updateCompany() {
    if (!this.validateCompanyType()) {
      this._toastr.error('You must select a company type');
      return;
    }
    const payload = this.form.value;
    if (this._validateNetsuiteInternalId()) {
      this._toastr.error(notifyMessage.errorMessage.NETSUITE.INVALID_ID);
      return;
    }
    this._setNsInternalId(payload);
    //this._managePayload(payload);
    for (const key in payload) {
      if (payload[key] === null) {
        delete payload[key]; // remove null value from update payload
      }
    }
    if (payload.tempGenerateInvoice !== 'MONTHLY' && payload.isABuyer) {
      this._toastr.info(
        'Feature Disable Survey Auto-close is turned off as Buyer invoice type is not Montly.'
      );
    }

    const company = this._companyService
      .updateCompany(this.selectedCompany.id, payload)
      .subscribe(
        (response) => {
          this._toastr.success(response.msg);
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscription.add(company);
  }

  private _validateNetsuiteInternalId() {
    return (
      this.isNetSuite &&
      this.form.value.netsuite?.enabled &&
      (!this.form.value.netsuite?.internalId ||
        !this.form.value.netsuite?.companyName)
    );
  }

  deleteCompany() {
    const company = this._companyService
      .deleteCompanyDetails(this.selectedCompany.id)
      .subscribe(
        (response) => {
          this.deleted.emit(this.selectedCompany);
          this.resetCompanyFormData();
          this._toastr.success(response.msg);
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscription.add(company);
  }

  public resetCompanyFormData() {
    this.prepareNewFormData();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  updateAcessLevelInputs(keys: string[], value: boolean) {
    keys.forEach((key) => this.form.controls[key].setValue(value));
  }

  resetAcessLevelInputs(keys: string[]) {
    keys.forEach((key) => this.form.controls[key].reset());
  }

  resetOrDisableAcessLevelInputs(keys: string[], value: boolean) {
    if (!value) {
      this.resetAcessLevelInputs(keys);
    }

    this.updateAcessLevelInputs(keys, false);
  }

  setBuyer() {
    const buyer = this.form.controls.isABuyer;
    const keys = ['isASupplier', 'isAnOperator'];
    this.isABuyer = buyer.value;
    this.resetOrDisableAcessLevelInputs(keys, this.isABuyer);
  }

  setSupplier() {
    const supplier = this.form.controls.isASupplier;
    const keys = ['isABuyer', 'isAnOperator'];
    this.isASupplier = supplier.value;
    this.setSelectedCompanyCurrency(DefaultSupplierCurrency);
    this.resetOrDisableAcessLevelInputs(keys, this.isASupplier);
  }

  setOperator() {
    const operator = this.form.controls.isAnOperator;
    const keys = ['isABuyer', 'isASupplier'];
    this.isAnOperator = operator.value;
    this.resetOrDisableAcessLevelInputs(keys, this.isAnOperator);
  }

  setTestAccountFlag(flag: boolean) {
    this.form.controls.isTestAccount.setValue(flag);
  }

  validateAlphaNumeric(event: any) {
    const inputKey = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inputKey)) {
      return true;
    }
    event.preventDefault();
    return false;
  }

  private async _syncNetsuiteDetail(nsInternalId: number) {
    this._netSuiteService.getNsCompany(nsInternalId).subscribe((response) => {
      response.enabled = !!response.internalId;
      response.companyName = response.name;
      this.form.patchValue({ netsuite: response });
    });
  }
}
