<div class="container">
  <h3 class="text-center invoice-text-label">Invoice Operations</h3>
  <ps-ui-tab-group
    (activeIndexChange)="checkActiveTab($event)"
    [activeIndex]="activeTabIndex"
  >
    <ps-ui-tab *ngFor="let tab of tabs" [label]="tab.title">
      <div class="row" *ngIf="tab.key === 'MANAGE_INVOICE'">
        <ps-manage-invoice></ps-manage-invoice>
      </div>
      <div class="row" *ngIf="tab.key === 'SEARCH_SURVEY_TO_INVOICE'">
        <ps-search-survey-invoice></ps-search-survey-invoice>
      </div>
      <br />
    </ps-ui-tab>
  </ps-ui-tab-group>
</div>
