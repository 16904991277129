<ps-ui-modal>
  <ps-ui-modal-title>
    <h2 psUiModalTitle>Choose which columns you see</h2>
  </ps-ui-modal-title>
  <ps-ui-modal-body>
    <div class="row">
      <div class="col-md-4 options-section">
        <div class="position-relative">
          <i class="fa fa-search position-absolute"></i>
          <input
            class="form-control search-input"
            type="search"
            placeholder="Search"
            [(ngModel)]="searchText"
          />
        </div>
        <div class="checkbox-list-group">
          <label class="column-title">Column Selection</label>
          <div class="list-group">
            <ng-container
              *ngFor="
                let dashboardPreference of dashboardPreferences
                  | searchFilter : searchText : 'column'
              "
            >
              <div class="list-group-item" *ngIf="dashboardPreference.selected">
                <label>
                  <input
                    class="m-1 form-check-input"
                    type="checkbox"
                    value="dashboardPreference.key"
                    [checked]="dashboardPreference.selected"
                    [attr.disabled]="
                      dashboardPreference.key === 'status' ? true : null
                    "
                    (click)="
                      selectPreferance(
                        dashboardPreference,
                        dashboardPreference.selected
                      )
                    "
                  />
                  <span class="list-group-item-text">
                    {{ dashboardPreference.column }}
                  </span>
                </label>
              </div>
            </ng-container>
          </div>
          <label class="column-title">Unselected Columns</label>
          <div class="list-group">
            <ng-container
              *ngFor="
                let dashboardPreference of dashboardPreferences
                  | searchFilter : searchText : 'column'
              "
            >
              <div
                class="list-group-item"
                *ngIf="!dashboardPreference.selected"
              >
                <label>
                  <input
                    class="m-1 form-check-input"
                    type="checkbox"
                    value="dashboardPreference.key"
                    [checked]="dashboardPreference.selected"
                    [attr.disabled]="
                      dashboardPreference.key === 'status' ? true : null
                    "
                    (click)="
                      selectPreferance(
                        dashboardPreference,
                        dashboardPreference.selected
                      )
                    "
                  />
                  <span class="list-group-item-text">
                    {{ dashboardPreference.column }}
                  </span>
                </label>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-md-8 selected-options-section preferance-table">
        <h5>Selected Columns ({{ selectedColumnsCount }})</h5>
        <table class="table">
          <thead>
            <tr>
              <th class="col-9">Column Name</th>
              <th class="col-1">Desktop</th>
              <th class="col-2">Mobile</th>
            </tr>
          </thead>
          <tbody cdkDropList (cdkDropListDropped)="dropColumns($event)">
            <tr
              class="drag-drop-row"
              *ngFor="let dashboardPreference of dashboardPreferences"
              cdkDrag
            >
              <ng-container *ngIf="dashboardPreference.selected">
                <td class="col-9">
                  <span>
                    <img src="../../../assets/img/grip.png" alt="Drag" />
                  </span>
                  {{ dashboardPreference.column }}
                </td>
                <td class="col-1">
                  <label>
                    <input
                      class="m-1 form-check-input"
                      type="checkbox"
                      value="dashboardPreference.key"
                      [checked]="dashboardPreference.desktop"
                      [attr.disabled]="
                        dashboardPreference.key === 'status' ? true : null
                      "
                      (click)="
                        selectDesktopPreferance(
                          dashboardPreference,
                          dashboardPreference.desktop
                        )
                      "
                    />
                  </label>
                </td>
                <td class="col-2">
                  <label>
                    <input
                      class="m-1 form-check-input"
                      type="checkbox"
                      value="dashboardPreference.key"
                      [checked]="dashboardPreference.mobile"
                      [attr.disabled]="
                        dashboardPreference.key === 'status' ? true : null
                      "
                      (click)="
                        selectMobilePreferance(
                          dashboardPreference,
                          dashboardPreference.mobile
                        )
                      "
                    />
                  </label>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        <p>
          <small>
            This is a view you will save for yourself. This will not effect the
            rest of your team.
          </small>
        </p>
        <button
          type="button"
          class="clear-btn btn btn-primary pull-right ms-2"
          psUiButton
          (click)="close('save')"
        >
          SAVE
        </button>
        <button
          type="button"
          psUiButton
          color="secondary"
          (click)="close()"
          class="cancle-btn btn btn-default pull-right"
        >
          CANCEL
        </button>
      </div>
    </div>
  </ps-ui-modal-body>
</ps-ui-modal>
