import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@purespectrum1/ui/button';
import { SelectDropdownModule } from '@purespectrum1/ui/select-dropdown';
import { ModalModule } from '@purespectrum1/ui/modal';
import { SliderModule } from '@purespectrum1/ui/slider';
import { TabsModule } from '@purespectrum1/ui/tabs';
import { AuthGuard } from '../auth/auth.guard';
import { ManageUserComponent } from '../operator/manage-user/manage-user.component';
import { ChangeUserStatusModalComponent } from './manage-user/change-user-status-modal/change-user-status-modal.component';
import { ManageCompaniesComponent } from './manage-companies/manage-companies.component';
import { ManageUserFormComponent } from './manage-user/manage-user-form/manage-user-form.component';
import { ManageCompaniesFormComponent } from './manage-companies/manage-companies-form/manage-companies-form.component';
import { ReportsDashboardComponent } from './reports-dashboard/reports-dashboard.component';
import { SurveyPerformanceReportComponent } from './reports-dashboard/survey-performance-report/survey-performance-report.component';
import { DailyStatisticsReportComponent } from './reports-dashboard/daily-statistics-report/daily-statistics-report.component';
import { SurveyTransactionReportComponent } from './reports-dashboard/survey-transaction-report/survey-transaction-report.component';
import { TransactionByStatusReportComponent } from './reports-dashboard/transaction-by-status-report/transaction-by-status-report.component';
import { TransactionByStatusReportTableComponent } from './reports-dashboard/transaction-by-status-report/transaction-by-status-report-table/transaction-by-status-report-table.component';
import { BuyerSalesReportComponent } from './reports-dashboard/buyer-sales-report/buyer-sales-report.component';
import { SupplierReportComponent } from './reports-dashboard/supplier-report/supplier-report.component';
import { PsidReportComponent } from './reports-dashboard/psid-report/psid-report.component';
import { StcReportComponent } from './reports-dashboard/stc-report/stc-report.component';
import { EpcReportComponent } from './reports-dashboard/epc-report/epc-report.component';
import { Daterangepicker } from 'ng2-daterangepicker';
import { SurveyPerformanceReportTableComponent } from './reports-dashboard/survey-performance-report/survey-performance-report-table/survey-performance-report-table.component';
import { SortTableModule } from '../sort-table-directive/sort-table.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SurveyHistoryComponent } from './reports-dashboard/psid-report/survey-history/survey-history.component';
import { ProfileDataComponent } from './reports-dashboard/psid-report/profile-data/profile-data.component';
import { SupplierHistoryComponent } from './reports-dashboard/psid-report/supplier-history/supplier-history.component';
import { NetsuiteFormComponent } from './manage-companies/netsuite-form/netsuite-form.component';
import { PaginatedListModule } from '@purespectrum1/ui/paginated-list';
import { ManageUserEditCompanyModalComponent } from './manage-user/manage-user-edit-company-modal/manage-user-edit-company-modal.component';
import { UserGuard } from '../auth/user.guard';
import { USER_TYPES } from './manage-companies/manage-companies-form/constant';

@NgModule({
  declarations: [
    ManageUserComponent,
    ChangeUserStatusModalComponent,
    ManageCompaniesComponent,
    ManageUserFormComponent,
    ManageCompaniesFormComponent,
    ReportsDashboardComponent,
    SurveyPerformanceReportComponent,
    DailyStatisticsReportComponent,
    SurveyTransactionReportComponent,
    TransactionByStatusReportComponent,
    BuyerSalesReportComponent,
    SupplierReportComponent,
    PsidReportComponent,
    StcReportComponent,
    EpcReportComponent,
    SurveyHistoryComponent,
    ProfileDataComponent,
    SupplierHistoryComponent,
    SurveyPerformanceReportTableComponent,
    TransactionByStatusReportTableComponent,
    NetsuiteFormComponent,
    ManageUserEditCompanyModalComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    ModalModule,
    SliderModule,
    TabsModule,
    FormsModule,
    SelectDropdownModule,
    ReactiveFormsModule,
    Daterangepicker,
    SortTableModule,
    NgSelectModule,
    PaginatedListModule,
    RouterModule.forRoot([
      {
        path: 'manage-users',
        canActivate: [AuthGuard],
        component: ManageUserComponent,
      },
      {
        path: 'manage-companies',
        canActivate: [AuthGuard],
        children: [{ path: '', component: ManageCompaniesComponent }],
      },
    ]),
    RouterModule.forChild([
      {
        path: 'operator',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'reports-dashboard',
            component: ReportsDashboardComponent,
            canActivate: [UserGuard],
            data: { roles: [USER_TYPES.OPERATOR] },
          },
        ],
      },
    ]),
    Daterangepicker,
  ],
})
export class OperatorModule {}
