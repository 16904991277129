import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'switchMultiCase',
})
export class SwitchMultiCasePipe implements PipeTransform {
  public transform(cases: unknown[], option: unknown): unknown {
    return cases.includes(option) ? option : !option;
  }
}
