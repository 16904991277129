import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MarketplaceService } from '@purespectrum1/ui/marketplace';

import { AuthState } from '@purespectrum1/ui/marketplace/shared/interfaces/auth.interface';
import { ModalService } from '@purespectrum1/ui/modal';
import { AuthService } from '../../auth/auth.service';
import { ServiceAccountModalComponent } from '../../layout/header/user-menu/service-account-modal/service-account-modal.component';
@Component({
  selector: 'ps-survey-launch',
  templateUrl: './survey-launch.component.html',
  styleUrls: ['./survey-launch.component.css'],
})
export class SurveyLaunchComponent implements OnInit, OnDestroy {
  private _subscriptions = new Subscription();
  public surveyId!: number;
  public auth: AuthState = this._auth.getMarketplaceAuth;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _auth: AuthService,
    private _marketplaceService: MarketplaceService,
    private _modal: ModalService
  ) {}

  ngOnInit(): void {
    this._subscriptions.add(
      this._route.params.subscribe((params) => {
        this.surveyId = params['id'];
      })
    );

    this._subscriptions.add(
      this._auth.loggedInAsServiceBuyer$.subscribe((data) => {
        this.auth = this._auth.getMarketplaceAuth;
      })
    );

    this._subscriptions.add(
      this._marketplaceService
        .onUpdateSurveyComplete()
        .subscribe((response) => {
          if (response.survey?.ps_survey_id && response.action === 'LiveEdit') {
            this._router.navigateByUrl(
              `/dashboard/${response.survey.ps_survey_id}`
            );
          }
        })
    );
  }

  goToDashboard(close: boolean) {
    if (close) {
      this._router.navigateByUrl('/dashboard');
    }
  }

  selectBuyer(open: boolean) {
    if (open) {
      this._modal.open(ServiceAccountModalComponent, {});
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
