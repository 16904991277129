import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  UploadMappingsResponse,
  DownloadSamplifyMappingResponse,
} from './samplify-mappings.interface';

@Injectable({
  providedIn: 'root',
})
export class SamplifyMappingsService {
  private readonly _samplifyUrl = environment.pureSpectrum.samplifyUrl;

  constructor(private _http: HttpClient) {}

  uploadMappings(formData: FormData): Observable<UploadMappingsResponse> {
    return this._http.post<UploadMappingsResponse>(
      `${this._samplifyUrl}/samplify/mappings/`,
      formData,
      {
        headers: {
          skipInterceptor: 'true',
        },
      }
    );
  }

  downloadSamplifyMappings(): Observable<DownloadSamplifyMappingResponse> {
    return this._http.get<DownloadSamplifyMappingResponse>(
      `${this._samplifyUrl}/download/samplifyMappings/`
    );
  }
}
