import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'localeShortCode' })
export class LocaleShortCodePipe implements PipeTransform {
  transform(
    locale: string,
    seprator: string,
    fromShortCode: string,
    toShortCode: string
  ): string | null {
    const localeArr = locale.split(seprator);
    if (localeArr && localeArr.length > 1 && localeArr[1] === fromShortCode) {
      localeArr[1] = toShortCode;
    }
    return localeArr.join(seprator);
  }
}
