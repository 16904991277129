import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, retry } from 'rxjs/operators';

import { AuthService } from '../../auth/auth.service';
import { CheckAccessResponse } from '../../auth/auth.interface';
import { Entitlement } from './../../shared/interfaces/entitlement.interface';
import { Constants } from './header.constants';
import { MarketplaceService } from '@purespectrum1/ui';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { SurveyMetaDataService } from '../../shared/services/survey-meta-data/survey-meta-data.service';
import { IPdsEnable } from '../../shared/services/supplier-api/supplier-api.interface';

@Component({
  selector: 'ps-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public onCreateSurveyPage = false;
  public showCreate: boolean = false;
  public isNewSurveyRoute: boolean = false;
  public isLaunchSurveyRoute: boolean = false;
  public surveyDetailPage = false;
  public enableViewByPM?: boolean;
  public isRecontactSurveyRoute: boolean = false;
  public isServiceBuyerLoggedIn: boolean = false;
  public isRecontactSurvey: boolean = false;
  public hideHeader: boolean = false; // disabled from platform-ui and enabled from component-library header.
  public entitlements: Entitlement[] = [];
  public pdsEnabledObj = <IPdsEnable>{ enabled: false };
  public isModularSurvey: boolean = false;
  private _routerSubscription?: Subscription;

  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _marketplaceService: MarketplaceService,
    private _toastr: ToasterService,
    private _surveyMetaDataService: SurveyMetaDataService
  ) {}

  ngOnInit() {
    let currentUrl = '#';
    this._routerSubscription = this._router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        filter((event) => event.url !== '/login')
      )
      .subscribe((event) => {
        this.onCreateSurveyPage = Constants.CREATE_SURVEY_REGEX_LINKS.some(
          (linkRegex) => linkRegex.test(event.url)
        );
        let regex = new RegExp(Constants.SHOW_ASSIGN_PROJECT_MANAGER_TAB);
        if (event.url.match(regex)) {
          this.surveyDetailPage = true;
        } else {
          this.surveyDetailPage = false;
        }
        currentUrl = event.url;
        this._buildMenuLinks(event.url);
        this.hideHeader = Constants.EXCLUDE_HEADER_REGEX_LINKS.some(
          (linkRegex) => linkRegex.test(event.url)
        );
        this.isNewSurveyRoute =
          event.url === '/create-surveys' || event.url.includes('/edit');
        this.isLaunchSurveyRoute =
          event.url.includes('/launch-survey') ||
          event.url.includes('/recontact-survey');
        this.isLaunchSurveyRoute = event.url.includes('/launch-survey');
        this.isRecontactSurveyRoute = event.url.includes('/recontact-survey');
      });
    const authServiceSubscription = this._auth.loggedInAsServiceBuyer$
      .pipe(filter((isLoggedIn) => isLoggedIn !== this.isServiceBuyerLoggedIn))
      .subscribe((isLoggedIn) => {
        this.isServiceBuyerLoggedIn = isLoggedIn;
        this._buildMenuLinks(currentUrl);
      });
    this._routerSubscription.add(authServiceSubscription);
    const buyer = this._auth?.user?.buyerAcssLvls !== 'none' ? true : false;
    setTimeout(() => {
      this.enableViewByPM = !!this._auth.buyerConfig?.enableViewByPM || !buyer;
    }, 1000);

    this._routerSubscription.add(
      this._surveyMetaDataService
        .getPdsEnabledData()
        .subscribe((data: IPdsEnable) => {
          this.pdsEnabledObj = data;
        })
    );

    this._routerSubscription.add(
      this._surveyMetaDataService
        .getModularSurveyData()
        .subscribe((isModularSurvey: boolean) => {
          this.isModularSurvey = isModularSurvey;
        })
    );
  }

  removeSeletedBuyer() {
    this._auth.resetServiceBuyerToken();
  }

  ngOnDestroy(): void {
    this._routerSubscription?.unsubscribe();
  }

  private _buildMenuLinks(currentUrl: string) {
    /**
     * Validates in the "entitlements" collection if the user
     * has access to the "CreateSurveys" feature
     */
    this._auth
      .checkUserAccess()
      .pipe(retry(3))
      .subscribe(
        (data: CheckAccessResponse) => {
          this.entitlements = data.Entitlement;
          const hasCreateSurveys = data.Entitlement.some(
            (feature) => feature.featureKey === 'CreateSurveys'
          );
          const onShowCreatePage = Constants.SHOW_SURVEY_REGEX_LINKS.some(
            (linkRegex) => linkRegex.test(currentUrl)
          );
          this.showCreate =
            (hasCreateSurveys || this.isServiceBuyerLoggedIn) &&
            onShowCreatePage;
        },
        (err) => {
          console.error('Failed to check user access', err);
          // reload the page as we can't do much without user information
          this._auth.logout().subscribe(() => {
            this._toastr.error(
              'Please refresh your page and try again.',
              'There was an issue loading your user information.'
            );
            this._router.navigateByUrl('/login');
          });
        }
      );
  }

  goToCreateSurveyPage() {
    this._marketplaceService.resetMultiCountryData();
    this._router.navigateByUrl('/create-surveys');
  }
}
