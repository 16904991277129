import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GetCurrencyResponse,
  GetCurrencyExchangeResponse,
} from '../types/currency-service-interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getCurrencies() {
    return this._http.get<GetCurrencyResponse>(
      `${this._baseUrl}/international_currency/companies`
    );
  }

  getCurrencyExchange(fx: number) {
    return this._http.get<GetCurrencyExchangeResponse>(
      `${this._baseUrl}/international_currency/${fx}?UI=1`
    );
  }
}
