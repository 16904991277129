import {
  HealthCheckCount,
  KPIS,
  McSurveyStats,
} from '../../../../shared/services/buyer-api/survey.interface';
import { Constants } from '../../../../dashboard/dashboard-constants';

export class SurveyHealthCheckMapper<
  T extends { healthCheck?: KPIS[]; mc?: McSurveyStats }
> {
  constructor(private readonly _survey: T) {}

  private _healthCheck(): KPIS[] {
    if (this._survey.mc?.SVP && this._survey.mc?.parent) {
      return this._survey.mc.surveys
        .map((childSurvey) => childSurvey.healthCheck || [])
        .flat();
    }

    return this._survey.healthCheck || [];
  }

  public kpis(): KPIS[] {
    return [
      ...new Map(this._healthCheck().map((kpi) => [kpi.name, kpi])).values(),
    ];
  }

  public count(): HealthCheckCount {
    return this._healthCheck().reduce(
      (acc, { status_text }) => {
        acc[status_text] = acc[status_text] + 1;
        return acc;
      },
      { ...Constants.HEALTH_CHECK.DEFAULT_COUNT }
    );
  }
}
