import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  GetBlockedBuyerResponse,
  PostBlockBuyerResponse,
  UpdateBuyerRequest,
} from '../../shared/services/buyer/buyer-response.interface';
import {
  DeactivateSupplierPayload,
  ManualPriceCardPayload,
  ManualPriceCardResponse,
  DownloadSupplierManualPayload,
} from '../../shared/interfaces/supplier-pricing-card-interface';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  updateBlockedBuyers(data: UpdateBuyerRequest) {
    return this._http.post<PostBlockBuyerResponse>(
      `${this._baseUrl}/updateblockedBuyerList`,
      data
    );
  }

  getBlockedBuyers(id: number) {
    return this._http.get<GetBlockedBuyerResponse>(
      `${this._baseUrl}/blockedBuyerList/${id}`
    );
  }

  manualRateFileUpload(
    languageId: number,
    countryId: number,
    manualPriceCard: ManualPriceCardPayload
  ) {
    return this._http.post<ManualPriceCardResponse>(
      `${this._baseUrl}/supplier/manualPriceCard?language=${languageId}&country=${countryId}`,
      manualPriceCard
    );
  }

  decativateManualRateCard(payload: DeactivateSupplierPayload) {
    return this._http.put<ManualPriceCardResponse>(
      `${this._baseUrl}/supplierPriceCard`,
      payload
    );
  }

  downloadManualRateCard(payload: DownloadSupplierManualPayload) {
    return this._http.get(
      `${this._baseUrl}/supplier/downloadSupplierManualCard?language=${payload.languageId}&country=${payload.countryId}&buyerCounterParty=${payload.buyerId}`,
      { responseType: 'text' }
    );
  }
}
