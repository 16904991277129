import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import {
  ITransactionStatusRequest,
  ISurveyTraffic,
  ISurveyTransactionIn,
  ISurveyTransactionOut,
} from '../qa-tools-service/qa-tools.interface';
import {
  TRANSACTION_STATUSES,
  QUANTITY_OPTIONS,
} from './transactions-tool.constants';
import { QaToolsService } from '../qa-tools-service/qa-tools.service';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { ToasterService } from '@purespectrum1/ui/toaster-service';

@Component({
  selector: 'ps-transactions-tool',
  templateUrl: './transactions-tool.component.html',
  styleUrls: ['./transactions-tool.component.css'],
})
export class TransactionsToolComponent implements OnInit {
  buyerToken: string;
  form!: FormGroup;
  transactionsStatusRequest: ITransactionStatusRequest[] = [];
  quantityOptions: number[] = [];
  selectedTransactions: ITransactionStatusRequest[] = [];
  surveyTrafficTransactions: ISurveyTraffic[] = [];
  submitted: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _qaToolsService: QaToolsService,
    private _toastr: ToasterService
  ) {
    this.transactionsStatusRequest = TRANSACTION_STATUSES;
    this.quantityOptions = QUANTITY_OPTIONS;
    this.buyerToken = this._auth.token!;
  }

  ngOnInit(): void {
    this.form = this._fb.group({
      surveyid: [null, Validators.required],
      selectedTransactions: [null],
    });
  }

  createSurveyTrafficTransactions() {
    this.submitted = true;
    const surveyTransactionIn: ISurveyTransactionIn = {
      survey: this.form.controls.surveyid.value,
      token: this.buyerToken,
      environment:
        environment && environment.production ? 'production' : 'staging',
      transactions: this.selectedTransactions,
    };

    this._qaToolsService
      .createTransactions(surveyTransactionIn)
      .pipe(
        finalize(() => {
          this.submitted = false;
        })
      )
      .subscribe(
        (response: ISurveyTransactionOut) => {
          this.surveyTrafficTransactions = response.transactions;
        },
        (error) => {
          console.log(error);
          this._toastr.error(error.error.detail || error.error.msg);
        }
      );
  }

  onTransactionStatusRequestChange(
    transReq: ITransactionStatusRequest,
    selectedValue: number
  ) {
    transReq.quantity = selectedValue;

    const existingIndex = this.selectedTransactions.findIndex(
      (item) => item.resSt === transReq.resSt
    );
    if (existingIndex !== -1) {
      this.selectedTransactions[existingIndex] = { ...transReq };
    } else {
      this.selectedTransactions.push({ ...transReq });
    }
  }
}
