'use strict';

export const EnableDecipherModalConstants = Object.freeze({
  SURVEY_TYPE: [
    {
      value: 'Main',
    },
    {
      value: 'Many',
    },
  ],
});
