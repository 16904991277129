import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '@purespectrum1/ui/toaster-service';

import { AuthService } from '../auth.service';
import { Constants } from './reset-password.constants';

@Component({
  selector: 'ps-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  resetPassToken: string = '';
  showForm: boolean = false;
  redirectUrls = Constants.INVALID_LINK_REDIRECT_URLS;

  form!: FormGroup;
  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _toastr: ToasterService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      this.resetPassToken = params['token'];
    });
    this.verifyResetToken();
    this.form = this._fb.group(
      {
        newpass: ['', [Validators.required]],
        cpass: ['', [Validators.required]],
      },
      { validators: this.checkPasswords }
    );
  }

  verifyResetToken() {
    this._auth.getUserDataFromResetToken(this.resetPassToken).subscribe(
      (res) => {
        if (
          res.result.reset_link_valid === 1 &&
          res.result.password_reset === 1
        ) {
          if (res.result.lockout_attempt > 3) {
            this._router.navigateByUrl(
              `/invalid-password/${this.redirectUrls.ACCOUNT_LOCKED}`
            );
          } else {
            this.showForm = true;
          }
        } else {
          this._router.navigateByUrl(
            `/invalid-password/${this.redirectUrls.LINK_INVALID}`
          );
        }
      },
      (err) => {
        this._router.navigateByUrl(
          `/invalid-password/${this.redirectUrls.LINK_EXPIRED}`
        );
      }
    );
  }

  resetPassword() {
    this._auth.resetPassword(this.resetPassToken, this.form.value).subscribe(
      (res) => {
        this._toastr.success(res.msg);
        this._router.navigateByUrl('/login');
      },
      (err) => {
        const errorMessage = err.error.msg;
        this._toastr.error(errorMessage);
      }
    );
  }

  checkPasswords: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    let pass = group.get('newpass')?.value;
    let confirmPass = group.get('cpass')?.value;
    return confirmPass !== '' && pass !== confirmPass
      ? { notSame: true }
      : null;
  };
}
