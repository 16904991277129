import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  BulkEditOption,
  FieldConfig,
  BulkEditEvent,
  Config,
  CombinedReport,
} from '../types';
import { OptionsConstants } from '../constants';
import { Constants } from '../../dashboard-constants';
import { ProjectManagers } from 'src/app/operator/user-service/user.interface';
import { BehaviorSubject, iif, Observable, Subject } from 'rxjs';
import { UserService } from '../../../operator/user-service/user.service';
import { AuthService } from '../../../auth/auth.service';
import { ModalService, ToastrService } from '@purespectrum1/ui';
import { CustomizeReportModalComponent } from '@purespectrum1/ui/marketplace';
import { SurveyListing } from 'src/app/shared/services/buyer-api/survey.interface';
import { DashboardSurveyMapper } from '../../domain/dashboard-survey-mapper';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { DEFAULT_CURRENCY_UNIT } from '../../../constants/currency-units';
import { BillingNumberRules } from '@purespectrum1/ui/marketplace/shared/interfaces/billing-rules.interfaces';
import { BillingRulesService } from './bulk-edit-container.service';
import { notifyMessage } from '../../../constants/notify-message';
import { LayoutConstants } from '../../../layout/layout-constants';
import { BulkEditStatusMapper } from '../domain/bulk-edit-status-mapper';
import { INVOICE_TYPE } from '../../../constants/invoice-type';
import { Constants as CreateSurveyConstants } from '../../../create-surveys/create-survey.constants';
import { BuyerApiService } from '../../../shared/services/buyer-api/survey.service';

interface SurveyStatus {
  id: string;
  status: string;
}

@Component({
  selector: 'ps-bulk-edit-container',
  templateUrl: './bulk-edit-container.component.html',
  styleUrls: ['./bulk-edit-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkEditContainerComponent implements OnInit {
  @Input()
  public surveys: SurveyListing[] = [];

  @ViewChild('inputBox') inputBox!: ElementRef;

  @Input()
  public set option(value: BulkEditOption) {
    if (value) {
      this._fieldConfigs[value.type].changeValue = '';
      this.type = value.type;
      this.config = this._fieldConfigs[value.type];
      this.config!.label = value.selectedLabel ?? '';
      this.managerSelected = {} as ProjectManagers;
      this.selectedStatus = 'Change status to';
    }
  }

  public get currencySymbol(): string {
    return (
      this.surveys.find((s) => !!s.currencySymbol)?.currencySymbol ||
      DEFAULT_CURRENCY_UNIT
    );
  }

  @Output()
  public confirmation: EventEmitter<BulkEditEvent> =
    new EventEmitter<BulkEditEvent>();

  @Output()
  public cancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public report = new EventEmitter<CombinedReport>();

  public options = OptionsConstants.OPTIONS;
  public surveyStatus = Constants.SURVEY_STATUS as { [key: string]: string };
  public config?: FieldConfig;
  public type: string = '';
  public selectedStatus: string = 'Change status to';
  public projectManagers$ = new Observable<ProjectManagers[]>();
  public surveyStatusArray!: SurveyStatus[];
  public showDynamicReportPrefOption: boolean = false;
  public disableButton: boolean = false;
  public managerSelected!: ProjectManagers;
  public billingRules?: BillingNumberRules;
  public billingRules$!: Observable<BillingNumberRules>;
  public MAX_CPI_THRESHOLD = LayoutConstants.MAX_CPI_THRESHOLD;
  public AVERAGE_MARGIN = CreateSurveyConstants.AVERAGE_VARIABLE_MARGIN;

  private _fieldConfigs: Config = OptionsConstants.FIELDS;
  private _managers: ProjectManagers[] = [];
  private _surveyDetailsCommand$ = new BehaviorSubject<SurveyListing[]>(
    this.surveys
  );
  private _billingCodeValidator$ = new Subject<string>();

  constructor(
    private _userService: UserService,
    private _auth: AuthService,
    private _modal: ModalService,
    private _billingRulesService: BillingRulesService,
    private _toastr: ToastrService,
    private _buyerApiService: BuyerApiService
  ) {}

  public setFocus() {
    this.inputBox?.nativeElement.focus();
  }

  public ngOnInit(): void {
    const filteredStatus = new BulkEditStatusMapper(
      this._auth.companyConfig.generateInvoice || INVOICE_TYPE.MONTHLY
    ).list();
    this.surveyStatusArray = Object.values(filteredStatus).map(
      (key): SurveyStatus => ({
        id: String(key),
        status: this.surveyStatus[key],
      })
    );

    const company = this._auth.isLoggedInAsServiceBuyer()
      ? this._auth.serviceBuyer!.id
      : this._auth.user!.cmp;

    this.projectManagers$ = iif(
      () => this.type === 'projectManager',
      this._userService.getAllBuyerUser(company, false),
      this._userService
        .getAllOperatorUser(false)
        .pipe(map((response) => response.users))
    ).pipe(
      tap((managers) => {
        this._managers = managers;
      })
    );

    this._setBillingNumberRules();
    this.billingCodeValidation();
    this._surveyDetailsCommand$.next(this.surveys);

    this.showDynamicReportPrefOption =
      this._auth.buyerConfig?.enableDynamicReports ||
      this._auth.userType === 'operator';
  }

  private _setBillingNumberRules(): void {
    this.billingRules$ = this._billingRulesService
      .fetchRules(this._auth.user?.cmp || 0)
      .pipe(
        tap((rules) => {
          if (rules.autoGenerate) {
            this.config!.changeValue = rules.example;
          }
          this.billingRules = rules;
        })
      );
  }

  public showBillingNumberRules() {
    return !!this._auth.buyerConfig?.enableBillingNumberRules;
  }

  public onValidateBillingCode() {
    const billingCode = this.config?.changeValue?.toString() || '';
    this._billingCodeValidator$.next(billingCode);
    return;
  }

  public updateBillingCode(updatedBillingCode: string): void {
    this._billingCodeValidator$.next(updatedBillingCode);
  }

  public showWarningToastr(): void {
    this._toastr.error(
      notifyMessage.errorMessage.BULK_EDIT.PO_NUMBER_SPACES_NOT_ALLOWED,
      notifyMessage.errorMessage.BULK_EDIT.PO_NUMBER_SPACE_NOT_ALLOWER_TITLE
    );
  }

  public searchBuyerSurveys(billingCode: string): Observable<String> {
    return this._buyerApiService.getSurveyAlredyInvoicedWithPONumber(
      billingCode
    );
  }

  public verifyBillingRules(value: string): Observable<BillingNumberRules> {
    return this._buyerApiService.getSurveyBillingRulesVerification(
      value,
      this.billingRules
    );
  }

  public billingCodeValidation(): void {
    this._billingCodeValidator$
      .pipe(
        filter((value) => !!value),
        debounceTime(500),
        switchMap((billingCode) => this.searchBuyerSurveys(billingCode))
      )
      .subscribe({
        error: () => {
          this._toastr.warning(
            notifyMessage.errorMessage.BULK_EDIT.BILLING_CODE_ALREADY_USED
          );
        },
      });
  }

  public onConfirmation(changes: FieldConfig): void {
    const event = {
      action: 'confirm',
      data: changes,
      managers: this._managers,
    };

    if (changes.key === 'billingNumber') {
      if (changes?.changeValue.toString().includes(' ')) {
        this.showWarningToastr();
        return;
      }

      if (!this.billingRules) {
        this.confirmation.emit(event);
        return;
      }

      this.verifyBillingRules(changes.changeValue.toString()).subscribe({
        next: () => {
          this.confirmation.emit(event);
        },
        error: (response) => {
          this._toastr.error(response.error.ps_api_response_message);
        },
      });
    }

    if (changes.key === 'deleteDraft') {
      event.data.changeValue = 'delete';
    }

    this.confirmation.emit(event);
  }

  public onDownloadReport(testTransactions: boolean): void {
    return this.report.emit({
      type: 'buyer',
      surveys: new DashboardSurveyMapper(this.surveys).ids(),
      testTransactions,
    });
  }

  public onDownloadSupplierReport(): void {
    return this.report.emit({
      type: 'supplier',
      surveys: new DashboardSurveyMapper(this.surveys).ids(),
    });
  }

  public onOpenReportConfigs(): void {
    this._modal.open(CustomizeReportModalComponent, {
      data: {
        authState: this._auth.getMarketplaceAuth,
      },
      height: '80%',
      width: '80%',
    });
  }

  public onManagerSelected(selected: ProjectManagers): void {
    this.managerSelected = selected;
    this.config!.changeValue = selected;
  }

  public onCancel(): void {
    if (['cpi', 'billingNumber'].includes(this.config!.key)) {
      this.config!.changeValue = '';
    }
    if (this.config!.key === 'status') {
      this.selectedStatus = 'Change status to';
    }
    if (['accountManager', 'projectManager'].includes(this.config!.key)) {
      this.managerSelected = <ProjectManagers>{};
    }
  }

  public onHandleStatusChange(status: SurveyStatus): void {
    this.config!.changeValue = status.id;
    this.selectedStatus = `${status.status}`;
  }
}
