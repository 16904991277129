<div class="supplier-tabs" [formGroup]="form">
  <div class="form-group">
    <label class="col-md-4 control-label"> Enable SHA1 Hashing Outbound</label>
    <div class="col-md-6">
      <label class="radio-inline">
        <input
          type="radio"
          name="isHashing"
          id="hashing"
          [value]="true"
          formControlName="isHashing"
        />
        Yes
      </label>
      <label class="radio-inline">
        <input
          type="radio"
          name="isHashing"
          [value]="false"
          formControlName="isHashing"
        />
        No
      </label>
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-4 control-label"> Enable SHA1 Hashing Inbound</label>
    <div class="col-md-6">
      <label class="radio-inline">
        <input
          type="radio"
          name="isHashingInbound"
          id="hashingInbound"
          [value]="true"
          formControlName="isHashingInbound"
        />
        Yes
      </label>
      <label class="radio-inline">
        <input
          type="radio"
          name="isHashingInbound"
          [value]="false"
          formControlName="isHashingInbound"
        />
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-4">Shared Private Key</label>
    <div class="col-md-6">
      <input
        name="private_key"
        type="text"
        placeholder="Private Key for Hash"
        class="form-control input-md"
        formControlName="private_key"
      />
    </div>
  </div>
</div>
