<ps-ui-modal size="lg" class="sup-group-modal">
  <h5 psUiModalTitle class="modal-text-title">Supplier Grouping</h5>
  <ps-ui-modal-body>
    <form [formGroup]="form">
      <div>
        <p>Group Name</p>
        <input
          type="text"
          name="name"
          class="form-control"
          placeholder="Enter Name"
          formControlName="group_name"
        />
      </div>
      <div>
        <p>Allocation</p>
        <input
          type="text"
          class="form-control"
          name="allocation_percentage"
          placeholder="Enter Allocation"
          psInputRestriction="[0-9]"
          formControlName="allocation_percentage"
        />
      </div>
      <div>
        <div class="row">
          <div class="col-sm-8">
            <p>Grouped Suppliers</p>
          </div>
          <div class="col-sm-4">
            <label>
              Custom Allocation %
              <ps-ui-slider
                [selected]="form.value.is_custom_allocation"
                (selectedChange)="changeCustomAllocation()"
              ></ps-ui-slider>
            </label>
          </div>
        </div>
        <div class="supplier-div" formArrayName="supplier_id">
          <ng-container
            *ngFor="let supplier of supplier_id.controls; let i = index"
          >
            <div class="row supplier-row" [formGroupName]="i">
              <div class="col-sm-8">
                <button psUiButton class="supplier-button">
                  {{ supplier.value.name }}
                  <i
                    class="fa fa-times-circle-o icon-remove-supplier"
                    (click)="removeSupplierFromGroup(i)"
                  ></i>
                </button>
              </div>
              <div class="col-4">
                <input
                  type="text"
                  max="100"
                  maxlength="3"
                  minlength="1"
                  class="form-control color-patch mb-0"
                  formControlName="allocation"
                  [attr.disabled]="
                    !form.controls.is_custom_allocation.value ? true : null
                  "
                  psInputRestriction="[0-9]"
                />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </ps-ui-modal-body>
  <ps-ui-modal-footer class="blend-modal-footer justify-content-center">
    <button type="button" psUiButton (click)="saveSupplierGroup()">
      {{ edit ? 'Save group for survey' : 'Apply Group to Blend' }}
    </button>
    <span class="save-as-template-span" (click)="saveSupplierGroup(true)">
      Save Supplier Group as Template
    </span>
  </ps-ui-modal-footer>
</ps-ui-modal>
