<section class="adjustment-section">
  <div class="col-12">
    <h4 class="text-center">Adjustments</h4>
  </div>
  <div class="col-12">
    <ps-ui-tab-group (activeIndexChange)="checkActiveTab($event)">
      <ps-ui-tab *ngFor="let tab of list" [label]="tab.title"></ps-ui-tab>
    </ps-ui-tab-group>
    <div class="center-div">
      <div *ngIf="showReconData">
        <ps-adjustment-file-upload
          [userId]="userId"
          [userName]="userName"
          [adjustmentReconciliatonRecord]="adjustmentsReconciliations"
          [operationType]="operationType"
          [showBackToDashboardButton]="true"
        ></ps-adjustment-file-upload>
      </div>
      <div *ngIf="showManualFixData">
        <ps-adjustment-file-upload
          [userId]="userId"
          [userName]="userName"
          [adjustmentManualFixRecord]="adjustmentsManualFix"
          [operationType]="operationType"
          [showBackToDashboardButton]="true"
        ></ps-adjustment-file-upload>
      </div>
    </div>
    <form class="adjustment-form margin-fixed">
      <ps-reconcilation
        [reconciliationList]="adjustmentsReconciliations"
        [userId]="userId"
        [userName]="userName"
        [hidden]="activeTab.title !== 'RECONCILIATION'"
      ></ps-reconcilation>
      <ps-manual-fix
        [manualFixList]="adjustmentsManualFix"
        [userId]="userId"
        [userName]="userName"
        [hidden]="activeTab.title !== 'MANUAL FIX'"
      ></ps-manual-fix>
    </form>
  </div>
</section>
