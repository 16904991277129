import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TabsModule } from '@purespectrum1/ui/tabs';
import { ButtonModule } from '@purespectrum1/ui/button';
import { DropdownModule } from '@purespectrum1/ui/dropdown';
import { Daterangepicker } from 'ng2-daterangepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AuthGuard } from '../../auth/auth.guard';
import { HasRoleModule } from '../../shared/ui/has-role/has-role.module';
import { DownloadReportsComponent } from './download-reports.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { SortTableComponent } from 'src/app/buyer/download-reports/report-sort-table/report-sort-table.component';

import { SortTableModule } from 'src/app/sort-table-directive/sort-table.module';
import { SelectDropdownModule } from '@purespectrum1/ui';
import { LayoutModule } from 'src/app/layout/layout.module';

@NgModule({
  declarations: [DownloadReportsComponent, SortTableComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ReactiveFormsModule,
    ButtonModule,
    Daterangepicker,
    RouterModule.forChild([
      {
        path: 'download-reports',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: DownloadReportsComponent,
          },
        ],
      },
    ]),
    TabsModule,
    InfiniteScrollModule,
    DropdownModule,
    HasRoleModule,
    SortTableModule,
    SelectDropdownModule,
    LayoutModule,
  ],
  providers: [DatePipe],
  schemas: [],
})
export class DownloadReportsModule {}
