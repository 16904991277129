import { Component, Inject } from '@angular/core';
import { ModalData, ModalRef } from '@purespectrum1/ui/modal';
import { GenpopSettingService } from '../genpop-setting.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';

@Component({
  selector: 'ps-genpop-fileupload-modal',
  templateUrl: './genpop-fileupload-modal.component.html',
  styleUrls: ['./genpop-fileupload-modal.component.css'],
})
export class GenpopFileuploadModalComponent {
  constructor(
    @Inject(ModalData) private _modalData: GenpopFileuploadModalData,
    private _modalRef: ModalRef<GenpopFileuploadModalComponent, string>,
    private _genpopSettingService: GenpopSettingService,
    private _toastr: ToasterService
  ) {}

  genpopSelectedQualificationName: string =
    this._modalData.genpopSelectedQualificationName;
  genpopSelectedCountry: string = this._modalData.genpopSelectedCountry;
  genpopSelectedLanguage: string = this._modalData.genpopSelectedLanguage;
  fileToUpload: File = this._modalData.genpopFileToUpload;
  fileUploading: boolean = false;

  uploadFile() {
    let fdata = new FormData();
    const isGlobalFile = this._modalData.genpopGlobalFile;
    fdata.append('file', this.fileToUpload);

    this.fileUploading = true;

    const updateGenpopService = isGlobalFile
      ? this._genpopSettingService.updateGenpopGlobalFile(fdata)
      : this._genpopSettingService.updateGenpopFile(fdata);

    updateGenpopService.subscribe(
      (result) => {
        this.fileUploading = false;
        this._toastr.success(result.msg);
        this.close();
      },
      (err) => {
        this.fileUploading = false;
        this._toastr.error(err.error.msg);
        this.close();
      }
    );
  }

  close() {
    this._modalRef.close();
  }
}

interface GenpopFileuploadModalData {
  genpopSelectedQualificationName: string;
  genpopSelectedCountry: string;
  genpopSelectedLanguage: string;
  genpopFileToUpload: File;
  genpopGlobalFile: boolean;
}
