import { Component } from '@angular/core';
import { Constants } from './reports-dashboard-constants';

@Component({
  selector: 'ps-reports-dashboard',
  templateUrl: './reports-dashboard.component.html',
  styleUrls: ['./reports-dashboard.component.css'],
})
export class ReportsDashboardComponent {
  constructor() {}

  public reportsdashboardSettingsTabs: Tab[] = Constants.REPORTS_DASHBOARD;
  public activeTabIndex: number = 0;
  public activeTab: Tab =
    this.reportsdashboardSettingsTabs[this.activeTabIndex];

  setActiveTab(activeTabIndex: number) {
    this.activeTabIndex = activeTabIndex;
    this.activeTab = this.reportsdashboardSettingsTabs[activeTabIndex];
  }
}

interface Tab {
  title: string;
  key: string;
  active: boolean;
}
