<div class="supplier-tabs" [formGroup]="form">
  <div class="heading-tab text-center">
    <p>
      You can use this tool to map our our URL variables to yours, so that your
      survey system recieve and return them back on redirect.
    </p>
  </div>
  <div class="row variable-mapping-first-row">
    <div class="col-md-4 offset-md-3">
      <b>{{ userCmpName }} calls these...</b>
    </div>
    <div class="col-md-5"><b>PureSpectrum recieves these back as...</b></div>
  </div>
  <div class="row variable-mapping-row align-items-center">
    <div class="col-md-1 tooltip-visible">
      <div class="tootip-setting-info">
        <i aria-hidden="true" class="fa fa-info-circle"></i>
        <p>
          Transaction ID is a required variable. We use this to match the
          respondents when they return from your survey.
        </p>
      </div>
    </div>
    <div class="col-md-2">
      <input
        class="m-1 form-check-input"
        type="checkbox"
        [attr.disabled]="true"
        formControlName="transactionIdCb"
      />Transaction ID
    </div>
    <div class="col-md-4">
      <input
        name="transactionIdOut"
        type="text"
        placeholder="transaction_id "
        class="form-control input-md"
        formControlName="transactionIdOut"
        maxlength="36"
      />
    </div>
    <div class="col-md-5">
      <input
        name="transactionIdIn"
        type="text"
        placeholder="transaction_id "
        class="form-control input-md"
        formControlName="transactionIdIn"
        maxlength="36"
        [attr.disabled]="true"
      />
    </div>
  </div>
  <div class="row variable-mapping-row align-items-center">
    <div class="col-md-1 tooltip-visible">
      <div class="tootip-setting-info">
        <i aria-hidden="true" class="fa fa-info-circle"></i>
        <p>
          This is first optional parameter(transaction_id2) for buyer which will
          contain the transaction_id
        </p>
      </div>
    </div>
    <div class="col-md-2">
      <input
        class="m-1 form-check-input"
        type="checkbox"
        formControlName="psTransactionId2Cb"
      />Transaction ID 2
    </div>
    <div class="col-md-4">
      <input
        name="psTransactionId2Out"
        type="text"
        placeholder="transaction_id2 "
        class="form-control input-md"
        formControlName="psTransactionId2Out"
        maxlength="36"
      />
    </div>
    <div class="col-md-5">
      <input
        name="psTransactionId2In"
        type="text"
        placeholder="transaction_id "
        class="form-control input-md"
        formControlName="psTransactionId2In"
        maxlength="36"
        [attr.disabled]="true"
      />
    </div>
  </div>
  <div class="row variable-mapping-row align-items-center">
    <div class="col-md-1 tooltip-visible">
      <div class="tootip-setting-info">
        <i aria-hidden="true" class="fa fa-info-circle"></i>
        <p>
          This is second optional parameter(transaction_id3) for buyer which
          will contain the transaction_id
        </p>
      </div>
    </div>
    <div class="col-md-2">
      <input
        class="m-1 form-check-input"
        type="checkbox"
        formControlName="psTransactionId3Cb"
      />Transaction ID 3
    </div>
    <div class="col-md-4">
      <input
        name="psTransactionId3Out"
        type="text"
        placeholder="transaction_id3 "
        class="form-control input-md"
        formControlName="psTransactionId3Out"
        maxlength="36"
      />
    </div>
    <div class="col-md-5">
      <input
        name="psTransactionId3In"
        type="text"
        placeholder="transaction_id "
        class="form-control input-md"
        formControlName="psTransactionId3In"
        maxlength="36"
        [attr.disabled]="true"
      />
    </div>
  </div>
  <div class="row variable-mapping-row align-items-center">
    <div class="col-md-1 tooltip-visible">
      <div class="tootip-setting-info">
        <i aria-hidden="true" class="fa fa-info-circle"></i>
        <p>
          This is third optional parameter(transaction_id4) for buyer which will
          contain the transaction_id
        </p>
      </div>
    </div>
    <div class="col-md-2">
      <input
        class="m-1 form-check-input"
        type="checkbox"
        formControlName="psTransactionId4Cb"
      />Transaction ID 4
    </div>
    <div class="col-md-4">
      <input
        name="textinput"
        type="text"
        placeholder="transaction_id4 "
        class="form-control input-md"
        formControlName="psTransactionId4Out"
        maxlength="36"
      />
    </div>
    <div class="col-md-5">
      <input
        name="textinput"
        type="text"
        placeholder="transaction_id "
        class="form-control input-md"
        formControlName="psTransactionId4In"
        maxlength="36"
        [attr.disabled]="true"
      />
    </div>
  </div>
  <div class="row variable-mapping-row align-items-center">
    <div class="col-md-1 tooltip-visible">
      <div class="tootip-setting-info">
        <i aria-hidden="true" class="fa fa-info-circle"></i>
        <p>
          This is the persistent PureSpectrum Respondent ID. We use this to
          validate the respondent when they are returned to us.
        </p>
      </div>
    </div>
    <div class="col-md-2">
      <input
        class="m-1 form-check-input"
        type="checkbox"
        formControlName="psIdCb"
      />PSID
    </div>
    <div class="col-md-4">
      <input
        name="psIdOut"
        type="text"
        placeholder="psid "
        class="form-control input-md"
        formControlName="psIdOut"
        maxlength="36"
      />
    </div>
    <div class="col-md-5">
      <input
        name="psIdIn"
        type="text"
        placeholder="psid "
        class="form-control input-md"
        formControlName="psIdIn"
        maxlength="36"
        [attr.disabled]="true"
      />
    </div>
  </div>
  <div class="row variable-mapping-row align-items-center">
    <div class="col-md-1 tooltip-visible">
      <div class="tootip-setting-info">
        <i aria-hidden="true" class="fa fa-info-circle"></i>
        <p>Send survey_id to buyer</p>
      </div>
    </div>
    <div class="col-md-2">
      <input
        type="checkbox"
        formControlName="psSurveyIdCb"
        class="ps_hash_cb m-1 form-check-input"
      />Survey ID
    </div>
    <div class="col-md-4">
      <input
        name="psSurveyIdOut"
        type="text"
        placeholder="survey_id "
        class="form-control input-md"
        formControlName="psSurveyIdOut"
        maxlength="36"
      />
    </div>
  </div>
  <div class="row variable-mapping-row align-items-center">
    <div class="col-md-1 tooltip-visible">
      <div class="tootip-setting-info">
        <i aria-hidden="true" class="fa fa-info-circle"></i>
        <p>Send ps_supplier_id to buyer</p>
      </div>
    </div>
    <div class="col-md-2">
      <input
        type="checkbox"
        formControlName="psSupplierIdCb"
        class="ps_hash_cb m-1 form-check-input"
      />Supplier ID
    </div>
    <div class="col-md-4">
      <input
        name="psSupplierIdOut"
        type="text"
        placeholder="ps_supplier_id "
        class="form-control input-md"
        formControlName="psSupplierIdOut"
        maxlength="36"
      />
    </div>
  </div>
  <div class="row variable-mapping-row align-items-center">
    <div class="col-md-1 tooltip-visible">
      <div class="tootip-setting-info">
        <i aria-hidden="true" class="fa fa-info-circle"></i>
        <p>Send supplier_grouping_name to buyer</p>
      </div>
    </div>
    <div class="col-md-3">
      <input
        type="checkbox"
        formControlName="psSupplierGroupNameCb"
        class="ps_hash_cb m-1 form-check-input"
      />Supplier Group Name
    </div>
    <div class="col-md-3">
      <input
        name="psSurveyrGroupNameOut"
        type="text"
        placeholder="supplier_grouping_name "
        class="form-control input-md"
        formControlName="psSupplierGroupNameOut"
        maxlength="30"
      />
    </div>
  </div>
  <div class="row variable-mapping-row align-items-center">
    <div class="col-md-1 tooltip-visible">
      <div class="tootip-setting-info">
        <i aria-hidden="true" class="fa fa-info-circle"></i>
        <p>
          This is the status code for complete, termination, overquota, or
          security.
        </p>
      </div>
    </div>
    <div class="col-md-2">
      <input
        class="m-1 form-check-input"
        type="checkbox"
        [attr.disabled]="true"
        formControlName="statusCb"
      />Status
    </div>
    <div class="col-md-5 offset-md-4">
      <input
        name="statusIn"
        type="text"
        placeholder="status "
        class="form-control input-md"
        formControlName="statusIn"
        maxlength="36"
        [attr.disabled]="true"
      />
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col-md-1 tooltip-visible">
      <div class="tootip-setting-info">
        <i aria-hidden="true" class="fa fa-info-circle"></i>
        <p>
          This is the security hash that is generated using the Transaction ID,
          PSID, and Status.
        </p>
      </div>
    </div>
    <div class="col-md-2">
      <input
        type="checkbox"
        formControlName="psHashCb"
        class="ps_hash_cb m-1 form-check-input"
      />Hash
    </div>
    <div class="col-md-5 offset-md-4">
      <input
        name="psHashIn"
        type="text"
        placeholder="ps_hash "
        class="form-control input-md"
        formControlName="psHashIn"
        maxlength="36"
        [attr.disabled]="true"
      />
    </div>
  </div>

  <div class="row variable-mapping-row align-items-center">
    <div class="col-md-1 tooltip-visible">
      <div class="tootip-setting-info">
        <i aria-hidden="true" class="fa fa-info-circle"></i>
        <p>Send hashed_id to buyer</p>
      </div>
    </div>
    <div class="col-md-2">
      <input
        class="m-1 form-check-input"
        type="checkbox"
        formControlName="psHashedIdCb"
      />Hashed ID
    </div>
    <div class="col-md-4">
      <input
        name="psHashedIdOut"
        type="text"
        placeholder="hashed_id"
        class="form-control input-md"
        formControlName="psHashedIdOut"
        maxlength="36"
      />
    </div>
    <div class="col-md-5">
      <input
        name="psHashedIdIn"
        type="text"
        placeholder="hashed_id"
        class="form-control input-md"
        formControlName="psHashedIdIn"
        maxlength="36"
        [attr.disabled]="true"
      />
    </div>
  </div>
</div>
