<div class="row">
  <div class="col-sm-6 col-md-4 form-group">
    <label class="p-1">Buyer</label>
    <ps-ui-select-dropdown
      [dropdownItems]="externalBuyers"
      [field]="'name'"
      [enableSearch]="false"
      labelNone="Select Buyer"
      [label]="selectedExternalBuyer?.name"
      (selectedItem)="selectBuyer($event)"
    >
    </ps-ui-select-dropdown>
  </div>
  <div class="flex-column" *ngIf="selectedExternalBuyer">
    <div class="d-flex justify-content-center">
      <input
        #fileUpload
        hidden
        type="file"
        class="file-upload"
        accept=".xlsx,.xls,.csv"
        (change)="uploadMappings($event)"
      />
      <button
        type="button"
        class="btn btn-link upload-button"
        (click)="fileUpload.value = ''; fileUpload.click()"
      >
        Upload a file for {{ selectedExternalBuyer.name }}
      </button>
    </div>
    <div class="d-flex justify-content-center">.csv, .xlsx, .xlx</div>
    <div class="d-flex justify-content-center p-3">
      <a
        type="button"
        class="btn btn-link download-button"
        (click)="downloadSampleMappings()"
      >
        Download Template
      </a>
    </div>
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-link download-button"
        (click)="downloadMappings()"
      >
        Download Mappings CSV
      </button>
    </div>
  </div>
</div>
