import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToasterService } from '@purespectrum1/ui/toaster-service';

import { notifyMessage } from '../../../constants/notify-message';
import { AuthService } from '../../../auth/auth.service';
import { SupplierService } from '../../supplier-service/supplier.service';
import { SupplierPricingCardService } from '../supplier-pricing-card-service/supplier-pricing-card.service';
import { DeactivateSupplierPayload } from '../../../shared/interfaces/supplier-pricing-card-interface';
import { SupplierPriceCard } from '../supplier-pricing-card-service/supplier-pricing-card.interface';

@Component({
  selector: 'ps-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.css'],
})
export class FormulaComponent implements OnChanges {
  @Input() countryId: number = 1;
  @Input() languageId: number = 1;
  @Input() buyerId: number = 0;
  @Input() deactivateFormulaPriceCard: boolean = false;
  @Input() supplierPriceCard!: SupplierPriceCard;
  @Output() updateSupplierPriceCard = new EventEmitter();
  form!: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _toastr: ToasterService,
    private _supplierService: SupplierService,
    private _priceCardService: SupplierPricingCardService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.supplierPriceCard) {
      this.supplierPriceCard = changes.supplierPriceCard.currentValue;
      this._generateForm();
    }

    if (changes.deactivateFormulaPriceCard?.currentValue) {
      this.deactivateFormulaPriceCard =
        changes.deactivateFormulaPriceCard.currentValue;
    }
  }

  private _generateForm() {
    const { id: user_id, cmp: supplier_id } = this._auth.user!;
    this.form = this._fb.group({
      buyerCounterParty: [this.buyerId],
      language: [this.languageId],
      country: [this.countryId],
      company: [supplier_id],
      userId: [user_id],
      EPCM: [this.supplierPriceCard?.EPCM || '', [Validators.required]],
      EPCFloor: [this.supplierPriceCard?.EPCFloor || '', [Validators.required]],
      minimumPrice: [
        this.supplierPriceCard?.minimumPrice || '',
        [Validators.required],
      ],
      minimumPricePerMinute: [
        this.supplierPriceCard?.minimumPricePerMinute || '',
      ],
    });
  }

  public deactivatePriceCard() {
    const payload: DeactivateSupplierPayload = {
      language: this.languageId,
      country: this.countryId,
      buyerCounterParty: this.buyerId,
    };
    this._supplierService.decativateManualRateCard(payload).subscribe(
      (res) => {
        this.updateSupplierPriceCard.emit();
        this._toastr.success(res.msg);
      },
      (err) => {
        this._toastr.error(err.error.msg);
      }
    );
  }

  public cancel() {
    this._clearPriceValues();
    this.updateSupplierPriceCard.emit();
  }

  public save() {
    this.form.patchValue({
      buyerCounterParty: this.buyerId,
      language: this.languageId,
      country: this.countryId,
    });

    this._priceCardService.saveSupplierPriceCard(this.form.value).subscribe(
      (response) => {
        this.updateSupplierPriceCard.emit();
        this._toastr.success(
          notifyMessage.successMessage.SUPPLIER_RATE_CARD.FORMULA_SAVED_SUCCESS
        );
      },
      (error) => {
        this._toastr.error(
          notifyMessage.errorMessage.SUPPLIER_RATE_CARD.FORMULA_SAVED_ERROR
        );
      }
    );
  }

  private _clearPriceValues(): void {
    this.form.patchValue({
      EPCM: '',
      EPCFloor: '',
      minimumPrice: '',
      minimumPricePerMinute: '',
    });
  }
}
