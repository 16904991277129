import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[psWatchVisibility]',
})
export class WatchVisibilityDirective
  implements OnInit, OnDestroy, AfterViewInit
{
  @Output() visible = new EventEmitter<HTMLElement>();

  private _observer?: IntersectionObserver;

  constructor(private _element: ElementRef) {}

  ngOnInit(): void {
    this._createObserver();
  }

  ngAfterViewInit(): void {
    this._startObserving();
  }

  ngOnDestroy(): void {
    this._observer?.disconnect();
  }

  private _createObserver() {
    const isIntersecting = (entry: IntersectionObserverEntry) =>
      entry.isIntersecting || entry.intersectionRatio > 0;

    this._observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (isIntersecting(entry)) {
          const target = entry.target as HTMLElement;
          this.visible.next(target);
          observer.unobserve(target);
        }
      });
    });
  }

  private _startObserving() {
    this._observer?.observe(this._element.nativeElement);
  }
}
