import { NgModule } from '@angular/core';

import { WatchVisibilityDirective } from './watch-visibility.directive';

@NgModule({
  imports: [],
  exports: [WatchVisibilityDirective],
  declarations: [WatchVisibilityDirective],
  providers: [],
})
export class WatchVisibilityModule {}
