import { Component, Inject, OnInit } from '@angular/core';
import { ModalData, ModalRef } from '@purespectrum1/ui/modal';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { DecipherService } from '../../../shared/services/decipher/decipher.service';
import { notifyMessage } from '../../../constants/notify-message';
import { ConfigSettingsModalData } from '../config-settings.component';
import { CommonService } from '../../../../../src/app/shared/services/common-service/common-service';
import {
  BuyerURIRecords,
  DecipherURIObject,
} from 'src/app/shared/services/decipher/decipher.interface';

import {
  EachCountry,
  EachLanguage,
} from '../../../shared/interfaces/common-service.interface';
import { EnableDecipherModalConstants } from './enable-decipher-modal-constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'ps-enable-decipher-modal',
  templateUrl: './enable-decipher-modal.component.html',
  styleUrls: ['./enable-decipher-modal.component.css'],
})
export class EnableDecipherModalComponent implements OnInit {
  enableDecipherMultiPath: boolean = false;
  countryList: Array<EachCountry> = [];
  selectedCountry?: EachCountry;
  languages: Array<EachLanguage> = [];
  selectedLanguage?: EachLanguage;
  decipherURIs: DecipherURIObject[] = [];
  selectedDecipherURI: DecipherURIObject = { value: '' };
  supplierTypes: CommonDecipherObjectType[] =
    EnableDecipherModalConstants.SURVEY_TYPE;
  selectedSupplierType: CommonDecipherObjectType = { value: '' };
  language_country = '';
  buyerURIRecords: BuyerURIRecords[] = [];
  id = '';
  decipherForm!: FormGroup;
  uriForm!: FormGroup;

  constructor(
    @Inject(ModalData) private _modalData: ConfigSettingsModalData,
    private _modalRef: ModalRef<EnableDecipherModalComponent, string>,
    private _toastr: ToasterService,
    private _decipherService: DecipherService,
    private _commomServices: CommonService,
    private _fb: FormBuilder
  ) {
    this.uriForm = this._generateURIForm(this._fb);
    this.decipherForm = this._generateDecipherForm(this._fb);
  }

  ngOnInit(): void {
    (async () => {
      await Promise.all([this._getCountries(), this._getDecipherURIList()]);
      await this._getDecipherData();
    })();
  }

  close() {
    this._modalRef.close();
  }

  private _generateURIForm(fb: FormBuilder): FormGroup {
    return fb.group({
      newUri: ['', Validators.required],
      decipherCloud: ['', Validators.required],
      apiToken: ['', Validators.required],
    });
  }
  private _generateDecipherForm(fb: FormBuilder): FormGroup {
    return fb.group({
      directoryName: ['', Validators.required],
      vendor: ['', Validators.required],
    });
  }
  public setEnableDecipherMultiPath() {
    this.enableDecipherMultiPath = !this.enableDecipherMultiPath;
  }

  selectCountry(country: EachCountry) {
    this.languages = country.lang;
    this.selectedCountry = country;
  }

  selectLanguage = (language: EachLanguage) => {
    if (!this.selectedCountry) {
      return;
    }
    if (!language.selected) {
      language.selected = true;
    }
    this.selectedLanguage = language;
    this.language_country = `${language.transalte_code}_${this.selectedCountry.short_Code}`;
  };

  selectDecipherURI(decipherURI: CommonDecipherObjectType) {
    this.selectedDecipherURI = decipherURI;
    let obj = this.buyerURIRecords.find(
      (o: BuyerURIRecords) => o.uri === this.selectedDecipherURI.value
    );
    if (obj) {
      this.uriForm.controls.newUri.setValue(obj.uri);
      this.uriForm.controls.decipherCloud.setValue(obj.decipherHost);
      this.uriForm.controls.apiToken.setValue(obj.apiKey);
      this.id = obj.id || '';
    } else {
      this.uriForm.controls.newUri.setValue(this.selectedDecipherURI.value);
      this.uriForm.controls.decipherCloud.setValue('');
      this.uriForm.controls.apiToken.setValue('');
    }
  }

  selectSupplierType(supplierType: CommonDecipherObjectType) {
    this.selectedSupplierType = supplierType;
  }

  _getCountries() {
    return new Promise<void>((resolve) => {
      const addAllOption = true;
      this._commomServices
        .getCountries(addAllOption)
        .subscribe((response: Array<EachCountry>) => {
          this.countryList = response;
          resolve();
        });
    });
  }

  async _getDecipherURIList() {
    try {
      const response = await this._decipherService
        .getDecipherURIList()
        .toPromise();
      this.decipherURIs = response.data;
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this._toastr.error(notifyMessage.errorMessage.API_ERROR);
      }
      throw e;
    }
  }
  async checkMultiPathCanBeEnableOrNot() {
    if (this.enableDecipherMultiPath) {
      const uriValue = this.uriForm.value.decipherCloud;
      const cloud = uriValue.substring(
        uriValue.indexOf('://') + 3,
        uriValue.indexOf('.com') + 4
      );
      try {
        await this._decipherService
          .getMultiPathAccess(cloud, this.uriForm.value.apiToken)
          .toPromise();
        return true;
      } catch (error) {
        return false;
      }
    }
    return true;
  }
  async saveDecipherData() {
    const isMultiPathEnabled = await this.checkMultiPathCanBeEnableOrNot();
    const { buyerId } = this._modalData;
    if (isMultiPathEnabled) {
      const decipherMetaData = {
        ...this.decipherForm.value,
        cmp: buyerId,
        defaultLocale: this.language_country,
        multiPath: this.enableDecipherMultiPath,
        selectedSupplierType: this.selectedSupplierType.value,
        selectedUri: this.selectedDecipherURI.value,
      };
      if (!this.checkAllFilledFields()) {
        this._toastr.warning(notifyMessage.warningMessage.INCOMPLETE_FIELDS);
        return;
      }
      try {
        await this._decipherService
          .addDecipherSettings(buyerId, decipherMetaData)
          .toPromise();
        this.close();
        this._toastr.success(notifyMessage.successMessage.SUCCESSFULL_SAVED);
      } catch (error) {
        this._toastr.error(notifyMessage.errorMessage.API_ERROR);
      }
    } else {
      this._toastr.error(notifyMessage.errorMessage.STAFF_ACCESS_REQUIRED);
    }
  }

  saveDecipherURI() {
    const { buyerId } = this._modalData;
    const uriData = {
      uri: this.uriForm.value['newUri'],
      apiKey: this.uriForm.value['apiToken'],
      decipherHost: this.uriForm.value['decipherCloud'],
    };
    if (!this.uriForm.valid) {
      this._toastr.warning(notifyMessage.warningMessage.INCOMPLETE_FIELDS);
      return;
    }
    this._decipherService.addDecipherURI(buyerId, uriData).subscribe(
      (response) => {
        this.buyerURIRecords.push(response.data);
        this.decipherURIs.push({ value: this.uriForm.value['newUri'] });
        this._toastr.success(notifyMessage.successMessage.SUCCESSFULL_SAVED);
      },
      (error) => {
        this._toastr.error(notifyMessage.errorMessage.API_ERROR);
      }
    );
  }

  updateDecipherURI() {
    const uriData = {
      uri: this.uriForm.value['newUri'],
      apiKey: this.uriForm.value['apiToken'],
      decipherHost: this.uriForm.value['decipherCloud'],
    };
    const { id, uri } =
      this.buyerURIRecords.find(
        (record: BuyerURIRecords) => record.uri === this.uriForm.value['newUri']
      ) ?? {};
    if (id) {
      this.id = id;
    }
    if (!this.uriForm.valid) {
      this._toastr.warning(notifyMessage.warningMessage.INCOMPLETE_FIELDS);
      return;
    }
    this._decipherService.updateDecipherURI(this.id, uriData).subscribe(
      (response) => {
        this._getDecipherURIList();
        this._toastr.success(notifyMessage.successMessage.SUCCESSFULL_SAVED);
      },
      (error) => {
        this._toastr.error(notifyMessage.errorMessage.API_ERROR);
      }
    );
  }
  _getDecipherData() {
    return new Promise<void>((resolve) => {
      const { buyerId } = this._modalData;
      this._decipherService.getDecipherSettings(buyerId).subscribe(
        (response) => {
          if (!response.metaData) {
            resolve();
          }
          this.buyerURIRecords = response.metaData
            .buyerURIRecords as BuyerURIRecords[];
          this.decipherForm.controls.directoryName.setValue(
            response.metaData.directoryName
          );
          this.enableDecipherMultiPath = response.metaData.multiPath;
          this.selectedSupplierType.value =
            response.metaData.selectedSupplierType;
          this.selectDecipherURI({ value: response.metaData.selectedUri });
          this.selectedDecipherURI.value = response.metaData.selectedUri;
          this.decipherForm.controls.vendor.setValue(response.metaData.vendor);
          if (response.metaData.defaultLocale || response.metaData.locale) {
            const locale =
              response.metaData.defaultLocale || response.metaData.locale;
            const langLocation = locale.split('_');
            const country = this.countryList.find(
              (location) => location.pl_code === langLocation[1]
            )!;
            this.selectCountry(country);
            const language = country.lang.find(
              (lang) => lang.transalte_code === langLocation[0]
            );
            this.selectLanguage(language!);
          }
          resolve();
        },
        (error) => {
          this._toastr.error(notifyMessage.errorMessage.API_ERROR);
        }
      );
    });
  }

  checkAllFilledFields(): boolean {
    let obj = this.buyerURIRecords.find(
      (o: BuyerURIRecords) => o.uri === this.selectedDecipherURI.value
    );

    return this.decipherForm.valid &&
      this.language_country &&
      this.selectedDecipherURI.value &&
      obj
      ? true
      : false;
  }
}

export interface CommonDecipherObjectType {
  value: string;
}
