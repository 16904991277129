import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  BuyerService,
  UploadFileResponse,
} from '../../shared/services/buyer/buyer.service';
import { CompanyService } from '../../shared/services/company/company.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import {
  BuyersListing,
  SuppliersListing,
} from '../programmatic-direct-settings/programmatic-direct-settings.interface';
import { BuyerRateCardSupplier } from './buyer-rate-card.interface';
import { GetBuyerRateCardResponse } from '../../shared/interfaces/buyer.interface';
import { CommonService } from '../../shared/services/common-service/common-service';
import {
  CountryInterface,
  LanguageInterface,
} from '../../shared/interfaces/create-surveys.interface';
import { Constants } from '../operator-settings-constants';
import { OperatorSettingsService } from '../operator-settings.service';
import { notifyMessage } from '../../constants/notify-message';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'ps-buyer-rate-card',
  templateUrl: './buyer-rate-card.component.html',
  styleUrls: ['./buyer-rate-card.component.css'],
})
export class BuyerRateCardComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  private _buyerRateCardTemplateInfo = Constants.BUYER_RATE_CARD;
  public buyers: BuyersListing[] = [];
  public countries: CountryInterface[] = [];
  public selectedCountry?: CountryInterface;
  public selectedLanguage?: LanguageInterface;
  public selectedLocale!: string;
  public languages: LanguageInterface[] = [];
  public suppliers: SuppliersListing[] = [];
  public selectedBuyer!: BuyersListing;
  public buyerRateCards!: GetBuyerRateCardResponse;
  private _rateCardFormData: FormData = new FormData();
  public newBuyerRateCardSupplier: BuyerRateCardSupplier = {
    editable: false,
  } as BuyerRateCardSupplier;
  private _oldHasBuyerRateCard: boolean = true;
  private _allSuppliers = {
    id: 0,
    isASupplier: true,
    name: 'All',
  };

  constructor(
    private _buyerService: BuyerService,
    private _companyService: CompanyService,
    private _commonService: CommonService,
    private _operatorSettingsService: OperatorSettingsService,
    private _auth: AuthService,
    private _toastr: ToasterService
  ) {}

  ngOnInit(): void {
    this._getBuyers();
    this._getCountries();
  }

  private _getBuyers() {
    const $getBuyers = this._buyerService.getBuyers().subscribe(
      (buyerResponse) => {
        this.buyers = buyerResponse.company;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add($getBuyers);
  }

  private _getCountries() {
    const countries = this._commonService.getCountries().subscribe(
      (countriesData) => {
        this.countries = countriesData;
      },
      (err) => {
        this._toastr.error(err.error.msg);
      }
    );
    this._subscriptions.add(countries);
  }

  selectCountry(country: CountryInterface) {
    this.selectedLanguage = undefined;
    this.selectedCountry = country;
    this.languages = country.lang;
  }

  selectLanguage = (language: LanguageInterface) => {
    if (!this.selectedCountry) {
      this.selectedLanguage = undefined;
      return;
    }
    this.selectedLanguage = language;
    this.selectedLocale = `${language.transalte_code} - ${this.selectedCountry.short_Code}`;
    this._getBuyerRateCards();
  };

  public selectBuyer(buyer: BuyersListing) {
    this.clearForm();
    if (buyer) {
      this.selectedBuyer = buyer;
      this.resetAddSupplier();
    }
  }

  clearForm() {
    this.selectedLanguage = undefined;
    this.selectedCountry = {} as CountryInterface;
    this.selectedBuyer = {} as BuyersListing;
  }

  private _getBuyerRateCards() {
    const $getRateCards = this._buyerService
      .getBuyerRateCard(
        this.selectedBuyer.id,
        this.selectedCountry?.id,
        this.selectedLanguage?.id
      )
      .subscribe(
        (response: GetBuyerRateCardResponse) => {
          this.buyerRateCards = response;
          const supplierIds: number[] = [];
          this.buyerRateCards?.suppliers?.forEach((supplier, index) => {
            supplier.editable = false;
            if (!supplier.id) {
              this.buyerRateCards?.suppliers.splice(index, 1);
              supplier.id = this._allSuppliers.id;
              supplier.name = this._allSuppliers.name;
              this.buyerRateCards?.suppliers.unshift(supplier);
            }
            supplierIds.push(supplier.id);
          });
          this._getSuppliers(supplierIds);
        },
        (error) => {
          this._toastr.error(error.error.msg);
          this._getSuppliers([]);
        }
      );
    this._subscriptions.add($getRateCards);
  }

  private _getSuppliers(supplierIds: number[]) {
    const $getSuppliers = this._companyService
      .getCompaniesSimplified()
      .subscribe(
        (response) => {
          this.suppliers = response.filter((company) => {
            return company.isASupplier;
          });
          this.suppliers.unshift(this._allSuppliers);
          this.suppliers = this.suppliers.filter(
            (supp) => !supplierIds.includes(supp.id)
          );
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add($getSuppliers);
  }

  public selectSupplier(supplier: SuppliersListing) {
    this.newBuyerRateCardSupplier.id = supplier.id;
    this.newBuyerRateCardSupplier.name = supplier.name;
    if (this.buyerRateCards) {
      const supplierRateCard = this.buyerRateCards?.suppliers?.find(
        (supp) => supp.id === this.newBuyerRateCardSupplier?.id
      );
      this.newBuyerRateCardSupplier.hasBuyerRateCard =
        supplierRateCard?.hasBuyerRateCard || false;
    }
  }

  public resetAddSupplier() {
    this.newBuyerRateCardSupplier = {
      editable: false,
    } as BuyerRateCardSupplier;
  }

  public editSupplier(supplier: BuyerRateCardSupplier) {
    const index = this.buyerRateCards?.suppliers?.findIndex(
      (sup) => sup.editable === true
    );
    if (index !== -1) {
      this.buyerRateCards.suppliers[index].editable = false;
      this.buyerRateCards.suppliers[index].hasBuyerRateCard =
        this._oldHasBuyerRateCard;
    }
    supplier.editable = true;
    this.newBuyerRateCardSupplier = {
      editable: false,
    } as BuyerRateCardSupplier;
    this._oldHasBuyerRateCard = supplier.hasBuyerRateCard;
  }

  public addNewBuyerRateCardRow() {
    this.newBuyerRateCardSupplier.editable = true;
    this.buyerRateCards?.suppliers?.map((sup) => (sup.editable = false));
  }

  public resetEditSupplier(supplier: BuyerRateCardSupplier) {
    supplier.editable = false;
    supplier.hasBuyerRateCard = this._oldHasBuyerRateCard;
  }

  uploadFile(event: Event, supplier: BuyerRateCardSupplier) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isValidFile = this._operatorSettingsService.validateFileExtention(
      file,
      Constants.FILES_FILTER_LIST
    );
    if (this.selectedCountry && this.selectedLanguage && isValidFile) {
      this._rateCardFormData = new FormData();
      this._rateCardFormData.append('file', file);
      this._rateCardFormData.append(
        'buyerId',
        this.selectedBuyer.id.toString()
      );
      this._rateCardFormData.append('supplierId', supplier.id.toString());
      this._rateCardFormData.append(
        'countryId',
        this.selectedCountry.id.toString()
      );
      this._rateCardFormData.append(
        'languageId',
        this.selectedLanguage.id.toString()
      );
    }
  }

  addNewBuyerRateCard(supplier: BuyerRateCardSupplier) {
    if (!supplier) {
      return this._toastr.error('Please select a supplier.');
    }
    if (!this._rateCardFormData || !this._rateCardFormData.get('file')) {
      return this._toastr.error('Please select a file.');
    }
    supplier._id
      ? this.updateBuyerRateCardFile(supplier._id, supplier.name)
      : this.addBuyerRateCardFile(supplier.name);
  }

  addBuyerRateCardFile(supplierName: string) {
    const uploadFile$ = this._buyerService
      .uploadBuyerRateCardFile(this._rateCardFormData)
      .subscribe(
        (_response: UploadFileResponse) => {
          this._toastr.success(
            `Uploaded buyer rate card file for ${supplierName}`
          );
          this._rateCardFormData = new FormData();
          this.newBuyerRateCardSupplier = {
            editable: false,
          } as BuyerRateCardSupplier;
          this._getBuyerRateCards();
        },
        (err: { error: { error: string } }) => {
          const message =
            err.error.error ?? notifyMessage.errorMessage.SERVER_ERROR;
          this._toastr.error(message);
        }
      );
    this._subscriptions.add(uploadFile$);
  }

  updateBuyerRateCardFile(rateCardId: string, supplierName: string) {
    const updateFile$ = this._buyerService
      .updateBuyerRateCardFile(this._rateCardFormData, rateCardId)
      .subscribe(
        (_response: UploadFileResponse) => {
          this._toastr.success(
            `Uploaded buyer rate card file for ${supplierName}`
          );
          this._rateCardFormData = new FormData();
          this.newBuyerRateCardSupplier = {
            editable: false,
          } as BuyerRateCardSupplier;
          this._getBuyerRateCards();
        },
        (err: { error: { error: string } }) => {
          const message =
            err.error.error ?? notifyMessage.errorMessage.SERVER_ERROR;
          this._toastr.error(message);
        }
      );
    this._subscriptions.add(updateFile$);
  }

  deleteBuyerRateCard(supplierName: string, rateCardId?: string) {
    if (rateCardId) {
      const deleteRateCard$ = this._buyerService
        .deleteBuyerRateCardFile(rateCardId)
        .subscribe(
          (_response: UploadFileResponse) => {
            this._toastr.success(
              `Buyer rate card for ${supplierName} has been deleted.`
            );
            this.newBuyerRateCardSupplier = {
              editable: false,
            } as BuyerRateCardSupplier;
            this._getBuyerRateCards();
          },
          (err: { error: { error: string } }) => {
            const message =
              err.error.error ?? notifyMessage.errorMessage.SERVER_ERROR;
            this._toastr.error(message);
          }
        );
      this._subscriptions.add(deleteRateCard$);
    }
    return true;
  }

  downloadBuyerRateCard(supplierId: number) {
    window.location.assign(
      this._buyerService.downloadBuyerRateCardFile(
        this.selectedBuyer.id,
        supplierId,
        this.selectedCountry?.id,
        this.selectedLanguage?.id,
        this._auth?.token
      )
    );
  }

  downloadBuyerRateCardTemplate() {
    window.open(this._buyerRateCardTemplateInfo.templatePath, '_blank');
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
