import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, timeout } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { BuyerSalesReportResponse } from './buyer-sales-report.interface';

@Injectable({
  providedIn: 'root',
})
export class BuyerSalesReportService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  getBuyerSalesReport(
    dataRange: { startDate: any; endDate: any },
    country_code: any,
    supplrId: any
  ) {
    return this._http
      .get<BuyerSalesReportResponse>(
        `${this._baseUrl}/getBuyerSalesReport?startDate=${dataRange.startDate}&endDate=${dataRange.endDate}&country_code=${country_code}&supplrId=${supplrId}`
      )
      .pipe(
        timeout(300000),
        map((response) => response.result)
      );
  }
}
