import { UserFilter } from '../survey-search/survey-search.component';
import { DashboardChannelData } from '../types';
import { DashboardPagination } from './dashboard-pagination';
import { DashboardPreferenceState } from './dashboard-preference';
import { DashboardTab } from './dashboard-tab';
import { SurveyListing } from '../../shared/services/buyer-api/survey.interface';
import {
  BuyerProgressChannelV3,
  SurveyStatusChange,
} from 'src/app/shared/services/socket/socket.interface';
import { DashboardSelectedSurveys } from './dasboard-survey-selected';
import { EachCountry } from '../../shared/interfaces/common-service.interface';
import { CompanyInfo } from '../../shared/interfaces/company.interface';
import { SurveyStatus } from 'src/app/utils/survey-status';

type DashboardStateParams = {
  preferences?: DashboardPreferenceState;
  pagination?: DashboardPagination;
  countries?: EachCountry[];
  surveys?: SurveyListing[];
  selected?: DashboardSelectedSurveys;
  company?: CompanyInfo;
};

export class DashboardState {
  constructor(
    public readonly preferences: DashboardPreferenceState,
    public readonly pagination: DashboardPagination,
    public readonly countries: EachCountry[],
    public readonly surveys: SurveyListing[],
    public readonly selected: DashboardSelectedSurveys,
    public readonly company?: CompanyInfo
  ) {}

  public static empty(): DashboardState {
    return new DashboardState(
      DashboardPreferenceState.none(),
      DashboardPagination.first(),
      [],
      [],
      DashboardSelectedSurveys.empty()
    );
  }

  public static initial(
    page: number = 1,
    selected?: DashboardSelectedSurveys,
    status?: SurveyStatus | string
  ): DashboardState {
    return new DashboardState(
      DashboardPreferenceState.none(),
      DashboardPagination.page(page, status),
      [],
      [],
      selected ?? DashboardSelectedSurveys.empty()
    );
  }

  public nextPage(): DashboardPagination {
    return this.pagination.next();
  }

  public concat(surveys: SurveyListing[]): DashboardState {
    if (this.pagination.page === 1) {
      return this.copyWith({ surveys });
    }

    return this.copyWith({ surveys: [...this.surveys, ...surveys] });
  }

  public canLoad(tab: DashboardTab): boolean {
    if (tab.count === 0) {
      return true;
    }

    return this.surveys.length <= tab.count;
  }

  public copyWith(params: DashboardStateParams): DashboardState {
    return new DashboardState(
      params.preferences ?? this.preferences,
      params.pagination ?? this.pagination,
      params.countries ?? this.countries,
      params.surveys ?? this.surveys,
      params.selected ?? this.selected,
      params.company ?? this.company
    );
  }

  public filter(): UserFilter {
    return this.pagination.view.filter;
  }

  public statusChange(
    data: SurveyStatusChange,
    tab: DashboardTab
  ): DashboardState {
    if (Number(tab.status) === data.oldStatus) {
      return this.delete([data.surveyId]);
    }

    if (tab.status === 'all' && data.newStatus) {
      const index = this.surveys.findIndex(
        (survey) => survey.surveyId === data?.surveyId
      );

      if (index !== -1) {
        this.surveys[index].status = data.newStatus;
      }

      return this.copyWith({ surveys: [...this.surveys] });
    }

    return this.copyWith({});
  }

  public progressChange(data: BuyerProgressChannelV3): DashboardState {
    const index = this.surveys.findIndex(
      (survey) => survey.surveyId === data?.surveyId
    );

    if (index !== -1 && data?.hasOwnProperty('actualBuyerInProgress')) {
      this.surveys[index].inProgress = data.actualBuyerInProgress;
    }

    return this.copyWith({ surveys: [...this.surveys] });
  }

  public dataChange(data: DashboardChannelData): DashboardState {
    const surveys = this.surveys.map((survey) => {
      return survey.surveyId === data.surveyId
        ? { ...survey, ...data }
        : survey;
    });

    return this.copyWith({ surveys });
  }

  public delete(ids: number[]): DashboardState {
    const surveys = this.surveys.filter(
      (survey) => !ids.some((id) => id === survey.surveyId)
    );

    return this.copyWith({ surveys: surveys });
  }

  public select(selection: DashboardSelectedSurveys): DashboardState {
    return this.copyWith({ selected: selection });
  }

  public contains(surveyId: number): boolean {
    return this.surveys.some((survey) => survey.surveyId === surveyId);
  }
}
