import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';

/**
 * Directive for automatically showing and hiding content based on the user's config settings
 * Like advance targeting questions can be added only when buyer has advanceTarget config settings enabled
 * Similar to *ngIf="config === advanceTarget" but without needing to pull in the auth service
 */
@Directive({
  selector: '[psConfigEnabled]',
})
export class ConfigEnabledDirective {
  private _hasView = false;

  @Input() set psConfigEnabled(config: string | string[]) {
    const configSettings = Array.isArray(config) ? config : [config];
    if (
      this._isConfigEnabled(configSettings, this._authService.buyerConfig) &&
      !this._hasView
    ) {
      this._viewContainer.createEmbeddedView(this._templateRef);
      this._hasView = true;
    } else if (
      !this._isConfigEnabled(configSettings, this._authService.buyerConfig) &&
      this._hasView
    ) {
      this._viewContainer.clear();
      this._hasView = false;
    }
  }

  constructor(
    private _authService: AuthService,
    private _viewContainer: ViewContainerRef,
    private _templateRef: TemplateRef<any>
  ) {}

  private _isConfigEnabled(configSettings: string[], buyerConfig: any) {
    return configSettings.some(
      (configSetting: string) => buyerConfig[configSetting]
    );
  }
}
