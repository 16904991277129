import { Component } from '@angular/core';
import { IrGeneratorChatService } from './ir-generator-chat.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';

interface Chat {
  type: boolean;
  value: string;
}

@Component({
  selector: 'ps-ir-generator-chat',
  templateUrl: './ir-generator-chat.component.html',
  styleUrls: ['./ir-generator-chat.component.css'],
})
export class IrGeneratorChatComponent {
  chatObject: Chat[] = [];
  question: string = '';

  constructor(
    private _irGeneratorChatService: IrGeneratorChatService,
    private _toastr: ToasterService
  ) {}

  askChatGPT(question: string) {
    this.chatObject = [
      ...this.chatObject,
      {
        type: true,
        value: question,
      },
    ];

    this._irGeneratorChatService.sendQuestion(question).subscribe({
      next: (response) => {
        this.chatObject = [
          ...this.chatObject,
          {
            type: false,
            value: response.response,
          },
        ];
      },
      error: (error) => {
        this._toastr.error('Chat GPT is unavailable to respond', error);
      },
    });
    this.question = '';
  }
}
