<main class="container">
  <div>
    <h2 class="text-center">IMPORT SURVEY FROM DECIPHER</h2>
  </div>
  <form [formGroup]="form">
    <div class="row survey_form" *ngIf="decipherSettings?.multiPath">
      <div class="col-md-4 form-group">
        <label>URI</label>
        <ps-ui-select-dropdown
          #uriDropdown
          [dropdownItems]="decipherURI"
          [field]="'value'"
          labelNone="Select URI"
          formControlName="decipherURI"
          (selectedItem)="getDirectory()"
        >
        </ps-ui-select-dropdown>
      </div>
      <div class="col-md-8 form-group">
        <label>DIRECTORY</label>
        <ps-ui-select-dropdown
          #directoryDropdown
          [dropdownItems]="directory"
          [field]="'name'"
          labelNone="Select Directory"
          formControlName="surveyDirectory"
          (selectedItem)="getSurveys($event)"
        >
        </ps-ui-select-dropdown>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="row survey_form">
      <div class="col-md-8 form-group">
        <label>SURVEY TITLE</label>
        <ps-ui-select-dropdown
          #surveyDropdown
          [dropdownItems]="decipherSurveys"
          [field]="'title'"
          labelNone="Select Title"
          formControlName="selectedSurvey"
          (selectedItem)="patchFormBySurvey($event)"
        >
        </ps-ui-select-dropdown>
      </div>
      <div class="col-md-4 form-group">
        <label>CATEGORY</label>
        <ps-ui-select-dropdown
          #categoryDropdown
          [dropdownItems]="categories"
          [field]="'name'"
          labelNone="Select Category"
          formControlName="selectedCategory"
          (selectedItem)="categoryDropdownSearch?.searchTextControl.reset()"
        >
        </ps-ui-select-dropdown>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="row survey_form double-line">
      <div class="col-md-4 col-lg-2 form-group">
        <label>COUNTRY</label>
        <ps-ui-select-dropdown
          #countryDropdown
          [dropdownItems]="countries"
          [multi]="multiSelect"
          [field]="'name'"
          (selectedItem)="getLanguages($event)"
          labelNone="Select Country"
          formControlName="selectedCountries"
          (selectedItem)="countryDropdownSearch?.searchText.reset()"
        >
        </ps-ui-select-dropdown>
      </div>
      <div class="col-md-4 col-lg-2 form-group">
        <label>LANGUAGE</label>
        <ps-ui-select-dropdown
          #languageDropdown
          [dropdownItems]="languages"
          [field]="'name'"
          labelNone="Select Language"
          [disabled]="multiSelect"
          formControlName="selectedLanguage"
          (selectedItem)="languageDropdownSearch?.searchText.reset()"
        >
        </ps-ui-select-dropdown>
      </div>
      <div class="col-md-4 col-lg-2 form-group completes-box">
        <label for="completes">Completes</label>
        <input
          class="form-control survey-style"
          type="number"
          name="numberOfCompletes"
          placeholder="0"
          formControlName="numberOfCompletes"
        />
      </div>
      <div class="col-md-4 col-lg-2 form-group place_holder">
        <label
          >Length of <br />
          Survey</label
        >
        <input
          class="form-control survey-style"
          type="number"
          name="lengthOfSurvey"
          formControlName="lengthOfSurvey"
        />
        <span>mins</span>
      </div>
      <div class="col-md-4 col-lg-2 form-group place_holder">
        <label>Incidence Rate</label>
        <input
          class="form-control survey-style"
          type="number"
          name="incidence"
          formControlName="incidence"
        />
        <span>%</span>
      </div>
      <div class="col-md-4 col-lg-2 form-group place_holder">
        <label for="field_time">Field Time</label>
        <input
          class="form-control border-less-input survey-style"
          type="number"
          name="fieldTime"
          formControlName="fieldTime"
        />
        <span>days</span>
      </div>
    </div>
  </form>
  <div *ngIf="form.value.selectedSurvey">
    <span
      class="text-center red error_size"
      *ngIf="form.controls.numberOfCompletes.errors?.min"
      >Completes must be greater then zero!</span
    >&nbsp;
    <span
      class="text-center red error_size"
      *ngIf="form.controls.lengthOfSurvey.errors?.min"
      >Length of Survey must be greater then zero!</span
    >&nbsp;
    <span
      class="text-center red error_size"
      *ngIf="form.controls.incidence.errors?.min"
      >Incidence Rate must be greater then zero!</span
    >&nbsp;
    <span
      class="text-center red error_size"
      *ngIf="form.controls.fieldTime.errors?.min"
      >Field Time must be greater then zero!</span
    >&nbsp;
    <span
      class="text-center red error_size"
      *ngIf="form.controls.numberOfCompletes.errors?.integerError"
      >Only Integers allowed in Completes!</span
    >&nbsp;
    <span
      class="text-center red error_size"
      *ngIf="form.controls.lengthOfSurvey.errors?.integerError"
      >Only Integers allowed in Length of Survey!</span
    >&nbsp;
    <span
      class="text-center red error_size"
      *ngIf="form.controls.incidence.errors?.integerError"
      >Only Integers allowed in Incidence Rate!</span
    >&nbsp;
    <span
      class="text-center red error_size"
      *ngIf="form.controls.numberOfCompletes.errors?.max"
      >Required Completes should be less than or equal to 1,00,000</span
    >
  </div>
</main>

<ps-conditions-mapping
  *ngIf="form.valid"
  [surveyMetadata]="form.value"
  [decipherSettings]="decipherSettings"
  [selectedSurvey]="selectedSurvey"
></ps-conditions-mapping>
