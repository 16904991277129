import {
  ChangeSurveyModalData,
  ChangeSurveyModalTemplate,
  ChangeSurveyModalTemplateData,
} from './change-survey-modal.template';

import { Constants } from '../../dashboard-constants';

export class StatusChangeModalTemplate implements ChangeSurveyModalTemplate {
  private _modalCloseBtnText: { [key: string]: string } =
    Constants.STATUS_CHANGE_MODAL.MODAL_CLOSE_BTN_TEXT;
  constructor(private _data: ChangeSurveyModalData) {}

  getData(): ChangeSurveyModalTemplateData {
    return {
      title: 'PROJECT STATUS CHANGE',
      body: `Save Survey #${this._data.surveyId} as ${this._data.status}?`,
      subtext: 'Note that Pause Threshold will be updated to',
      close: this._modalCloseBtnText[this._data.status],
    };
  }
}
