import { GetSurveysQueryParams } from '../../shared/services/buyer-api/survey.interface';
import { SurveyStatus } from '../../utils/survey-status';
import { SearchView } from '../survey-search/survey-search.component';

type DashboardPaginationParams = {
  page?: number;
  UI?: 1 | 0;
  status?: SurveyStatus | string;
  limit?: number;
  search?: string;
  targeting?: number;
  isServiceBuyerRequest?: boolean;
  mobile?: boolean;
  buyerId?: number;
  view?: SearchView;
};

export const EMPTY_VIEW = {
  option: 0,
  filter: {
    current: false,
    search: false,
  },
  managers: { buyer: undefined, serviceOperator: undefined },
};

export class DashboardPagination {
  constructor(
    public page: number,
    public UI: 1 | 0,
    public status: SurveyStatus | string = String(SurveyStatus.Live),
    public limit: number = 20,
    public search: string = '',
    public targeting: number = 0,
    public isServiceBuyerRequest: boolean = false,
    public mobile: boolean = false,
    public buyerId: number = 0,
    public view: SearchView = EMPTY_VIEW
  ) {}

  public static first(): DashboardPagination {
    return new DashboardPagination(1, 1);
  }

  public static page(
    page: number,
    status: SurveyStatus | string = SurveyStatus.Live
  ): DashboardPagination {
    return new DashboardPagination(page, 1, status);
  }

  public request(): GetSurveysQueryParams {
    return {
      UI: this.UI,
      page: this.page,
      search: this.search,
      status: this.status,
      targeting: this.targeting,
      limit: this.limit,
      buyerId: this.buyerId !== 0 ? this.buyerId : undefined,
      projectManagerId: this.view.managers.serviceOperator,
      buyerProjectManagerId: this.view.managers.buyer,
      isServiceBuyerRequest: this.isServiceBuyerRequest,
      mobile: this.mobile,
    };
  }

  public next(): DashboardPagination {
    return this.copyWith({ page: this.page + 1 });
  }

  public copyWith(params: DashboardPaginationParams): DashboardPagination {
    return new DashboardPagination(
      params.page ?? this.page,
      params.UI ?? this.UI,
      params.status ?? this.status,
      params.limit ?? this.limit,
      params.search ?? this.search,
      params.targeting ?? this.targeting,
      params.isServiceBuyerRequest ?? this.isServiceBuyerRequest,
      params.mobile ?? this.mobile,
      params.buyerId ?? this.buyerId,
      params.view ?? this.view
    );
  }
}
