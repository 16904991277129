import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';

import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';

import { CompanyService } from '../../../shared/services/company/company.service';
import { TransactionByStatusReportService } from './transaction-by-status-report.service';

import { Constants } from '../../../constants/trans-status';

import {
  EachSupplier,
  GetAllSuppliersRequest,
} from 'src/app/shared/interfaces/company.interface';

import {
  Transaction,
  SelectedStatus,
  MultiSelectedStatus,
  ValueDate,
} from './transaction-by-status-report.interface';

@Component({
  selector: 'ps-transaction-by-status-report',
  templateUrl: './transaction-by-status-report.component.html',
  styleUrls: ['./transaction-by-status-report.component.css'],
})
export class TransactionByStatusReportComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  transactionByStatusData: Array<Transaction> = [];
  surveyId: string = '';
  statusOptions: MultiSelectedStatus[] = Constants.TRANS_STATUS_LIST.map(
    (status) => ({
      ...status,
      // replace underscores by spaces for better UI readability
      name: status.name.replace(/_/g, ' '),
      selected: true,
    })
  );
  suppliers: EachSupplier[] = [];
  selectedSupplier!: EachSupplier;
  selectedStatus: Array<SelectedStatus> = Constants.TRANS_STATUS_LIST.map(
    ({ id }) => ({ id })
  );

  daterange = {
    start: { _d: Date.now() },
    end: { _d: Date.now() },
    label: '',
  };

  options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };

  constructor(
    private _toastr: ToasterService,
    private _companyService: CompanyService,
    private _transactionByStatusReportService: TransactionByStatusReportService,
    private _datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this._getSuppliers();
  }

  private _getSuppliers() {
    const request: GetAllSuppliersRequest = {
      supplier_type: 'public',
      isBuyr: false,
    };
    const suppliersResponse$ = this._companyService
      .getAllSuppliers(request)
      .subscribe(
        (resp) => {
          this.suppliers = resp;
          this.suppliers.splice(0, 0, {
            name: 'ALL',
            id: 0,
            isABuyer: false,
            isExpose: false,
            isNotify: false,
            supp_url_completes: '',
            supp_url_overquota: '',
            supp_url_terms: '',
          });
          this.selectedSupplier = this.suppliers[0];
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add(suppliersResponse$);
  }

  selectStatus(event: Array<MultiSelectedStatus>) {
    this.selectedStatus = event.map((status: MultiSelectedStatus) => {
      return { id: status.id };
    });
  }

  changeSurveyId(id: string) {
    this.surveyId = id;
  }

  selectSupplier(supplier: EachSupplier) {
    this.selectedSupplier = supplier;
  }

  selectedDate(value: ValueDate) {
    this.daterange.start = value.start;
    this.daterange.end = value.end;
  }

  getReport() {
    const payload = {
      startDate: this._datepipe.transform(
        this.daterange.start._d,
        'yyyy-MM-dd'
      ),
      endDate: this._datepipe.transform(this.daterange.end._d, 'yyyy-MM-dd'),
    };

    const _isRequiredMessage = 'is required. Please $0 to continue';
    const isRequiredAddMessage = _isRequiredMessage.replace('$0', 'add');
    const isRequiredSelectMessage = _isRequiredMessage.replace('$0', 'select');
    if (!this.surveyId) {
      this._toastr.error(`Survey ID ${isRequiredAddMessage}`);
      return;
    }
    if (!/\d+/g.test(this.surveyId)) {
      this._toastr.error('Survey ID must be a number');
      return;
    }
    if (!this.selectedStatus || !this.selectedStatus.length) {
      this._toastr.error(`Survey Respondent Status ${isRequiredSelectMessage}`);
      return;
    }
    if (!this.selectedSupplier) {
      this._toastr.error(`Supplier ${isRequiredSelectMessage}`);
      return;
    }
    if (!payload.startDate || !payload.endDate) {
      this._toastr.error(`Date Range ${isRequiredSelectMessage}`);
      return;
    }

    const reportData = this._transactionByStatusReportService
      .getTransactionByStatusReport(
        this.surveyId,
        this.selectedSupplier.id,
        this.selectedStatus,
        payload
      )
      .subscribe(
        (transactions) => {
          this.transactionByStatusData = transactions;
        },
        (error) => {
          this._toastr.error(error?.error.msg || error.msg.message);
        }
      );
    this._subscriptions.add(reportData);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
