import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SupplierReportService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getSuppliersReport(payloadObj: SuppliersReportPayload) {
    return this._http.get<SuppliersReportResponse>(
      `${this._baseUrl}/getSuppliersReport?startDate=${payloadObj.startDate}&endDate=${payloadObj.endDate}&country_code=${payloadObj.countryCode}&buyerId=${payloadObj.buyerId}`
    );
  }
}

export interface SuppliersReportPayload {
  startDate: string | null;
  endDate: string | null;
  countryCode: string;
  buyerId: number;
}

export interface Payload {
  statusCode: number;
  supplierRevenue: number;
  Supplier_name: string;
  total: number;
}

export interface Result {
  _id: number;
  Payload: Payload[];
  supplierName: string;
  supplierRevenue: number;
  completes: number;
  validClicks: number;
  totalClicks: number;
  epc: number;
  totalEpc: number;
}

export interface SuppliersReportResponse {
  apiStatus: string;
  result: Result[];
}
