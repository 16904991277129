import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterTheGenpopTable' })
export class GenpopSettingsPipe implements PipeTransform {
  transform(items: any, textToFilter: any, firstField: any, secondField: any) {
    if (!items) {
      return [];
    }
    if (!textToFilter) {
      return items;
    }
    textToFilter = textToFilter.toLowerCase();
    if (firstField) {
      return items.filter((item: any) => {
        return (
          item[firstField].toLowerCase().includes(textToFilter) ||
          item[secondField].toString().toLowerCase().includes(textToFilter)
        );
      });
    }
    return items.filter((item: any) => {
      return item.toLowerCase().includes(textToFilter);
    });
  }
}
