<div class="row margin-form-settings">
  <div class="col-sm-6 col-md-4 form-group">
    <label>Buyer</label>
    <ps-ui-select-dropdown
      [dropdownItems]="buyers"
      [field]="'name'"
      [enableSearch]="true"
      labelNone="Select Buyer"
      [label]="selectedBuyer?.name"
      (selectedItem)="selectBuyer($event)"
    >
    </ps-ui-select-dropdown>
  </div>
  <div class="col-sm-6 col-md-4 form-group">
    <label>Supplier</label>
    <ps-ui-select-dropdown
      [dropdownItems]="suppliers"
      [field]="'name'"
      [enableSearch]="true"
      labelNone="Select Supplier"
      [label]="selectedSupplier?.name"
      (selectedItem)="selectSupplier($event)"
    >
    </ps-ui-select-dropdown>
  </div>
  <div class="col-sm-6 col-md-4 form-group">
    <label>Margin</label>
    <input
      name="margin_value"
      type="number"
      class="form-control input-md"
      [(ngModel)]="margin"
    />
  </div>
</div>
<div class="row">
  <div class="col-sm-12 col-md-12 margin-setting-buttons text-center mt-6">
    <button type="submit" psUiButton color="primary" (click)="submitMargin()">
      Submit
    </button>
  </div>
</div>
<div class="row">
  <div class="margin-setting-buttons col-md-6 mt-6">
    <button
      id="dashboard"
      type="button"
      psUiButton
      color="primary"
      [routerLink]="['/dashboard/']"
    >
      Back to Dashboard
    </button>
  </div>
</div>
