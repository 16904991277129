import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';

import { CommonService } from '../../../shared/services/common-service/common-service';
import { CompanyService } from '../../../shared/services/company/company.service';
import { BuyerSalesReportService } from './buyer-sales-report.service';

import { EachCountry } from '../../../shared/interfaces/common-service.interface';
import {
  DateRange,
  EachSalesData,
  SalesTableData,
  TotalSalesTableData,
} from './buyer-sales-report.interface';
import {
  EachSupplier,
  GetAllSuppliersRequest,
} from 'src/app/shared/interfaces/company.interface';
import { Constants } from '../../../constants/trans-status';
import { notifyMessage } from '../../../constants/notify-message';

@Component({
  selector: 'ps-buyer-sales-report',
  templateUrl: './buyer-sales-report.component.html',
  styleUrls: ['./buyer-sales-report.component.css'],
})
export class BuyerSalesReportComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public countries: EachCountry[] = [];
  public selectedCountry!: EachCountry;
  public suppliers: EachSupplier[] = [];
  public selectedSupplier!: EachSupplier;
  public salesData: EachSalesData[] = [];
  public dateRange: DateRange = {
    start: { _d: Date.now() },
    end: { _d: Date.now() },
  };
  public tableData: SalesTableData[] = [];
  public tableTotal!: TotalSalesTableData;
  public options = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };

  constructor(
    private _toastr: ToasterService,
    private _commonService: CommonService,
    private _companyService: CompanyService,
    private _buyerSalesReportService: BuyerSalesReportService,
    private _datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this._getCountries();
    this._getSuppliers();
  }

  private _getCountries() {
    const addAllOption = true;
    const countriesResponse$ = this._commonService
      .getCountries(addAllOption)
      .subscribe(
        (resp) => {
          this.countries = resp;
          this.selectedCountry = this.countries[0];
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add(countriesResponse$);
  }

  private _getSuppliers() {
    const request: GetAllSuppliersRequest = {
      supplier_type: 'public',
      isBuyr: false,
    };
    const suppliersResponse$ = this._companyService
      .getAllSuppliers(request)
      .subscribe(
        (resp) => {
          this.suppliers = resp;
          this.suppliers.splice(0, 0, {
            name: 'ALL',
            id: 0,
            isABuyer: false,
            isExpose: false,
            isNotify: false,
            supp_url_completes: '',
            supp_url_overquota: '',
            supp_url_terms: '',
          });
          this.selectedSupplier = this.suppliers[0];
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add(suppliersResponse$);
  }

  selectCountry(country: EachCountry) {
    this.selectedCountry = country;
  }

  selectSupplier(supplier: EachSupplier) {
    this.selectedSupplier = supplier;
  }

  public selectDate(value: DateRange) {
    this.dateRange = {
      start: value.start,
      end: value.end,
    };
  }

  getReport() {
    if (this.dateRange && this.dateRange.start && this.dateRange.end) {
      const dateRange = {
        startDate: this._datepipe.transform(
          this.dateRange.start._d,
          'yyyy-MM-dd'
        ),
        endDate: this._datepipe.transform(this.dateRange.end._d, 'yyyy-MM-dd'),
      };
      const getBuyerSalesReportResponse$ = this._buyerSalesReportService
        .getBuyerSalesReport(
          dateRange,
          this.selectedCountry.short_Code,
          this.selectedSupplier.id
        )
        .subscribe(
          (response) => {
            this.tableData = <SalesTableData[]>[];
            this.tableTotal = {
              buyerRevenue: 0,
              completes: 0,
              totalClicks: 0,
              validClicks: 0,
              epc: 0,
              totalEpc: 0,
            };
            this.salesData = response;
            for (const eachSale of this.salesData) {
              let buyerName = '';
              let buyerRevenue = 0;
              let completes = 0;
              let totalClicks = 0;
              let validClicks = 0;
              let epc = 0;

              for (const eachSaleTrans of eachSale.Payload) {
                buyerName = eachSaleTrans.buyerName;
                if (
                  eachSaleTrans.statusCode ===
                  Constants.TRANSACTION_STATUS.COMPLETE
                ) {
                  buyerRevenue = eachSaleTrans.buyerRevenue;
                  completes = eachSaleTrans.total;
                }
                if (
                  [
                    Constants.TRANSACTION_STATUS.BUYER_SIDE_IN_PROGRESS,
                    Constants.TRANSACTION_STATUS.BUYER_QUOTAFULL,
                    Constants.TRANSACTION_STATUS.BUYER_TERMINATION,
                    Constants.TRANSACTION_STATUS.BUYER_DROP,
                    Constants.TRANSACTION_STATUS.BUYER_QUALITY_TERMINATION,
                    Constants.TRANSACTION_STATUS.COMPLETE,
                    Constants.TRANSACTION_STATUS.BUYER_SECURITY,
                    Constants.TRANSACTION_STATUS.BUYER_HASH_SECURITY,
                    Constants.TRANSACTION_STATUS.BUYER_RECONCILIATION_REJECT,
                  ].includes(eachSaleTrans.statusCode)
                ) {
                  totalClicks += eachSaleTrans.total;
                }
                if (
                  eachSaleTrans.statusCode ===
                    Constants.TRANSACTION_STATUS.COMPLETE ||
                  eachSaleTrans.statusCode ===
                    Constants.TRANSACTION_STATUS.BUYER_TERMINATION ||
                  eachSaleTrans.statusCode ===
                    Constants.TRANSACTION_STATUS.BUYER_QUOTAFULL ||
                  eachSaleTrans.statusCode ===
                    Constants.TRANSACTION_STATUS.BUYER_DROP
                ) {
                  validClicks += eachSaleTrans.total;
                }
                epc = Math.round((buyerRevenue / validClicks) * 100) / 100;
              }

              this.tableData.push({
                buyerName: buyerName,
                buyerRevenue: Math.round(buyerRevenue * 100) / 100,
                completes: completes,
                validClicks: validClicks,
                totalClicks: totalClicks,
                epc: epc,
                totalEpc: Math.round((buyerRevenue / totalClicks) * 100) / 100,
              });
              this.tableTotal.buyerRevenue +=
                Math.round(buyerRevenue * 100) / 100;
              this.tableTotal.completes += completes;
              this.tableTotal.validClicks += validClicks;
              this.tableTotal.totalClicks += totalClicks;
            }
            this.tableTotal.epc +=
              Math.round(
                (this.tableTotal.buyerRevenue / this.tableTotal.validClicks) *
                  100
              ) / 100;
            this.tableTotal.totalEpc +=
              Math.round(
                (this.tableTotal.buyerRevenue / this.tableTotal.totalClicks) *
                  100
              ) / 100;
            this.tableTotal.buyerRevenue = parseFloat(
              this.tableTotal.buyerRevenue.toFixed(2)
            );
          },
          (error) => {
            this._toastr.error(error.error.msg);
          }
        );
      this._subscriptions.add(getBuyerSalesReportResponse$);
    } else {
      this._toastr.error(notifyMessage.errorMessage.INVALID_DATE_RANGE);
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
