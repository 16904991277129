import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { EachCountry } from '../../../../../src/app/shared/interfaces/common-service.interface';
import { CommonService } from '../../../../../src/app/shared/services/common-service/common-service';
import { SurveyPerformanceResportService } from './survey-performance-report.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import {
  GetCompaniesAllDataResponse,
  GetDateFrom,
  GetSurveyPerformanceReportResponse,
  GetSurveyPerformanceReportResult,
  MultiSelectedBuyer,
  MultiSelectedStatus,
  Payload,
  SelectedBuyer,
  SelectedStatus,
} from './survey-performance-reporta.interface';
import { Constants } from '../../../../app/constants/trans-status';
import { notifyMessage } from '../../../../app/constants/notify-message';

@Component({
  selector: 'ps-survey-performance-report',
  templateUrl: './survey-performance-report.component.html',
  styleUrls: ['./survey-performance-report.component.css'],
})
export class SurveyPerformanceReportComponent implements OnInit {
  surveyStatusOptions = [
    {
      name: 'Live',
      id: 22,
    },
    {
      name: 'Paused',
      id: 33,
    },
    {
      name: 'Closed',
      id: 44,
    },
    {
      name: 'Invoice',
      id: 55,
    },
  ];

  private _validClicksStatusId: Array<number> = [
    Constants.TRANSACTION_STATUS.BUYER_QUOTAFULL,
    Constants.TRANSACTION_STATUS.BUYER_TERMINATION,
    Constants.TRANSACTION_STATUS.BUYER_DROP,
    Constants.TRANSACTION_STATUS.COMPLETE,
  ];
  private _validBuyerClickStatusId: Array<number> = [
    Constants.TRANSACTION_STATUS.BUYER_QUOTAFULL,
    Constants.TRANSACTION_STATUS.BUYER_TERMINATION,
    Constants.TRANSACTION_STATUS.BUYER_DROP,
    Constants.TRANSACTION_STATUS.BUYER_QUALITY_TERMINATION,
    Constants.TRANSACTION_STATUS.COMPLETE,
    Constants.TRANSACTION_STATUS.BUYER_SECURITY,
  ];

  options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };

  countryList: Array<EachCountry> = [];

  buyerList: Array<MultiSelectedBuyer> = [];

  startDate: string = this.datepipe.transform(new Date(), 'yyyy-MM-dd')!;
  endDate: string = this.datepipe.transform(new Date(), 'yyyy-MM-dd')!;
  countrySelected?: EachCountry;
  selectedBuyersList: Array<SelectedBuyer> = [];
  selectedStatus: Array<SelectedStatus> = [];
  surveyPerformanceData: Array<GetSurveyPerformanceReportResult> = [];

  constructor(
    private _surveyPerformanceService: SurveyPerformanceResportService,
    private _commomServices: CommonService,
    private _toast: ToasterService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this._getBuyers();
    this._getCountries();
    this.surveyStatusOptions = this.surveyStatusOptions.map((status: any) => {
      return {
        ...status,
        selected: true,
      };
    });
    this.selectedStatus = this.surveyStatusOptions.map(
      (response: SelectedBuyer) => {
        return { id: response.id };
      }
    );
  }

  selectStatus(event: Array<MultiSelectedStatus>) {
    this.selectedStatus = event.map((status: MultiSelectedStatus) => {
      return { id: status.id };
    });
  }

  public selectDate(event: GetDateFrom) {
    if (!event.start || !event.end) {
      return;
    }
    this.startDate = this.datepipe.transform(event.start, 'yyyy-MM-dd') || '';
    this.endDate = this.datepipe.transform(event.end, 'yyyy-MM-dd') || '';
  }

  selectCompanies(event: Array<MultiSelectedBuyer>) {
    this.selectedBuyersList = event.map((buyer: MultiSelectedBuyer) => {
      return {
        name: buyer.name,
        id: buyer.id,
      };
    });
  }

  selectCountry(event: EachCountry) {
    this.countrySelected = event;
  }

  getSurveyPerformanceData() {
    if (!this.startDate || !this.endDate) {
      this._toast.error(notifyMessage.errorMessage.INVALID_DATE_RANGE);
      return;
    }
    if (this.selectedBuyersList.length == 0) {
      this._toast.error(notifyMessage.errorMessage.NO_BUYER_SELECTED);
      return;
    }
    if (this.selectedStatus.length == 0) {
      this._toast.error(notifyMessage.errorMessage.NO_STATUS_SELECTED);
      return;
    }
    const payload = {
      buyers: this.selectedBuyersList,
      status: this.selectedStatus,
    };
    this._surveyPerformanceService
      .getPerformanceReport(
        this.startDate,
        this.endDate,
        this.countrySelected!.short_Code,
        payload
      )
      .subscribe((response: GetSurveyPerformanceReportResponse) => {
        this.surveyPerformanceData = response.result;
        this.surveyPerformanceData.forEach(
          (eachPer: GetSurveyPerformanceReportResult) => {
            let buyerRevenue = 0,
              supplierRevenue = 0,
              completes = 0,
              validClicks = 0,
              buyerClicks = 0,
              totalClicks = 0,
              surveyName = '',
              country = '',
              bName = '',
              surveyPo = '';

            eachPer.Payload.forEach((eachPayload: Payload) => {
              if (eachPayload.statusCode == 21) {
                buyerRevenue = eachPayload.buyerRevenue;
                supplierRevenue = eachPayload.supplierRevenue;
                completes = eachPayload.totalOfTimeFrame;
              }
              if (this._validClicksStatusId.includes(eachPayload.statusCode)) {
                validClicks += eachPayload.total;
              }
              if (
                this._validBuyerClickStatusId.includes(eachPayload.statusCode)
              ) {
                buyerClicks += eachPayload.total;
              }

              totalClicks += eachPayload.total;
              surveyName = eachPayload.surveyName;
              bName = eachPayload.bName;
              country = eachPayload.country;
            });

            eachPer.country = country;
            eachPer.surveyName = surveyName;
            eachPer.bName = bName;
            if (eachPer.surveyManagementData) {
              if (
                Object.prototype.hasOwnProperty.call(
                  eachPer.surveyManagementData,
                  'cpi_current'
                )
              ) {
                eachPer.cpi =
                  eachPer.surveyManagementData.cpi_current.toFixed(2);
              }
              eachPer.surveyName = eachPer.surveyManagementData.tle;
              eachPer.buyerRevenue = Math.round(buyerRevenue * 100) / 100;
              eachPer.supplierRevenue = Math.round(supplierRevenue * 100) / 100;
              eachPer.margin =
                Math.round(
                  (eachPer.buyerRevenue - eachPer.supplierRevenue) * 100
                ) / 100;
              eachPer.completesInTimeFrame = completes;
              eachPer.totalCompletes =
                eachPer.surveyManagementData.statistics &&
                eachPer.surveyManagementData.statistics[0] &&
                eachPer.surveyManagementData.statistics[0].buyer &&
                eachPer.surveyManagementData.statistics[0].buyer.completes
                  ? eachPer.surveyManagementData.statistics[0].buyer.completes
                      .buyer_fielded_after_adj || 0
                  : eachPer.surveyManagementData.fielded;
              eachPer.completesRemaining =
                eachPer.surveyManagementData.goal -
                eachPer.surveyManagementData.fielded;
              eachPer.loi_launch = Math.round(
                eachPer.surveyManagementData.loi_launch
              );
              eachPer.incidence_launch = Math.round(
                eachPer.surveyManagementData.incidence_launch
              );
              eachPer.loi =
                Math.round(eachPer.surveyManagementData.gpp.LOI * 100) / 100;
              eachPer.incidence_current =
                Math.round(eachPer.surveyManagementData.gpp.IR * 100) / 100;
              eachPer.surveyPo = eachPer.surveyManagementData
                ? eachPer.surveyManagementData.survey_po
                : '';
            }
            eachPer.totalClicks = totalClicks;
            eachPer.buyerClicks = buyerClicks;
            eachPer.validClicks = validClicks;
            eachPer.totalEPC =
              Math.round((buyerRevenue / totalClicks) * 100) / 100;
            eachPer.buyerEPC =
              Math.round((buyerRevenue / buyerClicks) * 100) / 100;
            eachPer.validEPC =
              Math.round((buyerRevenue / validClicks) * 100) / 100;
          }
        );
      });
  }

  _getBuyers() {
    const isSpplr: boolean = false;
    const isBuyr: boolean = true;
    this._surveyPerformanceService
      .getCompaniesAllData(isSpplr, isBuyr)
      .subscribe((response: GetCompaniesAllDataResponse) => {
        this.buyerList = response.companies.map(
          (company: MultiSelectedBuyer) => {
            return {
              ...company,
              selected: true,
            };
          }
        );
        this.selectedBuyersList = this.buyerList.map(
          (buyer: MultiSelectedBuyer) => {
            return {
              name: buyer.name,
              id: buyer.id,
            };
          }
        );
      });
  }

  _getCountries() {
    const addAllOption = true;
    this._commomServices
      .getCountries(addAllOption)
      .subscribe((response: Array<EachCountry>) => {
        this.countryList = response;
        this.countrySelected = this.countryList[0];
      });
  }
}
