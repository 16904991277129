import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  SurveyRequest,
  UpdateSurveyStatus,
  BuyerSurveyCountResponse,
  TcResponse,
  CreateTcPayload,
  CreateTcResponse,
  Quotas,
  SupplierResponseInterface,
  GetSupplierBlendInterface,
  UpdateQuotaFieldsInterface,
  PutSupplierInterface,
  UpdateSurveyFieldsApiResponse,
  PsidsBuyerUploadListResponse,
  ClearPsidsResponse,
  PsidsUploadRefListResponse,
  PatchSurveyInterface,
  GetSurveysQueryParams,
  SurveyUpdate,
  HighCpiConfrmationResponse,
} from './survey.interface';

import { SurveyStatus } from '../../../utils/survey-status';
import { V2SurveysQualification } from '../../interfaces/qualification.interface';
import { SurveyPayload } from '../survey-meta-data/survey-meta-data.interface';
import { map } from 'rxjs/operators';
import { UpdateQuotaInterface } from '@purespectrum1/ui/marketplace/shared/interfaces/manage-quotas.interface';
import { Observable, forkJoin, of } from 'rxjs';
import { SurveyStats } from 'src/app/dashboard/bulk-edit/types';
import { BillingNumberRules } from '@purespectrum1/ui/marketplace/shared/interfaces/billing-rules.interfaces';
import {
  BuyerSurveyResponse,
  SurveyNoTargetingResponse,
} from '@purespectrum1/ui/marketplace/shared/interfaces/survey.interface';

@Injectable({
  providedIn: 'root',
})
export class BuyerApiService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  addSurvey(surveyData: SurveyRequest) {
    return this._http.post<SurveyNoTargetingResponse>(
      `${this._baseUrl}/buyers/v2/surveys?UI=1`,
      surveyData
    );
  }

  updateSurvey(surveyData: SurveyPayload, surveyId: number) {
    return this._http.put<SurveyNoTargetingResponse>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}?UI=1&targeting=0`,
      surveyData
    );
  }

  updateSurveyStatus(surveyId: number, statusObj: UpdateSurveyStatus) {
    return this._http.put<UpdateSurveyStatus>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}/status?UI=1`,
      statusObj
    );
  }

  getSurveyHighCpiDetails(surveyId: number) {
    return this._http.get<HighCpiConfrmationResponse>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}/high-cpi-details`
    );
  }

  updateSurveyStatusesInBulk(
    surveysToUpdate: SurveyUpdate[]
  ): Observable<{ [key: string]: UpdateSurveyStatus }> {
    if (!surveysToUpdate.length) {
      return of({});
    }
    return forkJoin(
      Object.fromEntries(
        surveysToUpdate.map((s) => [
          s.surveyId,
          this.updateSurveyStatus(s.surveyId, {
            ps_survey_status: Number(s.status),
          }),
        ])
      )
    );
  }

  searchBuyerSurveys(
    status: number,
    billingPo: string,
    buyerId: number,
    strict: boolean = false
  ) {
    let url = `${this._baseUrl}/buyers/v2/surveys?UI=1&status=${status}&targeting=0&searchPo=${billingPo}&buyerId=${buyerId}`;
    if (strict) {
      url = `${url}&strict=${strict}`;
    }
    return this._http.get<BuyerSurveyResponse[]>(url);
  }

  getSurveyById(surveyId: number) {
    return this._http.get<BuyerSurveyResponse>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}?UI=1&QBS=1`
    );
  }

  getBuyerUploadedList(surveyId: number, incl_excl: number) {
    return this._http.get<PsidsBuyerUploadListResponse>(
      `${this._baseUrl}/inclexcl/getBuyerUploadedList/${surveyId}?incl_excl=${incl_excl}`
    );
  }

  clearUploadedPSIDs(surveyId: number) {
    return this._http.delete<ClearPsidsResponse>(
      `${this._baseUrl}/inclexcl/clearUploadedPSIDs/${surveyId}`
    );
  }

  uploadPSIDRefFile(surveyId: number, fdata: FormData) {
    return this._http.post<PsidsUploadRefListResponse>(
      `${this._baseUrl}/inclexcl/uploadPSIDListFromUI/${surveyId}`,
      fdata
    );
  }

  getSupplierBlends() {
    return this._http.get<GetSupplierBlendInterface[]>(
      `${this._baseUrl}/buyers/v2/supplier-blends`
    );
  }

  /*
   * The count api is now returning only LIVE and PAUSED counters
   * Ref: PD-12571 & PD-11788
   * */
  getSurveyCounts(
    status = SurveyStatus.Live,
    page = 1,
    search = '',
    buyerId?: number,
    projectManagerId?: number,
    buyerProjectManagerId?: number
  ) {
    let url = `${this._baseUrl}/buyers/v2/surveys/count?UI=1&page=${page}&search=${search}&status=${status}&limit=20&targeting=0`;
    if (buyerId) {
      url = `${url}&buyerId=${buyerId}`;
    }
    if (projectManagerId) {
      url = `${url}&projectManagerId=${projectManagerId}`;
    }
    if (buyerProjectManagerId) {
      url = `${url}&buyerProjectManagerId=${buyerProjectManagerId}`;
    }
    return this._http.get<BuyerSurveyCountResponse>(encodeURI(url));
  }

  getSurveys(queryParams: GetSurveysQueryParams) {
    const url = `${this._baseUrl}/buyers/v2/surveys`;
    let params = new HttpParams();
    const escapeNonTruthyValues = [0, false];
    Object.keys(queryParams).forEach((key) => {
      ((queryParams as any)[key] ||
        escapeNonTruthyValues.includes((queryParams as any)[key])) &&
        (params = params.set(key, (queryParams as any)[key]));
    });
    return this._http.get<BuyerSurveyResponse[]>(encodeURI(url), { params });
  }

  getLaunchedAndNotInvoicedSurveysWithSamePo(searchPo: string) {
    const queryParams: GetSurveysQueryParams = {
      UI: 1,
      targeting: 0,
      page: 1,
      status: [
        SurveyStatus.Live,
        SurveyStatus.Paused,
        SurveyStatus.Closed,
      ].join(','),
      searchPo: searchPo,
      strict: true,
    };
    return this.getSurveys(queryParams).pipe(
      // Filtering out all the TC child surveys because we do not want to show them in the bulk invoice modal
      map((surveys) =>
        surveys.filter(
          (s) => !s.trafficChannels || !s.trafficChannels.channelType
        )
      )
    );
  }

  getSurvey(surveyId?: number, targeting: number = 0, accessible = false) {
    let url = `${this._baseUrl}/buyers/v2/surveys/${surveyId}?UI=1&targeting=${targeting}`;
    if (accessible) {
      url = `${url}&accessible=${accessible}`;
    }
    return this._http.get<BuyerSurveyResponse>(url);
  }

  getTrafficChannelData(surveyId: number) {
    return this._http.get<TcResponse[]>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}/channels?UI=1`
    );
  }

  createTrafficChannelSurvey(surveyId: number, payload: CreateTcPayload) {
    return this._http.post<CreateTcResponse>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}/channels?UI=1`,
      payload
    );
  }

  getQuotaDetails(
    surveyId?: number,
    queryParams?: {
      page: number;
      limit: number;
      search: string;
      trafficChannel?: string;
      psEntDtRange?: string;
    }
  ) {
    let url = `${this._baseUrl}/buyers/v2/surveys/${surveyId}/quotas`;
    let params = new HttpParams();
    params = params.set('UI', 1);
    params = params.set('QBS', 1);
    if (queryParams) {
      Object.keys(queryParams).forEach((key) => {
        (queryParams as any)[key] &&
          (params = params.set(key, (queryParams as any)[key]));
      });
    }
    return this._http.get<Quotas[]>(url, { observe: 'response', params }).pipe(
      map((response) => {
        return {
          header: response.headers.get('total'),
          quota: response.body,
        };
      })
    );
  }

  getSuppliersParams(
    mode: string,
    loi?: number,
    ir?: number,
    lang?: number | string,
    manualScpiOverrideEnabled?: boolean
  ) {
    let params = new HttpParams();
    params = params.set('stats', 1);
    params = params.set('mode', mode);
    if (loi) {
      params = params.set('LOI', loi);
    }
    if (ir) {
      params = params.set('IR', ir);
    }
    if (lang) {
      params = params.set('LNG', lang);
    }
    if (manualScpiOverrideEnabled) {
      params = params.set(
        'manual_scpi_override_enabled',
        manualScpiOverrideEnabled
      );
    }
    params = params.set('epc', 1);
    params = params.set('UI', 1);
    return params;
  }

  getSuppliers(
    surveyId: number,
    mode: string,
    loi?: number,
    ir?: number,
    lang?: number | string
  ) {
    const params = this.getSuppliersParams(mode, loi, ir, lang);
    return this._http.get<SupplierResponseInterface[]>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}/suppliers?${params}`
    );
  }

  putSuppliers(
    surveyId: number,
    mode: string,
    suppliers: PutSupplierInterface[],
    priceOverride: number | string,
    manualOverride: boolean,
    surveyPauseThreshold?: number,
    loi?: number,
    ir?: number,
    lang?: number | string,
    blendId: string = 'NA',
    manualScpiOverrideEnabled?: boolean
  ) {
    let params = this.getSuppliersParams(mode, loi, ir, lang);
    params = params.set('manual_override', manualOverride);
    params = params.set('price_override', priceOverride ? priceOverride : '');
    if (blendId != 'NA') {
      params = params.set('blend_id', blendId);
    }
    if (!priceOverride && !manualOverride && surveyPauseThreshold) {
      params = params.set('survey_pause_threshold', surveyPauseThreshold);
    }
    if (manualScpiOverrideEnabled) {
      params = params.set(
        'manual_scpi_override_enabled',
        manualScpiOverrideEnabled
      );
    }

    return this._http.put<SupplierResponseInterface[]>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}/suppliers?${params}`,
      suppliers
    );
  }

  getQualifications(surveyId: number | undefined) {
    return this._http.get<V2SurveysQualification[]>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}/qualifications?UI=1`
    );
  }

  updateQuotaData(
    surveyId: number,
    quotaId: string,
    payload: UpdateQuotaFieldsInterface[]
  ) {
    return this._http.patch<UpdateQuotaInterface>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}/quotas/${quotaId}`,
      payload
    );
  }

  updateSupplierQuotaData(
    surveyId: number,
    quotaId: string,
    supplierId: string | number,
    payload: UpdateQuotaFieldsInterface[]
  ) {
    return this._http.patch<UpdateQuotaInterface>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}/quotas/${quotaId}/suppliers/${supplierId}`,
      payload
    );
  }

  cloneSurvey(surveyId: number, isServiceOperatorCreation?: boolean) {
    const params = this._getCloneSurveyParams(!!isServiceOperatorCreation);
    return this._http.post<BuyerSurveyResponse>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}/clone?${params}`,
      {}
    );
  }

  private _getCloneSurveyParams(isServiceOperatorCreation: boolean) {
    let params = new HttpParams();
    params = params.set('UI', '1');
    params = params.set('targeting', '0');
    if (isServiceOperatorCreation) {
      params = params.set('serviceOperatorCreation', '1');
    }
    return params;
  }

  updateSurveyField(surveyId: number, payload: UpdateQuotaFieldsInterface) {
    return this._http.patch<UpdateSurveyFieldsApiResponse>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}`,
      payload
    );
  }
  deleteDraftSurvey(surveyId: number) {
    return this._http.delete(`${this._baseUrl}/buyers/v2/surveys/${surveyId}`);
  }

  patchSurvey(surveyId: number, payload: PatchSurveyInterface[]) {
    return this._http.patch<any>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}`,
      payload
    );
  }

  updateTMCpi(surveyId: number, cpiCurrent: number) {
    return this._http.put<SupplierResponseInterface[]>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}/offerPrice?UI=1`,
      { value: cpiCurrent, margin_override: '' }
    );
  }

  updateOfferPriceOfSurvey(surveyId: number, cpiCurrent: number) {
    return this._http.put<SupplierResponseInterface[]>(
      `${this._baseUrl}/buyers/v2/surveys/${surveyId}/offerPrice?UI=1`,
      { value: cpiCurrent }
    );
  }

  getMarginReportData(surveyId: number | undefined, mode: string) {
    return this._http.get(
      `${this._baseUrl}/reports/surveys/${surveyId}/margin-report?mode=${mode}`
    );
  }

  formatStatsForCards(surveyIds: Array<number>) {
    return this._http.post<SurveyStats>(
      `${this._baseUrl}/buyers/v2/projects/stats`,
      {
        surveyIds,
      }
    );
  }

  getSurveyAlredyInvoicedWithPONumber(billingId: string) {
    return this._http.get<String>(
      `${this._baseUrl}/buyers/v2/surveys/invoiced/billing_id?billing=${billingId}`
    );
  }

  getSurveyBillingRulesVerification(
    po: string,
    billingRules?: BillingNumberRules
  ) {
    return this._http.post<BillingNumberRules>(
      `${this._baseUrl}/buyers/v2/surveys/billig_id/rules/`,
      { po, billingRules }
    );
  }
}
