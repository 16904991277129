export const BULK_STATUS_CHANGE_MODAL = {
  BUTTON: {
    ONLY_THIS: {
      TEXT: {
        SURVEY_DETAIL: 'Only this Survey',
        INVOICE_OPERATIONS: 'Only the selected surveys',
      },
      ACTION: 'ONLY_THIS',
    },
    THIS_AND_ALL_LISTED: {
      TEXT: {
        SURVEY_DETAIL: 'This survey and EVERY survey in the above list',
        INVOICE_OPERATIONS:
          'The selected surveys and EVERY survey in the above list',
      },
      ACTION: 'THIS_AND_ALL_LISTED',
    },
  },
  NO_BULK_INVOICING_ACTION: 'NO_BULK_INVOICING_ACTION',
};

export const WARNING_MESSAGE = {
  MARGIN_WARNING_TEXT:
    'You have entered a Target Margin value that is below the average Margin set for this account.',
  MARGIN_WARNING_RECOMMEND_TEXT:
    'We recommend reviewing the Banked Margin for the project before you confirm you want to proceed',
};
