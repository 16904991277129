import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@purespectrum1/ui';
import { Subscription } from 'rxjs';

import { SurveyMetaDataService } from '../../../shared/services/survey-meta-data/survey-meta-data.service';
import { ConfirmationModalComponent } from '../../../shared/ui/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'ps-create-survey-data',
  templateUrl: './create-survey-data.component.html',
  styleUrls: ['./create-survey-data.component.css'],
})
export class CreateSurveyDataComponent implements OnInit, OnDestroy {
  private _surveyDataSubscriptions: Subscription[] = [];
  public totalClicks?: number;
  private _isFormDirty: boolean = false;
  public surveyTotalCost?: number;
  public currencySymbol: string = '';
  /** Only display the survey metadata if the survey is new or a draft */
  @Input() isRecontactSurvey = false;

  constructor(
    private _surveyMetaDataService: SurveyMetaDataService,
    private _modal: ModalService,
    private _router: Router
  ) {}

  ngOnInit() {
    this._surveyMetaDataService.clearAll();
    this._surveyDataSubscriptions.push(
      this._surveyMetaDataService
        .getSurveyHeaderData()
        .subscribe((surveyResponse) => {
          if (surveyResponse) {
            this.totalClicks = surveyResponse.totalClicks;
            this.surveyTotalCost = surveyResponse.total_cost;
            if (surveyResponse.currencySymbol) {
              this.currencySymbol = surveyResponse.currencySymbol;
            }
          }
        })
    );

    this._surveyDataSubscriptions.push(
      this._surveyMetaDataService
        .getisFormDirty()
        .subscribe((isFormDirty: boolean) => {
          this._isFormDirty = isFormDirty;
        })
    );
  }

  close() {
    if (!this._isFormDirty) {
      this._router.navigateByUrl('/dashboard');
      return;
    }

    const exitModalRef = this._modal.open(ConfirmationModalComponent, {
      data: {
        text: 'Are you sure you want to exit, your data will not be saved?\n\nIf you want to save this project, you can use the Save Tool on the bottom left of this screen.',
        cancelButtonLabel: 'No, continue working.',
        confirmButtonLabel: 'Yes, leave page.',
      },
      maxWidth: '35%',
    });

    exitModalRef.onClose$.subscribe((action) => {
      if (action === 'ok') {
        this._router.navigateByUrl('/dashboard');
      }
    });
  }

  ngOnDestroy() {
    this._surveyDataSubscriptions.forEach((sub) => sub.unsubscribe());
  }
}
