<div *ngIf="tooltip" class="tooltip-visible">
  <div class="fa fa-info-circle setting-info">
    <p>
      <span>
        Billing Structure:
        <br />
        <br />
        Example: {{ tooltip!.example() }}
        <br />
        <br />
        <span *ngFor="let rule of tooltip.structure()">
          {{ rule.entry }}
          <ul>
            <li>{{ rule.characters.position }}</li>
            <li>{{ rule.characters.requirement }}</li>
            <li>{{ rule.characters.type }}</li>
          </ul>
        </span>
      </span>
      <br />
      <span>
        GLOSSARY
        <ul>
          <li>Alpha ( e.g. “a-z“ and “A-Z“)</li>
          <li>Numeric (e.g. “0-9“)</li>
          <li>Special Characters (e.g. {{ specialChars }})</li>
        </ul>
      </span>
    </p>
  </div>
</div>
