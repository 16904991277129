import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import {
  CompaniesResponse,
  ApiResponseObject,
  CompanyResponseObject,
  CompanyPayload,
  GetAllSuppliersRequest,
  GetAllSuppliersResponse,
  CompanyResponse,
  CompanyV2Response,
  SimplifiedCompaniesResponse,
} from '../../interfaces/company.interface';
import { ChurnZeroCompanyType } from '@purespectrum1/ui';
import { Pagination } from '../../types/pagination';
import { UserRole } from 'src/app/dashboard/bulk-edit/types';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getCompanies() {
    return this._http.get<CompaniesResponse>(`${this._baseUrl}/companies`);
  }

  getSupplierCompanies() {
    return this._http.get<CompaniesResponse>(
      `${this._baseUrl}/companies/v2?filter=supplier`
    );
  }

  getCompany(companyId: number) {
    return this._http.get<CompanyResponse>(
      `${this._baseUrl}/company/${companyId}`
    );
  }

  getCompaniesSimplified() {
    return this._http
      .get<SimplifiedCompaniesResponse>(`${this._baseUrl}/companies/simplified`)
      .pipe(
        map((response) => {
          return response.companies.map((company) => ({
            id: company.id,
            name: company.name,
            isASupplier: company.isASupplier,
          }));
        })
      );
  }

  getCompaniesPage(
    pagination: Pagination = Pagination.first(),
    search = '',
    role?: UserRole
  ) {
    const params =
      search.length !== 0
        ? { ...pagination.raw(), search }
        : { ...pagination.raw() };
    const query = new URLSearchParams(params);

    if (role) {
      // it will filter and we'll get only buyers
      query.set('filter', role);
    }

    return this._http.get<CompanyV2Response>(
      `${this._baseUrl}/companies/v2?${query.toString()}`
    );
  }

  getCompanyByName(name: string) {
    return this._http.get<CompanyV2Response>(
      `${this._baseUrl}/companies/v2?search=${name}&strict=true`
    );
  }

  getCompanyDetail(id: number) {
    return this._http.get<CompanyResponseObject>(
      `${this._baseUrl}/companies/v2/${id}`
    );
  }

  saveCompanyDetails(companyPayload: CompanyResponseObject) {
    return this._http.post<ApiResponseObject>(
      `${this._baseUrl}/company`,
      companyPayload
    );
  }

  updateCompany(companyID: number, companyPayload: ChurnZeroCompanyType) {
    return this._http
      .put<ApiResponseObject>(`${this._baseUrl}/company/${companyID}`, {
        ...companyPayload,
        id: companyID,
      })
      .pipe(
        tap((response) => {
          companyPayload['id'] = companyID;
        })
      );
  }

  deleteCompanyDetails(companyID: number) {
    return this._http.delete<ApiResponseObject>(
      `${this._baseUrl}/company/${companyID}`
    );
  }

  getAllBuyers(companyPayload: CompanyPayload, addAllOption: boolean = false) {
    return this._http
      .get<CompaniesResponse>(
        `${this._baseUrl}/invoice/companies?isSpplr=${companyPayload.isSpplr}&isBuyr=${companyPayload.isBuyr}`
      )
      .pipe(
        map((response) => {
          if (addAllOption) {
            response.companies.unshift({
              address: '',
              dynamicProfiler: false,
              fx: 0,
              id: 0,
              isABuyer: false,
              isASupplier: false,
              isAnOperator: false,
              isExpose: false,
              isNotify: false,
              isPrivacyPolicyActive: false,
              name: 'ALL',
              phone: '',
              primaryLiaison: '',
              secondaryLiaison: '',
              status: '',
              supp_url_completes: '',
              supp_url_overquota: '',
              supp_url_terms: '',
              supplier_type: '',
              isDataQualityQuestionsActive: false,
              isAsrRedirectionEnable: false,
              addr: '',
              phn: '',
              priLsn: '',
              secLsn: '',
              isAuxiliary: false,
              isBuyr: false,
              isSpplr: false,
              isOprtr: false,
              isService: false,
              isFusion: false,
              skipPreScreener: false,
              st: 0,
              sup_url: '',
              generateInvoice: '',
              tempGenerateInvoice: '',
              invoiceChangeOverTS: 0,
              crtd_on: 0,
              mod_on: 0,
              accountManagers: [],
              tal: 0,
            });
          }
          return response;
        })
      );
  }

  getAllSuppliers(data: GetAllSuppliersRequest) {
    return this._http
      .get<GetAllSuppliersResponse>(
        `${this._baseUrl}/invoice/companies?supplier_type=${data.supplier_type}&isBuyr=${data.isBuyr}`
      )
      .pipe(
        map((response) => {
          return response.companies.sort((a, b) => (a.name > b.name ? 1 : -1));
        })
      );
  }
}
