import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import {
  Payload,
  GetDailyStatsReportResponse,
} from './daily-statistics-report.interface';

@Injectable({
  providedIn: 'root',
})
export class DailyStatisticsReportService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getDailyStatsReport(
    payload: Payload
  ): Observable<GetDailyStatsReportResponse> {
    return this._http.get<GetDailyStatsReportResponse>(
      `${this._baseUrl}/getDailyStatsReport?startDate=${payload.startDate}&endDate=${payload.endDate}&country_code=${payload.shortCode}`
    );
  }
}
