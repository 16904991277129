import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import {
  QualificationsResponse,
  CensusResponse,
  Qualification,
} from '../../../operator-settings/operator-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class CensusService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  getQualifications(locale: string, qualificationIds: string) {
    return this._http.get<Array<QualificationsResponse>>(
      `${this._baseUrl}/buyers/v2/psAttributes?localization=${locale}&qualificationIds=${qualificationIds}&ignoreMapping=true&UI=1`
    );
  }

  getQualificationsWithCensusData(locale: string) {
    return this._http.get<Array<number>>(
      `${this._baseUrl}/buyers/v2/census/qualifications/ids?country=${locale}`
    );
  }

  getCensus(locale: string, qualId: number) {
    return this._http.get<CensusResponse>(
      `${this._baseUrl}/buyers/v2/census/${qualId}?locale=${locale}`
    );
  }

  saveCensus(id: number, payload: Qualification) {
    return this._http.put<CensusResponse>(
      `${this._baseUrl}/buyers/v2/census/${id}?UI=1`,
      payload
    );
  }
}
