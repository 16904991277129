<div class="form-group search-key" [class.offset]="offset">
  <label for="searchKey" class="control-label">Search</label>
  <input
    class="search-box form-control"
    type="text"
    placeholder="Search survey"
    id="searchKey"
    [value]="value"
    (keyup)="onSearchKeyUp(searchKey.value)"
    #searchKey
  />
  <div *psHasRole="'operator'">
    <button
      class="survey-search-button"
      [psUiDropdownToggleFor]="serviceOperatorFilterMenu"
    >
      <i
        *ngIf="filter.current || filter.search; else noFilter"
        class="fa fa-check filter-applied"
      ></i>
      <ng-template #noFilter>
        <i class="fa fa-caret-down"></i>
      </ng-template>
    </button>
    <ps-ui-dropdown
      #serviceOperatorFilterMenu="psUiDropdown"
      class="service-operator-filter-dropdown"
      align="start"
    >
      <a
        psUiDropdownItem
        [ngClass]="{ 'filter-applied': filter.current }"
        (click)="filterCurrentServiceOperator()"
        >View My Projects</a
      >
      <a
        psUiDropdownItem
        [ngClass]="{ 'filter-applied': filter.search }"
        (click)="filterSearchServiceOperator()"
        >View By Operator</a
      >
    </ps-ui-dropdown>
  </div>
  <ng-container *psHasRole="'buyer'">
    <div *psConfigEnabled="'enableViewByPM'">
      <button class="survey-search-button" [psUiDropdownToggleFor]="filterMenu">
        <i
          *ngIf="
            filter.current || filter.search || filter.unassigned;
            else noFilter
          "
          class="fa fa-check filter-applied"
        ></i>
        <ng-template #noFilter>
          <i class="fa fa-caret-down"></i>
        </ng-template>
      </button>
      <ps-ui-dropdown
        #filterMenu="psUiDropdown"
        class="service-operator-filter-dropdown"
        align="start"
      >
        <a
          psUiDropdownItem
          [ngClass]="{ 'filter-applied': filter.current }"
          (click)="filterCurrentBuyerSurvey()"
          >View My Projects</a
        >
        <a
          psUiDropdownItem
          [ngClass]="{ 'filter-applied': filter.search }"
          (click)="filterSearchBuyerSurvey()"
          >View By PM</a
        >
        <a
          psUiDropdownItem
          [ngClass]="{
            'filter-applied': filter.unassigned
          }"
          (click)="filterUnassignedBuyerSurvey()"
          >View Unassigned</a
        >
      </ps-ui-dropdown>
    </div>
  </ng-container>
</div>
