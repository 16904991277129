import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import {
  GetDailyEpcReportResponse,
  ReportPayload,
} from './epc-report.interface';
@Injectable({
  providedIn: 'root',
})
export class EpcReportService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getEpcReport(payload: ReportPayload): Observable<GetDailyEpcReportResponse> {
    return this._http.get<GetDailyEpcReportResponse>(
      `${this._baseUrl}/reports/epc/bybuyer?b_id=${payload.b_id}&startDate=${payload.startDate}&endDate=${payload.endDate}`
    );
  }
}
