import { GroupSupplier, SupplierBlend } from '@purespectrum1/ui';
import { deepCopy } from '../../../../app/utils/deep-copy';

export class AllocationClass {
  constructor() {}

  selectAll(supplierBlendData: SupplierBlend) {
    const supplierBlend = deepCopy(supplierBlendData);
    if (
      supplierBlend.suppliers.some((supp: GroupSupplier) => !supp.setActive)
    ) {
      supplierBlend.suppliers.forEach((supplier: GroupSupplier) => {
        supplier.setActive = true;
      });
    } else {
      supplierBlend.suppliers.forEach((supplier: GroupSupplier) => {
        supplier.setActive = false;
      });
    }
    return supplierBlend;
  }

  clearAllSuppliers(supplierBlendData: SupplierBlend) {
    const supplierBlend = deepCopy(supplierBlendData);
    supplierBlend.suppliers.forEach((supplier: GroupSupplier) => {
      delete supplier.allocation_percentage;
      delete supplier.flexibility_percentage;
      if (supplier.setActive) {
        supplier.setActive = false;
      }
    });
    return supplierBlend;
  }
}
