import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  McSVPEachSurvey,
  SurveyListing,
} from '../../../shared/services/buyer-api/survey.interface';
import { SurveyHealthCheckMapper } from './domain/survey-health-check-mapper';
import { Router } from '@angular/router';
import { EachCountry } from '../../../shared/interfaces/common-service.interface';
import { McSvpCountryNameMapper } from '../../services/mc-svp-country-name-mapper';
import { Observable, timer } from 'rxjs';
import { debounce, map, tap } from 'rxjs/operators';
import { BoundaryOverstep } from '@purespectrum1/ui/shared';

@Component({
  selector: 'ps-health-check-widget',
  templateUrl: './health-check-widget.component.html',
  styleUrls: ['./health-check-widget.component.css'],
})
export class HealthCheckWidgetComponent implements OnInit {
  @Input()
  public survey: SurveyListing = {} as SurveyListing;

  @Input()
  public countries: EachCountry[] = [];

  @Input()
  public boundaries?: DOMRect;

  @ViewChild('mcHealthDropdownContainer')
  private _mcHealthDropdownContainer!: ElementRef<HTMLDivElement>;

  @ViewChild('mcKpiDropdownContainer')
  private _mcKpiDropdownContainer!: ElementRef<HTMLDivElement>;

  private _hoveredSelectionCommand$ = new EventEmitter<number | null>();

  public surveyId!: number;
  public hasHealthCheckData: boolean = false;
  public mcSvpChildsWithHealth: McSVPEachSurvey[] = [];
  public hoveredMcSvpChildWithHealth$!: Observable<McSVPEachSurvey | null>;

  constructor(private _router: Router, private _renderer: Renderer2) {}

  public ngOnInit(): void {
    this.surveyId = this.survey.surveyId;
    const kpis = new SurveyHealthCheckMapper(this.survey).kpis();
    this.hasHealthCheckData = !!kpis.length;
    this.mcSvpChildsWithHealth = this._prepareMcSvpChildsHealthData();
    this._handleHoveredSelection();
  }

  private _prepareMcSvpChildsHealthData(): McSVPEachSurvey[] {
    if (!this.hasHealthCheckData) {
      return [];
    }

    if (!this.survey.mc?.SVP || !this.survey.mc.parent) {
      return [];
    }

    const filtered = this.survey.mc.surveys.filter(
      (childSurvey) => !!childSurvey.healthCheck?.length
    );
    return new McSvpCountryNameMapper(filtered, this.countries).map();
  }

  public redirectToTheSurveyDashboard(data: {
    survey_id: number;
    svp_child_id?: number | unknown;
  }): void {
    this._router.navigate(['/dashboard', data.survey_id], {
      state: {
        activeTab: 'HEALTH_CHECKS',
        ...(!!data.svp_child_id && { svp_child_id: data.svp_child_id }),
      },
    });
  }

  public onHover(selection: number | null): void {
    this._hoveredSelectionCommand$.next(selection);
  }

  private _handleHoveredSelection(): void {
    this.hoveredMcSvpChildWithHealth$ = this._hoveredSelectionCommand$.pipe(
      debounce((selection) => (!selection ? timer(200) : timer(0))), // when the hover goes off from an element, then we will wait for another selection (to allow user to go from one dropdown to another)
      map((selection) => {
        if (!selection) {
          return null;
        }

        return this.mcSvpChildsWithHealth.find(
          (child) => child.surveyId === selection
        )!;
      }),
      tap(() => this._decideHoveredDropdownDirection())
    );
  }

  private _decideHoveredDropdownDirection(): void {
    if (
      this._mcHealthDropdownContainer &&
      this._mcKpiDropdownContainer &&
      this.boundaries
    ) {
      const mcHealthContainerBoundaries =
        this._mcHealthDropdownContainer.nativeElement.getBoundingClientRect();
      const offset = this._mcKpiDropdownContainer.nativeElement.offsetWidth;
      const hasOversteppedRightBoundary = new BoundaryOverstep(
        this.boundaries!,
        mcHealthContainerBoundaries,
        offset
      ).hasOverstepped('right');

      if (hasOversteppedRightBoundary) {
        const parentNodeBoundaries =
          this._mcHealthDropdownContainer.nativeElement.parentElement?.getBoundingClientRect();
        const rightOffsetToCover =
          parentNodeBoundaries?.left === mcHealthContainerBoundaries.left
            ? '4rem'
            : '17.5rem';
        this._renderer.setStyle(
          this._mcKpiDropdownContainer.nativeElement,
          'top',
          '0px'
        );
        this._renderer.setStyle(
          this._mcKpiDropdownContainer.nativeElement,
          'right',
          `${rightOffsetToCover}`
        );
        return;
      }

      this._renderer.setStyle(
        this._mcKpiDropdownContainer.nativeElement,
        'top',
        '0px'
      );
      this._renderer.setStyle(
        this._mcKpiDropdownContainer.nativeElement,
        'left',
        '16.5rem'
      );
    }
  }
}
