import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';

import { CommonService } from '../../../shared/services/common-service/common-service';
import { EachCountry } from '../../../shared/interfaces/common-service.interface';
import { DailyStatisticsReportService } from './daily-statistics-report.service';
import { notifyMessage } from '../../../constants/notify-message';
import { Constants } from '../../../constants/trans-status';
import {
  TempStatsObj,
  EachStats,
  EachStatsPayload,
  ValueDate,
} from './daily-statistics-report.interface';

@Component({
  selector: 'ps-daily-statistics-report',
  templateUrl: './daily-statistics-report.component.html',
  styleUrls: ['./daily-statistics-report.component.css'],
})
export class DailyStatisticsReportComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public countries: EachCountry[] = [];
  public selectedCountry!: EachCountry;
  public statsData: TempStatsObj[] = [];
  constructor(
    private _toastr: ToasterService,
    private _commonService: CommonService,
    private _dailyStatisticsReportService: DailyStatisticsReportService,
    private _datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this._getCountries();
  }
  private _getCountries() {
    const addAllOption = true;
    const countriesResponse$ = this._commonService
      .getCountries(addAllOption)
      .subscribe(
        (resp) => {
          this.countries = resp;
          this.selectedCountry = this.countries[0];
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add(countriesResponse$);
  }

  public daterange = {
    start: { _d: Date.now() },
    end: { _d: Date.now() },
    label: '',
  };

  public options = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };

  public selectedDate(value: ValueDate) {
    this.daterange.start = value.start;
    this.daterange.end = value.end;
  }

  public selectCountry(country: EachCountry) {
    this.selectedCountry = country;
  }

  public getReport() {
    if (!this.selectedCountry) {
      this._toastr.error(
        notifyMessage.warningMessage.DAILY_STATISTICS.COUNTRY_REQUIRED
      );
    }
    if (!this.daterange) {
      this._toastr.error(
        notifyMessage.warningMessage.DAILY_STATISTICS.DATERANGE_REQUIRED
      );
    }
    const payload = {
      startDate: this._datepipe.transform(
        this.daterange.start._d,
        'yyyy-MM-dd'
      ),
      endDate: this._datepipe.transform(this.daterange.end._d, 'yyyy-MM-dd'),
      shortCode: this.selectedCountry.short_Code,
    };
    const statisticsReport$ = this._dailyStatisticsReportService
      .getDailyStatsReport(payload)
      .subscribe(
        (res) => {
          this.statsData = [];
          if (res && res.apiStatus == 'success') {
            const result = res.result;
            let buyerRevenue = new Number(),
              supplierRevenue = new Number(),
              completes = new Number(),
              totalClicks = new Number(),
              validClicks = new Number(),
              buyerClicks = new Number();
            result.forEach((eachStats: EachStats) => {
              eachStats.Payload.forEach(
                (eachStatsPayload: EachStatsPayload) => {
                  if (
                    [Constants.TRANSACTION_STATUS.COMPLETE].includes(
                      eachStatsPayload.statusCode
                    )
                  ) {
                    buyerRevenue += eachStatsPayload.buyerRevenue;
                    completes += eachStatsPayload.total;
                    supplierRevenue += eachStatsPayload.supplierRevenue;
                  }
                  if (
                    [
                      Constants.TRANSACTION_STATUS.COMPLETE,
                      Constants.TRANSACTION_STATUS.BUYER_TERMINATION,
                      Constants.TRANSACTION_STATUS.BUYER_QUOTAFULL,
                      Constants.TRANSACTION_STATUS.BUYER_DROP,
                    ].includes(eachStatsPayload.statusCode)
                  ) {
                    validClicks += eachStatsPayload.total;
                  }
                  if (
                    [
                      Constants.TRANSACTION_STATUS.COMPLETE,
                      Constants.TRANSACTION_STATUS.BUYER_TERMINATION,
                      Constants.TRANSACTION_STATUS.BUYER_QUOTAFULL,
                      Constants.TRANSACTION_STATUS.BUYER_DROP,
                      Constants.TRANSACTION_STATUS.BUYER_QUALITY_TERMINATION,
                      Constants.TRANSACTION_STATUS.BUYER_SECURITY,
                    ].includes(eachStatsPayload.statusCode)
                  ) {
                    buyerClicks += eachStatsPayload.total;
                  }
                  totalClicks += eachStatsPayload.total;
                }
              );
            });
            const tempStatsObj: TempStatsObj = {
              buyerRevenue: Math.round(<number>buyerRevenue * 100) / 100,
              overallCpi:
                Math.round((<number>buyerRevenue / <number>completes) * 100) /
                100,
              supplierRevenue: Math.round(<number>supplierRevenue * 100) / 100,
              margin:
                Math.round(
                  (<number>buyerRevenue - <number>supplierRevenue) * 100
                ) / 100,
              completes: <number>completes,
              marginPerComplete:
                Math.round(
                  ((<number>buyerRevenue - <number>supplierRevenue) /
                    <number>completes) *
                    100
                ) / 100,
              totalClicks: <number>totalClicks,
              buyerClicks: <number>buyerClicks,
              validClicks: <number>validClicks,
            };
            this.statsData.push(tempStatsObj);
          }
        },
        (err: { error: { msg: string } }) => {
          const errorMessage = err.error.msg;
          this._toastr.error(errorMessage);
        }
      );
    this._subscriptions.add(statisticsReport$);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
