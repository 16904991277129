import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalRef } from '@purespectrum1/ui/modal';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import {
  Buyer,
  BuyerServiceAccountTokenRes,
} from 'src/app/shared/services/buyer/buyer-response.interface';
import { BuyerService } from '../../../../shared/services/buyer/buyer.service';
import { AuthService } from '../../../../auth/auth.service';

@Component({
  selector: 'ps-service-account-modal',
  templateUrl: './service-account-modal.component.html',
  styleUrls: ['./service-account-modal.component.css'],
})
export class ServiceAccountModalComponent implements OnInit, OnDestroy {
  buyerList: Buyer[] = [];
  selectedBuyer?: Buyer;
  private _subscriptions: Subscription = new Subscription();

  constructor(
    private _modalRef: ModalRef<ServiceAccountModalComponent, string>,
    private _buyerService: BuyerService,
    private _auth: AuthService,
    private _toastr: ToasterService
  ) {}

  ngOnInit(): void {
    this.getBuyers();
    const authSubscription = this._auth.loggedInAsServiceBuyer$.subscribe(
      () => {
        this.selectedBuyer = this._auth.serviceBuyer as unknown as Buyer;
      }
    );
    this._subscriptions.add(authSubscription);
  }

  getBuyers() {
    const getBuyers = this._buyerService.getBuyers().subscribe(
      (buyerResponse) => {
        this.buyerList = buyerResponse.company;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add(getBuyers);
  }

  clearBuyer() {
    this.selectedBuyer = undefined;
  }

  setBuyerDetails() {
    if (!this.selectedBuyer?.id) {
      this._auth.resetServiceBuyerToken();
      this._modalRef.close();
    } else {
      const getServiceBuyerToken = this._buyerService
        .getBuyerServiceAccountToken(this.selectedBuyer.id)
        .subscribe(
          (response: BuyerServiceAccountTokenRes) => {
            this._modalRef.close();
            this._auth.setServiceBuyerData({
              ...response,
              ...this.selectedBuyer!,
            });
          },
          (error) => {
            this._toastr.error(error.error.msg);
          }
        );
      this._subscriptions.add(getServiceBuyerToken);
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
