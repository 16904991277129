<ps-simple-header></ps-simple-header>

<div class="form-section" *ngIf="showForm">
  <form class="form-reset-div" (ngSubmit)="resetPassword()" [formGroup]="form">
    <a href="#">
      <img
        class="img-responsive center-block reset-password-logo"
        src="../../../assets/img/logo-login-ps.png"
        alt="Logo"
        title=""
      />
    </a>
    <h2 class="text-center reset-password-text">Set Password</h2>
    <div class="mb-3">
      <input
        type="password"
        class="form-control input-tag"
        id="NewPassword"
        placeholder="New Password"
        formControlName="newpass"
      />
    </div>
    <div class="mb-3">
      <input
        type="password"
        class="form-control input-tag"
        id="ConfirmPassword"
        placeholder="Confirm Password"
        formControlName="cpass"
      />
    </div>
    <div class="err-msg" *ngIf="form.hasError('notSame')">
      Passwords do not match
    </div>
    <div class="d-grid">
      <button
        class="btn btn-info btn-lg reset-password"
        psUiButton
        [outline]="true"
        [disabled]="form.invalid"
      >
        SUBMIT
      </button>
    </div>
  </form>
</div>
