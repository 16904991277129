import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from '@purespectrum1/ui/modal';

import { ConfirmationModalComponent } from './confirmation-modal.component';

@NgModule({
  imports: [CommonModule, ModalModule],
  declarations: [ConfirmationModalComponent],
  exports: [ConfirmationModalComponent],
})
export class ConfirmationModalModule {}
