import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@purespectrum1/ui/modal';
import { AuthService } from '../../auth/auth.service';
import { ServiceAccountModalComponent } from '../../layout/header/user-menu/service-account-modal/service-account-modal.component';
@Component({
  selector: 'ps-choose-suppliers',
  templateUrl: './choose-suppliers.component.html',
  styleUrls: ['./choose-suppliers.component.css'],
})
export class ChooseSuppliersComponent implements OnInit {
  public surveyId!: number;
  public auth = this._auth.getMarketplaceAuth;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _modal: ModalService,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    this.surveyId = this._route.snapshot?.params['id'];
  }

  goToDashboard(close: boolean) {
    if (close) {
      this._router.navigateByUrl('/dashboard');
    }
  }

  selectBuyer(open: boolean) {
    if (open) {
      this._modal.open(ServiceAccountModalComponent, {});
    }
  }
}
