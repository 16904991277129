export class Group<T> {
  constructor(private readonly _values: T[]) {}

  public by(delegate: (item: T) => boolean): [T[], T[]] {
    const groups: [T[], T[]] = [[], []];

    return this._values.reduce(
      ([group1, group2], next) =>
        delegate(next)
          ? [[...group1, next], group2]
          : [group1, [...group2, next]],
      groups
    );
  }
}
