<div class="counter-container">
  <span class="badge bg-primary grey_pill">
    {{ count.GREY }}
  </span>
  <span class="badge bg-primary green_pill">
    {{ count.GREEN }}
  </span>
  <span class="badge bg-primary yellow_pill">
    {{ count.YELLOW }}
  </span>
  <span class="badge bg-primary red_pill">
    {{ count.RED }}
  </span>
</div>
