import { Component, OnInit } from '@angular/core';

import { ToasterService } from '@purespectrum1/ui/toaster-service';

import { CompanyResponseObject } from '../shared/interfaces/company.interface';
import { CompanyService } from '../shared/services/company/company.service';
import {
  GetQuestionApiResponse,
  Category,
} from './questions-library-services/questions-library-services.interface';
import { QuestionsLibraryService } from './questions-library-services/questions-library-services.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'ps-questions-library',
  templateUrl: './questions-library.component.html',
  styleUrls: ['./questions-library.component.css'],
})
export class QuestionsLibraryComponent implements OnInit {
  public formMode: boolean = false;
  public isEditMode: boolean = false;
  public questionId!: number;
  public search: string = '';
  public questionData: GetQuestionApiResponse = <GetQuestionApiResponse>{};
  public companiesResponse: CompanyResponseObject[] = [];
  public isReloadQuestions: boolean = false;
  public categories: Category[] = [];
  public selectedCategories: string = '';
  public showCategoryFilter: boolean = false;
  public isUserHasQuestionLibraryAccess: boolean = false;

  constructor(
    private _questionsLibraryService: QuestionsLibraryService,
    private _toastr: ToasterService,
    private _companyService: CompanyService,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    this.isUserHasQuestionLibraryAccess =
      !!this._auth.user?.questionLibraryAccessLevel;
    this._getBuyerCompanies();
    this._getCategories();
  }

  getQualificationById(id: number) {
    this.questionId = id;
    this._questionsLibraryService.getQuestionById(id).subscribe(
      (response) => {
        this.questionData = response;
        this.formMode = true;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
  }

  private _getBuyerCompanies() {
    const payload = { isBuyr: true, isSpplr: false };
    const addAllOption = true;
    this._companyService.getAllBuyers(payload, addAllOption).subscribe(
      (res) => {
        this.companiesResponse = res.companies;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
  }

  private _getCategories() {
    this._questionsLibraryService.getCategories().subscribe(
      (categories: Category[]) => {
        this.categories = categories;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
  }

  public removeFilterCategories() {
    this.showCategoryFilter = !this.showCategoryFilter;
    this.filterCategories([]);
  }

  public filterCategories(selectedCategories: Category[]) {
    this.selectedCategories = selectedCategories
      .map((category) => category.id)
      .join(',');
  }
}
