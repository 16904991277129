import { HttpErrorResponse } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { delayWhen, retryWhen, tap } from 'rxjs/operators';

/**
 * Retry any 5xx or unknown server errors up to `retryTimes` amount.
 * It adds a delay for each additional failed attempt
 * @param retryTimes The number of times to retry 5xx response codes
 * @param delay The delay in ms to wait between each request
 */
export function retryServerError<T>(retryTimes: number, delay = 500) {
  let count = 0;

  return (source: Observable<T>) =>
    source.pipe(
      retryWhen((errors: Observable<HttpErrorResponse>) => {
        return errors.pipe(
          tap((error) => {
            count++;

            if (count === retryTimes || !errorIsUnknownOr500(error.status)) {
              throw error;
            }
          }),
          delayWhen(() => timer(count * delay))
        );
      })
    );
}

function errorIsUnknownOr500(errorStatus: number) {
  return errorStatus.toString(10).startsWith('5') || errorStatus === 0;
}
