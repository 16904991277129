import { EventEmitter, Injectable } from '@angular/core';
import { SurveyPayload } from './survey-meta-data/survey-meta-data.interface';

@Injectable({
  providedIn: 'root',
})
export class EventEmitterService {
  saveSurveyFunctionEmitter = new EventEmitter();
  selectMultiCountrySurveyEmitter = new EventEmitter();
  multiCountrySelectionChangeEmitter = new EventEmitter();
  sendFeasibilityCpiEmitter = new EventEmitter();
  getTcConfirmationEmitter = new EventEmitter();
  changeSurveyStatusEmitter = new EventEmitter();
  draftIsSaved = new EventEmitter();

  constructor() {}

  onSaveButtonClick() {
    this.saveSurveyFunctionEmitter.emit();
  }

  onSelectMultiCountrySurvey(surveyId: number) {
    this.selectMultiCountrySurveyEmitter.emit(surveyId);
  }

  onMultiCountrySelectionChange(survey?: SurveyPayload) {
    this.multiCountrySelectionChangeEmitter.emit(survey);
  }

  draftIsSavedEvent() {
    this.draftIsSaved.emit();
  }

  sendFeasibilityCPi(data: FeasibilityCpiData) {
    this.sendFeasibilityCpiEmitter.emit(data);
  }

  sendTcConfirmation(msg: string) {
    this.getTcConfirmationEmitter.emit(msg);
  }

  changeSurveyStatus(data: {
    surveyId: number;
    status: number;
    channelType?: string;
  }) {
    this.changeSurveyStatusEmitter.emit(data);
  }
}

interface FeasibilityCpiData {
  maxFeasibility: number;
  recommendedCpi: number;
  surveyPriceOverride: number;
  totalCpi: number;
  surveyId: number;
  expectedIr: number;
}
