import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { randomString } from '../utils/random-string';

@Injectable()
export class CaptchaInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this._requestIsNotLogin(request) || this._requestHasCaptcha(request)) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        captcha: this._generateCaptcha(request),
      },
    });
    return next.handle(request);
  }

  private _requestIsNotLogin(request: HttpRequest<any>): boolean {
    return !request.url.includes('/user/login');
  }

  private _requestHasCaptcha(request: HttpRequest<any>): boolean {
    return !!request.headers.get('captcha');
  }

  private _generateCaptcha(request: HttpRequest<any>): string {
    let string = randomString(590).split('');
    const stringLength = string.length;
    const user = request.headers.get('username')!;
    const [name, domain] = user?.split('@');

    for (let i = 0; i < name.length; i++) {
      if (name[i] === '.') {
        continue;
      }
      const code = name.charCodeAt(i);
      string[code % stringLength] = name[i];
    }

    for (let i = 0; i < domain.length; i++) {
      if (domain[i] === '.') {
        continue;
      }
      const code = domain.charCodeAt(i);
      string[code + (name.charCodeAt(i % name.length) % stringLength)] =
        domain[i];
    }

    const checksum = user
      .split('')
      .reduce((total, curr) => {
        return (total += curr.charCodeAt(0));
      }, 0)
      .toString();
    const checksumLocation = checksum.length + 50;

    const captcha = string.join('');

    return (
      captcha.slice(0, -checksumLocation) +
      checksum +
      captcha.slice(captcha.length - checksumLocation + checksum.length)
    );
  }
}
