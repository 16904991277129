import { Component, Input, OnInit } from '@angular/core';
import {
  HealthCheckCount,
  KPIS,
  McSurveyStats,
} from '../../../../shared/services/buyer-api/survey.interface';
import { Constants } from '../../../../dashboard/dashboard-constants';
import { SurveyHealthCheckMapper } from '../domain/survey-health-check-mapper';

@Component({
  selector: 'ps-health-check-count-display',
  templateUrl: './health-check-count-display.component.html',
  styleUrls: ['./health-check-count-display.component.css'],
})
export class HealthCheckCountDisplayComponent<
  T extends { healthCheck?: KPIS[]; mc?: McSurveyStats }
> implements OnInit
{
  @Input() survey = <T>{};
  public count: HealthCheckCount = Constants.HEALTH_CHECK.DEFAULT_COUNT;

  constructor() {}

  ngOnInit(): void {
    this.count = new SurveyHealthCheckMapper(this.survey).count();
  }
}
