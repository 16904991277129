import { Component, Inject, OnInit } from '@angular/core';
import { ModalData, ModalRef } from '@purespectrum1/ui/modal';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { ConfigSettingsModalData } from '../config-settings.component';
import { BuyerService } from '../../../shared/services/buyer/buyer.service';
import { notifyMessage } from '../../../constants/notify-message';

@Component({
  selector: 'ps-rn-credentials-modal',
  templateUrl: './rn-credentials-modal.component.html',
  styleUrls: ['./rn-credentials-modal.component.css'],
})
export class RnCredentialsModalComponent implements OnInit {
  clientId = '';
  password = '';
  username = '';

  constructor(
    @Inject(ModalData) private _modalData: ConfigSettingsModalData,
    private _modalRef: ModalRef<RnCredentialsModalComponent, string>,
    private _toastr: ToasterService,
    private _buyerService: BuyerService
  ) {}

  ngOnInit(): void {
    this._getBuyerRNdata();
  }

  close() {
    this._modalRef.close();
  }

  saveRnCredentials() {
    const { buyerId } = this._modalData;
    const sendRNDataRequest = {
      clientId: this.clientId,
      password: this.password,
      username: this.username,
      cmpId: buyerId,
    };
    this._buyerService.sendBuyerRNData(sendRNDataRequest).subscribe(
      (response) => {
        this.close();
        this._toastr.success(notifyMessage.successMessage.SUCCESSFULL_SAVED);
      },
      (error) => {
        this._toastr.error(notifyMessage.errorMessage.API_ERROR);
      }
    );
  }

  _getBuyerRNdata() {
    const { buyerId } = this._modalData;
    this._buyerService.getBuyerRNdata(buyerId).subscribe(
      (response) => {
        this.clientId = response.data.clientId;
        this.password = response.data.password;
        this.username = response.data.username;
      },
      (error) => {
        this._toastr.error(notifyMessage.errorMessage.API_ERROR);
      }
    );
  }

  checkAllFilledFields() {
    if (
      this.clientId &&
      this.clientId.trim() &&
      this.password &&
      this.password.trim() &&
      this.username &&
      this.username.trim()
    ) {
      return false;
    }
    return true;
  }
}
