import { Newrelic } from '@purespectrum1/ui/tracking';

declare const newrelic: Newrelic;

const stubNewRelic: Newrelic = {
  setUserId(userId: string | null) {
    // do nothing
  },
  noticeError(error: Error, customAttributes?: object) {
    // do nothing
  },
  addPageAcion: (name: string, customAttributes?: object) => {
    // do nothing
  },
  addRelease: (releaseName: string, releaseId: string) => {
    // do nothing
  },
  setCustomAttribute: (name: string, value, persist?) => {
    // do nothing
  },
};

/**
 * Newrelic is declared as a global variable when running in production but
 * it does not exist in other environments. This variable will stub
 * out any needed newrelic functionality if it's not defined
 */
export const _newrelic = typeof newrelic === 'object' ? newrelic : stubNewRelic;
