import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToasterService } from '@purespectrum1/ui';
import { Constants, messages } from '../supplier-settings-constant';

@Component({
  selector: 'ps-exclusion-settings',
  templateUrl: './exclusion-settings.component.html',
  styleUrls: ['../base-form.css'],
})
export class ExclusionSettingsComponent {
  @Input()
  form!: FormGroup;
  constructor(private _toastrService: ToasterService) {}
  public validateLOI(event: Event) {
    const _value = parseInt((<HTMLInputElement>event.target).value);
    if (_value > Constants.MAX_LOI_VALUE) {
      (<HTMLInputElement>event.target).value = '';
      this._toastrService.warning(messages.WARNING_MESSAGE.INCORRECT_LOI_VALUE);
      this.form.controls.max_loi.setValue('');
      return false;
    }
    return true;
  }
}
