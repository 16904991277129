import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  GetCompaniesAllDataResponse,
  GetSurveyPerformanceReportResponse,
  PayloadGetPerformanceRecord,
} from './survey-performance-reporta.interface';

@Injectable({
  providedIn: 'root',
})
export class SurveyPerformanceResportService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getCompaniesAllData(isSpplr: boolean, isBuyr: boolean) {
    return this._http.get<GetCompaniesAllDataResponse>(
      `${this._baseUrl}/invoice/companies?isSpplr=${isSpplr}&isBuyr=${isBuyr}`
    );
  }

  getPerformanceReport(
    startDate: string,
    endtDate: string,
    country: string,
    payload: PayloadGetPerformanceRecord
  ) {
    return this._http.post<GetSurveyPerformanceReportResponse>(
      `${this._baseUrl}/getPerformanceReport?startDate=${startDate}&endDate=${endtDate}&country_code=${country}`,
      payload
    );
  }
}
