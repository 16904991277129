<ps-ui-modal class="service-operator-modal">
  <ps-ui-modal-title>
    <h5 psUiModalTitle class="service-operator-title-text">
      {{ modalInfo.header }}
    </h5>
  </ps-ui-modal-title>
  <ps-ui-modal-body>
    <p class="service-operator-label-text">
      {{ modalInfo.label }}
    </p>
    <div class="offset-md-2 col-sm-8 col-md-8 col-lg-8 col-xl-8">
      <ps-ui-select-dropdown
        class="service-operator-dropdown"
        [field]="'name'"
        [dropdownItems]="projectManagers"
        labelNone="{{ modalInfo.labelNone }}"
        labelAlignment="start"
        [label]="selectedProjectManagers?.name"
        (selectedItem)="setSelectedProjectManager($event)"
      ></ps-ui-select-dropdown>
    </div>
    <button
      type="button"
      class="confirm-operator-button"
      (click)="filterSearchedProjectManagerSurveys()"
    >
      Confirm
    </button>
  </ps-ui-modal-body>
</ps-ui-modal>
