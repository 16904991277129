import { Component, Input } from '@angular/core';
import { Reconcilation } from '../adjustments-reconciliation-service/adjustments-reconciliation-service.interface';
@Component({
  selector: 'ps-reconcilation',
  templateUrl: './reconcilation.component.html',
  styleUrls: ['./reconcilation.component.css'],
})
export class ReconcilationComponent {
  @Input() reconciliationList: Array<Reconcilation> = [];
  @Input() userId!: number;
  @Input() userName: string = '';
  operationType: string = 'RECON';
  constructor() {}
}
