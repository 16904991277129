import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ps-redirect-url',
  templateUrl: './redirect-url.component.html',
  styleUrls: ['../base-form.css'],
})
export class RedirectUrlComponent {
  @Input()
  form!: FormGroup;
  constructor() {}
}
