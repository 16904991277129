import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { Constants } from '../operator/operator.constant';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private _router: Router, private _auth: AuthService) {}

  private readonly _pagesThatNeedTheBuyerToken: Array<string> =
    Constants.PAGES_THAT_NEED_BUYER_TOKEN;

  private readonly _routesThatNeedBuyerToken: Array<string> =
    Constants.ROUTES_THAT_NEED_BUYER_TOKEN;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get('skipInterceptor')) {
      request = request.clone({
        headers: request.headers.delete('skipInterceptor'),
      });
      return next.handle(request);
    }

    if (!this._auth.token) {
      this._router.navigateByUrl('/login');
    }

    const accessToken = this._getAccessToken(request.url);
    request = request.clone({
      setHeaders: {
        'access-token': accessToken,
        'service-user-id':
          this._auth &&
          this._auth.user &&
          this._auth.user.id &&
          this._auth.user.operatorAcssLvls === 'serviceOperator'
            ? this._auth.user.id.toString()
            : '',
      },
    });
    return next.handle(request);
  }

  private _getAccessToken(url: string) {
    let accessToken = this._auth.token!;

    if (this._auth.isLoggedInAsServiceBuyer()) {
      const isOperatorExclusiveRequest = this._routesThatNeedBuyerToken.some(
        (route) => url.includes(route)
      );
      const isServiceBuyerTokenNeeded = this._pagesThatNeedTheBuyerToken.some(
        (page) => this._router.url.includes(page)
      );
      if (!isOperatorExclusiveRequest && isServiceBuyerTokenNeeded) {
        accessToken = this._auth.serviceBuyer!.token;
      }
    }
    return accessToken;
  }
}
