import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TEMPLATE_FILE_PATHS } from '../../../constants/template-file-path';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { notifyMessage } from '../../../constants/notify-message';
import { AuthService } from '../../../auth/auth.service';
import { SupplierService } from '../../supplier-service/supplier.service';
import { saveAs } from 'file-saver';
import {
  DeactivateSupplierPayload,
  ManualPriceCardPayload,
  ObjectType,
} from '../../../shared/interfaces/supplier-pricing-card-interface';

@Component({
  selector: 'ps-manual-rate-card',
  templateUrl: './manual-rate-card.component.html',
  styleUrls: ['./manual-rate-card.component.css'],
})
export class ManualRateCardComponent {
  @Input() countryId: number = 1;
  @Input() languageId: number = 1;
  @Input() buyerId: number = 0;
  @Input() manualRateCardUploaded: boolean = false;
  @Output() readonly cancelManualRateCard = new EventEmitter();
  csvUploaded: boolean = false;
  header: boolean = false;
  manualRatecardTemplate: string = TEMPLATE_FILE_PATHS.MANUAL_PRICE_CARD;
  csvFileDataInJson!: ObjectType;
  constructor(
    private _toastr: ToasterService,
    private _auth: AuthService,
    private _supplierService: SupplierService
  ) {}

  public uploadCsvFile(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      //convert text to json here
      this.csvFileDataInJson = this._csvToJSON(text);
    };
    reader.readAsText(file);
  }

  public saveRateCard() {
    const { id: user_id, cmp: supplier_id } = this._auth.user!;
    const manualPriceCard: ManualPriceCardPayload = {
      supplier_id,
      user_id,
      buyerCounterParty: this.buyerId,
      price_card: [this.csvFileDataInJson],
    };
    this._supplierService
      .manualRateFileUpload(this.languageId, this.countryId, manualPriceCard)
      .subscribe(
        (res) => {
          this.csvUploaded = false;
          this.manualRateCardUploaded = true;
          this.csvFileDataInJson = {};
          this._toastr.success(res.msg);
        },
        (err) => {
          const errorMessage = err.error.msg;
          this._toastr.error(errorMessage);
        }
      );
  }

  private _csvToJSON(csv: string | ArrayBuffer | null) {
    const lines = (csv as string).split('\n');
    let hasError = false;
    let rowCount = 0;
    const result: { [key: string]: object }[] = [];
    const headers = lines[0].split(',');
    for (let i = 1; i < lines.length; i++) {
      let obj: ObjectType = {};
      let currentline = lines[i].split(',');
      for (let j = 0; j < headers.length; j++) {
        hasError = hasError || this._checkForError(i, currentline, headers, j);
        if (this._checkLinesToExclude(headers[j], currentline[j])) {
          hasError = hasError || this._checkIfValueIsNumber(currentline, j);
          obj[headers[j]] = Number(currentline[j].replace(/^\D+/g, ''));
        }
        rowCount++;
      }
      if (Object.keys(obj).length) {
        result.push(obj);
      }
    }
    if (hasError || rowCount < 10000) {
      this._toastr.error(
        notifyMessage.errorMessage.SUPPLIER_RATE_CARD.INVALID_CSV_FILE
      );
      return {};
    } else {
      this.csvUploaded = true;
      return this._convertArrayIntoObject(result);
    }
  }

  private _convertArrayIntoObject(array: ObjectType[]) {
    const object: { [key: string]: object } = {};
    array.forEach((elem: object, index: number) => {
      object[(index + 1) as keyof { [key: string]: object }] = elem;
    });
    return object;
  }

  private _checkLinesToExclude(
    headerValue: number | string,
    currentLineValue: string
  ) {
    return (
      headerValue !== '' && !['', undefined, null].includes(currentLineValue)
    );
  }

  private _checkIfValueIsNumber(currentLine: string[], headerIndex: number) {
    let hasError = false;
    if (
      !currentLine[headerIndex] ||
      isNaN(Number(currentLine[headerIndex].replace(/^\D+/g, '')))
    ) {
      hasError = true;
    }
    return hasError;
  }

  private _checkForError(
    lineIndex: number,
    currentLine: string[],
    headers: string[],
    headerIndex: number
  ) {
    let hasError = false;
    if (lineIndex == 100 && currentLine[0] !== '100%') {
      hasError = true;
    }
    if (headers[headerIndex] === '' && headerIndex !== 0 && headerIndex < 100) {
      hasError = true;
    }
    return hasError;
  }

  public dectivateManualRateCard() {
    const deactivateSupplierPayload: DeactivateSupplierPayload = {
      language: this.languageId,
      country: this.countryId,
      buyerCounterParty: this.buyerId,
    };
    this._supplierService
      .decativateManualRateCard(deactivateSupplierPayload)
      .subscribe(
        (res) => {
          this.manualRateCardUploaded = false;
          this.csvUploaded = false;
          this.csvFileDataInJson = {};
          this._toastr.success(res.msg);
        },
        (err) => {
          const errorMessage = err.error.msg;
          this._toastr.error(errorMessage);
        }
      );
  }

  public emitCancelManualRateCard() {
    this.cancelManualRateCard.emit();
  }

  public downloadLatestUpload() {
    const payload = {
      languageId: this.languageId,
      countryId: this.countryId,
      buyerId: this.buyerId,
    };

    this._supplierService.downloadManualRateCard(payload).subscribe(
      (res) => {
        const supplierManualCard = res.replace(res.charAt(0), '');
        const fileName = `ManulaPriceCard_${this.languageId}_${this.countryId}_${this.buyerId}.csv`;
        let blob = new Blob([supplierManualCard], { type: 'text/csv' });
        saveAs(blob, fileName);
      },
      (err) => {
        const errorMessage = err.error.msg;
        this._toastr.error(errorMessage);
      }
    );
  }
}
