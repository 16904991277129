<div class="buyer-tabs" [formGroup]="form">
  <div *ngIf="!isDecipherHashSelected()" class="heading-tab">
    <p>
      If you enable SHA1 hashing, all respondents returning from your survey
      must include an SHA1 hash for their url varibales.Only enable this feature
      after discussing technical requirements with Pure Spectrum Support
    </p>
    <p>
      For every transaction we have appended the Transaction ID (transaction_id)
      and Status (st). Please note that the Transaction ID may be mapped to a
      different name in your system: pid, rid, or something else. You can select
      the variables that you would like mapped in the ‘Variable Mapping’ tab.
    </p>
  </div>
  <div class="form-group">
    <label class="col-md-4 control-label"> Enable Decipher SHA1 Hashing</label>
    <div class="col-md-6">
      <label class="radio-inline">
        <input
          type="radio"
          name="is_decipher_hash"
          [value]="true"
          id="Enable"
          class="form-check-input"
          formControlName="is_decipher_hash"
          (change)="checkHashing($event)"
        />
        Yes
      </label>
      <label class="radio-inline">
        <input
          type="radio"
          name="is_decipher_hash"
          [value]="false"
          id="NotEnable"
          class="form-check-input"
          formControlName="is_decipher_hash"
          (change)="checkHashing($event)"
        />
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="col-md-4 control-label"> Enable SHA1 Hashing</label>
    <div class="col-md-6">
      <label class="radio-inline">
        <input
          type="radio"
          name="isSHA1"
          [value]="true"
          id="Enable"
          class="form-check-input"
          formControlName="isSHA1"
          (change)="checkHashing($event)"
        />
        Yes
      </label>
      <label class="radio-inline">
        <input
          type="radio"
          name="isSHA1"
          [value]="false"
          id="NotEnable"
          class="form-check-input"
          formControlName="isSHA1"
          (change)="checkHashing($event)"
        />
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="col-md-4 control-label"> Enable SHA2 Hashing</label>
    <div class="col-md-6">
      <label class="radio-inline">
        <input
          type="radio"
          name="isSHA2"
          [value]="true"
          id="Enable"
          class="form-check-input"
          formControlName="isSHA2"
          (change)="checkHashing($event)"
        />
        Yes
      </label>
      <label class="radio-inline">
        <input
          type="radio"
          name="isSHA2"
          [value]="false"
          id="NotEnable"
          class="form-check-input"
          formControlName="isSHA2"
          (change)="checkHashing($event)"
        />
        No
      </label>
    </div>
  </div>
  <div
    class="form-group"
    *ngIf="
      form.value.isSHA1 || form.value.isSHA2 || form.value.is_decipher_hash
    "
  >
    <label class="col-md-4 control-label"> Enable Outbound Hashing</label>
    <div class="col-md-6">
      <label class="radio-inline">
        <input
          type="radio"
          name="isOutboundHash"
          [value]="true"
          id="Enable"
          class="form-check-input"
          formControlName="isOutboundHash"
          (change)="checkHashing($event)"
        />
        Yes
      </label>
      <label class="radio-inline">
        <input
          type="radio"
          name="isOutboundHash"
          [value]="false"
          id="NotEnable"
          class="form-check-input"
          formControlName="isOutboundHash"
        />
        No
      </label>
    </div>
  </div>
  <div *ngIf="!isDecipherHashSelected()" class="form-group">
    <label class="control-label col-md-4">Shared Private Key</label>
    <div class="col-md-6">
      <input
        name="private_key"
        type="text"
        placeholder="Private Key for Hash"
        class="form-control input-md"
        formControlName="private_key"
      />
    </div>
  </div>
  <div *ngIf="!isDecipherHashSelected()" class="form-group">
    <label class="control-label col-md-4">Key Name</label>
    <div class="col-md-6">
      <input
        name="private_key"
        type="text"
        placeholder=" Key Name"
        class="form-control input-md"
        formControlName="psHashIn"
      />
    </div>
  </div>
  <div *ngIf="isDecipherHashSelected()" class="form-group">
    <label class="control-label col-md-4">D Keyring</label>
    <div class="col-md-6">
      <select
        name="decipher_hash_selector"
        class="form-select"
        formControlName="decipherHashKeyID"
      >
        <option value="" disabled selected>Select Keyring</option>
        <option *ngFor="let keyId of decipherHashKeyIDs" [value]="keyId.id">
          {{ keyId.id }}
        </option>
      </select>
    </div>
  </div>
</div>
