import {
  EmptyFilter,
  Filterable,
  FilterDelegate,
} from '@purespectrum1/ui/table';
import { Group } from '../../../shared/types/group';
import { SurveyListing } from '../../../shared/services/buyer-api/survey.interface';
import { Constants } from '../../dashboard-constants';

export class HealthCheckTableFilter implements Filterable<SurveyListing> {
  private readonly _status = [
    'RED',
    'YELLOW',
    'GREEN',
    'GREY',
    'NOT AVAILABLE',
  ];

  constructor(private readonly _values: string[]) {}

  public allow(survey: SurveyListing): boolean {
    const health = survey.healthCheck ?? [];
    const notAvailable = this._values.includes('NOT AVAILABLE');

    if (notAvailable && health.length === 0) {
      return true;
    }

    const [status, categories] = new Group(this._values).by((value) =>
      this._status.includes(value)
    );

    return health.some(
      (kpis) =>
        this._includes(categories, kpis.name) &&
        this._includes(status, kpis.status_text)
    );
  }

  private _includes(filters: string[], value: string): boolean {
    if (filters.length === 0) {
      return true;
    }

    return filters.includes(value);
  }
}

export class TableHeaderColumnFilter implements Filterable<SurveyListing> {
  constructor(
    private readonly _values: string[],
    private readonly _key: keyof SurveyListing
  ) {}

  public allow(element: SurveyListing): boolean {
    const empty = this._values.includes('EMPTY');
    const notEmpty = this._values.includes('NOT EMPTY');

    if (empty && notEmpty) {
      return true;
    }

    if (empty) {
      return !element[this._key];
    }

    if (notEmpty) {
      return !!element[this._key];
    }

    return true;
  }
}

export class SurveyTableFilterDelegate
  implements FilterDelegate<SurveyListing>
{
  public filter(field: string, values: string[]): Filterable<SurveyListing> {
    if (field === Constants.SURVEY_LISTING_TABLE_HEADERS.HEALTH_CHECK) {
      return new HealthCheckTableFilter(values);
    }

    if (
      [
        Constants.SURVEY_LISTING_TABLE_HEADERS.BILLING_NUMBER,
        Constants.SURVEY_LISTING_TABLE_HEADERS.CRM_ID,
        Constants.SURVEY_LISTING_TABLE_HEADERS.OPERATOR_PROJECT_MANAGER,
        Constants.SURVEY_LISTING_TABLE_HEADERS.BUYER_PROJECT_MANAGER,
      ].includes(field)
    ) {
      return new TableHeaderColumnFilter(values, field as keyof SurveyListing);
    }

    return new EmptyFilter();
  }
}
