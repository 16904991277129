<div class="row table-responsive table-absolute mt-5">
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th scope="col" data-order="" data-name="_id" [psSortTable]="dataList">
          Survey Id
        </th>
        <th
          scope="col"
          data-order=""
          data-name="surveyName"
          [psSortTable]="dataList"
        >
          Survey Name
        </th>
        <th
          scope="col"
          data-order=""
          data-name="surveyPo"
          [psSortTable]="dataList"
        >
          PO
        </th>
        <th
          scope="col"
          data-order=""
          data-name="bName"
          [psSortTable]="dataList"
        >
          Buyer Name
        </th>
        <th
          scope="col"
          data-order=""
          data-name="country"
          [psSortTable]="dataList"
        >
          Country
        </th>
        <th scope="col" data-order="" data-name="cpi" [psSortTable]="dataList">
          CPI
        </th>
        <th
          scope="col"
          data-order=""
          data-name="buyerRevenue"
          [psSortTable]="dataList"
        >
          Total Revenue
        </th>
        <th
          scope="col"
          data-order=""
          data-name="supplierRevenue"
          [psSortTable]="dataList"
        >
          Total Cost
        </th>
        <th
          scope="col"
          data-order=""
          data-name="margin"
          [psSortTable]="dataList"
        >
          Margin
        </th>
        <th
          scope="col"
          data-order=""
          data-name="completesInGivenTimeFrame"
          [psSortTable]="dataList"
        >
          Completes In given Time Frame
        </th>
        <th
          scope="col"
          data-order=""
          data-name="totalCompletes"
          [psSortTable]="dataList"
        >
          Total Completes
        </th>
        <th
          scope="col"
          data-order=""
          data-name="completesRemaining"
          [psSortTable]="dataList"
        >
          Completes Remaining
        </th>
        <th
          scope="col"
          data-order=""
          data-name="loi_launch"
          [psSortTable]="dataList"
        >
          Stated LOI
        </th>
        <th
          scope="col"
          data-order=""
          data-name="incidence_launch"
          [psSortTable]="dataList"
        >
          Stated IR
        </th>
        <th scope="col" data-order="" data-name="loi" [psSortTable]="dataList">
          Current LOI
        </th>
        <th
          scope="col"
          data-order=""
          data-name="incidence_current"
          [psSortTable]="dataList"
        >
          Current IR
        </th>
        <th
          scope="col"
          data-order=""
          data-name="buyerClicks"
          [psSortTable]="dataList"
        >
          Buyer Clicks
        </th>
        <th
          scope="col"
          data-order=""
          data-name="validClicks"
          [psSortTable]="dataList"
        >
          Valid Clicks
        </th>
        <th
          scope="col"
          data-order=""
          data-name="totalClicks"
          [psSortTable]="dataList"
        >
          Total Clicks
        </th>
        <th
          scope="col"
          data-order=""
          data-name="buyerEPC"
          [psSortTable]="dataList"
        >
          Buyer EPC
        </th>
        <th
          scope="col"
          data-order=""
          data-name="validEPC"
          [psSortTable]="dataList"
        >
          Valid EPC
        </th>
        <th
          scope="col"
          data-order=""
          data-name="totalEPC"
          [psSortTable]="dataList"
        >
          Total EPC
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of dataList">
        <td>{{ item._id }}</td>
        <td>{{ item.surveyName }}</td>
        <td>{{ item.surveyPo }}</td>
        <td>{{ item.bName }}</td>
        <td>{{ item.country }}</td>
        <td>{{ item.cpi || 0 | number : '1.2-2' }}</td>
        <td>{{ item.buyerRevenue || 0 | number : '1.2-2' }}</td>
        <td>{{ item.supplierRevenue || 0 | number : '1.2-2' }}</td>
        <td>{{ item.margin || 0 }}</td>
        <td>{{ item.completesInTimeFrame || 0 }}</td>
        <td>{{ item.totalCompletes || 0 }}</td>
        <td>{{ item.completesRemaining || 0 }}</td>
        <td>{{ item.loi_launch || 0 }}</td>
        <td>{{ item.incidence_launch || 0 }}</td>
        <td>{{ item.loi || 0 }}</td>
        <td>{{ item.incidence_current || 0 }}</td>
        <td>{{ item.buyerClicks || 0 }}</td>
        <td>{{ item.validClicks || 0 }}</td>
        <td>{{ item.totalClicks || 0 }}</td>
        <td>{{ item.buyerEPC || 0 }}</td>
        <td>{{ item.validEPC || 0 }}</td>
        <td>{{ item.totalEPC || 0 }}</td>
      </tr>
    </tbody>
  </table>
</div>
