import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { AuthService } from '../../../auth/auth.service';
import { UserService } from '../../../operator/user-service/user.service';
import { SurveyMetaDataService } from '../../../shared/services/survey-meta-data/survey-meta-data.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { AssignOpeartorConstants } from '../assign-project-manager-tab/assign-project-manager-constants';

import {
  ProjectManagers,
  BpmPayload,
} from '../../../operator/user-service/user.interface';
import { User } from '../../../shared/types/user.interface';
import { notifyMessage } from '../../../constants/notify-message';

@Component({
  selector: 'ps-assign-buyer-project-manager',
  templateUrl: './assign-buyer-project-manager.component.html',
  styleUrls: ['./assign-buyer-project-manager.component.css'],
})
export class AssignBuyerProjectManagerComponent
  implements AfterViewChecked, OnInit, OnDestroy
{
  private _subscription: Subscription = new Subscription();

  searchText: string = '';
  public currentBuyerProjectManager!: string | undefined;
  public buyerProjectManagerAssigned = false;
  public isBuyer: boolean = false;
  public userDetails?: User = {} as User;
  public surveyId?: number;
  public allBuyerUsers: ProjectManagers[] = [];
  public buyerCompany?: number;

  constructor(
    private _userService: UserService,
    private _toastr: ToasterService,
    private _surveyMetaDataService: SurveyMetaDataService,
    private _authService: AuthService,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.userDetails = this._authService.user;
    this.isBuyer = this.userDetails?.buyerAcssLvls !== 'none' ? true : false;
    this._getSurveyHeaderData();
  }

  setupSearchInput = function (event: Event) {
    event.preventDefault();
    event.stopPropagation();
  };

  ngAfterViewChecked(): void {
    this._cd.detectChanges();
  }

  private _getSurveyHeaderData() {
    this._surveyMetaDataService
      .getSurveyHeaderData()
      .subscribe((surveyResponse) => {
        this.surveyId = surveyResponse.surveyId;
        this.buyerCompany = surveyResponse.buyerCompany;
        if (
          surveyResponse &&
          surveyResponse.bpmObj &&
          surveyResponse.bpmObj.nm
        ) {
          this.buyerProjectManagerAssigned = true;
          this.currentBuyerProjectManager = surveyResponse.bpmObj.nm;
        } else {
          this.buyerProjectManagerAssigned = false;
          this.currentBuyerProjectManager = '';
        }
        if (this.buyerCompany) {
          this._getAllBuyers();
        }
      });
  }

  private _getAllBuyers() {
    const buyerSubscription = this._userService
      .getAllBuyerUser(this.buyerCompany || 0, false)
      .subscribe(
        (response) => {
          this.allBuyerUsers = response;
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscription.add(buyerSubscription);
  }

  setBuyerProjectManager(operatorData: ProjectManagers) {
    const buyerPmPayload = {
      id: operatorData.id,
      nm: operatorData.name,
      eml: operatorData.email,
    };
    const payload = { bpm: buyerPmPayload };
    this._addOrRemoveBuyerProjectManager(payload, AssignOpeartorConstants.ADD);
  }

  setThisBuyer() {
    const buyerPmPayload = {
      id: Number(this.userDetails?.id),
      nm: this.userDetails?.usrName,
      eml: this.userDetails?.eml,
    };
    const payload = { bpm: buyerPmPayload };
    this._addOrRemoveBuyerProjectManager(payload, AssignOpeartorConstants.ADD);
  }

  private _addOrRemoveBuyerProjectManager(
    payload: BpmPayload,
    condition: string
  ) {
    const userId = payload.bpm.id;
    this._userService
      .updateBuyerProjectManager(this.surveyId || 0, userId, condition)
      .subscribe((_result) => {
        this.currentBuyerProjectManager = payload?.bpm.nm;
        this.buyerProjectManagerAssigned = true;
        this._getNotificationsAsPerConditions(condition);
      });
  }

  private _getNotificationsAsPerConditions(condition: string) {
    if (condition === AssignOpeartorConstants.REMOVE) {
      this._toastr.success(
        notifyMessage.successMessage.BUYER_PROJECT_MANAGER_REMOVED
      );
      this.currentBuyerProjectManager = '';
      this.buyerProjectManagerAssigned = false;
    } else {
      this._toastr.success(
        notifyMessage.successMessage.BUYER_PROJECT_MANAGER_ASSIGNED
      );
    }
  }

  removeBuyerProjectManager() {
    const payload = { bpm: {} };
    this._addOrRemoveBuyerProjectManager(
      payload,
      AssignOpeartorConstants.REMOVE
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
    this._cd.detach();
  }
}
