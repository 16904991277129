import { Component } from '@angular/core';

@Component({
  selector: 'ps-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css'],
})
export class AccessDeniedComponent {
  constructor() {}
}
