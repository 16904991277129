import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ModalData, ModalRef } from '@purespectrum1/ui';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import {
  Answer,
  QualityRule,
  ParentQuestion,
} from '../../../../questions-library-services/questions-library-services.interface';
import { QuestionsLibraryService } from '../../../../questions-library-services/questions-library-services.service';
import { notifyMessage } from '../../../../../constants/notify-message';
import { Constants } from '../../../../questions-library.constants';

@Component({
  selector: 'ps-search-qualifications-model',
  templateUrl: './search-qualifications-model.component.html',
  styleUrls: ['./search-qualifications-model.component.css'],
})
export class SearchQualificationsModelComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  public options: Answer[] = [];
  public questionslist: ParentQuestion[] = [];
  private _subscriptions: Subscription = new Subscription();
  readonly QUESTION_TYPE = Constants.QUESTION_TYPE;
  readonly QUALIFICATION_TYPE = Constants.QUALIFICATION_TYPE;

  constructor(
    @Inject(ModalData)
    private _modalData: {
      locale: string;
      setQualityRule: Function;
    },
    private _fb: FormBuilder,
    private _toastr: ToasterService,
    private _questionLibraryService: QuestionsLibraryService,
    private _modalRef: ModalRef<
      SearchQualificationsModelComponent,
      {
        msg: string;
        qualityRule: QualityRule;
      }
    >
  ) {}

  ngOnInit(): void {
    this._generateForm();
    this._getQuestions();
  }

  private _generateForm() {
    this.form = this._fb.group({
      selectedQualification: ['', Validators.required],
      selectedOptions: [
        '',
        (control: AbstractControl) => {
          if (control.parent && !this.isRangeQuestion()) {
            return Validators.required(control);
          }
          return null;
        },
      ],
      condition: ['or'],
      range: this._fb.group({
        min: [''],
        max: [''],
      }),
    });
  }

  private _getQuestions() {
    const parentQuestions = this._questionLibraryService
      .getParentQuestions(this._modalData.locale, 0, 1)
      .subscribe(
        (countriesData) => {
          this.questionslist = countriesData;
        },
        (err) => {
          this._toastr.error(err.error.msg);
        }
      );
    this._subscriptions.add(parentQuestions);
  }

  selectQualification(qualification: ParentQuestion) {
    this.form.patchValue({ selectedQualification: qualification });
    this.options = qualification.answers;
    this.form.patchValue({ selectedOptions: '' });
  }

  isRangeQuestion() {
    const questionType: string = this.form.get('selectedQualification')?.value
      .question_type;
    return (
      questionType &&
      !(
        questionType ===
          this.QUALIFICATION_TYPE[this.QUESTION_TYPE.SINGLEPUNCH].key ||
        questionType ===
          this.QUALIFICATION_TYPE[this.QUESTION_TYPE.MULTIPUNCH].key
      )
    );
  }

  onSubmit() {
    if (this.form.valid) {
      const formData = this.form.getRawValue();
      const qId = formData.selectedQualification.qualification_id;
      const options = formData.selectedOptions;
      const qType = formData.selectedQualification.question_type;
      const range = formData.range;
      const condition = formData.condition;
      // close model and send data to component
      this._modalRef.close({
        msg: 'save',
        qualityRule: this._modalData.setQualityRule(
          qId,
          options,
          qType,
          condition,
          range
        ),
      });
    } else {
      this._toastr.warning(notifyMessage.warningMessage.INCOMPLETE_FIELDS);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
