<ps-ui-modal class="change-survey-status" [showButtonClose]="false">
  <ps-ui-modal-title>
    <h5 psUiModalTitle>{{ template?.title }}</h5>
    <button
      type="button"
      class="btn-close status-modal-close-button"
      aria-label="Close"
      (click)="close()"
    ></button>
  </ps-ui-modal-title>
  <ps-ui-modal-body>
    <p
      *ngIf="!showSuccessMessage"
      class="text-center change-survey-status-body"
    >
      {{ template?.body }}
    </p>
    <p class="text-center" *ngIf="showPauseThersholdMessage(survey)">
      {{ template?.subtext }} {{ survey.currencySymbol }}{{ survey.currentCpi }}
    </p>
    <div class="text-center">
      <a
        *ngIf="
          !survey.toDelete && survey.status === 'Paused' && !showSuccessMessage
        "
        class="status-change-yes"
        (click)="updateSurveyStatus()"
      >
        <span *ngIf="!showSuccessMessage"> Yes, PAUSE this project. </span>
      </a>
      <a
        *ngIf="
          !survey.toDelete && survey.status === 'Live' && !showSuccessMessage
        "
        class="status-change-yes"
        (click)="checkForHighCPI()"
      >
        <span *ngIf="!showSuccessMessage"> Yes, make this project LIVE. </span>
      </a>
      <a
        *ngIf="
          !survey.toDelete && survey.status === 'Closed' && !showSuccessMessage
        "
        class="status-change-yes"
        (click)="updateSurveyStatus()"
      >
        <span *ngIf="!showSuccessMessage"> Yes, CLOSE this project. </span></a
      >
      <a
        *ngIf="
          !survey.toDelete && survey.status === 'Invoice' && !showSuccessMessage
        "
        class="status-change-yes"
        (click)="updateSurveyStatus()"
      >
        <span *ngIf="!showSuccessMessage"> Yes, INVOICE this project. </span></a
      >
      <a
        *ngIf="survey.toDelete && !showSuccessMessage"
        class="status-change-yes"
        (click)="deleteSurvey()"
        >{{ template?.delete }}</a
      >
      <a
        *ngIf="!showSuccessMessage"
        class="status-change-no"
        (click)="close('closeModal')"
      >
        {{ template?.close }}
      </a>
    </div>
    <div class="survey-state-success text-center">
      <p>
        <span *ngIf="showSuccessMessage">Success!</span> {{ statusUpdateMsg }}
      </p>
    </div>
  </ps-ui-modal-body>
</ps-ui-modal>
