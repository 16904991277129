<div class="counter_pill" *ngIf="hasHealthCheckData; else noKpis">
  <div class="inner_pill">
    <div class="box_lap">
      <ng-container
        *ngIf="survey.mc?.SVP && survey.mc?.parent; else defaultContent"
      >
        <ps-health-check-count-display
          [survey]="survey"
        ></ps-health-check-count-display>
        <div class="dropdown">
          <button
            class="dropdown-toggle plus_pill"
            type="button"
            id="HealthCheckdropdownMenu"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ></button>

          <div
            #mcHealthDropdownContainer
            class="dropdown-menu health-check-dropdown-container position-absolute"
          >
            <ng-container *ngFor="let childSurvey of mcSvpChildsWithHealth">
              <div
                class="d-flex justify-content-between width-110"
                [ngClass]="{
                  'hovered-row':
                    (hoveredMcSvpChildWithHealth$ | async)?.surveyId ===
                    childSurvey.surveyId
                }"
                (mouseenter)="onHover(childSurvey.surveyId)"
                (mouseleave)="onHover(null)"
                (click)="
                  redirectToTheSurveyDashboard({
                    survey_id: surveyId,
                    svp_child_id: childSurvey.surveyId
                  })
                "
              >
                <div class="d-flex justify-content-between">
                  <div class="mx-1 px-0 py-2 country-name-column">
                    <ng-container *ngIf="childSurvey.healthCheck?.length">
                      <span> {{ childSurvey.countryName }} </span>
                    </ng-container>
                  </div>

                  <div class="mx-1 px-0 py-2">
                    <ng-container *ngIf="childSurvey.healthCheck?.length">
                      <span class="p-1 fw-bold language-icon">
                        {{ childSurvey.languageCode | uppercase }}
                      </span>
                    </ng-container>
                  </div>

                  <div class="mx-1 mc-health-widget-info">
                    <ps-health-check-count-display
                      *ngIf="childSurvey.healthCheck?.length"
                      class="position-absolute"
                      [survey]="childSurvey"
                    ></ps-health-check-count-display>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <ng-container
            *ngIf="
              hoveredMcSvpChildWithHealth$
                | async as hoveredMcSvpChildWithHealth
            "
          >
            <div class="position-relative">
              <div #mcKpiDropdownContainer class="child-kpi-dropdown-container">
                <ps-health-check-kpi-dropdown
                  class="d-block dropdown-menu"
                  [survey]="hoveredMcSvpChildWithHealth"
                  [surveyId]="hoveredMcSvpChildWithHealth.surveyId"
                  (mouseenter)="onHover(hoveredMcSvpChildWithHealth.surveyId)"
                  (mouseleave)="onHover(null)"
                  (selectKpi)="
                    redirectToTheSurveyDashboard({
                      survey_id: surveyId,
                      svp_child_id: hoveredMcSvpChildWithHealth.surveyId
                    })
                  "
                ></ps-health-check-kpi-dropdown>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #defaultContent>
        <ps-health-check-count-display
          [survey]="survey"
        ></ps-health-check-count-display>
        <div class="dropdown">
          <button
            class="dropdown-toggle plus_pill"
            type="button"
            id="HealthCheckdropdownMenu"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ></button>
          <ps-health-check-kpi-dropdown
            class="dropdown-menu"
            [survey]="survey"
            [surveyId]="surveyId"
            (selectKpi)="redirectToTheSurveyDashboard($event)"
          ></ps-health-check-kpi-dropdown>
        </div>
      </ng-template>
    </div>
  </div>
  <a
    class="survey-link"
    (click)="redirectToTheSurveyDashboard({ survey_id: surveyId })"
  >
    SEE MORE IN DETAILS <i class="fa fa-angle-right" aria-hidden="true"></i>
  </a>
</div>
<ng-template data-no-kpi="no-data-template" #noKpis>
  <p data-no-kpi="no-kpi">No Data Available</p>
</ng-template>
