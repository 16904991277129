export class Pagination {
  constructor(public readonly page: number, public readonly limit: number) {}
  static first(limit = 10) {
    return new Pagination(1, limit);
  }
  static to(page: number, limit = 10) {
    return new Pagination(page, limit);
  }
  next(): Pagination {
    return new Pagination(this.page + 1, this.limit);
  }
  prev(): Pagination {
    if (this.page === 1) {
      return Pagination.first(this.limit);
    }
    return new Pagination(this.page - 1, this.limit);
  }
  raw() {
    return {
      page: this.page.toString(),
      limit: this.limit.toString(),
    };
  }
}
