import { Component, Inject } from '@angular/core';

import { ModalData, ModalRef } from '@purespectrum1/ui/modal';

import { ProjectManagers } from 'src/app/operator/user-service/user.interface';

@Component({
  selector: 'ps-project-manager-search-modal',
  templateUrl: './project-manager-search-modal.component.html',
  styleUrls: ['./project-manager-search-modal.component.css'],
})
export class ProjectManagerSearchModalComponent {
  public projectManagers: ProjectManagers[] = this._modalData.projectManagers;
  public selectedProjectManagers!: ProjectManagers;
  public isOperator: boolean = this._modalData.isOperator;
  public modalInfo = {
    header: 'Select Project Manager',
    label: 'Select the Project Manager to View Surveys',
    labelNone: 'Choose Project Manager',
  };
  constructor(
    @Inject(ModalData)
    private _modalData: {
      projectManagers: ProjectManagers[];
      isOperator: boolean;
    },
    private _modalRef: ModalRef<
      ProjectManagerSearchModalComponent,
      { msg: string; projectManagers: ProjectManagers }
    >
  ) {
    if (this.isOperator) {
      this.modalInfo = {
        header: 'Select Operator',
        label: 'Select the Operator to View Project',
        labelNone: 'Choose Operator',
      };
    }
  }

  setSelectedProjectManager(projectManager: ProjectManagers) {
    this.selectedProjectManagers = projectManager;
  }

  filterSearchedProjectManagerSurveys() {
    this._modalRef.close({
      msg: 'confirm',
      projectManagers: this.selectedProjectManagers,
    });
  }
}
