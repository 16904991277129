import { Component, Input } from '@angular/core';
import { ManualFix } from '../adjustments-reconciliation-service/adjustments-reconciliation-service.interface';

@Component({
  selector: 'ps-manual-fix',
  templateUrl: './manual-fix.component.html',
  styleUrls: ['./manual-fix.component.css'],
})
export class ManualFixComponent {
  @Input() manualFixList: Array<ManualFix> = [];
  @Input() userId!: number;
  @Input() userName: string = '';
  operationType: string = 'MF';
  constructor() {}
}
