import { Component } from '@angular/core';
import { Constants } from '../reports-dashboard-constants';

@Component({
  selector: 'ps-psid-report',
  templateUrl: './psid-report.component.html',
  styleUrls: ['./psid-report.component.css'],
})
export class PsidReportComponent {
  radioSel: any;
  radioSelected: string;
  subReportList: SubReport[] = Constants.PSID_SUB_REPORTS;

  constructor() {
    this.radioSelected = Constants.PSID_SUB_REPORTS[0].value;
    this.getSelecteditem();
  }

  getSelecteditem() {
    this.radioSel = Constants.PSID_SUB_REPORTS.find(
      (Item) => Item.value === this.radioSelected
    );
  }

  onItemChange(item: SubReport) {
    this.getSelecteditem();
  }
}

export interface SubReport {
  name: string;
  value: string;
}
