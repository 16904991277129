import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { Pagination } from '../../shared/types/pagination';
import { UserResponseObject } from '../user-service/user.interface';
import { UserService } from '../user-service/user.service';

@Component({
  selector: 'ps-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css'],
})
export class ManageUserComponent implements OnInit, OnDestroy {
  public pagination = Pagination.first();
  public users$!: Observable<UserResponseObject[]>;
  public selected?: UserResponseObject;
  public total = 0;
  public form!: FormGroup;
  private _fetch = new Subject<Pagination>();

  constructor(
    private _toastr: ToasterService,
    private _userService: UserService,
    private _fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.form = this._fb.group({
      search: '',
    });

    const fetch$ = this._fetch.pipe(startWith(this.pagination));
    const search$ = this.form.valueChanges.pipe(
      debounceTime(1000),
      map((values) => {
        this.pagination = Pagination.first();
        return values.search as string;
      }),
      distinctUntilChanged(),
      startWith('')
    );

    this.users$ = combineLatest([fetch$, search$]).pipe(
      switchMap(([_, search]) =>
        this._userService.getUsers(this.pagination, search).pipe(
          catchError((error) => {
            this._toastr.error(error.error.msg);
            return of({ count: 0, companies: [] });
          })
        )
      ),
      map((response) => {
        this.total = response.count;
        return response.companies;
      })
    );

    this.getUsers();
  }

  public ngOnDestroy(): void {
    this._fetch.complete();
  }

  formatUserStatus(status: string) {
    if (!status || status === 'Active') {
      return status;
    }
    return 'Inactive';
  }

  userType(user: UserResponseObject) {
    if (user.buyerSideAccessLevels != 'none') {
      return 'Buyer';
    }

    if (user.operatorAccessLevels != 'none') {
      return 'Operator';
    }

    return 'Supplier';
  }

  public onPageChange(page: number): void {
    this.pagination = Pagination.to(page);
    this.getUsers();
  }

  public onAddUser(): void {
    this.selected = {
      id: 0,
      name: '',
      email: '',
      company: 0,
      status: '',
      buyerSideAccessLevels: '',
      supplierSideAccessLevels: '',
      operatorAccessLevels: '',
      isApiUser: false,
      slackMemberId: '',
    };
  }

  public getUsers(): void {
    this._fetch.next(this.pagination);
  }
}
