import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CrmIdService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  uploadCrmIdFile(fileData: FormData) {
    return this._http.post(`${this._baseUrl}/survey/uploadCrmIds`, fileData);
  }
}
