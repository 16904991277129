<div class="footer">
  <div class="containier">
    <div
      class="center-btn"
      *ngIf="
        !(
          userType === 'operator' &&
          st === surveyStatus.DRAFT &&
          !isServiceBuyerLoggedIn
        )
      "
    >
      <div class="editAudience">
        <button
          [disabled]="
            isQbpChildSurvey ||
            (surveyChannelType && surveyChannelType !== 'SPA')
          "
          (click)="redirectToEditPage()"
          class="btn"
        >
          Edit Audience
        </button>
      </div>
      <div class="addSuppliers">
        <button
          [disabled]="
            isQbpChildSurvey ||
            (surveyChannelType && surveyChannelType !== 'SPA') ||
            (isRecontact && userType !== 'operator')
          "
          class="btn"
          (click)="redirectToSupplierPage()"
        >
          Add Suppliers
        </button>
      </div>
      <div class="editDetails">
        <button
          [disabled]="isQbpChildSurvey || surveyChannelType || isRecontact"
          class="btn"
          (click)="redirectToLaunchPage()"
        >
          Edit Details
        </button>
      </div>
    </div>
    <div
      class="center-btn operator-draft-text"
      *ngIf="
        userType === 'operator' &&
        st === surveyStatus.DRAFT &&
        !isServiceBuyerLoggedIn
      "
    >
      The Tabs are disabled while the project is in DRAFT status
    </div>
  </div>
</div>
