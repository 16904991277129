<ps-ui-modal class="viewModal">
  <h5 psUiModalTitle>Enable Nightcrawler</h5>

  <ps-ui-modal-body>
    <div class="form-group">
      <div class="row row-m-t">
        <div class="col-md-3">
          <label class="pull-left">URI:</label>
        </div>
        <div class="col-md-8">
          <input
            type="text"
            class="form-control"
            id="enable_nightcrawler_uri"
            placeholder="URI"
            name="enable_nightcrawler_uri"
            [(ngModel)]="uri"
          />
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row row-m-t">
        <div class="col-md-3">
          <label class="pull-left align-middle">Access-Token:</label>
        </div>
        <div class="col-md-8">
          <input
            type="text"
            class="form-control"
            id="enable_nightcrawler_access_token"
            placeholder="Access-Token"
            name="enable_nightcrawler_access_token"
            [(ngModel)]="accessToken"
          />
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
  </ps-ui-modal-body>
  <ps-ui-modal-footer class="padFooter">
    <button type="button" psUiButton color="secondary" (click)="close()">
      Close
    </button>
    <button
      type="button"
      [disabled]="checkAllFilledFields()"
      psUiButton
      (click)="saveNightCrawlerConfig()"
    >
      Save changes
    </button>
  </ps-ui-modal-footer>
</ps-ui-modal>
