import { Component } from '@angular/core';

@Component({
  selector: 'ps-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.css'],
})
export class SimpleHeaderComponent {
  constructor() {}
}
