import { CompanyInfo } from 'src/app/shared/interfaces/company.interface';
import { SurveyListing } from 'src/app/shared/services/buyer-api/survey.interface';

type DashboardSurveyCacheParams = {
  surveys?: SurveyListing[];
  page?: number;
  company?: CompanyInfo;
};

export class DashboardSurveyCache {
  private _surveys: SurveyListing[] = [];
  private _page: number = 1;
  private _company?: CompanyInfo;

  get listing() {
    return this._surveys;
  }

  get page() {
    return this._page;
  }

  public clear(): void {
    this._surveys = [];
    this._page = 1;
  }

  public cache(params: DashboardSurveyCacheParams): void {
    this._surveys = params.surveys ?? this._surveys;
    this._page = params.page ?? this._page;
    this._company = params.company ?? this._company;
  }

  public exists() {
    return this._surveys.length > 0;
  }
}
