import { NgModule } from '@angular/core';
import {
  ButtonModule,
  ModalModule,
  SelectDropdownModule,
  SliderModule,
  TabsModule,
} from '@purespectrum1/ui';
import { CapsuleRadioButtonModule } from '@purespectrum1/ui/capsule-radio-button';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { ImportQuestionsComponent } from './import-questions/import-questions.component';
import { QuestionsLibraryComponent } from './questions-library.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddQuestionsComponent } from './add-questions/add-questions.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from '@purespectrum1/ui/accordion';
import { HasRoleModule } from '../shared/ui/has-role/has-role.module';
import { QuestionsListComponent } from './questions-list/questions-list.component';
import { LocalizationComponent } from './add-questions/localization/localization.component';
import { DependencyComponent } from './add-questions/localization/dependency/dependency.component';
import { DataQualityComponent } from './add-questions/localization/data-quality/data-quality.component';
import { SearchQualificationsModelComponent } from './add-questions/localization/data-quality/search-qualifications-model/search-qualifications-model.component';
import { LocaleShortCodePipeModule } from '../shared/pipes/locale-short-code-pipe.module';
import { PaginatedListModule } from '@purespectrum1/ui/paginated-list';
import { ImportDataQualityQuestionsComponent } from './import-data-quality-questions/import-data-quality-questions.component';
import { SegmentationComponent } from './add-questions/localization/segmentation/segmentation.component';

@NgModule({
  declarations: [
    QuestionsLibraryComponent,
    ImportQuestionsComponent,
    AddQuestionsComponent,
    QuestionsListComponent,
    DataQualityComponent,
    SearchQualificationsModelComponent,
    LocalizationComponent,
    DependencyComponent,
    ImportDataQualityQuestionsComponent,
    SegmentationComponent,
  ],
  imports: [
    CommonModule,
    PaginatedListModule,
    FormsModule,
    ReactiveFormsModule,
    SliderModule,
    TabsModule,
    ButtonModule,
    ModalModule,
    CapsuleRadioButtonModule,
    SelectDropdownModule,
    ReactiveFormsModule,
    NgSelectModule,
    AccordionModule,
    HasRoleModule,
    LocaleShortCodePipeModule,
    RouterModule.forChild([
      {
        path: 'questions-library',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: QuestionsLibraryComponent,
          },
          {
            path: 'import-questions',
            component: ImportQuestionsComponent,
          },
        ],
      },
    ]),
  ],
})
export class QuestionsLibraryModule {}
