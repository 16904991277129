import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  GetBuyerSettingsResponse,
  UpdateBuyerSettingsResponse,
  UpdateObj,
} from '../buyer-settings-service/buyer-settings-service.interface';
import { BillingRules } from '../../shared/interfaces/billing-rules.interface';

@Injectable({
  providedIn: 'root',
})
export class BuyerSettingsService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getBuyerSettings(Id: number) {
    return this._http.get<GetBuyerSettingsResponse>(
      `${this._baseUrl}/buyerSettings/${Id}`
    );
  }
  updateBuyerSettings(updateObj: UpdateObj, Id: number) {
    return this._http.put<UpdateBuyerSettingsResponse>(
      `${this._baseUrl}/buyerSettings/${Id}`,
      updateObj
    );
  }

  createBuyerBillingRules(billingRules: BillingRules) {
    return this._http.post<BillingRules>(
      `${this._baseUrl}/buyers/v2/billing-rules`,
      billingRules
    );
  }

  updateBuyerBillingRules(id: number, billingRules: BillingRules) {
    return this._http.put<BillingRules>(
      `${this._baseUrl}/buyers/v2/billing-rules/${id}`,
      billingRules
    );
  }

  getBillingRules() {
    return this._http.get<BillingRules>(
      `${this._baseUrl}/buyers/v2/billing-rules`
    );
  }
}
