export const DefaultResetCompanyObject = {
  address: '',
  dynamicProfiler: false,
  fx: 0,
  id: 0,
  isABuyer: false,
  isASupplier: false,
  isAnOperator: false,
  isExpose: false,
  isNotify: false,
  isPrivacyPolicyActive: false,
  name: '',
  phone: '',
  primaryLiaison: '',
  secondaryLiaison: '',
  status: '',
  supp_url_completes: '',
  supp_url_overquota: '',
  supp_url_terms: '',
  supplier_type: '',
  isDataQualityQuestionsActive: false,
  isAsrRedirectionEnable: false,
  addr: '',
  phn: '',
  priLsn: '',
  secLsn: '',
  isAuxiliary: false,
  isBuyr: false,
  isSpplr: false,
  isOprtr: false,
  isService: false,
  isFusion: false,
  skipPreScreener: false,
  isPureScoreEnable: false,
  st: 0,
  sup_url: '',
  generateInvoice: '',
  tempGenerateInvoice: '',
  invoiceChangeOverTS: 0,
  crtd_on: 0,
  mod_on: 0,
  accountManagers: [],
  nsInternalId: 1234,
};

export const DefaultResetCompanyCurrencyObject = {
  fx: 0,
  currencyShortCode: '',
  currencyFullName: '',
};

export const DefaultSupplierCurrency = {
  currencyShortCode: 'USD',
  currencyFullName: 'United States Dollar',
  fx: 321,
};

export const DEFAULT_RESET_OBJECT = {
  name: '',
  address: '',
  phone: '',
  primaryLiaison: '',
  secondaryLiaison: '',
  isAServiceAccount: false,
  isExpose: false,
  isNotify: false,
  isFusion: false,
  generateInvoice: '',
  tempGenerateInvoice: '',
  pm: [],
};

export const EmptyOrBlankStringRegex = /^\s*$/;

export const DEFAULT_NETSUITE_OBJECT = {
  internalId: '',
  companyName: '',
  primaryEmail: '',
  copyEmail: '',
  billingInfo: {
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  currencies: [],
};

export const USER_TYPES = {
  BUYER: 'buyer',
  OPERATOR: 'operator',
  SUPPLIER: 'supplier',
};
