import { SurveyStatus } from '../../../utils/survey-status';
import {
  BulkViewSimpleColumn,
  BulkViewEditableCpiColumn,
  BulkViewFieldedVsGoalColumn,
  BulkViewCompletesLeftColumn,
  BulkViewStatusColumn,
  BulkViewCostColumn,
  BulkViewManagersColumn,
  BulkViewEditableBillingColumn,
  BulkViewCountryLanguageColumn,
  BulkViewCurrencyColumn,
  BulkViewEditableMarginColumn,
  BulkViewMarginColumn,
  BulkViewSupplierCpiColumn,
  BulkViewBillingColumn,
} from './bulk-view-columns';
import { BulkView, BulkViewColumn } from './types';
import { ProjectManagers as ProjectManager } from '../../../operator/user-service/user.interface';
import { ComposedState } from '../../bulk-option-confirmation/domain/editable-survey-state-composer';
import { EditableSurvey, UserRole } from '../types';
import { notifyMessage } from '../../../constants/notify-message';
import { LayoutConstants } from '../../../layout/layout-constants';
import { Constants } from '../../../create-surveys/create-survey.constants';
import { CurrencyExchange } from '../../../shared/types/currency-service-interface';
import { SURVEY_STATUS } from '../../../constants/survey-status';

export class BulkEditColumnComposer {
  private _columns: BulkViewColumn[] = [];
  constructor(private readonly _defaultColumns: BulkViewColumn[] = []) {
    this._columns = [..._defaultColumns];
  }

  public addColumn(columnToAdd: BulkViewColumn, nearTo?: BulkViewColumn): void {
    const presentAlready = this._columns.some(
      (c) => c.header() === columnToAdd.header()
    );

    if (presentAlready) {
      return;
    }

    if (!nearTo) {
      this._columns = [...this._columns, columnToAdd]; // Add new column to end of the table
      return;
    }

    // Adding new column to the specified place
    const indexOfNearToColumn = this._columns.findIndex(
      (c) => c.header() === nearTo?.header()
    );
    const indexForNewColumn = indexOfNearToColumn + 1;
    this._columns = [
      ...this._columns.slice(0, indexForNewColumn),
      columnToAdd,
      ...this._columns.slice(indexForNewColumn),
    ];
  }

  public removeColumn(columnToRemove: BulkViewColumn): void {
    this._columns = this._columns.filter(
      (c) => columnToRemove.header() !== c.header()
    );
  }

  public columns(): BulkViewColumn[] {
    return this._columns;
  }
}

export class BulkEditCpiBase
  extends BulkEditColumnComposer
  implements BulkView
{
  constructor(
    private readonly _toValue: string,
    _columns: BulkViewColumn[] = []
  ) {
    super(_columns);
  }

  public operation(): string {
    return 'Edit CPI';
  }

  public title(): string {
    return `The CPI of these surveys will change to <b>$${this._toValue}</b>`;
  }

  public subTitle(): string {
    return '';
  }

  public footer(): boolean {
    return false;
  }

  public validate(
    state: ComposedState,
    type: 'value-change' | 'submission'
  ): EditableSurvey {
    switch (type) {
      case 'value-change':
        return this._valueChangeValidation(state);
      case 'submission':
        return this._submissionValidation(state);
      default:
        return state.pending();
    }
  }

  private _valueChangeValidation(state: ComposedState): EditableSurvey {
    const currentState = state.pending();
    if (Number(currentState.value) > LayoutConstants.MAX_CPI_THRESHOLD) {
      return state.error(notifyMessage.CPI_CONFIRMATION_ERROR, 'toaster');
    }
    return currentState;
  }

  private _submissionValidation(state: ComposedState): EditableSurvey {
    const currentState = state.pending();
    if (!currentState.value) {
      return state.error(
        notifyMessage.errorMessage.BULK_EDIT.EDIT_CPI.EMPTY_VALUE
      );
    }

    return currentState;
  }
}
export class BulkEditCpiView extends BulkEditCpiBase {
  constructor(private readonly _value: string) {
    super(_value, [
      new BulkViewSimpleColumn('surveyId', 'Survey ID'),
      new BulkViewCountryLanguageColumn(),
      new BulkViewCurrencyColumn('averageCpi', 'Average CPI'),
      new BulkViewCurrencyColumn('aCpi', 'Current CPI'),
      new BulkViewEditableCpiColumn(),
      new BulkViewFieldedVsGoalColumn(),
      new BulkViewCompletesLeftColumn(),
    ]);
  }
}

export class BulkEditCpiWithTargetMarginView extends BulkEditCpiBase {
  constructor(
    private readonly _value: string,
    private readonly _currencyExchange = <CurrencyExchange>{}
  ) {
    super(_value, [
      new BulkViewSimpleColumn('surveyId', 'Survey ID'),
      new BulkViewCountryLanguageColumn(),
      new BulkViewCurrencyColumn('aCpi', 'Current CPI'),
      new BulkViewMarginColumn(),
      new BulkViewEditableCpiColumn(),
      new BulkViewSupplierCpiColumn(Number(_value), _currencyExchange),
      new BulkViewFieldedVsGoalColumn(),
      new BulkViewCompletesLeftColumn(),
    ]);
  }
}

export class BulkEditStatusView
  extends BulkEditColumnComposer
  implements BulkView
{
  constructor(private readonly _toValue: string) {
    super([
      new BulkViewSimpleColumn('surveyId', 'Survey ID'),
      new BulkViewCountryLanguageColumn(),
      new BulkViewStatusColumn(),
      new BulkViewStatusColumn(true),
      new BulkViewCurrencyColumn('aCpi', 'Current CPI'),
      new BulkViewCostColumn(),
      new BulkViewFieldedVsGoalColumn(),
      new BulkViewCompletesLeftColumn(),
    ]);
  }

  public operation(): string {
    return 'Edit STATUS';
  }

  public title(): string {
    return `The Status of these surveys will change to ${
      SurveyStatus[Number(this._toValue)]
    }`;
  }

  public subTitle(): string {
    return '';
  }

  public footer(): boolean {
    return true;
  }

  public validate(
    state: ComposedState,
    type: 'value-change' | 'submission'
  ): EditableSurvey {
    if (type === 'value-change') {
      return this._valueChangeValidation(state);
    }
    return state.pending();
  }

  private _valueChangeValidation(state: ComposedState) {
    const currentState = state.pending();
    if (
      currentState.value === SURVEY_STATUS.LIVE &&
      currentState.highCpiDetails?.isPriceGreaterThanCpiThreshold
    ) {
      return state.error(
        notifyMessage.CPI_CONFIRMATION_ERROR_ON_STATUS_CHANGE,
        'toaster'
      );
    }
    return state.pending();
  }
}

export class BulkMarginView extends BulkEditColumnComposer implements BulkView {
  constructor(private readonly _toValue: string) {
    super([
      new BulkViewSimpleColumn('surveyId', 'Survey ID'),
      new BulkViewCountryLanguageColumn(),
      new BulkViewCurrencyColumn('aCpi', 'Current CPI'),
      new BulkViewMarginColumn(),
      new BulkViewEditableMarginColumn(),
      new BulkViewCurrencyColumn('scpi', 'New Supplier CPI'),
      new BulkViewFieldedVsGoalColumn(),
      new BulkViewCompletesLeftColumn(),
    ]);
  }

  public operation(): string {
    return 'Edit Margin';
  }

  public title(): string {
    return `The Margin of these surveys will change to <b>${this._toValue} %</b>`;
  }

  public subTitle(): string {
    return '';
  }

  public footer(): boolean {
    return true;
  }

  public validate(
    state: ComposedState,
    type: 'value-change' | 'submission'
  ): EditableSurvey {
    switch (type) {
      case 'value-change':
        return this._valueChangeValidation(state);
      case 'submission':
        return this._submissionValidation(state);
      default:
        return state.pending();
    }
  }

  private _valueChangeValidation(state: ComposedState): EditableSurvey {
    const currentState = state.pending();
    if (Number(currentState.value) < Constants.AVERAGE_VARIABLE_MARGIN) {
      return state.marginError(
        notifyMessage.warningMessage.MARGIN_BELOW_THAN_AVERAGE,
        'toaster',
        'warning'
      );
    }

    if (
      Number(currentState.value) < Constants.MIN_MARGIN ||
      Number(currentState.value) > Constants.MAX_MARGIN
    ) {
      return state.error(
        notifyMessage.errorMessage.MARGIN_MIN_MAX_VALUE_ERROR,
        'toaster'
      );
    }

    return currentState;
  }

  private _submissionValidation(state: ComposedState) {
    const currentState = state.pending();

    if (!currentState.value) {
      return state.error(notifyMessage.errorMessage.EMPTY_MARGIN_VALUE);
    }

    if (
      !currentState.survey?.is_enable_margin_override ||
      (currentState.survey?.manual_scpi_override_enabled &&
        currentState.survey?.is_enable_margin_override) ||
      currentState.survey?.is_enable_margin_max
    ) {
      return state.error(
        notifyMessage.errorMessage.MARGIN_VALUE_UPDATE_ERROR,
        'info-column'
      );
    }

    return currentState;
  }
}

export class BulkEditPMView extends BulkEditColumnComposer implements BulkView {
  constructor(
    private readonly _toValue: ProjectManager,
    private readonly _role: UserRole
  ) {
    super([]);
  }

  public operation(): string {
    return `Edit/Assign ${this._disclaimer()}Project Manager`;
  }

  public title(): string {
    return `The ${this._disclaimer()}Project Manager for these surveys will change to ${
      this._toValue.name
    }`;
  }

  public subTitle(): string {
    return '';
  }

  public columns(): BulkViewColumn[] {
    return [
      new BulkViewSimpleColumn('surveyId', 'Survey ID'),
      new BulkViewCountryLanguageColumn(),
      new BulkViewSimpleColumn(
        'buyerAccount',
        `Current ${this._disclaimer()}Project Manager`
      ),
      new BulkViewManagersColumn('pm', this._disclaimer()),
      new BulkViewFieldedVsGoalColumn(),
      new BulkViewCompletesLeftColumn(),
    ];
  }
  public footer(): boolean {
    return false;
  }

  public validate(state: ComposedState): EditableSurvey {
    return state.pending();
  }

  private _disclaimer(): string {
    return ['service-buyer', 'operator'].includes(this._role) ? 'Buyer ' : '';
  }
}

export class BulkEditPOView extends BulkEditColumnComposer implements BulkView {
  constructor(
    private readonly _toValue: ProjectManager,
    private _role: UserRole
  ) {
    super([]);
  }

  public operation(): string {
    return 'Edit/Assign Operator Project Manager';
  }

  public title(): string {
    return `The Operator ${this._disclaimer()}Manager for these surveys will change to ${
      this._toValue.name
    }`;
  }

  public subTitle(): string {
    return '';
  }

  public columns(): BulkViewColumn[] {
    return [
      new BulkViewSimpleColumn('surveyId', 'Survey ID'),
      new BulkViewCountryLanguageColumn(),
      new BulkViewSimpleColumn(
        'buyerAccount',
        `Current Operator ${this._disclaimer()}Manager`
      ),
      new BulkViewManagersColumn('po', this._disclaimer()),
      new BulkViewFieldedVsGoalColumn(),
      new BulkViewCompletesLeftColumn(),
    ];
  }
  public footer(): boolean {
    return false;
  }

  public validate(state: ComposedState): EditableSurvey {
    return state.pending();
  }

  private _disclaimer(): string {
    return ['service-buyer', 'operator'].includes(this._role) ? 'Project ' : '';
  }
}

export class BulkEditBillingNumberView
  extends BulkEditColumnComposer
  implements BulkView
{
  constructor(private readonly _toValue: string) {
    super([
      new BulkViewSimpleColumn('surveyId', 'Survey ID'),
      new BulkViewCountryLanguageColumn(),
      new BulkViewBillingColumn(),
      new BulkViewEditableBillingColumn(),
      new BulkViewFieldedVsGoalColumn(),
      new BulkViewCompletesLeftColumn(),
    ]);
  }

  public operation(): string {
    return 'Edit Billing number';
  }

  public title(): string {
    return `The Billing number for these surveys will change to <b>${this._toValue}</b>`;
  }

  public subTitle(): string {
    return '';
  }

  public footer(): boolean {
    return true;
  }

  public validate(state: ComposedState): EditableSurvey {
    return state.pending();
  }
}

export class BulkEditDeleteDraftView
  extends BulkEditColumnComposer
  implements BulkView
{
  constructor() {
    super([
      new BulkViewSimpleColumn('surveyId', 'Survey ID'),
      new BulkViewCountryLanguageColumn(),
      new BulkViewStatusColumn(),
      new BulkViewCostColumn(),
      new BulkViewFieldedVsGoalColumn(),
      new BulkViewCompletesLeftColumn(),
    ]);
  }

  public title(): string {
    return 'Delete Draft Surveys';
  }

  public subTitle(): string {
    return 'These surveys will be deleted';
  }

  public footer(): boolean {
    return true;
  }

  public operation(): string {
    return 'Delete Draft Surveys';
  }

  public validate(state: ComposedState): EditableSurvey {
    return state.pending();
  }
}
