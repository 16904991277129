import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MarketplaceService } from '@purespectrum1/ui/marketplace';
import { AuthState } from '@purespectrum1/ui/marketplace/shared/interfaces/auth.interface';
import { AuthService } from '../auth/auth.service';
import { ServiceAccountModalComponent } from '../layout/header/user-menu/service-account-modal/service-account-modal.component';
import { ModalService } from '@purespectrum1/ui/modal';
import { TRAFFIC_CHANNEL } from '../constants/traffic-channels-constants';
import { BuyerSurveyResponse } from '@purespectrum1/ui/marketplace/shared/interfaces/survey.interface';

@Component({
  selector: 'ps-create-surveys',
  templateUrl: './create-surveys.component.html',
  styleUrls: ['./create-surveys.component.css'],
})
export class CreateSurveysComponent implements OnInit, OnDestroy {
  private _subscriptions = new Subscription();
  public surveyId: number = 0;
  public auth: AuthState = this._auth.getMarketplaceAuth;

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _marketplaceService: MarketplaceService,
    private _modal: ModalService
  ) {}

  ngOnInit(): void {
    this._subscriptions.add(
      this._auth.loggedInAsServiceBuyer$.subscribe((data) => {
        this.auth = this._auth.getMarketplaceAuth;
      })
    );
    this._subscriptions.add(
      this._marketplaceService
        .onUpdateSurveyComplete()
        .subscribe(({ survey, action }) => {
          if (survey?.ps_survey_id && action === 'LiveEdit') {
            const surveyId =
              this._fetchSurveyToRedirectToAfterUpdateComplete(survey);
            this._router.navigateByUrl(`/dashboard/${surveyId}`);
          }
        })
    );
  }

  private _fetchSurveyToRedirectToAfterUpdateComplete(
    survey: BuyerSurveyResponse
  ): number {
    if (survey.channel_type !== TRAFFIC_CHANNEL.SPECIFIC_AUDIENCE) {
      return survey.ps_survey_id;
    }

    return survey.ps_mc_project_id || survey.ps_project_id;
  }

  goToDashboard(close: boolean) {
    if (close) {
      this._router.navigateByUrl('/dashboard');
    }
  }

  selectBuyer(open: boolean) {
    if (open) {
      this._modal.open(ServiceAccountModalComponent, {});
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
