import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
} from '@angular/core';

@Directive({
  selector: '[psInputDecimalRestriction]',
})
export class InputDecimalRestrictionDirective {
  @Input() public allowedPlacesPostDecimal = 2; // default value kept at 2 digits after decimal
  // We are using this regex - /^\d*\.?\d{0,2}$/g, extra backslashes are to protect escaping of backslash inside string
  private _regex: RegExp = new RegExp(
    `^\\d*\\.?\\d{0,${this.allowedPlacesPostDecimal}}$`,
    'g'
  );

  // Add any keyboard keys that should be avoided in the regex test
  private _specialKeys: Array<string> = [
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
  ];
  constructor(
    private _el: ElementRef,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  // Whenever keydown event fires in the host element, the function below calls automatically
  @HostListener('keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (this._specialKeys.indexOf(event.key) !== -1) {
      return true;
    }

    const selectedValue = this._document
      .getSelection()
      ?.toString()
      .replace(',', '.');
    const currentValue = this._el.nativeElement.value;
    // If the value is selected then we are override the value in the input
    if (selectedValue === currentValue) {
      return true;
    }

    // For input type ='number', selectionStart is null, so using a hack
    const position: string =
      this._el.nativeElement.selectionStart || currentValue.length;
    const nextValue: string = [
      currentValue.slice(0, position),
      event.key,
      currentValue.slice(position),
    ].join('');
    if (nextValue && !String(nextValue).match(this._regex)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
