import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ModalService } from '@purespectrum1/ui/modal';
import { LayoutConstants } from '../../../layout/layout-constants';
import { ConfirmationModalComponent } from '../../ui/confirmation-modal/confirmation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(private _modal: ModalService) {}

  async openBillingNumberModal() {
    const modalRef = this._modal.open(ConfirmationModalComponent, {
      data: {
        text: LayoutConstants.CONFIRM_MODAL.BILLING_NUMBER.TEXT,
        cancelButtonLabel:
          LayoutConstants.CONFIRM_MODAL.BILLING_NUMBER.CANCEL_BUTTON_TEXT,
        confirmButtonLabel:
          LayoutConstants.CONFIRM_MODAL.BILLING_NUMBER.CONFIRM_BUTTON_TEXT,
      },
      maxWidth: '35%',
    });
    const isUpdate = await modalRef.onClose$
      .pipe(map((msg) => msg === 'ok'))
      .toPromise();
    return isUpdate;
  }
}
