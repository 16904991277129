'use strict';

export const Constants = Object.freeze({
  TOTAL_FEASIBILITY: 'Tot Feasibility',
  FEASIBILITY: 'Feasibility',
  CPI: 'CPI',
  AVERAGE_CPI: 'Avg CPI',
  CREATE_SURVEY_REGEX_LINKS: [
    /\/create-surveys$/,
    /\/surveys\/\d{1,9}\/edit.*$/,
    /\/launch-survey\/\d{1,9}/,
    /\/recontact-survey.*$/,
  ],
  SHOW_SURVEY_REGEX_LINKS: [/\/dashboard$/, /\/dashboard\/\d{1,9}$/],
  SHOW_ASSIGN_PROJECT_MANAGER_TAB: /\/dashboard\/\d{1,9}$/,
  USER_ACCESS: {
    LINKS: {
      adjustments: '/buyer/adjustments',
      downloadReports: '/download-reports',
      invoiceOperations: '/invoice-operations',
      manageBuyerSettings: '/buyer/manage-buyer-settings',
      manageCompanies: '/manage-companies',
      manageSuppliers: '/buyer/manage-suppliers',
      manageUsers: '/manage-users',
      manageBuyers: '/manage-buyers',
      ManageSupplierPricingCard: '/supplier-pricing-card',
      ManageSupplierSettings: '/supplier-settings',
      operatorSettings: '/operator-settings',
      reportsDashboard: '/operator/reports-dashboard',
      selectServiceAccount: '#',
      questionLibrary: '/questions-library',
      irGenerator: '/ir-generator',
    },
    IGNORE_FEATURE_KEYS: [
      'CreateSurveys',
      'manageSurveys',
      'ManualSurveyTrafficFixes',
      'reconcile',
      'addAdvQuestion',
    ],
  },
  SUBSTRING_VALUE: 40,
  GLOBAL_OVERRIDE: 'global',
  SELECTIVE_OVERRIDE: 'manual',
  EXCLUDE_HEADER_REGEX_LINKS: [
    // we are excluding platform-ui header as header will be added from component-library header
    /\/create-surveys$/,
    /\/surveys\/\d{1,9}\/edit.*$/,
    /\/launch-survey\/\d{1,9}/,
    /\/recontact-survey.*$/,
    /\/quota-mapper\/\d{1,9}/,
    /\/buyerRedirectGuide$/,
  ],
});
