import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[psInputRestriction]',
})
export class InputRestrictionDirective {
  @Input() psInputRestriction!: string;

  constructor() {}

  @HostListener('keypress', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    const regex = new RegExp(this.psInputRestriction);
    const str = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  }
}
