import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { BulkEditOption } from '../types';

@Component({
  selector: 'ps-bulk-options',
  templateUrl: './bulk-options.component.html',
  styleUrls: ['./bulk-options.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkOptionsComponent {
  @Input()
  public options: BulkEditOption[] = [];

  @Input()
  public userRole: string = '';

  @Output()
  public selectedOption: EventEmitter<BulkEditOption> =
    new EventEmitter<BulkEditOption>();

  public displaySelectedOption: string = 'Select Operation';

  public onSelectOption(option: BulkEditOption): void {
    this.displaySelectedOption = option.selectedLabel!;
    this.selectedOption.emit(option);
  }
}
