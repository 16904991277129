import { Component, Inject, OnInit } from '@angular/core';
import { ModalData, ModalRef } from '@purespectrum1/ui/modal';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { ConfigSettingsModalData } from '../config-settings.component';
import { BuyerService } from '../../../shared/services/buyer/buyer.service';
import { notifyMessage } from '../../../constants/notify-message';

@Component({
  selector: 'ps-enable-nightcrawler-modal',
  templateUrl: './enable-nightcrawler-modal.component.html',
  styleUrls: ['./enable-nightcrawler-modal.component.css'],
})
export class EnableNightcrawlerModalComponent implements OnInit {
  accessToken = '';
  uri = '';

  constructor(
    @Inject(ModalData) private _modalData: ConfigSettingsModalData,
    private _modalRef: ModalRef<EnableNightcrawlerModalComponent, string>,
    private _toastr: ToasterService,
    private _buyerService: BuyerService
  ) {}

  ngOnInit(): void {
    this._getNightCrawlerConfig();
  }

  close() {
    this._modalRef.close();
  }

  saveNightCrawlerConfig() {
    const { buyerName, buyerId } = this._modalData;
    const addNCConfigRequest = {
      name: buyerName,
      buyerId: buyerId,
      accessKey: this.accessToken,
      apiUri: this.uri,
      access: true,
    };

    this._buyerService
      .addNightcrawlerConfigSettings(buyerId, addNCConfigRequest)
      .subscribe(
        (response) => {
          this.close();
          this._toastr.success(notifyMessage.successMessage.SUCCESSFULL_SAVED);
        },
        (error) => {
          this._toastr.error(notifyMessage.errorMessage.API_ERROR);
        }
      );
  }

  _getNightCrawlerConfig() {
    const { buyerId } = this._modalData;
    this._buyerService.getNightcrawlerConfigSettings(buyerId).subscribe(
      (response) => {
        this.accessToken = response.data.accessKey;
        this.uri = response.data.apiUri;
      },
      (error) => {
        this._toastr.error(notifyMessage.errorMessage.API_ERROR);
      }
    );
  }

  checkAllFilledFields() {
    if (
      this.accessToken &&
      this.accessToken.trim() &&
      this.uri &&
      this.uri.trim()
    ) {
      return false;
    }
    return true;
  }
}
