<ps-ui-modal>
  <div class="ps-modal-header" psUiModalTitle>
    <h5>Open Ended Upload</h5>
  </div>
  <ps-ui-modal-body>
    <div class="confirmation-content">
      <p *ngIf="data.msg; then success; else error"></p>
      <ng-template #success>{{ data.msg }}</ng-template>
      <ng-template #error>{{ data.ps_api_response_message }}</ng-template>
    </div>
  </ps-ui-modal-body>
  <ps-ui-modal-footer position="center">
    <button type="button" class="close-btn" (click)="close()">Close</button>
  </ps-ui-modal-footer>
</ps-ui-modal>
