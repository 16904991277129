import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ps-report-sort-table',
  templateUrl: './report-sort-table.component.html',
  styleUrls: ['./report-sort-table.component.css'],
})
export class SortTableComponent {
  private readonly _bucketUrl = environment.BUCKET_URL;
  reportStatus = ['Pending', 'Ready', 'Error', 'Deleted'];

  constructor() {}
  @Input()
  public dataList: any[] = [];

  downloadFile(filename: any) {
    return `${this._bucketUrl}/${filename}`;
  }
}
