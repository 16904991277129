<ps-ui-table
  [data]="surveys"
  [trackBy]="'surveyId'"
  [border]="border"
  [state]="state"
  (scrolled)="onScrolled()"
  (stateChange)="onTableStateChange($event)"
  *ngIf="tab$ | async as tab"
  [ngClass]="{
    'no-survey': !surveys.length,
    'dashboard-table': surveys.length
  }"
>
  <ng-container psUiTableExpandableRow>
    <ng-container
      psUiTableCheckboxColumn
      [showSelectAll]="true"
      [show]="preferences.enableBulkEditAndSorting"
      [selectBy]="'surveyId'"
      [disable]="selection"
      [selectedKeys]="selectedKeys"
      (selectedKeysChange)="onSurveysSelected($event)"
    ></ng-container>
    <ng-container
      *ngFor="let header of preferences.columns"
      [ngSwitch]="header.key"
    >
      <ng-container
        psUiTableColumn
        [title]="header.column"
        [width]="'fit'"
        [field]="'status'"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.STATUS"
      >
        <ng-container
          [show]="preferences.enableBulkEditAndSorting"
          psUiTableColumnFilter
          [show]="tab.status === 'all'"
        >
          <option
            *ngFor="let status of surveyStatus"
            psUiTableColumnOption
            [value]="status.value"
          >
            {{ status.name }}
          </option>
        </ng-container>

        <ng-template psUiTableCellTemplate let-survey>
          <div class="serveryStatus">
            <button
              class="menu-btn"
              [psUiDropdownToggleFor]="surveysStatus"
              [disabled]="checkDisableSurveyStatus(survey)"
              [ngClass]="{
                greyOutInvoiceButton: checkDisableSurveyStatus(survey)
              }"
            >
              {{ SURVEY_STATUS[survey.status] }}
            </button>
            <ps-ui-dropdown
              class="status-dropdown"
              #surveysStatus="psUiDropdown"
            >
              <div *ngFor="let statusButton of statusButtons">
                <button
                  psUiDropdownItem
                  *ngIf="statusButton.condition.indexOf(survey.status) > -1"
                  (click)="
                    onChangeStatus(
                      survey.surveyId,
                      statusButton.value,
                      survey.billing_id,
                      survey.survey_pause_threshold,
                      survey.is_survey_pause_threshold_triggered,
                      survey.aCpi,
                      survey.currencySymbol
                    )
                  "
                >
                  {{ statusButton.value }}
                </button>
              </div>
            </ps-ui-dropdown>
          </div>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [searchable]="true"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.CRM_ID"
      >
        <ng-container psUiTableColumnFilter [delegate]="filter">
          <option psUiTableColumnOption value="EMPTY">Empty</option>
          <option psUiTableColumnOption value="NOT EMPTY">Not Empty</option>
        </ng-container>
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <div class="sernewTitle">
            <a class="survey-link" [routerLink]="survey | surveyRouterLink">
              {{ survey[header.key] }}</a
            >
          </div>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [searchable]="true"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.OPERATOR_PROJECT_MANAGER"
      >
        <ng-container psUiTableColumnFilter [delegate]="filter">
          <option psUiTableColumnOption value="EMPTY">Empty</option>
          <option psUiTableColumnOption value="NOT EMPTY">Not Empty</option>
        </ng-container>
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <div class="sernewTitle">
            <a class="survey-link" [routerLink]="survey | surveyRouterLink">
              {{ survey[header.key] || '_' }}</a
            >
          </div>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [searchable]="true"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.BUYER_PROJECT_MANAGER"
      >
        <ng-container psUiTableColumnFilter [delegate]="filter">
          <option psUiTableColumnOption value="EMPTY">Empty</option>
          <option psUiTableColumnOption value="NOT EMPTY">Not Empty</option>
        </ng-container>
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <div class="sernewTitle">
            <a class="survey-link" [routerLink]="survey | surveyRouterLink">
              {{ survey[header.key] || '_' }}</a
            >
          </div>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [width]="'fit'"
        [title]="header.column"
        [field]="header.key"
        [sortable]="preferences.enableBulkEditAndSorting"
        [searchable]="preferences.enableBulkEditAndSorting"
        [searchType]="'contains'"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.SURVEY_TITLE"
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a
            class="survey-link"
            title="{{ survey.surveyTitle }}"
            [routerLink]="survey | surveyRouterLink"
          >
            {{ survey.surveyTitle | truncateWord : 50 }}
          </a>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [searchable]="isCompanySelectionAllowed"
        [searchDropdownItems]="isCompanySelectionAllowed"
        [showClearStateButton]="true"
        [multi]="false"
        [sortable]="false"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.BUYER_ACCOUNT"
      >
        <ng-container psUiTableColumnFilter [show]="isCompanySelectionAllowed">
          <option
            *ngFor="let company of companies$ | async"
            psUiTableColumnOption
            [value]="company.name"
          >
            {{ company.name }}
          </option>
        </ng-container>
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <div class="buyerv">
            <a
              class="survey-link buyerv"
              [routerLink]="survey | surveyRouterLink"
              >{{ survey.buyerAccount }}</a
            >
          </div>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [sortable]="preferences.enableBulkEditAndSorting"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.FIELDED"
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a class="survey-link" [routerLink]="survey | surveyRouterLink"
            >{{ survey.fielded | number }} | {{ survey.goal | number }}</a
          >
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [sortable]="preferences.enableBulkEditAndSorting"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.AVERAGE_CPI"
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a class="survey-link" [routerLink]="survey | surveyRouterLink">{{
            survey.averageCpi !== 'NA'
              ? (survey.averageCpi
                | currency : (!supplier ? survey.currencySymbol : '$'))
              : 'NA'
          }}</a>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.EPC"
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a class="survey-link" [routerLink]="survey | surveyRouterLink">{{
            survey.epc !== undefined
              ? (survey.epc | currency : survey.currencySymbol)
              : 'NA'
          }}</a>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.PROJECT_COST"
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a class="survey-link" [routerLink]="survey | surveyRouterLink">{{
            survey.current_cost | currency : survey.currencySymbol
          }}</a>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.MARGIN"
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a class="survey-link" [routerLink]="survey | surveyRouterLink"
            >${{ survey[header.key] }}</a
          >
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.BANK"
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a class="survey-link" [routerLink]="survey | surveyRouterLink"
            >${{ survey[header.key] }}</a
          >
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [sortable]="preferences.enableBulkEditAndSorting"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.COUNTRY_CODE"
      >
        <ng-container
          [show]="preferences.enableBulkEditAndSorting"
          psUiTableColumnFilter
        >
          <option
            *ngFor="let country of countries"
            psUiTableColumnOption
            [value]="country.short_Code"
          >
            {{ country.name }}
          </option>
        </ng-container>
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <ps-multi-country-info-listing
            *ngIf="
              survey?.mc?.SVP &&
              survey?.mc?.parent &&
              survey?.mc?.surveys?.length
            "
            [countries]="countries"
            [mcSvpChildSurveys]="survey.mc?.surveys"
          >
            <ng-template psUiTableCellTemplate>
              <div
                class="multiple-label-container d-flex justify-content-center"
              >
                <div class="mcqb-survey-count pb-0 rounded-2">
                  {{ survey.mc.surveys.length }}
                </div>
                <div class="multiple-label">Multiple</div>
              </div>
            </ng-template>
            <ng-template
              psMultiCountryColumnContent
              classList="px-0 py-2 country-name-column"
              let-survey
            >
              <span> {{ survey.countryName }} </span>
            </ng-template>

            <ng-template
              psMultiCountryColumnContent
              classList="px-0 py-2"
              let-survey
            >
              <span class="p-1 fw-bold language-icon">
                {{ survey.languageCode | uppercase }}
              </span>
            </ng-template>
          </ps-multi-country-info-listing>
          <a
            class="survey-link"
            [routerLink]="survey | surveyRouterLink"
            *ngIf="!survey?.mc?.SVP || !survey?.mc?.parent"
          >
            {{ survey.mc_count ? 'MC' : survey[header.key] }}
          </a>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [sortable]="preferences.enableBulkEditAndSorting"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.LAUNCH_DATE"
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a
            [ngClass]="{
              'survey-link': survey.survey_launch_date,
              'blank-date': !survey.survey_launch_date
            }"
            [routerLink]="survey | surveyRouterLink"
          >
            {{
              survey.survey_launch_date
                ? (survey.survey_launch_date | date : 'yyyy/MM/dd HH:mm')
                : '-'
            }}
          </a>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [sortable]="preferences.enableBulkEditAndSorting"
        [field]="header.key"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.RECONCILED"
      >
        <ng-container
          [show]="preferences.enableBulkEditAndSorting"
          psUiTableColumnFilter
        >
          <option psUiTableColumnOption value="true">Yes</option>
          <option psUiTableColumnOption value="false">No</option>
        </ng-container>
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a class="survey-link" [routerLink]="survey | surveyRouterLink">{{
            survey[header.key] ? 'YES' : 'NO'
          }}</a>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [sortable]="preferences.enableBulkEditAndSorting"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.SURVEY_ID"
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a class="survey-link" [routerLink]="survey | surveyRouterLink">{{
            survey[header.key]
          }}</a>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [sortable]="preferences.enableBulkEditAndSorting"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.IN_PROGRESS"
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a class="survey-link" [routerLink]="survey | surveyRouterLink">{{
            survey[header.key]
          }}</a>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [sortable]="preferences.enableBulkEditAndSorting"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.CURRENT_IR"
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a class="survey-link" [routerLink]="survey | surveyRouterLink">{{
            survey[header.key]
          }}</a>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [sortable]="preferences.enableBulkEditAndSorting"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.LOI"
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a class="survey-link" [routerLink]="survey | surveyRouterLink">{{
            survey[header.key]
          }}</a>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.HEALTH_CHECK"
      >
        <ng-container
          [show]="preferences.enableBulkEditAndSorting"
          psUiTableColumnFilter
          [delegate]="filter"
        >
          <option psUiTableColumnOption group="Filter by Alerts" value="RED">
            Red
          </option>
          <option psUiTableColumnOption value="YELLOW">Yellow</option>
          <option psUiTableColumnOption value="GREEN">Green</option>
          <option psUiTableColumnOption value="GREY">Grey</option>
          <option psUiTableColumnOption value="NOT AVAILABLE">
            Not Available
          </option>

          <option
            psUiTableColumnOption
            group="Filter by Metrics"
            value="Buyer Drop Rate"
          >
            Buyer Drop Rate
          </option>
          <option psUiTableColumnOption value="Buyer Over Quota">
            Buyer Over Quota
          </option>
          <option psUiTableColumnOption value="Completes Per Hour">
            Completes Per Hour
          </option>
          <option psUiTableColumnOption value="Pace">Pace</option>
          <option psUiTableColumnOption value="IR Delta">IR Delta</option>
          <option psUiTableColumnOption value="LOI Delta">LOI Delta</option>
          <option psUiTableColumnOption value="PS Status Ratio">
            PS Status Ratio
          </option>
          <option psUiTableColumnOption value="Supplier Dominance">
            Supplier Dominance
          </option>
          <option psUiTableColumnOption value="Too Good to be True">
            Too Good to be True
          </option>
        </ng-container>

        <ng-template psUiTableCellTemplate let-survey>
          <ps-health-check-widget
            [survey]="survey"
            [countries]="countries"
            [boundaries]="
              table?.container?.nativeElement?.getBoundingClientRect()
            "
          ></ps-health-check-widget>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [searchable]="preferences.enableBulkEditAndSorting"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.BILLING_NUMBER"
      >
        <ng-container
          [show]="preferences.enableBulkEditAndSorting"
          psUiTableColumnFilter
          [delegate]="filter"
        >
          <option psUiTableColumnOption value="EMPTY">Empty</option>
          <option psUiTableColumnOption value="NOT EMPTY">Not Empty</option>
        </ng-container>
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <div class="sernewTitle">
            <a class="survey-link" [routerLink]="survey | surveyRouterLink">
              {{ survey[header.key] | truncateWord : 16 }}</a
            >
          </div>
        </ng-template>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [field]="header.key"
        [searchable]="preferences.enableBulkEditAndSorting"
        [sortable]="preferences.enableBulkEditAndSorting"
        [searchType]="'contains'"
        [showClearStateButton]="true"
        *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.TEMPLATE"
      >
        <ng-template
          *ngIf="preferences.enableBulkEditAndSorting"
          psUiTableColumnCustomHeader
        >
          <ps-ui-tree-dropdown
            class="template-dropdown"
            [labelNone]="'Template'"
            labelAlignment="start"
            [dropdownItems]="(templates$ | async) || []"
            [folderOption]="templateFolderOption"
            [fileOption]="templateOption"
            (selectedFiles)="onSelectedTemplates($event)"
            [multi]="true"
            [showFooterSelectButton]="true"
            [showFolderArrowIcon]="true"
            [addNewFolder]="true"
            [isFirstFooterButton]="false"
            [footerSelectButtonText]="'Confirm'"
            [indicator]="true"
          >
            <ng-template psUiSelectDropdownIcon>
              <i class="fa fa-sort template-icon" aria-hidden="true"></i>
            </ng-template>
          </ps-ui-tree-dropdown>
        </ng-template>
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <div class="sernewTitle">
            <a class="survey-link" [routerLink]="survey | surveyRouterLink"
              >{{ survey[header.key] | truncateWord : 10 }}
            </a>
          </div>
        </ng-template>
      </ng-container>

      <ng-container
        *ngSwitchCase="
          [
            SURVEY_LISTING_TABLE_HEADERS.MULTI_COUNTRY,
            SURVEY_LISTING_TABLE_HEADERS.TRAFFIC_CHANNELS
          ] | switchMultiCase : header.key
        "
      >
        <ng-container
          psUiTableColumn
          [title]="header.column"
          [showClearStateButton]="true"
        >
          <ng-template psUiTableCellTemplate let-survey let-index="index">
            <div class="widget-column">
              <ps-widget-button
                *ngIf="preferences.widget(header.key).count(survey)"
                [widget]="preferences.widget(header.key)"
                [count]="preferences.widget(header.key).count(survey)"
                [active]="
                  selectedWidget.selected &&
                  selectedWidget.for(survey) &&
                  selectedWidget.key === header.key
                "
                (tap)="onWidgetPressed($event, survey, index)"
              ></ps-widget-button>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-container
        psUiTableColumn
        [title]="header.column"
        [showClearStateButton]="true"
        *ngSwitchDefault
      >
        <ng-template
          psUiTableCellTemplate
          (tap)="onTableCellClicked($event)"
          let-survey
        >
          <a class="survey-link" [routerLink]="survey | surveyRouterLink">{{
            survey[header.key]
          }}</a>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-container psUiTableColumn title="Manage" [width]="'fit'">
      <ng-template
        psUiTableColumnCustomHeader
        *ngIf="preferences.configs.dynamic"
      >
        <div class="gear">
          <a (click)="openDashboardPreferenceModal()">
            <i class="fa fa-2x fa-gear"></i>
          </a>
        </div>
      </ng-template>

      <ng-template
        psUiTableCellTemplate
        *psHasRole="['operator', 'buyer']"
        let-survey
      >
        <div class="dots-outer">
          <button
            class="dotsv"
            *ngIf="survey.status === SURVEY_STATUS.Draft"
            (click)="
              onDetails(survey.surveyId, survey.status, survey.samplesType)
            "
          >
            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
          </button>
          <button
            class="dotsv"
            [psUiDropdownToggleFor]="manageDropDown"
            *ngIf="survey.status !== SURVEY_STATUS.Draft"
            [disabled]="
              (preferences.user === 'operator' &&
                !preferences.serviceOperator) ||
              preferences.user === 'supplier'
            "
          >
            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
          </button>
          <ps-ui-dropdown
            class="manage-dropdown"
            #manageDropDown="psUiDropdown"
            [canClose]="managing"
            (click)="onCloseManageMenu()"
          >
            <button
              psUiDropdownItem
              (click)="
                onDetails(survey.surveyId, survey.status, survey.samplesType)
              "
            >
              Manage
            </button>
            <button
              *ngIf="survey.samplesType !== RECONTACT_SURVEY_SAMPLE_TYPE"
              psUiDropdownItem
              (click)="onClone(survey.surveyId)"
              psUiGtagEvent
              trackOn="click"
              action="create_survey"
              category="Survey_Create"
              [params]="{ event_label: 'Clone Button Clicked' }"
            >
              Clone
            </button>
            <ps-ui-dropdown
              [toggleSubmenu]="true"
              class="btn-group"
              *ngIf="
                preferences.configs.trafficChannels &&
                survey.status === SURVEY_STATUS.Live
              "
            >
              <button
                type="button"
                class="btn btn-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                [disabled]="survey?.mc?.SVP"
                (click)="onOpenTrafficChannelManage()"
              >
                Traffic Channel
              </button>
              <ul class="dropdown-menu">
                <li>
                  <ps-ui-dropdown-item
                    (click)="
                      onCreateTC(
                        'REPLICA',
                        'surveyListingPage',
                        survey.surveyId
                      )
                    "
                    >Replica</ps-ui-dropdown-item
                  >
                </li>
                <li>
                  <ps-ui-dropdown-item
                    (click)="
                      onCreateTC(
                        'UNTARGETED',
                        'surveyListingPage',
                        survey.surveyId
                      )
                    "
                    >Untargeted</ps-ui-dropdown-item
                  >
                </li>
                <li>
                  <ps-ui-dropdown-item
                    (click)="
                      onCreateTC(
                        'SPECIFIC AUDIENCE',
                        'surveyListingPage',
                        survey.surveyId
                      )
                    "
                    >Specific Audience</ps-ui-dropdown-item
                  >
                </li>
                <li>
                  <ps-ui-dropdown-item
                    (click)="
                      onCreateTC(
                        'TARGETED LIST',
                        'surveyListingPage',
                        survey.surveyId
                      )
                    "
                    >Targeted List</ps-ui-dropdown-item
                  >
                </li>
              </ul>
            </ps-ui-dropdown>

            <button
              psUiDropdownItem
              (click)="onDownloadReport(survey.surveyId)"
            >
              Download Report
            </button>
          </ps-ui-dropdown>
        </div>
        <span
          *ngIf="survey.status === SURVEY_STATUS.Draft && tab.status !== 'all'"
          class="del-btn"
        >
          <button (click)="onDelete(survey.surveyId, survey.status)">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </span>
      </ng-template>

      <ng-template psUiTableCellTemplate *psHasRole="'supplier'">
        <div class="dots-outer">
          <button class="dotsv">
            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
          </button>
        </div>
      </ng-template>
    </ng-container>

    <ng-template psUiTableExpandableContent let-survey>
      <ps-widget-container
        [expanded]="true"
        [widget]="selectedWidget.widget"
        [survey]="survey"
        [isDasaboard]="isDasaboard"
        [tab]="tab"
        [isMultiCountryWidget]="isDashboardEnabled(survey)"
      ></ps-widget-container>
    </ng-template>
  </ng-container>

  <ng-template psUiTableCaption [placement]="'bottom'">
    <p>
      Showing 1 to {{ length }}
      <!-- of {{ tab.count }} entries -->
    </p>
  </ng-template>
</ps-ui-table>
