import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ps-import-decipher-surveys-iframe',
  templateUrl: './import-decipher-surveys-iframe.component.html',
  styleUrls: ['./import-decipher-surveys-iframe.component.css'],
})
export class ImportDecipherSurveysIframeComponent {
  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
    if (event?.data === 'moveToDashboard') {
      this._router.navigateByUrl('/dashboard');
    }
  }

  constructor(private _router: Router) {}
}
