import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GetBuyerConfigResponse } from './buyer-config.service.interface';

@Injectable({
  providedIn: 'root',
})
export class BuyerConfigService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  getBuyerConfig() {
    return this._http.get<GetBuyerConfigResponse>(
      `${this._baseUrl}/buyerConfigSettings`
    );
  }
}
