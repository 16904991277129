<div class="container">
  <div class="row d-flex justify-content-center">
    <h6 class="text-uppercase text-center fw-bold mb-5">
      Prevalence Multiplier
    </h6>
    <div class="d-flex justify-content-center">
      <input
        #prevalenceMultiplierFileUpload
        hidden
        type="file"
        class="file-upload"
        accept=".csv"
        (change)="uploadFile($event, uploadType.PrevalenceMultiplier)"
      />
      <button
        type="button"
        class="btn btn-link upload-button"
        (click)="
          prevalenceMultiplierFileUpload.value = '';
          prevalenceMultiplierFileUpload.click()
        "
      >
        Upload Prevalence Multiplier File
      </button>
    </div>
    <div class="d-flex justify-content-center">.csv</div>
    <div class="d-flex justify-content-center p-3">
      <a
        type="button"
        class="btn btn-link download-button"
        (click)="downloadPrevalenceMultiplierTemplate()"
      >
        Download Template
      </a>
    </div>
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-link download-button"
        (click)="downloadCurrentFile(uploadType.PrevalenceMultiplier)"
      >
        Download Prevalence Multipliers CSV
      </button>
    </div>
    <div class="col-sm-4">
      <div class="col-sm-12 col-md-12 form-group mb-35">
        <ps-ui-select-dropdown
          [dropdownItems]="countries"
          [field]="'name'"
          [enableSearch]="true"
          labelNone="Country"
          [label]="selectedCountry?.name"
          (selectedItem)="selectCountry($event)"
        >
        </ps-ui-select-dropdown>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="flex-column" *ngIf="selectedCountry?.name">
      <div class="row">
        <h6 class="text-uppercase text-center fw-bold mb-5">
          Pureprice Rate Cards
        </h6>
      </div>
      <div class="d-flex justify-content-center">
        <input
          #rateCardsFileUpload
          hidden
          type="file"
          class="file-upload"
          accept=".xlsx,.xls,.csv"
          (change)="uploadFile($event, uploadType.RateCard)"
        />
        <button
          type="button"
          class="btn btn-link upload-button"
          (click)="rateCardsFileUpload.value = ''; rateCardsFileUpload.click()"
        >
          Upload a file for {{ selectedCountry?.name }}
        </button>
      </div>
      <div class="d-flex justify-content-center">.csv, .xlsx, .xlx</div>
      <div class="d-flex justify-content-center p-3">
        <a
          type="button"
          class="btn btn-link download-button"
          (click)="downloadRateCardTemplateFile()"
        >
          Download Template
        </a>
      </div>
      <div class="d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-link download-button"
          (click)="downloadCurrentFile(uploadType.RateCard)"
        >
          Download Rate Card CSV
        </button>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="selectedCountry">
    <h6 class="text-uppercase text-center fw-bold mt-5 mb-3">
      Pureprice Field Time Multipliers
    </h6>
  </div>
  <div class="row mt-4">
    <div class="flex-column" *ngIf="selectedCountry?.name">
      <div class="d-flex justify-content-center">
        <input
          #fieldtimeMultipliersFileUpload
          hidden
          type="file"
          class="file-upload"
          accept=".xlsx,.xls,.csv"
          (change)="uploadFile($event, uploadType.FieldTimeMultiplier)"
        />
        <button
          type="button"
          class="btn btn-link upload-button"
          (click)="
            fieldtimeMultipliersFileUpload.value = '';
            fieldtimeMultipliersFileUpload.click()
          "
        >
          Upload a file for {{ selectedCountry?.name }}
        </button>
      </div>
      <div class="d-flex justify-content-center">.csv, .xlsx, .xlx</div>
      <div class="d-flex justify-content-center p-3">
        <a
          type="button"
          class="btn btn-link download-button"
          (click)="downloadFieldTimeMultiplierTemplateFile()"
        >
          Download Template
        </a>
      </div>
      <div class="d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-link download-button"
          (click)="downloadCurrentFile(uploadType.FieldTimeMultiplier)"
        >
          Download Field Time CSV
        </button>
      </div>
    </div>
  </div>
</div>
