<form class="form-inline" [formGroup]="form">
  <div class="input-group">
    <div class="form-group">
      <input
        class="form-control"
        type="text"
        formControlName="psid"
        placeholder="Enter PSID"
        (keyup)="diableInputBox()"
      />
    </div>
    <div class="form-group">
      <input
        class="form-control"
        type="text"
        formControlName="transaction_id"
        placeholder="Enter transaction_id"
        (keyup)="diableInputBox()"
      />
    </div>
  </div>
  <div class="form-group">
    <button type="button" (click)="getPSIDTransactionReport()">Find</button>
  </div>
</form>

<div class="table-responsive table-absolute mt-5" *ngIf="psidReportData.length">
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="srvId"
        >
          Survey Id
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="pName"
        >
          Survey Name
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="transId"
        >
          Transaction ID
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="survey_status"
        >
          Status
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="bName"
        >
          Buyer Name
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="supNm"
        >
          Supplier Name
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="supSid"
        >
          Supplier Session Id
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="resLoi"
        >
          Respondent Loi
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="byrCpi"
        >
          Buyer CPI
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="supCpi"
        >
          Supplier CPI
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="survey_start"
        >
          Entry Time
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of psidReportData">
        <td class="text-left">{{ item.srvId }}</td>
        <td class="text-left">{{ item.pName }}</td>
        <td class="text-left">{{ item.transId }}</td>
        <td class="text-left">{{ item.survey_status }}</td>
        <td class="text-left">{{ item.bName }}</td>
        <td class="text-left">{{ item.supNm }}</td>
        <td class="text-left">{{ item.supSid || 'NA' }}</td>
        <td class="text-left">{{ item.resLoi || 'NA' }}</td>
        <td class="text-left">{{ item.byrCpi || 'NA' }}</td>
        <td class="text-left">{{ item.supCpi || 'NA' }}</td>
        <td class="text-left">{{ item.survey_start || 'NA' }}</td>
      </tr>
    </tbody>
  </table>
</div>
