import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'configSettingsPipe',
})
export class ConfigSettingsFilterPipe implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item) => item.section.indexOf(filter.section) !== -1);
  }
}
