import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ps-s2scallbacks',
  templateUrl: './s2scallbacks.component.html',
  styleUrls: ['../base-form.css'],
})
export class S2scallbacksComponent {
  @Input()
  form!: FormGroup;
  constructor() {}
}
