'use strict';

export const Constants = Object.freeze({
  MIN_MARGIN: -99,
  MAX_MARGIN: 99,
  QUALIFICATIONS_RENDER_TYPE: {
    MULTIPUNCH_DROPDOWN: 1,
    FILE_UPLOAD: 2,
    SEARCH: 3,
    RANGE: 4,
    MULTIPUNCH_LIST: 5,
  },
  UNITS: {
    INCOME: 321,
    AGE: { YEAR: 311, MONTH: 312 },
    LIMIT_AGE_CHILD: { LIMIT_YEAR: 18, LIMIT_MONTH: 48 },
  },
  LOCATION_RES_MAPPER: {
    state: 'stData',
    csa: 'csaData',
    msa: 'msaData',
    county: 'countyData',
    dma: 'dmaList',
  },
  CONFIRM_MODAL_TEXT: {
    SWITCH_LOCATION:
      'Setting this location criteria will clear location qualifications in other categories. Select OK to continue.',
    DELETE_QUOTA:
      'Are you sure you want to delete the <QUALIFICATION_NAME> quotas?',
    ADVANCE_CORE_DEPENDENCY:
      'The qualification you have added has a dependency on <QUALIFICATION_NAME>. To prevent conflicts, that qualification will reset.',
    ADVANCE_CORE_DEPENDENCY_EXISTS:
      'You are attempting to add a qualification with a dependency. The new qualification is causing a conflict with other selections that you have made. Please check your selected qualifications and the associated dependencies to avoid conflicts.',
    DELETE_ADVANCE_CORE_DEPENDENCY:
      'Clearing this qualification will also reset the qualification on <QUALIFICATION_NAME>.',
    CLEAR_LOCATION_QUOTA:
      'Are you sure you want to clear all location quotas & qualifications?',
    ADD_CENSUS:
      'Setting Census Rep for this Qualification will reset your selections. Do you wish to continue?',
    MODIFY_QUALIFICATION:
      'Modifying the qualifications will delete the current nested quotas. Do you wish to continue?',
    TEMPLATE_SELECTION_FOR_MC: {
      SAME_LOCALE_AS_SELECTED:
        'Do you want to load the new configuration for this survey? The current configuration will be deleted.',
      NOT_SAME_LOCALE_AS_SELECTED:
        'You are trying to load a template for another country/language. The survey already exists in this Multi-country group. Do you want to use the new template configuration and delete the other survey?',
      LOCALE_NOT_EXIST_IN_MC_SURVEYS:
        'You are trying to load a template for another country/language. Do you want to add the new survey in this Multi-country group?',
    },
    HIGH_CPI:
      'You have entered a <span class="cpi-alert">HIGH CPI</span> of <span class="cpi-alert"><CURRENCY_SYMBOL><CPI></span>, please check that the value is entered exactly as you intend as all costs incurred will be billable.',
  },
  QUOTA_MODAL_ACTION: {
    APPLY_QUOTA: 'applyQuota',
    APPLY_NEST_QUOTA: 'applyNestQuota',
  },
  ADVANCE_TARGETING_ACTION: {
    ADD: 'add',
    DELETE: 'delete',
  },
  ADVANCE_TARGETING_QUAL_ID_START_INDEX: 1000,
  CREATE_SURVEY_ERROR_MESSAGE: {
    LENGTH_OF_SURVEY: 'Length of Survey cannot be empty.',
    LENGTH_OF_SURVEY_VALUE:
      'Please update with a value between 1 and 60 minutes to continue.',
    SELECT_CATEGORY: 'Category must be selected. Please update to continue.',
    SURVEY_TITLE: 'Please enter Survey Title to continue',
    COMPLETES:
      'Please enter a Number of Completes. A minimum of 1 or equal to total fielded is required',
    FIELD_TIME: 'Field Time cannot be empty. Please update to continue',
    INCIDENCE: 'Incidence cannot be empty. Please update to continue',
    ENTER_CRITERIA_SELECTION:
      'Error: @qualification must be selected. Please update to continue',
    INVALID_FIELD_OF_TIME:
      'Error: Invalid field time. Please enter a value between 1 and 999 days to continue',
    INVALID_BUYER_CPI:
      'Error: Buyer CPI cannot be empty. Please update to continue',
    REQUIRED_CATEGORY_CODE:
      'Error: Category must be selected. Please update to continue.',
  },
  CHILDREN: {
    NO_CHILDREN: 111,
    HAVE_CHILDREN: 112,
  },
  CHILD_GENDER: {
    BOTH: 'both',
    BOY: '111',
    GIRL: '112',
  },
  CHILD_GENDER_MAPPING: {
    '111': 'Boy',
    '112': 'Girl',
    both: 'Either',
  },
  CHILDREN_CONDITION_CODES: {
    NO_CHILDREN: '111',
    HAVE_CHILDREN: '112',
  },
  GROUP_QUOTA_SEPARATOR: ' or ',
  UNSUPPORTED_QUALIFICATIONS: [214],
  RANGE_LABELS: {
    MIN: 'min',
    MAX: 'max',
  },
  OPERATIONS: {
    CHILD: 'child',
    PARENT: 'parent',
  },
  SURVEY_PAYLOAD_DEFAULTS: {
    PRICE_MULTIPLIER: 0,
  },
  SURVEY_CATEGORY_CODE: {
    AUGMENT: 233,
    EXCITING_NEW: 232,
  },
  RECONTACT_SURVEY: {
    MODAL_TEXT: {
      LINK_TOGGLE_CONFIRMATION:
        'Changing your Link mode will reset the uploaded recontact list. Please reupload your recontact list using the appropriate format for this link mode. Please refer to the template to determine the correct link format. Would you like to change your link mode?',
    },
  },
  TEMPLATE_DEFAULT_CATEGORY: 232,
  AVERAGE_VARIABLE_MARGIN: 50,
  AUSTRALIA_SHORT_CODE: 'AU',
  RANGE_OPTIONS: [
    { id: 'lt', text: 'Less Than' },
    { id: 'gt', text: 'More Than' },
    { id: 'bt', text: 'Between' },
  ],
  TO_MAX_RANGE_VALUE: 99999,
  TEMPLATE_SELECTION_SCENARIOS_FOR_MC: {
    SAME_LOCALE_AS_SELECTED: 'same locale as selected',
    NOT_SAME_LOCALE_AS_SELECTED: 'different locale from selected',
    LOCALE_NOT_EXIST_IN_MC_SURVEYS: 'locale does not exist',
  },
});

export const APPLICABLE_QUALIFICATION_FOR_PURE_PRICE = [211, 212, 244, 245];
export const PURE_PRICE_COUNTRIES = ['UK', 'US', 'DE', 'CA', 'FR', 'AU'];
