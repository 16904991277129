<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-6 row">
    <div class="col-sm-6 col-md-6 col-lg-6">
      <ps-ui-select-dropdown
        [dropdownItems]="suppliers"
        [field]="'name'"
        [enableSearch]="true"
        labelNone="Select Supplier"
        [label]="supplierSelected?.name"
        [(ngModel)]="supplierSelected"
        (selectedItem)="selectSupplier($event)"
      >
      </ps-ui-select-dropdown>
    </div>

    <div class="col-sm-6 col-md-6 col-lg-6">
      <ps-ui-select-dropdown
        [dropdownItems]="countries"
        [field]="'name'"
        [enableSearch]="true"
        labelNone="Select Country"
        [label]="countrySelected?.name"
        [disabled]="!supplierSelected"
        [(ngModel)]="countrySelected"
        (selectedItem)="selectCountry($event)"
      >
      </ps-ui-select-dropdown>
    </div>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-6 row">
    <div class="col-sm-6 col-md-6 col-lg-6">
      <ps-ui-select-dropdown
        [dropdownItems]="selectedCountryLanguages"
        [field]="'name'"
        [enableSearch]="true"
        labelNone="Select Language"
        [label]="languageSelected?.name"
        [loading]="false"
        [disabled]="!countrySelected"
        [(ngModel)]="languageSelected"
        (selectedItem)="selectLanguage($event)"
      >
      </ps-ui-select-dropdown>
    </div>

    <div class="col-sm-6 col-md-6 col-lg-6">
      <ps-ui-select-dropdown
        [dropdownItems]="feasibilityList"
        [field]="'name'"
        [enableSearch]="true"
        labelNone="Select Feasibility Estimate"
        [label]="feasibilitySelected?.name"
        [disabled]="!languageSelected"
        [(ngModel)]="feasibilitySelected"
        (selectedItem)="selectFeasibility($event)"
      >
      </ps-ui-select-dropdown>
    </div>
  </div>

  <div
    class="col-sm-12 col-md-12 col-lg-6"
    class="feasibilityInputs"
    *ngIf="
      feasibilitySelected.genpopMode && feasibilitySelected.genpopMode !== 0
    "
  >
    <div class="col-sm-6 col-md-12 form-group" class="feasibilityInputs">
      <input
        type="number"
        class="form-control"
        placeholder="Desktop Monthly Active Users"
        id="xpto1"
        [(ngModel)]="feasibilityObj.desktopMonthlyActUsr"
      />
    </div>
    <div class="col-sm-6 col-md-12 form-group" class="feasibilityInputs">
      <input
        type="number"
        class="form-control"
        placeholder="Mobile Monthly Active Users"
        [(ngModel)]="feasibilityObj.mobileMonthlyActUsr"
      />
    </div>
    <div class="col-sm-6 col-md-12 form-group" class="feasibilityInputs">
      <input
        type="number"
        class="form-control"
        placeholder="Partner Delivery Multiplier"
        [(ngModel)]="feasibilityObj.patnrDelvryMulplr"
      />
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <button
      class="save_button feasibilityInputs feasibilityButton back-to-dashboard"
      id="dashboard"
      type="button"
      psUiButton
      size="small"
      color="primary"
      [routerLink]="['/dashboard/']"
    >
      Back to Dashboard
    </button>
  </div>
  <div class="col-md-3"></div>
  <div class="col-md-3 pull-right" *ngIf="feasibilitySelected.genpopMode !== 0">
    <button
      class="save_button feasibilityInputs feasibilityButton save-button"
      id="save"
      type="button"
      psUiButton
      size="small"
      color="primary"
      (click)="saveFeasibility()"
    >
      Save
    </button>
  </div>
  <div class="col-md-3"></div>
</div>
<div class="row d-flex justify-content-center feasibility-upload">
  <h6 class="text-uppercase text-center fw-bold mb-5">Feasibility Upload</h6>
  <div class="d-flex justify-content-center">
    <input
      #feasibilityFileUpload
      hidden
      type="file"
      class="file-upload"
      accept=".csv"
      (change)="uploadFile($event)"
    />
    <button
      type="button"
      class="btn btn-link upload-button"
      (click)="feasibilityFileUpload.value = ''; feasibilityFileUpload.click()"
    >
      Upload a file for Feasibility
    </button>
  </div>
  <div class="d-flex justify-content-center">.csv, .xls</div>
  <div class="d-flex justify-content-center p-3">
    <a
      type="button"
      class="btn btn-link download-button"
      (click)="downloadTemplate()"
    >
      Download Template
    </a>
  </div>
  <div class="d-flex justify-content-center">
    <button
      type="button"
      class="btn btn-link download-button"
      (click)="downloadFile()"
    >
      Download Current Feasibility CSV
    </button>
  </div>
</div>
