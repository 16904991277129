'use strict';

export const LayoutConstants = Object.freeze({
  MAX_CPI_THRESHOLD: 10,
  CONFIRM_MODAL: {
    EDIT_SUPPLIER: {
      TEXT: 'The total completes among suppliers adds up to more than 100% of the survey’s goal. Do you want to proceed?',
      CONFIRM_BUTTON_TEXT: 'Yes',
      CANCEL_BUTTON_TEXT: 'No',
    },
    TEMPLATES: {
      TEXT: 'Do you want to override the current template?',
      CONFIRM_BUTTON_TEXT: 'Confirm',
      CANCEL_BUTTON_TEXT: 'No',
    },
    BILLING_NUMBER: {
      TEXT: 'The survey(s) being invoiced do not have a Billing issued. If this is not required please click OK, otherwise Cancel and add a billing number prior to invoicing.',
      CONFIRM_BUTTON_TEXT: 'OK',
      CANCEL_BUTTON_TEXT: 'Cancel',
    },
  },
});
