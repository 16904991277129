<div class="d-flex justify-content-center">
  <form
    *ngIf="company$ | async"
    class="companies_form"
    (ngSubmit)="saveCompany()"
    [formGroup]="form"
  >
    <div class="form-title">
      <h4 class="list_heading">Company Info</h4>
    </div>

    <div class="row form-group">
      <div class="col-md-4">
        <label class="control-label pull-right">Name</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control input-tag input"
          placeholder="Name"
          formControlName="name"
        />
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-4">
        <label class="control-label pull-right">Address</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control input-tag input"
          placeholder="Address"
          formControlName="address"
        />
      </div>
    </div>

    <div class="row form-group" *ngIf="!selectedCompany?.fx">
      <div class="col-md-4 tooltip-visible">
        <div
          class="fa fa-info-circle tootip-setting-info setting-info float-end"
        >
          <div class="tooltip-content">
            <strong>The approved currencies are:</strong>
            <ul>
              <li>USD</li>
              <li>GBP</li>
              <li>EUR</li>
              <li>AUD</li>
            </ul>
            <strong>
              With approval from Accounting, you may also be permitted to use:
            </strong>
            <ul>
              <li>CAD</li>
              <li>SGD</li>
              <li>NZD</li>
            </ul>
            Other currencies, while listed will not import to ChurnZero and may
            result in the account not invoicing correctly. Please seek approval
            from Accounting before applying any of the other currencies.
          </div>
        </div>
        <label class="control-label float-end">Currency</label>
      </div>
      <div class="col-md-8">
        <ps-ui-select-dropdown
          class="manage-select-dropdown"
          [dropdownItems]="currencies"
          [field]="'currencyShortCode'"
          [label]="selectedCurrency?.currencyShortCode"
          (selectedItem)="setSelectedCompanyCurrency($event)"
        >
        </ps-ui-select-dropdown>
      </div>
    </div>

    <div class="row form-group" *ngIf="selectedCompany?.fx">
      <div class="col-md-4">
        <label class="control-label pull-right">Currency</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          [attr.disabled]="disableCurrency ? true : null"
          [ngClass]="{ 'not-allowed-cursor': disableCurrency }"
          class="form-control input-tag input"
          [value]="currencyPlaceholder"
        />
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-4">
        <label class="control-label pull-right">Phone</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control input-tag input"
          placeholder="Phone"
          formControlName="phone"
        />
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-4">
        <label class="control-label pull-right">Primary Liasion</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control input-tag input"
          placeholder="Primary Liasion"
          formControlName="primaryLiaison"
        />
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-4">
        <label class="control-label pull-right">Secondary Liasion</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control input-tag input"
          placeholder="Secondary Liasion"
          formControlName="secondaryLiaison"
        />
      </div>
    </div>

    <div class="row form-group" *ngIf="isASupplier">
      <div class="col-md-4">
        <label class="control-label pull-right"
          >Supplier Url For Completes:</label
        >
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control input-tag input"
          placeholder="Supplier Url For Completes"
          formControlName="supp_url_completes"
        />
      </div>
    </div>

    <div class="row form-group" *ngIf="isASupplier">
      <div class="col-md-4">
        <label class="control-label pull-right">Supplier Url For Terms:</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control input-tag input"
          placeholder="Supplier Url For Terms"
          formControlName="supp_url_terms"
        />
      </div>
    </div>

    <div class="row form-group" *ngIf="isASupplier">
      <div class="col-md-4">
        <label class="control-label pull-right"
          >Supplier Url For OverQuotas:</label
        >
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control input-tag input"
          placeholder="Supplier Url For OverQuotas"
          formControlName="supp_url_overquota"
        />
      </div>
    </div>

    <div class="row form-group" *ngIf="isASupplier">
      <div class="col-md-4">
        <label class="control-label pull-right"
          >Supplier Url For Security Termination:</label
        >
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control input-tag input"
          placeholder="Supplier Url For Security Termination"
          formControlName="supp_url_security_terms"
        />
      </div>
    </div>

    <div class="row form-group" *ngIf="isASupplier">
      <div class="col-md-4">
        <label class="control-label pull-right">Send Notification</label>
      </div>
      <div class="col-md-8">
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isNotify"
            [value]="true"
            id="Enable"
            formControlName="isNotify"
          />
          Yes
        </label>
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isNotify"
            [value]="false"
            id="NotEnable"
            formControlName="isNotify"
          />
          No
        </label>
      </div>
    </div>

    <div class="row form-group" *ngIf="isASupplier">
      <div class="col-md-4">
        <label class="control-label pull-right">Expose Buyer ID by API</label>
      </div>
      <div class="col-md-8">
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isExpose"
            [value]="true"
            id="Enable"
            formControlName="isExpose"
          />
          Yes
        </label>
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isExpose"
            [value]="false"
            id="NotEnable"
            formControlName="isExpose"
          />
          No
        </label>
      </div>
    </div>

    <div class="row form-group" *ngIf="isASupplier">
      <div class="col-md-4">
        <label class="control-label pull-right"
          >Expose Additional Metrics</label
        >
      </div>
      <div class="col-md-8">
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isAdditionalMetrics"
            [value]="true"
            id="Enable"
            formControlName="isAdditionalMetrics"
          />
          Yes
        </label>
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isAdditionalMetrics"
            [value]="false"
            id="NotEnable"
            formControlName="isAdditionalMetrics"
          />
          No
        </label>
      </div>
    </div>

    <div class="row form-group" *ngIf="isASupplier">
      <div class="col-md-4">
        <label class="control-label pull-right"
          >Expose PureScore Flag by API</label
        >
      </div>
      <div class="col-md-8">
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isPureScoreEnable"
            [value]="true"
            id="Enable"
            formControlName="isPureScoreEnable"
          />
          Yes
        </label>
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isPureScoreEnable"
            [value]="false"
            id="NotEnable"
            formControlName="isPureScoreEnable"
          />
          No
        </label>
      </div>
    </div>
    <div class="row form-group" *ngIf="isASupplier">
      <div class="col-md-4">
        <label class="control-label pull-right">Expose RD Flags by API</label>
      </div>
      <div class="col-md-8">
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isRdFlagsEnable"
            [value]="true"
            id="Enable"
            formControlName="isRdFlagsEnable"
          />
          Yes
        </label>
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isRdFlagsEnable"
            [value]="false"
            id="NotEnable"
            formControlName="isRdFlagsEnable"
          />
          No
        </label>
      </div>
    </div>
    <div class="row form-group" *ngIf="isASupplier">
      <div class="col-md-4">
        <label class="control-label pull-right"
          >Append profile on redirects</label
        >
      </div>
      <div class="col-md-8">
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="appendProfileOnRedirects"
            [value]="true"
            id="Enable"
            formControlName="appendProfileOnRedirects"
          />
          Yes
        </label>
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="appendProfileOnRedirects"
            [value]="false"
            id="NotEnable"
            formControlName="appendProfileOnRedirects"
          />
          No
        </label>
      </div>
    </div>
    <div class="row form-group" *ngIf="isABuyer">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <label for="isTestAccount" class="col-form-label pull-right"
          >Is Test Account</label
        >
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <ps-ui-slider
          id="isTestAccount"
          (selectedChange)="setTestAccountFlag($event)"
          [selected]="form.value.isTestAccount"
        ></ps-ui-slider>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-4">
        <label class="control-label pull-right">Is a Buyer</label>
      </div>
      <div class="col-md-8">
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isABuyer"
            [value]="true"
            id="Enable"
            [attr.disabled]="isASupplier || isAnOperator ? true : null"
            (change)="setBuyer()"
            formControlName="isABuyer"
          />
          Yes
        </label>
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isABuyer"
            [value]="false"
            id="NotEnable"
            [attr.disabled]="isASupplier || isAnOperator ? true : null"
            (change)="setBuyer()"
            formControlName="isABuyer"
          />
          No
        </label>
      </div>
    </div>

    <div class="row form-group" *ngIf="isABuyer">
      <div class="col-md-4">
        <label class="control-label pull-right">Is A Service Account</label>
      </div>
      <div class="col-md-8">
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isAServiceAccount"
            [value]="true"
            id="Enable"
            formControlName="isAServiceAccount"
          />
          Yes
        </label>
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isAServiceAccount"
            [value]="false"
            id="NotEnable"
            formControlName="isAServiceAccount"
          />
          No
        </label>
      </div>
    </div>

    <div class="row form-group" *ngIf="isABuyer">
      <div class="col-md-4">
        <label class="control-label pull-right">Invoicing Type</label>
      </div>
      <div class="col-sm-8 d-flex align-items-center">
        <select
          formControlName="tempGenerateInvoice"
          class="form-control invoice_list"
          style="color: #333"
        >
          <option *ngIf="!form.value.tempGenerateInvoice" disabled selected>
            Select
          </option>
          <option value="MONTHLY">Monthly</option>
          <option value="PROJECT">Project</option>
          <option value="PO">PO</option>
        </select>
        <div class="setting-info">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <p style="white-space: normal">
            Any change on invoicing setting will be applied the next month.
            <br />Current Invoicing Type: {{ form.value.generateInvoice }}
          </p>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-4">
        <label class="control-label pull-right">Is a Supplier</label>
      </div>
      <div class="col-md-8">
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isASupplier"
            [value]="true"
            id="Enable"
            [attr.disabled]="isABuyer || isAnOperator ? true : null"
            (change)="setSupplier()"
            formControlName="isASupplier"
          />
          Yes
        </label>
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isASupplier"
            [value]="false"
            id="NotEnable"
            [attr.disabled]="isABuyer || isAnOperator ? true : null"
            (change)="setSupplier()"
            formControlName="isASupplier"
          />
          No
        </label>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-4">
        <label class="control-label pull-right">Is an Operator</label>
      </div>
      <div class="col-md-8">
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isAnOperator"
            [value]="true"
            id="Enable"
            [attr.disabled]="isABuyer || isASupplier ? true : null"
            (change)="setOperator()"
            formControlName="isAnOperator"
          />
          Yes
        </label>
        <label class="radio-inline pad-right-8">
          <input
            type="radio"
            name="isAnOperator"
            [value]="false"
            id="NotEnable"
            [attr.disabled]="isABuyer || isASupplier ? true : null"
            (change)="setOperator()"
            formControlName="isAnOperator"
          />
          No
        </label>
      </div>
    </div>

    <div class="row form-group" *ngIf="isABuyer">
      <div class="col-md-4">
        <label class="control-label pull-right">Account Manager(s)</label>
      </div>
      <div class="col-sm-8">
        <ng-select
          name="accountManagers"
          class="custom"
          [clearable]="false"
          [items]="pmEmailsToDisplay"
          bindLabel="name"
          placeholder="Search"
          [multiple]="true"
          formControlName="accountManagers"
        >
        </ng-select>
      </div>
    </div>

    <div class="row form-group" *ngIf="isABuyer">
      <div class="col-md-4">
        <label class="control-label pull-right">Project Manager</label>
      </div>
      <div class="col-sm-8">
        <ng-select
          name="projectManager"
          class="project-manager-dropdown"
          [clearable]="false"
          [items]="pmEmailsToDisplay"
          bindLabel="name"
          placeholder="Search"
          [multiple]="false"
          formControlName="projectManager"
        >
        </ng-select>
      </div>
    </div>
    <div class="row form-group" *ngIf="isABuyer">
      <div class="col-md-4">
        <label class="control-label pull-right">Org ID</label>
      </div>
      <div class="col-md-8">
        <input
          type="text"
          class="form-control"
          placeholder="Organization ID"
          formControlName="orgId"
          (keypress)="validateAlphaNumeric($event)"
        />
      </div>
    </div>

    <ng-container>
      <ps-netsuite-form
        *ngIf="form.value.isABuyer || form.value.isASupplier || isFusion"
        [formGroup]="form"
        [selectedCompany]="selectedCompany"
        [currencies]="currencies"
        [isFusion]="isFusion"
      ></ps-netsuite-form>
    </ng-container>

    <div class="d-flex justify-content-around mb-3">
      <button
        type="button"
        class="btn btn-info cancel_btn"
        psUiButton
        [outline]="true"
        (click)="resetCompanyFormData()"
      >
        CANCEL
      </button>
      <button
        *ngIf="selectedCompany?.id"
        [disabled]="form.controls.netsuite.invalid"
        type="button"
        class="btn btn-info submit_btn"
        psUiButton
        [outline]="true"
        (click)="updateCompany()"
      >
        UPDATE
      </button>
      <button
        *ngIf="!selectedCompany?.id"
        type="submit"
        class="btn btn-info submit_btn"
        psUiButton
        [outline]="true"
        [disabled]="form.invalid"
      >
        SAVE
      </button>
      <button
        *ngIf="selectedCompany?.id"
        type="button"
        class="btn btn-info delete_btn"
        psUiButton
        [outline]="true"
        (click)="deleteCompany()"
      >
        DELETE
      </button>
    </div>
  </form>
</div>
