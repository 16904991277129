import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivate {
  constructor(private _router: Router, private _auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const { data = {} } = route;
    const roles = data.roles || [];

    if (!roles.length) {
      throw new Error('Roles are not provided');
    }

    if (!this._auth.isLoggedIn() || !roles.includes(this._auth.userType)) {
      return this._router.parseUrl('/access-denied');
    }

    return true;
  }
}
