<form class="form-inline" [formGroup]="form">
  <div class="input-group">
    <div class="form-group">
      <input
        class="form-control"
        type="text"
        formControlName="psid"
        placeholder="Enter PSID"
        (keyup)="diableInputBox()"
      />
    </div>
    <div class="form-group">
      <input
        class="form-control"
        type="text"
        formControlName="transaction_id"
        placeholder="Enter transaction_id"
        (keyup)="diableInputBox()"
      />
    </div>
  </div>
  <div class="form-group">
    <button type="button" (click)="getPSIDTransactionReport()">Find</button>
  </div>
</form>

<div
  class="table-responsive table-absolute mt-5"
  *ngIf="profileReportData.length"
>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th
          scope="col"
          [psSortTable]="profileReportData"
          data-order=""
          data-name="qual_code"
        >
          Qualification Code
        </th>
        <th
          scope="col"
          [psSortTable]="profileReportData"
          data-order=""
          data-name="cond_code"
        >
          Condition Code
        </th>
        <th
          scope="col"
          [psSortTable]="profileReportData"
          data-order=""
          data-name="input_type"
        >
          Input Type
        </th>
        <th
          scope="col"
          [psSortTable]="profileReportData"
          data-order=""
          data-name="input_date"
          data-type="date"
        >
          Input date
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of profileReportData">
        <td class="text-left">{{ item.qual_code }}</td>
        <td class="text-left">{{ item.cond_code }}</td>
        <td class="text-left">{{ item.input_type }}</td>
        <td class="text-left">{{ item.input_date }}</td>
      </tr>
    </tbody>
  </table>
</div>
