import { Component, OnInit, OnDestroy } from '@angular/core';

import * as fileSaver from 'file-saver';
import { Subscription } from 'rxjs';

import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { CompanyService } from '../../shared/services/company/company.service';
import { CommonService } from '../../shared/services/common-service/common-service';
import { ManageFeasibilityService } from './manage-feasibility.service';
import { notifyMessage } from '../../constants/notify-message';
import { Constants } from './manage-feasibility.constants';
import {
  CompaniesResponse,
  CompanyResponseObject,
} from '../../shared/interfaces/company.interface';
import {
  EachCountry,
  EachLanguage,
} from 'src/app/shared/interfaces/create-surveys.interface';
import {
  FeasibilitySettingMeta,
  FeasibilityEstimate,
} from './manage-feasibility.interface';

@Component({
  selector: 'ps-manage-feasibility',
  templateUrl: './manage-feasibility.component.html',
  styleUrls: ['./manage-feasibility.component.css'],
})
export class ManageFeasibilityComponent implements OnInit, OnDestroy {
  public suppliers: CompanyResponseObject[] = [];
  public selectedCountryLanguages: EachLanguage[] = [];
  public feasibilityList: FeasibilityEstimate[] = [];
  public feasibilitySelected = {} as FeasibilityEstimate;
  public countries: Array<EachCountry> = [];
  public desktopMonthlyActUsr: string = '';
  public mobileMonthlyActUsr: string = '';
  public patnrDelvryMulplr: string = '';
  public supplierSelected!: CompanyResponseObject;
  public countrySelected!: EachCountry;
  public languageSelected?: EachLanguage;
  public feasibilitySettings: Array<FeasibilitySettingMeta> = [];
  public feasibilityObj = {} as FeasibilitySettingMeta;

  private _subscriptions: Subscription = new Subscription();

  constructor(
    private _manageFeasibilityService: ManageFeasibilityService,
    private _companyService: CompanyService,
    private _commonService: CommonService,
    private _toastr: ToasterService
  ) {}

  ngOnInit() {
    this.getSuppliersList();
    this.getContriesList();
    this.feasibilityList = Constants.FEASIBILITY_ESTIMATE;
  }

  getSuppliersList() {
    this._companyService.getSupplierCompanies().subscribe(
      (response: CompaniesResponse) => {
        this.suppliers = response.companies;
      },
      (error: any) => {
        this._toastr.error(error.error.msg);
      }
    );
  }

  getContriesList() {
    this._commonService.getCountries().subscribe(
      (response: Array<EachCountry>) => {
        this.countries = response;
      },
      (error: any) => {
        this._toastr.error(error.error.msg);
      }
    );
  }

  selectCountry(country: EachCountry) {
    this.countrySelected = country;
    this.selectedCountryLanguages = this.countrySelected.lang;
    if (
      this.languageSelected &&
      !this.selectedCountryLanguages.some(
        (lang) => lang.name === this.languageSelected?.name
      )
    ) {
      this.languageSelected = this.selectedCountryLanguages[0];
      this.feasibilitySelected = {} as FeasibilityEstimate;
      this.selectFeasibilitySetting();
      this.setUpFeasibility();
      return;
    }
    this.selectLanguage(this.selectedCountryLanguages[0]);
  }

  selectLanguage(languageList: EachLanguage) {
    if (languageList) {
      this.languageSelected = languageList;
      this.selectFeasibilitySetting();
      this.setUpFeasibility();
    }
  }

  selectSupplier(supplier: CompanyResponseObject) {
    this.clearForm();
    if (supplier) {
      this.supplierSelected = supplier;
      this.getFeasibilitySettings();
      this.selectFeasibilitySetting();
    }
  }

  selectFeasibility(feasibility: FeasibilityEstimate) {
    if (feasibility) {
      this.feasibilitySelected = feasibility;
    }
  }

  setUpFeasibility() {
    if (this.feasibilityObj && this.feasibilityObj.genpopMode) {
      this.feasibilitySelected = this.feasibilityList.find(
        (feas) => feas.genpopMode === this.feasibilityObj.genpopMode
      )!;
    }
  }

  clearForm() {
    this.supplierSelected = {} as CompanyResponseObject;
    this.countrySelected = {} as EachCountry;
    this.languageSelected = undefined;
    this.feasibilitySettings = [] as FeasibilitySettingMeta[];
    this.feasibilitySelected = {} as FeasibilityEstimate;
    this.feasibilityObj = {} as FeasibilitySettingMeta;
  }

  getFeasibilitySettings() {
    this._manageFeasibilityService
      .getFeasibilitySettings(this.supplierSelected.id)
      .subscribe(
        (response) => {
          if (response.data && response.data.length) {
            this.feasibilitySettings = response.data;
          }
        },
        (error) => {
          this._toastr.error(notifyMessage.errorMessage.API_ERROR);
        }
      );
  }

  selectFeasibilitySetting() {
    const settingData = this.feasibilitySettings.find(
      (data) =>
        data.surveyLocalization.feasCountry ===
          this.countrySelected.short_Code &&
        data.surveyLocalization.feasLng === this.languageSelected?.short_code
    );
    this.feasibilityObj = settingData || ({} as FeasibilitySettingMeta);
  }

  saveFeasibility() {
    this.feasibilityObj.genpopMode = this.feasibilitySelected.genpopMode;
    this.feasibilityObj.feasLng = this.languageSelected?.short_code;
    this.feasibilityObj.feasCountry = this.countrySelected.short_Code;
    this.feasibilityObj.surveyLocalization = {
      feasCountry: this.countrySelected.short_Code,
      feasLng: this.languageSelected?.short_code,
    };
    this.feasibilityObj.s_id = this.supplierSelected!.id;
    let feasObj = this.feasibilityObj;

    if (
      this.feasibilityObj.desktopMonthlyActUsr &&
      this.feasibilityObj.mobileMonthlyActUsr &&
      this.feasibilityObj.patnrDelvryMulplr &&
      this.supplierSelected &&
      this.countrySelected &&
      this.languageSelected &&
      this.feasibilitySelected
    ) {
      this._manageFeasibilityService.saveFeasibilityApi(feasObj).subscribe(
        () => {
          this.feasibilitySettings.push(feasObj);
        },
        (error: any) => {
          this._toastr.error(error.error.msg);
        }
      );
      this._toastr.success('Success! Feasibility has been saved');
    } else {
      this._toastr.error('Error!: Fields below cannot be empty');
      this._toastr.error(JSON.stringify(this.feasibilityObj));
    }
  }

  uploadFile(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    if (this._isValidFile(file)) {
      const formData = new FormData();
      formData.append('file', file);
      this._manageFeasibilityService.uploadFeasibility(formData).subscribe(
        (response) => {
          this._toastr.success(
            notifyMessage.successMessage.FEASIBILITY_UPLOADED
          );
        },
        ({ error }) => {
          this._toastr.error(
            error.message || notifyMessage.errorMessage.SERVER_ERROR
          );
        }
      );
    }
  }

  private _isValidFile(file: File) {
    if (!file) {
      this._toastr.error(notifyMessage.errorMessage.FILE_UPLOAD_MISSING);
      return false;
    }
    const extension = file.name.substring(file.name.lastIndexOf('.'));
    if (['.csv', '.xls'].indexOf(extension) < 0) {
      this._toastr.error(
        notifyMessage.errorMessage.FILE_UPLOAD_ASK_FEASIBILITY
      );
      return false;
    }
    return true;
  }

  downloadFile() {
    this._manageFeasibilityService.downloadFeasibility().subscribe(
      (file: Blob) => {
        fileSaver.saveAs(file, Constants.FEASIBILITY_UPLOAD.downloadFileName);
      },
      ({ error }) => {
        this._toastr.error(
          error.message || notifyMessage.errorMessage.FILE_DOWNLOAD_FAILED
        );
      }
    );
  }

  downloadTemplate() {
    window.open(Constants.FEASIBILITY_UPLOAD.templatePath, '_blank');
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
