import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InputRestrictionDirective } from './input-restriction.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [InputRestrictionDirective],
  exports: [InputRestrictionDirective],
})
export class InputRestrictionModule {}
