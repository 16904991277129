import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TabService {
  constructor() {}

  private _activeTab: Subject<number> = new BehaviorSubject(1);

  setActiveTab(activeTab: number) {
    this._activeTab.next(activeTab);
  }

  getActiveTab(): Observable<any> {
    return this._activeTab.asObservable();
  }
}
