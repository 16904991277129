<ps-ui-modal class="viewModal" id="decipher_modal">
  <h5 psUiModalTitle>Enable Decipher Mapping</h5>
  <ps-ui-modal-body>
    <div class="form-group">
      <div class="row row-m-t">
        <div class="col-md-4">
          <label class="pull-left">URI</label>
        </div>
        <div class="col-md-4">
          <label class="pull-left">Decipher Cloud</label>
        </div>
        <div class="col-md-4">
          <label class="pull-left">API Token</label>
        </div>
      </div>
      <form [formGroup]="uriForm">
        <div class="row row-m-t">
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              id="enable_decipher_new_uri"
              placeholder="Add new URI"
              name="enable_decipher_new_uri"
              formControlName="newUri"
            />
          </div>
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              id="enable_decipher_cloud"
              placeholder="Input Decipher Cloud"
              name="enable_decipher_cloud"
              formControlName="decipherCloud"
            />
          </div>
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              id="enable_decipher_api_token"
              placeholder="Input API Token"
              name="enable_decipher_api_token"
              formControlName="apiToken"
            />
          </div>
        </div>
      </form>

      <div class="row row-m-t">
        <div class="col-md-12">
          <button
            type="button"
            psUiButton
            color="primary"
            class="decipher-uri-button"
            [disabled]="!uriForm.valid"
            (click)="updateDecipherURI()"
          >
            Update
          </button>

          <button
            type="button"
            psUiButton
            color="primary"
            class="decipher-uri-button"
            [disabled]="!uriForm.valid"
            (click)="saveDecipherURI()"
          >
            Add
          </button>
        </div>
      </div>
      <br />
      <div class="row row-m-t">
        <div class="col-md-4">
          <label class="pull-left">URI</label>
        </div>
        <div class="col-md-4">
          <label class="pull-left">Decipher Directory</label>
        </div>
        <div class="col-md-4">
          <label class="pull-left">Enable Multi Path Surveys</label>
        </div>
      </div>
      <form [formGroup]="decipherForm">
        <div class="row row-m-t">
          <div class="col-md-4">
            <ps-ui-select-dropdown
              [dropdownItems]="decipherURIs"
              [field]="'value'"
              labelNone="Select from Existing"
              [label]="selectedDecipherURI?.value"
              (selectedItem)="selectDecipherURI($event)"
            >
            </ps-ui-select-dropdown>
          </div>
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              id="enable_decipher_dic_name"
              placeholder="Directory Name"
              name="enable_decipher_dic_name"
              formControlName="directoryName"
            />
          </div>
          <div class="col-md-4">
            <ps-ui-slider
              id="enable_decipher_multi_path"
              class="pull-left"
              (selectedChange)="setEnableDecipherMultiPath()"
              [selected]="enableDecipherMultiPath"
            ></ps-ui-slider>
          </div>
        </div>

        <br />
        <div class="row row-m-t">
          <div class="col-md-4">
            <label class="pull-left">Vendor</label>
          </div>
          <div class="col-md-4">
            <label class="pull-left">COUNTRY</label>
          </div>
          <div class="col-md-4">
            <label class="pull-left">Language</label>
          </div>
        </div>
        <div class="row row-m-t">
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              id="enable_decipher_vendor"
              placeholder="Vendor Id"
              name="enable_decipher_vendor"
              formControlName="vendor"
            />
          </div>
          <div class="col-md-4">
            <ps-ui-select-dropdown
              [dropdownItems]="countryList"
              [field]="'name'"
              [enableSearch]="true"
              labelNone="Country"
              [label]="selectedCountry?.name"
              [loading]="false"
              (selectedItem)="selectCountry($event)"
            >
            </ps-ui-select-dropdown>
          </div>
          <div class="col-md-4">
            <ps-ui-select-dropdown
              [dropdownItems]="languages"
              [field]="'name'"
              [enableSearch]="true"
              labelNone="Language"
              [label]="selectedLanguage?.name"
              [loading]="false"
              (selectedItem)="selectLanguage($event)"
            >
            </ps-ui-select-dropdown>
          </div>
        </div>
      </form>
    </div>
  </ps-ui-modal-body>
  <ps-ui-modal-footer class="padFooter">
    <button type="button" psUiButton color="secondary" (click)="close()">
      Close
    </button>
    <button
      type="button"
      [disabled]="!checkAllFilledFields()"
      psUiButton
      (click)="saveDecipherData()"
    >
      Save changes
    </button>
  </ps-ui-modal-footer>
</ps-ui-modal>
