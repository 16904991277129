import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpStatusCode,
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _router: Router, private _auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get('skipErrorInterceptor')) {
      request = request.clone({
        headers: request.headers.delete('skipErrorInterceptor'),
      });
      return next.handle(request);
    }

    return next.handle(request).pipe(
      tap({
        error: (error) => {
          if (error.status === HttpStatusCode.Unauthorized) {
            this._auth.resetAuthState();
            this._router.navigateByUrl('/login');
          }
          if (error.status === HttpStatusCode.Forbidden) {
            this._router.navigateByUrl('/access-denied');
          }
        },
      })
    );
  }
}
