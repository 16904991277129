<div class="container">
  <section>
    <div>
      <h4>Supplier Price Card</h4>
      <form [formGroup]="form">
        <div class="row survey-form">
          <div class="col-sm-4 text-center">
            <label class="mb-1">Country</label>
            <ps-ui-select-dropdown
              [dropdownItems]="countryList"
              [field]="'name'"
              [label]="form.controls.country.value"
              (selectedItem)="selectCountry($event)"
            >
            </ps-ui-select-dropdown>
          </div>
          <div class="col-sm-4 text-center">
            <label class="mb-1">Language</label>
            <ps-ui-select-dropdown
              [multi]="false"
              [dropdownItems]="languageList"
              [field]="'name'"
              [label]="form.controls.language.value"
              (selectedItem)="selectLanguage($event)"
            >
            </ps-ui-select-dropdown>
          </div>
          <div class="col-sm-4 text-center">
            <label class="mb-1">Buyer Counter Party</label>
            <ps-ui-select-dropdown
              [multi]="false"
              [dropdownItems]="buyerList"
              [field]="'name'"
              [label]="form.controls.buyer.value"
              (selectedItem)="selectBuyerCounterParty($event)"
            >
            </ps-ui-select-dropdown>
          </div>
        </div>
      </form>

      <div
        class="col-xs-12 col-sm-8 col-md-8 col-md-offset-1 survey supplier-pricing"
      >
        <div class="panel panel-default">
          <div class="panel-heading"><label>Pricing</label></div>
          <div class="pricingcard-detail-tabs">
            <ul class="list-inline" role="tablist">
              <li class="inactive">
                <a>PRICING METHOD</a>
              </li>
              <li
                [ngClass]="{
                  current_method: currentTab === 'formula',
                  active: isSet === 1
                }"
              >
                <a role="button" (click)="activeTab('formula')">FORMULA</a>
              </li>
              <li
                [ngClass]="{
                  current_method: currentTab === 'manual_price_card',
                  active: isSet === 2
                }"
              >
                <a role="button" (click)="activeTab('manual_price_card')"
                  >MANUAL RATE CARD</a
                >
              </li>
            </ul>
          </div>
          <ps-formula
            *ngIf="isSet === 1"
            [languageId]="languageId"
            [buyerId]="buyerId"
            [countryId]="countryId"
            [supplierPriceCard]="supplierPriceCard"
            [deactivateFormulaPriceCard]="manualRateCardUploaded"
            (updateSupplierPriceCard)="cancelManualRateCard()"
          ></ps-formula>
          <ps-manual-rate-card
            *ngIf="isSet === 2"
            [languageId]="languageId"
            [buyerId]="buyerId"
            [countryId]="countryId"
            [manualRateCardUploaded]="manualRateCardUploaded"
            (cancelManualRateCard)="cancelManualRateCard()"
          ></ps-manual-rate-card>
        </div>
      </div>
    </div>
  </section>
</div>
