import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants } from './invalid-password-link-constants';

@Component({
  selector: 'ps-invalid-password-link',
  templateUrl: './invalid-password-link.component.html',
  styleUrls: ['./invalid-password-link.component.css'],
})
export class InvalidPasswordLinkComponent implements OnInit {
  reason: string = '';
  profileLocked: boolean = false;
  linkInvalid: boolean = false;
  linkExpired: boolean = false;
  redirectUrls = Constants.INVALID_LINK_REDIRECT_URLS;

  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      this.reason = params['reason'];
    });
    this.checkForinvalidLinkReason();
  }

  checkForinvalidLinkReason() {
    switch (this.reason) {
      case this.redirectUrls.LINK_INVALID: {
        this.linkInvalid = true;
        break;
      }
      case this.redirectUrls.ACCOUNT_LOCKED: {
        this.profileLocked = true;
        break;
      }
      case this.redirectUrls.LINK_EXPIRED: {
        this.linkExpired = true;
        break;
      }
    }
  }
}
