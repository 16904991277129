<div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-6" *psHasRole="'operator'">
        <div class="form-group">
          <label class="control-label label-invoice-operation"
            >Buyer (required field)</label
          >
          <div class="form-control new-drp-changer">
            <ps-ui-select-dropdown
              class="new-com-vs"
              [dropdownItems]="buyers"
              [field]="'name'"
              [label]="selectedCompany?.name || ''"
              (selectedItem)="setSelectedCompany($event)"
            ></ps-ui-select-dropdown>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="poNumber" class="control-label label-invoice-operation"
            >Billing/PO Number</label
          >
          <input
            class="form-control input-invoice-operation"
            type="text"
            placeholder="Billing/PO Number"
            id="poNumber"
            [(ngModel)]="filterFields.poNumber"
            (keyup)="searchProjects()"
          />
        </div>
      </div>
      <div class="col-md-6" *psHasRole="'buyer'">
        <ng-template [ngTemplateOutlet]="surveyStatusFilter"></ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="dateRange" class="control-label label-invoice-operation"
            >Survey Live Date Range</label
          >
          <input
            class="form-control input-invoice-operation"
            type="text"
            name="daterangeInput"
            daterangepicker
            [(ngModel)]="filterFields.dateRange"
            placeholder="Survey Live Date Range"
            [options]="options"
            (selected)="selectedDate($event, daterange)"
            (cancelDaterangepicker)="calendarCanceled()"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="surveyName" class="control-label label-invoice-operation"
            >Survey Name</label
          >
          <input
            class="form-control input-invoice-operation"
            type="text"
            placeholder="Survey Name"
            id="surveyName"
            [(ngModel)]="filterFields.surveyName"
            (keyup)="searchProjects()"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3" *psHasRole="'operator'">
    <div class="col-md-12">
      <ng-template [ngTemplateOutlet]="surveyStatusFilter"></ng-template>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <div class="form-group">
        <label class="label-invoice-operation"
          >Survey Number
          <div class="setting-info survey-number-tooltip ps-sky-info">
            <i class="fa fa-info-circle"></i>
            <p>
              <span>
                Input all surveys separated by a ‘, (comma)' OR “one survey
                number per line”
              </span>
            </p>
          </div>
        </label>
        <textarea
          class="form-control restrict-vertical"
          name="surveyNumber"
          id="surveyNumber"
          [(ngModel)]="filterFields.surveyId"
          rows="3"
          (keyup)="searchProjects()"
        ></textarea>
      </div>
    </div>
  </div>
</div>

<div class="row my-2">
  <div class="col-xs-12 col-sm-12 col-md-8">
    <button
      psUiButton
      type="button"
      class="button-report-invoice"
      id="invoiceSurveybtn"
      [disabled]="buttonDisabled"
      (click)="sendToInvoice()"
    >
      Invoice Selected Surveys
    </button>
    <button
      psUiButton
      type="button"
      class="button-report-invoice"
      id="invoiceReportbtn"
      [disabled]="buttonDisabled"
      (click)="downloadReports()"
    >
      Download Report
    </button>
    <button
      psUiButton
      type="button"
      class="button-report-invoice"
      id="invoiceReportbtn"
      [disabled]="buttonDisabled"
      (click)="requestSupplierSummaryReport()"
    >
      Supplier Summary Report Request
    </button>
  </div>
</div>
<div
  infiniteScroll
  [infiniteScrollDistance]="3"
  [infiniteScrollThrottle]="20"
  class="tableMakeover"
  (scrolled)="onScroll()"
>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <th>
          <input
            type="checkbox"
            [(ngModel)]="checkedAll"
            (change)="allChecked()"
          />
        </th>
        <th>Status</th>
        <th>Invoiced</th>
        <th>Survey #</th>
        <th>Billing/PO #</th>
        <th>Survey Title</th>
        <th>Buyer Account</th>
        <th>Fielded vs Goal</th>
        <th>in Progress</th>
        <th>AVG CPI</th>
        <th>Current IR</th>
        <th>Country</th>
      </thead>
      <tbody *ngIf="surveys.length; else noSurveys">
        <tr *ngFor="let survey of surveys; let i = index">
          <td>
            <input
              type="checkbox"
              [(ngModel)]="survey.selected"
              (change)="singleSelected(i, survey.ps_survey_id)"
            />
          </td>
          <td>
            <div class="dropdown survey-status">
              <div class="serveryStatus">
                <button
                  class="menu-btn"
                  [psUiDropdownToggleFor]="surveysStatus"
                  [disabled]="survey.ps_survey_status === SURVEY_STATUS.CLOSED"
                >
                  {{ surveyStatus[survey.ps_survey_status] }}
                </button>
                <ps-ui-dropdown
                  class="status-dropdown"
                  #surveysStatus="psUiDropdown"
                >
                  <div *ngFor="let statusBttn of statusButtons">
                    <button
                      psUiDropdownItem
                      *ngIf="
                        statusBttn.condition.indexOf(survey.ps_survey_status) >
                        -1
                      "
                      (click)="openStatusModal(survey, statusBttn.value)"
                    >
                      {{ statusBttn.value }}
                    </button>
                  </div>
                </ps-ui-dropdown>
              </div>
            </div>
          </td>
          <td
            (click)="openSurveyDetails(survey.ps_survey_id)"
            class="survey-title"
            title="{{ survey.ps_survey_status }}"
          >
            {{ survey.ps_survey_status === 55 ? 'Yes' : 'No' }}
          </td>

          <td
            (click)="openSurveyDetails(survey.ps_survey_id)"
            class="survey-title"
            style="cursor: pointer"
            title="{{ survey.ps_survey_id }}"
          >
            {{ survey.ps_survey_id }}
          </td>

          <td
            (click)="openSurveyDetails(survey.ps_survey_id)"
            style="cursor: pointer"
          >
            {{ survey.billing_id }}
          </td>
          <td
            (click)="openSurveyDetails(survey.ps_survey_id)"
            class="survey-title"
          >
            {{ survey.survey_title }}
          </td>
          <td
            (click)="openSurveyDetails(survey.ps_survey_id)"
            style="cursor: pointer"
          >
            {{ survey.company_name }}
          </td>
          <td
            (click)="openSurveyDetails(survey.ps_survey_id)"
            style="cursor: pointer"
          >
            {{ survey.fielded || 0 }} | {{ survey.completes_required || 0 }}
          </td>
          <td
            (click)="openSurveyDetails(survey.ps_survey_id)"
            style="cursor: pointer"
          >
            {{ (survey.bip || 0) < 0 ? 0 : survey.bip }}
          </td>
          <td (click)="openSurveyDetails(survey.ps_survey_id)">
            {{
              survey.average_cpi || survey.average_cpi === 0
                ? (survey.average_cpi | currency : survey.currency_symbol)
                : 'NA'
            }}
          </td>
          <td
            (click)="openSurveyDetails(survey.ps_survey_id)"
            style="cursor: pointer"
          >
            {{
              survey.ps_survey_status === 11
                ? 'NA'
                : survey.current_incidence || 0
            }}
            <span ng-if="survey.status !== 11">%</span>
          </td>
          <td
            *ngIf="
              !(
                survey?.mc?.SVP &&
                survey?.mc?.parent &&
                survey?.mc?.surveys?.length
              )
            "
            (click)="openSurveyDetails(survey.ps_survey_id)"
          >
            {{ survey.survey_localization.split('_')[1] }}
          </td>
          <td
            *ngIf="
              survey?.mc?.SVP &&
              survey?.mc?.parent &&
              survey?.mc?.surveys?.length
            "
            (click)="openSurveyDetails(survey.ps_survey_id)"
          >
            <ps-multi-country-info-listing
              [countries]="countries"
              [mcSvpChildSurveys]="survey.mc?.surveys || []"
            >
              <ng-template psUiTableCellTemplate>
                <div
                  class="multiple-label-container d-flex justify-content-center"
                >
                  <div class="mcqb-survey-count pb-0 rounded-2">
                    {{ survey.mc?.surveys?.length }}
                  </div>
                  <div class="multiple-label">Multiple</div>
                </div>
              </ng-template>
              <ng-template
                psMultiCountryColumnContent
                classList="px-0 py-2 country-name-column"
                let-survey
              >
                <div class="mx-1">
                  <span> {{ survey.countryName }} </span>
                </div>
              </ng-template>

              <ng-template
                psMultiCountryColumnContent
                classList="px-0 py-2"
                let-survey
              >
                <div class="mx-1">
                  <span class="p-1 fw-bold language-icon">
                    {{ survey.languageCode | uppercase }}
                  </span>
                </div>
              </ng-template>
            </ps-multi-country-info-listing>
          </td>
        </tr>
      </tbody>
      <ng-template #noSurveys>
        <tbody class="border-bottom">
          <tr>
            <td colspan="5">
              <p>No Surveys Available Based On Your Search Criteria</p>
            </td>
          </tr>
        </tbody>
      </ng-template>
    </table>
    <p>
      Showing {{ surveys.length ? 1 : 0 }} to {{ surveys.length }}
      <!-- of {{ surveyCount }} entries -->
    </p>
  </div>
</div>

<ng-template #surveyStatusFilter>
  <div class="form-group">
    <label class="control-label label-invoice-operation">Survey Status</label>
    <div class="form-control new-drp-changer">
      <ps-ui-select-dropdown
        class="new-com-vs"
        [multi]="true"
        labelAlignment="start"
        labelNone="Select Status"
        [dropdownItems]="surveyStatusOptions"
        [field]="'name'"
        (selectedItems)="setSelectedStatuses($event)"
      ></ps-ui-select-dropdown>
    </div>
  </div>
</ng-template>
