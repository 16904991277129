<div class="row text-center dash-report">
  <p class="text-center report-title-spacing">PSID Report</p>
  <ul class="list-unstyled">
    <li class="d-inline" *ngFor="let item of subReportList">
      <input
        type="radio"
        [(ngModel)]="radioSelected"
        name="list_name"
        value="{{ item.value }}"
        (change)="onItemChange(item)"
      />
      {{ item.name }}
    </li>
  </ul>
</div>

<div class="row psid-report">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div *ngIf="radioSel.value === 'survey_history'">
      <ps-survey-history></ps-survey-history>
    </div>
    <div *ngIf="radioSel.value === 'profile_data'">
      <ps-profile-data></ps-profile-data>
    </div>
    <div *ngIf="radioSel.value === 'supplier_history'">
      <ps-supplier-history></ps-supplier-history>
    </div>
  </div>
</div>
