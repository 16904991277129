import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponseObject } from 'src/app/shared/interfaces/company.interface';
import {
  FeasibilitySettingResponse,
  FeasibilitySettingMeta,
  UploadFeasibilityResponse,
} from './manage-feasibility.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManageFeasibilityService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  saveFeasibilityApi(feasibilityData: FeasibilitySettingMeta) {
    return this._http.post<ApiResponseObject>(
      `${this._baseUrl}/feasibilitySetting/`,
      feasibilityData
    );
  }

  getFeasibilitySettings(supplierId: number) {
    return this._http.get<FeasibilitySettingResponse>(
      `${this._baseUrl}/getFeasibilitySetting/${supplierId}`
    );
  }

  uploadFeasibility(formData: FormData): Observable<UploadFeasibilityResponse> {
    return this._http.post<UploadFeasibilityResponse>(
      `${this._baseUrl}/feasibility-setting/upload`,
      formData
    );
  }

  downloadFeasibility(): Observable<Blob> {
    return this._http.get<Blob>(
      `${this._baseUrl}/feasibility-setting/download`,
      {
        responseType: 'blob' as 'json',
      }
    );
  }
}
