'use strict';

import { environment } from '../../environments/environment';

export const Constants = Object.freeze({
  USER_STATUS: {
    ACTIVE: 'Active',
    PAUSED: 'Paused',
    DEACTIVE: 'Deactive',
    INACTIVE: 'Inactive',
  },
  ACCESS_LEVELS: [
    {
      value: 'limited',
      label: 'Limited',
    },
    {
      value: 'full',
      label: 'Full',
    },
    {
      value: 'admin',
      label: 'Admin',
    },
  ],
  SERVICE_OPERATOR_ACCESS_LEVEL: {
    value: 'serviceOperator',
    label: 'Service Operator',
  },
  BUYER_SUPER_ADMIN_ACCESS_LEVEL: {
    value: 'superAdmin',
    label: 'Super Admin',
  },
  BUYER_LIMITED_DRAFT_ACCESS_LEVEL: {
    value: 'limitedDraft',
    label: 'Limited Draft',
  },
  PS_CMP_ID: environment.PS_CMP_ID,
  CHURNZERO_TIMEZONE: 5,
  PAGES_THAT_NEED_BUYER_TOKEN: [
    '/dashboard',
    '/create-surveys',
    '/launch-survey',
    '/edit',
    '/suppliers',
    '/recontact-survey',
  ],
  ROUTES_THAT_NEED_BUYER_TOKEN: ['/users/buyer-service-account/token/'],
});
