<form class="form-inline" [formGroup]="form">
  <div class="input-group">
    <div class="form-group">
      <input
        class="form-control"
        type="text"
        formControlName="psid"
        placeholder="Enter PSID"
        (keyup)="diableInputBox()"
      />
    </div>
    <div class="form-group">
      <input
        class="form-control"
        type="text"
        formControlName="transaction_id"
        placeholder="Enter transaction_id"
        (keyup)="diableInputBox()"
      />
    </div>
  </div>
  <div class="form-group">
    <button type="button" (click)="getPSIDTransactionReport()">Find</button>
  </div>
</form>

<div class="table-responsive table-absolute mt-5" *ngIf="psidReportData.length">
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="supNm"
        >
          Supplier Name
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="supResId"
        >
          Respondent Id
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="last_start_date"
        >
          Last Survey Start
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="valid_click"
        >
          Total Valid Clicks
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="completes"
        >
          Total Completes
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="cpi_total"
        >
          Total CPI
        </th>
        <th
          scope="col"
          [psSortTable]="psidReportData"
          data-order=""
          data-name="deviceId"
        >
          Device ID
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of psidReportData">
        <td class="text-left">{{ item.supNm }}</td>
        <td class="text-left">{{ item.supResId || 'NA' }}</td>
        <td class="text-left">{{ item.last_start_date }}</td>
        <td class="text-left">{{ item.valid_click || 'NA' }}</td>
        <td class="text-left">{{ item.completes || 'NA' }}</td>
        <td class="text-left">
          {{ item.cpi_total | number : '1.2-2' || 'NA' }}
        </td>
        <td class="text-left">{{ item.deviceId || 'NA' }}</td>
      </tr>
    </tbody>
  </table>
</div>
