import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import { CurrencyService } from './currency.service';
import {
  Currency,
  GetAllCurrenciesResponse,
  UpdateCurrencyDetails,
} from './currency-settings.interface';

@Component({
  selector: 'ps-currency-settings',
  templateUrl: './currency-settings.component.html',
  styleUrls: ['./currency-settings.component.css'],
})
export class CurrencySettingsComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  form!: FormGroup;
  currencies: Currency[] = [];

  constructor(
    private _currencyService: CurrencyService,
    private _toastr: ToasterService,
    private _fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this._generateForm();
    this._getCurrencies();
  }

  private _generateForm() {
    this.form = this._fb.group({
      fx: [null, [Validators.required]],
      CRRate: [null, [Validators.required]],
      symbol: [null, [Validators.required]],
      currencyShortCode: ['', [Validators.required]],
      name: ['', [Validators.required]],
      rate: [null],
    });
  }

  private _getCurrencies() {
    const getCurrency = this._currencyService.getAllCurrencies().subscribe(
      (currencyResponse: GetAllCurrenciesResponse) => {
        if (currencyResponse.currencies) {
          this.currencies = currencyResponse.currencies.map((currency) => {
            currency.name = `${currency.currencyFullName} (${currency.currencyShortCode})`;
            return currency;
          });
        }
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add(getCurrency);
  }

  selectCurrency(currency: Currency) {
    this.form.patchValue(currency);
  }

  saveCurrency() {
    if (this._validateCurrency()) {
      return;
    }
    const updateCurrency = this._currencyService
      .updateCurrencyDetails(this.form.value)
      .subscribe(
        (response: UpdateCurrencyDetails) => {
          this._toastr.success(response.msg);
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add(updateCurrency);
  }

  private _validateCurrency() {
    if (this.form.controls.currencyShortCode.invalid) {
      this._toastr.error('Currency is not valid');
      return true;
    }
    if (this.form.controls.fx.invalid) {
      this._toastr.error('Currency Code (fx) is not valid');
      return true;
    }
    if (this.form.controls.CRRate.invalid) {
      this._toastr.error('CRRate is not valid');
      return true;
    }
    if (this.form.controls.symbol.invalid) {
      this._toastr.error('Symbol is not valid');
      return true;
    }
    if (this.form.invalid) {
      this._toastr.error('Please enter valid details');
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
