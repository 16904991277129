import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StcReportService } from './stc-report.service';
import { StcReportReponse, StcReportResult } from './stc-report.interface';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
@Component({
  selector: 'ps-stc-report',
  templateUrl: './stc-report.component.html',
  styleUrls: ['./stc-report.component.css'],
})
export class StcReportComponent implements OnInit {
  form!: FormGroup;
  constructor(
    private _stcReportService: StcReportService,
    private _fb: FormBuilder,
    private _toastr: ToasterService
  ) {}

  stcReportData: StcReportResult[] = [];
  isTableVisible = false;
  ngOnInit(): void {
    this._generateForm();
  }

  private _generateForm() {
    this.form = this._fb.group({
      transaction_id: [null],
      session_id: [null],
    });
  }

  onChange(key: string) {
    if (key === 'session_id') {
      if (this.form.get('session_id')?.value) {
        this.form.get('transaction_id')?.disable();
      } else {
        this.form.get('transaction_id')?.enable();
      }
    } else if (key === 'transaction_id') {
      if (this.form.get('transaction_id')?.value) {
        this.form.get('session_id')?.disable();
      } else {
        this.form.get('session_id')?.enable();
      }
    }
  }

  findTrafficReport() {
    this._stcReportService.getStcReport(this.form.value).subscribe(
      (response: StcReportReponse) => {
        this.stcReportData = response.result;
        this.isTableVisible = true;
        this._toastr.success(response.apiStatus);
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
  }
}
