import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigEnabledDirective } from './config-enabled.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ConfigEnabledDirective],
  exports: [ConfigEnabledDirective],
})
export class ConfigEnabledModule {}
