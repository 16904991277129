import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Constants } from './manage-suppliers-constants';
import {
  GroupTemplateSupplier,
  ManageSuppliersService,
  SupplierGroupTemplateResponseInterface,
  Supplier,
  SupplierList,
  SupplierBlend,
  GroupSupplier,
  InputGroupSupplier,
  FusionSupplier,
} from '@purespectrum1/ui';
import { AuthService } from '../../auth/auth.service';
import { ModalService } from '@purespectrum1/ui/modal';
import { ActivatedRoute } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { validateSupplierGroupName } from '../../utils/regex';
import { SupplierGroupModalComponent } from './supplier-group-modal/supplier-group-modal.component';
import { deepCopy } from '../../utils/deep-copy';
import { AllocationClass } from './suppliers-manage-class/allocation.class';
import { FlexibilityClass } from './suppliers-manage-class/flexibility.class';
import { FLEXIBILITY_OPTIONS } from './suppliers-manage-class/flexibility-constants';
import {
  CountryInterface,
  LanguageInterface,
} from '../../shared/interfaces/create-surveys.interface';
import { CommonService } from '../../shared/services/common-service/common-service';
@Component({
  selector: 'ps-manage-suppliers',
  templateUrl: './manage-suppliers.component.html',
  styleUrls: ['./manage-suppliers.component.css'],
})
export class ManageSuppliersComponent implements OnInit, OnDestroy {
  tabs: Tab[] = Constants.MANAGE_SUPPLIER_TABS;
  activeTab: Tab = this.tabs[0];
  initialTab: number = 0;
  private _userCmp!: number;
  suppliers: Supplier[] = [];
  fusionSuppliers: FusionSupplier[] = [];
  showGroupingButton: boolean = false;
  blockAllFlag: boolean = false;
  pureScoreStFlag: boolean = false;
  researchDefStFlag: boolean = false;
  showFusionSuppliers: boolean = false;
  fusionCmpId: number = Constants.FUSION_CMP_ID;
  researchDefDupeStFlag: boolean = false;
  selectedSupplierBlend = <SupplierBlend>{};
  enableGroupingButton: boolean = false;
  private _selectedSupplierGroup: GroupSupplier[] = [];
  supplierGroup = <InputGroupSupplier>{};
  supplierBlends: SupplierBlend[] = [];
  private _routeFragmentSubscription?: Subscription;
  supplierGroupTemplates: SupplierGroupTemplateResponseInterface[] = [];
  defaultTemplateOptionId: string = '0';
  _selectedSupplierGroupTemplate = <GroupSupplier>{};
  public UPDATE_SUPPLIER_TRIGGER_SOURCE =
    Constants.UPDATE_SUPPLIER_TRIGGER_SOURCE;
  public readonly flexibilityStatus = [
    {
      key: 0,
      value: 'Global',
    },
    {
      key: 1,
      value: 'Selective',
    },
    {
      key: 2,
      value: 'None',
    },
  ];
  flexibilitySelectedType = {
    key: 0,
    value: 'Global',
  };
  private _defaultFlexibility: number = 100;
  public countries: CountryInterface[] = [];
  public languages: LanguageInterface[] = [];
  public selectedCountry?: CountryInterface;
  public selectedLanguage?: LanguageInterface;
  public selectLockAllBlends: boolean = false;
  public isProd: boolean = false;

  constructor(
    private _manageSupplierService: ManageSuppliersService,
    private _authService: AuthService,
    private _toastr: ToasterService,
    private _modal: ModalService,
    private _route: ActivatedRoute,
    private _allocationClass: AllocationClass,
    private _flexibililtyClass: FlexibilityClass,
    private _commonService: CommonService
  ) {}

  ngOnInit() {
    this.getSupplierTabSettings();
    this._userCmp = this._authService.user!.cmp;
    this.getSuppliers();
    this.getSupplierBlends();
    this.getFusionSuppliers();
    this.getSupplierGroupTemplates();
    this._getCountries();
  }

  navigateToBlends() {
    this.showGroupingButton = false;
    this.selectedSupplierBlend.is_active = false;
    this.selectedCountry = undefined;
    this.selectedLanguage = undefined;
    this.getSuppliers();
  }

  checkActiveTab(activeIndex: number) {
    this.activeTab = this.tabs[activeIndex];
    this.selectedLanguage = undefined;
    this.selectedCountry = undefined;
    this.getSuppliers();
  }

  selectCountry(country: CountryInterface) {
    this.selectedLanguage = undefined;
    this.selectedCountry = country;
    this.languages = country.lang;
    this.selectLanguage(this.languages[0]);
  }

  selectLanguage(language: LanguageInterface) {
    this.selectedLanguage = language;
    this.getSuppliers();
  }

  getSupplierTabSettings() {
    const showSupplierBlends =
      this._authService.user?.buyerAcssLvls === 'superAdmin' &&
      this._authService.buyerConfig?.enableSupplierGrouping;
    if (!showSupplierBlends) {
      this.tabs.splice(1, 1);
    }
    this._routeFragmentSubscription = this._route.fragment.subscribe(
      (fragment) => {
        this.setInitialTab(fragment, showSupplierBlends);
      }
    );
  }

  setInitialTab(
    fragment: string | null,
    showSupplierBlends: boolean | undefined
  ) {
    if (fragment === 'supplier-blend' && showSupplierBlends) {
      this.initialTab = 1;
      this.checkActiveTab(1);
    }
  }

  private _hasValidAllocationForAllSupplierBlends() {
    let isAllocationValid = true;
    this.supplierBlends.forEach((blend) => {
      this.openSupplierBlend(blend, false);
      if (
        !this._hasValidAllocationForSingleSupplierBlend(
          this.selectedSupplierBlend
        )
      ) {
        isAllocationValid = false;
      }
      this.showGroupingButton = false;
      this.selectedSupplierBlend = <SupplierBlend>{};
    });
    return isAllocationValid;
  }

  isNumeric(value: any) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  private _hasValidAllocationForSingleSupplierBlend(blend: SupplierBlend) {
    let totalAllocation = 0;
    let hasBlankAllocation = false;
    blend.suppliers.forEach((supp) => {
      if (
        !supp.hasOwnProperty('allocation_percentage') ||
        !this.isNumeric(supp.allocation_percentage)
      ) {
        hasBlankAllocation = true;
      }
      totalAllocation += Number(supp.allocation_percentage);
    });
    return !hasBlankAllocation && totalAllocation >= 100;
  }

  lockAllBlends() {
    if (
      !this._hasValidAllocationForAllSupplierBlends() &&
      !this.selectLockAllBlends
    ) {
      return this._toastr.error(
        `Supplier Blends ${Constants.INVALID_ALLOCATION_FOR_LOCKING_BLEND}`
      );
    }
    const blendIds = this.supplierBlends.map((blend) => blend.id);
    this._manageSupplierService
      .lockBlends(blendIds, !this.selectLockAllBlends)
      .subscribe(
        (_resp) => {
          this.selectLockAllBlends = !this.selectLockAllBlends;
          this.getSupplierBlends();
          if (this.selectLockAllBlends) {
            return this._toastr.warning(Constants.LOCK_ALL_BLENDS_SUCCESS);
          } else {
            return this._toastr.success(Constants.UNLOCK_ALL_BLENDS_SUCCESS);
          }
        },
        (error) => {
          return this._toastr.error(error.error.msg);
        }
      );
  }

  lockSingleBlend(isLocked: boolean) {
    if (!this.selectedSupplierBlend.config_name) {
      return this._toastr.warning('Supplier blend config name is required');
    }
    if (
      !this._hasValidAllocationForSingleSupplierBlend(
        this.selectedSupplierBlend
      ) &&
      !this.selectedSupplierBlend.is_locked
    ) {
      return this._toastr.error(
        `Supplier Blend ${Constants.INVALID_ALLOCATION_FOR_LOCKING_BLEND}`
      );
    }
    if (this.selectedSupplierBlend.id) {
      this.saveSupplierBlends(
        this.UPDATE_SUPPLIER_TRIGGER_SOURCE.LOCK_BLEND,
        isLocked
      );
    } else {
      this._updateIsLockedFlag(isLocked);
    }
  }

  private _updateIsLockedFlag(isLocked: boolean = false) {
    this.selectedSupplierBlend.is_locked = isLocked;
    if (this.selectedSupplierBlend.is_locked) {
      return this._toastr.warning(Constants.LOCK_SINGLE_BLEND_SUCCESS);
    } else {
      return this._toastr.success(Constants.UNLOCK_SINGLE_BLEND_SUCCESS);
    }
  }

  public canChangeLockedToggle = this._canChangeLockedToggle.bind(this);

  private _canChangeLockedToggle() {
    let canChange = true;
    if (!this.selectedSupplierBlend.config_name) {
      return false;
    } else if (
      this.selectedSupplierBlend.config_name &&
      this.selectedSupplierBlend.is_active
    ) {
      canChange = this._hasValidAllocationForSingleSupplierBlend(
        this.selectedSupplierBlend
      );
      return this.selectedSupplierBlend.is_locked ? true : canChange;
    } else {
      canChange = this._hasValidAllocationForAllSupplierBlends();
      return this.selectLockAllBlends ? true : canChange;
    }
  }

  getSuppliers() {
    this._manageSupplierService
      .getSuppliers(
        this._userCmp,
        this.selectedCountry?.id,
        this.selectedLanguage?.id
      )
      .subscribe(
        (resp) => {
          this.suppliers = resp.supplierList || [];
          this.blockAllFlag = this.suppliers.every(
            (supplier) => supplier.supplrSt === 'B'
          );
          this.pureScoreStFlag = this.suppliers.every(
            (supplier) => supplier.pureScoreSt === false
          );
          this.researchDefStFlag = this.suppliers.every(
            (supplier) => supplier.researchDefSt === false
          );
          this.researchDefDupeStFlag = this.suppliers.every(
            (supplier) => supplier.researchDefDupeSt === false
          );
          if (this.selectedSupplierBlend.suppliers) {
            this._remapSelectedSupplierBlendSuppliersBasedOnLocale();
          }
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
  }

  getSupplierGroupTemplates() {
    this._manageSupplierService.getAllGroupTemplates().subscribe(
      (resp) => {
        this.supplierGroupTemplates = resp;
        this.supplierGroupTemplates.unshift({
          groupName: '-- Choose Supplier Group --',
          allocation: 0,
          is_custom_allocation: false,
          id: this.defaultTemplateOptionId,
          suppliers: [],
        });
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
  }

  selectGroupTemplate(template: SupplierGroupTemplateResponseInterface) {
    let selectedTemplate: GroupSupplier = {
      group_name: template.groupName,
      supplier_id: template.suppliers,
      is_custom_allocation: template.is_custom_allocation || false,
      allocation_percentage: template.allocation,
      setActive: false,
    };
    if (template.id === this.defaultTemplateOptionId) {
      this._selectedSupplierGroup = [];
      this.supplierGroup = <InputGroupSupplier>{};
      this.ungroupSupplierGroup(this._selectedSupplierGroupTemplate);
      this._selectedSupplierGroupTemplate = selectedTemplate;
      return;
    }
    this._selectedSupplierGroup = [selectedTemplate];
    this.supplierGroup.groupName = selectedTemplate.group_name;
    this.supplierGroup.is_custom_allocation =
      selectedTemplate.is_custom_allocation || false;
    this.supplierGroup.allocation_percentage =
      selectedTemplate.allocation_percentage as number;
    const groupPayload: GroupSupplier[] = this.createGroupObj(selectedTemplate);
    this.selectedSupplierBlend.suppliers = [
      ...this._selectedSupplierGroup,
      ...groupPayload,
    ];
    this.supplierGroup = <InputGroupSupplier>{};
    this._selectedSupplierGroup = [];
    this._selectedSupplierGroupTemplate = selectedTemplate;
  }

  createGroupObj(selectedSupplierGroup: GroupSupplier) {
    let supplierGroups: GroupSupplier[] = [];
    let suppliersWithoutGroup: GroupSupplier[] = [];
    this.selectedSupplierBlend.suppliers.forEach((supplier) => {
      let suppliersData = this.filterSuppliersForBlend(
        supplier,
        selectedSupplierGroup
      );
      supplierGroups = [...supplierGroups, ...suppliersData.supplierGroups];
      suppliersWithoutGroup = [
        ...suppliersWithoutGroup,
        ...suppliersData.suppliersWithoutGroup,
      ];
    });
    return [...supplierGroups, ...suppliersWithoutGroup];
  }

  filterSuppliersForBlend(
    supplier: GroupSupplier,
    selectedSupplierGroup: GroupSupplier
  ) {
    const supplierGroups: GroupSupplier[] = [];
    const suppliersWithoutGroup: GroupSupplier[] = [];
    const suppIdsInSelectedGroup = selectedSupplierGroup.supplier_id.map(
      (selectedSup) => selectedSup.id
    );
    if (supplier.supplier_id.length > 1) {
      const suppIdsInCurrentGroup = supplier.supplier_id.map(
        (selectedSup) => selectedSup.id
      );
      const commonSupp = suppIdsInSelectedGroup.some((item) =>
        suppIdsInCurrentGroup.includes(item)
      );
      if (commonSupp) {
        supplier.supplier_id.forEach((sup) => {
          if (!suppIdsInSelectedGroup.includes(sup.id)) {
            suppliersWithoutGroup.push({
              group_name: '',
              allocation_percentage: '',
              supplier_id: [sup],
              setActive: false,
              is_custom_allocation: false,
            });
          }
        });
      } else {
        if (this.supplierGroup.is_custom_allocation) {
          this._mapCustomAllocation(supplier);
        }
        supplier.is_custom_allocation = this.supplierGroup.is_custom_allocation;
        supplierGroups.push(supplier);
      }
    } else {
      if (!suppIdsInSelectedGroup.includes(supplier.supplier_id[0].id)) {
        suppliersWithoutGroup.push({
          group_name: '',
          allocation_percentage: '',
          supplier_id: supplier.supplier_id,
          setActive: false,
          is_custom_allocation: false,
        });
      }
    }
    return { supplierGroups, suppliersWithoutGroup };
  }

  private _mapCustomAllocation(supplier: GroupSupplier) {
    supplier.supplier_id.forEach((supplierIds) => {
      this._selectedSupplierGroup.forEach(({ supplier_id }) => {
        supplier_id.forEach(({ id, allocation }) => {
          if (supplierIds.id === id) {
            supplierIds.allocation = allocation;
          }
        });
      });
    });
  }

  getFusionSuppliers() {
    this._manageSupplierService.getFusionSuppliers(this._userCmp).subscribe(
      (resp) => {
        this.fusionSuppliers = resp;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
  }

  blockSupplier(event: Event, index: number) {
    const e = event.target as HTMLInputElement;
    if (e.checked) {
      this.suppliers[index].supplrSt = 'B';
    } else {
      this.suppliers[index].supplrSt = '';
    }
    this.blockAllFlag = this.suppliers.every(
      (supplier) => supplier.supplrSt === 'B'
    );
  }

  blockFusionSupplier(index: number) {
    this.fusionSuppliers[index].blockedInPlatform =
      !this.fusionSuppliers[index].blockedInPlatform;
    this.blockAllFlag = this.fusionSuppliers.every(
      (supplier) => supplier.blockedInPlatform === true
    );
  }

  selectAllBlock(event: Event) {
    const e = event.target as HTMLInputElement;
    if (e.checked) {
      this.suppliers.forEach((supplier: { supplrSt: string; id: number }) => {
        if (supplier.id !== this.fusionCmpId) {
          supplier.supplrSt = 'B';
        }
      });
      this.fusionSuppliers.forEach(
        (fusionSupplier: { blockedInPlatform: boolean }) => {
          fusionSupplier.blockedInPlatform = true;
        }
      );
      this.showFusionSuppliers = true;
    } else {
      this.suppliers.forEach((supplier: { supplrSt: string }) => {
        supplier.supplrSt = '';
      });
      this.fusionSuppliers.forEach(
        (fusionSupplier: { blockedInPlatform: boolean }) => {
          fusionSupplier.blockedInPlatform = false;
        }
      );
      this.showFusionSuppliers = true;
    }
  }

  setPureScore(event: Event, index: number) {
    const e = event.target as HTMLInputElement;
    if (e.checked) {
      this.suppliers[index].pureScoreSt = false;
    } else {
      this.suppliers[index].pureScoreSt = true;
    }
    this.pureScoreStFlag = this.suppliers.every(
      (supplier) => supplier.pureScoreSt === false
    );
  }

  selectAllPurescore(event: Event) {
    const e = event.target as HTMLInputElement;
    if (e.checked) {
      this.suppliers.forEach((supplier: { pureScoreSt: boolean }) => {
        supplier.pureScoreSt = false;
      });
    } else {
      this.suppliers.forEach((supplier: { pureScoreSt: boolean }) => {
        supplier.pureScoreSt = true;
      });
    }
  }

  setResearchDefender(event: Event, index: number) {
    const e = event.target as HTMLInputElement;
    if (e.checked) {
      this.suppliers[index].researchDefSt = false;
    } else {
      this.suppliers[index].researchDefSt = true;
    }
    this.researchDefStFlag = this.suppliers.every(
      (supplier) => supplier.researchDefSt === false
    );
  }

  selectAllResearchDefender(event: Event) {
    const e = event.target as HTMLInputElement;
    if (e.checked) {
      this.suppliers.forEach((supplier: { researchDefSt: boolean }) => {
        supplier.researchDefSt = false;
      });
    } else {
      this.suppliers.forEach((supplier: { researchDefSt: boolean }) => {
        supplier.researchDefSt = true;
      });
    }
  }

  setResearchDefenderDupe(event: Event, index: number) {
    const e = event.target as HTMLInputElement;
    if (e.checked) {
      this.suppliers[index].researchDefDupeSt = false;
    } else {
      this.suppliers[index].researchDefDupeSt = true;
    }
    this.researchDefDupeStFlag = this.suppliers.every(
      (supplier) => supplier.researchDefDupeSt === false
    );
  }

  selectAllResearchDefenderDupe(event: Event) {
    const e = event.target as HTMLInputElement;
    if (e.checked) {
      this.suppliers.forEach((supplier: { researchDefDupeSt: boolean }) => {
        supplier.researchDefDupeSt = false;
      });
    } else {
      this.suppliers.forEach((supplier: { researchDefDupeSt: boolean }) => {
        supplier.researchDefDupeSt = true;
      });
    }
  }

  saveSuppliers() {
    if (this.supplierBlends.length) {
      this._toastr.warning(Constants.SUPPLIER_BOCK_UNBLOCK_WARNING);
    }
    const supplierList: SupplierList[] = [];
    this.suppliers.forEach((supplier) => {
      supplierList.push({
        supplierId: supplier.id,
        supplierStatus: supplier.supplrSt,
        pureScoreSt: supplier.pureScoreSt,
        researchDefSt: supplier.researchDefSt,
        researchDefDupeSt: supplier.researchDefDupeSt,
      });
    });

    const updateObj = {
      companyId: this._userCmp,
      supplierList: supplierList,
    };

    const fusionSuppliersBlockedInPS = this.fusionSuppliers.map((sup) => ({
      supplierId: sup.supplierId,
      blocking: sup.blockedInPlatform,
    }));
    // Block PS Suppliers in Fusion too to be blocked when recapture is fired
    const psSuppliersBlocked = this.suppliers
      .filter((sup) => !!sup.asr_cmp_id)
      .map((sup) => ({
        supplierId: sup.asr_cmp_id,
        blocking: sup.supplrSt === 'B',
      }));
    const updateFusionSupObj = {
      blockedSuppliersPs: [
        ...fusionSuppliersBlockedInPS,
        ...psSuppliersBlocked,
      ],
    };

    forkJoin({
      supplier: this._manageSupplierService.updateBlockedSuppliers(updateObj),
      fusion: this._manageSupplierService.updateBlockedFusionSuppliers(
        this._userCmp,
        updateFusionSupObj
      ),
    }).subscribe(
      () => {
        this._toastr.success('Success! Supplier status has been saved');
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
  }

  /* Supplier blends */
  newSupplierBlend() {
    this._mapBlendLocale('');
    this.showGroupingButton = true;
    this.selectedSupplierBlend = {
      id: '',
      config_name: '',
      is_active: true,
      is_default: false,
      is_locked: false,
      blend_flexibility: 100,
      locale: '',
      suppliers: this.suppliers.length
        ? this.suppliers
            .filter((supp) => !(supp.supplrSt && supp.supplrSt === 'B'))
            .map((supplier) => {
              return {
                group_name: '',
                supplier_id: [{ id: supplier.id, name: supplier.name }],
                setActive: false,
                allocation_percentage: '',
                is_custom_allocation: false,
              };
            })
        : [],
    };
  }

  _remapSelectedSupplierBlendSuppliersBasedOnLocale() {
    const allSuppliersinLocale = this.suppliers.length
      ? this.suppliers
          .filter((supp) => !(supp.supplrSt && supp.supplrSt === 'B'))
          .map((supplier) => {
            return {
              group_name: '',
              supplier_id: [{ id: supplier.id, name: supplier.name }],
              setActive: false,
              allocation_percentage: '',
              is_custom_allocation: false,
            };
          })
      : [];
    if (this.selectedSupplierBlend.id === '') {
      this.selectedSupplierBlend.suppliers = allSuppliersinLocale;
    } else {
      const { finalArr, supplierWithoutGroup, groupedSupplierIds } =
        this._mapSuppliersForEditSupplierBlend(this.selectedSupplierBlend);
      const suppliers = [
        ...finalArr,
        ...supplierWithoutGroup,
        ...allSuppliersinLocale.filter(
          (supp: any) => !groupedSupplierIds.includes(supp.supplier_id[0].id)
        ),
      ];
      this.selectedSupplierBlend = { ...this.selectedSupplierBlend, suppliers };
    }
  }

  selectSupplierGroup = (selectedSupplier: any) => {
    let supplierGroupCount = 0;
    this.enableGroupingButton = false;
    this._selectedSupplierGroup = [];
    this.selectedSupplierBlend.suppliers.forEach((supplier: any) => {
      if (supplier.setActive && supplier.setActive === true) {
        supplierGroupCount++;
        this._selectedSupplierGroup.push(supplier);
      }
      if (supplierGroupCount >= 2) {
        this.enableGroupingButton = true;
      } else {
        this.enableGroupingButton = false;
      }
    });
    const groupedSupplier = this._selectedSupplierGroup.find(
      (supplier: any) => supplier.group_name
    ); // get grouped supplier from selected suppliers.
    if (groupedSupplier) {
      if (selectedSupplier.setActive) {
        // check if currently checked supplier is added or removed. if added `setActive`=true
        if (!this.supplierGroup.groupName) {
          // check if groupName is already set, if two supplier groups are selected then groupName should show the first selected group.
          this.supplierGroup.groupName = selectedSupplier.group_name;
        }
      } else {
        // if currently checked supplier is removed then set groupName field to next selected grouped supplier.
        this.supplierGroup.groupName = groupedSupplier.group_name;
      }
    } else {
      // if no grouped suppliers are selected then remove the groupName field.
      this.supplierGroup = <InputGroupSupplier>{};
    }
  };

  _validateSuppliersAllocation(blendSuppliers: GroupSupplier[]) {
    let totalAllocation = 0;
    let hasBlankAllocation = false;
    blendSuppliers.forEach((supp) => {
      if (
        !supp.hasOwnProperty('allocation_percentage') ||
        supp.allocation_percentage === ''
      ) {
        hasBlankAllocation = true;
      }
      totalAllocation += Number(supp.allocation_percentage);
    });
    if (hasBlankAllocation) {
      this._toastr.warning(Constants.SUPPLIER_BLANK_ALLOCATION);
    }
    if (!hasBlankAllocation && totalAllocation < 100) {
      this._toastr.error(Constants.SUPPLIER_ALLOCATION_LESS_THAN_HUNDRED);
      return false;
    }
    return true;
  }

  saveSupplierBlends(source: string, isLocked?: boolean) {
    isLocked =
      source === this.UPDATE_SUPPLIER_TRIGGER_SOURCE.LOCK_BLEND
        ? isLocked
        : this.selectedSupplierBlend.is_locked;
    if (!this.selectedSupplierBlend.config_name) {
      this._toastr.warning('Supplier blend config name is required');
      return;
    }

    if (
      this.selectedSupplierBlend.suppliers?.some(
        (supplier) => !validateSupplierGroupName(supplier.group_name)
      )
    ) {
      this._toastr.warning(Constants.INVALID_SUPPLIER_GROUP_NAME);
      return;
    }
    if (
      !this._hasValidAllocationForSingleSupplierBlend(
        this.selectedSupplierBlend
      ) &&
      isLocked
    ) {
      return this._toastr.error(
        `Supplier Blend ${Constants.INVALID_ALLOCATION_FOR_LOCKING_BLEND}`
      );
    }

    // validate supplier allocation
    if (this.selectedSupplierBlend?.suppliers) {
      const hasValidSupplierAllocation = this._validateSuppliersAllocation(
        this.selectedSupplierBlend.suppliers
      );
      if (!hasValidSupplierAllocation) {
        return;
      }
    }

    const payload = {
      config_name: this.selectedSupplierBlend.config_name,
      is_default: false,
      is_locked: isLocked,
      blend_flexibility: this.selectedSupplierBlend.blend_flexibility,
      flexibilitySelectedType: this.flexibilitySelectedType,
      locale: this._mapLocalePayload(),
      suppliers: this.selectedSupplierBlend.suppliers
        .filter(
          (supplier: any) =>
            supplier.allocation_percentage ||
            supplier.allocation_percentage === 0 ||
            (supplier.group_name && supplier.allocation_percentage === '')
        )
        .map((supplier: any) => {
          return {
            group_name: supplier.group_name,
            id:
              supplier.group_name === ''
                ? supplier.supplier_id[0].id.toString()
                : '',
            allocation_percentage: supplier.allocation_percentage,
            flexibility_percentage: supplier.flexibility_percentage,
            is_custom_allocation: supplier.is_custom_allocation,
            supplier_id: this._mapSupplierId(supplier.supplier_id),
          };
        }),
    };
    if (!payload.suppliers.length) {
      this._toastr.warning('Please create atleast 1 supplier grouping');
      return;
    }
    if (this.selectedSupplierBlend.id) {
      this._manageSupplierService
        .updateSupplierBlends(this.selectedSupplierBlend.id, payload)
        .subscribe(
          (resp) => {
            if (source === this.UPDATE_SUPPLIER_TRIGGER_SOURCE.LOCK_BLEND) {
              this._updateIsLockedFlag(isLocked);
              this.getSupplierBlends();
            } else {
              this.showGroupingButton = false;
              this.selectedSupplierBlend = <SupplierBlend>{};
              this.selectedCountry = undefined;
              this.selectedLanguage = undefined;
              this._toastr.success('Blend updated successfully.');
              this.getSupplierBlends();
              this.getSuppliers();
            }
          },
          (error) => {
            this._toastr.error(
              error.error.msg ||
                error.error.ps_api_response_message ||
                'Something went worng!'
            );
          }
        );
    } else {
      this._manageSupplierService.createSupplierBlends(payload).subscribe(
        (resp) => {
          this.showGroupingButton = false;
          this.selectedSupplierBlend = <SupplierBlend>{};
          this.selectedCountry = undefined;
          this.selectedLanguage = undefined;
          this._toastr.success('Blend created successfully.');
          this.getSupplierBlends();
          this.getSuppliers();
        },
        (error) => {
          this._toastr.error(
            error.error.msg ||
              error.error.ps_api_response_message ||
              'Something went worng!'
          );
        }
      );
    }
  }

  private _mapLocalePayload() {
    return this.selectedLanguage?.transalte_code
      ? `${this.selectedLanguage?.transalte_code}_${this.selectedCountry?.short_Code}`
      : '';
  }

  private _mapSupplierId(supplierId: GroupTemplateSupplier[]) {
    return supplierId.map(({ id, allocation }) => {
      const payload = {} as { id: number; allocation: number };
      payload.id = id;
      if (allocation) {
        payload.allocation = Number(allocation);
      } else {
        payload.allocation = Constants.DEFAULT_SUB_SUPPLIER_ALLOCATION;
      }

      return payload;
    });
  }

  getSupplierBlends = () => {
    this._manageSupplierService.getSupplierBlends().subscribe(
      (resp) => {
        this.supplierBlends = resp;
        this._setLockAllBlendsFlag();
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
  };

  private _setLockAllBlendsFlag() {
    this.selectLockAllBlends = !(
      this.supplierBlends.findIndex((blend) => blend.is_locked === false) > -1
    );
  }

  ungroupSupplierGroup = (supplier: any) => {
    const groupIndex = this.selectedSupplierBlend.suppliers.findIndex(
      (supp: any) => supp.group_name === supplier.group_name
    );
    if (groupIndex !== -1) {
      const ungroupedSuppliers = this.deleteGroup(
        this.selectedSupplierBlend.suppliers[groupIndex]
      );
      this.selectedSupplierBlend.suppliers.splice(
        groupIndex,
        1,
        ...ungroupedSuppliers
      );
      // PD-11747 filter blocked suppliers from the list
      this.selectedSupplierBlend.suppliers =
        this.selectedSupplierBlend.suppliers.filter((supp) => {
          const supplierDetails = this.suppliers.find(
            (sup) => sup.id === supp.supplier_id[0].id
          );
          if (
            supplierDetails &&
            !(supplierDetails.supplrSt && supplierDetails.supplrSt === 'B')
          ) {
            return true;
          }
          return false;
        });
    }
  };

  deleteGroup = (groupSupp: any) => {
    return groupSupp.supplier_id.map((supp: any) => {
      const ungroupedSupp = {
        group_name: '',
        setActive: false,
        supplier_id: [supp],
      };
      return ungroupedSupp;
    });
  };

  deleteSupplierBlend = (blend: any) => {
    this._manageSupplierService.deleteSupplierBlends(blend.id).subscribe(
      (resp) => {
        this._toastr.success('Blend deleted successfully.');
        this.getSupplierBlends();
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
  };

  openSupplierBlend = (blend: any, mapLocale: boolean = true) => {
    this.showGroupingButton = true;
    this.selectedSupplierBlend.suppliers = [];
    const { finalArr, supplierWithoutGroup, groupedSupplierIds } =
      this._mapSuppliersForEditSupplierBlend(blend);
    this.selectedSupplierBlend = {
      ...blend,
      ...{
        suppliers: this.suppliers
          .filter((supp: any) => !(supp.supplrSt && supp.supplrSt === 'B'))
          .map((supplier) => {
            return {
              group_name: '',
              supplier_id: [{ id: supplier.id, name: supplier.name }],
              setActive: false,
            };
          }),
      },
    };
    this.selectedSupplierBlend.suppliers = [
      ...finalArr,
      ...supplierWithoutGroup,
      ...this.selectedSupplierBlend.suppliers.filter(
        (supp: any) => !groupedSupplierIds.includes(supp.supplier_id[0].id)
      ),
    ];
    if (mapLocale) {
      this._mapBlendLocale(blend.locale);
    }
  };

  _mapSuppliersForEditSupplierBlend(blend: any) {
    this.flexibilitySelectedType =
      this.selectedSupplierBlend.flexibilitySelectedType ||
      FLEXIBILITY_OPTIONS.GLOBAL;
    const finalArr = [];
    const groupedSupplierIds: any = [];
    let supplierWithoutGroup: any = [];
    for (const supplierBlend of blend.suppliers) {
      let supplierWithGroup: any = [];
      for (const suppId of supplierBlend.supplier_id) {
        const supplierExists = this.suppliers
          .filter((supp) => !(supp.supplrSt && supp.supplrSt === 'B'))
          .filter(({ id }) => id === suppId.id)
          .map((supplier) => {
            if (suppId.allocation) {
              supplier.allocation = suppId.allocation;
            }
            return supplier;
          });
        if (supplierExists.length) {
          supplierWithGroup = [...supplierWithGroup, ...supplierExists];
          groupedSupplierIds.push(suppId.id);
        }
      }

      if (supplierWithGroup.length && supplierWithGroup.length > 1) {
        finalArr.push({
          group_name: supplierBlend.group_name,
          blend_flexibility: supplierBlend.blend_flexibility,
          flexibilitySelectedType: supplierBlend.flexibilitySelectedType,
          allocation_percentage: supplierBlend.allocation_percentage,
          flexibility_percentage: supplierBlend.flexibility_percentage,
          is_custom_allocation: supplierBlend.is_custom_allocation || false,
          supplier_id: supplierWithGroup.map((supp: any) => {
            return {
              id: supp.id,
              name: supp.name,
              allocation: supp.allocation,
            };
          }),
          setActive: false,
        });
      } else if (supplierWithGroup.length) {
        supplierWithoutGroup.push({
          group_name: '',
          blend_flexibility: supplierBlend.blend_flexibility,
          flexibilitySelectedType: supplierBlend.flexibilitySelectedType,
          allocation_percentage: supplierBlend.allocation_percentage,
          flexibility_percentage: supplierBlend.flexibility_percentage,
          is_custom_allocation: supplierBlend.is_custom_allocation || false,
          supplier_id: supplierWithGroup.map((supp: any) => {
            return {
              id: supp.id,
              name: supp.name,
              allocation: supp.allocation,
            };
          }),
          setActive: false,
        });
      }
    }
    return { finalArr, supplierWithoutGroup, groupedSupplierIds };
  }

  changeIsDefault(event: Event, blendSelected: any) {
    const e = event.target as HTMLInputElement;
    blendSelected.is_default = e.checked;
    this.supplierBlends.forEach((blend: any) => {
      if (blend.id === blendSelected.id) {
        blend.is_default = blendSelected.is_default;
      } else {
        blend.is_default = false;
      }
    });

    this._manageSupplierService
      .setBlendAsDefault(blendSelected.id, blendSelected.is_default)
      .subscribe(
        (resp: any) => {
          if (blendSelected.is_default) {
            this._toastr.success(
              `“${resp.configName}” is set as default supplier blend.`
            );
          } else {
            this._toastr.success(
              `“${resp.configName}” was removed as default supplier blend.`
            );
          }
        },
        (error) => {
          blendSelected.is_default = false;
          this._toastr.error(error.error.msg);
        }
      );
  }

  /* New Supplier Group Modal Start */
  openGroupModal() {
    const modalRef = this._modal.open(SupplierGroupModalComponent, {
      data: {
        selectedSupplierGroup: this._selectedSupplierGroup,
        selectedSupplierBlend: this.selectedSupplierBlend,
        editModal: false,
      },
    });

    modalRef.onClose$.subscribe((response) => {
      if (response?.msg === 'ok') {
        this.selectedSupplierBlend.suppliers = this._mapSupplierGroupInBlend(
          response.supplierGroup,
          deepCopy(this._selectedSupplierGroup)
        );
        this._selectedSupplierGroup = [];
      }
    });
  }

  editGroupModal(supplier: any) {
    this._selectedSupplierGroup = [supplier];
    const modalRef = this._modal.open(SupplierGroupModalComponent, {
      data: {
        selectedSupplierGroup: this._selectedSupplierGroup,
        selectedSupplierBlend: this.selectedSupplierBlend,
        editModal: true,
      },
    });

    modalRef.onClose$.subscribe((response) => {
      if (response?.msg === 'ok') {
        this.selectedSupplierBlend.suppliers = this._mapSupplierGroupInBlend(
          response.supplierGroup,
          deepCopy(this._selectedSupplierGroup)
        );
        this._selectedSupplierGroup = [];
      }
    });
  }

  private _mapSupplierGroupInBlend(
    supplierGroup: GroupSupplier,
    oldSelectedSupplierGroup: GroupSupplier[]
  ): GroupSupplier[] {
    const newGroupSupplierIds = supplierGroup.supplier_id.map(({ id }) => id);
    const oldGroupSupplierIds = oldSelectedSupplierGroup.flatMap(
      ({ supplier_id }) => {
        return supplier_id.map((supplier) => supplier);
      }
    );
    const removeFromGroupSuppliers = oldGroupSupplierIds.filter(
      ({ id }) => !newGroupSupplierIds.includes(id)
    );
    const removeFromGroupSupplierId = removeFromGroupSuppliers.map(
      ({ id }) => id
    );

    const otherSuppliers: GroupSupplier[] =
      this.selectedSupplierBlend.suppliers.reduce((acc, supplier) => {
        const supIds = supplier.supplier_id.map((_supplier) => _supplier.id);
        const notExistInGroup = supIds.some(
          (id) =>
            !newGroupSupplierIds.includes(id) &&
            !removeFromGroupSupplierId.includes(id)
        );
        if (notExistInGroup) {
          acc.push(supplier);
        }
        return acc;
      }, <GroupSupplier[]>[]);

    if (removeFromGroupSuppliers.length) {
      removeFromGroupSuppliers.forEach((supplier) => {
        otherSuppliers.push({
          group_name: '',
          allocation_percentage: '',
          is_custom_allocation: false,
          supplier_id: [supplier],
          setActive: false,
        });
      });
    }

    return [supplierGroup, ...otherSuppliers];
  }
  /* New Supplier Group Modal End */

  public selectSupplierValue(supplier: Supplier) {
    if (supplier.selected === false || !supplier.selected) {
      supplier.selected = true;
    } else {
      supplier.selected = false;
    }
    if (supplier.id === this.fusionCmpId) {
      this.showFusionSuppliers = !this.showFusionSuppliers;
    }
    return supplier;
  }

  validateBlendFlexibilityPercentage(event: any) {
    let blendPercentage = Number(event.target.value);
    if (blendPercentage >= 0 && blendPercentage <= 100) {
      return true;
    } else {
      this.selectedSupplierBlend.blend_flexibility = 100;
      this._toastr.error('Invalid number for Flexibility');
      return false;
    }
  }

  setFlexibilityType(event: any) {
    switch (event.key) {
      case 0:
        this.selectedSupplierBlend.blend_flexibility = 100;
        this.selectedSupplierBlend = this._flexibililtyClass.updateFlexValue(
          this.selectedSupplierBlend,
          this.selectedSupplierBlend.blend_flexibility
        );
        break;
      case 2:
        this.selectedSupplierBlend.blend_flexibility = 0;
        this.selectedSupplierBlend = this._flexibililtyClass.updateFlexValue(
          this.selectedSupplierBlend,
          this.selectedSupplierBlend.blend_flexibility
        );
    }
  }

  updateFlexValue(flexValue: number) {
    this.selectedSupplierBlend.blend_flexibility = flexValue;
    this.selectedSupplierBlend = this._flexibililtyClass.updateFlexValue(
      this.selectedSupplierBlend,
      flexValue
    );
  }

  updateSupplierFlexibility(supplier: GroupSupplier) {
    const selectedSuppliers = this.selectedSupplierBlend.suppliers.filter(
      ({ setActive }) => setActive
    );
    selectedSuppliers.forEach((supplierx) => {
      supplierx.flexibility_percentage = supplier.flexibility_percentage;
    });
  }

  updateSupplierAllocation(supplier: GroupSupplier) {
    const selectedSuppliers = this.selectedSupplierBlend.suppliers.filter(
      ({ setActive }) => setActive
    );
    selectedSuppliers.forEach((supplierx) => {
      supplierx.allocation_percentage = supplier.allocation_percentage;
    });
  }

  selectAll() {
    this.selectedSupplierBlend = this._allocationClass.selectAll(
      this.selectedSupplierBlend
    );
  }

  clearAllSuppliers() {
    this.selectedSupplierBlend = this._allocationClass.clearAllSuppliers(
      this.selectedSupplierBlend
    );
  }

  private _getCountries() {
    this._commonService.getCountries(true).subscribe(
      (countriesData) => {
        this.countries = countriesData;
      },
      (err) => {
        this._toastr.error(err.error.msg);
      }
    );
  }

  private _mapBlendLocale(locale: string) {
    let countryIndex = 0;
    let languageIndex = 0;

    if (locale) {
      const [languageCode, countryCode] = locale.split('_');
      countryIndex = this.countries.findIndex(
        (country) => country.short_Code === countryCode
      );
      if (countryIndex) {
        languageIndex = this.countries[countryIndex]?.lang.findIndex(
          (lang) => lang.transalte_code === languageCode
        );
      }
    }
    if (this.countries[countryIndex]) {
      this.selectedCountry = this.countries[countryIndex];
      this.languages = this.countries[countryIndex].lang;
      this.selectLanguage(this.languages[languageIndex]);
    } else {
      this._toastr.warning(Constants.BLOCKED_COUNTRY_BLEND_UNAVAILABLE);
    }
  }

  ngOnDestroy(): void {
    this._routeFragmentSubscription?.unsubscribe();
  }
}

interface Tab {
  title: string;
  active: boolean;
}
