<div class="row buyer-rate-card">
  <div class="col-sm-12 col-md-3 form-group">
    <div class="row">
      <!-- Buyer -->
      <div class="col-12">
        <label>Buyer</label>
        <ps-ui-select-dropdown
          [dropdownItems]="buyers"
          [field]="'name'"
          [enableSearch]="true"
          labelNone="Select Buyer"
          [label]="selectedBuyer?.name"
          [(ngModel)]="selectedBuyer"
          (selectedItem)="selectBuyer($event)"
        >
        </ps-ui-select-dropdown>
      </div>
      <!-- Country -->
      <div class="col-12 rate-card-dropdown-margin">
        <label>Country {{ selectedCountry?.name }}</label>
        <ps-ui-select-dropdown
          [dropdownItems]="countries"
          [field]="'name'"
          [enableSearch]="true"
          labelNone="Select Country"
          [(ngModel)]="selectedCountry"
          [label]="selectedCountry?.name"
          (selectedItem)="selectCountry($event)"
        >
        </ps-ui-select-dropdown>
      </div>
      <!-- Language -->
      <div class="col-12 rate-card-dropdown-margin">
        <label>Language {{ selectedLanguage?.name }}</label>
        <ps-ui-select-dropdown
          [dropdownItems]="languages"
          [field]="'name'"
          [enableSearch]="true"
          [(ngModel)]="selectedLanguage"
          labelNone="Select Language"
          [label]="selectedLanguage?.name"
          [loading]="false"
          (selectedItem)="selectLanguage($event)"
        >
        </ps-ui-select-dropdown>
      </div>
    </div>
  </div>
  <div
    class="col-sm-12 col-md-9 form-group"
    *ngIf="
      selectedBuyer && selectCountry && selectedLanguage;
      else noBuyerSelected
    "
  >
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <div
          class="alert alert-light text-center"
          role="alert"
          *ngIf="
            !buyerRateCards?.suppliers?.length &&
            !newBuyerRateCardSupplier.editable
          "
        >
          No Buyer Rate Card exists for this Buyer in this locale.
        </div>
        <table class="table">
          <thead
            *ngIf="
              buyerRateCards?.suppliers?.length ||
              newBuyerRateCardSupplier.editable
            "
          >
            <th>Locale</th>
            <th>Supplier</th>
            <th>Buyer Rate Card (USD Only)</th>
            <th>Last Updated</th>
            <th>Updated By</th>
            <th>Edit</th>
          </thead>
          <tbody>
            <ng-container *ngIf="buyerRateCards?.suppliers?.length">
              <tr *ngFor="let supplier of buyerRateCards.suppliers">
                <td>{{ selectedLocale }}</td>
                <td>{{ supplier.name }}</td>
                <td>
                  <div class="row mt-4">
                    <div class="flex-column">
                      <div
                        class="d-flex justify-content-center"
                        *ngIf="supplier.editable"
                      >
                        <input
                          #rateCardsFileUpload
                          hidden
                          type="file"
                          class="file-upload"
                          accept=".xlsx,.xls,.csv"
                          (change)="uploadFile($event, supplier)"
                        />
                        <button
                          type="button"
                          class="btn btn-link upload-button"
                          (click)="
                            rateCardsFileUpload.value = '';
                            rateCardsFileUpload.click()
                          "
                        >
                          Upload a file for {{ supplier.name }}
                        </button>
                      </div>
                      <div
                        class="d-flex justify-content-center"
                        *ngIf="supplier.editable"
                      >
                        .csv
                      </div>
                      <div class="d-flex justify-content-center">
                        <button
                          type="button"
                          class="btn btn-link download-button"
                          (click)="downloadBuyerRateCard(supplier.id)"
                        >
                          Download Rate Card CSV
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{{ supplier.updatedAt | date : 'h:mm a MM/dd/yyyy' }}</td>
                <td>{{ supplier.updatedBy }}</td>
                <td>
                  <div class="add-button-group" *ngIf="supplier.editable">
                    <div
                      class="fa fa-check"
                      (click)="addNewBuyerRateCard(supplier)"
                    ></div>
                    <div
                      class="fa fa-times"
                      (click)="resetEditSupplier(supplier)"
                    ></div>
                  </div>
                  <div class="add-button-group" *ngIf="!supplier.editable">
                    <div
                      class="fa fa-pencil"
                      (click)="editSupplier(supplier)"
                    ></div>
                    <div
                      class="fa fa-trash"
                      (click)="deleteBuyerRateCard(supplier.name, supplier._id)"
                    ></div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <tr
              *ngIf="newBuyerRateCardSupplier.editable"
              class="supplier-dropdown"
            >
              <td>{{ selectedLocale }}</td>
              <td>
                <ps-ui-select-dropdown
                  [dropdownItems]="suppliers"
                  [field]="'name'"
                  [enableSearch]="true"
                  labelNone="Select Supplier"
                  [label]="newBuyerRateCardSupplier?.name"
                  (selectedItem)="selectSupplier($event)"
                >
                </ps-ui-select-dropdown>
              </td>
              <td>
                <div class="flex-column">
                  <div class="d-flex justify-content-center">
                    <input
                      #rateCardsFileUpload
                      hidden
                      type="file"
                      class="file-upload"
                      accept=".xlsx,.xls,.csv"
                      (change)="uploadFile($event, newBuyerRateCardSupplier)"
                    />
                    <button
                      type="button"
                      class="btn btn-link upload-button"
                      (click)="
                        rateCardsFileUpload.value = '';
                        rateCardsFileUpload.click()
                      "
                    >
                      Upload Rate Card File
                    </button>
                  </div>
                  <div class="d-flex justify-content-center">.csv</div>
                  <div class="d-flex justify-content-center">
                    <button
                      type="button"
                      *ngIf="!newBuyerRateCardSupplier.hasBuyerRateCard"
                      class="btn btn-link download-button"
                      (click)="downloadBuyerRateCardTemplate()"
                    >
                      Download Template
                    </button>
                    <button
                      type="button"
                      *ngIf="newBuyerRateCardSupplier.hasBuyerRateCard"
                      class="btn btn-link download-button"
                      (click)="
                        downloadBuyerRateCard(newBuyerRateCardSupplier.id)
                      "
                    >
                      Download Rate Card CSV
                    </button>
                  </div>
                </div>
              </td>
              <td>-</td>
              <td>-</td>
              <td>
                <div class="add-button-group">
                  <div
                    class="fa fa-check"
                    (click)="addNewBuyerRateCard(newBuyerRateCardSupplier)"
                  ></div>
                  <div class="fa fa-times" (click)="resetAddSupplier()"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" *ngIf="!newBuyerRateCardSupplier.editable">
      <div class="col-sm-12 col-md-12 buyer-rate-card-buttons text-center mt-6">
        <button
          type="submit"
          psUiButton
          color="primary"
          (click)="addNewBuyerRateCardRow()"
        >
          Add New Buyer Rate Card
        </button>
      </div>
    </div>
  </div>
  <ng-template #noBuyerSelected>
    <div class="col-sm-12 col-md-8">
      <div class="alert alert-light text-center" role="alert">
        Select Buyer, Country and Language to proceed!
      </div>
    </div>
  </ng-template>
</div>
<div class="row">
  <div class="buyer-rate-card-buttons col-md-6 mt-6">
    <button
      id="dashboard"
      type="button"
      psUiButton
      color="primary"
      [routerLink]="['/dashboard/']"
    >
      Back to Dashboard
    </button>
  </div>
</div>
