import { Component, Inject } from '@angular/core';
import { ModalData, ModalRef } from '@purespectrum1/ui/modal';
import { BULK_STATUS_CHANGE_MODAL } from '../../../constants/modal-constants';

@Component({
  selector: 'ps-bulk-survey-status-change-modal',
  templateUrl: './bulk-survey-status-change-modal.component.html',
  styleUrls: ['./bulk-survey-status-change-modal.component.css'],
})
export class BulkSurveyStatusChangeModalComponent {
  public data: BulkSurveyStatusModalData = this._modalData;
  public modalConstants = BULK_STATUS_CHANGE_MODAL;
  constructor(
    @Inject(ModalData)
    private _modalData: BulkSurveyStatusModalData,
    private _modalRef: ModalRef<BulkSurveyStatusChangeModalComponent, string>
  ) {}

  close(msg?: string) {
    this._modalRef.close(msg);
  }
}

interface BulkSurveyStatusModalData {
  surveyIds: number[];
  onlyThisBtnText: string;
  thisAndAllBtnText: string;
}
