interface CurrencyUnit {
  [key: string]: string;
}

export const UNITS: CurrencyUnit = {
  '321': '$', // USA Dollor
  '322': '€', // Germany, Austria EURO
  '323': '£', //UK Pond
  '324': '$', //AU Dollor
  '325': '¥', // Japan Yen
  '326': '$', //Canadian Dollar
  '327': '$', // Mexican Peso
  '328': '$', // Argentina Peso
  '329': '$', // Chile Peso
  '330': '$', // Columbian Peso
  '331': '¥', // Chinese Yuan
  '332': '₹', // Indian Rupees
  '333': 'zł', //Poland currency Polish Zloty
  '334': '₩', //Korea currency
  '335': '₱', // Phillipines peso
  '336': '₦', // Nigerian Naira
  '337': 'Rp', // Indonesian rupiah
  '338': 'R$', // Brazilian Real
  '339': '₫', // Vietnami Dong
  '340': '₴', // Ukrainian Hryvnia
  '341': '€', // Ireland Euro
  '342': 'HK$', // Hong Kong $
  '343': 'ر.س', // Saudi Riyal
  '344': 'NT$', // New Taiwan Dollar
  '345': 'د.إ', // AED Dirham UAE
  '346': 'RM', // malaysian Ringgit (MYR)
  '347': '€', // netherlands Euro (EUR)
  '348': '€', // Belgium Euro (EUR)
  '349': 'KR', // Norwegian krone (NOK)
  '350': 'KR', // Danish krone (DKK)
  '351': 'R', // Rand (ZAR)
  '352': 'KR', // Krona (SEK)
  '353': '₺', // Turkish Lira (TRY)
  '354': 'E£', //Egyptian pound(EGP)
  '355': 'CHF', // Swiss Franc CHF
  '356': '$', // Singapore Dollar
  '357': '฿', // Thailand Bhat
  '358': 'S/', //  Peruvian Sol
  '359': '€', //  Portugal Euro
  '361': '$', // Ecuador Dollor
  '311': 'yr', // Years
  '312': 'mth', // Months
  '313': 'day', // Days,
  '314': 'hr', // Hours
  '401': 'movies', // movies
  '402': 'tv hours', // hours
  '403': 'nights', // Hotel Nights
  '404': 'trips', // Hotel Nights
  '362': 'د.إ', // AED Dirham Qatar
  '363': '₡', //Costa Rican colón,
  '364': 'Лв', //Bulgaria currency
  '365': 'Kč', // Czech Republic
  '366': '€', // Slovakia
  '367': 'Ft', // hungary
  '368': 'lei', //Romania
  '369': '€', // greece
  '370': '₪', // Israel
  '371': '$', // New Zealand
  '372': '€', //Finland
  '373': '৳', //Bangladesh
  '374': '₨', //Pakistan
  '375': 'د.ك', // Kuwait dinar
  '376': 'ل.ل.', // Lebanese Pound
  '377': 'K', // Myanmar kyat
  '378': '៛', // Cambodia
  '379': 'Дин', // Serbia (Serbian Dinar)
  '380': '€', // Croatia (Euro)
  '381': '€', // Slovenia (Euro),
  '382': '₸', // Kazakhstan (Tenge)
  '383': 'лв', // Uzbekistan (SUM)
  '384': '€', // Lithuania (Euro)
  '385': '€', // Estonia (Euro)
  '386': '€', // Latvia (Euro)
  '387': 'Ksh', // Kenya (Kenyan shilling)
  '388': '$', // El Salvador (USA Dollar)
  '389': 'Q', // Guatemalan Quetzal
  '390': 'دج', // Algeria (Algerian dinar)
  '391': 'د.م.', // Morocco (Moroccan Dirham)
  '392': 'MT', // Mozambican Metical (MZN)
  '393': 'JD', // JORDAN (Jordanian Dinar)
  '394': '.د.ب', // Bahrain (Bahraini Dinar)
  '395': '₲', // Paraguay (Paraguayan Guarani - PYG)
  '396': '$U', // Uruguay Peso
  '397': 'J$', // Jamaica (Jamaican Dollar)
  '398': 'B/.', // Panama (Panamanian Balboa - PAB)
  '399': 'G', // Haiti (Haitian Gourde)
  '400': '€', // Kosovo (Euro - EUR)
  '405': 'FC', // Congolese Franc
  '406': 'L', // Moldova (Moldovan Leu)
  '407': 'R₣', // Rwandan Franc - RWF
  '408': 'ብር', // Ethiopia (ETB)
  '409': 'ر.ع.', // Omani Rial
  '410': 'GH¢', // Ghana (Ghanaian Cedi - GHS)
  '411': '₾', // Georgia (Georgian lari - GEL)
  '412': 'Rbl', // Belarus (Belarusian Ruble - BUN)
  '413': 'ج.س.', // Sudan (Sudanese Pound - SDG)
  '414': '€', // Malta (Euro)
  '415': '֏', // Armenian Dram - AMD
  '416': '€', // Cyprus (Euro - EUR)
  '417': 'Rs रू', // Nepalese Rupee - NPR
  '418': '€', // Luxembourg (Euro - EUR)
  '419': '₼', // Azerbaijan
  '420': 'Rs', // Sri Lanka (Sri Lankan Rupee - LKR)
  '421': 'XOF', // Ivory Coast (West African Franc - CFA)
  '422': 'c', // Kyrgyzstan (Kyrgyzstani som - KGS)
  '423': 'SM', // Tajikistani somoni
  '424': 'm', // Turkmenistan Manat - TMT
  '425': '₽', // Russia (Russian Ruble - RUB)
  '426': 'Lek', // Albania (Albanian Lek - ALL)
  '427': 'Bs', // Bolivia (BOB)
  '428': 'د.ع', // Iraq (Iraqi Dinar - IQD)
  '429': 'Bs.F', // Venezuela (Venezuelan bolívar - VEF)
  '430': 'Kz', // Angolo (AOA)
};

export const CURRENCY_CODE = {
  US: 321,
  AU: 324,
};

export const DEFAULT_CURRENCY_UNIT = '$';
