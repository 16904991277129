import {
  DashboardPreference,
  SurveyListing,
} from '../../shared/services/buyer-api/survey.interface';
import { WIDGETS } from '../dashboard-constants';

export class Widget {
  constructor(
    public readonly id: number,
    public readonly column: string,
    public readonly key: string
  ) {}

  static preference(preference: DashboardPreference): Widget {
    return new Widget(preference.id, preference.column, preference.key);
  }
  static extract(
    preferences: DashboardPreference[],
    isMobile: boolean
  ): Widget[] {
    return preferences
      .filter(
        (preference) =>
          Object.values(WIDGETS).includes(preference.key) &&
          preference.selected &&
          ((isMobile && preference.mobile) || (!isMobile && preference.desktop))
      )
      .map((preference) => Widget.preference(preference));
  }

  count(survey: SurveyListing): number {
    if (this.key == WIDGETS.multiCountry) {
      return survey.mc_count || 0;
    }

    return survey.tc_count || 0;
  }
}

export class SelectedWidget {
  get key() {
    return this.widget.key;
  }

  constructor(
    public readonly widget: Widget,
    public readonly selected: boolean,
    public readonly survey?: SurveyListing
  ) {}

  static empty(): SelectedWidget {
    return new SelectedWidget(new Widget(0, 'empty', 'empty'), false);
  }

  static selected(widget: Widget, survey: SurveyListing): SelectedWidget {
    return new SelectedWidget(widget, true, survey);
  }

  select(widget: Widget, survey: SurveyListing): SelectedWidget {
    if (this.widget.key != widget.key) {
      return SelectedWidget.selected(widget, survey);
    }

    if (this.survey?.surveyId != survey.surveyId) {
      return SelectedWidget.selected(widget, survey);
    }

    return SelectedWidget.empty();
  }

  for(survey: SurveyListing): boolean {
    return this.survey?.surveyId === survey.surveyId;
  }

  equals(widget: Widget) {
    return (
      this.widget.id === widget.id &&
      this.widget.column === widget.column &&
      this.widget.key === widget.key
    );
  }
}
