import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  Currency,
  GetAllCurrenciesResponse,
  UpdateCurrencyDetails,
} from '../currency-settings/currency-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  getAllCurrencies(): Observable<GetAllCurrenciesResponse> {
    return this._http.get<GetAllCurrenciesResponse>(
      `${this._baseUrl}/international_currency`
    );
  }

  updateCurrencyDetails(
    curencyData: Currency
  ): Observable<UpdateCurrencyDetails> {
    return this._http.put<UpdateCurrencyDetails>(
      `${this._baseUrl}/international_currency/${curencyData.currencyShortCode}`,
      curencyData
    );
  }
}
