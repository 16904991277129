import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CompanyResponseObject } from '@purespectrum1/ui/marketplace/shared/interfaces/company.interface';
import { UserResponseObject } from '@purespectrum1/ui/marketplace/shared/interfaces/user.interface';
import { ModalRef, ModalData } from '@purespectrum1/ui/modal';
import { CompanyFormatter } from '../domain/company-formatter';

export interface EditCompanyModalData {
  from: CompanyResponseObject;
  to: CompanyResponseObject;
  user: UserResponseObject;
}

@Component({
  selector: 'ps-manage-user-edit-company-modal',
  templateUrl: './manage-user-edit-company-modal.component.html',
  styleUrls: ['./manage-user-edit-company-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageUserEditCompanyModalComponent {
  public get userCompany(): string {
    return new CompanyFormatter(this._modalData.from).toString();
  }
  public get userCompanyType(): string {
    return new CompanyFormatter(this._modalData.from).format().type();
  }
  public get newCompany(): string {
    return new CompanyFormatter(this._modalData.to).toString();
  }

  public get newCompanyType(): string {
    return new CompanyFormatter(this._modalData.to).format().type();
  }

  public get hasCompanyChange(): boolean {
    return this._modalData.user.company !== this._modalData.to.id;
  }

  constructor(
    @Inject(ModalData) private _modalData: EditCompanyModalData,
    private _modalRef: ModalRef<ManageUserEditCompanyModalComponent, string>
  ) {}

  public close(msg?: string) {
    this._modalRef.close(msg);
  }
}
