import { UserType } from '@purespectrum1/ui/marketplace/shared/types/user-type';
import { SurveyStatus } from '@purespectrum1/ui/marketplace/shared/utils/survey-status';
import { SurveyStatusChange } from 'src/app/shared/services/socket/socket.interface';
import { SURVEY_STATUS } from '../../constants/survey-status';
import { Constants } from '../dashboard-constants';
import { Counters, Tab, TabTitle } from '../types';

export class DashboardTab {
  constructor(
    public readonly title: TabTitle,
    public readonly status: SurveyStatus | string,
    public readonly roleAccess: string[],
    public readonly active: boolean,
    public count: number
  ) {}

  public static from(tab: Tab) {
    return new DashboardTab(
      tab.title,
      tab.status,
      tab.roleAccess,
      tab.active,
      tab.count
    );
  }

  public update(counters: Counters): void {
    const tab = counters.find((counter) => counter._id === Number(this.status));
    this.count =
      this.title === 'ALL'
        ? 0 // Always return 0 for all tabs so it can be paginated
        : tab?.count ?? 0;
  }

  public change(data: SurveyStatusChange): void {
    if (Number(this.status) === data.newStatus) {
      this.count += 1;
      return;
    }

    if (Number(this.status) === data.oldStatus) {
      this.count = Math.max(this.count - 1, 0);
    }
  }

  public counter(): number {
    return Constants.SURVEY_STATUS_SHOW_COUNT.includes(this.status.toString())
      ? this.count
      : 0;
  }
}

export class TabState {
  constructor(public tabs: DashboardTab[]) {}

  public update(counters: Counters): void {
    this.tabs.forEach((tab) => tab.update(counters));
  }

  public statusChange(data: SurveyStatusChange): void {
    this.tabs.forEach((tab) => tab.change(data));
  }
}

export class TabsFactory {
  constructor(
    private readonly _user: UserType,
    private readonly _invoice: boolean,
    private readonly _service: boolean
  ) {}

  public create(): DashboardTab[] {
    if (this._user === 'buyer') {
      return this._buyer();
    }

    if (this._service) {
      return this._buyer();
    }

    if (this._user === 'operator') {
      return this._operator();
    }

    if (this._user === 'supplier') {
      return this._supplier();
    }

    return this._default();
  }

  private _buyer(): DashboardTab[] {
    const tabs = this._invoice
      ? Constants.SURVEY_TABS
      : Constants.SURVEY_TABS.filter((tab) => tab.title !== 'INVOICED');

    return tabs.map((tab) => DashboardTab.from(tab));
  }

  private _operator(): DashboardTab[] {
    return Constants.SURVEY_TABS.filter(
      (tab) => tab.status !== SURVEY_STATUS.DRAFT.toString()
    ).map((tab) => DashboardTab.from(tab));
  }

  private _supplier(): DashboardTab[] {
    const tabs = Constants.SURVEY_TABS.filter(
      (tab) => !['DRAFTS', 'INVOICED'].includes(tab.title)
    );

    return tabs.map((tab) => DashboardTab.from(tab));
  }

  private _default(): DashboardTab[] {
    return Constants.SURVEY_TABS.map((tab) => DashboardTab.from(tab));
  }
}
