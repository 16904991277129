<div class="container">
  <div class="row justify-content-md-center">
    <div class="col-xs-12 col-sm-11 col-md-8">
      <h4 class="text-center">Manage Buyers</h4>
      <div class="pull-right">
        <span class="mx-2">Select All</span>
        <ps-ui-slider
          [selected]="selectAllTogle"
          (selectedChange)="selectAll()"
        ></ps-ui-slider>
      </div>
      <table class="table manage-buyer-table">
        <thead>
          <th>Name</th>
          <th>Blocked</th>
        </thead>
        <tbody>
          <tr *ngFor="let buyer of buyers; let i = index">
            <td>{{ buyer.name }}</td>
            <td>
              <input
                type="checkbox"
                [(ngModel)]="buyer.blocked"
                (change)="blockBuyer($event, i)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <a routerLink="/dashboard" psUiButton class="col-md-3"
        >Back To Dashboard</a
      >
      <button psUiButton class="col-md-2 offset-md-4" (click)="saveBuyers()">
        Save
      </button>
      <a routerLink="/dashboard" psUiButton class="col-md-2 offset-md-1"
        >Cancel</a
      >
    </div>
  </div>
</div>
