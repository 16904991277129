import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[psMultiCountryColumnContent]',
})
export class MultiCountryColumnContentDirective {
  @Input() classList = '';

  @Input() show = true;

  constructor(public template: TemplateRef<unknown>) {}
}
