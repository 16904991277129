import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@purespectrum1/ui/modal';
import { ServiceAccountModalComponent } from '../../../layout/header/user-menu/service-account-modal/service-account-modal.component';

@Component({
  selector: 'ps-quota-mapper',
  templateUrl: './quota-mapper.component.html',
  styleUrls: ['./quota-mapper.component.css'],
})
export class QuotaMapperComponent {
  constructor(private _router: Router, private _modal: ModalService) {}

  goToDashboard(close: boolean) {
    if (close) {
      this._router.navigateByUrl('/dashboard');
    }
  }

  selectBuyer(open: boolean) {
    if (open) {
      this._modal.open(ServiceAccountModalComponent, {});
    }
  }
}
