<div class="container epc-report-form-settiings dash-report">
  <h3 class="text-center report-title-spacing">EPC Report</h3>

  <div class="row">
    <div class="col-sm-6 col-md-4 form-group">
      <label for="Country" class="control-label">Company</label>
      <ps-ui-select-dropdown
        [dropdownItems]="companies"
        [field]="'name'"
        labelNone="Select Company"
        [label]="selectedCompany?.name"
        (selectedItem)="selectCompany($event)"
      >
      </ps-ui-select-dropdown>
    </div>
    <div class="form-group col-xs-12 col-md-6 col-lg-4">
      <label for="dateRange" class="control-label">Date Range</label>
      <input
        class="form-control date-range"
        type="text"
        name="daterangeInput"
        daterangepicker
        [options]="options"
        (selected)="selectedDate($event)"
      />
    </div>
  </div>
  <div class="row mt-12 justify-content-center">
    <div class="hasing-buttons mt-6 col-sm-4">
      <button
        type="submit"
        psUiButton
        color="primary"
        size="large"
        (click)="getEpcReport()"
      >
        Find
      </button>
    </div>
  </div>
  <div
    class="row table-responsive table-absolute mt-5"
    *ngIf="epcReportByBuyerArr.length > 0"
  >
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th
            scope="col"
            [psSortTable]="epcReportByBuyerArr"
            data-order=""
            data-name="user_name"
          >
            User Name
          </th>
          <th
            scope="col"
            [psSortTable]="epcReportByBuyerArr"
            data-order=""
            data-name="validEpc"
          >
            Valid EPC
          </th>
          <th
            scope="col"
            [psSortTable]="epcReportByBuyerArr"
            data-order=""
            data-name="epc"
          >
            Total EPC
          </th>

          <th
            scope="col"
            [psSortTable]="epcReportByBuyerArr"
            data-order=""
            data-name="activeSurveys"
          >
            Number of Active Projects
          </th>
          <th
            scope="col"
            [psSortTable]="epcReportByBuyerArr"
            data-order=""
            data-name="srv_lunchCount"
          >
            Projects Launched
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of epcReportByBuyerArr">
          <td>{{ data.user_name || 'NA' }}</td>
          <td>{{ data.validEpc || 'NA' }}</td>
          <td>{{ data.epc || 'NA' }}</td>
          <td>{{ data.activeSurveys || 'NA' }}</td>
          <td>{{ data.srv_lunchCount || 'NA' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
