<div [formGroup]="form">
  <div class="row form-group">
    <div class="col-md-12">
      <h4 class="list_heading">Billing Information</h4>
    </div>
  </div>
  <div class="row form-group" formGroupName="netsuite">
    <div class="col-md-4">
      <label class="control-label pull-right">NetSuite Integrations</label>
    </div>
    <div class="col-md-2 align-center netsuite-check">
      <input
        type="checkbox"
        class="form-control form-check-input d-flex"
        (change)="toggleNSIntegration()"
        formControlName="enabled"
      />
      <p class="checkbox-label">Yes</p>
    </div>
  </div>
  <div *ngIf="form.value.netsuite.enabled">
    <div class="row form-group">
      <div class="col-md-4">
        <label class="control-label pull-right">NetSuite Integration ID</label>
      </div>
      <div class="col-md-6">
        <input
          type="text"
          class="form-control input-tag input"
          placeholder="NetSuite Integration ID"
          [(ngModel)]="nsInternalId"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
      <div class="col-md-2">
        <button
          type="button"
          size="small"
          color="primary"
          class="button-basic"
          psUiButton
          (click)="fetchNetSuiteCompany()"
        >
          >
        </button>
      </div>
    </div>
    <div class="row form-group" formGroupName="netsuite">
      <div class="col-md-4">
        <label class="control-label pull-right">NetSuite Company Name</label>
      </div>

      <div class="col-md-8">
        <input
          type="text"
          class="form-control input-tag input"
          [attr.disabled]="true"
          placeholder="NetSuite Company Name"
          formControlName="companyName"
        />
      </div>
    </div>
    <div class="row form-group" formGroupName="netsuite">
      <div class="col-md-4">
        <label class="control-label pull-right">Billing Address</label>
      </div>
      <div class="col-md-8 d-flex" formGroupName="billingInfo">
        <div class="row">
          <div class="col-md-12 mb-2">
            <input
              type="textarea"
              [attr.disabled]="true"
              class="form-control input-tag input"
              placeholder="Address"
              formControlName="address"
            />
          </div>

          <div class="col-md-6 mb-2 pr-5">
            <input
              type="text"
              [attr.disabled]="true"
              class="form-control input-tag input"
              placeholder="City/Town"
              formControlName="city"
            />
          </div>

          <div class="col-md-6 mb-2 pl-5">
            <input
              type="text"
              [attr.disabled]="true"
              class="form-control input-tag input"
              placeholder="State"
              formControlName="state"
            />
          </div>

          <div class="col-md-6 mb-2 pr-5">
            <input
              type="text"
              [attr.disabled]="true"
              class="form-control input-tag input"
              placeholder="Zipcode"
              formControlName="zip"
            />
          </div>

          <div class="col-md-6 mb-2 pl-5">
            <input
              type="text"
              [attr.disabled]="true"
              class="form-control input-tag input"
              placeholder="Country"
              formControlName="country"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
