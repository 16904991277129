'use strict';
export const MARGIN = 99;
export const Constants = Object.freeze({
  REPORTS_DASHBOARD: [
    {
      title: 'SURVEY PERFORMANCE REPORT',
      key: 'SURVEY_PERFORMANCE_REPORT',
      active: true,
    },
    {
      title: 'DAILY STATISTICS REPORT',
      key: 'DAILY_STATISTICS_REPORT',
      active: false,
    },
    {
      title: 'SURVEY TRANSACTION REPORT',
      key: 'SURVEY_TRANSACTION_REPORT',
      active: false,
    },
    {
      title: 'BUYER SALES REPORT',
      key: 'BUYER_SALES_REPORT',
      active: false,
    },
    {
      title: 'SUPPLIER REPORT',
      key: 'SUPPLIER_REPORT',
      active: false,
    },
    {
      title: 'PSID REPORT',
      key: 'PSID_REPORT',
      active: false,
    },
    {
      title: 'STC REPORT',
      key: 'STC_REPORT',
      active: false,
    },
    {
      title: 'TRANSACTION BY STATUS',
      key: 'TRANSACTION_BY_STATUS',
      active: false,
    },
    {
      title: 'EPC REPORT',
      key: 'EPC_REPORT',
      active: false,
    },
  ],
  PSID_SUB_REPORTS: [
    {
      name: 'Survey History',
      value: 'survey_history',
    },
    {
      name: 'Profile Data',
      value: 'profile_data',
    },
    {
      name: 'Supplier History',
      value: 'supplier_history',
    },
  ],
});
