<div class="supplier-tabs" [formGroup]="form">
  <div class="form-group">
    <label class="col-md-4 control-label">Send Notification</label>
    <div class="col-md-6">
      <label class="radio-inline">
        <input type="radio" [value]="true" formControlName="isNotify" />
        Yes
      </label>
      <label class="radio-inline">
        <input type="radio" [value]="false" formControlName="isNotify" />
        No
      </label>
    </div>
  </div>
</div>
