<ng-container *ngIf="config">
  <div class="bulk-container" *ngIf="config.label">
    <span [class]="'label-content-' + config.class">
      <strong> Bulk Operation: </strong>{{ config.label }}
    </span>

    <ng-container [ngSwitch]="type">
      <div *ngSwitchCase="'projectManager'" class="box">
        <div class="pm-dropdown">
          <ps-ui-select-dropdown
            id="with-search"
            [dropdownItems]="(projectManagers$ | async) || []"
            [field]="'name'"
            [label]="managerSelected.name ? managerSelected.name : config.label"
            (selectedItem)="onManagerSelected($event)"
          ></ps-ui-select-dropdown>
        </div>
        <div class="buttons">
          <button psUiButton (click)="onConfirmation(config)">
            {{ config.confirm || 'Confirm' }}
          </button>
          <button psUiButton color="secondary" (click)="onCancel()">
            Cancel
          </button>
        </div>
      </div>

      <div *ngSwitchCase="'accountManager'" class="box">
        <div class="pm-dropdown">
          <ps-ui-select-dropdown
            id="with-search"
            [dropdownItems]="(projectManagers$ | async) || []"
            [field]="'name'"
            [label]="managerSelected.name ? managerSelected.name : config.label"
            (selectedItem)="onManagerSelected($event)"
          ></ps-ui-select-dropdown>
        </div>
        <div class="buttons">
          <button psUiButton (click)="onConfirmation(config)">
            {{ config.confirm || 'Change PM' }}
          </button>
          <button psUiButton color="secondary" (click)="onCancel()">
            Cancel
          </button>
        </div>
      </div>

      <div *ngSwitchCase="'billingNumber'" class="box">
        <ng-container
          *ngIf="billingRules$ | async as billingRules; else noBillingRules"
        >
          <ps-billing-number-tooltip
            *ngIf="config.key === 'billingNumber' && showBillingNumberRules"
            [rules]="billingRules"
            style="margin-left: -5%"
          ></ps-billing-number-tooltip>

          <div class="billing-number">
            <input
              class="default-input"
              [type]="config.input || 'text'"
              [placeholder]="config.placeholder"
              [(ngModel)]="config.changeValue"
              (focusout)="onValidateBillingCode()"
              [class]="'input-content-' + config.class"
            />
            <small>({{ billingRules?.example }})</small>
          </div>
        </ng-container>

        <ng-template #noBillingRules>
          <div class="no-billing-rules-container">
            <input
              class="default-input"
              [type]="config.input || 'text'"
              [placeholder]="config.placeholder"
              [(ngModel)]="config.changeValue"
              (focusout)="onValidateBillingCode()"
              [class]="'input-content-' + config.class"
            />
          </div>
        </ng-template>

        <div class="buttons">
          <button
            psUiButton
            [disabled]="disableButton"
            (click)="onConfirmation(config)"
          >
            {{ config.confirm || 'Confirm' }}
          </button>
          <button psUiButton color="secondary" (click)="onCancel()">
            Cancel
          </button>
        </div>
      </div>

      <div *ngSwitchCase="'status'" class="box">
        <div class="pm-dropdown">
          <ps-ui-select-dropdown
            id="with-search"
            [dropdownItems]="surveyStatusArray"
            [field]="'status'"
            [label]="selectedStatus || config.label"
            (selectedItem)="onHandleStatusChange($event)"
          ></ps-ui-select-dropdown>
        </div>
        <div class="buttons">
          <button psUiButton (click)="onConfirmation(config)">
            {{ config.confirm || 'Confirm' }}
          </button>
          <button psUiButton color="secondary" (click)="onCancel()">
            Cancel
          </button>
        </div>
      </div>

      <div *ngSwitchCase="'deleteDraft'" class="box">
        <div class="buttons">
          <button psUiButton (click)="onConfirmation(config)">
            {{ config.confirm || 'Confirm' }}
          </button>
          <button psUiButton color="secondary" (click)="onCancel()">
            Cancel
          </button>
        </div>
      </div>

      <div class="ps-1 text-center option" *ngSwitchCase="'supplierSummary'">
        <button
          class="btn btn-primary text-white"
          (click)="onDownloadSupplierReport()"
        >
          Download
        </button>
      </div>

      <div class="ps-1 text-center option" *ngSwitchCase="'combinedReport'">
        <button
          class="btn btn-primary text-white"
          (click)="onDownloadReport(testTransaction.checked)"
        >
          Download
        </button>
        <a
          class="gear"
          (click)="onOpenReportConfigs()"
          *ngIf="showDynamicReportPrefOption"
          ><i class="fa fa-2x fa-gear"></i
        ></a>
        <div class="include-test-checkbox-container">
          <input
            id="includeTests"
            type="checkbox"
            class="form-check-input"
            #testTransaction
          />
          <label for="includeTests" class="include-test-checkbox-label">
            Include Test Transactions</label
          >
        </div>
      </div>

      <div class="box" *ngSwitchDefault>
        <ng-container *ngIf="config.key !== 'combinedReport'">
          <ng-container *ngIf="config.dollarSymbol">
            {{ currencySymbol }}
          </ng-container>
          <ng-container [ngSwitch]="config.key">
            <ng-container *ngSwitchCase="'cpi'">
              <input
                class="default-input"
                [type]="config.input || 'text'"
                [placeholder]="config.placeholder"
                [(ngModel)]="config.changeValue"
                [class]="'input-content-' + config.class"
                psInputDecimalRestriction
                [ngClass]="{
                  'threshold-surpassed': config.changeValue > MAX_CPI_THRESHOLD
                }"
              />
            </ng-container>
            <ng-container *ngSwitchDefault>
              <input
                class="default-input"
                [type]="config.input || 'text'"
                [placeholder]="config.placeholder"
                [(ngModel)]="config.changeValue"
                [class]="'input-content-' + config.class"
                [ngClass]="{
                  'threshold-surpassed':
                    config.key === 'margin' &&
                    config.changeValue &&
                    config.changeValue < AVERAGE_MARGIN
                }"
                #inputBox
              />
            </ng-container>
          </ng-container>
          <ng-container>
            <span class="mx-2">{{ config.key === 'margin' ? ' % ' : '' }}</span>
          </ng-container>
          <div class="buttons">
            <button psUiButton (click)="onConfirmation(config)">
              {{ config.confirm || 'Confirm' }}
            </button>
            <button psUiButton color="secondary" (click)="onCancel()">
              Cancel
            </button>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
