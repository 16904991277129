<div class="container-fluid">
  <div class="row top_search_block">
    <div class="col-md-4">
      <div class="top_header_search">
        <form [formGroup]="form">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search"
              formControlName="search"
            />
            <div class="input-group-addon">
              <span class="input-group-text" id="basic-addon1"
                ><i class="fa fa-search"></i
              ></span>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row">
        <div class="offset-md-2 col-md-6">
          <h1 class="heading text-center">Manage Users</h1>
        </div>
      </div>
      <div class="row">
        <div class="offset-md-6 col-md-6">
          <button
            type="button"
            class="btn btn-square pull-right"
            (click)="onAddUser()"
          >
            + Add User
          </button>
          <button
            type="button"
            class="btn btn-square pull-right"
            [routerLink]="['/dashboard']"
          >
            Back to Dashboard
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <ps-ui-paginated-list
        [length]="total"
        [limit]="pagination.limit"
        (pageChange)="onPageChange($event)"
      >
        <ps-ui-paginated-list-item empty>
          <p description>No Users Available</p>
        </ps-ui-paginated-list-item>

        <ng-container *ngIf="users$ | async as users">
          <div class="users-list" *ngFor="let user of users">
            <ps-ui-paginated-list-item
              [active]="selected?.id === user.id || false"
              (tap)="selected = user"
            >
              <div class="title" title>
                <p>
                  {{ user.email }}
                </p>
                <p>
                  <span>{{ userType(user) + ' User | ' }}</span>
                  {{ formatUserStatus(user.status) }}
                </p>
              </div>
              <div description>
                <div class="description">
                  <p>{{ user.name }}</p>
                  <p>
                    {{ user.isApiUser ? 'API User | ' : '' }}
                    {{ user.accessLevel }}
                  </p>
                </div>
                <p>{{ user.companyName }}</p>
              </div>
              <br />
            </ps-ui-paginated-list-item>
          </div>
        </ng-container>
      </ps-ui-paginated-list>
    </div>
    <div class="col-md-8">
      <ps-manage-user-form
        *ngIf="selected; else noForm"
        [user]="selected!"
        (updated)="getUsers()"
      ></ps-manage-user-form>
      <ng-template #noForm>
        <div class="d_table selected-user">
          <p>
            Choose an existing user or<br />
            click “Add User” to get started
          </p>
        </div>
      </ng-template>
    </div>
  </div>
</div>
