import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from '@purespectrum1/ui/button';
import { DropdownModule } from '@purespectrum1/ui/dropdown';
import { ModalModule } from '@purespectrum1/ui/modal';
import { SliderModule } from '@purespectrum1/ui/slider';
import { SelectDropdownModule } from '@purespectrum1/ui/select-dropdown';
import { SortTableModule } from '../sort-table-directive/sort-table.module';

import { ZeroDashPipeModule } from '../shared/pipes/zero-dash-pipe-module';
import { SearchFilterPipeModule } from '../shared/pipes/filter-pipe.module';

import { AccordionModule } from '@purespectrum1/ui/accordion';

import { HeaderComponent } from './header/header.component';
import { SimpleHeaderComponent } from './simple-header/simple-header.component';

import { CreateSurveyDataComponent } from './header/create-survey-data/create-survey-data.component';
import { EditSurveyFooterComponent } from './edit-survey-footer/edit-survey-footer.component';
import { UserMenuComponent } from './header/user-menu/user-menu.component';
import { ServiceAccountModalComponent } from './header/user-menu/service-account-modal/service-account-modal.component';
import { ConfigEnabledModule } from '../shared/ui/config-enabled/config-enabled.module';
import { AssignProjectManagerTabComponent } from './header/assign-project-manager-tab/assign-project-manager-tab.component';
import { HasRoleModule } from '../shared/ui/has-role/has-role.module';
import { FooterCopyrightComponent } from './footer-copyright/footer-copyright.component';
import { TruncateWordPipeModule } from '../shared/pipes/truncate-word-pipe.module';
import { HeaderServiceAccountComponent } from './header/header-service-account/header-service-account.component';
import { InputDecimalRestrictionModule } from '../shared/ui/input-decimal-restriction/input-decimal-restriction.module';
import { SurveyLaunchModule } from '@purespectrum1/ui/marketplace';
import { AssignBuyerProjectManagerComponent } from './header/assign-buyer-project-manager/assign-buyer-project-manager.component';
import { GoogleTagManagerModule } from '@purespectrum1/ui/tracking';
import { IrGeneratorChatComponent } from './ir-generator/ir-generator-chat/ir-generator-chat.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    DropdownModule,
    RouterModule,
    ModalModule,
    SliderModule,
    SelectDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    SortTableModule,
    AccordionModule,
    ConfigEnabledModule,
    SearchFilterPipeModule,
    HasRoleModule,
    ZeroDashPipeModule,
    InputDecimalRestrictionModule,
    SurveyLaunchModule,
    GoogleTagManagerModule,
    TruncateWordPipeModule,
  ],
  declarations: [
    HeaderComponent,
    SimpleHeaderComponent,
    CreateSurveyDataComponent,
    EditSurveyFooterComponent,
    UserMenuComponent,
    ServiceAccountModalComponent,
    AssignProjectManagerTabComponent,
    FooterCopyrightComponent,
    HeaderServiceAccountComponent,
    AssignBuyerProjectManagerComponent,
    IrGeneratorChatComponent,
  ],
  exports: [
    HeaderComponent,
    SimpleHeaderComponent,
    EditSurveyFooterComponent,
    FooterCopyrightComponent,
  ],
  providers: [DecimalPipe],
})
export class LayoutModule {}
