import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

import {
  BuyerSurveyCountResponse,
  GetCompany,
  GetInvoice,
  RegenerateInvoice,
} from './invoice-operation.interface';
import { Observable } from 'rxjs';
import { BuyerSurveyResponse } from '@purespectrum1/ui/marketplace/shared/interfaces/survey.interface';
@Injectable({
  providedIn: 'root',
})
export class InvoiceOperationService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getBuyerSurvey(
    pageNo: number,
    searchTitle: string,
    billingPo: string,
    startDate: string,
    endDate: string,
    buyerId: string,
    surveyIds: string,
    statuses: number[] = []
  ) {
    let url = `${
      this._baseUrl
    }/buyers/v2/surveys?UI=1&targeting=0&page=${pageNo}&limit=20&buyerId=${buyerId}&surveyIds=${surveyIds}&status=${
      statuses.length ? statuses.toString() : '22,33,44'
    }`;
    if (searchTitle) {
      url = `${url}&searchTitle=${searchTitle}`;
    }
    if (billingPo) {
      url = `${url}&searchPo=${billingPo}`;
    }
    if (startDate) {
      url = `${url}&fromDate=${startDate}`;
    }
    if (endDate) {
      url = `${url}&toDate=${endDate}`;
    }
    return this._http.get<BuyerSurveyResponse[]>(url);
  }
  updateSurveyStatus(
    id: number,
    data: { ps_survey_status: number }
  ): Observable<any> {
    return this._http.put<any>(
      `${this._baseUrl}/buyers/v2/surveys/${id}/status`,
      data
    );
  }
  getInvoiceCompaniesData({ buyers } = { buyers: false }) {
    const query = buyers ? '?isBuyr=true' : '';
    return this._http
      .get<GetCompany>(`${this._baseUrl}/invoice/companies${query}`)
      .pipe(
        map((response) => {
          return response.companies.sort((a, b) => (a.name > b.name ? 1 : -1));
        })
      );
  }
  downloadSTRV3(
    cmp: number,
    survey_id: string | undefined,
    user_type: string,
    includeTestTransactions: boolean = false
  ) {
    return this._http.get<{ fileName: string }>(
      `${this._baseUrl}/reports/surveytrafficV3report?user_id=${cmp}&survey_id=${survey_id}&type=${user_type}&includeTestTransactions=${includeTestTransactions}`
    );
  }
  downloadFile(fileName: string, token: string | null) {
    return `${this._baseUrl}/reports/download-report/${fileName}?access_token=${token}`;
  }

  getBuyerSurveyCount(
    pageNo: number,
    searchTitle: string,
    billingPo: string,
    startDate: string,
    endDate: string,
    buyerId: string,
    surveyIds: string,
    statuses: number[] = []
  ) {
    let url = `${
      this._baseUrl
    }/buyers/v2/surveys/count?UI=1&targeting=0&page=${pageNo}&limit=20&buyerId=${buyerId}&surveyIds=${surveyIds}&status=${
      statuses?.length ? statuses.toString() : '22,33,44'
    }`;
    if (searchTitle) {
      url = `${url}&searchTitle=${searchTitle}`;
    }
    if (billingPo) {
      url = `${url}&searchPo=${billingPo}`;
    }
    if (startDate) {
      url = `${url}&fromDate=${startDate}`;
    }
    if (endDate) {
      url = `${url}&toDate=${endDate}`;
    }
    return this._http.get<BuyerSurveyCountResponse>(url);
  }

  downloadSurveyFinanceReport(
    company_id: number,
    name: string,
    time_period: string
  ) {
    return this._http.get<GetInvoice>(
      `${this._baseUrl}/survey/finance?company_id=${company_id}&company_name=${name}&period=${time_period}`
    );
  }

  downloadSurveyFinance(data: {
    company_id: number;
    company_name: string;
    pdf_type: string;
    period: string;
  }): Observable<ArrayBuffer> {
    if (data.period && parseInt(data.period) < 201708) {
      return this._http.post<ArrayBuffer>(
        `${this._baseUrl}/survey/pdfinvoice/finance/V1`,
        data,
        { responseType: 'arraybuffer' as 'json' }
      );
    } else {
      return this._http.post<ArrayBuffer>(
        `${this._baseUrl}/survey/pdfinvoice/finance`,
        data,
        { responseType: 'arraybuffer' as 'json' }
      );
    }
  }

  downloadSurveyFinanceXlsx(data: {
    company_id: number;
    company_name: string;
    pdf_type: string;
    period: string;
    invoiceType?: string;
  }): Observable<Blob> {
    if (data.period && parseInt(data.period) < 201708) {
      return this._http.post<Blob>(
        `${this._baseUrl}/survey/pdfinvoice/finance/V1`,
        data,
        { responseType: 'blob' as 'json' }
      );
    } else {
      return this._http.post<Blob>(
        `${this._baseUrl}/survey/pdfinvoice/finance`,
        data,
        { responseType: 'blob' as 'json' }
      );
    }
  }

  downloadPoProjectInvoice(data: {
    cmp: number;
    date: string;
    invoiceType: string;
    isStatement: boolean;
    month: string;
    year: string;
  }): Observable<ArrayBuffer> {
    return this._http.post<ArrayBuffer>(
      `${this._baseUrl}/invoice/download`,
      data,
      {
        responseType: 'arraybuffer' as 'json',
      }
    );
  }

  regenerateInvoice(data: {
    cmp: number;
    date: string;
    invoiceType: string;
    month: string;
    year: string;
  }): Observable<RegenerateInvoice> {
    return this._http.post<RegenerateInvoice>(
      `${this._baseUrl}/invoice/regenerate`,
      data
    );
  }

  downloadInvoice(fileName: string, token: string | undefined) {
    return (
      this._baseUrl +
      '/survey/download-invoice/' +
      fileName +
      '?access_token=' +
      token
    );
  }
}
