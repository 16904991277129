export const STATUS_BUTTONS = [
  {
    value: 'Live',
    condition: [33],
  },
  {
    value: 'Paused',
    condition: [22],
  },
  {
    value: 'Closed',
    condition: [33, 22],
  },
  {
    value: 'Invoice',
    condition: [44],
  },
];
