import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';

import { PsidReportService } from '../psid-report.service';

import { EachTransaction } from '../psid-report.interface';
import { notifyMessage } from '../../../../constants/notify-message';
@Component({
  selector: 'ps-profile-data',
  templateUrl: './profile-data.component.html',
  styleUrls: ['./profile-data.component.css'],
})
export class ProfileDataComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public form!: FormGroup;
  public profileReportData: EachTransaction[] = [];

  constructor(
    private _toastr: ToasterService,
    private _fb: FormBuilder,
    private _psidReportService: PsidReportService
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      psid: [{ value: '', disabled: false }],
      resp_id: [{ value: '', disabled: false }],
      transaction_id: [{ value: '', disabled: false }],
      session_id: [{ value: '', disabled: false }],
    });
  }

  getPSIDTransactionReport() {
    const isValid = this._checkEligibility();
    const reportType: string = 'profile';
    if (isValid) {
      const getPsidTransIdreport$ = this._psidReportService
        .getPsidTransIdreport(reportType, this.form.value)
        .subscribe(
          (response) => {
            if (response.length > 0) {
              this.profileReportData = response.map((trans) => {
                trans.cond_code =
                  trans.cond_code instanceof Array
                    ? JSON.stringify(trans.cond_code)
                    : trans.cond_code;
                return trans;
              });
            } else {
              this._toastr.error(
                notifyMessage.errorMessage.PSID_REPORT.NO_DATA_FOUND
              );
            }
          },
          (error) => {
            this._toastr.error(error.error.msg);
          }
        );
      this._subscriptions.add(getPsidTransIdreport$);
    } else {
      this._toastr.error(notifyMessage.errorMessage.PSID_REPORT.INVALID_ENTRY);
    }
  }

  private _checkEligibility() {
    return (
      this.form.value.psid !== '' ||
      this.form.value.resp_id !== '' ||
      this.form.value.transaction_id !== '' ||
      this.form.value.session_id !== ''
    );
  }

  diableInputBox() {
    const criteria = [
      {
        case: () => this.form.value.psid,
        toggleInputBox: () => {
          this.form.get('psid')?.enable();
          this.form.get('resp_id')?.disable();
          this.form.get('transaction_id')?.disable();
          this.form.get('session_id')?.disable();
        },
      },
      {
        case: () => this.form.value.resp_id,
        toggleInputBox: () => {
          this.form.get('psid')?.disable();
          this.form.get('resp_id')?.enable();
          this.form.get('transaction_id')?.disable();
          this.form.get('session_id')?.disable();
        },
      },
      {
        case: () => this.form.value.transaction_id,
        toggleInputBox: () => {
          this.form.get('psid')?.disable();
          this.form.get('resp_id')?.disable();
          this.form.get('transaction_id')?.enable();
          this.form.get('session_id')?.disable();
        },
      },
      {
        case: () => this.form.value.session_id,
        toggleInputBox: () => {
          this.form.get('psid')?.disable();
          this.form.get('resp_id')?.disable();
          this.form.get('transaction_id')?.disable();
          this.form.get('session_id')?.enable();
        },
      },
      {
        case: () => true,
        toggleInputBox: () => {
          this.form.get('psid')?.enable();
          this.form.get('resp_id')?.enable();
          this.form.get('transaction_id')?.enable();
          this.form.get('session_id')?.enable();
        },
      },
    ];
    const matchedCriteria = criteria.find((item) => item.case());
    matchedCriteria?.toggleInputBox();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
