import { BulkEditOption } from './types';

export const OptionsConstants = Object.freeze({
  OPTIONS: [
    {
      label: 'Edit CPI',
      selectedLabel: 'CPI',
      type: 'cpi',
      config: 'overridCmp',
    },
    { label: 'Edit Status', selectedLabel: 'Status', type: 'status' },
    {
      label: 'Edit Margin',
      selectedLabel: 'Margin',
      type: 'margin',
      role: 'service-buyer',
      config: 'enableTargetMargin',
    },
    {
      label: 'Edit Billing Number',
      selectedLabel: 'Billing Number',
      type: 'billingNumber',
    },
    {
      label: 'Edit Project Manager',
      selectedLabel: 'Project Manager',
      type: 'projectManager',
      role: 'buyer',
    },
    {
      label: 'Edit Buyer Project Manager',
      selectedLabel: 'Buyer Project Manager',
      type: 'projectManager',
      role: 'service-buyer',
    },
    {
      label: 'Edit Operator Project Manager',
      selectedLabel: 'Operator Project Manager',
      type: 'accountManager',
      role: 'service-buyer',
    },
    {
      label: 'Delete Draft Surveys',
      selectedLabel: 'Delete Draft Surveys',
      type: 'deleteDraft',
      visibility: ['DRAFTS'],
    },
    {
      label: 'Download Combined Report',
      selectedLabel: 'Combined Report',
      type: 'combinedReport',
    },
    {
      label: 'Download Combined Supplier Summary',
      selectedLabel: 'Combined Supplier Summary',
      type: 'supplierSummary',
    },
  ] as BulkEditOption[],
  FIELDS: {
    cpi: {
      key: 'cpi',
      label: 'CPI',
      input: 'number',
      placeholder: '',
      confirm: 'Confirm',
      class: 'sm',
      dollarSymbol: true,
      changeValue: '',
    },
    billingNumber: {
      key: 'billingNumber',
      label: 'Billing Number',
      placeholder: 'Billing Number',
      class: 'xl',
      changeValue: '',
    },
    accountManager: {
      key: 'accountManager',
      label: 'Operator Manager',
      placeholder: 'Enter Account Manager Name',
      confirm: 'Confirm',
      class: 'lg',
      changeValue: '',
    },
    projectManager: {
      key: 'projectManager',
      label: 'Project Manager',
      placeholder: 'Enter Project Manager Name',
      confirm: 'Confirm',
      class: 'lg',
      changeValue: '',
    },
    status: {
      key: 'status',
      label: 'Status',
      placeholder: 'Enter Status',
      class: 'md',
      changeValue: '',
    },
    margin: {
      key: 'margin',
      label: 'Margin',
      input: 'number',
      placeholder: '',
      class: 'sm',
      changeValue: '',
    },
    combinedReport: {
      key: 'combinedReport',
      label: 'Combined Report',
      placeholder: 'PS#ProjectID#',
      class: 'md',
      changeValue: '',
      confirm: 'Download',
    },
    supplierSummary: {
      key: 'supplierSummary',
      label: 'Combined Supplier Summary',
      placeholder: 'Enter Supplier Summary Value',
      class: 'md',
      changeValue: '',
      confirm: 'Download',
    },
    deleteDraft: {
      key: 'deleteDraft',
      label: 'Delete draft surveys',
      placeholder: '',
      class: 'md',
      changeValue: 'delete',
    },
  },
});

export const BulkEditConstants = Object.freeze({
  BILLING_ID_MAX_LENGTH: 16,
});
