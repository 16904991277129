import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveysTableComponent } from './surveys-table.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from '@purespectrum1/ui/dropdown';
import { RouterModule } from '@angular/router';
import { SortTableModule } from 'src/app/sort-table-directive/sort-table.module';
import { InputDecimalRestrictionModule } from '../input-decimal-restriction/input-decimal-restriction.module';
@NgModule({
  declarations: [SurveysTableComponent],
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    RouterModule,
    SortTableModule,
    InputDecimalRestrictionModule,
  ],
  exports: [SurveysTableComponent],
})
export class SurveyTableModule {}
