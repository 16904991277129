<main class="container">
  <h2 class="header-text">Transaction Testing Tool</h2>
  <div class="row">
    <div class="column">
      <div class="form-section">
        <form
          class="input-form form-input-div"
          (ngSubmit)="createSurveyTrafficTransactions()"
          [formGroup]="form"
        >
          <div class="mb-3">
            <input
              type="text"
              class="form-control input-text"
              id="surveyid"
              placeholder="Survey Id"
              formControlName="surveyid"
            />
          </div>
          <div class="mb-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Status code</th>
                  <th scope="col">Description</th>
                  <th scope="col">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let transReq of transactionsStatusRequest">
                  <td>{{ transReq.resSt }}</td>
                  <td>{{ transReq.resStDes }}</td>
                  <td>
                    <select
                      (change)="
                        onTransactionStatusRequestChange(
                          transReq,
                          $any($event.target).value
                        )
                      "
                    >
                      <option
                        *ngFor="let quantityOption of quantityOptions"
                        [value]="quantityOption"
                      >
                        {{ quantityOption }}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div class="d-grid">
            <button
              class="btn btn-info btn-lg button"
              type="submit"
              psUiButton
              [outline]="true"
              [disabled]="form.invalid || submitted"
            >
              Create Transactions
            </button>
          </div>
          <hr />
          <div class="mb-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Status code</th>
                  <th scope="col">Transaction Id</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let surveyTraffic of surveyTrafficTransactions">
                  <td>{{ surveyTraffic.resSt }}</td>
                  <td>{{ surveyTraffic.trans_id }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
    <div class="column">
      <p></p>
    </div>
  </div>
</main>
