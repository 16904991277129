import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PurepriceFileService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  uploadRateCardFile(
    formData: FormData,
    country: string
  ): Observable<UploadPurepriceFileResponse> {
    return this._http.post<UploadPurepriceFileResponse>(
      `${this._baseUrl}/pureprice/rate-card/upload/${country}`,
      formData
    );
  }

  downloadRateCardFile(country: string, token?: string) {
    return `${this._baseUrl}/pureprice/rate-card/download/${country}?access_token=${token}`;
  }

  uploadFieldTimeMultiplierFile(
    formData: FormData,
    country: string
  ): Observable<UploadPurepriceFileResponse> {
    return this._http.post<UploadPurepriceFileResponse>(
      `${this._baseUrl}/pureprice/field-time-multiplier/upload/${country}`,
      formData
    );
  }

  downloadFieldTimeMultiplierFile(country: string, token?: string) {
    return `${this._baseUrl}/pureprice/field-time-multiplier/download/${country}?access_token=${token}`;
  }

  public uploadPrevalenceMultiplier(formData: FormData) {
    return this._http.post<UploadPurepriceFileResponse>(
      `${this._baseUrl}/pureprice/prevalence-multiplier`,
      formData
    );
  }

  public downloadPrevalenceMultiplier(token: string) {
    return `${this._baseUrl}/pureprice/prevalence-multiplier?access_token=${token}`;
  }
}

export interface UploadPurepriceFileResponse {
  apiStatus: string;
  statusCode: number;
}
