<div class="container daily-statics-form-settiings">
  <p class="transaction-by-status-report-text report-title-spacing">
    Transaction By Status Report
  </p>

  <div class="row">
    <div class="form-group col-xs-12 col-md-6 col-lg-3">
      <label for="surveyRespondentStatus">Survey Respondent Status</label>
      <ps-ui-select-dropdown
        [multi]="true"
        [dropdownItems]="statusOptions"
        [field]="'name'"
        (selectedItems)="selectStatus($event)"
      >
      </ps-ui-select-dropdown>
    </div>
    <div class="form-group col-xs-12 col-md-6 col-lg-3">
      <label for="surveyId">Survey ID</label>
      <input
        class="form-control input-height-align"
        type="text"
        placeholder="Enter Survey ID"
        [ngModel]="surveyId"
        (ngModelChange)="changeSurveyId($event)"
      />
    </div>
    <div class="form-group col-xs-12 col-md-6 col-lg-3">
      <label for="supplier">Supplier</label>
      <ps-ui-select-dropdown
        [dropdownItems]="suppliers"
        [field]="'name'"
        labelNone="Select Supplier"
        [label]="selectedSupplier?.name"
        (selectedItem)="selectSupplier($event)"
      >
      </ps-ui-select-dropdown>
    </div>
    <div class="form-group col-xs-12 col-md-6 col-lg-3">
      <label for="dateRange">Date Range</label>
      <input
        class="form-control date-range input-height-align"
        type="text"
        name="daterangeInput"
        daterangepicker
        [options]="options"
        (selected)="selectedDate($event)"
      />
    </div>
  </div>
</div>

<div class="row mt-12 justify-content-center">
  <div class="hasing-buttons mt-6 col-sm-4">
    <button
      class="btn submit-btn"
      type="submit"
      psUiButton
      color="primary"
      size="large"
      (click)="getReport()"
    >
      Find
    </button>
  </div>
</div>

<div class="row mt-4">
  <div class="col-12-md" *ngIf="transactionByStatusData.length > 0">
    <ps-transaction-by-status-report-table
      [dataList]="transactionByStatusData"
    ></ps-transaction-by-status-report-table>
  </div>
</div>
