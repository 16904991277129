import { Component } from '@angular/core';

@Component({
  selector: 'ps-footer-copyright',
  templateUrl: './footer-copyright.component.html',
  styleUrls: ['./footer-copyright.component.css'],
})
export class FooterCopyrightComponent {
  constructor() {}
}
