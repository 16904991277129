import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { Constants, Tab } from './invoice-operation.constants';
@Component({
  selector: 'ps-invoice-operation',
  templateUrl: './invoice-operation.component.html',
  styleUrls: ['./invoice-operation.component.css'],
})
export class InvoiceOperationComponent implements OnInit, OnDestroy {
  private _subscription: Subscription = new Subscription();
  tabs: Tab[] = Constants.INVOICE_OP_TABS;
  public activeTabIndex: number = 0;
  activeTab: Tab = this.tabs[this.activeTabIndex];

  constructor(
    private _authService: AuthService,
    private _toastr: ToasterService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.tabs = Constants.INVOICE_OP_TABS.filter((currentTab) =>
      currentTab.allowed.includes(this._authService.userType)
    );
    this.activeTabIndex = this.tabs.findIndex((tab) => tab.active) || 0;
    this.activeTab = this.tabs[this.activeTabIndex];
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  checkActiveTab(activeIndex: number) {
    this.activeTab = this.tabs[activeIndex];
    this.activeTabIndex = activeIndex;
  }
}
