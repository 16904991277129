<p class="buyer-sales-report-text report-title-spacing">Buyer Sales Report</p>
<div class="row">
  <div class="col-sm-4 text-center">
    <label class="mb-1">Country</label>
    <ps-ui-select-dropdown
      [dropdownItems]="countries"
      [field]="'name'"
      [enableSearch]="true"
      labelNone="Select Country"
      [label]="selectedCountry?.name"
      (selectedItem)="selectCountry($event)"
    >
    </ps-ui-select-dropdown>
  </div>

  <div class="col-sm-4 text-center">
    <label class="mb-1">Supplier</label>
    <ps-ui-select-dropdown
      [dropdownItems]="suppliers"
      [field]="'name'"
      labelNone="Select Supplier"
      [label]="selectedSupplier?.name"
      (selectedItem)="selectSupplier($event)"
    >
    </ps-ui-select-dropdown>
  </div>

  <div class="col-sm-4 text-center">
    <label class="mb-1">Date Range</label>
    <input
      class="form-control date-range"
      type="text"
      name="daterangeInput"
      [options]="options"
      daterangepicker
      (selected)="selectDate($event)"
    />
  </div>
</div>

<div class="row justify-content-center">
  <div class="col-sm-4 hasing-buttons">
    <button
      type="button"
      psUiButton
      size="large"
      color="primary"
      (click)="getReport()"
    >
      Find
    </button>
  </div>
</div>

<div class="table-responsive table-absolute mt-5" *ngIf="tableData.length">
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th
          scope="col"
          [psSortTable]="tableData"
          data-order=""
          data-name="buyerName"
        >
          Buyer_Name
        </th>
        <th
          scope="col"
          [psSortTable]="tableData"
          data-order=""
          data-name="buyerRevenue"
        >
          Total Revenue
        </th>
        <th
          scope="col"
          [psSortTable]="tableData"
          data-order=""
          data-name="completes"
        >
          Total Completes
        </th>
        <th
          scope="col"
          [psSortTable]="tableData"
          data-order=""
          data-name="validClicks"
        >
          Valid Clicks
        </th>
        <th
          scope="col"
          [psSortTable]="tableData"
          data-order=""
          data-name="totalClicks"
        >
          Total Clicks
        </th>
        <th scope="col" [psSortTable]="tableData" data-order="" data-name="epc">
          Valid EPC
        </th>
        <th
          scope="col"
          [psSortTable]="tableData"
          data-order=""
          data-name="totalEpc"
        >
          Total EPC
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of tableData">
        <td class="text-left">{{ item.buyerName }}</td>
        <td class="text-left">{{ item.buyerRevenue | number : '1.2-2' }}</td>
        <td class="text-left">{{ item.completes || 0 }}</td>
        <td class="text-left">{{ item.validClicks || 0 }}</td>
        <td class="text-left">{{ item.totalClicks || 0 }}</td>
        <td class="text-left">{{ item.epc | number : '1.2-2' }}</td>
        <td class="text-left">{{ item.totalEpc | number : '1.2-2' }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td class="text-left">Total</td>
        <td class="text-left">
          {{ tableTotal.buyerRevenue | number : '1.2-2' }}
        </td>
        <td class="text-left">{{ tableTotal.completes || 0 }}</td>
        <td class="text-left">{{ tableTotal.validClicks || 0 }}</td>
        <td class="text-left">{{ tableTotal.totalClicks || 0 }}</td>
        <td class="text-left">{{ tableTotal.epc | number : '1.2-2' }}</td>
        <td class="text-left">{{ tableTotal.totalEpc | number : '1.2-2' }}</td>
      </tr>
    </tfoot>
  </table>
</div>
