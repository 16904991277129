<ps-ui-modal>
  <h5 psUiModalTitle>Confirm Changing Company</h5>
  <ps-ui-modal-body>
    <div>
      <p>
        Do you want to switch this user from
        <strong
          >{{ userCompany }} <i>{{ userCompanyType }}</i></strong
        >
        to
        <strong
          >{{ newCompany }} <i>{{ newCompanyType }}</i></strong
        >?
      </p>
      <p *ngIf="hasCompanyChange && newCompanyType !== userCompanyType">
        <strong>Note:</strong> The new company selected is a
        <strong>{{ newCompanyType }}</strong
        >. After changing the company, the system will change the User type.
        Click <strong>"Confirm“</strong>to save the change or Cancel.
      </p>
    </div>
  </ps-ui-modal-body>
  <ps-ui-modal-footer>
    <button
      type="button"
      psUiButton
      color="secondary"
      (click)="close('cancel')"
    >
      Cancel
    </button>
    <button type="button" psUiButton (click)="close('save')">Save</button>
  </ps-ui-modal-footer>
</ps-ui-modal>
