import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, timeout } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { environment } from '../../../../environments/environment';
import {
  GetAdjustmentFilesDataResponse,
  UploadFile,
} from './adjustments-reconciliation-service.interface';

@Injectable({
  providedIn: 'root',
})
export class AdjustmentsReconciliationServiceService {
  private readonly _rmfUrl = environment.pureSpectrum.rmfUrl;
  private readonly _buyerExternalIntegrationUrl =
    environment.pureSpectrum.buyerExternalIntegrationUrl;
  private _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient, private _auth: AuthService) {}
  getAdjustmentsFilesData(Id: number) {
    return this._http
      .get<GetAdjustmentFilesDataResponse>(
        `${this._rmfUrl}/v2/rmf/adjustments/users/${Id}`
      )
      .pipe(
        timeout(300000),
        map((response) => ({
          ...response,
          reconciliations: response.reconciliations?.map(
            this._mapReconciliationSummaryTransaction
          ),
          manualFixes: response.manualFixes?.map(
            this._mapReconciliationSummaryTransaction
          ),
        }))
      );
  }
  uploadAdjustementFile(fdata: FormData) {
    return this._http.post<UploadFile>(
      `${this._rmfUrl}/v2/rmf/fileupload`,
      fdata
    );
  }

  getAdjustmentsReport(id: string) {
    return `${this._rmfUrl}/v2/rmf/adjustments/reports/${id}`;
  }

  confirmAdjustment(adjustMentId: string) {
    return this._http.post(
      `${this._rmfUrl}/v2/rmf/confirm/${adjustMentId}`,
      {}
    );
  }
  declinedAdjustment(adjustMentId: string) {
    return this._http.post(
      `${this._rmfUrl}/v2/rmf/decline/${adjustMentId}`,
      {}
    );
  }

  declinedAdjustmentBeacon(adjustMentId: string) {
    return fetch(`${this._rmfUrl}/v2/rmf/decline/${adjustMentId}`, {
      method: 'POST',
      headers: {
        'access-token': this._auth.token!,
      },
      body: JSON.stringify({}),
      keepalive: true,
    });
  }

  private _mapReconciliationSummaryTransaction = (reconciliation: any) => ({
    ...reconciliation,
    summaryTransactions: reconciliation.summary?.transactions || 0,
  });
}
