import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ChurnZeroService } from '@purespectrum1/ui';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { GlobalConstants } from './constants/global';
import { legacyRedirects } from './legacy-redirects';
import { SharedDataService } from './shared/services/shared-data-service';

@Component({
  selector: 'ps-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  userLoggedIn$ = this._authService.loggedIn$;
  authServiceSubscription?: Subscription;
  onImportDecipherPage: boolean = false;
  public isDisplayWheel: boolean = true;

  constructor(
    private _authService: AuthService,
    private _churnZeroService: ChurnZeroService,
    private _router: Router,
    private _sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.authServiceSubscription = this._authService.loggedIn$.subscribe(
      (isLoggedIn) => {
        if (isLoggedIn && this._authService.user) {
          this._churnZeroService.connect(
            this._authService.user,
            this._authService.churnZeroKey
          );
        }
      }
    );
    this._router.events
      .pipe(
        filter(
          (event): event is NavigationStart => event instanceof NavigationStart
        )
      )
      .subscribe((event) => {
        this.onImportDecipherPage =
          GlobalConstants.IMPORT_DECIPHER_LINK_REGEX.test(event.url);
        const matchingRoute = legacyRedirects.find((redirect) =>
          event.url.startsWith(redirect.path)
        );
        if (matchingRoute) {
          this._router.navigateByUrl(
            event.url.replace(matchingRoute.path, matchingRoute.redirectTo)
          );
        }
      });
    this._sharedDataService
      .getIsPageLoaderDisplay()
      .subscribe((display: boolean) => {
        this.isDisplayWheel = display;
      });
  }

  ngOnDestroy(): void {
    this.authServiceSubscription?.unsubscribe();
  }
}
