<p class="stc-report-text report-title-spacing">Survey Transaction Report</p>
<div class="row currency-form-settiings" [formGroup]="form">
  <div class="row currency-form-settiings">
    <div class="col-sm-6 col-md-5 col-lg-3 form-group mb-4 mb-sm-0">
      <input
        formControlName="transaction_id"
        type="text"
        class="form-control p-3"
        placeholder="Enter transaction_id"
        (ngModelChange)="onChange('transaction_id')"
      />
    </div>
    <div class="col-sm-6 col-md-5 col-lg-3 form-group mx-md-3">
      <input
        type="text"
        class="form-control p-3"
        formControlName="session_id"
        placeholder="Enter ps_supplier_session_id"
        (ngModelChange)="onChange('session_id')"
      />
    </div>
  </div>

  <div class="row justify-content-center mt-4">
    <div class="col-12 col-sm-8 col-md-4 findbutton">
      <button
        id="find"
        type="button"
        psUiButton
        color="primary"
        (click)="findTrafficReport()"
        class="submit-btn w-100 p-3 mb-4"
      >
        FIND
      </button>
    </div>
  </div>
</div>

<div class="row table-responsive table-absolute mt-5" *ngIf="isTableVisible">
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th class="text-left">Survey ID</th>
        <th class="text-left">PSID</th>
        <th class="text-left">Transaction ID</th>
        <th class="text-left">Survey Name</th>
        <th class="text-left">Survey Country</th>
        <th class="text-left">Survey Language</th>
        <th class="text-left">Supplier ID</th>
        <th class="text-left">Supplier Name</th>
        <th class="text-left">Supplier Respondent ID</th>
        <th class="text-left">Supplier Sid</th>
        <th class="text-left">Buyer ID</th>
        <th class="text-left">Buyer Name</th>
        <th class="text-left">PS Entry DateTime</th>
        <th class="text-left">Buyer Survey Entry Datetime</th>
        <th class="text-left">Buyer Survey Return Datetime</th>
        <th class="text-left">PS Exit Datetime</th>
        <th class="text-left">Respondent Status Code</th>
        <th class="text-left">Respondent Status Description</th>
        <th class="text-left">Offer ID</th>
        <th class="text-left">Expected Supplier CPI</th>
        <th class="text-left">Expected Buyer CPI</th>
        <th class="text-left">Expected Buyer CPI Fx</th>
        <th class="text-left">CR</th>
        <th class="text-left">ER</th>
        <th class="text-left">Block</th>
        <th class="text-left">Is Block Factor</th>
        <th class="text-left">GPP</th>
        <th class="text-left">GPP LOI</th>
        <th class="text-left">GPP IR</th>
        <th class="text-left">Respondent LOI</th>
        <th class="text-left">Respondent Buyer CPI</th>
        <th class="text-left">Respondent Buyer CPI Fx</th>
        <th class="text-left">Respondent Supplier CPI</th>
        <th class="text-left">Reconciliation Status</th>
        <th class="text-left">Reconciliation Date</th>
        <th class="text-left">Reconciliation Reason</th>
        <th class="text-left">Transaction Original Status</th>
        <th class="text-left">Transaction Original Description</th>
        <th class="text-left">PS_Termination Reason</th>
        <th class="text-left">PS_QuotaFull Reason</th>
        <th class="text-left">Gender</th>
        <th class="text-left">Gender Code</th>
        <th class="text-left">Age</th>
        <th class="text-left">Income</th>
        <th class="text-left">Race</th>
        <th class="text-left">Race Code</th>
        <th class="text-left">BeraRace</th>
        <th class="text-left">BeraRace Code</th>
        <th class="text-left">HispanicOrigin</th>
        <th class="text-left">HispanicOrigin Code</th>
        <th class="text-left">Relationship</th>
        <th class="text-left">Relationship Code</th>
        <th class="text-left">Employment</th>
        <th class="text-left">Employment Code</th>
        <th class="text-left">Education</th>
        <th class="text-left">Education Code</th>
        <th class="text-left">Device</th>
        <th class="text-left">Device Code</th>
        <th class="text-left">Device Type</th>
        <th class="text-left">ZipCode</th>
        <th class="text-left">Children Count</th>
        <th class="text-left">Child1 Age</th>
        <th class="text-left">Child1 Gender</th>
        <th class="text-left">Child2 Age</th>
        <th class="text-left">Child2 Gender</th>
        <th class="text-left">Child3 Age</th>
        <th class="text-left">Child3 Gender</th>
        <th class="text-left">Child4 Age</th>
        <th class="text-left">Child4 Gender</th>
        <th class="text-left">Child5 Age</th>
        <th class="text-left">Child5 Gender</th>
        <th class="text-left">Region</th>
        <th class="text-left">Division</th>
        <th class="text-left">Browser</th>
        <th class="text-left">UserAgent</th>
        <th class="text-left">IP</th>
        <th class="text-left">Type</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of stcReportData">
        <td class="text-left">{{ item.srvId }}</td>
        <td class="text-left">{{ item.PSID }}</td>
        <td class="text-left">{{ item.transId }}</td>
        <td class="text-left">{{ item.pName || 'NA' }}</td>
        <td class="text-left">
          {{ item.locale && item.locale.cnt_nm ? item.locale.cnt_nm : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.locale && item.locale.lang_nm ? item.locale.lang_nm : 'NA' }}
        </td>
        <td class="text-left">{{ item.supId || 'NA' }}</td>
        <td class="text-left">{{ item.supNm || 'NA' }}</td>
        <td class="text-left">{{ item.supResId || 'NA' }}</td>
        <td class="text-left">{{ item.supSid || 'NA' }}</td>
        <td class="text-left">{{ item.bId || 'NA' }}</td>
        <td class="text-left">{{ item.bName || 'NA' }}</td>
        <td class="text-left">{{ item.psEntDt || 'NA' }}</td>
        <td class="text-left">{{ item.bSrvEnt || 'NA' }}</td>
        <td class="text-left">{{ item.bSrvRtnDt || 'NA' }}</td>
        <td class="text-left">{{ item.psExtDt || 'NA' }}</td>
        <td class="text-left">{{ item.resSt || 'NA' }}</td>
        <td class="text-left">{{ item.resStDes || 'NA' }}</td>
        <td class="text-left">
          {{
            item.buyerParams && item.buyerParams.offerId
              ? item.buyerParams.offerId
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.cpi && item.cpi.expectedSupCpi ? item.cpi.expectedSupCpi : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.cpi && item.cpi.expectedByrCpi ? item.cpi.expectedByrCpi : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.cpi && item.cpi.expectedByrCpiFx
              ? item.cpi.expectedByrCpiFx
              : 'NA'
          }}
        </td>

        <td class="text-left">
          {{ item.cpi && item.cpi.cr ? item.cpi.cr : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.cpi && item.cpi.er ? item.cpi.er : 'NA' }}
        </td>
        <td class="text-left">
          {{
            item.blockInfo && item.blockInfo.blockNum
              ? item.blockInfo.blockNum
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.blockInfo && item.blockInfo.isFactorBlock
              ? item.blockInfo.isFactorBlock
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.blockInfo && item.blockInfo.blockNum
              ? item.blockInfo.blockNum - 1
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.blockInfo && item.blockInfo.gpp && item.blockInfo.gpp.LOI
              ? item.blockInfo.gpp.LOI
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.blockInfo && item.blockInfo.gpp && item.blockInfo.gpp.IR
              ? item.blockInfo.gpp.IR
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{ item.srvPerf && item.srvPerf.resLoi ? item.srvPerf.resLoi : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.cpi && item.cpi.byrCpi ? item.cpi.byrCpi : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.cpi && item.cpi.byrCpiFx ? item.cpi.byrCpiFx : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.cpi && item.cpi.supCpi ? item.cpi.supCpi : 'NA' }}
        </td>
        <td class="text-left">
          {{
            item.mfRecon && item.mfRecon.recElgb ? item.mfRecon.recElgb : 'NA'
          }}
        </td>
        <td class="text-left">
          {{ item.mfRecon && item.mfRecon.recDt ? item.mfRecon.recDt : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.mfRecon && item.mfRecon.recRsn ? item.mfRecon.recRsn : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.mfRecon && item.mfRecon.origSt ? item.mfRecon.origSt : 'NA' }}
        </td>
        <td class="text-left">
          {{
            item.mfRecon && item.mfRecon.origStDes
              ? item.mfRecon.origStDes
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.srvPerf && item.srvPerf.psTermRsn
              ? item.srvPerf.psTermRsn
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.srvPerf && item.srvPerf.psQuotaRsn
              ? item.srvPerf.psQuotaRsn
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.gndr ? item.resProf.gndr : 'NA' }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.gndr_cd ? item.resProf.gndr_cd : 'NA'
          }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.age ? item.resProf.age : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.hhi ? item.resProf.hhi : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.race ? item.resProf.race : 'NA' }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.race_cd ? item.resProf.race_cd : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.bera_race
              ? item.resProf.bera_race
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.bera_race_cd
              ? item.resProf.bera_race_cd
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.bera_hispanic
              ? item.resProf.bera_hispanic
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.bera_hispanic_cd
              ? item.resProf.bera_hispanic_cd
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.rln ? item.resProf.rln : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.rln_cd ? item.resProf.rln_cd : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.emp ? item.resProf.emp : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.emp_cd ? item.resProf.emp_cd : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.edu ? item.resProf.edu : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.edu_cd ? item.resProf.edu_cd : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.dvce ? item.resProf.dvce : 'NA' }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.dvce_cd ? item.resProf.dvce_cd : 'NA'
          }}
        </td>
        <td class="text-left">
          {{ item && item.deviceType ? item.deviceType : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.zip_cd ? item.resProf.zip_cd : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.chld ? item.resProf.chld : 'NA' }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.chld1_ag ? item.resProf.chld1_ag : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.chld1_gndr
              ? item.resProf.chld1_gndr
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.chld2_ag ? item.resProf.chld2_ag : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.chld2_gndr
              ? item.resProf.chld2_gndr
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.chld3_ag ? item.resProf.chld3_ag : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.chld3_gndr
              ? item.resProf.chld3_gndr
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.chld4_ag ? item.resProf.chld4_ag : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.chld4_gndr
              ? item.resProf.chld4_gndr
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.chld5_ag ? item.resProf.chld5_ag : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item.resProf && item.resProf.chld5_gndr
              ? item.resProf.chld5_gndr
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.rgn ? item.resProf.rgn : 'NA' }}
        </td>
        <td class="text-left">
          {{ item.resProf && item.resProf.dvsn ? item.resProf.dvsn : 'NA' }}
        </td>
        <td class="text-left">
          {{
            item && item.traceInfo && item.traceInfo.browser
              ? item.traceInfo.browser
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{
            item && item.traceInfo && item.traceInfo.source
              ? item.traceInfo.source
              : 'NA'
          }}
        </td>
        <td class="text-left">
          {{ item && item.host && item.host.ip ? item.host.ip : 'NA' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
