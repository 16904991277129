<div class="row programmatic-form-settings">
  <div class="col-sm-12 col-md-4 form-group">
    <label>Buyer</label>
    <ps-ui-select-dropdown
      [dropdownItems]="buyers"
      [field]="'name'"
      [enableSearch]="true"
      labelNone="Select Buyer"
      [label]="selectedBuyer?.name"
      (selectedItem)="selectBuyer($event)"
    >
    </ps-ui-select-dropdown>
  </div>
  <div
    class="col-sm-12 col-md-8 form-group"
    *ngIf="selectedBuyer; else noBuyerSelected"
  >
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <div
          class="alert alert-light text-center"
          role="alert"
          *ngIf="
            !buyerPDSettings?.suppliers?.length && !newPDSSupplier.editable
          "
        >
          No Programmatic Direct relationships exist for this Buyer.
        </div>
        <table class="table">
          <thead
            *ngIf="
              buyerPDSettings?.suppliers?.length || newPDSSupplier.editable
            "
          >
            <th>Supplier</th>
            <th>PureSpectrum Fee</th>
            <th>Last Updated</th>
            <th>Updated By</th>
            <th>Edit</th>
          </thead>
          <tbody>
            <ng-container *ngIf="buyerPDSettings?.suppliers?.length">
              <tr *ngFor="let supplier of buyerPDSettings.suppliers">
                <td>{{ supplier.name }}</td>
                <td>
                  <div class="currency-wrap">
                    <div class="currency-box">
                      <span class="currency-code">{{ currencySymbol }}</span>
                      <input
                        type="number"
                        name="fee_per_complete"
                        class="form-control input-md text-currency"
                        [(ngModel)]="supplier.fee"
                        [disabled]="!supplier.editable"
                        (keyup)="validateInputFee($event)"
                        psInputDecimalRestriction
                      />
                    </div>
                  </div>
                </td>
                <td>{{ supplier.updatedAt | date : 'h:mm a MM/dd/yyyy' }}</td>
                <td>{{ supplier.updatedBy.name }}</td>
                <td>
                  <div class="add-button-group" *ngIf="supplier.editable">
                    <div
                      class="fa fa-check"
                      (click)="updatePDSettings(supplier)"
                    ></div>
                    <div
                      class="fa fa-times"
                      (click)="resetEditSupplier(supplier)"
                    ></div>
                  </div>
                  <a *ngIf="!supplier.editable" (click)="editSupplier(supplier)"
                    ><i class="fa fa-pencil"></i
                  ></a>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="newPDSSupplier.editable" class="supplier-dropdown">
              <td>
                <ps-ui-select-dropdown
                  [dropdownItems]="suppliers"
                  [field]="'name'"
                  [enableSearch]="true"
                  labelNone="Select Supplier"
                  [label]="newPDSSupplier?.name"
                  (selectedItem)="selectSupplier($event)"
                >
                </ps-ui-select-dropdown>
              </td>
              <td>
                <div class="currency-wrap">
                  <div class="currency-box">
                    <span class="currency-code">{{ currencySymbol }}</span>
                    <input
                      type="number"
                      name="fee_per_complete"
                      class="form-control input-md text-currency"
                      (keyup)="validateInputFee($event)"
                      [(ngModel)]="newPDSSupplier.fee"
                      psInputDecimalRestriction
                    />
                  </div>
                </div>
              </td>
              <td>-</td>
              <td>-</td>
              <td>
                <div class="add-button-group">
                  <div
                    class="fa fa-check"
                    (click)="updatePDSettings(newPDSSupplier)"
                  ></div>
                  <div class="fa fa-times" (click)="resetAddSupplier()"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" *ngIf="!newPDSSupplier.editable">
      <div
        class="col-sm-12 col-md-12 programmatic-setting-buttons text-center mt-6"
      >
        <button
          type="submit"
          psUiButton
          color="primary"
          (click)="newPDSSupplier.editable = true"
        >
          Add New Programmatic Direct Supplier
        </button>
      </div>
    </div>
  </div>
  <ng-template #noBuyerSelected>
    <div class="col-sm-12 col-md-8">
      <div class="alert alert-light text-center" role="alert">
        Select a Buyer to proceed!
      </div>
    </div>
  </ng-template>
</div>
<div class="row">
  <div class="programmatic-setting-buttons col-md-6 mt-6">
    <button
      id="dashboard"
      type="button"
      psUiButton
      color="primary"
      [routerLink]="['/dashboard/']"
    >
      Back to Dashboard
    </button>
  </div>
</div>
