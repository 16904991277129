<form [formGroup]="form">
  <div class="row">
    <div class="col mb-3">
      <label for="parent-question">Select the parent question</label>
      <ps-ui-select-dropdown
        class="parent-selector-dropdown"
        id="parent-question"
        labelNone="Select parent question"
        field="question_description"
        [dropdownItems]="questionList"
        [loading]="isLoading"
        formControlName="parentQuestion"
        [searchByKeys]="['qualification_id']"
        [disabled]="
          isEditingLocalization &&
          classType !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
        "
        [ngClass]="{
          'not-allowed-cursor':
            isEditingLocalization &&
            classType !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
        }"
      ></ps-ui-select-dropdown>
    </div>
  </div>
  <div class="row" *ngIf="enableSegmentationDependentQuestion">
    <div class="col mb-3">
      <label for="answer-option">Select child question</label>
      <ps-ui-select-dropdown
        class="parent-selector-dropdown"
        id="parent-question"
        labelNone="Select child question"
        field="question_description"
        [dropdownItems]="childQuestionList"
        [loading]="isLoading"
        formControlName="childQuestion"
        (selectedItem)="childQuestionSelected($event)"
        [searchByKeys]="['qualification_id']"
        [disabled]="
          isEditingLocalization &&
          classType !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
        "
        [ngClass]="{
          'not-allowed-cursor':
            isEditingLocalization &&
            classType !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
        }"
      ></ps-ui-select-dropdown>
    </div>
  </div>
  <div
    class="row"
    *ngIf="
      enableSegmentationDependentQuestion && form.controls.childQuestion.value
    "
  >
    <div class="col mb-3">
      <label for="answer-option">Select answer option/condition</label>
      <ps-ui-select-dropdown
        class="answer-selector-dropdown"
        id="answer-option"
        labelNone="Select answer option"
        field="name"
        [dropdownItems]="parentData"
        [loading]="isLoading"
        formControlName="answerOption"
        [searchByKeys]="['id']"
        [disabled]="
          isEditingLocalization &&
          classType !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
        "
        [ngClass]="{
          'not-allowed-cursor':
            isEditingLocalization &&
            classType !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
        }"
      ></ps-ui-select-dropdown>
    </div>
  </div>
</form>
