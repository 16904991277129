<ng-container *ngIf="survey$ | async let data">
  <div
    [ngClass]="{ 'widget-content': expanded }"
    [@expand]="expanded ? 'expanded' : 'collapsed'"
  >
    <ng-container *ngIf="expanded">
      <ng-container [ngSwitch]="widget?.key">
        <!-- Traffic Channels Widget -->
        <ps-ui-manage-traffic-channels
          [survey]="data"
          [canAddTrafficChannels]="false"
          *ngSwitchCase="widgets.traffic"
          [isDashboard]="isDasaboard"
          [dashbordTabStatus]="dashbordTabStatus"
          [isMultiCountryWidget]="isMultiCountryWidget"
        ></ps-ui-manage-traffic-channels>

        <!-- Multi Country Widget-->
        <div *ngSwitchCase="widgets.multiCountry">
          <ps-ui-manage-multi-country
            [surveyId]="survey.surveyId"
            [mcUniqueId]="data.mc_unique_id"
          ></ps-ui-manage-multi-country>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
