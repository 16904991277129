'use strict';

export const Constants = Object.freeze({
  MANAGE_BUYER_TABS: [
    {
      title: 'VARIABLE MAPPING',
      active: true,
    },
    {
      title: 'HASHING',
      active: false,
    },
    {
      title: 'DECIPHER',
      active: false,
    },
    {
      title: 'BILLING NUMBER RULES',
      active: false,
    },
  ],
});
