<ps-ui-modal class="viewModal">
  <h5 psUiModalTitle>R/N Credentials</h5>

  <ps-ui-modal-body>
    <div class="form-group">
      <div class="row row-m-t">
        <div class="col-md-3">
          <label class="pull-left">Client Id:</label>
        </div>
        <div class="col-md-8">
          <input
            type="text"
            class="form-control"
            id="rn_credentials_client_id"
            placeholder="clientId"
            name="rn_credentials_client_id"
            [(ngModel)]="clientId"
          />
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row row-m-t">
        <div class="col-md-3">
          <label class="pull-left align-middle">Password:</label>
        </div>
        <div class="col-md-8">
          <input
            type="text"
            class="form-control"
            id="rn_credentials_password"
            placeholder="password"
            name="rn_credentials_password"
            [(ngModel)]="password"
          />
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row row-m-t">
        <div class="col-md-3">
          <label class="pull-left align-middle">User Name:</label>
        </div>
        <div class="col-md-8">
          <input
            type="text"
            class="form-control"
            id="rn_credentials_user_name"
            placeholder="username"
            name="rn_credentials_user_name"
            [(ngModel)]="username"
          />
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
  </ps-ui-modal-body>
  <ps-ui-modal-footer class="padFooter">
    <button type="button" psUiButton color="secondary" (click)="close()">
      Close
    </button>
    <button
      type="button"
      [disabled]="checkAllFilledFields()"
      psUiButton
      (click)="saveRnCredentials()"
    >
      Save changes
    </button>
  </ps-ui-modal-footer>
</ps-ui-modal>
