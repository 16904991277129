import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { AuthService } from '../../../auth/auth.service';
import { BuyerSettingsService } from '../../buyer-settings-service/buyer-settings-service.service';

@Component({
  selector: 'ps-variable-mapping',
  templateUrl: './variable-mapping.component.html',
  styleUrls: ['./variable-mapping.component.css'],
})
export class VariableMappingComponent implements OnInit, OnDestroy {
  @Input()
  form!: FormGroup;
  userCmp!: number;
  userCmpName!: string;
  private _subscription: Subscription = new Subscription();
  constructor(
    private _auth: AuthService,
    private _buyerSettingsService: BuyerSettingsService,
    private _toastr: ToasterService
  ) {}

  ngOnInit(): void {
    if (this._auth.user?.cmp) {
      this.userCmp = this._auth.user.cmp;
    }
    this._getManageBuyerSetting();
  }

  private _getManageBuyerSetting() {
    const buyerSetting$ = this._buyerSettingsService
      .getBuyerSettings(this.userCmp)
      .subscribe(
        (res) => {
          const buyer = res.buyer;
          this.userCmpName = buyer.companyName;
        },
        (err) => {
          const errorMessage = err.error.msg;
          this._toastr.error(errorMessage);
        }
      );
    this._subscription.add(buyerSetting$);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
