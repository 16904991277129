import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocaleShortCodePipe } from './locale-short-code-pipe';

@NgModule({
  declarations: [LocaleShortCodePipe],
  imports: [CommonModule],
  exports: [LocaleShortCodePipe],
})
export class LocaleShortCodePipeModule {}
