import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import { TrafficDensityFileService } from './traffic-density-file.service';
import { EachCountry } from '../../shared/interfaces/common-service.interface';
import { UploadTrafficDensityFileResponse } from './traffic-density-file.interface';
import { notifyMessage } from '../../constants/notify-message';
import { CommonService } from '../../shared/services/common-service/common-service';
import { OperatorSettingsService } from '../operator-settings.service';
import { Constants } from '../operator-settings-constants';
import { saveAs } from 'file-saver';

@Component({
  selector: 'ps-traffic-density-file',
  templateUrl: './traffic-density-file.component.html',
  styleUrls: ['./traffic-density-file.component.css'],
})
export class TrafficDensityFileComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public countries: EachCountry[] = [];
  public selectedCountry?: EachCountry;

  constructor(
    private _toastr: ToasterService,
    private _trafficDensityFileService: TrafficDensityFileService,
    private _commonService: CommonService,
    private _operatorSettingsService: OperatorSettingsService
  ) {}

  ngOnInit(): void {
    this._getCountries();
  }

  private _getCountries() {
    const countries = this._commonService.getCountries().subscribe(
      (countriesData) => {
        this.countries = countriesData;
      },
      (err) => {
        this._toastr.error(err.error.msg);
      }
    );
    this._subscriptions.add(countries);
  }

  selectCountry(country: EachCountry) {
    this.selectedCountry = country;
  }

  uploadTrafficDensityFile(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isValidFile = this._operatorSettingsService.validateFileExtention(
      file,
      Constants.FILES_FILTER_LIST
    );
    if (this.selectedCountry && isValidFile) {
      const fdata = new FormData();
      fdata.append('file', file);
      const ulpoadFile$ = this._trafficDensityFileService
        .uploadTrafficDensityFile(fdata, this.selectedCountry.short_Code)
        .subscribe(
          (_response: UploadTrafficDensityFileResponse) => {
            this._toastr.success(
              `Uploaded traffic density file for ${this.selectedCountry?.name}`
            );
          },
          (err: { error: { message: string } }) => {
            const message =
              err.error.message ?? notifyMessage.errorMessage.SERVER_ERROR;
            this._toastr.error(message);
          }
        );
      this._subscriptions.add(ulpoadFile$);
    }
  }

  downloadCurrentTrafficDensityFile() {
    if (this.selectedCountry) {
      const downloadCurrentFile$ = this._trafficDensityFileService
        .downloadCurrentTrafficDensityFile(this.selectedCountry.short_Code)
        .subscribe(
          (result: Blob) => {
            if (this.selectedCountry) {
              saveAs(
                result,
                `${this.selectedCountry.short_Code}-traffic-density-file`
              );
            }
          },
          ({
            data: {
              message = notifyMessage.errorMessage.FILE_DOWNLOAD_FAILED,
            } = {},
          } = {}) => {
            this._toastr.error(message);
          }
        );
      this._subscriptions.add(downloadCurrentFile$);
    }
  }

  downloadTrafficDensityFileTemplate() {
    const downloadTemplate$ = this._trafficDensityFileService
      .downloadTrafficDensityFileTemplate()
      .subscribe(
        (result: Blob) => {
          saveAs(result, 'traffic-density-file-template');
        },
        ({
          data: {
            message = notifyMessage.errorMessage.FILE_DOWNLOAD_FAILED,
          } = {},
        } = {}) => {
          this._toastr.error(message);
        }
      );
    this._subscriptions.add(downloadTemplate$);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
