<div class="container">
  <div class="row dash-report">
    <p class="text-center report-title-spacing">Supplier Report</p>
    <div class="col-sm-4 text-center">
      <label class="control-label">Country</label>
      <ps-ui-select-dropdown
        [dropdownItems]="countries"
        [field]="'name'"
        labelNone="Select Country"
        [label]="selectedCountry?.name"
        (selectedItem)="selectCountry($event)"
        [enableSearch]="true"
      >
      </ps-ui-select-dropdown>
    </div>
    <div class="col-sm-4 text-center">
      <label>Buyer</label>
      <ps-ui-select-dropdown
        [dropdownItems]="buyers"
        [field]="'name'"
        labelNone="Select Buyer"
        [label]="selectedBuyer?.name"
        (selectedItem)="selectBuyer($event)"
        [enableSearch]="true"
      >
      </ps-ui-select-dropdown>
    </div>
    <div class="col-sm-4 text-center">
      <label class="mb-1">Date Range</label>
      <input
        class="date-range"
        [options]="datapickerOptions"
        type="text"
        name="daterangeInput"
        daterangepicker
        (selected)="selectedDate($event)"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4 text-center"></div>
    <div class="col-sm-4 text-center hasing-buttons">
      <button
        type="submit"
        class="btn submit-btn"
        psUiButton
        color="primary"
        size="large"
        (click)="getReport()"
      >
        Find
      </button>
    </div>
  </div>

  <!-- Stats Table -->
  <div
    class="row table-responsive table-absolute mt-5"
    *ngIf="supplierData.length > 0"
  >
    <table class="table table-striped border mt-1">
      <thead class="not-fixed">
        <tr>
          <th
            scope="col"
            [psSortTable]="supplierData"
            data-order=""
            data-name="supplierName"
          >
            Supplier_Name
          </th>
          <th
            scope="col"
            [psSortTable]="supplierData"
            data-order=""
            data-name="supplierRevenue"
          >
            Total Cost
          </th>
          <th
            scope="col"
            [psSortTable]="supplierData"
            data-order=""
            data-name="completes"
          >
            Total Completes
          </th>

          <th
            scope="col"
            [psSortTable]="supplierData"
            data-order=""
            data-name="validClicks"
          >
            Valid Clicks
          </th>
          <th
            scope="col"
            [psSortTable]="supplierData"
            data-order=""
            data-name="totalClicks"
          >
            Total Clicks
          </th>
          <th
            scope="col"
            [psSortTable]="supplierData"
            data-order=""
            data-name="epc"
          >
            Valid EPC
          </th>
          <th
            scope="col"
            [psSortTable]="supplierData"
            data-order=""
            data-name="totalEpc"
          >
            Total EPC
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of supplierData">
          <td>{{ data.supplierName }}</td>
          <td>{{ data.supplierRevenue | number : '1.2-2' }}</td>
          <td>{{ data.completes || 0 }}</td>
          <td>{{ data.validClicks || 0 }}</td>
          <td>{{ data.totalClicks }}</td>
          <td>{{ data.epc || 0 | number : '1.2-2' }}</td>
          <td>{{ data.totalEpc || 0 | number : '1.2-2' }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="text-left">Total</td>
          <td class="text-left">
            {{ tableTotal.supplierRevenue | number : '1.2-2' }}
          </td>
          <td class="text-left">{{ tableTotal.completes || 0 }}</td>
          <td class="text-left">{{ tableTotal.validClicks || 0 }}</td>
          <td class="text-left">{{ tableTotal.totalClicks || 0 }}</td>
          <td class="text-left">{{ tableTotal.epc | number : '1.2-2' }}</td>
          <td class="text-left">
            {{ tableTotal.totalEpc | number : '1.2-2' }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
