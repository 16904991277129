<div class="tooltip-country position-relative d-inline opacity-1">
  <ng-container
    *ngIf="cell?.template"
    [ngTemplateOutlet]="cell.template"
  ></ng-container>
  <div class="tooltip-country-text position-absolute">
    <ng-container
      *ngFor="let childSurvey of mappedMcSvpChildSurveys; let i = index"
    >
      <div
        class="d-flex justify-content-between width-150"
        [ngClass]="{ 'hovered-row': hoveredId === childSurvey.surveyId }"
        (mouseenter)="selectHoveredRow(childSurvey.surveyId)"
        (mouseleave)="selectHoveredRow(null)"
      >
        <div class="d-flex justify-content-between">
          <ng-container *ngFor="let column of columns">
            <div
              class="mx-1"
              *ngIf="column.show"
              class="{{ column.classList }}"
              [ngClass]="{ 'hovered-row': hoveredId === childSurvey.surveyId }"
              (click)="tap.emit(childSurvey.surveyId)"
            >
              <ng-container
                [ngTemplateOutlet]="column.template"
                [ngTemplateOutletContext]="{
                  $implicit: childSurvey,
                  index: i
                }"
              ></ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="hoveredId">
    <div class="position-relative">
      <ng-container
        *ngIf="hoveredRow?.template"
        [ngTemplateOutlet]="hoveredRow.template"
      ></ng-container>
    </div>
  </ng-container>
</div>
