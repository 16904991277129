<ps-ui-modal class="modal-tos">
  <h5 psUiModalTitle>PureSpectrum Terms Of Service</h5>

  <ps-ui-modal-body>
    <div>
      <div class="row">
        <div class="padding-5">
          <div id="terms" class="scroller-tos">
            <div class="img-container-block">
              <img
                src="../../../assets/img/logo-login-ps.png"
                alt="PureSpectrum"
              />
            </div>
            <p align="justify">
              <strong
                >Thank you for using PureSpectrum. We have built the best
                Marketplace for researchers and panels to deliver great surveys
                to people interested in providing their honest opinion. In order
                to keep the marketplace a secure and healthy environment, we
                have outlined the terms to use our Services (Services being
                anything provided by the platform or PureSpectrum team).</strong
              >
            </p>
            <p align="center" class="my-4">
              <strong
                >By logging in to your Account or using the Services, you agree
                to the Terms of Use.</strong
              >
            </p>
            <ol class="terms-list">
              <li>
                <p>
                  <strong>Costs and Payment Terms</strong> &ndash; All Costs
                  associated with your use of Services will be sent in an
                  invoice via email to your preferred billing e-mail address, or
                  the Primary Account Contact. Costs do not include sales taxes
                  VAT, GST or other value added tax, and you are responsible for
                  any taxes associated with use of the Services. Services will
                  be rendered pursuant to a Statement of Work (“SOW”) or other
                  writing, and unless otherwise stated in the SOW will contain
                  the Default Pricing Assumptions listed below, and pricing may
                  change if those Default Pricing Assumptions are not met.
                  Payment for Services is due within 30 days of the invoice
                  date. Unless otherwise agreed to between You and PureSpectrum,
                  You must notify PureSpectrum before the end of the calendar
                  month after the month in which a Survey Response was collected
                  of any cost-related disputes or Reconciliations and provide
                  PureSpectrum with a reason for each Reconciled Survey
                  response. Payment may be sent by ACH, check or wire transfer.
                  PureSpectrum will net any Costs incurred by the Customer
                  against revenues generated by the Customer in the Account.
                  PureSpectrum reserves the right to suspend access to an
                  Account with past due invoices. Your obligation to pay
                  PureSpectrum is not dependent upon your receiving payment from
                  any third party. Late payments will bear interest at the rate
                  of 1.5% per month from the invoice date or the maximum rate
                  allowed by applicable law. If PureSpectrum must initiate legal
                  action to collect payment, Client will pay all legal fees and
                  costs of collection. PureSpectrum may, at its discretion, stop
                  work on a project and/or withhold its work product from
                  Client, on any open project that PureSpectrum is performing
                  for Client, if Client's payment for the project that is the
                  subject of these Standard Terms is late.
                </p>
              </li>
              <li>
                <p><strong>Account Management</strong></p>
                <ol type="a">
                  <li>
                    <p>
                      Access to PureSpectrum is provided by issuing an Account
                      with User profiles for your team members. An Account and
                      User Profiles are for you and your employees use only and
                      are not transferrable. If an employee with a User profile
                      on the Account leaves the Customer’s employment, it is the
                      Customer’s responsibility to provide written notice to
                      PureSpectrum to deactivate the terminated employee’s User
                      profile. Customer must supply all equipment software,
                      servers, and internet access necessary to access the
                      Services. Employees of PureSpectrum have access to your
                      Account to support your Account, to respond to complaints
                      of unacceptable use and to improve overall platform
                      services
                    </p>
                  </li>
                  <li>
                    <p>
                      Customer will not, directly or indirectly: (i) reverse
                      engineer, decompile, disassemble or otherwise attempt to
                      discover the source code, object code or underlying
                      structure, ideas, know-how or algorithms relevant to the
                      Services or any software, documentation or data related to
                      the Services (collectively, the “Software”); (ii) modify,
                      translate, or create derivative works based on the
                      Services or any Software (except to the extent expressly
                      permitted in this Agreement); (iii) use the Services or
                      any Software for timesharing or service bureau purposes or
                      otherwise for the benefit of a third party; or (iv) remove
                      any proprietary notices or labels from any PureSpectrum
                      products, services, or materials.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p><strong>Acceptable Uses</strong></p>
                <ol type="a">
                  <li>
                    <p>
                      Customer represents, warrants and covenants to
                      PureSpectrum that Customer will, and will cause its
                      employees and/or agents to, abide by and comply with any
                      and all applicable international, national, federal, state
                      and/or local laws, rules, regulations, requirements,
                      statutes, codes, decisions and opinions, including but not
                      limited to the Gramm-Leach-Bliley Act, the Children's
                      Online Privacy Protection Act, the Health Insurance
                      Portability and Accountability Act, the FCC Client Privacy
                      Rule, the European Union Directive on Data Protection,
                      GDPR, The China Personal Information Protection Law
                      (PIPL), the California Consumer Protection Act of 2018,
                      the Virginia Consumer Data Protection Act, the Connecticut
                      Data Privacy Act, The Utah Consumer Privacy Act, The
                      Colorado Privacy Act, The Indiana Consumer Data protection
                      Ac, The Iowa Act Relating to Consumer Data Protection, The
                      Nevada Health Privacy Law, the Oregon Consumer Privacy
                      Act, The Tennessee Information Protection Act, The Texas
                      Information Protection Act, The Washington My Health My
                      Data Act, and the ethical codes of the Insights
                      Association (www.insightsassociation.org) and ESOMAR
                      (www.esomar.org), and any amendments thereto (collectively
                      “Applicable Laws and Codes”), in connection with
                      Customer’s collection, storage, maintenance, processing,
                      use and/or disclosure of personal data, only as permitted
                      by this Agreement. Each of PureSpectrum and Customer will
                      provide all assistance required for each to meet its
                      obligations under all Applicable Laws and Codes.
                    </p>
                  </li>
                  <li>
                    <p>
                      Customer agrees that its survey projects will not (i) use
                      survey farms, bots, or scrapers for any purpose, (ii) ask
                      or answer questions using vulgar, abusive, or
                      inappropriate language or content, (iii) fundraise,
                      advertise, or make solicitations, (iv) ask or answer
                      leading questions that are intended to coach, guide, or
                      bias specific responses, (v) use copyrights, trademarks,
                      or any other intellectual property without proper
                      ownership or license, (vi) collect, solicit, download,
                      submit, or otherwise save Personally Identifiable Data or
                      Sensitive Personally Identifiable Data (as such terms are
                      defined in the Protecting American’s Data From Foreign
                      Adversaries Act of 2024 (“PADFAA”), on or through the
                      PureSpectrum Marketplace, (vii) ask or answer questions
                      that do not conform with industry guidelines or Applicable
                      Laws and Codes, (viii) ask or answer questions about
                      sensitive financial information, including but not limited
                      to, bank account and credit cards, and government-issued
                      identification numbers.
                    </p>
                  </li>
                  <li>
                    <p>
                      Ask minors to answer questions without obtaining parental
                      consent, as defined under Applicable Laws.
                    </p>
                  </li>
                  <li>
                    <p>
                      Solicit Respondents to take fraudulent or otherwise
                      illegal surveys.
                    </p>
                  </li>
                  <li>
                    <p>
                      e. Customer represents and warrants that it is not a
                      person residing in a Foreign Adversary Country as
                      specified in section 4872(d)(2) of title 10, United States
                      Code, or an entity controlled by a Foreign Adversary
                      Country (“CBFA”), as defined in PADFAA, or the parent of,
                      subsidiary of or an affiliate of a CBFA. Customer agrees
                      that for so long as it has access to the PureSpectrum
                      Marketplace or receives services from PureSpectrum, it
                      will immediately advise PureSpectrum if it becomes or
                      believes that it will become a CBFA.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>Ethical Standards</strong> &ndash; PureSpectrum and
                  Customer must respect the customs, rules, and practices that
                  apply to our industry, some of which are described below
                </p>
                <ol type="a">
                  <li>
                    <p>
                      Protection of Human Rights. PureSpectrum and Customer
                      shall protect the human rights of its employees and comply
                      with all Applicable Laws, as well as all ethical standards
                      applicable in the countries in which they operate.
                    </p>
                  </li>
                  <li>
                    <p>
                      Corruption and Bribery. PureSpectrum and Customer shall
                      not engage in corruption, extortion, embezzlement, money
                      laundering, or bribery.
                    </p>
                  </li>
                  <li>
                    <p>
                      Child Labor and Forced Labor. PureSpectrum and Customer
                      shall prohibit and refrain from child labor within their
                      organizations or other forms of trafficking in humans,
                      forced labor or modern slavery.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p><strong>Data Ownership & Privacy</strong></p>
                <ol type="a">
                  <li>
                    <p>
                      The Customer will own the questionnaire and all data
                      collected in the Survey. Customer is solely responsible
                      for all survey content. Customer agrees that all survey
                      content will comply with all Applicable Laws and Codes,
                      including but not limited to laws and regulations
                      regarding the interviewing of children. Customer will be
                      held solely liable for any violation of this Section.
                      PureSpectrum reserves the right to remove any survey
                      content that violates these Terms of Use. The Client may
                      not solicit or recruit Panel Members or Non-Panel Members,
                      to become members of a Client’s Panel, mailing list, or
                      any other Panels or send any commercial advertising to the
                      Panel Members or Non-Panel Members, available through the
                      System, unless otherwise agreed in writing between
                      PureSpectrum and the Client. Customer may under no
                      circumstances use the Services or any data obtained via
                      the Services to identify a survey respondent or to recruit
                      any survey respondent into a panel or database managed by
                      Customer unless explicitly agreed to with PureSpectrum in
                      writing in advance.
                    </p>
                  </li>
                  <li>
                    <p>
                      Any data collected in the PureSpectrum platform will be
                      used only to provide the Services. PureSpectrum will own
                      all screener data and other data collected by PureSpectrum
                      from respondents in order to provide the Services,
                      including but not limited to the PureSpectrum ID assigned
                      to each respondent.
                    </p>
                  </li>
                  <li>
                    <p>
                      Customer represents and warrants that it has enacted
                      administrative, physical, and technical security measures,
                      privacy and data security policies, procedures, and
                      controls which reflect industry best standards and
                      practices with regard to the collection, storage, transfer
                      and sale of Personal Data. Customer agrees that it will
                      update its policies as necessary to remain compliant with
                      industry best standards and practices and Applicable Laws
                      and Codes, as may be necessary from time to time.
                    </p>
                  </li>
                  <li>
                    <p>
                      For the control and processing of Personal Data of
                      European Union Data Subjects in accordance with the
                      General Data Protection Regulation: (i) Customer
                      acknowledges and agrees that they are Controllers
                      regarding the control and processing of Survey respondents
                      purchased on the PureSpectrum Marketplace, (ii) Customer
                      acknowledges and agrees that they are Controllers
                      regarding the control and processing of Personal Data
                      during the Survey when off the PureSpectrum Marketplace,
                      and (iii) PureSpectrum acts as Processor for any
                      processing activity conducted at the direction of the
                      Customer on the PureSpectrum Marketplace.
                    </p>
                  </li>
                  <li>
                    <p>
                      For transfers of any Personal Data to any country outside
                      EEA without an adequate level of data protection, Customer
                      and PureSpectrum agree to apply, and accept to be bound
                      by, the EU Standard Contractual Clauses applicable for the
                      contemplated transfer of Personal Data, i.e. the EU
                      Standard Contractual Clauses pursuant to Commission
                      Implementing Decision (EU) 2021/914 of 4 June 2021 on
                      standard contractual clauses for the transfer of personal
                      data to third countries pursuant to Regulation (EU)
                      2016/679 of the European Parliament and of the Council (or
                      subsequent contractual clauses as may be adopted by the
                      European Commission from time to time).
                    </p>
                  </li>
                  <li>
                    <p>
                      PureSpectrum shall have the right to utilize Customer’s
                      name and trademarks for marketing and PR purposes to
                      identify Customer as a user of the Services, including
                      without limitation placing Customer’s company logo on the
                      PureSpectrum’s website and other marketing materials.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>Termination of Services</strong> &ndash; Customer may
                  terminate use of the Services at any time, unless otherwise
                  defined by a Subscription Agreement. Any outstanding costs or
                  fees will be collected under the normal billing cycle.
                </p>
              </li>
              <li>
                <p>
                  <strong>Written Notice</strong> &ndash; All Notices must be
                  sent to
                  <a href="mailto:support@purespectrum.com"
                    >support@purespectrum.com</a
                  >
                  or to c/o PureSpectrum 31416 Agoura Rd., #210, Westlake
                  Village, CA 91361 USA.
                </p>
              </li>
              <li>
                <p>
                  <strong>Warranties</strong> &ndash; Customer acknowledges that
                  the Services provided by PureSpectrum operates using the
                  Internet, and includes factors outside the control of
                  PureSpectrum. PureSpectrum will take commercially reasonable
                  efforts to provide the Services without disruption. The
                  Customer will not hold PureSpectrum responsible for Survey
                  downtime or any other inability to provide Services hereunder
                  caused by or arising out of factors or causes outside of
                  PureSpectrum's control. PureSpectrum expressly disclaims all
                  warranties, expressed and implied. PURESPECTRUM DOES NOT
                  WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE;
                  NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
                  OBTAINED FROM USE OF THE SERVICES. EXCEPT AS EXPRESSLY SET
                  FORTH IN THIS SECTION, THE SERVICES ARE PROVIDED “AS IS” AND
                  PURESPECTRUM DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                  INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                  NON-INFRINGEMENT.
                </p>
              </li>
              <li>
                <p>
                  <strong>Mutual Indemnification</strong> &ndash; Each Party
                  agrees to indemnify the other and its officers, agents,
                  employees and subcontractors against all claims against them
                  for damages caused by (i) the manufacture, distribution, sale
                  or use of any products or services supplied by the Party or
                  its agents for the purpose of any Service contemplated by
                  these Terms of Use and from all costs and expenses (including
                  reasonable attorney’s fees) and lawsuits which may be brought
                  against the other Party, its officers, agents, employees, and
                  subcontractors on account of such damages; (ii) negligence or
                  willful acts or omissions on the part of a Party, its
                  officers, agents, employees and subcontractors; and (iii) the
                  breach by the a Party of any of its covenants or obligations
                  under this Agreement, except to the extent any such claim
                  arises from or is caused by the other Party’s negligence or
                  willful acts or omissions.
                </p>
              </li>
              <li>
                <p>
                  <strong>Limitation of Liability</strong> &ndash;
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY, PURESPECTRUM AND ITS
                  SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL EQUIPMENT AND
                  TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES, REPRESENTATIVES,
                  CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR LIABLE
                  WITH RESPECT TO ANY SUBJECT MATTER OF THESE TERMS OF USE OR
                  THE SERVCIES RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE,
                  STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR
                  INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF
                  DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR
                  TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY INDIRECT,
                  EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C)
                  FOR ANY MATTER BEYOND PURESPECTRUM’S REASONABLE CONTROL; OR
                  (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED
                  WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY CUSTOMER TO
                  PURESPECTRUM FOR THE SERVICES UNDER THESE TERMS OF USE IN THE
                  TWELVE (12) MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE
                  LIABILITY, IN EACH CASE, WHETHER OR NOT CUSTOMER HAS BEEN
                  ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
              </li>
              <li>
                <p>
                  <strong>Non-Circumvention</strong> &ndash; The Customer hereby
                  agrees that, for the Term and for a period of one (1) year
                  from the end of the Term or other termination of this
                  Agreement, the Customer will not enter into any agreement,
                  transaction or arrangement with any of the entities (including
                  their agents, principals and affiliates) which the
                  PureSpectrum has introduced, directly or indirectly, to the
                  Customer pursuant to a direct meeting, telephone call or as a
                  survey respondent source, regardless of whether a transaction
                  is consummated with such prospective entities.
                </p>
              </li>
              <li>
                <p>
                  <strong>Audit Rights</strong> &ndash; PureSpectrum has the
                  right to audit Customer and Customer's affiliates that provide
                  sourcing for Customer's panel(s). PureSpectrum shall announce
                  its intent to audit Customer by providing at a minimum ten
                  (10) business days' notice. A scope document along with a
                  request for deliverables will be provided at the time of
                  notification of an audit. If the documentation requested
                  cannot be removed from Customer's premises, Customer will
                  permit PureSpectrum access to their site(s). Customer will
                  make necessary employees or contractors available for
                  interviews in person or on the phone during the time frame of
                  the audit. Audits will be at PureSpectrum's sole expense,
                  except where the audit reveals material noncompliance with
                  this Agreement or Applicable Laws and Codes, in which case the
                  cost will be borne by Customer.
                </p>
              </li>
              <li>
                <p>
                  <strong>Support</strong> &ndash; PureSpectrum will provide
                  Technical Support to Customer via both telephone and
                  electronic mail on weekdays during the hours of 4:00 am
                  through 7:00 pm Pacific time, with the exclusion of US Federal
                  Holidays (“Support Hours”). Customer may initiate a helpdesk
                  ticket during Support Hours by calling
                  <a href="tel:8778657873">877-865-PURE</a> or any time by
                  emailing
                  <a href="mailto:support@purespectrum.com"
                    >support@purespectrum.com</a
                  >. PureSpectrum will use commercially reasonable efforts to
                  respond to all Support tickets within one (1) hour if during
                  Support Hours, and one (1) business day if outside those
                  hours.
                </p>
              </li>
              <li>
                <p>
                  <strong>Governing Law</strong> &ndash; These Terms of Use
                  shall be governed by and construed in accordance with the laws
                  of the United States of America and the state of California,
                  and all disputes related to these Terms of Use or Customers
                  use of the Services shall be brought in the state or federal
                  courts located in Los Angeles County, California. In any
                  action, proceeding to enforce the these Terms of Use, or to
                  collect any monies due hereunder, the prevailing party will be
                  entitled to all of its reasonable attorney’s fees, expenses
                  and costs, including but not limited to expert witness fees
                  and costs of investigation.
                </p>
              </li>
            </ol>
            <p><strong>Definitions</strong></p>
            <ul class="list-unstyled">
              <li>
                <em>Account</em> &ndash; your access to the PureSpectrum
                platform (Services), inclusive of all User Profiles
              </li>
              <li>
                <em>Costs</em> &ndash; all charges incurred using the Services,
                including but not limited to CPI, service charges and
                subscription fees.
              </li>
              <li><em>CPI</em> &ndash; Cost Per Interview.</li>
              <li>
                <em>Customer</em> &ndash; the entity that has been given access
                to the Account and PureSpectrum Services, also defined by “you”
                or “your”.
              </li>
              <li>
                <em>Default Pricing Assumptions</em>
                <ul>
                  <li>
                    Multi-country: Timings and costs assume the questionnaire is
                    at least 80% consistent between markets.
                  </li>
                  <li>
                    Translations: Translation costs are based on an estimate of
                    words for the quoted LOI. If the actual word count
                    significantly exceeds the amount assumed within the quote,
                    PureSpectrum reserves the right to inform the client and
                    revise timings and costs accordingly.
                  </li>
                  <li>
                    Data Checking: As standard, PureSpectrum will perform data
                    accuracy and respondent quality checks. Should Client have
                    any specific requirements, please specify these at the start
                    of the project. Additional time and/or costs may apply.
                  </li>
                  <li>
                    Data Tables: Costs assume that each set of Excel data tables
                    has a banner length of up to 20 columns. Additional costs
                    may apply if a larger banner is required.
                  </li>
                </ul>
              </li>
              <li>
                <em>Personal Data</em> &ndash; Sometimes referred to as
                Personally Identifiable Information or PII or Personal
                Information) refers to any information relating to an identified
                or identifiable natural person (i.e., a private individual as
                opposed to a corporate or other comparable entity). An
                identifiable person is someone who can be identified directly or
                indirectly, in particular by reference to an identification
                number or the person’s physical, physiological, mental,
                economic, cultural or social characteristics.
              </li>
              <li>
                <em>Primary Account Contact</em> &ndash; an employee of the
                Customer assigned to the Account.
              </li>
              <li>
                <em>Reconciliations</em> &ndash; Survey Responses rejected by
                Customer for legitimate reasons related to quality such as
                speeding (where the Survey is completed in less than 1/3rd of
                the median length of interview within the Survey project),
                straight lining (where all or substantially all answer choices
                are the same), logical inconsistencies, open-end gibberish
                answers (for the avoidance of doubt single word responses are
                not gibberish).
              </li>
              <li>
                <em>Subscription Agreement</em> &ndash; A separate agreement
                outlining additional services and use.
              </li>
              <li>
                <em>Survey</em> &ndash; The data collection instrument linked to
                the PureSpectrum platform and Customer Account.
              </li>
              <li>
                <em>Survey Response</em> &ndash; An individual completed
                response within a Survey.
              </li>
              <li>
                <em>User Profile</em> &ndash; employee-specific access within a
                Customer Account.
              </li>
              <li><em>VAT</em> &ndash; value-added tax.</li>
            </ul>
            <p>Revised 06-04-24</p>
            <p>
              Click the link below to review the PureSpectrum Privacy Policy.
            </p>
            <p>
              <a
                href="https://purespectrum.com/privacy-policy/"
                target="_blank"
              >
                <span>
                  <span style="font-family: 'OpenSans-Light', serif">
                    <span psWatchVisibility (visible)="this.scrolled = true">
                      <b>PRIVACY POLICY</b>
                    </span>
                  </span>
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div>
      <label class="opt-check">
        <input type="checkbox" [(ngModel)]="optIn" [checked]="optIn" /> Yes
        Please!
        <span>
          I would like to receive updates about products & services, promotions,
          special offers, news, and events from PureSpectrum.
        </span></label
      >
    </div>
  </ps-ui-modal-body>
  <ps-ui-modal-footer>
    <div class="text-center tos-button">
      <button class="status-decline" routerLink="/login">Decline</button>
      <button
        *ngIf="!scrolled"
        [disabled]="!scrolled"
        class="status-accept disable"
      >
        Accept
      </button>
      <button *ngIf="scrolled" class="status-accept" (click)="onStatusAccept()">
        Accept
      </button>
      <div *ngIf="!scrolled" class="accept-button-div" id="spanAccept">
        <span>
          Scroll to the bottom of the Terms of Service to click the 'Accept'
          button.
        </span>
      </div>
    </div>
  </ps-ui-modal-footer>
</ps-ui-modal>
