import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

import { Subscription, Subject, merge } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { ModalService } from '@purespectrum1/ui';
import { AuthService } from '../../auth/auth.service';
import { UserService } from '../../operator/user-service/user.service';
import { ProjectManagerSearchModalComponent } from '../project-manager-search-modal/project-manager-search-modal.component';
import { USER_DASHBOARD_VIEW_OBJECT } from '../dashboard-constants';
import { UserProjectManagers } from '../types';

@Component({
  selector: 'ps-survey-search',
  templateUrl: './survey-search.component.html',
  styleUrls: ['./survey-search.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveySearchComponent implements OnInit, OnDestroy {
  @Input()
  public value: string = '';

  @Input()
  public filter: UserFilter = {
    current: false,
    search: false,
  };

  @Input()
  public offset = false;

  @Output()
  public search = new EventEmitter<string>();

  @Output()
  public view = new EventEmitter<SearchView>();

  private _subscription: Subscription = new Subscription();

  private _searchCommand: Subject<string> = new Subject();
  private _viewCommand: Subject<'buyer' | 'serviceOperator'> = new Subject();

  constructor(
    private _auth: AuthService,
    private _userService: UserService,
    private _modal: ModalService
  ) {}

  public ngOnInit(): void {
    const _search = this._searchCommand
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((text) => {
        this.value = text;
        this.search.emit(text);
      });

    this._subscription.add(_search);

    const buyer$ = this._viewCommand.pipe(
      filter((type) => type === 'buyer'),
      switchMap(() => this._userService.getAllBuyerUser(this._auth.user!.cmp)),
      map((users) => ({ type: 'buyer', users }))
    );

    const operator$ = this._viewCommand.pipe(
      filter((type) => type === 'serviceOperator'),
      switchMap(() => this._userService.getAllOperatorUser()),
      map((response) => ({ type: 'serviceOperator', users: response.users }))
    );

    const modal$ = merge(buyer$, operator$).pipe(
      switchMap((data) => {
        const modalRef = this._modal.open(ProjectManagerSearchModalComponent, {
          data: {
            projectManagers: data.users,
            isOperator: data.type === 'serviceOperator',
          },
          width: '730px',
          height: '350px',
        });

        return modalRef.onClose$.pipe(
          filter((value) => value && value.msg === 'confirm'),
          map((value) => ({
            managers: {
              [data.type]: value.projectManagers.id,
            },
            option:
              data.type === 'buyer'
                ? USER_DASHBOARD_VIEW_OBJECT.VIEW_BY_PM
                : USER_DASHBOARD_VIEW_OBJECT.VIEW_BY_OPERATOR,
            filter: {
              current: false,
              search: true,
              unassigned: false,
              projectManagerId: value.projectManagers.id,
            },
          }))
        );
      })
    );

    const _modal = modal$.subscribe((event) => this.view.emit(event));
    this._subscription.add(_modal);
  }

  public onSearchKeyUp(searchTextValue: string): void {
    this._searchCommand.next(searchTextValue);
  }

  public filterSearchServiceOperator(): void {
    if (this.filter.search) {
      this._clearServiceOperatorFilter();
      return;
    }

    this._viewCommand.next('serviceOperator');
  }

  public filterSearchBuyerSurvey(): void {
    if (this.filter.search) {
      this._clearBuyerFilter();
      return;
    }

    this._viewCommand.next('buyer');
  }

  public filterCurrentServiceOperator(): void {
    if (this.filter.current) {
      this._clearServiceOperatorFilter();
      return;
    }

    this.view.emit({
      managers: {
        serviceOperator: this._auth.user?.id,
      },
      option: USER_DASHBOARD_VIEW_OBJECT.VIEW_MY_PROJECTS_OPERATOR,
      filter: {
        current: true,
        search: false,
        unassigned: false,
        projectManagerId: this._auth.user?.id,
      },
    });
  }

  public filterCurrentBuyerSurvey(): void {
    if (this.filter.current) {
      this._clearBuyerFilter();
      return;
    }

    this.view.emit({
      managers: {
        buyer: this._auth.user?.id,
      },
      option: USER_DASHBOARD_VIEW_OBJECT.VIEW_MY_PROJECTS_BUYER,
      filter: {
        current: true,
        search: false,
        unassigned: false,
        projectManagerId: this._auth.user?.id,
      },
    });
  }

  public filterUnassignedBuyerSurvey(): void {
    if (this.filter.unassigned) {
      this._clearBuyerFilter();
      return;
    }

    this.view.emit({
      managers: {
        buyer: -1,
      },
      option: USER_DASHBOARD_VIEW_OBJECT.VIEW_UNASSIGNED,
      filter: {
        current: false,
        search: false,
        unassigned: true,
        projectManagerId: -1,
      },
    });
  }

  private _clearBuyerFilter() {
    this.view.emit({
      managers: {
        buyer: undefined,
      },
      option: USER_DASHBOARD_VIEW_OBJECT.NONE,
      filter: {
        current: false,
        search: false,
        unassigned: false,
        projectManagerId: undefined,
      },
    });
  }

  private _clearServiceOperatorFilter() {
    this.view.emit({
      managers: {
        serviceOperator: undefined,
      },
      option: USER_DASHBOARD_VIEW_OBJECT.NONE,
      filter: {
        current: false,
        search: false,
        unassigned: false,
        projectManagerId: undefined,
      },
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}

export type SearchView = {
  managers: UserProjectManagers;
  option: number;
  filter: UserFilter;
};

export interface UserFilter {
  current: boolean;
  search: boolean;
  unassigned?: boolean;
  projectManagerId?: number;
}
