import { Component, OnInit } from '@angular/core';
import { ModalService } from '@purespectrum1/ui';
import { ServiceAccountModalComponent } from '../user-menu/service-account-modal/service-account-modal.component';
import { AuthService } from '../../../auth/auth.service';
import { ServiceBuyer } from '../../../auth/auth.interface';

@Component({
  selector: 'ps-header-service-account',
  templateUrl: './header-service-account.component.html',
  styleUrls: ['./header-service-account.component.css'],
})
export class HeaderServiceAccountComponent implements OnInit {
  public serviceBuyer?: ServiceBuyer;

  constructor(
    private _authService: AuthService,
    private _modal: ModalService
  ) {}

  ngOnInit(): void {
    this._authService.loggedInAsServiceBuyer$.subscribe(() => {
      this.serviceBuyer = this._authService.serviceBuyer;
    });
  }

  selectBuyer() {
    this._modal.open(ServiceAccountModalComponent, {});
  }
}
