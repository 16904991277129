import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/services/common-service/common-service';
import { EachCountry } from '../../shared/interfaces/create-surveys.interface';
import { OperatorSettingsService } from '../operator-settings.service';
import { Constants } from '../operator-settings-constants';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { PurepriceFileService } from './pureprice-file.service';
import { notifyMessage } from '../../constants/notify-message';
import { iif, Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'ps-pureprice-settings',
  templateUrl: './pureprice-settings.component.html',
  styleUrls: ['./pureprice-settings.component.css'],
})
export class PurepriceSettingsComponent implements OnInit {
  private _subscriptions: Subscription = new Subscription();
  countries: Array<EachCountry> = [];
  selectedCountry?: EachCountry;
  private _rateCardTemplateInfo = Constants.PUREPRICE_RATECARD;
  private _fieldTimeMultiplierTemplateInfo =
    Constants.PUREPRICE_FIELDTIME_MULTIPLIER;
  private _prevalenceMultiplier = Constants.PREVALENCE_MULTIPLIER;
  public uploadType = FileType;
  constructor(
    private _toastr: ToasterService,
    private _commonService: CommonService,
    private _operatorSettingsService: OperatorSettingsService,
    private _purepriceFileService: PurepriceFileService,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    this._subscriptions.add(
      this._commonService.getCountries().subscribe((response) => {
        this.countries = response;
      })
    );
  }

  selectCountry(event: EachCountry) {
    this.selectedCountry = event;
  }

  uploadFile(event: Event, type: FileType) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isValidFile = this._operatorSettingsService.validateFileExtention(
      file,
      Constants.FILES_FILTER_LIST
    );
    if (!isValidFile) {
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    let uploadFile$;
    const observer = {
      next: () => this._toastr.success('Uploaded successfully'),
      error: (err: { error: { message: string } }) => {
        const message =
          err.error.message ?? notifyMessage.errorMessage.SERVER_ERROR;
        this._toastr.error(message);
      },
    };
    if (type === FileType.PrevalenceMultiplier) {
      uploadFile$ =
        this._purepriceFileService.uploadPrevalenceMultiplier(formData);
    } else if (this.selectedCountry) {
      const shortCode = this.selectedCountry.short_Code;
      uploadFile$ = iif(
        () => type === FileType.RateCard,
        this._purepriceFileService.uploadRateCardFile(formData, shortCode),
        this._purepriceFileService.uploadFieldTimeMultiplierFile(
          formData,
          shortCode
        )
      );
    } else {
      return;
    }
    uploadFile$.subscribe(observer);
  }

  downloadCurrentFile(type: FileType) {
    if (!this.selectedCountry && type !== FileType.PrevalenceMultiplier) {
      // if country not selected don't make the request
      return;
    }
    switch (type) {
      case FileType.PrevalenceMultiplier:
        window.location.assign(
          this._purepriceFileService.downloadPrevalenceMultiplier(
            this._auth.token!
          )
        );
        break;

      case FileType.RateCard:
        window.location.assign(
          this._purepriceFileService.downloadRateCardFile(
            this.selectedCountry!.short_Code,
            this._auth.token
          )
        );
        break;

      case FileType.FieldTimeMultiplier:
        window.location.assign(
          this._purepriceFileService.downloadFieldTimeMultiplierFile(
            this.selectedCountry!.short_Code,
            this._auth.token
          )
        );
        break;
    }
  }

  downloadRateCardTemplateFile() {
    window.open(this._rateCardTemplateInfo.templatePath, '_blank');
  }

  downloadFieldTimeMultiplierTemplateFile() {
    window.open(this._fieldTimeMultiplierTemplateInfo.templatePath, '_blank');
  }

  downloadPrevalenceMultiplierTemplate() {
    window.open(this._prevalenceMultiplier.templatePath, '_blank');
  }
}

export enum FileType {
  RateCard,
  FieldTimeMultiplier,
  PrevalenceMultiplier,
}
