<div class="row manual-rate-card-div">
  <div>
    <label class="upload-file-label"> Upload a file(.csv) </label>
    <br />
    <input
      #fileUpload
      type="file"
      accept=".csv"
      class="upload-file-btn-margin"
      (change)="uploadCsvFile($event)"
    />
    <br />
    <a
      target="_blank"
      class="download-template-button btn btn-default btn-margin text-decoration-none"
      href="{{ manualRatecardTemplate }}"
    >
      <span class="download-template-span">Download Template</span>
      <em class="fa fa-question-circle"></em>
    </a>
    <br />
    <button
      [disabled]="manualRateCardUploaded === false"
      class="download-template-button btn btn-default btn-margin-top"
      (click)="downloadLatestUpload()"
    >
      Download Latest Upload
    </button>
    <br />
    <span class="or-span">or</span><br />
    <button
      [disabled]="csvUploaded === false"
      class="download-template-button btn btn-default btn-margin-bottom"
    >
      Clear Rate Card
    </button>
  </div>
  <div class="manual-rate-card-buttons">
    <div class="pull-left">
      <a class="btn btn-primary submit-btn" href="/">
        <span>Back to Dashboard</span>
      </a>
    </div>
    <div class="pull-left">
      <button
        class="btn btn-primary submit-btn"
        [disabled]="manualRateCardUploaded === false"
        (click)="dectivateManualRateCard()"
      >
        Deactivate
      </button>
    </div>

    <div class="pull-right">
      <button class="btn btn-primary submit-btn" (click)="saveRateCard()">
        Save
      </button>
    </div>
    <div class="pull-right">
      <button
        class="btn btn-primary submit-btn"
        [disabled]="manualRateCardUploaded === true"
        (click)="emitCancelManualRateCard()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
