import { Component, OnDestroy, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { ModalRef } from '@purespectrum1/ui/modal';

// Constants
import { Constants } from '../dashboard-constants';

// Services
import { AuthService } from '../../auth/auth.service';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { DashboardPreferenceService } from '../../shared/services/dashboard-preference/dashboard-preference.service';

// Interfaces
import { DashboardPreference } from '../../shared/services/buyer-api/survey.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ps-dashboard-preference-modal',
  templateUrl: './dashboard-preference-modal.component.html',
  styleUrls: ['./dashboard-preference-modal.component.css'],
})
export class DashboardPreferenceModalComponent implements OnInit, OnDestroy {
  private _subscription: Subscription = new Subscription();
  public searchText: string = '';
  public selectedColumnsCount: number = 0;
  public dashboardPreferences: DashboardPreference[] = [];
  public _isServiceBuyerLoggedIn: boolean = false;

  constructor(
    private _modalRef: ModalRef<DashboardPreferenceModalComponent, string>,
    private _auth: AuthService,
    private _toastr: ToasterService,
    private _dashboardPreferenceService: DashboardPreferenceService
  ) {}

  ngOnInit(): void {
    const userId = this._auth.user?.id || 0;
    const authServiceSubscription =
      this._auth.loggedInAsServiceBuyer$.subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this._isServiceBuyerLoggedIn = true;
        }
      });
    this._subscription.add(authServiceSubscription);
    this._dashboardPreferenceService
      .getDashboardPreference(userId, this._isServiceBuyerLoggedIn)
      .subscribe(
        (response: DashboardPreference[]) => {
          this.dashboardPreferences = response.filter((option) => {
            if (this._auth.userType === 'buyer') {
              if (option.key === 'trafficChannels') {
                return this._auth.buyerConfig!.enableTrafficChannels;
              }
              if (option.key === 'multiCountry') {
                return this._auth.buyerConfig!.enableMultiCountry;
              }
              if (option.key === 'healthCheck') {
                return this._auth.buyerConfig!.enableHealthMetrics;
              }
            }
            return true;
          });
          this._countSelectedColumns();
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
  }

  selectPreferance(dashboardPreference: DashboardPreference, value: boolean) {
    dashboardPreference.selected = !value;
    if (!value) {
      // Always set both to true when the user selects a new KPI
      dashboardPreference.mobile = true;
      dashboardPreference.desktop = true;
    }
    this._countSelectedColumns();
  }

  selectDesktopPreferance(
    dashboardPreference: DashboardPreference,
    value: boolean
  ) {
    dashboardPreference.desktop = !value;
  }

  selectMobilePreferance(
    dashboardPreference: DashboardPreference,
    value: boolean
  ) {
    dashboardPreference.mobile = !value;
  }

  private _countSelectedColumns() {
    this.selectedColumnsCount = this.dashboardPreferences.reduce(
      (counter, { selected }) => {
        if (selected === true) {
          counter += 1;
        }
        return counter;
      },
      0
    );
  }

  close(msg?: string) {
    if (msg === 'save') {
      const userId = this._auth.user?.id || 0;
      this.dashboardPreferences = this.dashboardPreferences.map(
        (dashboardPreference, index) => {
          dashboardPreference.order = index;
          return dashboardPreference;
        }
      );
      this._dashboardPreferenceService
        .saveDashboardPreference(userId, this.dashboardPreferences)
        .subscribe(
          () => {
            this._modalRef.close();
            this._toastr.success(
              Constants.MODAL_MESSAGE.SAVE_DASHBOARD_PREFERENCES
            );
            window.location.reload();
          },
          (error) => {
            this._toastr.error(error.error.msg);
          }
        );
    } else {
      this._modalRef.close();
    }
  }

  dropColumns(event: CdkDragDrop<DashboardPreference[]>) {
    moveItemInArray(
      this.dashboardPreferences,
      event.previousIndex,
      event.currentIndex
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
