<div class="form">
  <div class="row form-group">
    <div class="col-sm-6">
      <label>COUNTRIES</label>
      <ps-ui-select-dropdown
        [dropdownItems]="countries"
        [field]="'name'"
        labelNone="Select Country"
        [label]="selectedCountry?.name"
        (selectedItem)="selectCountry($event)"
      >
      </ps-ui-select-dropdown>
    </div>

    <div class="col-sm-6">
      <label>QUALIFICATIONS</label>
      <ps-ui-select-dropdown
        [dropdownItems]="censusQualifications"
        [field]="'desc'"
        labelNone="Select Qualification"
        [label]="selectedQualification?.desc"
        [loading]="false"
        [disabled]="!censusQualifications.length"
        (selectedItem)="getCensusDetails($event)"
      >
      </ps-ui-select-dropdown>
    </div>
  </div>
  <hr class="mt20" />
  <div class="row form-group">
    <div class="custom_space" *ngIf="selectedQualification">
      <div
        class="row border_bottom"
        *ngIf="isRangeType(selectedQualification.qualification_code)"
      >
        <div class="col-md-4 col-md-offset-1">
          <input
            type="text"
            class="text-line text-center"
            number
            placeholder="From"
            [(ngModel)]="newMin"
          />
          <span class="mrg15">to</span>
          <input
            type="text"
            class="text-line text-center"
            number
            placeholder="To"
            [(ngModel)]="newMax"
          />
        </div>
        <div class="col-sm-5 col-md-5 add_btn">
          <input
            type="number"
            class="labelFeasInput marginMobilelabelFeasInput marginfeasoption1Input"
            limit-to="3"
            number
            [(ngModel)]="newRepValue"
            placeholder="Census Percentage"
          />
          <button color="primary" (click)="addCensus()">
            <i class="fa fa-plus" aria-hidden="true"></i> Click to Add
          </button>
        </div>
      </div>

      <div
        *ngFor="
          let conditionCode of selectedQualification.condition_codes;
          let i = index
        "
      >
        <div
          class="row mb-4"
          *ngIf="
            isRangeType(selectedQualification.qualification_code);
            else noRangeType
          "
        >
          <div class="col-md-4 col-md-offset-1">
            <input
              type="text"
              class="text-line text-center"
              number
              placeholder="From"
              [(ngModel)]="conditionCode.min"
            />
            <span class="mrg15" span>to</span>
            <input
              type="text"
              class="text-line text-center"
              number
              placeholder="To"
              [(ngModel)]="conditionCode.max"
            />
          </div>
          <div class="col-md-4 add_btn">
            <input
              type="number"
              class=""
              limit-to="3"
              number
              [(ngModel)]="conditionCode.rep_value"
              placeholder="Census Percentage"
            />
            <button class="btn btn-link btn-default" (click)="removeCensus(i)">
              <span class="glyphicon glyphicon-remove"></span>
            </button>
          </div>
        </div>
        <ng-template #noRangeType>
          <div class="row mt35">
            <label class="col-md-4 col-md-offset-1">
              {{ conditionCode.name }}
            </label>
            <div class="col-md-4 col-md-offset-1 ml-2">
              <input
                type="number"
                class=""
                limit-to="3"
                number
                [(ngModel)]="conditionCode.rep_value"
                placeholder="Census Percentage"
              />
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="row mt-5">
      <div class="backbutton hasing-buttons col-md-6 mt-6">
        <button
          id="dashboard"
          type="button"
          psUiButton
          size="large"
          color="primary"
          [routerLink]="['/dashboard/']"
        >
          Back to Dashboard
        </button>
      </div>
      <div
        class="hasing-buttons mt-6 col-md-6 text_right"
        *ngIf="selectedQualification?.hasOwnProperty('qualification_code')"
      >
        <button type="submit" color="primary" (click)="saveCensus()">
          Save</button
        ><br />
      </div>
    </div>
  </div>
</div>
