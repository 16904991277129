import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';

import { DashboardPreference } from '../buyer-api/survey.interface';

@Injectable({
  providedIn: 'root',
})
export class DashboardPreferenceService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  getDashboardPreference(userId: number, isServiceBuyer: boolean) {
    return this._http.get<DashboardPreference[]>(
      `${this._baseUrl}/users/${userId}/dashboard-preference?isServiceBuyer=${isServiceBuyer}`
    );
  }

  saveDashboardPreference(userId: number, preferences: DashboardPreference[]) {
    return this._http.post<DashboardPreference[]>(
      `${this._baseUrl}/users/${userId}/dashboard-preference`,
      preferences
    );
  }
}
