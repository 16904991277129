'use strict';
import { environment } from '../../../environments/environment';
export const Constants = Object.freeze({
  MANAGE_SUPPLIER_TABS: [
    {
      title: 'SUPPLIER ACCESS',
      active: true,
    },
    {
      title: 'SUPPLIER BLENDS',
      active: false,
    },
  ],
  UPDATE_SUPPLIER_TRIGGER_SOURCE: {
    LOCK_BLEND: 'LOCK BLEND TOGGLE',
    SAVE_SUPPLIER_BLEND: 'SAVE SUPPLIER BLEND BUTTON',
  },
  DEFAULT_SUB_SUPPLIER_ALLOCATION: 100,
  INVALID_SUPPLIER_GROUP_NAME:
    'Supplier Group Name should contain only letters, numbers, symbols (-, _, @) and should not exceed 28 chars',
  FUSION_CMP_ID: environment.FUSION_CMP_ID,
  SUPPLIER_GROUP_TEMPLATE_SAVED: 'Supplier group template saved successfully',
  SUPPLIER_GROUP_TEMPLATE_NOT_SAVED:
    'Error: Unable to save this group template. Please refresh and try again.',
  SUPPLIER_BLANK_ALLOCATION:
    'Supplier Blends saved with Suppliers or Supplier Groups having Blank Allocation values will have whatever remaining Allocations evenly distributed among those Suppliers or Supplier Groups',
  SUPPLIER_ALLOCATION_LESS_THAN_HUNDRED:
    'Supplier Blends should have allocations with at least 100% allocation.  If not, there should be Suppliers or Supplier Groups with Allocations left Blank to evenly distribute the remaining Allocation across.',
  SUPPLIER_BOCK_UNBLOCK_WARNING:
    'If Blocking or Unblocking any Suppliers please be sure to review and update existing Supplier Blends and Groups to be sure they account for these changes.',
  INVALID_ALLOCATION_FOR_LOCKING_BLEND:
    'should have at least 100% Allocation set and no Suppliers or Supplier Groups with Allocations left Blank.',
  LOCK_ALL_BLENDS_SUCCESS:
    'All Supplier Blends have been Locked. This means only Suppliers or Supplier Groups with defined allocation values will be included when Blends are applied to Surveys.',
  LOCK_SINGLE_BLEND_SUCCESS:
    'Supplier Blend has been Locked. This means only Suppliers or Supplier Groups with defined allocation values will be included when Blend is applied to Surveys.',
  UNLOCK_ALL_BLENDS_SUCCESS: 'All Supplier Blends have been Unlocked.',
  UNLOCK_SINGLE_BLEND_SUCCESS: 'Supplier Blend has been Unocked.',
  BLOCKED_COUNTRY_BLEND_UNAVAILABLE:
    'This Supplier Blend is no longer available due to the assigned Country being Blocked on your account.  If you feel this is a mistake, please contact your Account Manager.',
});
