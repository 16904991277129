<form class="form-horizontal-select-dropdown" [formGroup]="form">
  <div class="row">
    <div class="col-md-6">
      <label>Country <span class="required">*</span></label>
      <ps-ui-select-dropdown
        class="basic-size-of-select-dropdown"
        [dropdownItems]="countries"
        [field]="'name'"
        [label]="form.controls.selectedCountry.value?.name"
        (selectedItem)="selectCountry($event)"
        [ngClass]="{
          'not-allowed-cursor': isEditLocalizationMode
        }"
        [disabled]="isEditLocalizationMode"
      >
      </ps-ui-select-dropdown>
    </div>
    <div class="col-md-6">
      <label>Language <span class="required">*</span></label>
      <ps-ui-select-dropdown
        class="basic-size-of-select-dropdown"
        [dropdownItems]="languages"
        [field]="'name'"
        [label]="form.controls.selectedLanguage.value?.name"
        (selectedItem)="selectLanguage($event)"
        [ngClass]="{
          'not-allowed-cursor': isEditLocalizationMode
        }"
        [disabled]="isEditLocalizationMode"
      >
      </ps-ui-select-dropdown>
    </div>
  </div>
  <div
    class="row"
    *ngIf="
      userType === 'operator' &&
      class !== CONSTANTS.MAPPED_CLASS_TYPE.DATA_QUALITY
    "
  >
    <div class="col-md-6">
      <label>Dynamic Profiler</label>
      <ps-ui-select-dropdown
        class="basic-size-of-select-dropdown"
        [dropdownItems]="dpOptions"
        [field]="'value'"
        [enableSearch]="false"
        [label]="form.controls.enableDP.value?.value"
        (selectedItem)="form.controls.enableDP.setValue($event)"
      ></ps-ui-select-dropdown>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Dynamic Profiler Priority</label>
        <input
          type="number"
          class="form-control input-text"
          name="priority"
          placeholder="0"
          formControlName="dpPriority"
        />
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="
      userType === 'operator' &&
      class === CONSTANTS.MAPPED_CLASS_TYPE.DATA_QUALITY
    "
  >
    <div class="col">
      <div class="row mb-3">
        <div class="col-4">
          <label for="with-dataquality">Active</label>
        </div>
        <div class="col">
          <ps-ui-slider
            id="with-dataquality"
            class="float-start"
            [selected]="form.controls.enableDataquality.value"
            (selectedChange)="form.controls.enableDataquality.setValue($event)"
          >
          </ps-ui-slider>
        </div>
        <div class="col-6" *ngIf="isEligibleForOETerminationVisible">
          <label for="term-oe-eligibility">Eligible for OE Termination</label>
          <ps-ui-slider
            id="term-oe-eligibility"
            class="float-end"
            [selected]="form.controls.eligibleForOETermination.value"
            (selectedChange)="
              form.controls.eligibleForOETermination.setValue($event)
            "
          >
          </ps-ui-slider>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="class === CONSTANTS.MAPPED_CLASS_TYPE.SEGMENTATION">
    <div class="col">
      <div class="row mb-3">
        <div class="col-6">
          <label for="child-question">Segmentation Child Question</label>
          <ps-ui-slider
            id="child-question"
            class="float-end"
            [selected]="form.controls.enableSegmentation.value"
            (selectedChange)="form.controls.enableSegmentation.setValue($event)"
            [disabled]="isEditLocalizationMode"
          >
          </ps-ui-slider>
        </div>
      </div>
      <div class="row mb-3" *ngIf="form.controls.enableSegmentation.value">
        <div class="col-6">
          <label for="dependent-question"
            >Segmentation Dependent Question</label
          >
          <ps-ui-slider
            id="dependent-question"
            class="float-end"
            [selected]="form.controls.enableSegmentationDependentQuestion.value"
            (selectedChange)="
              form.controls.enableSegmentationDependentQuestion.setValue(
                $event
              );
              callGetChildQuestionsMethod()
            "
            [disabled]="isEditLocalizationMode"
          >
          </ps-ui-slider>
        </div>
      </div>
      <ps-ui-accordion [closeOthers]="false" [arrowToggleOnly]="false">
        <ps-ui-accordion-section
          [isExpanded]="form.controls.enableSegmentation.value"
          class="dependency-accordion"
        >
          <ng-template psUiAccordionContent>
            <ps-segmentation
              [isEditingLocalization]="isEditLocalizationMode"
              [classType]="class"
              [clonedLocalization]="clonedLocalization"
              [enableSegmentationDependentQuestion]="
                form.controls.enableSegmentationDependentQuestion.value
              "
            >
            </ps-segmentation>
          </ng-template>
        </ps-ui-accordion-section>
      </ps-ui-accordion>
    </div>
  </div>
  <div
    class="row"
    *ngIf="
      class !== CONSTANTS.MAPPED_CLASS_TYPE.DATA_QUALITY &&
      class !== CONSTANTS.MAPPED_CLASS_TYPE.SEGMENTATION
    "
  >
    <div class="col">
      <div class="row mb-3">
        <div
          class="col-6"
          *ngIf="userType === 'operator' && isPrivacyPolicyQuestion"
        >
          <label for="non-gdpr">NON-GDPR</label>
          <ps-ui-slider
            id="non-gdpr"
            class="float-end"
            [selected]="form.controls.enableNonGdpr.value"
            (selectedChange)="form.controls.enableNonGdpr.setValue($event)"
          >
          </ps-ui-slider>
        </div>
        <div class="col-6">
          <label for="with-dependency">Question With Dependency</label>
          <ps-ui-slider
            id="with-dependency"
            class="float-end"
            [selected]="form.controls.enableDependency.value"
            (selectedChange)="form.controls.enableDependency.setValue($event)"
            [disabled]="isEditLocalizationMode"
          >
          </ps-ui-slider>
        </div>
      </div>
      <ps-ui-accordion [closeOthers]="false" [arrowToggleOnly]="false">
        <ps-ui-accordion-section
          [isExpanded]="form.controls.enableDependency.value"
          class="dependency-accordion"
        >
          <ng-template psUiAccordionContent>
            <ps-question-with-dependency
              [isEditLocalizationMode]="isEditLocalizationMode"
              [class]="class"
              [clonedLocalization]="clonedLocalization"
            >
            </ps-question-with-dependency>
          </ng-template>
        </ps-ui-accordion-section>
      </ps-ui-accordion>
    </div>
  </div>
  <div class="row mar-top-15">
    <div class="col-md-6">
      <label>Question Screener Text <span class="required">*</span></label>
      <button
        type="button"
        class="btn btn-default submit-btn btn-common add-resp-id-btn add-resp-id-button"
        [disabled]="isDisableRespIdBtn"
        [ngClass]="{
          'not-allowed-cursor': isDisableRespIdBtn
        }"
        (click)="clickRespId()"
        *ngIf="
          isUserHasQuestionLibraryAccess &&
          !isEditLocalizationMode &&
          questionType !== CONSTANTS.QUESTION_TYPE.CALCULATION
        "
      >
        + Add respId
      </button>
      <button
        type="button"
        class="btn btn-default btn-common add-calc-txt-btn add-calc-txt-lcl-btn"
        [disabled]="isDisableRespIdBtn"
        [ngClass]="{
          'not-allowed-cursor': isDisableRespIdBtn
        }"
        (click)="clickAddCalcQuesTxt()"
        *ngIf="
          isUserHasQuestionLibraryAccess &&
          !isEditLocalizationMode &&
          userType === 'operator' &&
          questionType === CONSTANTS.QUESTION_TYPE.CALCULATION
        "
      >
        + Add Calculation
      </button>
      <input
        type="text"
        name="text"
        [attr.disabled]="isEditLocalizationMode ? true : null"
        [ngClass]="{
          'not-allowed-cursor': isEditLocalizationMode
        }"
        class="form-control input-text"
        placeholder="I work in the %%respId%% industry"
        formControlName="screenerText"
        (input)="changeScrennerText()"
      />
      <small *ngIf="isPrivacyPolicyQuestion"
        >(Note:- add p_policy as a placeholder for your privacy policy
        text)</small
      >
    </div>
    <div class="col-md-6 padding-placeholder">
      <div class="form-group">
        <label>Placeholder </label>
        <div class="dropdown dropdown-scroll">
          <input
            type="text"
            name="placeholder"
            class="form-control"
            autocomplete="off"
            placeholder="Pick One"
            formControlName="placeholder"
            (click)="togglePlaceHolderDropdown()"
          />
          <ul
            id="PlaceholderDropdown"
            [class.show]="isPlaceHolderDropdownShow"
            class="dropdown-menu"
          >
            <li *ngFor="let placeholder of placeholders">
              <a (click)="addTypedPlaceholder(placeholder)">
                {{ placeholder }}
              </a>
            </li>
          </ul>
        </div>
        <div
          class="overlay"
          (click)="togglePlaceHolderDropdown()"
          *ngIf="isPlaceHolderDropdownShow"
        ></div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="
      userType === 'operator' &&
      questionType === CONSTANTS.QUESTION_TYPE.IMAGE_UPLOAD
    "
  >
    <div class="col-md-12">
      <div class="d-flex" *ngIf="form.get('imageUrl')?.value as imageUrl">
        <img
          [src]="imageUrl"
          class="rounded mb-2"
          style="width: 100px"
          alt="Uploaded Image"
        />
        <i
          title="Remove Image"
          class="fa fa-2 fa-times-circle ms-1"
          aria-hidden="true"
          style="cursor: pointer"
          (click)="removeImage(imageUrl)"
        >
        </i>
      </div>
      <label class="form-label mb-0">
        Image Upload <span class="required">*</span>
      </label>
      <div class="d-flex align-items-center">
        <input type="file" class="form-control" accept="image/*" #imageUpload />
        <button
          psUiButton
          type="button"
          class="add-resp-id-btn ms-3 mt-1"
          [disabled]="imageUploading"
          (click)="uploadImage(imageUpload)"
        >
          {{ imageUploading ? 'Uploading...' : 'Upload' }}
        </button>
      </div>
      <div class="form-text text-secondary">
        Please upload image files with less than 1MB.
      </div>
    </div>
  </div>
  <ng-container *ngIf="userType === 'operator' && isPrivacyPolicyQuestion">
    <div class="col">
      <div class="form-group">
        <label>p_policy Text</label>
        <input
          type="text"
          class="form-control input-text"
          placeholder="p_policy text"
          formControlName="ppPlaceholderText"
          [attr.disabled]="
            isEditLocalizationMode &&
            class !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
              ? true
              : null
          "
          [ngClass]="{
            'not-allowed-cursor':
              isEditLocalizationMode &&
              class !== CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM
          }"
        />
        <small>(Note:- this text will show in place of p_policy)</small>
      </div>
    </div>
    <div class="col mar-top-15">
      <div class="form-group">
        <label>Privacy Policy Text</label>
        <textarea
          type="text"
          class="form-control input-text"
          placeholder="Privacy Policy Text"
          formControlName="ppModalText"
          [attr.disabled]="isEditLocalizationMode ? true : null"
          [ngClass]="{
            'not-allowed-cursor': isEditLocalizationMode
          }"
        ></textarea>
      </div>
    </div>
  </ng-container>
  <br />
  <div class="create-survey-question-view">
    <label>Create Survey Question View</label>
    <div class="setting-info">
      <i class="fa fa-info-circle" aria-hidden="true"></i>
      <p>
        This allows the Operator to decide the position and the view of the
        Location box in the Survey Creation process
      </p>
    </div>
    <div class="row">
      <div class="col-sm">
        <label> Position</label>
        <ps-ui-select-dropdown
          class="basic-size-of-select-dropdown"
          [dropdownItems]="create_survey_position"
          [field]="'value'"
          [enableSearch]="false"
          formControlName="create_survey_position"
          (selectedItem)="selectQualificationPosition($event)"
        ></ps-ui-select-dropdown>
      </div>
      <div class="col-sm">
        <label> Component</label>
        <ps-ui-select-dropdown
          class="basic-size-of-select-dropdown"
          [dropdownItems]="create_survey_component"
          [field]="'value'"
          [enableSearch]="false"
          formControlName="create_survey_component"
          (selectedItem)="selectQualificationComponent($event)"
        ></ps-ui-select-dropdown>
      </div>
      <div class="col-sm">
        <label> Name Alias</label>
        <input
          type="text"
          class="form-control input-text"
          placeholder="Alias"
          formControlName="name_alias"
        />
      </div>
    </div>
    <br />
  </div>
  <div class="col">
    <div class="form-group">
      <label>Census</label>
      <div class="checkbox">
        <label>
          <input type="checkbox" formControlName="has_census_data" />
          Has Census Data
        </label>
      </div>
    </div>
  </div>
  <div
    class="row ans-text"
    *ngIf="
      questionType === CONSTANTS.QUESTION_TYPE.SINGLEPUNCH ||
      questionType === CONSTANTS.QUESTION_TYPE.MULTIPUNCH ||
      questionType === CONSTANTS.QUESTION_TYPE.IMAGE_UPLOAD
    "
  >
    <h3>
      {{
        questionType === CONSTANTS.QUESTION_TYPE.IMAGE_UPLOAD
          ? 'Pre-Defined Answer Options'
          : 'Answers Options'
      }}
    </h3>
  </div>
  <div
    formArrayName="answers"
    *ngIf="
      questionType === CONSTANTS.QUESTION_TYPE.SINGLEPUNCH ||
      questionType === CONSTANTS.QUESTION_TYPE.MULTIPUNCH ||
      questionType === CONSTANTS.QUESTION_TYPE.IMAGE_UPLOAD
    "
  >
    <!-- Answer options -->
    <ng-container *ngFor="let answer of answers.controls; let i = index">
      <div class="row mb-10 padding-bottom" [formGroupName]="i">
        <!-- FIRST ROW - ADD-->
        <ng-container *ngIf="i === 0">
          <!-- FIRST COLUMN - Condition code -->
          <div class="col-md-2">
            <input
              type="text"
              name="id"
              class="form-control input-text"
              placeholder="111"
              formControlName="id"
            />
          </div>
          <!-- MIDDLE COLUMNS - Answer Text, Total Selected and Translation -->
          <div
            class="ans-textarea"
            [ngClass]="[
              form.controls.selectedLanguage?.value?.transalte_code === 'en'
                ? 'col-md-8'
                : 'col-md-4'
            ]"
          >
            <textarea
              type="text"
              name="text"
              class="form-control input-text"
              placeholder="Answer Text"
              formControlName="text"
            ></textarea>
          </div>
          <div
            class="col-md-4 ans-textarea"
            *ngIf="
              form.controls.selectedLanguage?.value?.transalte_code !== 'en'
            "
          >
            <textarea
              type="text"
              name="translation"
              class="form-control input-text"
              placeholder="English Translation"
              formControlName="translation"
            ></textarea>
          </div>
          <!-- LAST COLUMN - Add button -->
          <div
            class="col-md-2 plus-btn"
            *ngIf="isUserHasQuestionLibraryAccess && !isSegmentation"
          >
            <button
              type="button"
              class="btn add_input_btn btn-common"
              (click)="addAnswerOption()"
            >
              +
            </button>
          </div>
        </ng-container>
        <!-- REST ROWS - READONLY/EDITABLE -->
        <ng-container *ngIf="i !== 0">
          <!-- FIRST COLCUMN - Condition code - Read only -->
          <div class="col-md-2">
            <input
              type="text"
              name="id"
              class="form-control input-text not-allowed-cursor"
              placeholder="111"
              formControlName="id"
              [readonly]="true"
            />
          </div>
          <!-- MIDDLE COLUMNS - Answer Text, Total Selected and Translation - Editable (Total selected is readonly) -->
          <div
            class="ans-textarea"
            [ngClass]="[
              isEditLocalizationMode
                ? form.controls.selectedLanguage?.value?.transalte_code === 'en'
                  ? 'col-md-6'
                  : 'col-md-3'
                : form.controls.selectedLanguage?.value?.transalte_code === 'en'
                ? 'col-md-8'
                : 'col-md-4'
            ]"
          >
            <textarea
              type="text"
              name="text"
              class="form-control input-text"
              placeholder="Answer Text"
              formControlName="text"
            ></textarea>
          </div>
          <div *ngIf="isEditLocalizationMode" class="col-md-2 ans-textarea">
            <input
              formControlName="totalSelectedText"
              class="form-control input-text pad-right-0"
              [readonly]="true"
            />
          </div>
          <div
            class="col-md-3 ans-textarea"
            [ngClass]="isEditLocalizationMode ? 'col-md-3' : 'col-md-4'"
            *ngIf="
              form.controls.selectedLanguage?.value?.transalte_code !== 'en'
            "
          >
            <textarea
              type="text"
              name="translation"
              class="form-control input-text"
              placeholder="English Translation"
              formControlName="translation"
            ></textarea>
          </div>
          <!-- LAST COLUMN - Add location case -DELETE button-->
          <ng-container
            *ngIf="isUserHasQuestionLibraryAccess && !isEditLocalizationMode"
            class="col-md-2 plus-btn"
          >
            <div class="col-md-2 plus-btn">
              <button
                type="button"
                class="btn add_input_btn btn-common red-bg-button"
                (click)="deleteAnswerOption(i)"
              >
                x
              </button>
            </div>
          </ng-container>
          <!-- LAST COLUMN - Accordion Location case  -->
          <ng-container
            *ngIf="isUserHasQuestionLibraryAccess && isEditLocalizationMode"
          >
            <!-- FOR custom class, Delete button should be at last -->
            <div
              *ngIf="class === CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM"
              class="col-md-2 plus-btn"
            >
              <button
                type="button"
                class="btn add_input_btn btn-common red-bg-button"
                (click)="deleteAnswerOption(i)"
              >
                x
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <!-- Data Quality check -->
  <ps-data-quality
    [answers]="form.controls?.answers?.value"
    [questionType]="questionType"
    [class]="class"
    [qualificationId]="'respId'"
    [locale]="
      form.controls?.selectedLanguage?.value?.short_code +
      '_' +
      form.controls.selectedCountry?.value?.short_Code
    "
    [isEditLocalizationMode]="isEditLocalizationMode"
    [dataQualityAdded]="localizationAdded?.data_quality?.quality_rules"
    (dataQuality)="saveQualityRules($event)"
  ></ps-data-quality>
  <div class="row next-btn-div" *ngIf="!isEditLocalizationMode">
    <div class="col-md-6 btn-left">
      <button
        type="button"
        class="btn btn-default submit-btn btn-common add-resp-id-btn"
        (click)="addLocalization()"
      >
        Add Localization
      </button>
    </div>
    <div class="col-md-6 btn-right">
      <button
        type="button"
        class="btn btn-default submit-btn btn-common add-resp-id-btn"
        (click)="resetForm()"
      >
        Clear Localization
      </button>
    </div>
  </div>
</form>
