export class StringTruncator {
  constructor(
    private readonly _value: string,
    private readonly _upTo: number = 20
  ) {}

  public truncate(): string {
    if (!this._value) {
      return '';
    }
    if (this._value.length <= this._upTo) {
      return this._value;
    }
    return this._value.substring(0, this._upTo).concat('...');
  }
}
