import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, timeout } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import {
  SelectedStatus,
  TransactionByStatusReportResponse,
} from './transaction-by-status-report.interface';

@Injectable({
  providedIn: 'root',
})
export class TransactionByStatusReportService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  getTransactionByStatusReport(
    surveyId: string,
    supplierId: number,
    statusOptions: Array<SelectedStatus>,
    { startDate, endDate }: { startDate: any; endDate: any }
  ) {
    const req = {
      survey_id: surveyId,
      supplier_id: supplierId,
      survey_st: statusOptions,
      startDate,
      endDate,
    };

    return this._http
      .post<TransactionByStatusReportResponse>(
        `${this._baseUrl}/report/surveys/transactionByStatus`,
        req
      )
      .pipe(
        timeout(300000),
        map((response) =>
          response.result.map((r) => ({
            transactionId: r.transId,
            supplierName: r.supNm,
            supplierSessionId: r.supSid,
            supplierRespondentId: r.supResId,
            transactionStatus: r.resStDes.replace(/_/g, ' '),
            surveyStartTime: r.psEntDt,
            LOI: r.srvPerf?.resLoi,
            PSID: r.PSID,
          }))
        )
      );
  }
}
