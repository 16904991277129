<ps-ui-modal>
  <ps-ui-modal-body>
    <i class="fa fa-exclamation-circle exclamationStyle" aria-hidden="true"></i>
    <span *ngIf="text"
      ><p class="lableText" *ngFor="let line of text">{{ line }}</p></span
    >
    <p *ngIf="innerHtml" class="lableText" [innerHtml]="innerHtml"></p>
    <p class="lableText" *ngIf="data.onlyOneTCLive">{{ data.onlyOneTCLive }}</p>
  </ps-ui-modal-body>
  <ps-ui-modal-footer>
    <button
      type="button"
      psUiButton
      color="secondary"
      (click)="close('cancel')"
      class="btn btn-default cancelBtn"
    >
      {{ data.cancelButtonLabel || 'Cancel' }}
    </button>
    <button
      type="button"
      class="btn btn-default clearBtn"
      psUiButton
      (click)="close('ok')"
    >
      {{ data.confirmButtonLabel || 'Ok' }}
    </button>
  </ps-ui-modal-footer>
</ps-ui-modal>
