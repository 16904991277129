<div class="row">
  <div class="flex-column">
    <div class="d-flex justify-content-center">
      <input
        #fileUpload
        hidden
        type="file"
        accept=".csv, .xlsx, .xlx"
        (change)="validateAndUploadCrmIdFile($event)"
      />
      <a
        class="btn btn-link common-btn upload-btn"
        (click)="fileUpload.value = ''; fileUpload.click()"
      >
        Upload a file</a
      >
    </div>
    <div class="d-flex justify-content-center">.csv, .xlsx, .xlx</div>
    <div class="d-flex justify-content-center download-btn-container">
      <a
        type="button"
        class="btn btn-link common-btn download-btn"
        (click)="downloadTemplateFile()"
        >Download File</a
      >
    </div>
  </div>
</div>
