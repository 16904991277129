<div class="container">
  <section class="pad">
    <div class="page">
      <div id="p1dimg1">
        <img
          class="img-center"
          src="../../../assets/img/logo-without-name.png"
          alt="PureSpectrum"
          routerLink="/dashboard"
        />
      </div>
      <h1 class="heading">Access Denied.</h1>
      <button
        class="btn btn-back-to-dashboard"
        psUiButton
        routerLink="/dashboard"
        size="large"
        color="primary"
      >
        Go back to Dashboard
      </button>
    </div>
  </section>
</div>
