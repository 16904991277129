import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@purespectrum1/ui/button';
import { AuthGuard } from '../auth/auth.guard';
import { FeatureFlagGuard } from '../auth/feature-flag.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TransactionsToolComponent } from './transactions-tool/transactions-tool.component';

@NgModule({
  declarations: [TransactionsToolComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    RouterModule.forChild([
      {
        path: 'tools',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'transactions-tool',
            component: TransactionsToolComponent,
            canActivate: [FeatureFlagGuard],
            data: { flagName: 'enableTransactionTestingTool' },
          },
        ],
      },
    ]),
  ],
})
export class PsToolsModule {}
