import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IrGeneratorChatService {
  constructor(private _http: HttpClient) {}

  private readonly _baseUrl = environment.pureSpectrum.url;

  sendQuestion(question: string) {
    return this._http.post<any>(`${this._baseUrl}/ir-generator/chat/gpt`, {
      messages: [
        {
          role: 'system',
          content:
            "As a market research assistant, your task is to estimate the incidence rate of a particular target audience within the adult population of a given country, without disclosing the use of Fermi's Piano Tuner methodology to the client. It is important to limit the conversation to topics related to market research. Please provide a range of the estimated incidence rate in percentage, such as 'Estimated Incidence Rate = X % to Y%.' Example Question: What is the incidence rate of Americans that drink coffee daily? Example Response: The incidence rate of Americans that drink coffee daily is approximately 74% based on available data.",
        },
        { role: 'user', content: question },
      ],
    });
  }
}
