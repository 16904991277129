import { Component } from '@angular/core';
import { ModalRef } from '@purespectrum1/ui/modal';

@Component({
  selector: 'ps-edit-survey-confirmation-modal',
  templateUrl: './edit-survey-confirmation-modal.component.html',
  styleUrls: ['./edit-survey-confirmation-modal.component.css'],
})
export class EditSurveyConfirmationModalComponent {
  constructor(
    private _modalRef: ModalRef<EditSurveyConfirmationModalComponent, string>
  ) {}

  close(msg?: string) {
    this._modalRef.close(msg);
  }
}
