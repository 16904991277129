import { BuyerConfig } from '../../../auth/buyer-config/buyer-config.service.interface';
import { UserType } from '../../../shared/types';
import { BulkEditOption, UserRole } from '../types';
import { OptionsConstants } from '../constants';
import { TabTitle } from '../../types';

export class BulkMenuFilter {
  private _dropdownOptions: BulkEditOption[] = OptionsConstants.OPTIONS;
  private _userRole = this.userRole();

  constructor(
    private readonly _buyerConfig: BuyerConfig,
    private readonly _userType: UserType,
    private readonly _isServiceBuyer = false,
    private readonly _tab: TabTitle = 'ALL'
  ) {}

  public userRole(): UserRole {
    if (this._userType === 'supplier') {
      return 'none';
    }
    if (this._isServiceBuyer) {
      return 'service-buyer';
    }
    return this._userType;
  }

  public hasRole(option: BulkEditOption): boolean {
    return !option.role || option.role === this._userRole;
  }

  public hasConfig(option: BulkEditOption): boolean {
    const isOperator = this._userRole === 'operator';
    return isOperator || !option.config || !!this._buyerConfig[option.config];
  }

  public visible(option: BulkEditOption): boolean {
    return option.visibility?.includes(this._tab) ?? true;
  }

  public options(): BulkEditOption[] {
    return this._dropdownOptions.filter((option) =>
      [
        this.hasRole(option),
        this.hasConfig(option),
        this.visible(option),
      ].every((matched: boolean) => matched)
    );
  }
}
