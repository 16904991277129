<div class="kpi-dropdown-container">
  <ul class="pill_drop ps-0" aria-labelledby="HealthCheckdropdownMenu">
    <li *ngFor="let kpi of kpis">
      <a
        class="dropdown-item"
        [ngClass]="getHealthKpiClass(kpi.status_text)"
        (click)="onKpiSelection()"
      >
        {{ kpi.name }}
      </a>
    </li>
  </ul>
</div>
