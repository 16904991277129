import { Pipe, PipeTransform } from '@angular/core';
import { UNITS } from 'src/app/constants/currency-units';
import { QUALIFICATION_CODES } from 'src/app/constants/qualification-code';

@Pipe({ name: 'currencySymbol' })
export class CurrencySymbolPipe implements PipeTransform {
  transform(name: string, currentUnit: string, qualId: number): string {
    return qualId === QUALIFICATION_CODES.INCOME
      ? `${UNITS[currentUnit]}${name}`
      : `${name} ${UNITS[currentUnit]}`;
  }
}
