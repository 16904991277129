import { Component, OnInit, OnDestroy } from '@angular/core';

import { IndividualConfig } from 'ngx-toastr';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';

import { MARGIN } from '../operator-settings-constants';

import { MarginService } from '../margin-service/margin.service';
import { CompanyService } from '../../shared/services/company/company.service';
import { BuyerService } from '../../shared/services/buyer/buyer.service';
@Component({
  selector: 'ps-margin-settings',
  templateUrl: './margin-settings.component.html',
  styleUrls: ['./margin-settings.component.css'],
})
export class MarginSettingsComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public buyers: BuyersListing[] = [];
  public suppliers: SuppliersListing[] = [];
  public margin: number = 0;
  public selectedBuyer!: BuyersListing;
  public selectedSupplier!: SuppliersListing;
  constructor(
    private _companyService: CompanyService,
    private _buyerService: BuyerService,
    private _toastr: ToasterService,
    private _marginService: MarginService
  ) {}

  ngOnInit(): void {
    this.getBuyers();
    this.getSuppliers();
  }

  getBuyers() {
    const $getBuyers = this._buyerService.getBuyers().subscribe(
      (buyerResponse) => {
        this.buyers = buyerResponse.company;
        this.buyers.splice(0, 0, { name: 'All', id: 0 });
        this.selectedBuyer = this.buyers[0];
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add($getBuyers);
  }

  getSuppliers() {
    const $getCompanies = this._companyService.getCompanies().subscribe(
      (response) => {
        this.suppliers = response.companies.filter((eachCompany) => {
          return eachCompany.isASupplier;
        });
        this.suppliers.splice(0, 0, { name: 'All', id: 0, isASupplier: true });
        this.selectedSupplier = this.suppliers[0];
        this.getPricingMargin();
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add($getCompanies);
  }

  selectBuyer(buyer: BuyersListing) {
    this.selectedBuyer = buyer;
    this.getPricingMargin();
  }

  selectSupplier(supplier: SuppliersListing) {
    this.selectedSupplier = supplier;
    this.getPricingMargin();
  }

  getPricingMargin() {
    const $getPricingMargin = this._marginService
      .getPricingMargin(this.selectedBuyer.id, this.selectedSupplier.id)
      .subscribe(
        (pricingMarginResponse) => {
          this.margin = pricingMarginResponse.hasOwnProperty('margin')
            ? pricingMarginResponse.margin
            : 0;
        },
        (error) => {
          this._toastr.error(error.msg);
        }
      );
    this._subscriptions.add($getPricingMargin);
  }

  submitMargin() {
    if (this.margin > MARGIN) {
      this._toastr.error('Buyer margin cannot be more than 99%');
    } else {
      const marginObj = {
        buyer_id: this.selectedBuyer.id,
        supplier_id: this.selectedSupplier.id,
        margin: this.margin,
      };
      const $savePricingMargin = this._marginService
        .savePricingMargin(marginObj)
        .subscribe(
          (submitMarginResponse) => {
            this._toastr.success('Pricing has been saved !', 'Success', {
              timeOut: 2000,
            } as IndividualConfig);
          },
          (error) => {
            this._toastr.error(error.error.msg);
          }
        );
      this._subscriptions.add($savePricingMargin);
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}

interface BuyersListing {
  name: string;
  id: number;
}
interface SuppliersListing {
  name: string;
  id: number;
  isASupplier: boolean;
}
