import { SurveyListing } from '../../../shared/services/buyer-api/survey.interface';

export class SurveysLength {
  constructor(private readonly _surveys: SurveyListing[]) {}

  calculate(): number {
    return this._surveys.reduce((acc, survey) => {
      if (survey.mc_count) {
        return acc + survey.mc_count;
      }

      return acc + 1;
    }, 0);
  }
}
