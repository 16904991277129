<div class="supplier-tabs" [formGroup]="form">
  <div class="form-group">
    <label class="control-label col-md-4">Max LOI</label>
    <div class="col-md-6">
      <input
        name="max_loi"
        type="number"
        placeholder="Max LOI"
        class="form-control input-md"
        formControlName="max_loi"
        (keyup)="validateLOI($event)"
      />
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-4">Min Incidence</label>
    <div class="col-md-6">
      <input
        name="min_incidence"
        type="number"
        placeholder="Min Incidence"
        class="form-control input-md"
        formControlName="min_incidence"
      />
    </div>
  </div>
  <div class="form-group">
    <label class="col-md-4 control-label"> Recontact</label>
    <div class="col-md-6">
      <label class="radio-inline">
        <input
          type="radio"
          name="isRecontact"
          id="Recontact"
          [value]="true"
          formControlName="isRecontact"
        />
        Yes
      </label>
      <label class="radio-inline">
        <input
          type="radio"
          name="isRecontact"
          [value]="false"
          formControlName="isRecontact"
        />
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="col-md-4 control-label modular-survey">
      Modular Survey
    </label>

    <div class="setting-info col-md-1 setting-info-modular-survey">
      <i class="fa fa-info-circle" aria-hidden="true"></i>
      <p class="info-style">
        Allow a respondent to take another survey if qualified for an additional
        survey for the same buyer.
      </p>
    </div>
    <div class="col-md-6 modular-radio">
      <label class="radio-inline">
        <input
          type="radio"
          name="isModularSurvey"
          id="Modular-survey"
          [value]="true"
          formControlName="isModularSurvey"
        />
        Yes
      </label>
      <label class="radio-inline">
        <input
          type="radio"
          name="isModularSurvey"
          [value]="false"
          formControlName="isModularSurvey"
        />
        No
      </label>
    </div>
  </div>
</div>
