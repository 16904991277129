import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  GetCategoryResponse,
  GetCountriesResponse,
  GetLocationResponse,
  EachCountry,
} from '../../interfaces/create-surveys.interface';
import { SurveyStatusInterface } from '../../services/buyer-api/survey.interface';

import { map } from 'rxjs/operators';
import { QUALIFICATION_CODES } from '../../../constants/qualification-code';
import { Constants as CreateSurveyConstants } from '../../../create-surveys/create-survey.constants';
import { SurveyStatus } from '../../../utils/survey-status';
import { randomString } from '../../../utils/random-string';
import { AuthService } from '../../../../app/auth/auth.service';
import { Subscription } from 'rxjs';
import { DecipherHashKeyIDsResponse } from '../../interfaces/common-service.interface';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  public isServiceBuyerLoggedIn: boolean = false;
  invokeChangeSurveyStatus = new EventEmitter();

  private _subscription: Subscription = new Subscription();
  constructor(private _http: HttpClient, private _auth: AuthService) {}

  getCategories() {
    return this._http.get<GetCategoryResponse>(`${this._baseUrl}/samples`);
  }

  searchLocation(country: string, field: number, searchKey: string) {
    return this._http.get<GetLocationResponse>(
      `${this._baseUrl}/searchAutocomplete/${country}/${field}/${searchKey}`
    );
  }

  getDecipherHashKeyIDs() {
    return this._http.get<DecipherHashKeyIDsResponse>(
      `${this._baseUrl}/decipherHashKeyIds`
    );
  }

  getCountries(addAllOption: boolean = false) {
    return this._http
      .get<GetCountriesResponse>(`${this._baseUrl}/countries`)
      .pipe(
        map((response) => {
          return this._mapCountriesResponse(response, addAllOption);
        })
      );
  }

  getCountriesList(addAllOption: boolean = false) {
    return this._http
      .get<GetCountriesResponse>(`${this._baseUrl}/country_list`)
      .pipe(
        map((response) => {
          return this._mapCountriesResponse(response, addAllOption);
        })
      );
  }

  getRandomString(length: number = 15) {
    return randomString(length);
  }

  getUnit(qualificationCode: number) {
    if (qualificationCode === QUALIFICATION_CODES.INCOME) {
      return CreateSurveyConstants.UNITS.INCOME;
    }
    return CreateSurveyConstants.UNITS.AGE.YEAR;
  }

  public getSurveyStatus(surveyType: string): number {
    switch (surveyType) {
      case 'Live':
        return SurveyStatus.Live;
      case 'Paused':
        return SurveyStatus.Paused;
      case 'Closed':
        return SurveyStatus.Closed;
      case 'Invoice':
        return SurveyStatus.Invoice;
      default:
        return SurveyStatus.Draft;
    }
  }

  public onChangeSurveyStatus(survey: SurveyStatusInterface) {
    this.invokeChangeSurveyStatus.emit(survey);
  }

  public checkIfServiceBuyer() {
    const authServiceSubscription =
      this._auth.loggedInAsServiceBuyer$.subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.isServiceBuyerLoggedIn = true;
        }
      });
    this._subscription.add(authServiceSubscription);
    return this.isServiceBuyerLoggedIn;
  }

  private _mapCountriesResponse(
    response: GetCountriesResponse,
    addAllOption: boolean = false
  ) {
    const topCountries: EachCountry[] = []; //contains US and CA country data
    const remainCountries: EachCountry[] = [];
    response.countries.values.forEach((eachCountry) => {
      if (eachCountry.short_Code === 'US' || eachCountry.short_Code === 'CA') {
        topCountries.push(eachCountry);
      } else {
        remainCountries.push(eachCountry);
      }
    });

    topCountries.sort((a: EachCountry, b: EachCountry) =>
      a.short_Code < b.short_Code ? 1 : -1
    );
    remainCountries.sort((a: EachCountry, b: EachCountry) =>
      a.name > b.name ? 1 : -1
    );
    response.countries.values = [...topCountries, ...remainCountries];
    if (addAllOption) {
      response.countries.values.unshift({
        name: 'ALL',
        id: 0,
        short_Code: '',
        pl_code: '',
        lang: [],
        orderId: 0,
      });
    }
    response.countries.values = response.countries.values.map(
      (country, index) => {
        return {
          ...country,
          orderId: index,
        };
      }
    );
    return response.countries.values;
  }
}
