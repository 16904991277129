<ps-header-service-account></ps-header-service-account>
<button
  type="button"
  class="btn btn-square pull-right basic-ps-button"
  [routerLink]="['/dashboard']"
>
  Back to Dashboard
</button>
<h1 class="ir-generator-title">IR Generator Alpha v.0.0.2</h1>

<div class="ir-generator-container">
  <div *ngFor="let question of chatObject">
    <div class="chat-question" *ngIf="question.type">
      <label>WHAT IS YOUR DESIRED AUDIENCE?</label>
      <span>{{ question.value }}</span>
      <a
        >Example Prompt: “What is the percentage of people that own a dog in
        United States“</a
      >
    </div>

    <div class="chat-answer" *ngIf="!question.type">
      <label>RESPONSE:</label>
      <p>{{ question.value }}</p>
    </div>
  </div>
  <div class="chat-question">
    <label>WHAT IS YOUR DESIRED AUDIENCE?</label>
    <input [(ngModel)]="question" />
    <a
      >Example Prompt: “What is the percentage of people that own a dog in
      United States“</a
    >
  </div>
  <button class="basic-ps-button" (click)="askChatGPT(question)">Submit</button>
</div>

<footer class="footer-chat">
  <p>
    Inspiration by: William Van Heusen Maintained and Owned by: PureSpectrum For
    Experimental, Demonstrational, and Educational Purposes only. <br />
    Content is generated via a direct API integration to OpenAI's ChatGPT 3.5
    Turbo model. Submit feedback here. <br />
    DISCLAIMER: This project is an experiment in utilizing generative AI
    language models, such as Chat GPT, to streamline the process of finding
    incidence rates for market research projects. By harnessing the power of
    advanced natural language processing algorithms, the goal of this project is
    to simplify and accelerate the process of identifying accurate and reliable
    estimates for audience sizes for consumer quantitative research
    [projects.By|http://projects.By] continuing, you agree that you are using
    this tool only for experimental, educational, or demonstrational purposes.
    There is no guarantee of accuracy of the information provided by this tool.
    Any generated content should not be used in an actual research or
    professional environment. <br />
    Update 0.0.2 - April 18th - Now works for international markets (previously
    US only). Requires user to define country in prompt. <br />
    Initial release 0.0.1 - April 13th
  </p>
</footer>
