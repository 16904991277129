import {
  ChangeSurveyModalData,
  ChangeSurveyModalTemplate,
  ChangeSurveyModalTemplateData,
} from './change-survey-modal.template';

export class DeleteMcSurveyModalTemplate implements ChangeSurveyModalTemplate {
  constructor(private _data: ChangeSurveyModalData) {}

  getData(): ChangeSurveyModalTemplateData {
    return {
      title: 'DELETE DRAFT',
      body: 'Do you want to remove and delete the survey from the configuration?',
      close: 'Cancel',
      delete: 'Yes, delete',
    };
  }
}
