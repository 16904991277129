<div class="profile-div">
  <button class="menu-btn" [psUiDropdownToggleFor]="userMenu">
    <img
      class="img-responsive"
      src="../../../../assets/img/dashbrd-header-menu.png"
      alt="menu"
      title=""
    />
  </button>
  <ps-ui-dropdown #userMenu="psUiDropdown" class="userDropdown">
    <ps-ui-dropdown-item class="username">
      Hello {{ userEmail }}
    </ps-ui-dropdown-item>
    <button
      psUiDropdownItem
      (click)="selectServiceAccount()"
      *ngIf="isServiceOperator"
    >
      Select Service Account
    </button>
    <ng-container *ngFor="let item of menuItems">
      <a psUiDropdownItem routerLink="{{ getFeatureLink(item.featureKey) }}">
        {{ item.featureLabel }}
      </a>
    </ng-container>
    <a psUiDropdownItem routerLink="/buyer/reset-password">Reset Password </a>
    <button psUiDropdownItem (click)="logout()">Logout</button>
  </ps-ui-dropdown>
</div>
