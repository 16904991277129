<ng-container *ngIf="state$ | async as state">
  <ps-bulk-edit
    *ngIf="state.preferences.enableBulkEditAndSorting"
    [selected]="state.selected"
    [total]="state.surveys.length"
    [tab]="tab"
    (report)="onBulkReport($event)"
    [countries]="state.countries"
    (edit)="onBulkEdit($event)"
  ></ps-bulk-edit>
  <main
    class="container-flex dashboard_box"
    [class.service-operator-selected]="state.preferences.serviceOperator"
  >
    <div class="row">
      <div class="col-sm-12">
        <ps-survey-search
          [value]="state.pagination.search"
          [filter]="state.filter()"
          [offset]="state.preferences.enableBulkEditAndSorting"
          (view)="onViewChange($event)"
          (search)="onSearch($event)"
        ></ps-survey-search>
        <ps-ui-tab-group
          [activeIndex]="tabIndex"
          (activeIndexChange)="onChangeTab($event)"
          [beforeTabChange]="onBeforeTabChange()"
          class="colwidth"
          psUiGtagEvent
          trackOn="click"
          action="dashboard_tab"
          category="Dashboard_Tab"
          [params]="{ event_label: 'Dashboard Tab' }"
        >
          <ps-ui-tab
            *ngFor="let tab of tabState.tabs"
            [label]="tab.title"
            [count]="tab.counter()"
          >
            <ng-template psUiTabContent>
              <ps-survey-table
                [preferences]="state.preferences"
                [surveys]="state.surveys"
                [countries]="state.countries"
                [selected]="state.selected.selected()"
                [tab]="tab"
                (paginate)="onPagination()"
                (clone)="onClone($event)"
                (tc)="onTC($event)"
                (delete)="onDelete($event)"
                (downloadReport)="onDownloadReport($event)"
                (selectedChange)="onSurveysSelected($event)"
                (companyChange)="onCompanyChange($event)"
                [isDasaboard]="true"
                [isMultiCountryWidget]="isMultiCountryWidget"
              ></ps-survey-table>
            </ng-template>
          </ps-ui-tab>
        </ps-ui-tab-group>
      </div>
    </div>
  </main>
</ng-container>
