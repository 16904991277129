<div class="supplier-tabs" [formGroup]="form">
  <div class="form-group">
    <label class="col-md-4 control-label">S2S Callbacks</label>
    <div class="col-md-6">
      <label class="radio-inline">
        <input
          type="radio"
          name="iss2sCallback"
          id="iss2sCallback"
          [value]="true"
          formControlName="iss2sCallback"
        />
        Yes
      </label>
      <label class="radio-inline">
        <input
          type="radio"
          name="iss2sCallback"
          [value]="false"
          formControlName="iss2sCallback"
        />
        No
      </label>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-md-4">Complete to Non-Complete</label>
    <div class="col-md-6">
      <input
        name="isCompleteToNonCompleteURL"
        type="text"
        placeholder="Complete to Non-Complete"
        class="form-control input-md"
        formControlName="isCompleteToNonCompleteURL"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-md-4">Non-Complete to Complete</label>
    <div class="col-md-6">
      <input
        name="isNonCompleteToCompleteURL"
        type="text"
        placeholder="Non-Complete to Complete"
        class="form-control input-md"
        formControlName="isNonCompleteToCompleteURL"
      />
    </div>
  </div>
</div>
