<ps-ui-modal size="lg">
  <h5 psUiModalTitle class="select-service-account-header">
    Select Service Account
  </h5>
  <ps-ui-modal-body>
    <div>
      <p class="service-operator-title">
        Select the Service Account that you will operate as
      </p>
    </div>
    <div class="select-service-account">
      <ps-ui-select-dropdown
        labelNone="Choose Service Account"
        [multi]="false"
        [dropdownItems]="buyerList"
        [field]="'name'"
        [(ngModel)]="selectedBuyer"
      >
      </ps-ui-select-dropdown>
      <i
        class="fa fa-times-circle clear"
        *ngIf="buyerList.length && selectedBuyer"
        (click)="clearBuyer()"
      >
      </i>
    </div>

    <button
      psUiButton
      class="confirm-service-account"
      (click)="setBuyerDetails()"
    >
      Confirm
    </button>
  </ps-ui-modal-body>
</ps-ui-modal>
