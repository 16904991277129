import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  KPIS,
  McSurveyStats,
} from '../../../../shared/services/buyer-api/survey.interface';
import { SurveyHealthCheckMapper } from '../domain/survey-health-check-mapper';

@Component({
  selector: 'ps-health-check-kpi-dropdown',
  templateUrl: './health-check-kpi-dropdown.component.html',
  styleUrls: ['./health-check-kpi-dropdown.component.css'],
})
export class HealthCheckKpiDropdownComponent<
  T extends { healthCheck?: KPIS[]; mc?: McSurveyStats }
> implements OnInit, OnChanges
{
  @Input() survey = <T>{};
  @Input() surveyId!: number;

  @Output() selectKpi = new EventEmitter<{
    survey_id: number;
  }>();
  public kpis: KPIS[] = [];

  constructor() {}

  ngOnInit(): void {
    this.kpis = new SurveyHealthCheckMapper(this.survey).kpis();
  }

  ngOnChanges(): void {
    this.kpis = new SurveyHealthCheckMapper(this.survey).kpis();
  }

  public getHealthKpiClass(status: string): string {
    switch (status) {
      case 'RED':
        return 'red_pill_drop';
      case 'GREEN':
        return 'green_pill_drop';
      case 'YELLOW':
        return 'yellow_pill_drop';
      default:
        return 'grey_pill_drop';
    }
  }

  public onKpiSelection(): void {
    this.selectKpi.emit({ survey_id: this.surveyId });
  }
}
