import { Component, OnInit, Inject } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import { ModalData, ModalRef } from '@purespectrum1/ui/modal';
import {
  QuestionMapping,
  ProcessedTarget,
  MappingModalData,
  BuyerQualifications,
  ProcessedTargetMapping,
  RangeSet,
  QualificationData,
} from '../../../shared/interfaces/qualification-mappings.interface';
import { notifyMessage } from '../../../constants/notify-message';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { QUALIFICATION_TYPES } from '../../../constants/qualification-code';
import { DEFAULT_AGE_QUOTA_CRITERIA } from '../../../constants/default-age-genpop-quota-criteria';
import { DecipherImportHelperService } from '../../../shared/services/decipher/decipher-import-helper/decipher-import-helper.service';

@Component({
  selector: 'ps-mapping-modal',
  templateUrl: './mapping-modal.component.html',
  styleUrls: ['./mapping-modal.component.css'],
})
export class MappingModalComponent implements OnInit {
  public qualificationTypes = QUALIFICATION_TYPES;
  private _minRange!: number;
  private _maxRange!: number;
  private _emptyList: [] = [];
  private _generateModalForm = () => {
    return this._fb.group({
      target: this._fb.array([]),
    });
  };

  private _generateTargetForm = (targetData?: ProcessedTarget) => {
    const {
      decipherCode = '',
      desc = '',
      mappings = [],
      decipherCondition = '',
    } = targetData || {};
    const targetForm = this._fb.group({
      decipherCode: [decipherCode],
      desc: [desc],
      decipherCondition: [decipherCondition],
      mappings: this._fb.array([]),
    });
    if (mappings.length) {
      mappings.forEach((mapping: ProcessedTargetMapping) => {
        (targetForm.get('mappings')! as FormArray).push(
          this._generateMappingsForm(mapping)
        );
      });
    }
    return targetForm;
  };

  private _generateMappingsForm = (targetMapping?: ProcessedTargetMapping) => {
    const {
      qualificationCode = null,
      conditionCodes = [],
      rangeSets,
    } = targetMapping || {};
    const formObj: { [key in keyof ProcessedTargetMapping]: any } = {
      qualificationCode: [qualificationCode],
      conditionCodes: this._fb.array([...conditionCodes]),
    };
    if (rangeSets) {
      delete formObj.conditionCodes;
      formObj.rangeSets = this._fb.group({
        from: [rangeSets.from],
        to: [rangeSets.to],
      });
      if (
        !isNaN(
          this.qualificationDetails[0].localizations[0].format?.min as number
        ) &&
        !isNaN(
          this.qualificationDetails[0].localizations[0].format?.max as number
        )
      ) {
        this._minRange = this.qualificationDetails[0].localizations[0].format
          .min as number;
        this._maxRange = this.qualificationDetails[0].localizations[0].format
          .max as number;
        formObj.rangeSets.controls.from.setValidators([
          Validators.min(this._minRange),
          Validators.max(this._maxRange),
        ]);
        formObj.rangeSets.controls.to.setValidators([
          Validators.min(this._minRange),
          Validators.max(this._maxRange),
        ]);
      }
    }
    return this._fb.group(formObj);
  };

  private _generateConditionControl = (conditionCode?: number) => {
    return new FormControl(conditionCode);
  };

  modalForm = this._generateModalForm();

  constructor(
    @Inject(ModalData) private _modalData: MappingModalData,
    private _fb: FormBuilder,
    private _toastr: ToasterService,
    private _modalRef: ModalRef<MappingModalComponent, ProcessedTarget[]>,
    private _decipherImportHelper: DecipherImportHelperService
  ) {}

  public mappingData: QuestionMapping = this._modalData.mappingData;
  public isUsedInDefaultMapping: boolean =
    this._modalData.isUsedInDefaultMapping;
  public qualificationDetails: BuyerQualifications[] =
    this._modalData.qualificationDetails;

  public close = () => {
    if (this.modalForm.status === 'INVALID') {
      if (
        this.isRangeType(this.qualificationDetails[0].qualification_code) &&
        !isNaN(this._minRange) &&
        !isNaN(this._maxRange)
      ) {
        if (this._isOutOfRange()) {
          return;
        }
      }
      this._toastr.error(
        notifyMessage.errorMessage.CONDITION_MAPPING_ERROR.INVALID_MAPPING
      );
      return;
    }
    if (this._areOptionsMapped()) {
      const hasDuplicate = this.hasDuplicateMapping(
        this.modalForm.value.target
      );
      if (hasDuplicate) {
        this._toastr.error(
          notifyMessage.errorMessage.CONDITION_MAPPING_ERROR.DUPLICATE_MAPPING
        );
        return;
      }
      if (this._isDefaultMappingError()) {
        return;
      }
      this._modalRef.close(this.modalForm.get('target')!.value);
    }
  };

  private _areOptionsMapped = () => {
    return (
      (this.isUsedInDefaultMapping && this._hasAllMappings()) ||
      (!this.isUsedInDefaultMapping &&
        this._hasAtleastOneMappingCheck() &&
        this._hasNoPartialMappingCheck())
    );
  };

  public hasDuplicateMapping = (targets: ProcessedTarget[]) => {
    return this._decipherImportHelper.hasDuplicate(targets);
  };

  private _isOutOfRange = () => {
    let isErrorShown = false;
    (this.modalForm.controls.target as FormArray).controls.forEach(
      (target: any) => {
        (target.controls.mappings as FormArray).controls.forEach(
          (mapping: any) => {
            if (
              mapping.controls.rangeSets.controls.from.errors?.min ||
              mapping.controls.rangeSets.controls.from.errors?.max ||
              mapping.controls.rangeSets.controls.to.errors?.min ||
              mapping.controls.rangeSets.controls.to.errors?.max
            ) {
              this._toastr.error(
                'Enter ' +
                  this.qualificationDetails[0].desc +
                  ' between ' +
                  this._minRange +
                  ' to ' +
                  this._maxRange
              );
              isErrorShown = true;
              return;
            }
          }
        );
      }
    );
    return isErrorShown;
  };

  private _isDefaultMappingError = () => {
    if (this.isUsedInDefaultMapping) {
      let isErrorShown = false;
      this.modalForm.value.target.forEach((target: any) => {
        if (target.mappings && target.mappings.length) {
          target.mappings.forEach((mapping: any) => {
            const targetMappingCheck =
              this.isRangeType(
                this.qualificationDetails[0].qualification_code
              ) ||
              (target.decipherCondition &&
                mapping.conditionCodes &&
                mapping.conditionCodes.length &&
                !isNaN(parseInt(mapping.conditionCodes[0])));
            if (targetMappingCheck) {
              isErrorShown = false;
            } else {
              this._toastr.error(
                notifyMessage.errorMessage.CONDITION_MAPPING_ERROR
                  .INVALID_MAPPING
              );
              isErrorShown = true;
              return;
            }
          });
        }
      });
      return isErrorShown;
    }
    return false;
  };

  private _hasAtleastOneMappingCheck = () => {
    const targetObjs = this.modalForm.value.target;
    const hasAtleastOneMapping =
      this._decipherImportHelper.hasAtleastOneMapping(
        targetObjs,
        this.qualificationDetails
      );
    if (!hasAtleastOneMapping) {
      this._toastr.error(
        notifyMessage.errorMessage.CONDITION_MAPPING_ERROR.ATLEAST_ONE_MAPPING
      );
      return false;
    }
    return true;
  };

  private _hasNoPartialMappingCheck = () => {
    const targetObjs = this.modalForm.value.target;
    const hasNoPartialMapping = this._decipherImportHelper.hasNoPartialMapping(
      targetObjs,
      this.qualificationDetails
    );
    if (!hasNoPartialMapping) {
      this._toastr.error(
        notifyMessage.errorMessage.CONDITION_MAPPING_ERROR.PARTIAL_MAPPING
      );
      return false;
    }
    return true;
  };

  private _hasAllMappings = () => {
    const hasAllMappings = this._decipherImportHelper.hasAllMappings(
      this.modalForm.value.target,
      this.qualificationDetails
    );
    if (!hasAllMappings) {
      this._toastr.error(
        notifyMessage.errorMessage.CONDITION_MAPPING_ERROR.MISSING_MAPPING
      );
      return false;
    }
    return true;
  };

  public getQualificationDetail = (qCode: number) => {
    return this.qualificationDetails.find(
      (q) => q.qualification_code === qCode
    );
  };

  public getQualificationAnswers = (qCode: number) => {
    const qual = this.getQualificationDetail(qCode);
    return qual && qual.localizations && qual.localizations.length
      ? qual.localizations[0].answers
      : this._emptyList;
  };

  public getTargetFormControls = () => {
    return (this.modalForm.get('target') as FormArray).controls as FormGroup[];
  };

  public getMappingFormControls = (target: FormGroup) => {
    return (target.get('mappings') as FormArray).controls as FormGroup[];
  };

  public getConditionControls = (mappingFG: FormGroup): FormControl[] => {
    return (mappingFG.get('conditionCodes') as FormArray)
      .controls as FormControl[];
  };

  public addCondition = (targetMapping: FormGroup) => {
    (targetMapping.get('conditionCodes') as FormArray).push(
      this._generateConditionControl()
    );
  };

  public addConditionMapping = (qualificationCode: number, index?: number) => {
    let mappings;
    if (this.isRangeType(this.qualificationDetails[0].qualification_code)) {
      mappings = [
        {
          qualificationCode,
          rangeSets: {
            from: this._minRange || 0,
            to: this._maxRange || 0,
          },
        },
      ];
    } else {
      mappings = [
        {
          qualificationCode,
          conditionCodes: [''],
        },
      ];
    }
    let decipherCondition = '';
    if (this.mappingData.decipherQuestionId) {
      if (index) {
        decipherCondition = this.mappingData.decipherQuestionId + '.r' + index;
      } else if (
        this.isRangeType(this.qualificationDetails[0].qualification_code)
      ) {
        decipherCondition =
          this.mappingData.decipherQuestionId +
          '.ival in range' +
          (this._minRange || 0) +
          ',' +
          (this._maxRange || 0);
      }
    }
    const emptyTarget = {
      desc: '',
      mappings,
      decipherCode: '',
      decipherCondition,
    };
    (this.modalForm.get('target') as FormArray).push(
      this._generateTargetForm(emptyTarget)
    );
  };

  public removeConditionMapping = (index: number) => {
    (this.modalForm.get('target') as FormArray).removeAt(index);
  };

  public updateRange = (targetMapping: FormGroup, $event: Event) => {
    const newValue = (<HTMLInputElement>$event.target).value;
    const splitRange = typeof newValue == 'string' ? newValue.split('-') : [];
    const rangeSetsFG = targetMapping.get('rangeSets') as FormGroup;
    if (newValue === '') {
      rangeSetsFG.get('from')?.reset(null);
      rangeSetsFG.get('to')?.reset(null);
    } else if (this._isInvalidRangeInput(newValue)) {
      rangeSetsFG.get('from')?.setValue(newValue);
      rangeSetsFG.get('to')?.setValue(newValue);
    } else {
      rangeSetsFG.get('from')?.setValue(Number(splitRange[0]));
      rangeSetsFG.get('to')?.setValue(Number(splitRange[1]));
    }
  };

  private _isInvalidRangeInput = (value: string) => {
    const splitRange = typeof value == 'string' ? value.split('-') : [];
    return (
      splitRange.length != 2 ||
      isNaN(Number(splitRange[0])) ||
      isNaN(Number(splitRange[1]))
    );
  };

  public removeCondition = (
    targetMapping: FormGroup,
    coniditonIndex: number
  ) => {
    const conditionCodes = targetMapping.get('conditionCodes') as FormArray;
    conditionCodes.removeAt(coniditonIndex);
    if (!conditionCodes.length) {
      conditionCodes.push(this._generateConditionControl());
    }
  };

  public optionLabel = (qualCode: number, condCode: string) => {
    let label = this.getQualificationAnswers(qualCode).filter((ans) => {
      return ans.condition_code === parseInt(condCode);
    });
    return label.length ? label[0]?.local_text : '';
  };

  public setCondition = (
    mapping: FormGroup,
    conditionIdx: number,
    selectedItem: QualificationData
  ) => {
    this.getConditionControls(mapping)[conditionIdx].setValue(
      selectedItem.condition_code.toString()
    );
  };

  private _patchFormData = (data: QuestionMapping) => {
    const targetArray = this.modalForm.get('target') as FormArray;
    data.target.forEach((target: ProcessedTarget) => {
      const targetGroup = this._generateTargetForm(target);
      this.addFillerMappingControl(targetGroup.get('mappings') as FormArray);
      this.getMappingFormControls(targetGroup).forEach(
        (mappingFG: FormGroup) => {
          this.addFillerConditionControl(
            mappingFG.get('conditionCodes') as FormArray
          );
        }
      );
      targetArray.push(targetGroup);
    });
  };

  addFillerConditionControl = (conditionCodesFormArray: FormArray) => {
    if (conditionCodesFormArray && !conditionCodesFormArray.length) {
      conditionCodesFormArray.push(this._generateConditionControl());
    }
  };

  addFillerMappingControl = (mappingFormArray: FormArray) => {
    if (mappingFormArray && !mappingFormArray.length) {
      mappingFormArray.push(this._generateMappingsForm());
    }
  };

  returnRangeString = (rangeObject: RangeSet) => {
    const { from, to } = rangeObject;
    return typeof from == 'number' && typeof to == 'number'
      ? `${from}-${to}`
      : '';
  };

  public setEmptyModal = () => {
    if (this.isRangeType(this.qualificationDetails[0].qualification_code)) {
      this.addConditionMapping(this.qualificationDetails[0].qualification_code);
    } else {
      this.qualificationDetails[0].localizations[0].answers.forEach(
        (ans, i) => {
          this.addConditionMapping(
            this.qualificationDetails[0].qualification_code,
            i + 1
          );
        }
      );
    }
  };

  public returnPlaceholder = (qCode: number) => {
    const { max, min } =
      this.getQualificationDetail(qCode)!.localizations[0]?.format;
    const { MAX, MIN } = DEFAULT_AGE_QUOTA_CRITERIA;
    const minString = `${typeof min === 'number' ? min : MIN}`;
    const maxString = `${typeof max === 'number' ? max : MAX}`;
    return `${minString} - ${maxString}`;
  };

  public isRangeType = (qCode: number) => {
    const qualification = this.getQualificationDetail(qCode);
    const { RANGE, INPUT } = QUALIFICATION_TYPES;
    return ([RANGE, INPUT] as number[]).includes(qualification!.type);
  };

  ngOnInit(): void {
    if (
      this.isUsedInDefaultMapping &&
      !this.mappingData.target?.length &&
      this.qualificationDetails[0].localizations
    ) {
      this.setEmptyModal();
    }
    this._patchFormData(this.mappingData);
  }
}
