import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Constants } from './dashboard-constants';
import { SurveyStatus } from '../utils/survey-status';
import {
  DashboardPreference,
  SurveyListing,
} from '../shared/services/buyer-api/survey.interface';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { BuyerApiService } from '../shared/services/buyer-api/survey.service';
import { SupplierApiService } from '../shared/services/supplier-api/supplier-api.service';
import { AuthService } from '../auth/auth.service';
import { ModalService } from '@purespectrum1/ui/modal';
import { ChurnZeroConsts, ChurnZeroService } from '@purespectrum1/ui/tracking';
import { UserService } from '../operator/user-service/user.service';
import { USER_DASHBOARD_VIEW_OBJECT } from './dashboard-constants';
import {
  asyncScheduler,
  BehaviorSubject,
  combineLatest,
  forkJoin,
  iif,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  observeOn,
  scan,
  share,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { DashboardPreferenceService } from '../shared/services/dashboard-preference/dashboard-preference.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SocketService } from '../shared/services/socket/socket.service';
import { DashboardPreferenceState } from './domain/dashboard-preference';
import { DashboardPagination, EMPTY_VIEW } from './domain/dashboard-pagination';
import { DashboardState } from './domain/dashboard-state';
import { SearchView } from './survey-search/survey-search.component';
import { Counters, TCCreateAction } from './types';
import { DashboardTab, TabsFactory, TabState } from './domain/dashboard-tab';
import { DashboardSurveyTransform } from './domain/dashboard-survey-transform';
import { Router } from '@angular/router';
import { SurveyPayloadService } from '../create-surveys/survey-payload.service';
import {
  InactiveQualificationsMessageComposer,
  TrafficChannelModalComponent,
} from '@purespectrum1/ui';
import { BuyerService } from '../shared/services/buyer/buyer.service';
import { notifyMessage } from '../constants/notify-message';
import { TosComponent } from './tos/tos.component';
import { DashboardSelectedSurveys } from './domain/dasboard-survey-selected';
import { CommonService } from '../shared/services/common-service/common-service';
import { EachCountry } from '../shared/interfaces/common-service.interface';
import { DashboardClearSelectionConfirmation } from './dashboard-clear-selection-confirmation/dashboard-clear-selection-confirmation.component';
import {
  BuyerCombinedReport,
  CombinedReport,
  SupplierCombinedReport,
} from './bulk-edit/types';
import { ReportService } from '../buyer/download-reports/report-service/report.service';
import { CompanyInfo } from '../shared/interfaces/company.interface';
import { DashboardStateService } from './services/dashboard-state.service';
import { BuyerSurveyResponse } from '@purespectrum1/ui/marketplace/shared/interfaces/survey.interface';

@Component({
  selector: 'ps-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit, OnDestroy {
  public tabState: TabState = new TabState([]);
  public state$!: Observable<DashboardState>;
  public isMultiCountryWidget: boolean = false;

  public get tabIndex(): number {
    return this._dashboardStateService.lastTab;
  }

  public get tab(): DashboardTab {
    return this.tabState.tabs[this.tabIndex];
  }

  private _viewCommand$ = new Subject<SearchView>();
  private _paginationCommand$ = new Subject<DashboardPagination>();
  private _cloneCommand$ = new Subject<number>();
  private _deleteCommand$ = new Subject<number[]>();
  private _tcCommand$ = new Subject<TCCreateAction>();
  private _downloadCommand$ = new Subject<number>();
  private _bulkReportCommnad$ = new Subject<CombinedReport>();
  private _bulkEditCommand$ = new Subject<boolean>();
  private _companyCommand$ = new BehaviorSubject<CompanyInfo | undefined>(
    undefined
  );

  private _state!: DashboardState;
  private _subscription: Subscription = new Subscription();
  private _useCache = false;

  constructor(
    private _toastr: ToasterService,
    private _auth: AuthService,
    private _buyerApiService: BuyerApiService,
    private _supplierApiService: SupplierApiService,
    private _userService: UserService,
    private _churnZeroService: ChurnZeroService,
    private _dashboardPreferenceService: DashboardPreferenceService,
    private _deviceService: DeviceDetectorService,
    private _surveyPayloadService: SurveyPayloadService,
    private _buyerService: BuyerService,
    private _socketService: SocketService,
    private _commonService: CommonService,
    private _reportService: ReportService,
    private _dashboardStateService: DashboardStateService,
    private _modal: ModalService,
    private _router: Router
  ) {
    this._generateTabs();
    const index = this._dashboardStateService.lastTab;
    const status = this.tabState.tabs[index].status;

    this._state = DashboardState.initial(
      this._dashboardStateService.surveys.page,
      this._dashboardStateService.selected,
      status
    );
  }

  public ngOnInit(): void {
    this.initializeState();

    // Subscriptions
    this._subscription.add(this._updateView());
    this._subscription.add(this._clone());
    this._subscription.add(this._tc());
    this._subscription.add(this._download());
    this._subscription.add(this._downloadBuyerBulkReport());
    this._subscription.add(this._downloadSupplierBulkReport());

    // others
    this._checkTOS();
    this._churnZeroService.sendEvent(
      ChurnZeroConsts.events.lastActive,
      ChurnZeroConsts.eventDescriptions.lastActive
    );
  }

  public initializeState() {
    const dashboard$ = this._dashboard();
    const countries$ = this._commonService.getCountriesList();
    const [page$, search$] = this._pagination();
    const [surveys$] = this._surveys(dashboard$, countries$, search$, page$);
    const socket$ = this._sockets();
    const selected$ = this._selected();

    const delete$ = this._deleteCommand$.pipe(
      map((ids) => this._state.delete(ids)),
      tap(() => {
        return this._dashboardStateService.surveys.cache({
          surveys: this._state.surveys,
          company: this._state.company,
        });
      })
    );

    const bulkEdit$ = this._bulkEditCommand$.pipe(
      filter((edited) => edited),
      map(() =>
        this._state.copyWith({ selected: DashboardSelectedSurveys.empty() })
      )
    );

    this.state$ = merge(surveys$, delete$, socket$, selected$, bulkEdit$).pipe(
      tap((state) => {
        this._state = state;
      }),
      startWith(this._state)
    );
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public onSearch(value: string): void {
    this._dashboardStateService.search(value);
  }

  public onViewChange(view: SearchView): void {
    this._viewCommand$.next(view);
  }

  public onChangeTab(tab: number) {
    if (tab !== this.tabIndex) {
      this._dashboardStateService.tab(tab);
      this._dashboardStateService.company = undefined;
      this._companyCommand$.next(undefined);
    }
  }

  public onPagination(): void {
    if (this._state.canLoad(this.tab)) {
      this._paginationCommand$.next(this._state.nextPage());
    }
  }

  public onClone(id: number): void {
    this._cloneCommand$.next(id);
  }

  public onDelete(id: number[]): void {
    this._deleteCommand$.next(id);
  }

  public onTC(tc: TCCreateAction): void {
    this._tcCommand$.next(tc);
  }

  public onDownloadReport(id: number): void {
    this._downloadCommand$.next(id);
  }

  public onSurveysSelected(surveys: SurveyListing[]): void {
    this._dashboardStateService.select(surveys);
  }

  public onBulkReport(event: CombinedReport): void {
    this._bulkReportCommnad$.next(event);
  }

  public onBulkEdit(event: boolean): void {
    this._bulkEditCommand$.next(event);
  }

  public onBeforeTabChange(): () => Observable<boolean> {
    return () => {
      if (this._state.selected.some()) {
        return this._modal
          .open(DashboardClearSelectionConfirmation)
          .onClose$.pipe(tap(() => this._dashboardStateService.select([])));
      }

      return of(true);
    };
  }

  public onCompanyChange(company: CompanyInfo) {
    this._dashboardStateService.clearSelectionOfActiveTab();
    this._dashboardStateService.company = company;
    this._companyCommand$.next(company);
  }

  private _selected(): Observable<DashboardState> {
    return this._dashboardStateService.selected$.pipe(
      map((selection) => this._state.select(selection))
    );
  }

  private _pagination(): [
    Observable<DashboardPagination>,
    Observable<DashboardPagination>
  ] {
    const service$ = this._auth.loggedInAsServiceBuyer$.pipe(
      map((status) => ({ status, id: this._auth.serviceBuyer?.id ?? 0 }))
    );

    const tab$ = this._dashboardStateService.tab$.pipe(
      map((tab) => this.tabState.tabs[tab].status)
    );

    const bulkEdit$ = this._bulkEditCommand$.pipe(
      filter((edited) => edited),
      startWith(true)
    );

    const search$ = combineLatest([
      this._companyCommand$,
      this._dashboardStateService.search$,
      merge(this._view(), this._viewCommand$),
      tab$,
      service$,
      bulkEdit$,
    ]).pipe(
      scan((pagination, [company, search, view, tab, service]) => {
        const selectedCompanyId = this._dashboardStateService.company?.id;
        return pagination.copyWith({
          search,
          view,
          status: tab,
          isServiceBuyerRequest: service.status,
          buyerId: company?.id || selectedCompanyId || service.id,
        });
      }, DashboardPagination.first()),
      share()
    );

    const page$ = this._paginationCommand$.pipe(
      withLatestFrom(search$),
      map(([pagination, search]) => search.copyWith({ page: pagination.page })),
      tap((pagination) =>
        this._dashboardStateService.surveys.cache({ page: pagination.page })
      )
    );

    return [page$, search$];
  }

  private _surveys(
    config$: Observable<DashboardPreferenceState>,
    countries$: Observable<EachCountry[]>,
    search$: Observable<DashboardPagination>,
    page$: Observable<DashboardPagination>
  ): [
    Observable<DashboardState>,
    Observable<DashboardState>,
    Observable<Counters>
  ] {
    const pagination$ = merge(page$, search$).pipe(
      map((pagination) => {
        const page = this._useCache
          ? this._dashboardStateService.surveys.page
          : pagination.page;
        return pagination.copyWith({ page });
      })
    );

    const fetch$ = combineLatest([config$, countries$, pagination$]).pipe(
      scan((state, [config, countries, pagination]) => {
        return state.copyWith({
          preferences: config.copyWith({
            serviceOperator: this._auth.isLoggedInAsServiceBuyer(),
          }),
          pagination: pagination,
          countries: countries,
          surveys: this._state.surveys,
          company: config?.company,
        });
      }, this._state),
      switchMap((state) => this._fetch(state)),
      map((state) =>
        state.copyWith({
          selected:
            this._state.pagination.status == state.pagination.status
              ? this._state.selected
              : DashboardSelectedSurveys.empty(),
        })
      ),
      share()
    );

    const count$ = search$.pipe(
      switchMap((pagination) => this._count(pagination)),
      startWith([]),
      share()
    );

    const surveys$ = combineLatest([fetch$, count$]).pipe(
      tap(([_, counters]) => this.tabState.update(counters)),
      map(([state, _]) => state)
    );

    return [surveys$, fetch$, count$];
  }

  private _dashboard(): Observable<DashboardPreferenceState> {
    const userId = this._auth.user?.id || 0;
    const supplier$ = of(
      Constants.SUPPLIER_SURVEY_LISTING_TABLE_HEADERS as DashboardPreference[]
    ).pipe(observeOn(asyncScheduler));

    const buyer$ = this._dashboardPreferenceService.getDashboardPreference(
      userId,
      this._auth.isLoggedInAsServiceBuyer()
    );
    const enableBulkEditAndSorting$ = this._isBulkEditAndSortingEnabled();
    const dashboardPref$ = combineLatest([
      this._companyCommand$,
      enableBulkEditAndSorting$,
      iif(() => this._auth.userType === 'supplier', supplier$, buyer$),
    ]);
    return dashboardPref$.pipe(
      catchError((error) => {
        this._toastr.error(error.error.msg);
        return of([]);
      }),
      map(([company, enableBulkEditAndSorting, response]) => {
        return DashboardPreferenceState.from(
          response,
          this._deviceService.isMobile(),
          this._auth.userType,
          !!this._auth.buyerConfig?.enableDynamicDash ||
            this._auth.user?.operatorAcssLvls !== 'none',
          this._auth.buyerConfig?.enableTrafficChannels ?? false,
          this._auth.isLoggedInAsServiceBuyer(),
          enableBulkEditAndSorting,
          this._auth.buyerConfig?.enableMultiCountry ?? false,
          this._auth.buyerConfig?.enableHealthMetrics ?? false,
          company
        );
      }),
      tap((data) => {
        this.isMultiCountryWidget = !!data.columns.find(
          (column) => column.key === 'multiCountry'
        );
      })
    );
  }

  private _isBulkEditAndSortingEnabled(): Observable<boolean> {
    const service$ = this._auth.loggedInAsServiceBuyer$.pipe(
      map((isLoggedIn) => {
        const bulkEditFeature =
          this._auth.buyerConfig?.enableFilteringSortingBulkEdit ?? false;
        /**
         * We will show the bulk edit and sorting feature in the following cases.
         * 1. If service operator is logged-in and the selected buyer has enableFilteringSortingBulkEdit config enabled.
         * 2. If operator and has previous company selected.
         * 3. If enableFilteringSortingBulkEdit is enabled for buyer.
         */
        const enabledToServiceOperator = isLoggedIn && bulkEditFeature;
        const enabledToOperatorOrBuyer =
          !!this._dashboardStateService.company || bulkEditFeature;
        return enabledToServiceOperator || enabledToOperatorOrBuyer;
      }),
      tap(() => this._generateTabs())
    );

    const company$ = this._companyCommand$.pipe(
      map(() => {
        const companyConfigExists =
          !!this._auth.companyConfig.name && !!this._auth.companyConfig.id;
        return !!this._dashboardStateService.company || companyConfigExists;
      }),
      distinctUntilChanged()
    );

    const result$ =
      this._auth.userType === 'buyer' ? service$ : merge(service$, company$);

    return result$;
  }

  private _view(): Observable<SearchView> {
    const userId = this._auth.user?.id || 0;
    const supplier$ = of(EMPTY_VIEW).pipe(observeOn(asyncScheduler));

    const buyer$ = this._userService.getUserViewSelection(userId).pipe(
      map((response) =>
        this._userService.setSurveyFilterOptions(response, userId)
      ),
      map((options) => ({
        option: 0,
        filter:
          this._auth.userType === 'buyer'
            ? options.buyerFilterObject
            : options.serviceOperatorFilterObject,
        managers: options.projectManagerObject,
      })),
      catchError((error) => {
        this._toastr.error(error.error.msg);
        return of(EMPTY_VIEW);
      })
    );

    return iif(() => this._auth.userType === 'supplier', supplier$, buyer$);
  }

  private _fetch(state: DashboardState): Observable<DashboardState> {
    const supplier$ = this._supplierApiService
      .getSupplierSurveys(
        state.pagination.status as SurveyStatus,
        state.pagination.page,
        state.pagination.search
      )
      .pipe(map((response) => response.surveys));

    const buyer$ = this._buyerApiService.getSurveys(state.pagination.request());

    const surveys$ = iif(
      () => this._auth.userType === 'supplier',
      supplier$,
      buyer$
    ).pipe(
      catchError((error) => {
        this._toastr.error(error.error.msg);
        return of([]);
      }),
      map(
        (surveys) =>
          new DashboardSurveyTransform(surveys, this._auth.userType, [])
      ),
      map((surveys) => surveys.listing()),
      map((surveys) => state.concat(surveys)),
      tap((_state) =>
        this._dashboardStateService.surveys.cache({
          surveys: _state.surveys,
        })
      )
    );

    const cache$ = of(this._dashboardStateService.surveys.listing).pipe(
      map((surveys) => state.copyWith({ surveys })),
      tap(() => {
        this._useCache = false;
      })
    );

    return iif(() => this._useCache, cache$, surveys$);
  }

  private _count(pagination: DashboardPagination): Observable<Counters> {
    const buyer$ = this._buyerApiService
      .getSurveyCounts(
        SurveyStatus.Live,
        pagination.page,
        pagination.search,
        pagination.buyerId,
        pagination.view.managers.serviceOperator,
        pagination.view.managers.buyer
      )
      .pipe(map((response) => response.total));

    const supplier$ = this._supplierApiService
      .getSupplierSurveyCounts(SurveyStatus.Live, 1, pagination.search)
      .pipe(map((response) => response.count));

    return iif(() => this._auth.userType === 'supplier', supplier$, buyer$);
  }

  private _sockets(): Observable<DashboardState> {
    const status$ = this._socketService.surveyStatusChange$.pipe(
      filter((data) => this._state.contains(data.surveyId)),
      tap((data) => this.tabState.statusChange(data)),
      map((data) => this._state.statusChange(data, this.tab))
    );

    const progress$ = this._socketService.buyerProgressChannelV3$.pipe(
      filter((data) => this._state.contains(data.surveyId)),
      map((data) => this._state.progressChange(data))
    );

    const supplierData$ = this._socketService.dashboardChannel$.pipe(
      filter(
        ({ supplierManagement }) =>
          this._auth.userType === 'supplier' &&
          this._auth.user?.cmp === supplierManagement.supplier_id
      ),
      map(({ supplierManagement }) => ({
        surveyId: supplierManagement.survey_id,
        fielded: supplierManagement.fielded,
      }))
    );

    const surveyData$ = this._socketService.dashboardChannel$.pipe(
      filter(() => this._auth.userType !== 'supplier'),
      map(({ surveyManagement }) => ({
        surveyId: surveyManagement.survey_id,
        currentIR: `${surveyManagement.incidence_current}%`,
        averageCpi: surveyManagement.averageCpi,
        fielded: surveyManagement.fielded,
      }))
    );

    const dashData$ = merge(supplierData$, surveyData$).pipe(
      filter((data) => this._state.contains(data.surveyId)),
      map((data) => this._state.dataChange(data))
    );

    return merge(status$, progress$, dashData$);
  }

  private _checkTOS(): void {
    const user = this._auth.user;
    const tosAccepted = this._auth?.user?.tos?.accept;
    const internalUser = user && user.eml.includes('@purespectrum.com');
    if (!tosAccepted && !internalUser) {
      this._modal.open(TosComponent);
    }
  }

  private _generateTabs(): void {
    const tabs = new TabsFactory(
      this._auth.userType,
      this._auth.buyerConfig?.enableInvoiceTab ?? false,
      this._auth.isLoggedInAsServiceBuyer()
    ).create();

    this.tabState = new TabState(tabs);
  }

  private _updateView(): Subscription {
    return this._viewCommand$
      .pipe(
        switchMap((view) =>
          this._userService.setUserViewSelection(this._auth.user?.id, {
            option: view.option,
            pmId: [
              USER_DASHBOARD_VIEW_OBJECT.VIEW_BY_PM,
              USER_DASHBOARD_VIEW_OBJECT.VIEW_MY_PROJECTS_BUYER,
              USER_DASHBOARD_VIEW_OBJECT.VIEW_UNASSIGNED,
            ].includes(view.option)
              ? view.managers.buyer
              : view.managers.serviceOperator,
          })
        )
      )
      .subscribe();
  }

  private _clone(): Subscription {
    return this._cloneCommand$
      .pipe(
        switchMap((id) => {
          if (this._isMcQbSurvey(id)) {
            this._toastr.info(
              notifyMessage.infoMessage.MC_SINGLE_VIEW
                .CLONE_MC_SVP_NOT_AVAILABLE
            );
            return of(null);
          }
          return this._buyerApiService
            .cloneSurvey(id, this._auth.isLoggedInAsServiceBuyer())
            .pipe(
              catchError((error) => {
                let errorMessage =
                  'Error encountered while attempting to clone this survey.';
                if (error?.error?.ps_api_response_message) {
                  errorMessage = error?.error?.ps_api_response_message;
                }
                this._toastr.error(errorMessage);
                return of(null);
              })
            );
        }),
        filter((value) => !!value),
        map((value) => value as BuyerSurveyResponse)
      )
      .subscribe((response) => {
        const clonedSurveyId = response.ps_survey_id;
        const inactiveQualificationMessage =
          new InactiveQualificationsMessageComposer(
            response.inactive_qualifications,
            'clone-survey'
          ).compose();
        if (inactiveQualificationMessage) {
          this._toastr.warning(inactiveQualificationMessage, '', {
            enableHtml: true,
          });
        }
        this._router.navigate([`/surveys/${clonedSurveyId}/edit`], {
          state: {
            isCloneSurvey: true,
            is_race_qualification_removed:
              response.is_race_qualification_removed,
          },
        });
      });
  }

  private _tc(): Subscription {
    return this._tcCommand$
      .pipe(
        switchMap((action) =>
          forkJoin({
            survey: this._buyerApiService.getSurvey(action.id),
            tcs: this._buyerApiService.getTrafficChannelData(action.id),
            quotas: this._buyerApiService.getQuotaDetails(action.id, {
              page: 1,
              limit: Constants.QUOTAPAGELIMIT,
              search: '',
            }),
          }).pipe(map((response) => ({ ...action, response })))
        ),
        map((tc) => {
          const survey = this._surveyPayloadService.mapBuyerSurvey(
            tc.response.survey
          );
          return {
            id: tc.id,
            type: tc.type,
            survey: survey,
            surveyQuotas: tc.response.quotas,
            tcSurveys: tc.response.tcs,
          };
        }),
        switchMap((data) =>
          this._modal
            .open(TrafficChannelModalComponent, {
              data,
              width: '60%',
            })
            .onClose$.pipe(map((msg) => ({ msg, id: data.id })))
        ),
        filter((response) => response.msg === 'Created')
      )
      .subscribe((response) =>
        this._toastr.success(
          `${notifyMessage.TRAFFIC_CHANNEL_SURVEY_CREATED_SUBPART} ${response.id}`
        )
      );
  }

  private _download(): Subscription {
    return this._downloadCommand$
      .pipe(
        switchMap((id) =>
          this._buyerService.downloadTransactionReport(id, false).pipe(
            catchError(() => {
              this._toastr.error(
                notifyMessage.errorMessage.DOWNLOAD_REPORT_ERROR
              );
              return of(null);
            })
          )
        ),
        filter((value) => !!value)
      )
      .subscribe(() => console.log('success'));
  }

  private _downloadBuyerBulkReport() {
    return this._bulkReportCommnad$
      .pipe(
        filter((report) => report.type === 'buyer'),
        map((report) => report as BuyerCombinedReport),
        switchMap((report) =>
          this._buyerService
            .downloadCombinedReport(report.surveys, report.testTransactions)
            .pipe(
              catchError((e) => {
                const error =
                  e || notifyMessage.errorMessage.DOWNLOAD_REPORT_ERROR;
                this._toastr.error(error);
                return of(null);
              })
            )
        )
      )
      .subscribe();
  }

  private _downloadSupplierBulkReport() {
    return this._bulkReportCommnad$
      .pipe(
        filter((report) => report.type === 'supplier'),
        map((report) => report as SupplierCombinedReport),
        switchMap((report) =>
          this._reportService
            .requestMultiSurveysStatementReport(
              this._auth.email(),
              report.surveys
            )
            .pipe(
              catchError((e) => {
                const message =
                  e && e.error
                    ? e.error.error
                    : notifyMessage.errorMessage.DOWNLOAD_REPORT_ERROR;
                return of({ status: 'Error', message });
              })
            )
        )
      )
      .subscribe((result) => {
        if (result.status !== 'Success') {
          this._toastr.error(result.message);
        }

        this._toastr.success(result.message);
      });
  }

  private _isMcQbSurvey(id: number) {
    return !!this._state?.surveys?.find(
      (survey) => survey.surveyId === id && survey.mc && survey.mc?.SVP
    );
  }
}
