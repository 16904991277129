import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { UploadTrafficDensityFileResponse } from './traffic-density-file.interface';

@Injectable({
  providedIn: 'root',
})
export class TrafficDensityFileService {
  private readonly _surveyStatiscticsServiceUrl =
    environment.pureSpectrum.surveyStatiscticsServiceUrl;

  constructor(private _http: HttpClient) {}

  uploadTrafficDensityFile(
    formData: FormData,
    country: string
  ): Observable<UploadTrafficDensityFileResponse> {
    return this._http.post<UploadTrafficDensityFileResponse>(
      `${this._surveyStatiscticsServiceUrl}/traffic-density/upload/${country}`,
      formData,
      {
        headers: {
          skipInterceptor: 'true',
        },
      }
    );
  }

  downloadCurrentTrafficDensityFile(country: string): Observable<Blob> {
    return this._http.get<Blob>(
      `${this._surveyStatiscticsServiceUrl}/traffic-density/file/${country}`,
      { responseType: 'blob' as 'json' }
    );
  }

  downloadTrafficDensityFileTemplate(): Observable<Blob> {
    return this._http.get<Blob>(
      `${this._surveyStatiscticsServiceUrl}/traffic-density/template-file`,
      { responseType: 'blob' as 'json' }
    );
  }
}
