import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Netsuite } from '../../interfaces/netsuite.interface';

@Injectable({
  providedIn: 'root',
})
export class NetsuiteService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getNsCompany(nsInternalId: number) {
    return this._http.get<Netsuite>(
      `${this._baseUrl}/netsuite/${nsInternalId}`
    );
  }

  fetchNetSuiteCompany(nsInternalId: number, userType: string) {
    return this._http.get<Netsuite>(
      `${this._baseUrl}/netsuite/${nsInternalId}/${userType}`
    );
  }
}
