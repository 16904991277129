import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToasterService } from '@purespectrum1/ui/toaster-service';

import { AuthService } from '../auth.service';
import { environment } from '../../../../src/environments/environment';

@Component({
  selector: 'ps-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  form!: FormGroup;
  public readonly captchaSiteKey = environment.CATPTCHA_SITE_KEY;
  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _router: Router,
    private _toastr: ToasterService
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group({
      email: [null, [Validators.email, Validators.required]],
      captcha: [null],
    });
  }

  forgotPassword() {
    this._auth.forgotPassword(this.form.value).subscribe(
      (res) => {
        this._toastr.success(res.msg);
        this._router.navigateByUrl('/login');
      },
      (err) => {
        const errorMessage = err.error.msg;
        this._toastr.error(errorMessage);
      }
    );
  }

  resolved(captchaResponse: string) {
    this.form.patchValue({ captcha: captchaResponse });
  }
}
