<form
  *ngIf="
    isOperator &&
    class === CONSTANTS.MAPPED_CLASS_TYPE.DATA_QUALITY &&
    questionType !== CONSTANTS.QUESTION_TYPE.OPENENDED
  "
  #dqForm="ngForm"
>
  <hr />
  <div class="row">
    <div class="col-sm-12 padding-0 quality-check">
      <label>Quality Check</label>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 padding-0">
      <label class="text-muted">Low Quality Response(s)</label>
    </div>
  </div>
  <div class="row quality-check-box" *ngIf="!isEditLocalizationMode">
    <div class="col-sm-4 padding-left-0">
      <div class="row">
        <div
          class="form-group col-md-12"
          *ngIf="
            questionType === CONSTANTS.QUESTION_TYPE.MULTIPUNCH ||
            questionType === CONSTANTS.QUESTION_TYPE.SINGLEPUNCH
          "
        >
          <ps-ui-select-dropdown
            id="multi-with-search"
            [multi]="true"
            [dropdownItems]="answers"
            [idField]="'id'"
            [field]="'id'"
            [spanField]="'label'"
            [enableSearch]="false"
            [labelNone]="multiLabelText"
            [allSelected]="false"
            [firstSelected]="false"
            [disabled]="!isAnswerAvailable"
            [loading]="false"
            (selectedItems)="setSelectedItems($event)"
          ></ps-ui-select-dropdown>
          <div *ngIf="questionType === CONSTANTS.QUESTION_TYPE.MULTIPUNCH">
            <label class="label-control">Condition</label>
            <div class="col-xs-12 dependent-radios">
              <label class="col-sm-3 radio-inline">
                <input
                  type="radio"
                  name="condition"
                  [value]="'or'"
                  [(ngModel)]="condition"
                />
                or
              </label>
              <label class="col-sm-3 radio-inline">
                <input
                  type="radio"
                  name="condition"
                  [value]="'and'"
                  [(ngModel)]="condition"
                />
                and
              </label>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="questionType === CONSTANTS.QUESTION_TYPE.RANGE">
          <div class="form-group col-md-6">
            <input
              type="number"
              name="min"
              class="form-control"
              placeholder="Min"
              [value]="range.min"
              [(ngModel)]="range.min"
              (input)="setSelectedItems([])"
            />
          </div>
          <div class="form-group col-md-6">
            <input
              type="number"
              name="max"
              class="form-control"
              placeholder="Max"
              [value]="range.max"
              [(ngModel)]="range.max"
              (input)="setSelectedItems([])"
            />
          </div>
        </div>
        <div class="col-sm-12 search-qualification">
          <a (click)="openSearchQualificationModel()">Search Qualifications</a>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row quality-textarea-margin">
        <div class="col-md-12 quality_check_textarea">
          <div class="form-group">
            <label>
              <p>Answer appear in "Or" format by default:(1 or 2 or 3)</p>
            </label>
            <textarea
              type="text"
              class="form-control"
              [value]="generateLowQualityResponseText(qualityRule)"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group col-md-2">
      <button
        type="button"
        class="btn add_input_btn btn-common"
        (click)="addQualityRules()"
      >
        +
      </button>
    </div>
  </div>

  <div
    class="row quality-check-box mb-15"
    *ngFor="let rule of qualityRules; let i = index"
  >
    <div class="form-group col-md-10 padding-left-0">
      <textarea
        type="text"
        class="form-control"
        [value]="generateLowQualityResponseText(rule)"
        disabled
      ></textarea>
    </div>
    <div class="form-group col-md-2" *ngIf="!isEditLocalizationMode">
      <button
        type="button"
        class="btn btn-danger delete_icon btn-common"
        (click)="removeQualityRules(i)"
      >
        x
      </button>
    </div>
  </div>
</form>
