import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ISurveyTransactionIn,
  ISurveyTransactionOut,
} from '../qa-tools-service/qa-tools.interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QaToolsService {
  private readonly _qaToolsUrl = environment.pureSpectrum.qaToolsUrl;

  constructor(private _http: HttpClient) {}

  createTransactions(input: ISurveyTransactionIn) {
    return this._http.post<ISurveyTransactionOut>(
      `${this._qaToolsUrl}/qatools/v1/transactions/`,
      input
    );
  }
}
