import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  GetPricingMarginResponse,
  SavePricingMarginResponse,
} from '../operator-settings.interface';
@Injectable({
  providedIn: 'root',
})
export class MarginService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  savePricingMargin(data: SavePricingMargin) {
    return this._http.post<SavePricingMarginResponse>(
      `${this._baseUrl}/pricing/margin/save`,
      data
    );
  }

  getPricingMargin(buyer_id: Number, supplier_id: Number) {
    return this._http.get<GetPricingMarginResponse>(
      `${this._baseUrl}/pricing/margin/fetch?buyer_id=${buyer_id}&supplier_id=${supplier_id}`
    );
  }
}

interface SavePricingMargin {
  buyer_id: number;
  supplier_id: number;
  margin: number;
}
