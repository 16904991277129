import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ps-notification-status',
  templateUrl: './notification-status.component.html',
  styleUrls: ['../base-form.css'],
})
export class NotificationStatusComponent {
  @Input()
  form!: FormGroup;

  constructor() {}
}
