<ps-ui-modal id="mapping_modal">
  <h5 psUiModalTitle>Add New Qualifications</h5>
  <ps-ui-modal-body>
    <div class="modal-body container mb-5">
      <div class="cstm_text_field modal-max-height" *ngIf="customScreenerForm">
        <form [formGroup]="customScreenerForm" novalidate="novalidate">
          <div class="row survey_form">
            <div *ngIf="customScreenerForm">
              <label class="cstm_label_field">Question Name</label> <br />
              <input
                class="col-md-12 form-control"
                type="text"
                formControlName="qName"
              />
              <span
                class="help-block form-error text-danger small error_msg"
                *ngIf="
                  customScreenerForm.controls['qName'].hasError('required') &&
                  customScreenerForm.controls['qName'].touched
                "
                >Field cannot be empty.
              </span>
              <br />
              <span
                class="help-block form-error text-danger small error_msg"
                *ngIf="customScreenerForm.controls['qName'].hasError('pattern')"
                >Please remove special characters in
                {{ customScreenerForm.value.qName }}. Only allowed characters
                are a-z, numbers, spaces.
              </span>
              <!-- custom screener class Questions -->
              <div class="row">
                <div class="col-sm-6">
                  <label class="cstm_label_field">Question Type </label>
                  <br />
                  <div class="switch_wrap">
                    <div class="btn-group" data-toggle="buttons">
                      <div *ngFor="let option of options">
                        <label
                          class="btn btn-select btn-on"
                          [ngClass]="[
                            (customScreenerForm?.getRawValue())['type'] ===
                            option['value']
                              ? 'active'
                              : ''
                          ]"
                          (click)="addRow(option['value'])"
                          id="type{{ option.id }}"
                        >
                          <input
                            type="radio"
                            class="displayNone"
                            [checked]="
                              customScreenerForm.value['type'] ===
                              option['value']
                                ? true
                                : false
                            "
                            [value]="1"
                            name="type"
                            (click)="addRow(option['value'])"
                            formControlName="type"
                          />
                          {{ option.label }}
                        </label>
                        <br />
                      </div>
                    </div>
                    <div>
                      <span
                        class="help-block form-error text-danger small error_msg"
                        *ngIf="
                          customScreenerForm.controls['type'].hasError(
                            'required'
                          ) && customScreenerForm.controls['type'].touched
                        "
                        >Field cannot be empty.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" style="margin-left: -9px">
                  <label class="cstm_label_field" style="padding-bottom: 12px"
                    >Language</label
                  >
                  <br />
                  <ps-ui-select-dropdown
                    class="col-md-4 mb-1"
                    [dropdownItems]="langData"
                    [field]="'localizationCode'"
                    (selectedItem)="selectLangOption($event)"
                  ></ps-ui-select-dropdown>
                  <div class="info" *ngIf="localisationNotAvailable">
                    <span class="fa-stack">
                      <em
                        [ngStyle]="{
                          color: localisationSaveError ? 'red' : '#ffca1c'
                        }"
                        class="fa fa-circle fa-stack"
                      ></em>
                      <em class="fa fa-info"></em> </span
                    ><span
                      [ngStyle]="{
                        color: localisationSaveError ? 'red' : '#ffca1c'
                      }"
                      class="info-text"
                      >{{ localisationError }}</span
                    >
                  </div>
                </div>
              </div>

              <label class="cstm_label_field">Question Text</label> <br />
              <div class="ques-row">
                <div class="input-check-box">
                  <input
                    class="form-control"
                    type="text"
                    formControlName="text"
                  />
                  <span
                    class="help-block form-error text-danger small error_msg"
                    *ngIf="
                      customScreenerForm.controls['text'].hasError('pattern')
                    "
                    >Please remove special characters in
                    {{ customScreenerForm.value.text }}. Only allowed characters
                    are letters, numbers, spaces and special characters (except
                    ", \)
                  </span>
                </div>
                <span
                  class="help-block form-error text-danger small error_msg"
                  *ngIf="
                    customScreenerForm.controls['text'].hasError('required') &&
                    customScreenerForm.controls['text'].touched
                  "
                  >Field cannot be empty.
                </span>
              </div>
            </div>
            <div class="other-above-check">
              <div class="quotaclass add_quotas">
                <div class="cs-checkbox">
                  <input
                    type="checkbox"
                    [id]="'CS_Other'"
                    class="screenerCheckbox"
                    formControlName="other"
                    (change)="manageOtherAndNoneOfAboveEvents(998)"
                  />

                  <label [for]="'CS_Other'">
                    {{ this.newQuestion ? 'Add Other' : 'Other' }}</label
                  >
                </div>
              </div>
              <div class="quotaclass add_quotas">
                <div class="cs-checkbox">
                  <input
                    type="checkbox"
                    [id]="'CS_noneOfAbove'"
                    class="screenerCheckbox"
                    formControlName="noneOfAbove"
                    (change)="manageOtherAndNoneOfAboveEvents(999)"
                  />

                  <label [for]="'CS_noneOfAbove'">
                    {{
                      this.newQuestion
                        ? 'Add None of the above'
                        : 'None of the above'
                    }}</label
                  >
                </div>
              </div>
            </div>
            <div
              *ngIf="customScreenerForm.get('answers')"
              formArrayName="answers"
            >
              <div
                *ngFor="
                  let answer of getcustomScreenerFormControl();
                  let j = index
                "
                [formGroupName]="j"
              >
                <span *ngIf="j === findIndexOfSelectedLocale()">
                  <br />
                  <label class="cstm_label_field">Answers</label>
                  <div
                    *ngIf="
                      getcustomScreenerFormRowIndexControl(
                        findIndexOfSelectedLocale()
                      )
                    "
                    formArrayName="rows"
                  >
                    <div
                      *ngFor="
                        let row of getcustomScreenerFormRowIndexControl(
                          findIndexOfSelectedLocale()
                        );
                        let i = index
                      "
                      [formGroupName]="i"
                      class="row"
                    >
                      <div class="col-md-11 margin-top">
                        <span formGroupName="text">
                          <input
                            type="text"
                            formControlName="textItem"
                            class="form-control"
                          />
                        </span>
                        <!--   custom screener condition codes  -->
                        <input
                          [hidden]="true"
                          type="text"
                          formControlName="condition_code"
                          class="cstm_input_field width80"
                        />
                      </div>
                      <div class="col-md-1 margin-top">
                        <button
                          *ngIf="
                            checkLocalesCondition(locale) &&
                            i !==
                              defaultRows[findIndexOfSelectedLocale()].length -
                                1 &&
                            row.value['condition_code'] !== 998 &&
                            row.value['condition_code'] !== 999
                          "
                          class="add-button"
                          (click)="removeSubControl(i)"
                          [ngClass]="[!allowEditQuestion ? 'disallowed' : '']"
                        >
                          <em class="fa fa-times"></em>
                        </button>
                        <button
                          *ngIf="
                            checkLocalesCondition(locale) &&
                            i ===
                              defaultRows[findIndexOfSelectedLocale()].length -
                                1 &&
                            row.value['condition_code'] !== 998 &&
                            row.value['condition_code'] !== 999
                          "
                          (click)="createFieldsForAllLocales({})"
                          class="add-button"
                        >
                          <em class="fa fa-plus" aria-hidden="true"></em>
                        </button>
                      </div>
                      <!-- custom screener condition codes  -->

                      <span
                        class="help-block form-error text-danger small error_msg"
                        *ngIf="
                          getcustomScreenerAnswerOptionsControl(
                            findIndexOfSelectedLocale(),
                            i
                          ).hasError('required') &&
                          getcustomScreenerAnswerOptionsControl(
                            findIndexOfSelectedLocale(),
                            i
                          ).touched
                        "
                        >Field cannot be empty.
                      </span>
                      <span
                        class="help-block form-error text-danger small error_msg"
                        *ngIf="
                          getcustomScreenerAnswerOptionsControl(
                            findIndexOfSelectedLocale(),
                            i
                          ).hasError('pattern')
                        "
                        >Please remove special characters in
                        {{
                          getcustomScreenerAnswerOptionsControl(
                            findIndexOfSelectedLocale(),
                            i
                          ).value
                        }}. Only allowed characters are letters, numbers, spaces
                        and special characters (except ", \)
                      </span>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ps-ui-modal-body>
  <ps-ui-modal-footer>
    <button psUiButton (click)="saveQuestion()">Save</button>
    <button psUiButton (click)="close()">close</button>
  </ps-ui-modal-footer>
</ps-ui-modal>
