import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
  FormControl,
} from '@angular/forms';
import { QuestionsLibraryService } from '../../../questions-library-services/questions-library-services.service';
import { Constants } from '../../../questions-library.constants';
import {
  Answer,
  ParentQuestion,
  Localization,
  ChildQuestion,
} from '../../../questions-library-services/questions-library-services.interface';

@Component({
  selector: 'ps-segmentation',
  templateUrl: './segmentation.component.html',
  styleUrls: ['./segmentation.component.css'],
})
export class SegmentationComponent {
  @Input() isEditingLocalization: boolean = false;
  @Input() classType?: number;
  @Input() clonedLocalization?: Localization;
  @Input() enableSegmentationDependentQuestion: boolean = false;

  form!: FormGroup;
  questionList: ParentQuestion[] = [];
  childQuestionList: ChildQuestion[] = [];
  parentData: Answer[] = [];
  isLoading: boolean = false;
  readonly CONSTANTS = Constants;

  constructor(
    fb: FormBuilder,
    rootFormGroup: FormGroupDirective,
    private _questionsLibraryService: QuestionsLibraryService
  ) {
    this.form = this._generateForm(fb);
    rootFormGroup.control.addControl('segmentation', this.form);
  }

  private _generateForm(fb: FormBuilder): FormGroup {
    return fb.group({
      parentQuestion: ['', Validators.required],
    });
  }

  public onLocaleSelected(locale: string, segmentation: any = {}) {
    if (!locale) {
      return;
    }
    this.isLoading = true;
    this._questionsLibraryService
      .getParentQuestions(locale, 0, 0)
      .subscribe((questionList: any) => {
        this.isLoading = false;
        this.questionList = questionList;
        if (Object.keys(segmentation).length) {
          this._setForm(segmentation);
        }
      });
  }

  public getChildQuestions(
    locale: string,
    enableSegmentationDependentQuestion: boolean
  ) {
    if (enableSegmentationDependentQuestion) {
      if (!locale) {
        return;
      }
      this.isLoading = true;
      this._questionsLibraryService
        .getChildQuestions(locale, enableSegmentationDependentQuestion)
        .subscribe((questionList: any) => {
          this.isLoading = false;
          this.childQuestionList = questionList;
          this.form.addControl(
            'childQuestion',
            new FormControl('', [Validators.required])
          );
        });
    } else {
      this.form.removeControl('childQuestion');
    }
  }

  childQuestionSelected(parentQuestion: any) {
    if (this.enableSegmentationDependentQuestion) {
      this.parentData = parentQuestion.answers;
      this.form.addControl(
        'answerOption',
        new FormControl('', [Validators.required])
      );
    }
  }

  private _setForm(segmentation: any) {
    const parentQuestion = this.questionList.find(
      (question: ParentQuestion) =>
        question.qualification_id === segmentation.parent_qualification_id
    );
    this.form.patchValue({
      parentQuestion: parentQuestion,
    });
  }
}
