import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ModalData, ModalRef, ModalService } from '@purespectrum1/ui/modal';
import { BuyerApiService } from '../../shared/services/buyer-api/survey.service';
import { Subscription } from 'rxjs';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Constants } from '../dashboard-constants';
import { Constants as SurveyConstants } from '../../../app/create-surveys/create-survey.constants';
import { CommonService } from '../../shared/services/common-service/common-service';
import { SURVEY_STATUS } from '../../constants/survey-status';
import { map } from 'rxjs/operators';
import {
  ChangeSurveyModalData,
  ChangeSurveyModalTemplateData,
  changeSurveyTemplateFactory,
} from './templates/change-survey-modal.template';
import { ChurnZeroConsts, ChurnZeroService } from '@purespectrum1/ui';
import { HighCpiConfrmationResponse } from '../../shared/services/buyer-api/survey.interface';
import { ConfirmationModalComponent } from '../../shared/ui/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'ps-change-survey-status-modal',
  templateUrl: './change-survey-status-modal.component.html',
  styleUrls: ['./change-survey-status-modal.component.css'],
})
export class ChangeSurveyStatusModalComponent implements OnInit, OnDestroy {
  survey: ChangeSurveyModalData = this._modalData;
  showSuccessMessage = false;
  statusUpdateMsg = '';
  template?: ChangeSurveyModalTemplateData;

  private _subscription: Subscription = new Subscription();
  readonly MODEL_STATUS = Constants.MODEL_STATUS;

  constructor(
    @Inject(ModalData)
    private _modalData: ChangeSurveyModalData,
    private _modalRef: ModalRef<ChangeSurveyStatusModalComponent, string>,
    private _buyerApiService: BuyerApiService,
    private _toastr: ToasterService,
    private _commonService: CommonService,
    private _churnZeroService: ChurnZeroService,
    private _modal: ModalService
  ) {}

  ngOnInit(): void {
    this._setTemplateData();
  }

  _setTemplateData() {
    this.template = changeSurveyTemplateFactory(this.survey).getData();
  }

  close(msg?: string) {
    if (this.statusUpdateMsg && !this.statusUpdateMsg.includes('deleted')) {
      this._modalRef.close(Constants.MODAL_MESSAGE.UPDATE_SURVEY_STATUS);
    } else if (
      this.statusUpdateMsg &&
      this.statusUpdateMsg.includes('deleted')
    ) {
      this._modalRef.close(Constants.MODAL_MESSAGE.DELETE_SURVEY);
    } else {
      this._modalRef.close(msg);
    }
  }

  async checkForHighCPI() {
    const highCPIDetailsSub = this._buyerApiService
      .getSurveyHighCpiDetails(this.survey.surveyId)
      .subscribe(
        async (response: HighCpiConfrmationResponse) => {
          if (response.isPriceGreaterThanCpiThreshold) {
            const isConfirmed = await this._modal
              .open(ConfirmationModalComponent, {
                data: {
                  innerHtml:
                    SurveyConstants.CONFIRM_MODAL_TEXT.HIGH_CPI.replace(
                      '<CPI>',
                      `${response.pricingDetails.value}`
                    ).replace(
                      '<CURRENCY_SYMBOL>',
                      `${response.pricingDetails.currency || '$'}`
                    ),
                  confirmButtonLabel: 'Confirm',
                },
                maxWidth: '25%',
              })
              .onClose$.pipe(map((msg) => msg === 'ok'))
              .toPromise();
            if (!isConfirmed) {
              return false;
            }
            return this.updateSurveyStatus();
          } else {
            return this.updateSurveyStatus();
          }
        },
        (errorStatusResponse) => {
          this._toastr.error(errorStatusResponse.error.ps_api_response_message);
        }
      );
    this._subscription.add(highCPIDetailsSub);
  }

  updateSurveyStatus() {
    const ps_survey_status: number = this._commonService.getSurveyStatus(
      this.survey.status
    );
    const updatedSurveyStatus = this._buyerApiService
      .updateSurveyStatus(this.survey.surveyId, { ps_survey_status })
      .subscribe(
        () => {
          if (
            Constants.SURVEY_STATUS_CHANGE_FOR_CHURNZERO.includes(
              ps_survey_status
            )
          ) {
            const newStatusChurnZeroUpdate =
              ps_survey_status === SURVEY_STATUS.PAUSED
                ? 'surveyPaused'
                : 'surveyClosed';
            this._churnZeroService.sendEvent(
              ChurnZeroConsts.events[newStatusChurnZeroUpdate],
              ChurnZeroConsts.eventDescriptions[
                newStatusChurnZeroUpdate
              ].replace(':id', this.survey.surveyId + '')
            );
          }

          this.statusUpdateMsg = `#${this.survey.surveyId} is in ${this.survey.status} state`;
          this.showSuccessMessage = true;
          setTimeout(() => {
            this._modalRef.close(Constants.MODAL_MESSAGE.UPDATE_SURVEY_STATUS);
          }, 3000);
        },
        (errorStatusResponse) => {
          this._toastr.error(errorStatusResponse.error.ps_api_response_message);
        }
      );
    this._subscription.add(updatedSurveyStatus);
  }

  deleteSurvey() {
    this._buyerApiService.deleteDraftSurvey(this.survey.surveyId).subscribe(
      (resp) => {
        if (this.survey.isMc) {
          return this._modalRef.close(Constants.MODAL_MESSAGE.DELETE_SURVEY);
        }

        this.statusUpdateMsg = `#${this.survey.surveyId} is deleted`;
        this.showSuccessMessage = true;
        setTimeout(() => {
          this._modalRef.close(Constants.MODAL_MESSAGE.DELETE_SURVEY);
        }, 3000);
      },
      (error) => {
        this._toastr.error(error.error.ps_api_response_message);
      }
    );
  }

  showPauseThersholdMessage(survey: any) {
    if (
      !survey.toDelete &&
      survey.status === 'Live' &&
      !this.showSuccessMessage &&
      !!survey.survey_pause_threshold &&
      survey.is_survey_pause_threshold_triggered
    ) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
