<ps-ui-modal>
  <div class="ps-modal-header" psUiModalTitle>
    <h5>PSIDs Upload Results</h5>
  </div>
  <ps-ui-modal-body>
    <div class="confirmation-content">
      <h6>PSIDs Processed Summary:</h6>
      <ul>
        <li>Whitelisted : {{ data.whitelisted.ok || 0 }}</li>
        <li>Not Whitelisted : {{ data.whitelisted.error || 0 }}</li>
        <li>Blacklisted : {{ data.blacklisted.ok || 0 }}</li>
        <li>Not Blacklisted : {{ data.blacklisted.error || 0 }}</li>
      </ul>
    </div>
  </ps-ui-modal-body>
  <ps-ui-modal-footer position="center">
    <button type="button" class="close-btn" (click)="close()">Close</button>
  </ps-ui-modal-footer>
</ps-ui-modal>
