import { Injectable } from '@angular/core';
import {
  BuyerQualifications,
  LocalizationFormat,
  ProcessedTarget,
  ProcessedTargetMapping,
  QualificationData,
  RangeSet,
} from '../../../interfaces/qualification-mappings.interface';

@Injectable({
  providedIn: 'root',
})
export class DecipherImportHelperService {
  constructor() {}

  public hasDuplicate = (target: Array<ProcessedTarget>): boolean => {
    const uniqueArr = new Set<any>();
    for (const obj of target) {
      // do not check duplicacy of non mapped decipher conditions.
      const isUnMappedTarget = this._checkIfUnmappedCondition(obj.mappings);
      if (isUnMappedTarget) {
        continue;
      }
      const objectString = JSON.stringify(this._sortMappings(obj.mappings));
      if (uniqueArr.has(objectString)) {
        return true;
      }
      uniqueArr.add(objectString);
    }
    return false;
  };

  private _sortMappings = (mappings: Array<ProcessedTargetMapping>) => {
    mappings.sort(
      (a: ProcessedTargetMapping, b: ProcessedTargetMapping) =>
        a.qualificationCode - b.qualificationCode
    );
    mappings.forEach((item: ProcessedTargetMapping) => {
      if (item.conditionCodes) {
        item.conditionCodes = this.shrinkConditionCodes(item.conditionCodes);
      }
    });
    return mappings;
  };

  /**
   * check if conditionCodes or rangeSets of all the qualification are empty for a decipher condition.
   * [
   *  {qualificationCode : 223, condition_codes : []},
   *  {qualificationCode : 212, rangeSet : {from: null, to : null}}
   * ]
   *  */
  private _checkIfUnmappedCondition = (
    mappings: Array<ProcessedTargetMapping>
  ): boolean => {
    return mappings.every((mapping) => {
      if (mapping.conditionCodes) {
        return !mapping.conditionCodes.filter(Boolean).length;
      }
      const { rangeSets: { from, to } = {} } = mapping;
      return from == null && to == null;
    });
  };

  public hasAtleastOneMapping = (
    targetObjs: ProcessedTarget[],
    qualificationDetails: BuyerQualifications[]
  ) => {
    return targetObjs.some((item: ProcessedTarget) => {
      return this.isOptionCorrectlyMapped(item.mappings, qualificationDetails);
    });
  };

  public hasAllMappings = (
    targetObjs: ProcessedTarget[],
    qualificationDetails: BuyerQualifications[]
  ) => {
    return targetObjs.every((item: ProcessedTarget) => {
      return this.isOptionCorrectlyMapped(item.mappings, qualificationDetails);
    });
  };

  public hasNoPartialMapping = (
    targetObjs: ProcessedTarget[],
    qualificationDetails: BuyerQualifications[]
  ) => {
    return targetObjs.every((item: ProcessedTarget) => {
      return (
        this.isOptionCorrectlyMapped(item.mappings, qualificationDetails) ||
        this.isOptionUnmapped(item.mappings)
      );
    });
  };

  public isOptionCorrectlyMapped = (
    mappings: ProcessedTargetMapping[],
    buyerQualifications: BuyerQualifications[]
  ) => {
    return mappings.every((mapping: ProcessedTargetMapping) => {
      const { qualificationCode, conditionCodes, rangeSets } = mapping;
      const localization = this._getLocalization(
        qualificationCode,
        buyerQualifications
      );
      if (!localization) {
        return false;
      }
      if (conditionCodes?.filter(Boolean).length) {
        return this._isValidConditionCodes(
          conditionCodes,
          localization.answers!
        );
      } else if (rangeSets) {
        return this.isValidRange(
          rangeSets,
          localization.format as LocalizationFormat
        );
      }
      return false;
    });
  };

  private _isValidConditionCodes = (
    conditionCodes: string[],
    answers: QualificationData[]
  ) => {
    return conditionCodes.filter(Boolean).every((code) => {
      return answers.find(
        (answer) => answer.condition_code.toString() == code.toString()
      );
    });
  };

  private _getLocalization = (
    qualCode: number,
    qualDetails: BuyerQualifications[]
  ) => {
    const qualDetail = qualDetails.find(
      (qual) => qual.qualification_code == qualCode
    );
    return qualDetail && qualDetail.localizations[0];
  };

  public isOptionUnmapped = (mappings: ProcessedTargetMapping[]) => {
    return mappings.every((mapping: ProcessedTargetMapping) => {
      const { conditionCodes, rangeSets } = mapping;
      if (conditionCodes && this.isEmptyValue(...conditionCodes)) {
        return true;
      } else if (rangeSets) {
        const { from, to } = rangeSets;
        if (this.isEmptyValue(from, to)) {
          return true;
        }
      }
      return false;
    });
  };

  public isValidRange(
    rangeSet: RangeSet,
    localizationFormat: LocalizationFormat = {} as LocalizationFormat
  ): boolean {
    let { from, to } = rangeSet;
    from = parseInt(from?.toString() as string);
    to = parseInt(to?.toString() as string);
    if (!this._isCorrectFromTo(from, to)) {
      return false;
    }
    let { min, max } = localizationFormat || {};
    if (this.isEmptyValue(min, max)) {
      return true;
    }
    min = parseInt(min!.toString());
    max = parseInt(max!.toString());
    if (this._isRangeWithingMinMax(from, to, min, max)) {
      return true;
    }
    return false;
  }

  private _isCorrectFromTo(from: number, to: number) {
    return !(isNaN(from) || isNaN(to) || from >= to);
  }

  private _isRangeWithingMinMax(
    from: number,
    to: number,
    min: number,
    max: number
  ) {
    return isNaN(min) || isNaN(max) || (from >= min && to <= max);
  }

  public isEmptyValue(...args: any[]) {
    return args.every((value) => [null, undefined, ''].includes(value));
  }

  public returnConditionCodesNoNull = (
    conditionCodes: Array<string | number>
  ) => {
    return conditionCodes.filter(
      (code: string | number) => !this.isEmptyValue(code)
    );
  };

  public shrinkConditionCodes(conditionCodes: Array<string>) {
    return [
      ...new Set(
        conditionCodes.filter(Boolean).sort((a, b) => Number(a) - Number(b))
      ),
    ];
  }
}
