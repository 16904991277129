<ps-ui-modal size="lg" class="search-qualification-modal">
  <h5 psUiModalTitle>Search Qualifications:</h5>
  <ps-ui-modal-body>
    <form [formGroup]="form">
      <div class="row quality-search-box">
        <ps-ui-select-dropdown
          id="qualifications-input-search"
          class="basic-size-of-select-dropdown"
          [dropdownItems]="questionslist"
          [field]="'question_description'"
          [firstSelected]="false"
          [enableSearch]="true"
          labelNone="Select Quality/Check Question"
          (selectedItem)="selectQualification($event)"
        >
        </ps-ui-select-dropdown>
      </div>
      <div
        class="row condition-box"
        *ngIf="
          form.controls.selectedQualification.value.question_type ===
          QUESTION_TYPE.MULTIPUNCH
        "
      >
        <label class="label-control">Condition</label>
        <div>
          <label class="col-sm-3">
            <input type="radio" value="or" formControlName="condition" />
            or
          </label>
          <label class="col-sm-3">
            <input type="radio" value="and" formControlName="condition" />
            and
          </label>
        </div>
      </div>
      <div class="row quality-search-box">
        <label class="label-control">Qualification Options </label>
        <div
          class="col-xs-12 col-sm-12 col-md-12 form-group"
          *ngIf="isRangeQuestion(); else elseBlock"
        >
          <div class="row" formGroupName="range">
            <div class="col-xs-12 col-sm-6 col-md-6 form-group">
              <input
                type="number"
                class="form-control"
                placeholder="Minimum Value"
                formControlName="min"
              />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 form-group">
              <input
                type="number"
                class="form-control"
                placeholder="Maximum Value"
                formControlName="max"
              />
            </div>
          </div>
        </div>
        <ng-template #elseBlock>
          <ng-select
            [items]="options"
            [multiple]="true"
            [closeOnSelect]="false"
            [searchable]="false"
            bindLabel="name"
            placeholder="Value"
            formControlName="selectedOptions"
          >
          </ng-select>
        </ng-template>
      </div>
    </form>
  </ps-ui-modal-body>
  <ps-ui-modal-footer>
    <button
      type="button"
      psUiButton
      (click)="onSubmit()"
      [disabled]="!form.valid"
    >
      Insert Qualification
    </button>
  </ps-ui-modal-footer>
</ps-ui-modal>
