<p class="survey-performance-report-text report-title-spacing">
  Survey Performance Report
</p>

<div class="row">
  <div class="col-sm-3 text-center">
    <label class="mb-1">Survey Status</label>
    <ps-ui-select-dropdown
      [multi]="true"
      [dropdownItems]="surveyStatusOptions"
      [field]="'name'"
      (selectedItems)="selectStatus($event)"
    >
    </ps-ui-select-dropdown>
  </div>

  <div class="col-sm-3 text-center">
    <label class="mb-1">Buyer name</label>
    <ps-ui-select-dropdown
      [multi]="true"
      [dropdownItems]="buyerList"
      [field]="'name'"
      (selectedItems)="selectCompanies($event)"
    >
    </ps-ui-select-dropdown>
  </div>

  <div class="col-sm-3 text-center">
    <label class="mb-1">Country</label>
    <ps-ui-select-dropdown
      [dropdownItems]="countryList"
      [field]="'name'"
      labelNone="Select Country"
      [label]="countrySelected?.name"
      (selectedItem)="selectCountry($event)"
    >
    </ps-ui-select-dropdown>
  </div>

  <div class="col-sm-3 text-center">
    <label class="mb-1">Date Range</label>
    <input
      class="form-control date-range"
      type="text"
      name="daterangeInput"
      daterangepicker
      [options]="options"
      (selected)="selectDate($event)"
    />
  </div>
</div>

<div class="row justify-content-center">
  <div class="col-sm-4 hasing-buttons">
    <button
      type="button"
      psUiButton
      size="large"
      color="primary"
      (click)="getSurveyPerformanceData()"
    >
      Find
    </button>
  </div>
</div>

<div class="col-12-md" *ngIf="surveyPerformanceData.length > 0">
  <ps-survey-performance-report-table
    [dataList]="surveyPerformanceData"
  ></ps-survey-performance-report-table>
</div>
