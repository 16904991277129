import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import {
  CreatePurescoreThresholdRequest,
  UpdatePurescoreThresholdRequest,
} from './editable-purescore-threshold.interface';

@Injectable({
  providedIn: 'root',
})
export class EditablePurescoreThresholdService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getEditablePurescoreThreshold(locale: string, token: string) {
    return this._http.get(
      `${this._baseUrl}/operator-settings/purescore-threshold/${locale}`,
      {
        headers: { 'acess-token': token },
      }
    );
  }

  createEditablePurescoreThreshold(
    payload: CreatePurescoreThresholdRequest,
    token: string
  ) {
    return this._http.post(
      `${this._baseUrl}/operator-settings/purescore-threshold`,
      payload,
      {
        headers: { 'acess-token': token },
      }
    );
  }

  updateEditablePurescoreThreshold(
    payload: UpdatePurescoreThresholdRequest,
    token: string
  ) {
    return this._http.put(
      `${this._baseUrl}/operator-settings/purescore-threshold`,
      payload,
      {
        headers: { 'acess-token': token },
      }
    );
  }
}
