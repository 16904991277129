<ps-ui-modal>
  <h5 psUiModalTitle>
    SAVE AS {{ user.status === 'Active' ? 'INACTIVE' : 'ACTIVE' }} OR CLOSE
  </h5>
  <ps-ui-modal-body>
    Save User {{ user.name }} as
    {{ user.status === 'Active' ? 'Inactive' : 'Active' }}?
  </ps-ui-modal-body>
  <ps-ui-modal-footer>
    <button
      type="button"
      psUiButton
      color="secondary"
      (click)="close('cancel')"
    >
      No thanks, close
    </button>
    <button type="button" psUiButton (click)="close('save')">
      Save as {{ user.status === 'Active' ? 'Inactive' : 'Active' }} and Close
    </button>
  </ps-ui-modal-footer>
</ps-ui-modal>
