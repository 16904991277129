import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable, of, partition } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { SurveyListing } from '../../shared/services/buyer-api/survey.interface';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { BuyerApiService } from '../../shared/services/buyer-api/survey.service';
import { DashboardSelectedSurveys } from '../domain/dasboard-survey-selected';
import { DashboardSurveyMapper } from '../domain/dashboard-survey-mapper';
import { TableStateEvent } from '@purespectrum1/ui/table';
import { CompanyInfo } from 'src/app/shared/interfaces/company.interface';
import { DashboardSurveyCache } from '../domain/dashboard-survey-cache';

export const EMPTY_DASHBOARD_STATE: TableStateEvent = {
  sort: { type: 'none', field: '' },
  filter: [],
};

@Injectable({ providedIn: 'root' })
export class DashboardStateService {
  private readonly _search$ = new BehaviorSubject<string>('');
  private readonly _tab$ = new BehaviorSubject<number>(1);
  private readonly _selected$ = new BehaviorSubject<SurveyListing[]>([]);
  private readonly _state$ = new BehaviorSubject<TableStateEvent>(
    EMPTY_DASHBOARD_STATE
  );

  private _dashboardSelectedSurveys: DashboardSelectedSurveys =
    DashboardSelectedSurveys.empty();

  public selected$!: Observable<DashboardSelectedSurveys>;

  // cache
  public company?: CompanyInfo = undefined;
  public surveys = new DashboardSurveyCache();
  public selected = DashboardSelectedSurveys.empty();

  constructor(
    private readonly _buyerApiService: BuyerApiService,
    private readonly _toastr: ToasterService
  ) {
    const [selection$, empty$] = partition(
      this._selected$,
      (selected) => selected.length > 0
    );

    const select$ = selection$.pipe(
      filter((selected) => selected.length > 0),
      map((selected) => new DashboardSurveyMapper(selected)),
      switchMap((mapper) => this._selectedSurveys(mapper)),
      tap((selected) => {
        this.selected = selected;
      })
    );

    const clear$ = empty$.pipe(mapTo(DashboardSelectedSurveys.empty()));

    this.selected$ = merge(select$, clear$);
  }

  public get search$() {
    return this._search$.asObservable();
  }

  public get tab$() {
    return this._tab$.asObservable();
  }

  public get lastSearch(): string {
    return this._search$.value;
  }

  public get lastTab(): number {
    return this._tab$.value;
  }

  public get lastState(): TableStateEvent {
    return this._state$.value;
  }

  public get selection(): DashboardSelectedSurveys {
    return this._dashboardSelectedSurveys;
  }

  public get state$(): Observable<TableStateEvent> {
    return this._state$.asObservable();
  }

  public search(value: string): void {
    this._search$.next(value);
  }

  public tab(tab: number): void {
    if (tab !== this.lastTab) {
      this.clearState();
    }

    this._tab$.next(tab);
  }

  public select(surveys: SurveyListing[]): void {
    this._selected$.next(surveys);
  }

  public update(state: TableStateEvent): void {
    this._state$.next(state);
  }

  public clear(): void {
    this._search$.next('');
    this._tab$.next(1);
    this._selected$.next([]);
  }

  public clearState(): void {
    this._state$.next(EMPTY_DASHBOARD_STATE);
  }

  public clearSelectionOfActiveTab() {
    this._search$.next('');
    this._selected$.next([]);
  }

  private _selectedSurveys(
    mapper: DashboardSurveyMapper
  ): Observable<DashboardSelectedSurveys> {
    return this._buyerApiService.formatStatsForCards(mapper.ids()).pipe(
      map((stats) => new DashboardSelectedSurveys(stats, mapper.surveys)),
      catchError((error) => {
        this._toastr.error(error.message);
        return of(DashboardSelectedSurveys.empty());
      })
    );
  }
}
