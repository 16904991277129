import { Component, OnInit } from '@angular/core';
import { Constants } from '../operator-settings/operator-settings-constants';
import { Constants as OperatorConstants } from '../operator/operator.constant';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'ps-operator-settings',
  templateUrl: './operator-settings.component.html',
  styleUrls: ['./operator-settings.component.css'],
})
export class OperatorSettingsComponent implements OnInit {
  constructor(private _auth: AuthService) {}

  public operatorSettingsTabs: Tab[] = Constants.OPERATOR_SETTINGS;
  public activeTabIndex: number = -1;
  public activeTab: Tab = this.operatorSettingsTabs[this.activeTabIndex];
  public isProduction: boolean = environment.production;

  ngOnInit() {
    this._manageOperatorSettingsTabs();
  }
  setActiveTab(activeTabIndex: number) {
    this.activeTabIndex = activeTabIndex;
    this.activeTab = this.operatorSettingsTabs[activeTabIndex];
  }

  private _manageOperatorSettingsTabs() {
    const operatorAcessLevels = this._auth.user
      ? this._auth.user.operatorAcssLvls
      : '';
    const tabAcessFilters = [
      [
        ['admin'],
        ['SUPPLIERS_SETTINGS', 'GLOBAL_SETTINGS', 'DATA_QUALITY_SETTINGS'],
      ],
      [[OperatorConstants.SERVICE_OPERATOR_ACCESS_LEVEL.value], []],
    ];
    for (const [acessLevel, tabSettingFilter] of tabAcessFilters) {
      if (!acessLevel.includes(operatorAcessLevels)) {
        this.operatorSettingsTabs = this.operatorSettingsTabs.filter(
          (operatorSettingsTab) =>
            !tabSettingFilter.includes(operatorSettingsTab.key)
        );
      }
    }
  }
}

interface Tab {
  title: string;
  key: string;
  active: boolean;
}
