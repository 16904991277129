import { SurveyListing } from 'src/app/shared/services/buyer-api/survey.interface';

export class DashboardSurveyMapper {
  constructor(public readonly surveys: SurveyListing[]) {}

  public ids(): number[] {
    return this.surveys.reduce((acc: number[], survey: SurveyListing) => {
      if (survey.mc?.filtered_ids?.length) {
        return [...acc, ...survey.mc.filtered_ids];
      }
      return [...acc, survey.surveyId];
    }, []);
  }
}
