import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZeroDashPipe } from './zero-dash-pipe';

@NgModule({
  declarations: [ZeroDashPipe],
  imports: [CommonModule],
  exports: [ZeroDashPipe],
})
export class ZeroDashPipeModule {}
