import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, SchedulerLike, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { BillingNumberRules } from '@purespectrum1/ui/marketplace/shared/interfaces/billing-rules.interfaces';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class BillingRulesService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient, private _auth: AuthService) {}

  public find(): Observable<BillingNumberRules> {
    return this._http
      .get<BillingNumberRules>(`${this._baseUrl}/buyers/v2/billing-rules`)
      .pipe(catchError(this._handleError));
  }

  public findByCompany(company: number): Observable<BillingNumberRules> {
    return this._http
      .get<BillingNumberRules>(
        `${this._baseUrl}/buyers/v2/billing-rules/${company}`
      )
      .pipe(catchError(this._handleError));
  }

  public fetchRules(buyerCompanyId: number): Observable<BillingNumberRules> {
    const isServiceBuyerLoggedIn = this._auth.isLoggedInAsServiceBuyer();

    const companyRuleCriteria = {
      case: () => this._auth.userType === 'operator' && !isServiceBuyerLoggedIn,
      rules: () => this.findByCompany(buyerCompanyId),
      enabled: () =>
        this._auth.allBuyerConfigs.enableBillingNumberRules.includes(
          buyerCompanyId
        ),
    };

    const buyerRuleCriteria = {
      case: () => this._auth.userType !== 'operator' || isServiceBuyerLoggedIn,
      rules: () => this.find(),
      enabled: () => !!this._auth.buyerConfig?.enableBillingNumberRules,
    };

    return companyRuleCriteria.case()
      ? companyRuleCriteria
          .rules()
          .pipe(
            filter(companyRuleCriteria.enabled),
            catchError(this._handleError)
          )
      : buyerRuleCriteria
          .rules()
          .pipe(
            filter(buyerRuleCriteria.enabled),
            catchError(this._handleError)
          );
  }

  private _handleError(error: SchedulerLike): Observable<never> {
    return throwError('Something went wrong. Please try again later.', error);
  }
}
