import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Widget } from '../widget';

@Component({
  selector: 'ps-widget-button',
  templateUrl: './widget-button.component.html',
  styleUrls: ['./widget-button.component.css'],
})
export class WidgetButtonComponent {
  @Input()
  public widget!: Widget;

  @Input()
  public active = false;

  @Input()
  public count: number = 0;

  @Output()
  public tap = new EventEmitter<Widget>();

  pressed() {
    this.tap.emit(this.widget);
  }
}
