export type BillingRuleType = 'fixed' | 'input';

export interface CharacterRuleType {
  label: string;
  value: BillingRuleType;
}

export enum CharacterType {
  Alpha = 'alpha',
  Numeric = 'numeric',
  Special = 'special',
}

export interface Rules {
  range: {
    start: number;
    end: number;
    length: number;
  };
  required: boolean;
  entry: 'input' | 'fixed';
  definition: string;
  char: ('alpha' | 'numeric' | 'special')[];
}

export interface BillingRules {
  id: number;
  cmp: number;
  length: {
    min: number;
    max: number;
  };
  mandatory: boolean;
  example: string;
  rules: Rules[];
  autoGenerate: boolean;
}
