<ps-simple-header></ps-simple-header>

<div class="form-section">
  <p [hidden]="!linkExpired">This link has been expired.</p>
  <p [hidden]="!linkInvalid">Sorry that's not a valid password reset link.</p>
  <p [hidden]="!profileLocked">Sorry your account has been locked.</p>
  <br />
  <p>Please contact support@purespectrum.com for assistance.</p>
  <br />
  <br />
  <a type="submit" class="btn btn-info btn-lg back-to-login" href="login"
    >Return to Login</a
  >
</div>
