import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchFilter' })
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, field?: string): any[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    if (field) {
      return items.filter((item) => {
        return item[field].toLowerCase().includes(searchText);
      });
    }

    return items.filter((item) => {
      return item.toLowerCase().includes(searchText);
    });
  }
}
